import { defaultStyles, FileIcon } from 'react-file-icon'
import React from 'react'
import { get } from 'lodash'
import { File as Document } from '../../__generated__/graphql'

interface DocumentPreviewIconProps {
  document: Document
  className?: string
}

export const DocumentPreviewIcon:  React.FC<DocumentPreviewIconProps> = ({
  document,
  className,
}) => {
  const extension = document.name.split(".").pop() || ""
  const style = get(defaultStyles, extension)
  return(
    <span className={className}>
      <FileIcon extension={extension} {...style}/>
    </span>
  )
}