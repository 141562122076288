import classnames from 'classnames'
import React from 'react'
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Col, Container, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledDropdown } from 'reactstrap'

import Auth from "../../Auth/Auth"
import { ManagerType } from '../../helpers/helpers'
import { WithOptional } from '../../helpers/typeFunctions'
import { ManagerDetailProps } from '../../queries/extended/types/Managers'
import { ManagerDetails_overview_Manager } from '../../queries/types/ManagerDetails'
import ManagerOverviewEmployees from './ManagerOverviewEmployees'
import ManagerOverviewESG from './ManagerOverviewESG'
import ManagerOverviewGovernance from './ManagerOverviewGovernance'
import ManagerOverviewOwnership from './ManagerOverviewOwnership'
import ManagerOverviewDiversity from './ManagerOverviewDiversity'
import QueryManagerSummary from './ManagerOverviewSummary'

export type ManagerSummaryData = WithOptional<
  ManagerDetails_overview_Manager,
  "id" | "name" | "address" | "structure" | "hedgeFundOfFunds"
>

interface ManagerOverviewProps {
  data: ManagerDetails_overview_Manager
  subtype?: string
  managerType?: ManagerType
  auth: Auth
}

const getActiveTab = (props: any, url: string) => {
  if (props.match.url.indexOf(`${url}/summary`) === 0) {
    return 'Summary'
  }
  if (props.match.url.indexOf(`${url}/ownership`) === 0) {
    return 'Ownership'
  }
  if (props.match.url.indexOf(`${url}/employees`) === 0) {
    return 'Employees'
  }
  if (props.match.url.indexOf(`${url}/governance`) === 0) {
    return 'Governance'
  }
  if (props.match.url.indexOf(`${url}/esg`) === 0) {
    return 'ESG'
  }
  if (props.match.url.indexOf(`${url}/diversity`) === 0) {
    return 'DEI'
  }
  return 'Menu'
}
const ManagerOverview: React.FC<ManagerOverviewProps &
  ManagerDetailProps> = props => {
  const params = useParams() as { subtype?: string }
  const history = useHistory()
  const match = useRouteMatch() || props.match
  const managerType = props.managerType
  let urlWithoutSubtype =
      params && params.subtype
        ? match.url.slice(0, -params.subtype.length - 1)
        : match.url
  const handleRedirect = (tab: string) => {
    history.push(urlWithoutSubtype + "/" + tab)
  }
  const data = props.data as ManagerDetails_overview_Manager
  const { id, name, address, structure, hedgeFundOfFunds } = data
  const summaryData = {
    id,
    name,
    address,
    structure,
    hedgeFundOfFunds
  } as Partial<ManagerDetails_overview_Manager>
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Nav className="sub-nav sub-nav-secondary collapsed" tabs role="group">
              <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                <DropdownToggle caret>
                  { getActiveTab(props, urlWithoutSubtype) }
                </DropdownToggle>
                <DropdownMenu>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutSubtype}/summary`) === 0
                      })}
                      onClick={() => handleRedirect("summary")}
                    >
                      Summary
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutSubtype}/ownership`) === 0
                      })}
                      onClick={() => handleRedirect("ownership")}
                    >
                      Ownership
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutSubtype}/employees`) === 0
                      })}
                      onClick={() => {
                        handleRedirect("employees")
                      }}
                    >
                      Employees
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutSubtype}/governance`) === 0
                      })}
                      onClick={() => {
                        handleRedirect("governance")
                      }}
                    >
                      Governance
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutSubtype}/esg`) === 0
                      })}
                      onClick={() => handleRedirect("esg")}
                    >
                      ESG
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutSubtype}/diversity`) === 0
                      })}
                      onClick={() => handleRedirect("diversity")}
                    >
                      DEI
                    </NavLink>
                  </NavItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        <Route
          exact
          path="/managers/:managerId(\d+)/overview/:subtype(summary)"
          component={() => <QueryManagerSummary id={id} managerType={managerType} auth={props.auth} />}
        />
        <Route
          exact
          path="/managers/:managerId(\d+)/overview/:subtype(ownership)"
          component={() => <ManagerOverviewOwnership id={id} auth={props.auth} />}
        />
        <Route
          exact
          path="/managers/:managerId(\d+)/overview/:subtype(employees)"
          component={() => <ManagerOverviewEmployees id={id} auth={props.auth} />}
        />
        <Route
          exact
          path="/managers/:managerId(\d+)/overview/:subtype(governance)"
          component={() => <ManagerOverviewGovernance managerId={id} auth={props.auth} />}
        />
        <Route
          exact
          path="/managers/:managerId(\d+)/overview/:subtype(esg)"
          component={() => <ManagerOverviewESG managerId={id} auth={props.auth} />}
        />
        <Route
          exact
          path="/managers/:managerId(\d+)/overview/:subtype(diversity)"
          component={() => <ManagerOverviewDiversity managerId={id} auth={props.auth} />}
        />
        <Redirect
          from="/managers/:managerId(\d+)/overview"
          to="/managers/:managerId(\d+)/overview/summary"
        />
      </Switch>
    </>
  )
}

export default ManagerOverview
