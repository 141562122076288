import { disableFragmentWarnings, gql } from "@apollo/client"

disableFragmentWarnings()

export const DATA_LOAD_TEMPLATE_FRAGMENT = gql`
  fragment DataLoadTemplateFragment on DataLoadTemplate {
    id
    name
    assetClass {
      id
    }
    type {
      code
      value
    }
    vendor {
      id
      name
    }
  }
`

export const DATA_LOAD_BASIC_FRAGMENT = gql`
  fragment DataLoadBasicFragment on DataLoad {
    id
    lastPublished
    messages
    existingRows
    person {
      id
      firstName
      lastName
    }
    status {
      code
      value
    }
    template {
      ...DataLoadTemplateFragment
    }
    type {
      code
      value
    }
    uploadFileInfo {
      filename
      id
      uploaded
    }
  }
  ${DATA_LOAD_TEMPLATE_FRAGMENT}
`

export const LOAD_MESSAGE_FRAGMENT = gql`
  fragment LoadMessageFragment on LoadMessage {
    column
    message
    row
    severity
    value
  }
`

export const DATA_LOAD_MESSAGE_FRAGMENT = gql`
  fragment DataLoadMessageFragment on DataLoadData {
    summaryPositions {
      id
      messages {
        ...LoadMessageFragment
      }
      portfolio {
        id
        name
      }
    }
    summaryTransactions {
      id
      messages {
        ...LoadMessageFragment
      }
    }
  }
  ${LOAD_MESSAGE_FRAGMENT}
`

export const DATA_LOAD_DETAILS_FRAGMENT = gql`
  fragment DataLoadDetailsFragment on DataLoad {
    ...DataLoadBasicFragment
    data {
      ...DataLoadMessageFragment
    }
  }
  ${DATA_LOAD_MESSAGE_FRAGMENT}
`

export const DATA_LOAD_DATA_FRAGMENT = gql`
  fragment DataLoadDataFragment on DataLoadData {
    summaryPositions {
      accountId
      accruedInterest
      date
      id
      marketValue
      messages {
        column
        message
        row
        severity
      }
      portfolio {
        id
        name
        client {
          id
          name
        }
        plan {
          id
          shortName
        }
      }
    }
    summaryTransactions {
      accountId
      accruedInterest
      extension
      id
      messages {
        column
        message
        row
        severity
      }
      periodEndDate
      portfolio {
        id
        name
        client {
          id
          name
        }
        plan {
          id
          shortName
        }
      }
      transactionDate
      transactionType {
        code
        value
        cashEffect
      }
    }
  }
`

export const DATA_LOAD_DATA_CASH_FLOW_FRAGMENT = gql`
  fragment DataLoadDataCashFlowFragment on DataLoadData {
    summaryTransactions {
      id
      accruedInterest
      extension
      messages {
        column
        message
        row
        severity
      }
      periodEndDate
      portfolio {
        id
        name
        client {
          id
          name
        }
        plan {
          id
          shortName
        }
      }
      transactionDate
      transactionType {
        code
        value
        cashEffect
      }
    }
  }
`

export const DATA_LOAD_DATA_ASSETS_FRAGMENT = gql`
  fragment DataLoadDataAssetsFragment on DataLoadData {
    summaryPositions {
      accountId
      accruedInterest
      date
      id
      marketValue
      messages {
        column
        message
        row
        severity
      }
      portfolio {
        id
        name
        client {
          id
          name
        }
        plan {
          id
          shortName
        }
      }
    }
  }
`