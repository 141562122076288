import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { isNull } from "lodash"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import {
  CreateSavedProductFilterInput,
  UserSavedProductFilterFragment,
  Maybe,
  useCreateUserSavedProductFilterMutation,
  FieldControls,
  ProductInputField,
  ProductFieldControlsInput,
  useDeleteSavedProductFiltersMutation,
  UserSavedProductFilterDocument,
  SavedProductFilter,
  SortDirection,
  ProductFieldControls,
} from "../../__generated__/graphql"
import {
  convertFieldControlType,
  CurrentProductInputField,
  FieldControlType,
  FieldModelRiskTargetFilterIndexMapping,
  FilterModelFieldMapping,
  FilterModelRiskTargetMapping,
  ProductInputRiskTargetFieldKeys,
  ResearchProductStrategyTypeColumnDefsMapping as ColumnDefs,
  ResearchProductStrategyTypeSearchType as SearchType,
} from "./ResearchProductConfig"
import EditButtons from "../ui/EditButtons"
import { FilterModelState } from "./ResearchProductView"
import _ from "lodash"
import { GridReadyEvent } from "@ag-grid-community/core"
import moment from "moment"
import { DATE_API_FORMAT } from "../../helpers/constant"

interface FilterViewModalProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  type: SearchType
  callBackIfSuccess: () => void
  setForceUpdateSavedFilters: React.Dispatch<React.SetStateAction<boolean>>
}

interface AddFilterViewModalProps extends FilterViewModalProps {
  filterModelState: Maybe<FilterModelState>
  gridReadyEvent: GridReadyEvent | null
}

interface CurrentFilterViewModalProps extends FilterViewModalProps {
  currentFilters?: Maybe<UserSavedProductFilterFragment[]>
  selectedFilter?: Maybe<UserSavedProductFilterFragment>
  setSelectedFilter: (filter: Maybe<UserSavedProductFilterFragment>) => void
  setAddNewModalOpen: (value: boolean) => void
  // filterId in url
  filterId?: number
}

const getId = (view: Maybe<UserSavedProductFilterFragment>) => {
  return view?.id
}

const useForceUpdate = () => {
  // integer state
  const [value, setValue] = useState(0)
  // update the state to force render
  return () => setValue((value) => value + 1)
}

export const CurrentViewsModal = (props: CurrentFilterViewModalProps) => {
  const { modalOpen, setModalOpen, setAddNewModalOpen, type, callBackIfSuccess } = props
  const { currentFilters, selectedFilter, setSelectedFilter, filterId, setForceUpdateSavedFilters } = props
  const history = useHistory()

  const [deleteProductFilter] = useDeleteSavedProductFiltersMutation()
  const [saving, setSaving] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  // const forceUpdateNow = useForceUpdate()
  const [selectedData, setSelectedData] = useState<Maybe<UserSavedProductFilterFragment>>(selectedFilter || null)
  const [savedCurrentFilters, setCurrentFilters] = useState<UserSavedProductFilterFragment[]>(currentFilters || [])
  // test for empty filters
  // const [savedCurrentFilters, setCurrentFilters] = useState<UserSavedProductFilterFragment[]>([])

  const onSelectView = (view: UserSavedProductFilterFragment) => {
    if (view) {
      setSelectedData(view)
    }
  }

  const closeModal = (refresh: boolean = false) => {
    setSelectedData(null)
    setModalOpen(false)
    if (refresh && callBackIfSuccess) {
      callBackIfSuccess()
    }
  }

  const onDelete = () => {
    let selectedId = selectedData?.id
    if (_.isNumber(selectedId)) {
      setSaving(true)
      deleteProductFilter({ variables: { input: { id: selectedId } } })
        .then((result) => {
          console.log(`filter id ${selectedId} is deleted.`, { filterId })
          setForceUpdateSavedFilters(true)
          setSaving(false)
          setModalOpen(false)
          if (filterId === selectedId) {
            history.push(`/research/${type}`)
          }
        })
        .catch((err) => {
          setSaving(false)
          setModalOpen(false)
          console.error({ err: err.message, selectedId })
        })
    }
  }

  const onSubmit = () => {
    if (setSelectedFilter) {
      setSelectedFilter(selectedData)
      setModalOpen(false)
    }
    return
  }

  useEffect(() => {
    setCurrentFilters(currentFilters || [])
  }, [currentFilters])

  let notSelected = !selectedData || isNull(getId(selectedData))
  return (
    <Modal
      size='md'
      className='mt-5 filter-product-views'
      isOpen={modalOpen}
      toggle={() => {
        setSelectedData(null)
        setModalOpen(!modalOpen)
      }}
      zIndex={1499}
    >
      <ModalHeader className='fee-modal-header full-width-header'>
        <div className='d-flex justify-content-between'>
          <div>Filter Views</div>
          <div onClick={() => closeModal()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <>
          {savedCurrentFilters?.length > 0 && (
            <div className={"pb-1"}>
              <div>Filter ID: {getId(selectedData)}</div>
              <div>Filter Name: {selectedData?.name}</div>
            </div>
          )}
          {showSuccessMessage && <h5 className='modal-title mb-2 pb-1'>{successMessage}</h5>}
          {savedCurrentFilters?.length === 0 && (
            <div className='filter-views-placeholder d-flex'>
              <span className='m-auto'>{`No saved filter views`}</span>
            </div>
          )}
          {savedCurrentFilters?.length > 0 && (
            <Table
              bordered
              hover
              size='sm'
              key={`filter-views-table`}
              className={`table-bordered-internal filter-views-table`}
            >
              <tbody key={0}>
                {savedCurrentFilters?.map((row, index) => {
                  let id = getId(row)
                  return (
                    <tr
                      className={classNames({ selected: getId(selectedData) === id }, "filter-views-row")}
                      key={`${id}-${index}`}
                      onClick={() => {
                        onSelectView(row)
                        console.log(`selected filter id: ${row?.name}`, { row })
                      }}
                    >
                      <td>{id}</td>
                      <td>{row?.name}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
        </>
      </ModalBody>
      <ModalFooter>
        {!saving && (
          <>
            <Button
              key={`add`}
              className='add w-16'
              color='link btn-thin border-blue-80'
              onClick={() => {
                console.log("add clicked")
                setAddNewModalOpen(true)
                closeModal()
              }}
            >
              <FontAwesomeIcon icon='plus-circle' size='lg' className='text-blue-120' />
            </Button>
            <Button
              className={classNames("delete w-16", { "disable-on-white": notSelected })}
              color='link btn-thin border-blue-80'
              disabled={notSelected}
              onClick={onDelete}
            >
              <FontAwesomeIcon icon='trash' size='lg' className={classNames({ "text-blue-120": !notSelected })} />
            </Button>
          </>
        )}
        <EditButtons
          className={"disable-on-white"}
          key={`edit`}
          editMode={true}
          setEditMode={() => true}
          cancelEdit={() => closeModal()}
          saving={saving}
          // onSubmit here should use the filter to update the table.
          onSubmit={onSubmit}
          saveText={`Show`}
          disabled={!selectedData || isNull(getId(selectedData))}
          disableOnError={true}
        />
      </ModalFooter>
    </Modal>
  )
}

const DEFAULT_STATE: FilterModelState = {}

type FieldControlsKeys = Extract<keyof FieldControls, string>
type FieldControlsKeysType = Exclude<FieldControlsKeys, "__typename">
type NumberFieldControlsKeysType = Extract<FieldControlsKeysType, "neq" | "eq" | "lt" | "gt" | "lte" | "gte" | "sort" | "_in">
type NumberFieldControlsKeysFilterType = Exclude<NumberFieldControlsKeysType, "sort">
type TextFieldControlsKeysType = Extract<FieldControlsKeysType, "iLike" | "notILike" | "eq" | "neq" | "sort">

const numberFilterToFieldControlsMapping: { [key: string]: NumberFieldControlsKeysType } = {
  notEqual: "neq",
  equals: "eq",
  lessThan: "lt",
  greaterThan: "gt",
  lessThanOrEqual: "lte",
  greaterThanOrEqual: "gte",
  // "inRange": filter: 1, filterTo: 3
}

const SavedFieldToNumberFieldMapping: { [key in NumberFieldControlsKeysType]: string } = {
  neq: "notEqual",
  eq: "equals",
  lt: "lessThan",
  gt: "greaterThan",
  lte: "lessThanOrEqual",
  gte: "greaterThanOrEqual",
  _in: "set",
  // how to use this
  // notIn: "set", 
  sort: "",
  // "inRange": filter: 1, filterTo: 3
}

type TextFilterType = "contains" | "notContains" | "equals" | "notEqual" | "startsWith" | "endsWith" | "sort"

const textFilterToFieldControlsMapping: { [key in TextFilterType]: FieldControlsKeysType } = {
  contains: "iLike",
  notContains: "notILike",
  equals: "eq",
  notEqual: "neq",
  startsWith: "iLike", // "string%"
  endsWith: "iLike", // "%string"
  sort: "sort",
}

const savedFilterToTextFieldMapping: { [key in TextFieldControlsKeysType]: TextFilterType } = {
  iLike: "contains", // "contains", "startsWith", "endsWith"
  notILike: "notContains",
  eq: "equals",
  neq: "notEqual",
  sort: "sort",
}

const productInputFieldToFieldType: {
  [key in CurrentProductInputField]: string
} = {
  [ProductInputField.MANAGER]: "product.manager.name",
  [ProductInputField.NAME]: "product.name",
  [ProductInputField.ID]: "product.id",
  [ProductInputField.STRATEGY]: "product.assetClass.value",
  [ProductInputField.STRATEGY_ID]: "product.assetClass.code",
  [ProductInputField.ASSET_CLASS]: "product.assetClass.parent.value",
  [ProductInputField.ASSET_CLASS_ID]: "product.assetClass.parent.code",

  [ProductInputField.BENCHMARK]: "product.primaryBenchmark.name",
  [ProductInputField.AUM]: "product.latestAum.aum",
  [ProductInputField.HAS_COMMINGLED]: "product.hasCommingled",
  [ProductInputField.HAS_MF]: "product.hasMutualFund",
  [ProductInputField.QTR_RETURN]: "product.quarterly",

  [ProductInputField.YEARLY_RETURN]: "product.yearly",
  [ProductInputField.ACTIVE_PASSIVE]: "product.activePassive",
  [ProductInputField.LAST_INTERACTION]: "product.lastActivity",
  [ProductInputField.NUM_CLIENT_EXPOSURES]: "product.numberOfClientsInvested",
  [ProductInputField.OVERALL_STOPLIGHT_STATUS]: "product.writeUps.opinions",
  [ProductInputField.INACTIVE]: "product.inactive",

  [ProductInputField.PRODUCT_STRUCTURE_CODE]: "product.structure.code",
  [ProductInputField.CAPITAL_STRUCTURE_CODE]: "product.capitalStructure",
  [ProductInputField.FUNDRAISING_STATUS]: "product.fundraisingStatus",
  [ProductInputField.VINTAGE_YEAR]: "closedEnded.vintageYearFirstCashflow",
  [ProductInputField.FUND_SIZE]: "closedEnded.fundRaising",
  [ProductInputField.TRACKING_ERROR_MIN]: "openEnded.riskStatisticsTargets.trackingError",
  [ProductInputField.TRACKING_ERROR_MAX]: "openEnded.riskStatisticsTargets.trackingError",
  [ProductInputField.EXCESS_RETURN_MIN]: "openEnded.riskStatisticsTargets.excessReturn",
  [ProductInputField.EXCESS_RETURN_MAX]: "openEnded.riskStatisticsTargets.excessReturn",
}

type FieldModelControlType = "number" | "date" | "set"

enum FieldControlTypeToFieldModelControlTypeMapping {
  "float" = "number",
  "int" = "number",
  "string" = "text",
  "boolean" = "set",
  "enum" = "set",
  "date" = "date",
  "numeric_enum" = "set",
  "multi" = "float"
}
const ProductInputFieldValueTypeMapping: {
  [key in CurrentProductInputField]: FieldControlType
} = {
  [ProductInputField.MANAGER]: "string",
  [ProductInputField.NAME]: "string",
  [ProductInputField.ID]: "int",
  [ProductInputField.STRATEGY]: "string",
  [ProductInputField.ASSET_CLASS]: "string",
  [ProductInputField.ASSET_CLASS_ID]: "int",
  [ProductInputField.STRATEGY_ID]: "int",

  [ProductInputField.BENCHMARK]: "string",
  [ProductInputField.AUM]: "float",
  [ProductInputField.HAS_COMMINGLED]: "boolean",
  [ProductInputField.HAS_MF]: "boolean",
  [ProductInputField.QTR_RETURN]: "float",

  [ProductInputField.YEARLY_RETURN]: "float",
  [ProductInputField.ACTIVE_PASSIVE]: "enum",
  [ProductInputField.LAST_INTERACTION]: "date",
  [ProductInputField.NUM_CLIENT_EXPOSURES]: "int",
  [ProductInputField.OVERALL_STOPLIGHT_STATUS]: "numeric_enum",
  [ProductInputField.INACTIVE]: "boolean",

  [ProductInputField.PRODUCT_STRUCTURE_CODE]: "enum",
  [ProductInputField.CAPITAL_STRUCTURE_CODE]: "enum",
  [ProductInputField.FUNDRAISING_STATUS]: "string",
  [ProductInputField.VINTAGE_YEAR]: "int",
  [ProductInputField.FUND_SIZE]: "float",
  [ProductInputField.TRACKING_ERROR_MIN]: "multi",
  [ProductInputField.TRACKING_ERROR_MAX]: "multi",
  [ProductInputField.EXCESS_RETURN_MIN]: "multi",
  [ProductInputField.EXCESS_RETURN_MAX]: "multi",
}

// mock data SavedProductFilter
const savedFilter: SavedProductFilter = {
  id: 1,
  name: "test",
  filters: [
    {
      __typename: "ProductFieldControls",
      field: ProductInputField.NAME,
      controls: {
        __typename: "FieldControls",
        like: "test",
      },
    },
    {
      __typename: "ProductFieldControls",
      field: ProductInputField.AUM,
      controls: {
        __typename: "FieldControls",
        neq: "1",
        eq: "2",
        lt: "3",
      },
    },
    {
      __typename: "ProductFieldControls",
      field: ProductInputField.HAS_COMMINGLED,
      controls: {
        __typename: "FieldControls",
        eq: "true",
      },

    }
  ],
  __typename: "SavedProductFilter",
}

const generateSetFilterModel = (values: any[]) => ({filterType: "set", values})

/** convert SavedFilterModel to ProductFieldControlsInput[] */
// { values, filter: value, filterType, type, operator } = settings
export const convertToFilterModel = (props: Maybe<SavedProductFilter>) => {
  if (!props) return null
  let { id, name, filters } = props
  let result: FilterModelState = filters.reduce((acc, { field, controls }, idx) => {
    let modelField = productInputFieldToFieldType[field as CurrentProductInputField] // product.name
    let keys = Object.keys(controls) // [_in]
    // FieldControlType = "int" | "string" | "boolean" | "date" | "float" | "numeric_enum" | "enum"
    let filterType = ProductInputFieldValueTypeMapping[field as CurrentProductInputField]
    let filterModelType = FieldControlTypeToFieldModelControlTypeMapping[filterType]
    keys.map((key) => {
      if (key === "sort" || key === "__typename") return
      let value: any = controls[key as keyof FieldControls]
      let type: string = ""
      if(_.isNull(value)) return
      switch (filterType) {
        case "multi":
          type = SavedFieldToNumberFieldMapping[key as NumberFieldControlsKeysType]
          let filterModel = { filterType: "number", filter: convertFieldControlType(value, filterType), type}
          let idx = FieldModelRiskTargetFilterIndexMapping[field as ProductInputRiskTargetFieldKeys]
          if(modelField in acc) {
            let models = acc[modelField].filterModels
            if(models && models[idx]) {
              let previousFilter = models[idx]
              let newFilter = { filterType: "number", filter: convertFieldControlType(value, filterType), type}
              if(previousFilter.type !== newFilter.type) {
                if(previousFilter.type === "lessThan" && newFilter.type === "greaterThan") {
                  models[idx] = { filterType: "number", filter: newFilter.filter, type: "inRange", filterTo: previousFilter.filter }
                }else if (previousFilter.type === "greaterThan" && newFilter.type === "lessThan") {
                  models[idx] = { filterType: "number", filter: previousFilter.filter, type: "inRange", filterTo: newFilter.filter }
                }else {
                }
              }
              acc[modelField]["filterModels"] = models
            }else if(models){
              acc[modelField]["filterModels"][idx] = filterModel
            }else {
              acc[modelField] = { filterType: "multi", filterModels: [null, filterModel]}
            }
          }else {
            let models = idx === 0? [filterModel]: [null, filterModel]
            acc[modelField] = { filterType: "multi", filterModels: models }
          }
          break
        case "int":
        case "float":
          type = SavedFieldToNumberFieldMapping[key as NumberFieldControlsKeysType]
          value = controls[key as NumberFieldControlsKeysFilterType] || ""
          if(type === "set") {
            // key === "_in"
            let filterValues = (value as any[]).map((v: string) => convertFieldControlType(v, filterType))
            acc[modelField] = generateSetFilterModel(filterValues)
            break
          }
          if(modelField in acc) {
            let previousFilter = acc[modelField]
            let newFilter = { filterType: filterModelType, filter: convertFieldControlType(value, filterType), type}
            if(previousFilter.type !== newFilter.type) {
              if(previousFilter.type === "lessThan" && newFilter.type === "greaterThan") {
                acc[modelField] = { filterType: filterModelType, filter: newFilter.filter, type: "inRange", filterTo: previousFilter.filter }
              }else if (previousFilter.type === "greaterThan" && newFilter.type === "lessThan") {
                acc[modelField] = { filterType: filterModelType, filter: previousFilter.filter, type: "inRange", filterTo: newFilter.filter }
              }else {
                console.log(999, previousFilter.type, newFilter.type)
              }
            }
          }else {
            acc[modelField] = { filterType: filterModelType, filter: convertFieldControlType(value, filterType), type}
          }
          break
        case "string":
          let stringField = savedFilterToTextFieldMapping[key as TextFieldControlsKeysType]
          value = controls[key as TextFieldControlsKeysType] || ""
          if(key === "iLike") {
            if (value.startsWith("%") && value.endsWith("%")) {
              stringField = "contains"
              value = value.slice(1, value.length - 1)
            }else if(value.startsWith("%")) {
              stringField = "endsWith"
              value = value.slice(1)
            }else if(value.endsWith("%")) {
              stringField = "startsWith"
              value = value.slice(0, value.length - 1)
            }
            acc[modelField] = { filterType: filterModelType, filter: convertFieldControlType(value, filterType), type: stringField}
            break
          } else if(key === "notILike") {
            if (value.startsWith("%") && value.endsWith("%")) {
              stringField = "notContains"
              value = value.slice(1, value.length - 1)
            }
            acc[modelField] = { filterType: filterModelType, filter: convertFieldControlType(value, filterType), type: stringField}
            break
          } else if (key === "_in") {
            let filterValues = (value as any[]).map((v: string) => convertFieldControlType(v, filterType))
            acc[modelField] = generateSetFilterModel(filterValues)
          }
          break
        // boolean, enum, numeric_enum all uses _in as values control.
        case "boolean":
        case "enum":
        case "numeric_enum":
          type = "set"
          // key === "_in"
          value = controls[key as NumberFieldControlsKeysFilterType] || ""
          let values = generateSetFilterModel(value)
          acc[modelField] = values
          break
        case "date":
          type = SavedFieldToNumberFieldMapping[key as NumberFieldControlsKeysType]
          value = controls[key as NumberFieldControlsKeysFilterType] || ""
          if(modelField in acc) {
            let previousFilter = acc[modelField]
            let newFilter = { filterType, filter: convertFieldControlType(value, filterType), type}
            if(previousFilter.type !== newFilter.type) {
              if(previousFilter.type === "lessThan" && newFilter.type === "greaterThan") {
                acc[modelField] = { filterType: filterModelType, dateFrom: newFilter.filter, type: "inRange", dateTo: previousFilter.filter }
              }else if (previousFilter.type === "greaterThan" && newFilter.type === "lessThan") {
                acc[modelField] = { filterType: filterModelType, dateFrom: previousFilter.filter, type: "inRange", dateTo: newFilter.filter }
              }else {
                console.log(previousFilter.type, newFilter.type)
              }
            }
          }else {
            acc[modelField] = { filterType: filterModelType, filter: convertFieldControlType(value, filterType), type}
          }
          break
        default:
          break
      }
      // {values, filter: value, filterType, type, operator}
    })
    return acc
  }, {} as FilterModelState)
  // console.log(363, { id, filters, result })
  return result
}

export enum SortModelDirection {
  /** Sort in ascending order */
  asc = "asc",
  /** Sort in descending order */
  desc = "desc",
}

type SortModelType = {
  sort: SortModelDirection | null // direction
  colId: string // field
  sortIndex: number // priority
}

export type SortModelState = SortModelType[]

/** convert UserSavedProductFilterFragment to SortColumnState */
export const  convertToSortState = (props: Maybe<UserSavedProductFilterFragment>) => {
  if (!props?.filters) return null
  let { filters } = props
  let sortState: SortModelState = filters.reduce((acc, { field, controls }) => {
    let { sort } = controls
    if (!!sort) {
      acc.push({ sort: sort.direction.toLowerCase(), colId: productInputFieldToFieldType[field as CurrentProductInputField], sortIndex: sort.priority } as SortModelType)
    }
    return acc
  }, [] as SortModelState)
  return sortState
}

/**
 * lower index => lower priority number => higher priority
 */
export const convertSortStateToSavedFilterState = (state: { sort: SortModelDirection | "asc" | "desc"; colId: string }[]) => {
  if (!state) return null
  let controls: ProductFieldControlsInput[] = state.reduce((acc, el, idx) => {
    let { sort, colId: field } = el
    let productField: ProductInputField = FilterModelFieldMapping[field]
    if(!productField) {
      let [first, second] = FilterModelRiskTargetMapping[field]
      acc = acc.concat([{
        field: first,
        controls: {
          sort: {
            direction: sort.toUpperCase() as SortDirection,
            priority: idx * 2,
          },
        },
      },{
        field: second,
        controls: {
          sort: {
            direction: sort.toUpperCase() as SortDirection,
            priority: idx * 2 + 1,
          },
        },
      }])
    } else {
      acc.push({
        field: productField,
        controls: {
          sort: {
            direction: sort.toUpperCase() as SortDirection,
            priority: idx* 2,
          },
        },
      })
    }
    return acc
  }, [] as ProductFieldControlsInput[])
  return controls
}

type MultiFilterModel = {
  filterType: "multi",
  filterModels: FilterModel[],
  operator?: "AND"| "OR",
  type?: string
}

type SetFilterModel = {
  filterType: "set",
  values: any[],
  type: string, // options e.g. notContains, greaterThan
  filter: any, // 1st value
  filterTo?: any, // 2st value if exists, for inRange. 
  operator?: "AND"| "OR",
}

type DateFilterModel = {
  filterType: "date",
  type: string, // options e.g. notContains, greaterThan
  dateFrom: Maybe<string>, // 1st value
  dateTo: Maybe<string>, // 2st value if exists, for inRange. default: null
  operator?: "AND"| "OR",
}

type TextFilterModel = {
  filterType: "text",
  type: string, // options e.g. notContains, greaterThan
  filter: string,
  operator?: "AND"| "OR",
}

type NumberFilterModel = {
  filterType: "number",
  type: string, // options e.g. notContains, greaterThan
  filter: any, // 1st value
  filterTo?: any, // 2st value if exists, for inRange. 
  operator?: "AND"| "OR",
}

// Discriminated Union
type FilterModel = MultiFilterModel | SetFilterModel | DateFilterModel | TextFilterModel | NumberFilterModel

/** convert FilterModel to ProductFieldControlsInput[] */
export const convertFilterModel = (props: Maybe<{ [key: string]: FilterModel}>) => {
  if (!props) return null
  let fields = Object.keys(props)
  if(fields?.length === 0) return null
  let results: ProductFieldControlsInput[] = fields.reduce((acc, field) => {
    let settings = props[field]
    let { filterType, operator, type } = settings
    let productField: ProductInputField = FilterModelFieldMapping[field]
    let controls: { [key: string]: any } = {}
    let controlType: FieldControlsKeysType
    if(!productField) {
      let [firstField, secondField] = FilterModelRiskTargetMapping[field]
      if(settings.filterType === "multi" && settings.filterModels) {
        // for number multi filter only. 
        let {filterModels} = settings as MultiFilterModel
        filterModels.map((filterModel, idx) => {
          if(!filterModel) return
          let {filterType: subFilterType, type: subType, filter: subFilterValue, filterTo: subFilterToValue} = filterModel as NumberFilterModel
          let subField = idx === 0 ? firstField: secondField
          if (subType === "inRange") {
            controls = {
              gt: _.isNumber(subFilterValue) ? subFilterValue.toString() : "",
              lt: _.isNumber(subFilterToValue) ? subFilterToValue.toString() : "",
            }
            acc.push({ field: subField, controls })
          } else {
            controlType = numberFilterToFieldControlsMapping[subType]
            controls = { [controlType]: _.isNumber(subFilterValue) ? subFilterValue.toString() : "" }
            acc.push({ field: subField, controls })
          }
        })
      }else if(filterType !== "multi"){
        console.log("error: filterType is not multi")
      }else {
        // no filterModels
      }
      // console.log(592, {acc})
      return acc
    }
    let {values} = settings as SetFilterModel
    let {filter: value, filterTo: toValue} = settings as NumberFilterModel
    let { dateFrom, dateTo } = settings as DateFilterModel
    let {filter: textValue} = settings as TextFilterModel
    switch (settings.filterType) {
      case "set": // filterType, values
      // nin cases??
      // currently all values are strings.
        controls = { _in: values }
        acc.push({ field: productField, controls })
        break
      case "number":
        if (operator === "AND") {
        } else if (operator === "OR") {
          // or suppressed now. Needs api change if we want to support it.
        } else {
          if (type === "inRange") {
            // in range includes edge? Not now.
            controls = {
              gt: _.isNumber(value) ? value.toString() : "",
              lt: _.isNumber(toValue) ? toValue.toString() : "",
            }
            acc.push({ field: productField, controls })
          } else {
            controlType = numberFilterToFieldControlsMapping[type as string]
            controls = { [controlType]: _.isNumber(value) ? value.toString() : "" }
            acc.push({ field: productField, controls })
          }
        }
        break
      case "text":
        switch (type) {
          case "contains":
          case "notContains":
            controlType = textFilterToFieldControlsMapping[type as TextFilterType]
            controls = { [controlType]: `%${textValue}%` }
            acc.push({ field: productField, controls })
            break
          case "equals":
          case "notEqual":
            controlType = textFilterToFieldControlsMapping[type as TextFilterType]
            controls = { [controlType]: (textValue || "").toString() }
            acc.push({ field: productField, controls })
            break
          case "startsWith":
            controls = { iLike: `${textValue}%` }
            acc.push({ field: productField, controls })
            break
          case "endsWith":
            controls = { iLike: `%${textValue}` }
            acc.push({ field: productField, controls })
            break
          case "sort":
            // Not support, should be handled by sort model.
            break
          default:
            break
        }
        break
      // TODO: handle date filter
      case "date":
        if (type === "inRange") {
          // in range includes edge? Not now.
          controls = {
            gt: _.isNull(dateFrom) ? "": moment(dateFrom).format(DATE_API_FORMAT),
            lt: _.isNull(dateTo) ? "": moment(dateTo).format(DATE_API_FORMAT),
          }
          acc.push({ field: productField, controls })
        } else {
          controlType = numberFilterToFieldControlsMapping[type as string]
          controls = { [controlType]: moment(dateFrom).format(DATE_API_FORMAT) }
          acc.push({ field: productField, controls })
        }
        break
      default:
        console.log(`filter type of ${filterType} is not supported.`)
        break
    }
    return acc
  }, [] as ProductFieldControlsInput[])
  // console.log(248, { fields, props, results })
  return results
}
/**
 * Merge default filters with table filters, if table filters has category filter, then merge other default filters.
*/
export const mergeDefaultFilters = (tableFilters: ProductFieldControlsInput[], defaultFilters: ProductFieldControlsInput[]) => {
  let categoryFromTableFilters = tableFilters.filter(filter => filter.field === ProductInputField.ASSET_CLASS_ID)
  if(!defaultFilters?.length) {
    return tableFilters
  } else if (!categoryFromTableFilters?.length) {
    return [...tableFilters, ...defaultFilters]
  } else {
    let otherFilters = defaultFilters.filter(filter => filter.field !== ProductInputField.ASSET_CLASS_ID) || []
    return [...tableFilters, ...otherFilters]
  }
}

export const mergeSortState = (filters: ProductFieldControlsInput[], sortState: SortColumnState[]) => {
    let sortModelState = sortState.map(({sort, colId, sortIndex}) => {
      return {
        field: FilterModelFieldMapping[colId],
        controls: {
          sort: {
            direction: sort.toUpperCase() as SortDirection,
            priority: sortIndex,
          },
        },
      }
    })
  return [...filters, ...sortModelState]
}

interface SortColumnState {
 sort: string,
 sortIndex: number,
 colId: string,
}

export const AddNewViewModal = (props: AddFilterViewModalProps) => {
  const { modalOpen, setModalOpen, type, callBackIfSuccess } = props
  const { filterModelState, gridReadyEvent, setForceUpdateSavedFilters } = props
  const history = useHistory()
  const [state, setFilterState] = useState<FilterModelState>(filterModelState || DEFAULT_STATE)

  const nameRef = useRef<HTMLInputElement>(null)
  const [savedName, setViewName] = useState<string>("")
  const [createProductFilter] = useCreateUserSavedProductFilterMutation()

  const [saving, setSaving] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  useEffect(() => {
    setFilterState(filterModelState || {})
  }, [filterModelState])

  const closeModal = (refresh: boolean = false) => {
    setViewName("")
    setModalOpen(false)
    if (refresh && callBackIfSuccess) {
      callBackIfSuccess()
    }
  }

  const onSubmit = () => {
    let earlyReturn = false
    if (savedName.length === 0) {
      nameRef?.current?.focus()
      earlyReturn = true
    }
    if (earlyReturn) {
      console.log("early exit", "add new Filter")
      return
    }
    setSuccessMessage("")
    setShowSuccessMessage(false)
    setSaving(true)
    let tableFilters = convertFilterModel(state) || []
    // add default filter
    let defaultFilters = ColumnDefs[type].filters.controls || []

    let filters = mergeDefaultFilters(tableFilters, defaultFilters)
    let columnState = (gridReadyEvent?.columnApi?.getColumnState()?.filter(el => !!el.sort && !!el.colId) || []) as SortColumnState[]
    let sortModelState = columnState.map(({sort, colId, sortIndex}) => ({sort, colId, sortIndex}))
    let withSort = mergeSortState(filters, sortModelState)
    let input = {
      name: savedName,
      filters: withSort
    } as CreateSavedProductFilterInput
    createProductFilter({variables: {input}, awaitRefetchQueries: true,
      refetchQueries:[{query: UserSavedProductFilterDocument}]})
    .then(result => {
      let id = result?.data?.createSavedProductFilter?.savedProductFilter?.id
      console.log("success create Product Filter", {result, id})
      setSaving(false)
      setModalOpen(false)
      setForceUpdateSavedFilters(true)
      if(_.isNumber(id)) {
        history.push(`/research/${type}?filterId=${id}`)
      }
    }).catch(err => {
      setSaving(false)
      setModalOpen(false)
      console.error({err: err.message, input})
    })
  }
  return (
    <Modal
      size='md'
      className='mt-5 filter-product-views'
      isOpen={modalOpen}
      toggle={() => {
        setViewName("")
        setModalOpen(!modalOpen)
      }}
      zIndex={1500}
    >
      <ModalHeader className='fee-modal-header full-width-header'>
        <div className='d-flex justify-content-between'>
          <div>Add Filter View</div>
          <div onClick={() => closeModal()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className='filter-views-add'>
          <span>{`Name and save the current filter settings as a reusable view.`}</span>
        </div>
        <Form className='mt-1'>
          <FormGroup row className='relative m-0 mr-1'>
            <Input
              className='ml-0'
              type='text'
              placeholder='Name'
              value={savedName}
              onChange={(e) => {
                setViewName(e.target.value)
              }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <EditButtons
          className={"disable-on-white"}
          editMode={true}
          setEditMode={() => true}
          cancelEdit={() => closeModal()}
          saving={saving}
          onSubmit={onSubmit}
          disableOnError={true}
          disabled={!savedName}
        />
      </ModalFooter>
    </Modal>
  )
}
