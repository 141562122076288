import React, { useState } from "react"
import { NavLink, NavbarBrand } from "reactstrap"
import { slide as Menu, State } from "react-burger-menu"
import { Link } from "react-router-dom"
import Icon from "./ui/Icon"
import Auth from "../Auth/Auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ControlCenterComponentConfig } from "./ControlCenter/ControlCenterDashboard"

interface SideBarProps {
  auth: Auth
}

export const SideBar = (props: SideBarProps) => {
  const [isMenuOpen, handleMenu] = useState(false)
  const handleCloseMenu = () => {
    handleMenu(false)
  }
  const handleStateChange = (state: State) => {
    handleMenu(state.isOpen)
  }

  const canSeeControlCenter = ControlCenterComponentConfig.some(config => props.auth.checkPermissions([config.permission]))
  
  return (
    <Menu
      isOpen={isMenuOpen}
      customBurgerIcon={<Icon width={17} height={17} />}
      width={270}
      onStateChange={handleStateChange}
      itemListClassName={"mega-menu d-flex flex-column"}
    >
      <div className="title d-flex">
        <NavLink
          onClick={(e) => {
            e.preventDefault()
            handleCloseMenu()
          }}
          href="/"
        >
          <Icon width={17} height={17} />
        </NavLink>
        <NavbarBrand className="company_logo" to="/" tag={Link}>
          <img
            className="light-text-color mr-2"
            src="/img/callan_logo.svg"
            width="82px"
            height="24px"
            alt="callan_logo"
          />
        </NavbarBrand>
      </div>
      <div className="link-heading">
        Data
      </div>
      <div className="link-item">
        <Link
          onClick={handleCloseMenu}
          to="/"
        >
          Dashboard
        </Link>
      </div>
      {
       props.auth.checkPermissions(["view:research_products"]) &&
        <div className="link-item">
          <Link
            onClick={handleCloseMenu}
            to="/research"
          >
            Research Products
          </Link>
        </div>
      }
      {props.auth.checkPermissions(["view:all_clients"]) &&
        (
          <div className="link-item">
            <Link
              onClick={handleCloseMenu}
              to="/clients"
            >
              Clients
            </Link>
          </div>
        )
      }
       {props.auth.checkPermissions(["view:all_clients"]) &&
        (
          <div className="link-item">
            <Link
              onClick={handleCloseMenu}
              to="/plans"
            >
              Manage Plans
            </Link>
          </div>
        )
      }
      {props.auth.checkPermissions(["view:all_clients"]) &&
        <div className="link-heading">
          Reporting
        </div>
      }
      {props.auth.checkPermissions(["view:all_clients"]) &&
        (<div className="link-item">
          <Link
            onClick={handleCloseMenu}
            to="/reports"
            >
            Reports
          </Link>
        </div>)
      }
      {props.auth.checkPermissions(["edit:lists"]) &&
        (<div className="link-item">
          <Link
            onClick={handleCloseMenu}
            to="/lists"
            >
            Lists
          </Link>
        </div>)
      }
      {/* <div className="link-item">
        <Link
          onClick={handleCloseMenu}
          to="/managers"
        >
          Managers
        </Link>
      </div> */}
      {canSeeControlCenter && <div className="link-control mt-auto">
        <Link
          onClick={handleCloseMenu}
          to="/control-center"
          className="start"
          >
          <FontAwesomeIcon icon={["fas", "sliders-h-square"]} className={"control-icon"} size="sm"/>
          Control Center
        </Link>
      </div>}
    </Menu>
  )
}