import classnames from 'classnames'
import React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
  RouteComponentProps
} from 'react-router-dom'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap'

import Auth from '../../Auth/Auth'
import GlidePathAllocation from './GlidePathAllocation'
import GlidePathReturns from './GlidePathReturns'


interface GlidePathSummaryProps extends RouteComponentProps {
  glidepathId: number
  auth: Auth
}

const GlidePathSummary: React.FC<GlidePathSummaryProps> = props => {
  const params = useParams() as { subtype?: string }
  const history = useHistory()
  const match = useRouteMatch() || props.match

  let urlWithoutSubtype =
      params && params.subtype
        ? match.url.slice(0, -params.subtype.length - 1)
        : match.url
  const handleRedirect = (tab: string) => {
    history.push(urlWithoutSubtype + "/" + tab)
  }
  const id = props.glidepathId
  const auth = props.auth
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Nav className="sub-nav sub-nav-secondary" tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutSubtype}/allocation`) === 0
                  })}
                  onClick={() => handleRedirect("allocation")}
                >
                  Allocation
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutSubtype}/returns`) === 0
                  })}
                  onClick={() => handleRedirect("returns")}
                >
                  Returns
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        <Route
          exact
          path="/glidepaths/:glidepathId(\d+)/glidepath/:subtype(allocation)"
          component={() => <GlidePathAllocation id={id} auth={auth}/>}
        />
        <Route
          exact
          path="/glidepaths/:glidepathId(\d+)/glidepath/:subtype(returns)"
          component={() => <GlidePathReturns id={id} />}
        />
        <Redirect
          from="/glidepaths/:glidepathId(\d+)/glidepath"
          to="/glidepaths/:glidepathId(\d+)/glidepath/allocation"
        />
      </Switch>
    </>
  )
}

export default GlidePathSummary
