import React, { useState } from 'react';
import { Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from 'classnames'
import { snakeCase } from 'lodash'

interface SidebarCollapseProps {
  title: string
  open?: boolean
  children: React.ReactNode
  className?: string
}

export const SidebarCollapse: React.FC<SidebarCollapseProps> = props => {
  const { title, open, children, className } = props
  const [isOpen, setIsOpen] = useState(open);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className={classnames("sidebar-collapse py-4", className)}>
      <a onClick={toggle} className="d-flex">
        <h4 className="mb-0">{ title }</h4>
          <FontAwesomeIcon
            icon={ isOpen ? 'chevron-up' : 'chevron-down'}
            size="sm"
            className="ml-auto mr-0"
          />
      </a>
      <Collapse isOpen={isOpen}>
        {/* <div className="scroll-fade scroll-fade-in" /> */}
        <div className="mt-3 collapse-content">
          { children }
        </div>
        {/* <div className="scroll-fade scroll-fade-out" /> */}
      </Collapse>
    </div>
  );
}