import React from "react"
import { FormGroup, CustomInput } from "reactstrap"

interface CustomBooleanInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: boolean
  id: string
}

export const CustomBooleanInput: React.FC<CustomBooleanInputProps> = ({
  onChange,
  value,
  id,
}) => {
  return (
    <FormGroup
      style={{
        margin: 0,
      }}
    >
      <CustomInput
        id={`${id}-yes`}
        className="boolean-radio custom-radio custom-radio-sm custom-control custom-control-inline"
        bsSize="sm"
        type="radio"
        value="true"
        checked={value}
        onChange={onChange}
        label="Yes"
        inline
      />
      <CustomInput
        id={`${id}-no`}
        className="boolean-radio custom-radio custom-radio-sm custom-control custom-control-inline"
        bsSize="sm"
        type="radio"
        value="false"
        checked={!value}
        onChange={onChange}
        label="No"
        inline
      />
    </FormGroup>
  )
}
