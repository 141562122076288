import _, { compact, filter, find, first, get, isUndefined, uniqBy } from 'lodash'
import moment from 'moment'
import React from 'react'
import { History } from 'history'
import { useHistory } from 'react-router-dom'
import { Col, ListGroup, ListGroupItem } from 'reactstrap'
import { ConsultantDashboardComponentSettingsFragment, ConsultantNotableInteractionsFetchFragment, ConsultantNotableInteractionsPlanFetchFragment,  InteractionSidebarFragment,  Maybe,  NotableInteractionsInput,  NotableInteractionTimePeriod,  UpdateComponentInput,  useInteractionsForPlanListQuery, useUpdateComponentMutation } from '../../../__generated__/graphql'
import { ConsultantDashboardAggregatedComponentProps, ConsultantDashboardBaseComponent, ConsultantDashboardIndividualComponentProps } from './ConsultantDashboardComponent'
import { ASSET_CLASS_OPTIONS_DATA, InteractionsSidebar, MultiFilter } from '../../Org/ManagerInteractions'
import { ErrorComponent, LoadingComponent } from '../../Report/Shared/ReportComponent'
import { Filter } from '../../Shared/Picker'

const NotableInteractions: React.FC<ConsultantDashboardAggregatedComponentProps> = ({component, auth, draftView}) => {
  return (
    <ConsultantDashboardBaseComponent<ConsultantNotableInteractionsFetchFragment>
      component={component}
      auth={auth}
      draftView={draftView}
      expectedTypename={"NotableInteractions"}
      reactComponent={NotableInteractionsDisplay}
    />
  )
}

const NotableInteractionsDisplay: React.FC<ConsultantDashboardIndividualComponentProps<ConsultantNotableInteractionsFetchFragment>>  = (props) => {
  let {auth, component, settings} = props
  // default Filter
  let {list, trailingMonths, importance, managerProductFilter} = settings
  const [updateComponentMutation] = useUpdateComponentMutation()

  // TODO default trailing months number??
  let startDate = trailingMonths? moment().subtract(trailingMonths, "months").format("YYYY-MM-DD"): null

  let filteredPlans: Maybe<{__typename: "ListMember", item: ConsultantNotableInteractionsPlanFetchFragment}>[] = []
  let history = useHistory()
  const listId = list?.id || 0
  const { data, loading, error } = useInteractionsForPlanListQuery({
    variables: {
      id: listId,
      interactionFilters: {
        startDate,
        managerProductFilter,
      }
    }
  });

  if(loading) return <LoadingComponent name={""}/>
  if(error) return <ErrorComponent name={""} error={error.message}/>
  if(data) {
    filteredPlans = compact(data?.list?.items?.map(el=>{
      let plan = el.item as ConsultantNotableInteractionsPlanFetchFragment
      if(plan){
        return {...el, item: plan} as Maybe<{__typename: "ListMember", item: ConsultantNotableInteractionsPlanFetchFragment}>
      }
      return undefined
    }))
    const isClientPath = (path:string) => path.indexOf("/clients")
    let redirectPathMap = {} as {[interactionId:string]: string}
    let allInteractions = [] as InteractionSidebarFragment[]
    filteredPlans.map((el, idx)=>{
      let plan = el?.item as ConsultantNotableInteractionsPlanFetchFragment
      let {interactions, client} = plan
      if(!interactions || interactions.length < 1) {
        return undefined
      }
      let clientId: number | undefined
      let redirectPath: string = ""
      let reportId: number | undefined
      let managerId: number | undefined
      if(client) {
        clientId = client.id
        reportId = first(filter(plan?.report, report=> !!report?.id))?.id || undefined
        redirectPath = `/clients/${clientId}/${reportId||0}/interactions`
      }
      if(isUndefined(reportId)){
        managerId = first(filter(interactions, el=>!!el?.primaryManagerAssociation?.id))?.primaryManagerAssociation?.id
        redirectPath = `/managers/${managerId}/interactions`
      }
      let notNullInteractions = compact(interactions)
      notNullInteractions.forEach(interaction=>{
        let id = interaction.id
        if(!redirectPathMap.hasOwnProperty(id)) {
          redirectPathMap[id] = redirectPath
        }else {
          if(isClientPath(redirectPath) === 0) {
            // use clientsPath if there is
            redirectPathMap[id] = redirectPath
          }
        }
      })
      allInteractions = allInteractions.concat(notNullInteractions)
    })
    const handleSelect = (id:string )=> {
      let redirectUrl = redirectPathMap[id]
      history.push(`${redirectUrl}/${id}`)
    }
    allInteractions = uniqBy(allInteractions, "id")
    const settingsToDefaultFilter = (filters: Filter[]):MultiFilter[] => {
      return compact(
        filters.map((filter) => {
          if (filter.property === "notes") {
            const trueOption = filter.options.find(
              (option) => option.value === "true"
            )
            if (settings.hasNotes && trueOption)
              return {
                filterId: filter.filterId,
                activeOptions: compact([trueOption.id]),
              }
          } else if (filter.property === "importance") {
            if (settings.importance && settings.importance.length > 0) {
              return {
                filterId: filter.filterId,
                activeOptions: compact(
                  settings.importance.map(
                    (importance) =>
                      filter.options.find(
                        (option) => option.value === importance?.toString()
                      )?.id
                  )
                ),
              }
            }
          } else if (filter.property === "date") {
            const timePeriodMapping:{[key:string]: NotableInteractionTimePeriod} = {
              "1 M": NotableInteractionTimePeriod.ONE_MONTH,
              "1 Q": NotableInteractionTimePeriod.ONE_QUARTER,
              "1 y": NotableInteractionTimePeriod.ONE_YEAR,
            }
            if(settings.timePeriod) {
              return {
                filterId: filter.filterId,
                activeOptions: compact([filter.options.find(
                  (option) => option.value && timePeriodMapping[option.value] === settings.timePeriod
                )?.id]),
              }
            }
          } else if (filter.property === "assetClass") {
            if(settings.assetClass) {
              return {
                filterId: filter.filterId,
                activeOptions: compact(filter.options.map(
                  (option) => (option.value && settings.assetClass?.includes(option.value as string) && option.id) || undefined
                )),
              }
            }
          }
          return undefined
        })
      )
    }
    const afterFilter = (filters: Filter[], activeFilters:MultiFilter[]) => {
      let newSettings:any = {list: listId, trailingMonths, managerProductFilter}
      filters.forEach((filter) => {
        const currentFilter = activeFilters.find((f) => f.filterId === filter.filterId)
        if (filter.property === "notes") {
          if(currentFilter && currentFilter.activeOptions.length > 0) {
            newSettings["hasNotes"] = true
          } else {
            newSettings["hasNotes"] = false
          }
        } else if (filter.property === "importance") {
          if(currentFilter && currentFilter.activeOptions.length > 0) {
            newSettings["importance"] = compact(currentFilter.activeOptions.map((optionId) => {
              const option = filter.options.find((o) => o.id === optionId)
              return option?.value
            }))
          } else {
            newSettings["importance"] = []
          }
        } else if (filter.property === "date") {
          const timePeriodMapping:{[key:string]: NotableInteractionTimePeriod} = {
            "1 M": NotableInteractionTimePeriod.ONE_MONTH,
            "1 Q": NotableInteractionTimePeriod.ONE_QUARTER,
            "1 y": NotableInteractionTimePeriod.ONE_YEAR,
          }
          if(currentFilter && currentFilter.activeOptions.length > 0) {
            const selectedOption = filter.options.find((o) => o.id === currentFilter.activeOptions[0])
            newSettings["timePeriod"] = timePeriodMapping[selectedOption?.value || ""]
          } else {
            // newSettings["timePeriod"] = NotableInteractionTimePeriod.ONE_YEAR
            newSettings["timePeriod"] = null
          }
        } else if (filter.property === "assetClass") {
          if(currentFilter && currentFilter.activeOptions.length > 0) {
            newSettings["assetClass"] = currentFilter.activeOptions.map((option) => {
              return get(find(ASSET_CLASS_OPTIONS_DATA, {code: option}), 'value')
            })
          } else {
            newSettings["assetClass"] = []
          }
        }
      })
      const input = {
        id: component.id,
        patch: {
          settings: {
            notableInteractions: newSettings
          }
        }
      } as UpdateComponentInput
      updateComponentMutation({ variables: { input: input, liveView: false, draftView: true} })
    }
    return(
      <Col md={12} className="position-inherit">
        <div className="pane dashboard-pane stackable notable-interactions">
          {allInteractions.length === 0 && (
            <>
              <span className="muted-text">
                Notable Interactions will appear here.
              </span>

              <ListGroup className="recent">
                <ListGroupItem className="dummy-recent-item">
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "40%" }}
                  ></div>
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "85%" }}
                  ></div>
                </ListGroupItem>
                <ListGroupItem className="dummy-recent-item">
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "40%" }}
                  ></div>
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "60%" }}
                  ></div>
                </ListGroupItem>
                <ListGroupItem className="dummy-recent-item">
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "35%" }}
                  ></div>
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "60%" }}
                  ></div>
                </ListGroupItem>
                <ListGroupItem className="dummy-recent-item">
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "40%" }}
                  ></div>
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "85%" }}
                  ></div>
                </ListGroupItem>
                <ListGroupItem className="dummy-recent-item">
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "40%" }}
                  ></div>
                  <div
                    className="dummy-recent-item-entry"
                    style={{ width: "65%" }}
                  ></div>
                </ListGroupItem>
              </ListGroup>
            </>
          )}
          <ListGroup className="recent interaction-list" key={`notable-interactions`}>
            <InteractionsSidebar
              auth={auth}
              interactions={allInteractions as InteractionSidebarFragment[]}
              selectedInteraction={"0"}
              selectInteraction={handleSelect}
              source={"Client"}
              componentPiece={true}
              showAssociation={true}
              clientInteractions={true}
              assetClasses={ASSET_CLASS_OPTIONS_DATA}
              settingsToDefaultFilter={settingsToDefaultFilter}
              afterFilter={afterFilter}
              stayOpen={true}
            />
          </ListGroup>
        </div>
      </Col>
    )
  }

  return (<div>NotableInteractionsDisplay WIP</div>)
}

export default NotableInteractions
