import React, { Component } from "react"
import Auth from "../../Auth/Auth"
import { QueryResult } from "@apollo/client"
import { Query } from "@apollo/client/react/components"
import { RouteComponentProps, Switch } from "react-router"
import { useHistory } from "react-router-dom"
import { WithTopNav } from "../ui/LayoutWrapper"
import { appProps } from "../../queries/extended/appProps"
import { IndexBasicsQuery, IndexBasicsQueryVariables, IndexBasicsFragment, IndexBasicsDocument, VehicleStatusCode } from "../../__generated__/graphql"
import LoadingOverlay from "../ui/Loading"
import { Alert, BreadcrumbProps, Col, Container, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledAlert, UncontrolledDropdown } from "reactstrap"
import classnames from "classnames"
import { CrumbRoute } from "../../routes/crumb-route"
import IndexOverview from "./IndexOverview"
import PlaceHolder from "../ui/PlaceHolder"
import ErrorDisplay from "../Shared/ErrorDisplay"

const getActiveTab = (props: any, url: string) => {
  if (props.match.url.indexOf(`${url}/overview`) === 0) {
    return "Overview"
  }
  return "Menu"
}

interface authProps {
  auth: Auth
}

interface IndexRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.indexId
    indexId: string
    type: string
  }> {}

interface IndexOverviewBasicsFragment {
  data: IndexBasicsFragment
}
const IndexDetailComponent: React.FC<appProps & IndexRouteProps & IndexOverviewBasicsFragment> = (props) => {
  const history = useHistory()
  const { match, auth } = props
  const { data, ...otherProps } = props
  if (!data) {
    return (
      <Container fluid>
        <UncontrolledAlert color="danger">
          <h4>404: Index Not Found</h4>
          <p>No Index exists with this id.</p>
        </UncontrolledAlert>
      </Container>
    )
  }

  if (data.__typename !== "Index") {
    return (
      <Container fluid>
        <UncontrolledAlert color="danger">
          <h4>400: Org Not Supported</h4>
          <p>Org type {data.__typename} is not currently supported</p>
        </UncontrolledAlert>
      </Container>
    )
  }

  if(!auth.checkPermissions(["view:all_clients"])) {
    return (
      <Alert color="error">
        <p>Sorry, you do not have permission to visit this url.</p>
      </Alert>
    )
  }

  const { indexId, type } = match.params
  const urlWithoutType = type ? match.url.slice(0, -type.length - 1) : match.url

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline">{data?.name}
              {data?.status?.code === VehicleStatusCode.t && (
                <span className="inactive-tag">Inactive</span>
              )}
            </h2>
            <Nav className="sub-nav sub-nav-primary collapsed" tabs role="index" aria-label="Index Subpage">
              <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                <DropdownToggle caret>{getActiveTab(props, urlWithoutType)}</DropdownToggle>
                <DropdownMenu>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: match.url.indexOf(`${urlWithoutType}/overview`) === 0,
                      })}
                      onClick={() => history.push(`${urlWithoutType}/overview`)}
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Switch>
              <CrumbRoute
                exact
                title="overview"
                path={`${urlWithoutType}/overview`}
                render={(params: BreadcrumbProps) => <IndexOverview indexId={data.id as string} data={data} {...otherProps} match={params.match} />}
              />
            </Switch>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

interface IndexDetailsOverviewQueryChildren {
  (result: QueryResult<IndexBasicsQuery, IndexBasicsQueryVariables> & authProps & IndexRouteProps): React.ReactNode
}

const IndexDetailsOverview: IndexDetailsOverviewQueryChildren = (params) => {
  const { loading, error, data, history, match, location } = params
  const auth = params.auth as Auth
  const routeProps = { history, match, location }
  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <div>
        <p>{error.message}</p>
      </div>
    )
  }
  if (!loading && data) {
    return <IndexDetailComponent data={data.overview as IndexBasicsFragment} auth={auth} {...routeProps} />
  }
}

class QueryIndexDetails extends Component<IndexRouteProps & Partial<IndexBasicsQueryVariables> & appProps> {
  render() {
    const id = decodeURIComponent(this.props.match.params.indexId)

    const { auth, history, location, match } = this.props
    const newProps = { auth, history, location, match }
    return (
      <Query<IndexBasicsQuery, IndexBasicsQueryVariables> query={IndexBasicsDocument} variables={{ id }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
        {(params) => {
          if (!params) {
            return <>Null</>
          }
          const allProps = { ...params, ...newProps }
          if (params.loading) {
            return (
              <Col>
                <div className="pane">
                  <PlaceHolder />
                </div>
              </Col>
            )
          }
          if (params.error) {
            var error=params.error
            return (
              <Col>
                <div className="pane">
                  <UncontrolledAlert color="danger">
                    <h4>Error Fetching Record Keeper</h4>
                    <ErrorDisplay error={error}/>
                  </UncontrolledAlert>
                </div>
              </Col>
            )
          }
          return <>{IndexDetailsOverview(allProps)}</>
        }}
      </Query>
    )
  }
}

export default WithTopNav(QueryIndexDetails)