import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { compact, filter, sortBy } from 'lodash';
import React, { Component } from 'react';
import { Col, DropdownMenu, Form, FormGroup, Input, ListGroup, ListGroupItem, Row, UncontrolledDropdown } from 'reactstrap';
import { useClientAllReportsQuery } from '../../../__generated__/graphql';

interface ReportPickerProps {
  clientId: number
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (reportId:number) => void
}

interface ReportSelection {
  id: number
  name: string
}

const ReportPicker: React.FC<ReportPickerProps> = ({ clientId, title, children, dropdownClass, onClick }: ReportPickerProps) => {
  const { data } = useClientAllReportsQuery({
    variables: { id: clientId }
  })

  let reports = compact((data?.org && ("report" in data?.org)) ? data?.org?.report : []) || []
  let filteredReports = sortBy(filter(reports, (p) => p.active), 'order')

  return (
    <UncontrolledDropdown className={classNames("headline-dropdown product-picker-dropdown", dropdownClass)}>
      {children}
      <DropdownMenu>
        <h5>{title}</h5>
        <ReportSearch key={reports.length} data={filteredReports} onClick={onClick} />
      </DropdownMenu>
    </UncontrolledDropdown>
  )

}

interface ReportSearchProps {
  data:ReportSelection[]
  onClick: (reportId:number) => void

}

export class ReportSearch extends Component<ReportSearchProps> {
  state = {
    reports: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredReports = filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p.name.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      reports: filteredReports
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="6">
            <Form>
              <FormGroup row className="relative m-0">
                <Input
                  type="text"
                  placeholder="Find Report by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          { this.state.reports.map((report) => {
            if (!report) { return <></> }

            return (<ListGroupItem tag="a" key={`pp-report-${report?.id}`} onClick={() => this.props.onClick(report.id)}>{report?.name}</ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

export default ReportPicker