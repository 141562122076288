import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import _ from "lodash"
import React, { FormEvent, Fragment, useEffect, useState } from "react"
import { match } from "react-router"
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom"
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import {
  ProductNarrative,
  ProductProcessQuery,
  useProductProcessQuery,
  useUpdateProductProcessMutation
} from "../../__generated__/graphql"
import { parseDateTime } from "../../helpers/helpers"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import RichTextEditor from "../ui/Editor"
import PlaceHolder from "../ui/PlaceHolder"
import ViewHistory from "../ui/ViewHistory"
import ErrorDisplay from "../Shared/ErrorDisplay"

const ProductOverviewProcess: React.FC<{ productId: number, auth: Auth }> = props => {
  const { productId, auth } = props
  const { loading, error, data } = useProductProcessQuery({
    variables: { id: productId },
    fetchPolicy: "no-cache"
  })
  if (loading) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            <div className="pane pane-toolbar">
              {auth.checkPermissions(["edit:manager"]) &&
                <Button disabled className="ml-auto">
                  <FontAwesomeIcon icon="pen" size="xs" className="mr-2" />
                  Edit
                </Button>
              }
            </div>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <ErrorDisplay error={error}/>
    )
  }
  if (!data?.product) {
    return <p>Product Management Data not found</p>
  }
  return <Display {...data} productId={productId} auth={auth} />
}

const getInitialData = (data: any) => {
  const product = data.product.product
  const { process, ...rest } = product
  const sortedProcess = _.sortBy(process, [el => el?.type?.description])
  const state = { ...rest, process: sortedProcess } as any
  return state
}

const getCodeFromHash = (hash: string | null | undefined) => {
  if (!hash) {
    return ""
  } else {
    return hash.toUpperCase().slice(1)
  }
}

const getActiveTab = (tabs: any[], location: any, match: match<{}>) => {
  // const code = getCodeFromHash(hash)
  if (!match.isExact) {
    let idx = location.pathname.indexOf("process") + 8
    let code = location.pathname.substring(idx).toUpperCase()
    let index = _.findIndex(tabs, { code })
    if (index < 0) {
      index = 0
    }
    return tabs[index]?.code as string
  } else {
    return tabs[0].code as string
  }
}

const getAllTabs = (state: any) => {
  return state?.process.map((el: ProductNarrative) => el?.type)
}

const Display: React.FC<ProductProcessQuery & { productId: number, auth: Auth }> = data => {
  const [currentState, setState] = useState(() => getInitialData(data))
  const [initialState, setInitial] = useState(currentState)
  const [sortedTabs, setData] = useState(getAllTabs(currentState))
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const auth = data.auth

  const [activeTab] = useState(getActiveTab(sortedTabs, location, match))
  const [historyMode, setViewHistoryMode] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [updateProcess] = useUpdateProductProcessMutation()

  useEffect(() => {
    setState(getInitialData(data))
  }, [data])
  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }
  const toggle = (tab: any) => {
    let subPath = `/${tab.toLowerCase()}`
    if (activeTab !== tab) {
      history.push(match.url + subPath)
    }
  }

  const findActiveTab = (tab: any, _: number) => {
    return tab?.type.code === activeTab
  }

  const handleInputChange = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let item = currentState.process[idx]
    let newItem = { ...item, [property]: value }
    let list = currentState.process
    let newList = _.clone(list)
    newList[idx] = newItem
    let newState = { ...currentState, process: newList }
    setState(newState)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    const { id, process } = currentState
    let tab = process.find(findActiveTab)
    let narrative = { type: tab.type.code, body: tab.body }
    let input = { id, patch: { narrative } }
    updateProcess({ variables: { input } })
      .then(result => {
        setSaving(false)
        if (result.data?.updateProductProcess) {
          console.log("success")
          let newData = getInitialData(result.data?.updateProductProcess)
          setState(newData)
          setInitial(newData)
          setEditMode(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log(err.message)
      })
  }
  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid className={"px-0"}>
          <Row>
            <Col>
              <div className="pane pane-toolbar sticky-top">
                {/* <Button color="secondary" className="mr-1" onClick={() => {}}>
                  <FontAwesomeIcon icon="history" size="xs" className="mr-2" />
                  View History
                </Button> */}
                {/* <ViewHistory /> */}
                {auth.checkPermissions(["edit:manager"]) &&
                  <EditButtons 
                    editMode={editMode} 
                    setEditMode={setEditMode} 
                    saving={saving} 
                    onSubmit={handleSubmit}
                    cancelEdit={() => setState(initialState)}
                  />
                }
              </div>
              <div className="pane">
                <Row>
                  <Col sm="3" lg="2">
                    <Nav
                      tabs
                      vertical
                      id="v-tab"
                      role="tablist"
                      className="sub-nav sub-nav-primary border-right"
                    >
                      {sortedTabs.map(
                        (
                          tab: {
                            code: any
                            description: any
                            lastUpdated: any
                          },
                          idx: number
                        ) => {
                          let code = tab?.code
                          return (
                            <NavItem key={idx}>
                              <NavLink
                                className={classnames(
                                  {
                                    active: activeTab === code
                                  },
                                  "text-right",
                                  "pr-2"
                                )}
                                key={idx}
                                aria-controls={`#v-${code}`}
                                onClick={() => {
                                  toggle(code)
                                }}
                              >
                                {tab?.description || tab?.code || ""}
                              </NavLink>
                            </NavItem>
                          )
                        }
                      )}
                    </Nav>
                  </Col>
                  <Col sm="9" lg="10">
                    <TabContent activeTab={activeTab}>
                      {sortedTabs.map((tab: any, idx: number) => {
                        let code = tab?.code
                        const content = currentState.process[idx]
                        const body = content?.body || ""
                        const lastUpdated = content?.lastUpdated || "No update"
                        let property = "body"
                        if (activeTab !== code) {
                          return <Fragment key={idx} />
                        }
                        return (
                          <TabPane tabId={code} key={idx}>
                            <Switch>
                              <Route
                                path={`${location.pathname}/${activeTab}`}
                              ></Route>
                            </Switch>
                            <Row>
                              <Col sm="12">
                                <div>
                                  <div className="d-flex justify-content-between">
                                    <h4 key={`0`}>{tab.description}</h4>
                                  </div>
                                  <p key={`1`}>{editMode && tab.question}</p>
                                  <div key={`EditorWrapper-${idx}`}>
                                    <RichTextEditor
                                      value={body}
                                      editMode={editMode}
                                      updateValue={value => {
                                        handleInputChange(
                                          idx,
                                          value,
                                          "text",
                                          property
                                        )
                                      }}
                                    />
                                  </div>
                                  <span>{body && parseDateTime(lastUpdated)}</span>
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                        )
                      })}
                    </TabContent>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}

export default ProductOverviewProcess
