import React, { useState } from "react"
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps"
import { Tooltip } from "react-tooltip"
import { numbroFormatter } from "../../helpers/helpers"
import { AssetsByRegion } from "../../__generated__/graphql"
import worldMap from "./worldmap.geo.json"

type LookupName = {
  data: AssetsByRegion[]
}

type RegionDefinition = {
  code: string
  location: Array<number>
  color: string
}

const Regions:RegionDefinition [] = [
  {code: "AME", location: [18.573328247754397, 15.276276076983166], color: "#00466E"},
  {code: "AUL", location: [134.31428644171018, -25.76309564920502], color: "#006B6B"},
  {code: "CAN", location: [-99.68077976858608, 54.63260265246353], color: "#669966"},
  {code: "CHN", location: [103.45282980829866, 36.683499543987566], color: "#9FB400"},
  {code: "DEN", location: [9.885528537142234, 56.05921342457645], color: "#F28015"},
  {code: "EAFE", location: [79.54023670885476, 22.81923513721499], color: "#D25C1B"},
  {code: "EEU", location: [31.450152828187154, 48.99850059267209], color: "#616971"},
  {code: "EMKT", location: [-28.700250, -13.791858], color: "#06354F"},
  {code: "EXUK", location: [14.061123562530051, 47.62101037800562], color: "#5F0004"},
  {code: "FIN", location: [26.141951248220614, 64.26143440113948], color: "#5F96FF"},
  {code: "FRA", location: [-6.811515603828783, 43.13595840601362], color: "#0069A0"},
  {code: "GBL", location: [-34.52525, 41.12595], color: "#6B90AD"},
  {code: "GER", location: [10.270439759445987, 51.07731333800242], color: "#003E36"},
  {code: "HK", location: [114.206656, 22.440918], color: "#00AAAA"},
  {code: "ITA", location: [12.268697154318005, 42.67073633766635], color: "#8BC4C3"},
  {code: "JPN", location: [137.71140307877872, 37.535745094693596], color: "#A3C2A3"},
  {code: "LA", location: [-58.07361898472568, -14.806559605422809], color: "#DDDA23"},
  {code: "NET", location: [5.500559775874431, 52.29334985179333], color: "#DDDA23"},
  {code: "NOR", location: [12.82945862076669, 66.64817833001503], color: "#B2595D"},
  {code: "OAXJP", location: [101.00279480162826, 14.980983213678885], color: "#91B7FF"},
  {code: "OEU", location: [27.96491053237833, 53.49530760463369], color: "#006B6B"},
  {code: "OTH", location: [77.633757, -22.793793], color: "#669966"},
  {code: "SIN", location: [109.69993922984062, 3.7463482018965664], color: "#F28015"},
  {code: "SPA", location: [-3.624326269454891, 40.320489962603645], color: "#9FB400"},
  {code: "SWE", location: [16.108935740135564, 62.41834091126858], color: "#D25C1B"},
  {code: "SWI", location: [8.115739312522004, 46.79305119772097], color: "#06354F"},
  {code: "UK", location: [-2.759268662772829, 53.8082990875911], color: "#1F6CFF"},
  {code: "USA", location: [-99.02204397238303, 39.28649409577129], color: "#5F96FF"},
]

export const WorldMap: React.FC<LookupName> = ({ data }) => {
  const [content, setContent] = useState<any>("");
  const TooltipId = "map-tooltip"
  return (
    <>
      <ComposableMap>
        <Geographies geography={worldMap.features}>
          {({ geographies }) =>
            <>
            {geographies.map(geo => (
              <Geography
                key={geo.rsmKey}
                stroke="#FFF"
                geography={geo}
                fill="#DDD"
              />
            ))}
            {Regions.map(region => {
              const {code, location, color} = region
              const referenceData = data.find(region => region.region.code == code)
              const content = (
                <div className="d-flex flex-column">
                  <text x={-100} y={5} fontSize={14} fill='black'>{referenceData?.region.value}</text>
                  <text x={-100} y={19} fontSize={14} fill='black'>Assets: {numbroFormatter(referenceData?.assetsUnderManagement,'$0,0')}M</text>
                  <text x={-100} y={33} fontSize={14} fill='black'>Accounts: {referenceData?.numberOfAccounts}</text>
                </div>
                )
              return (
                <g key={code + "-name"}>
                  <Marker coordinates={[location[0],location[1]]}
                  >
                    <circle
                      onMouseEnter={() => {
                        setContent(content)
                      }}
                      onMouseLeave={() => {
                        setContent(<React.Fragment key={"blank"}></React.Fragment>)
                      }}
                      r={Math.log2(referenceData?.assetsUnderManagement || 1)}
                      fill={color}
                      fillOpacity="0.4"
                      stroke={color}
                      strokeWidth="1"
                      strokeOpacity="0.8"
                      data-tooltip-id={TooltipId}
                    />
                  </Marker>
                </g>
              );
            })}
          </>
          }
        </Geographies>
      </ComposableMap>
      <Tooltip id={TooltipId}>
        {content}
      </Tooltip>
    </>
  )
}


