import React, { useState, Component } from 'react'

import { get, cloneDeep, set, isNil, unset, pick, compact, findIndex, uniqBy } from 'lodash'
import { Table, Row, Col, Button } from 'reactstrap'
import omitDeep from 'omit-deep-lodash'

import { CashFlows, VehiclePerformanceCashflowQuery, CashFlowTransactionType, CashFlowTransaction, CashFlowCashActivity, VehiclePerformanceCashflowPerformanceQuery, CashFlowPerformance, CashFlowsInput, CashFlowTransactionInput, ClosedEndedPrivateNonRegisteredMulitAssetClass, ClosedEndedPrivateNonRegisteredPrivateCredit, ClosedEndedPrivateNonRegisteredPrivateEquity, ClosedEndedPrivateNonRegisteredRealAssets, CashFlowNetAssetValue, AssetClassAbbreviationCode, DeleteCashFlowTransactionInput, UniqueTransactionTypeCode, CashFlowDefaultKey, UniqueTransactionTypeCodeMapItems, VehiclePerformanceOpenCashflowQuery,  } from '../../../__generated__/graphql'

import PlaceHolder from '../../ui/PlaceHolder'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import moment from 'moment'
import { FormInput } from '../../ui/Forms/FormInput'
import { RecursivePartial } from '../../../helpers/typeFunctions'
import numbro from 'numbro'
import { VEHICLE_PERFORMANCE_CASHFLOWS_PERFORMANCE } from '../../../queries/Vehicles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type AllowedVehicleType = ClosedEndedPrivateNonRegisteredMulitAssetClass | ClosedEndedPrivateNonRegisteredPrivateCredit | ClosedEndedPrivateNonRegisteredPrivateEquity | ClosedEndedPrivateNonRegisteredRealAssets

interface ProductPerformanceVehiclesOpenEndedValuationsProps {
  id: string
  editMode: boolean
  date: string
  data: VehiclePerformanceOpenCashflowQuery
  handleChange: (newDiff:CashFlowsInput) => void
  removeTransaction: (removedTransactionInput:DeleteCashFlowTransactionInput) => void
  editCancelled: boolean
  clearChanges: boolean
}

interface ProductPerformanceVehiclesState {
  stateDiff: CashFlowsInput
  removedInteractions: DeleteCashFlowTransactionInput[]
}

class ProductPerformanceVehiclesOpenEndedValuations extends Component<ProductPerformanceVehiclesOpenEndedValuationsProps,ProductPerformanceVehiclesState> {
  constructor(props: ProductPerformanceVehiclesOpenEndedValuationsProps) {
    super(props)

    this.state = {
      stateDiff: {},
      removedInteractions: []
    }
  }

  static getDerivedStateFromProps(nextProps:ProductPerformanceVehiclesOpenEndedValuationsProps, prevState:ProductPerformanceVehiclesState){
    if(nextProps.editCancelled || nextProps.clearChanges) {
      return { stateDiff: {} };
    }

    return null;
  }

  setStateDiff = (cashFlowInput:CashFlowsInput) => {
    this.setState({stateDiff: cashFlowInput}, () => {
      let newInput = cloneDeep(cashFlowInput)
      newInput.cashFlowTransactionTypes = newInput?.cashFlowTransactionTypes?.map(transactionType => {
        let newTransactionType = cloneDeep(transactionType)

        if (isNil(newTransactionType)) {
          return null
        }

        newTransactionType.transactions = newTransactionType?.transactions?.map(transaction => {
          let newTransaction = cloneDeep(transaction)
          if ((newTransaction?.transactionNumber && newTransaction?.transactionNumber <= 0) || isNil(newTransaction?.transactionNumber)) {
            unset(newTransaction, 'transactionNumber')
          }
          return newTransaction
        })
        return newTransactionType
      }) || []

      console.log('fire parent change', newInput)
      this.props.handleChange(newInput)
    })
  }

  handleActivityChange = (property:string, value: number) => {

    let cashFlowInput = cloneDeep(this.state.stateDiff)
    set(cashFlowInput, property, value)
    set(cashFlowInput, "cashFlowCashActivity.periodEndDate", this.props.date)
    // let cashFlowInput = omitDeep(updatedCashflow, '__typename')
    // set(cashFlowInput, 'beginningNetAssetValue.currency', updatedCashflow.beginningNetAssetValue?.currency?.code)
    // set(cashFlowInput, 'beginningNetAssetValue.country', updatedCashflow.beginningNetAssetValue?.country?.code)
    // set(cashFlowInput, 'endingNetAssetValue.currency', updatedCashflow.endingNetAssetValue?.currency?.code)
    // set(cashFlowInput, 'endingNetAssetValue.country', updatedCashflow.endingNetAssetValue?.country?.code)

    // cashFlowInput?.cashFlowTransactionTypes?.map((transactionType:CashFlowTransactionType, ind:number) => {
    //   set(cashFlowInput, `cashFlowTransactionTypes[${ind}].transactionType`, transactionType.transactionType.code)
    //   transactionType?.transactions?.map((transaction:Maybe<CashFlowTransaction>, ind2:number) => {
    //     set(cashFlowInput, `cashFlowTransactionTypes[${ind}].transactions[${ind2}].currency`, transaction?.currency.code)
    //   })
    // })

    // console.log({cashFlowInput})
    this.setStateDiff(cashFlowInput as CashFlowsInput)
  }

  render() {
    const { editMode, date, data, editCancelled } = this.props

    const vehicle = data.vehicle_open_cashflows?.vehicle
    const product = vehicle?.product

    console.log({ vehicle, product})
    if (vehicle && product ) {
      console.log('render cashflow')
      return (
        <CashflowDisplay
          key={`cashflow-${vehicle.id}-display-${date}-${editCancelled}`}
          editMode={editMode}
          cashflows={vehicle.cashFlows as CashFlows || null}
          vehicleType={vehicle.__typename}
          handleActivityChange={this.handleActivityChange}
          date={date}
          vehicleId={vehicle.id}
          editCancelled={editCancelled}
        />
      )

    }

    return <></>
  }
}

interface CashflowDisplayProps {
  editMode: boolean
  cashflows: CashFlows | null
  vehicleType: string
  handleActivityChange: (property:string, value:number) => void
  date: string
  vehicleId: string
  editCancelled: boolean
}

interface CashflowDisplayState {
  currentState: CashFlows
  initialState: CashFlows
}

class CashflowDisplay extends Component<CashflowDisplayProps,CashflowDisplayState> {
  constructor(props: CashflowDisplayProps) {
    super(props)

    let cashflows:CashFlows = {
      beginningNetAssetValue: props.cashflows?.beginningNetAssetValue || null,
      endingNetAssetValue: props.cashflows?.endingNetAssetValue || null,
      cashFlowTransactionTypes: props.cashflows?.cashFlowTransactionTypes || [],
      cashFlowCashActivity: props.cashflows?.cashFlowCashActivity || null,
      __typename: "CashFlows"
    }

    this.state = {
      currentState: cashflows,
      initialState: cashflows
    }
  }

  static getDerivedStateFromProps(nextProps:CashflowDisplayProps, prevState:CashflowDisplayState){
    if(nextProps.editCancelled) {
      return { currentState: nextProps.cashflows };
    }

    return null;
  }

  handleActivityUpdate = (property:keyof CashFlowCashActivity, value:number) => {
    let newState = cloneDeep(this.state.currentState)
    let newActivity = cloneDeep(newState.cashFlowCashActivity)

    if (!newActivity) {
      newActivity = { __typename: 'CashFlowCashActivity', periodEndDate: this.props.date}
    }
    set(newActivity, property, value)

    newState.cashFlowCashActivity = newActivity
    this.setState({ currentState: newState },() => {
      this.props.handleActivityChange(`cashFlowCashActivity.${property}`, value)
    })
  }

  render() {
    const cashflows = this.state.currentState
    const { editMode } = this.props

    return(
      <>
      <div className="pane pane-table">
        <Row>
          <Col md="6">
            <ValuationsTable
              editMode={editMode}
              cashFlowCashActivity={cashflows.cashFlowCashActivity}
              updateActivity={this.handleActivityUpdate}
              vehicleType={this.props.vehicleType}
            />

            <CashActivityTable
              editMode={editMode}
              cashFlowCashActivity={cashflows.cashFlowCashActivity}
              updateActivity={this.handleActivityUpdate}
              vehicleType={this.props.vehicleType}
              />

          </Col>
        </Row>
      </div>
    </>
    )
  }
}

interface ValuationsTableProps {
  cashFlowCashActivity?: RecursivePartial<CashFlowCashActivity> | null
  editMode: boolean
  updateActivity: (property:keyof CashFlowCashActivity, value:number) => void
  vehicleType: string
}
const ValuationsTable = (props:ValuationsTableProps) => {
  const { editMode } = props
  const [cashFlowCashActivity, setCashFlowCashActivity] = useState(props.cashFlowCashActivity)

  const onChange = (property:keyof CashFlowCashActivity, value:number) => {
    let newActivity = cloneDeep(cashFlowCashActivity) || {}
    set(newActivity, property, value)
    setCashFlowCashActivity(newActivity)
    props.updateActivity(property, value)
  }

  return (
    <>
    <h4 className="mt-4">Valuation</h4>
    <Table size="sm" className="table-condensed">
      <tbody>
        <tr>
          <td className="text-left">Gross Asset Value ($)</td>
          <td>
            <FormInput
              idx="valuation-grossAssetValue"
              property="valuation-grossAssetValue"
              displayName=''
              type='float'
              subtype='currency'
              editMode={editMode}
              propertyVal={cashFlowCashActivity?.grossAssetValue}
              updateValue={(v) => onChange('grossAssetValue', v)}
            />
          </td>
        </tr>
          <tr>
            <td className="text-left">Net Asset Value ($)</td>
            <td>
              <FormInput
                idx="valuation-netAssetValue"
                property="valuation-netAssetValue"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.netAssetValue}
                updateValue={(v) => onChange('netAssetValue', v)}
              />
            </td>
          </tr>
      </tbody>
    </Table>
    </>
  )
}

interface CashActivityTableProps {
  cashFlowCashActivity?: RecursivePartial<CashFlowCashActivity> | null
  editMode: boolean
  updateActivity: (property:keyof CashFlowCashActivity, value:number) => void
  vehicleType: string
}
const CashActivityTable = (props:CashActivityTableProps) => {
  const { editMode } = props
  const [cashFlowCashActivity, setCashFlowCashActivity] = useState(props.cashFlowCashActivity)

  const onChange = (property:keyof CashFlowCashActivity, value:number) => {
    let newActivity = cloneDeep(cashFlowCashActivity) || {}
    set(newActivity, property, value)
    setCashFlowCashActivity(newActivity)
    props.updateActivity(property, value)
  }

  return (
    <>
      <h4 className="mt-5">Cash Activity</h4>
      <Table size="sm" className="table-condensed mb-0">
        <tbody>
          <tr>
            <td className="text-left">Cash Value ($)</td>
            <td className="text-right">
              <FormInput
                idx="valuation-cashValue"
                property="valuation-cashValue"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.cashValue}
                updateValue={(v) => onChange('cashValue', v)}
              />
            </td>
          </tr>
          <tr>
            <td className="text-left">Entry Queue ($)</td>
            <td className="text-right">
              <FormInput
                idx="valuation-entryQueue"
                property="valuation-entryQueue"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.entryQueue}
                updateValue={(v) => onChange('entryQueue', v)}
              />
            </td>
          </tr>
          <tr>
            <td className="text-left">Exit Queue ($)</td>
            <td className="text-right">
              <FormInput
                idx="valuation-exitQueue"
                property="valuation-exitQueue"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.exitQueue}
                updateValue={(v) => onChange('exitQueue', v)}
              />
            </td>
          </tr>

        </tbody>
      </Table>
    </>
  )
}


export default ProductPerformanceVehiclesOpenEndedValuations