import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import { FormInputSubClasses } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { FormInput } from "../../ui/Forms/FormInput"

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  optionSource?: string
  tdClass?: string
  tooltip?: { icon: string; id: string }
}

interface FinancingTypeTableItemProps {
  data: any
  rowDefItem: RowDefItem
  highlightRow?: boolean
  idx: number
  row: number
  editMode: boolean
  updateValue: (idx: number, type: string, value: any, property: string) => void
}

type FinancingTypeTableProps = {
  data: any
  handleChange: (state: any, property: string) => void
  editMode?: boolean
}

export const FinancingTypeTableRowDef: RowDefItem[] = [
  {
    field: "shortTerm",
    type: "float",
    title: "Short Term",
    tooltip: {
      icon: "question-circle",
      id: "financingTypeShortTermTooltip",
    },
  },
  {
    field: "longTerm",
    type: "float",
    title: "Long Term",
    tooltip: {
      icon: "question-circle",
      id: "financingTypeLongTermTooltip",
    },
  }
]

const ManagementColDef: RowDefItem[] = [
  {
    field: "used",
    type: "checkbox",
    subtype: "boolean",
    title: "Used",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "limit",
    type: "float",
    subtype: "percent",
    title: "Financing Limit",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "basis.code",
    type: "select",
    optionSource: "FinancingTypeBasisCode",
    title: "On",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    tdClass: "wide"
  }
]

interface FinancingTypeTableRowProps {
  data: any
  index: number
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

const FinancingTypeTableRow = ({
  data,
  index,
  rowDef,
  editMode,
  updateValue
}: FinancingTypeTableRowProps) => {
  let field = rowDef.field
  let tooltip = rowDef.tooltip
  const value = _.get(data, field)
  if (_.isUndefined(value)) {
    return <tr key={`${index}`} className={"invisible"}></tr>
  }
  let title = tooltip ? 
  (<div className={`d-flex align-items-center tooltip-icon`} id={tooltip.id}>
    {rowDef.title}
    <FontAwesomeIcon
      icon={"question-circle" as IconName}
      size="sm"
      className="ml-auto"
    />
  </div>
  ): rowDef.title
  return (
    <tr key={`${index}`}>
      <td key={`FinancingType-${field}`} className={"text-left"}> 
        {title}
      </td>
      {ManagementColDef.map((col, colIndex) => {
        const data = _.get(value, col.field)
        return (
          <FinancingTypeTableItem
            data={data}
            rowDefItem={rowDef}
            key={colIndex}
            idx={colIndex}
            row={index}
            editMode={editMode}
            updateValue={updateValue}
          />
        )
      })}
    </tr>
  )
}

const FinancingTypeTableItem = ({
  data,
  rowDefItem,
  idx,
  row,
  editMode,
  updateValue
}: FinancingTypeTableItemProps) => {
  let { field } = rowDefItem
  let { type, subtype, subClasses, optionSource, tdClass } = ManagementColDef[idx]
  subtype = subtype || ""
  let displayIdx = idx
  if (field === "shortTerm") {
    displayIdx = displayIdx + 100
  }
  let property = field + "." + ManagementColDef[idx]?.field
  return (
    <td key={`${field}-${idx}`} className={tdClass}>
      <FormInput
        property={property + "ft"}
        displayName={""}
        type={type}
        subtype={subtype}
        idx={row}
        editMode={editMode}
        propertyVal={data}
        placeholder={type === "number" ? "0" : ""}
        subClasses={subClasses}
        optionSource={optionSource}
        updateValue={value => updateValue(idx, value, type, field)}
      />
    </td>
  )
}

const FinancingTypeTable = (seed: FinancingTypeTableProps) => {
  const tableName = "Management"
  const widthClassName = "col-sm-7"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)

  useEffect(() => {
    setData(seed.data)
  }, [seed.data])

  if (!data) {
    return <div>No data</div>
  }

  const onHandleChangeManagementRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData =
      getNewStateObject({
        state: tableState,
        newValue: value,
        property: `${property}.${ManagementColDef[idx]?.field}`,
        type
      }) || {}
    setData(newData)
    handleChange(_.get(newData, property), property)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size w-100 py-2 mb-2"
        }
        key={`${tableName}-0`}
      >
        {`Financing Type`}
      </div>
      <div
        className={`${tableName}-table ${widthClassName} row form-group pl-3`}
      >
        <Table striped hover borderless size="sm" key={`FinancingType-table`}className={"exportable"} data-export-name={"Financing type table"}>
          <thead>
            <tr className="table-header">
              <th key={0}>Type</th>
              {ManagementColDef.map((header, headerIdx) => (
                <th key={headerIdx + 1}>{header.title}</th>
              ))}
            </tr>
          </thead>
          <tbody key={0}>
            {FinancingTypeTableRowDef.map((rowDef, rowIndex) => {
              return (
                <FinancingTypeTableRow
                  index={rowIndex}
                  key={rowIndex}
                  data={tableState}
                  rowDef={rowDef}
                  editMode={!!editMode}
                  updateValue={onHandleChangeManagementRow}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default FinancingTypeTable
