import { History } from 'history'
import { first } from 'lodash'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Container, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledAlert, UncontrolledDropdown } from 'reactstrap'

import { default as classnames, default as classNames } from 'classnames'
import { MeFragment, ReportsFragment, useMeQuery, useReportDetailQuery } from '../../__generated__/graphql'
import Auth from '../../Auth/Auth'
import { isCallanUser } from '../../helpers/person'
import { expandReportList, listReportExpanded } from '../../helpers/report'
import { useDocumentTitle } from '../../helpers/usehook'
import { ReportDisplayType } from '../Report/Shared/ReportComponent'
import ReportMain from '../Report/Shared/ReportMain'
import { matchProps } from '../Shared/Header'
import { MatchParams, WithTopNav } from '../ui/LayoutWrapper'
import ReportPicker from '../ui/Pickers/ReportPicker'
import PlaceHolder from '../ui/PlaceHolder'
import { SumSheet } from './SumSheet'

type idProps = {
  history: History
  auth: Auth
  match: matchProps<MatchParams>
}

const ReportDetail: React.FC<idProps> = ({ history, auth, match }) => {
  const reportId = parseInt(match.params.reportId || "")
  const sumSheet = match.params.portfolioId === `sumsheet`
  const { setTitle, resetTitle } = useDocumentTitle()

  const { loading, data, error } = useReportDetailQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: reportId, liveView: true, draftView: true },
    skip: !auth.checkPermissions(['view:all_clients']),
  })

  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined
  const isCallan = userData ? isCallanUser(userData) : false

  useEffect(() => {
    if (data?.report?.client?.name && data?.report?.name) {
      setTitle(`${data.report.client?.name} - ${data.report.name}`)
    } else if (data?.report?.name ) {
      setTitle(data?.report?.name)
    }

    return () => {
      resetTitle()
    }
  }, [data])

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline mb-4">Report</h2>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null || !auth.checkPermissions(['view:all_clients'])) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline mb-4">Report</h2>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline mb-4">Report</h2>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!!data && data.report ) {
    const report = data.report
    const matchedReport = report.id === reportId
    let showList:listReportExpanded[] | undefined = undefined
    if (isCallan && report.draftList){
      showList = expandReportList(report.draftList)
    } else if (report.liveList) {
      showList = expandReportList(report.liveList)
    }

    const urlWithType = match.params.portfolioId
      ? match.url.slice(0, -match.params.portfolioId.length - 1)
      : match.url
    const urlWithoutType = match.params.reportId
      ? urlWithType.slice(0, -match.params.reportId.length - 1)
      : urlWithType

    if(!showList) {
      return (
        <Container fluid>
          <Row>
            <Col>
              <h2 className="headline mb-4">{report.name}</h2>
              <div className='pane pane-table'>
                <UncontrolledAlert color="danger">
                  <h4>Can't find List to view</h4>
                </UncontrolledAlert>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <Container fluid className={classNames({'d-flex flex-grow-1 flex-direction-column': sumSheet})}>
        <Row>
          <Col xs="10" sm="8" md="6">
            <Breadcrumb>
              <BreadcrumbItem
                className="headline-breadcrumbs"
                key="test"
                onClick={() =>
                  history.push(
                    `/reports`
                  )
                }
              >
                All Reports
              </BreadcrumbItem>
            </Breadcrumb>
            {data.report.client?.id && (
              <ReportPicker
                key={data.report.id}
                clientId={data.report.client?.id}
                title={`View another report for ${data.report.client?.name || ""}`}
                onClick={(report) => {
                  history.push(
                    `/reports/${report}`
                  )
                }}
              >
                <div className='dropdown'>
                  <DropdownToggle caret className='mb-3'>
                    <h2 className="headline">
                      {data.report.client?.name || ""} - {data.report.name || ""}
                    </h2>
                  </DropdownToggle>
                </div>
              </ReportPicker>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav className="sub-nav sub-nav-primary collapsed" tabs role="group" aria-label="Report Subpage">
              <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                <DropdownToggle caret>
                  { sumSheet ? "Sumsheet" : "Report Editor" }
                </DropdownToggle>
                <DropdownMenu>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: !sumSheet
                      })}
                      onClick={() => history.push(`${urlWithType}/`)}
                    >
                      Report Editor
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: sumSheet
                      })}
                      onClick={() => history.push(`${urlWithType}/sumsheet`)}
                    >
                      Sumsheet
                    </NavLink>
                  </NavItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Col>
        </Row>
        <Switch>
          <Route
            exact
            path={`${urlWithType}/sumsheet`}
            render={() =>
              <SumSheet
                listId={data.report?.draftList?.id || 0}
                reportId={reportId}
                planId={first(data.report?.plans)?.id || 0}
                auth={auth}
              />
            }
          />
          <Route
            exact
            path={`${urlWithType}/:portfolioId`}
            render={(params) =>
              <ReportHolder
                list={showList || []}
                match={params.match}
                clientId={data.report?.client?.id || 0}
                reportId={reportId}
                history={history}
                auth={auth}
                user={user}
                report={report}
              />}
            >
          </Route>
          {matchedReport && (
            <Redirect
              to={`${urlWithType}/${first(showList)?.id}`}
            />
          )}
          {!matchedReport && (
            <PlaceHolder />
          )}
        </Switch>

      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

interface ReportHolderProps {
  clientId: number
  reportId: number
  history: History
  auth: Auth
  match: {params: {portfolioId?: string}}
  user?: MeFragment
  list: listReportExpanded[]
  report: ReportsFragment
}

function ReportHolder({
  list,
  history,
  user,
  auth,
  clientId,
  reportId,
  match,
  report,
  }: ReportHolderProps) {

  const portfolioId = match.params.portfolioId
  return (
    <Row>
      <ReportMain
        portfolioId={portfolioId ? parseInt(portfolioId) : undefined}
        clientId={clientId}
        reportId={reportId}
        history={history}
        auth={auth}
        list={list}
        user={user}
        view={ReportDisplayType.Draft}
        report={report}
      />
    </Row>
  )
}


export default WithTopNav(ReportDetail)
