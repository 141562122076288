import { useHistory } from 'react-router'
import { match } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Container, DropdownToggle, Row, UncontrolledAlert } from 'reactstrap'
import Auth from '../../Auth/Auth'
import { WithTopNav } from '../ui/LayoutWrapper'
import ResearchProductsPicker from '../ui/Pickers/ResearchProductsPicker'
import {
  ResearchProductStrategyTypeScript as Script,
  ResearchProductStrategyTypeSearchType as SearchType
} from './ResearchProductConfig'
import ResearchProductMain from './ResearchProductMain'
import { ResearchProductsRouteProps } from './ResearchProducts'

export type ResearchProductDetailsProps = {
  auth: Auth
  location: Location
  match: match
}

const ResearchProductDetails = (props: ResearchProductDetailsProps & ResearchProductsRouteProps) => {
  const history = useHistory()
  const { match, auth } = props

  const { type } = match.params
  const currentTitle = Script[type as SearchType].title

  if(!auth.checkPermissions(["view:research_products"])) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline mb-4">Report</h2>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <Col xs='10' sm='8' md='6'>
                <Breadcrumb>
                  <BreadcrumbItem className='headline-breadcrumbs' key='test' onClick={() => history.push(`/research`)}>
                    {`Research Products`}
                  </BreadcrumbItem>
                </Breadcrumb>
                <ResearchProductsPicker
                  title={`View another category`}
                  onClick={(type) => {
                    window.location.href = `/research/${type}`
                  }}
                >
                  <div className='dropdown'>
                    <DropdownToggle caret>
                      <h2 className='headline'>{currentTitle}</h2>
                    </DropdownToggle>
                  </div>
                </ResearchProductsPicker>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ResearchProductMain key={type} {...props} />
    </>
  )
}

export default WithTopNav(ResearchProductDetails)
