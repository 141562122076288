import React, { Component } from 'react';
import { filter, compact } from 'lodash'
import { UncontrolledDropdown, DropdownMenu, Row, Col, Form, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useManagerBreadcrumbGlidePathsQuery, BreadcrumbGlidePathFragment } from '../../../__generated__/graphql'
import classNames from 'classnames'

interface GlidePathSeriesProps {
  managerId: number
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (product_id:number) => void
}

const GlidePathSeriesPicker: React.FC<GlidePathSeriesProps> = ({ managerId, title, children, dropdownClass, onClick }: GlidePathSeriesProps) => {
  const { data } = useManagerBreadcrumbGlidePathsQuery({
    variables: { id: managerId }
  })

  let glidePathSeries = compact((data?.org && ("glidePaths" in data.org)) ? data?.org?.glidePaths : []) || []

  return (
    <UncontrolledDropdown className={classNames("headline-dropdown product-picker-dropdown", dropdownClass)}>
      { children }
      <DropdownMenu>
        <h5>{title}</h5>
        <GlidePathSeriesSearch key={glidePathSeries.length} data={glidePathSeries} onClick={onClick} />
      </DropdownMenu>
    </UncontrolledDropdown>
  )

}

interface GlidePathSeriesSearchProps {
  data: BreadcrumbGlidePathFragment[]
  onClick: (product_id:number) => void

}

export class GlidePathSeriesSearch extends Component<GlidePathSeriesSearchProps> {
  state = {
    series: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredSeries = filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p?.name?.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      series: filteredSeries
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="6">
            <Form>
              <FormGroup row className="relative m-0">
                <Input
                  type="text"
                  placeholder="Find Glide Path by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          { this.state.series?.map((s, idx) => {
            if (!s || !s.dataCollect) { return <React.Fragment key={idx}></React.Fragment> }

            return (<ListGroupItem tag="a" key={`gpsp-series-${s?.id || idx}`} onClick={() => this.props.onClick(s.id)}>{s?.name}</ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

export default GlidePathSeriesPicker