import React from "react"
import { Container, Row, Col, Nav, NavLink, NavItem } from "reactstrap"

export default function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col>
            <p className="m-0">
              Callan LLC &copy; {new Date().getFullYear()}{" "}
              <a
                className="ml-2 text-white"
                href="https://www.callan.com/dna-service-terms/"
                target={"_blank"}
                rel={"noreferrer"}
              >
                Terms of Service
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
