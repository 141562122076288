import _ from "lodash"
import React from "react"
import {
  Col,
  Row,
} from "reactstrap"

interface ErrorDisplayProps {
    error: Error
  }

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({error}: ErrorDisplayProps) => {
  if (error) {
    console.error(error.message)
    return (
      <Row>
        <Col>
          <div className="pane pane-table">
            <p>{"An error has occurred, please refresh the page. If the problem persists, please try again later, or reach out to webinfo@callan.com."}</p>
          </div>
        </Col>
      </Row>
    )
  }
  return <div>data doesn't exist</div>
}

  export default ErrorDisplay