import auth0 from "auth0-js"
import jwtDecode from "jwt-decode"
import {
  domain,
  clientID,
  audience,
  redirectUri,
  auth0Namespace,
} from "../environment"
import { RouteComponentProps, StaticContext } from "react-router"

interface AuthToken {
  "https://api.callan.com/claims/permissions": any[]
  iss: string
  sub: string
  aud: string[]
  iat: number
  exp: number
  azp: string
  scope: string
  permissions: string[]
}

export default class Auth {
  userProfile: auth0.Auth0UserProfile | null
  requestedScopes = "openid profile permissions"
  tokenRenewalTimeout: number | undefined

  auth0 = new auth0.WebAuth({
    domain,
    clientID,
    redirectUri,
    audience,
    responseType: "token id_token",
    scope: this.requestedScopes,
  })

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.silentAuthentication = this.silentAuthentication.bind(this)
    this.scheduleRenewal()
    this.userProfile = null
    this.tokenRenewalTimeout = undefined
  }

  login() {
    this.auth0.authorize()
  }

  handleAuthentication(props: RouteComponentProps<any, StaticContext, any>) {
    this.auth0.parseHash((err, authResult) => {
      let location = sessionStorage.getItem("callback-url") || "/"
      sessionStorage.removeItem("callback-url")
      if (location === "/") {
        location = "/"
      }
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
        props.history.replace(location)
      } else if (err) {
        console.error(err)
        alert(`Error: ${err.error}. Check the console for further details.`)
      }
    })
  }

  silentAuthentication() {
    var redirectUrl = window.location.protocol + "//" + window.location.hostname
    if (window.location.port !== "80" && window.location.port !== "443") {
      redirectUrl = redirectUrl + ":" + window.location.port
    }
    redirectUrl = redirectUrl + "/silent-callback.html"
    this.auth0.renewAuth(
      {
        audience: process.env.REACT_APP_AUTH0_API_URL,
        scope: this.requestedScopes,
        redirectUri: redirectUrl,
        usePostMessage: true,
      },
      (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult)
        } else if (err) {
          console.error(err)
          alert(`Error: ${err.error}. Check the console for further details.`)
        }
      }
    )
  }

  setSession(authResult: any) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    )
    // If there is a value on the `scope` param from the authResult,
    // use it to set scopes in the session for the user. Otherwise
    // use the scopes as requested. If no scopes were requested,
    // set it to nothing
    const scopes = authResult.scope || this.requestedScopes || ""

    localStorage.setItem("access_token", authResult.accessToken)
    localStorage.setItem("id_token", authResult.idToken)
    localStorage.setItem("expires_at", expiresAt)
    localStorage.setItem("scopes", JSON.stringify(scopes))
    localStorage.setItem(
      "authorization",
      JSON.stringify(authResult.idTokenPayload[auth0Namespace])
    )
    this.scheduleRenewal()
  }

  getAccessToken() {
    const accessToken = localStorage.getItem("access_token")
    if (!accessToken) {
      throw new Error("No access token found")
    }
    return accessToken
  }

  async renewToken() {
    return await new Promise<boolean>((resolve, reject) => {
      this.auth0.checkSession({ redirectUri }, (err, result) => {
        if (err) {
          console.log(err)
          resolve(false)
        } else this.setSession(result)
        resolve(true)
      })
    })
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem("expires_at") || "{}")
    const delay = expiresAt - Date.now()
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken()
      }, delay) as any
    }
  }

  getProfile(cb: Function) {
    if (this.userProfile) {
      cb(null, this.userProfile)
    }
    let accessToken = this.getAccessToken()
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile
      }
      cb(err, profile)
    })
  }

  checkPermissions(permissionsToCheck: string[]): boolean {
    const accessToken = jwtDecode<AuthToken>(this.getAccessToken())
    const userPermissions = accessToken.permissions
    return permissionsToCheck.every(
      (permission) => userPermissions.indexOf(permission) !== -1
    )
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token")
    localStorage.removeItem("id_token")
    localStorage.removeItem("expires_at")
    localStorage.removeItem("scopes")
    sessionStorage.removeItem("perspective")
    sessionStorage.removeItem("date")
    this.userProfile = null
    // navigate to the home route
    clearTimeout(this.tokenRenewalTimeout)
    this.auth0.logout({
      clientID,
      returnTo: window.location.origin,
    })
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at") || "{}")
    return new Date().getTime() < expiresAt
  }
}
