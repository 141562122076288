import { Query } from '@apollo/client/react/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { History, Location } from 'history'
import { compact, differenceBy, find, set, union } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'

import { FileBasicInfoFragment, GetInteractionQuery, GlidePathInteractionNoteFields, InteractionFragment, OrganizationInteractionNotesFragment, OrgInteractionNoteFields, ProductInteractionNoteFields, ProductInteractionNotesFragment, UpdateFileDataInput, UpdateFileMetadataInput, useAddInteractionAssociationMutation, useDeleteInteractionProductNoteMutation, useDownloadInteractionNoteLazyQuery, useGlidePathDocumentsQuery, useGlidePathInteractionsQuery, useMeQuery, useRemoveInteractionAssociationMutation, useUpdateFileMetadataMutation, useUpsertInteractionOrgNoteMutation, useUpsertInteractionProductNoteMutation } from '../../__generated__/graphql'
import Auth from '../../Auth/Auth'
import { diffOrgNote, diffProductNote, IsSameProductInteractionNote, productInteractionNoteHasChanged, toOrgInteractionNoteFields, toProductInteractionNoteFields } from '../../helpers/interaction'
import { GET_INTERACTION } from '../../queries/Interaction'
import { ASSET_CLASS_OPTIONS_DATA, InteractionDisplay, InteractionsSidebar } from '../Org/ManagerInteractions'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import EditButtons from '../ui/EditButtons'
import PlaceHolder from '../ui/PlaceHolder'
import { Association } from '../Associations/AssociationsList'
import _ from 'lodash'
import ErrorDisplay from '../Shared/ErrorDisplay'

type idProps = {
  glidePathId: number
  managerId: number
  history: History
  location: Location
  auth: Auth
}

type NoteChangeTypes = "organizationNotes" | "allProductNotes"

interface StateChangeStoreType {
  organizationNotes: OrgInteractionNoteFields | null
  allProductNotes: OrgInteractionNoteFields | null
  productNotes: ProductInteractionNoteFields[]
  glidePathNote: GlidePathInteractionNoteFields[]
}

const GlidePathInteractions: React.FC<idProps> = ({ managerId, glidePathId, history, location, auth }) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [search, setSearch] = useState("")

  const params = useParams() as { id?: string }
  const getInitialInteractionId = (id:string) => {
    const interactionId = id || ""
    if (!interactionId) {
      return "0"
    }else {
      return interactionId
    }
  }

  const [selectedInteractionId, setSelectedInteractionId] = useState(()=>getInitialInteractionId(params.id || ""))
  const [currentInteraction, setCurrentInteraction] = useState<InteractionFragment|null>(null)
  // avoid unnecessary forceUpdate CAL-1653
  const [forceUpdateFlag, setForceUpdateFlag] = useState<boolean>(false)

  const initialStateChangeStore = {
    organizationNotes: null,
    allProductNotes: null,
    productNotes: [],
    glidePathNote: []
  }

  const [noteIdsToDelete, setNoteIdsToDelete] = useState(new Set<number>())
  const [stateChangeStore, setStateChangeStore] = useState<StateChangeStoreType>(initialStateChangeStore)
  const resetStateChangeStore = () => setStateChangeStore(initialStateChangeStore)

  const [upsertInteractionOrgNote] = useUpsertInteractionOrgNoteMutation()
  const [upsertInteractionProductNotes] = useUpsertInteractionProductNoteMutation()
  const [deleteInteractionProductNote] = useDeleteInteractionProductNoteMutation()
  const [addInteractionsMutation] = useAddInteractionAssociationMutation()
  const [removeInteractionsMutation] = useRemoveInteractionAssociationMutation()
  const [updateFileMutation] = useUpdateFileMetadataMutation()

  const { loading, error, data } = useGlidePathInteractionsQuery({
    fetchPolicy: "cache-first",
    variables: { id: glidePathId }
  })

  const { data: documentsData } = useGlidePathDocumentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { id: glidePathId },
    errorPolicy: "all",
  })

  // const [getInteractionUrl, {loading: urlLoading, data: urlData, error: urlError}] = useDownloadInteractionNoteLazyQuery({
  //   fetchPolicy: "network-only"
  // });

  const { loading:userLoading, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || null

  useEffect(()=>{
    let newId = getInitialInteractionId(params.id ||"")
    if(newId !== selectedInteractionId) {
      setSelectedInteractionId(newId)
    }
  }, [params.id])

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:interactions"])){
      return
    }
    setSaving(true)
    setForceUpdateFlag(true)
    const submitProducts = stateChangeStore.organizationNotes || stateChangeStore.allProductNotes
    const initialProductNotes: ProductInteractionNotesFragment[] = compact(currentInteraction?.notes?.specificProductNotes || [])
    const currentProductNotes: ProductInteractionNoteFields[] = compact(stateChangeStore.productNotes)
    const submitAll = currentProductNotes.length > 0 || Array.from(noteIdsToDelete).length > 0
    const associations = InteractionDisplay.generateAllInteractions(currentInteraction).concat({__typename: "Manager", id: managerId, name: ""})

    // Find the list of associations attached to all documents
    currentInteraction?.documentAssociations.forEach((document) => {
      const managerAssociations:Association[] = compact(document.managers).map(m => { return { __typename: m?.__typename, id: m?.id, name: m?.name || '' } })
      const products = compact(document.products?.map(p => p?.product))
      const productAssociations: Association[] = products.map(p => { return { __typename: p?.__typename, id: p?.id, name: p?.name } })
      const glidePathAssociations: Association[] = compact(document.glidePaths).map(g => { return { __typename: g?.__typename, id: g?.id, name: g?.name || '' } })
      const addAssociations = differenceBy(associations, [...managerAssociations, ...productAssociations, ...glidePathAssociations] || [], (association:Association) => {
        return `${association.__typename}:${association.id}`
      })
      if(addAssociations.length > 0 && currentInteraction){
        let updateData = {
          managers: compact(union(document.managers?.map((ob:any) => ob.id), currentInteraction.otherManagerAssociations.map((ob:any) => ob.id), [currentInteraction.primaryManagerAssociation?.id])),
          products: compact(union(document.products?.map((ob:any) => ob.product.id), currentInteraction.productAssociations.map((ob:any) => ob.product.id))),
          glidePaths: compact(union(document.glidePaths?.map((ob:any) => ob.id), currentInteraction.glidePathAssociations.map((ob:any) => ob.id))),
        } as UpdateFileDataInput

        const input = { id: document.id, patch: updateData } as UpdateFileMetadataInput

        updateFileMutation({ variables: { input } })
          .catch(err => {
            console.log("Error Update File Metadata", err.message)
          })
      }
    })
    // update Organization Notes and All Associated Products Notes
    if (submitProducts) {
      const initialOrgNotes: OrganizationInteractionNotesFragment | null = currentInteraction?.notes?.organizationNotes || null
      const initialAllProductNotes: OrganizationInteractionNotesFragment | null = currentInteraction?.notes?.allProductNotes || null
      let submissionData = {
        interactionId: selectedInteractionId,
        organizationNotes: diffOrgNote(initialOrgNotes, stateChangeStore.organizationNotes),
        allProductNotes: diffOrgNote(initialAllProductNotes, stateChangeStore.allProductNotes)
      }

      upsertInteractionOrgNote({ variables: { input: submissionData } })
        .then(result => {
          if (result && result.data) {
            if(!submitAll){
              setEditMode(false)
              setSaving(false)
            }
          }
        })
        .catch(err => {
          console.log("Error upserting org note", err.message)
          setSaving(false)
        })
    }

    // update Product Specific Notes
    if (submitAll) {
      const notesToUpsert: ProductInteractionNoteFields[] = []
      initialProductNotes.forEach((initialNote: ProductInteractionNotesFragment) => {
        const updatedNote: ProductInteractionNoteFields | undefined = find(currentProductNotes, current => IsSameProductInteractionNote(initialNote, current))
        if (updatedNote && productInteractionNoteHasChanged(initialNote, updatedNote)){
          notesToUpsert.push(diffProductNote(initialNote, updatedNote))
        }
        // if (!updatedNote) {
        //   initialNote.summary?.id && noteIdsToDelete.push(initialNote.summary.id)
        //   initialNote.observation?.id && noteIdsToDelete.push(initialNote.observation.id)
        //   initialNote.internalOpinion?.id && noteIdsToDelete.push(initialNote.internalOpinion.id)
        // }
      })
      currentProductNotes.forEach((current: ProductInteractionNoteFields) => {
        const isNew = !find(initialProductNotes, initialNote => IsSameProductInteractionNote(initialNote, current))
        if (isNew) notesToUpsert.push(current)
      })
      let noteIdsArray = Array.from(noteIdsToDelete)
      //CAL-1587  do not send empty array to graphql api if there's nothing to update.
      if(notesToUpsert.length > 0){
        let productNotesSubmissionData = {
          interactionId: selectedInteractionId,
          productNotes: notesToUpsert,
        }
        // delete/upsert modified note sections
        Promise.all([
          upsertInteractionProductNotes({ variables: { input: productNotesSubmissionData } }),
          ...noteIdsArray.map(noteID => deleteInteractionProductNote({ variables: {input: {id: noteID}}}))
        ])
        .then(results => {
          if(results[0] && results[0].data){
            setEditMode(false)
            setSaving(false)
          }
        })
        .catch(err => {
          console.log("Error updating product notes", err.message)
          setSaving(false)
        })
      }else if(noteIdsToDelete.size > 0){
        Promise.all([...noteIdsArray.map(noteID => deleteInteractionProductNote({ variables: {input: {id: noteID}}}))
        ])
        .then(results => {
          if(results[0] && results[0].data){
            setEditMode(false)
            setSaving(false)
          }
        })
        .catch(err => {
          console.log("Error updating product notes", err.message)
          setSaving(false)
        })
      }else {
        // no upsert or delete, exit saving and editMode
        console.log("no productSpecific notes saving, exit")
        setForceUpdateFlag(false)
        setSaving(false)
        setEditMode(false)
      }
    }

    if (!submitProducts && !submitAll) {
      setForceUpdateFlag(false)
      setSaving(false)
      setEditMode(false)
    }
  }

  const handleChange = (notes: OrganizationInteractionNotesFragment | null, noteType: NoteChangeTypes) => {
    let stateStore = stateChangeStore
    const hasInternalOpinion = auth.checkPermissions(["edit:interactions_private"])
    stateStore[noteType] = toOrgInteractionNoteFields(notes, hasInternalOpinion)
    setStateChangeStore(stateStore)
  }

  const handleProductNoteChange = (prodNotes:ProductInteractionNotesFragment[]) => {
    let stateStore = stateChangeStore
    const hasInternalOpinion = auth.checkPermissions(["edit:interactions_private"])
    const newProdNotes: ProductInteractionNoteFields[] = compact(prodNotes.map(note => {
      return toProductInteractionNoteFields(note, hasInternalOpinion)
    }))
    set(stateStore, 'productNotes', newProdNotes)
    setStateChangeStore(stateStore)
  }

  const handleProductNoteDelete = (noteIds: number[]) => {
    let newSet = _.clone(noteIdsToDelete)
    noteIds.forEach(id => newSet.add(id))
    setNoteIdsToDelete(newSet)
  }

  const selectInteraction = (id:string) => {
    // setSelectedInteractionId(id)
    if(selectedInteractionId === "" || selectedInteractionId === "0"){
      history.replace(`/glidepaths/${glidePathId}/interactions/${id}`)
    } else {
      history.push(`/glidepaths/${glidePathId}/interactions/${id}`)
    }
  }

  const cancelEditMode = () => {
    resetStateChangeStore()
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="mr-2 pr-3 border-right" onSubmit={e=>e.preventDefault()}>
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        {auth.checkPermissions(["edit:interactions"]) &&
          <EditButtons
            editMode={editMode}
            setEditMode={setEditMode}
            saving={saving}
            onSubmit={handleSubmit}
            cancelEdit={cancelEditMode}
          />
        }
      </div>
    </>
  )

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error) {
    setCurrentInteraction(null)
    return (
      <Container fluid>
        {heading}
          <ErrorDisplay error={error}/>
      </Container>
    );
  }

  if (!loading && !!data && data.glidePath?.__typename === 'GlidePath') {
    const interactions = compact(data.glidePath.interactions) || []
    const showInternalOpinion = auth.checkPermissions(["view:interactions_private"])
    return (
      <Container fluid>
        {heading}
        <Row>
            <InteractionsSidebar
              auth={auth}
              interactions={interactions}
              assetClasses={ASSET_CLASS_OPTIONS_DATA}
              selectInteraction={(id:string) => selectInteraction(id)}
              selectedInteraction={selectedInteractionId}
              search={search}
              source={"glidePath"}
            />
            <Query<GetInteractionQuery> query={GET_INTERACTION} variables={{ id: selectedInteractionId, showInternal: showInternalOpinion }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
              {
                (results) => {
                  if (results.loading) {
                    return (
                      <Col md="8" lg="9" className="pl-1">
                        <div className="pane">
                          <PlaceHolder />
                        </div>
                      </Col>
                    )
                  }

                  if (results.error) {
                    return (
                      <Col md="8" lg="9" className="pl-1">
                        <div className="pane">
                          <UncontrolledAlert color="danger">
                            <h4>Error Fetching Interaction</h4>
                            <p>{results.error.message}</p>
                          </UncontrolledAlert>
                        </div>
                      </Col>
                    )
                  }

                  const interaction = results.data?.interaction
                  console.log(interaction)
                  // fix update inside render warning
                  if (!interaction) {
                    return (
                      <Col md="8" lg="9" className="pl-1">
                        <div className="pane">
                          <Row>
                            <Col>
                              <h3>No Interactions Listed</h3>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )
                  }
                  return(<InteractionDisplay
                    id={selectedInteractionId}
                    editMode={editMode}
                    interaction={interaction}
                    notEditable={!auth.checkPermissions(["edit:interactions"])}
                    setCurrentInteraction={setCurrentInteraction}
                    onChange={handleChange}
                    onProductNoteChange={handleProductNoteChange}
                    onProductNoteDelete={handleProductNoteDelete}
                    user={user}
                    setEditMode={setEditMode}
                    refetchQuery={(variables?: Record<string, any> | undefined) => {
                      resetStateChangeStore()
                      return results.refetch(variables)
                    }}
                    forceUpdate={forceUpdateFlag}
                    auth={auth}
                    addInteractionsMutation={addInteractionsMutation}
                    removeInteractionsMutation={removeInteractionsMutation}
                    updateFileMutation={updateFileMutation}
                    associationType="GlidePath"
                    associationId={data.glidePath?.id}
                    // do not show export button for GlidePath
                    // downloadUrl={getInteractionUrl}
                    // urlLoading={urlLoading}
                    // urlError={urlError}
                    // urlData={urlData}
                    searchableDocuments={documentsData?.glidePath?.documents as FileBasicInfoFragment[]}
                  />)
                }
              }
            </Query>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

export default GlidePathInteractions
