import classnames from 'classnames'
import React, { Component, useState, MouseEvent, useEffect } from 'react'
import {Row, Col, Button, Modal, ModalBody, ModalHeader, ListGroup, ListGroupItem, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Form, FormGroup, Input, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from 'moment'
import _ from "lodash"

import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from "../../helpers/constant"
import RichTextEditor from "../ui/Editor"
import { statusColor } from '../../helpers/helpers'
import { Fact, ManagerNoteFragment, Opinion, Person, ProductOpinion, User} from '../../__generated__/graphql'
import DOMPurify from 'dompurify';
import EmployeePicker from "../ui/Pickers/EmployeePicker"
import { ClientsLookup, WRITEUPS_ANCHOR_LIST } from '../Org/ManagerWriteUps'
import classNames from 'classnames'
import Auth from '../../Auth/Auth'

interface WriteUpProps {
  type: "fact" | "opinion" | "callanRepOpinion" | "dnaNote" | "legacyNote"
  writeUp: Fact | Opinion | ProductOpinion | ManagerNoteFragment
  editMode: boolean
  // code: ResearchCategoryCode
  // auth: any
  // managerType?: ManagerType
  currentUser?: User
  handleUpdate: (value: any,property: string) => void
  handleManagerNoteDelete?: () => void
  handleFocus: () => void
  openHistory: () => void
  edited: boolean
  version: number // for opinion/productOpinion only
  clientList?: boolean
  clients?: ClientSelection[]
  subtype?: "header"| "editor" // for callan Rep *Opinion* upper/lower section
}

export interface ClientSelection {
  id: number
  name?: string | null
  type?: {
    code: string
    value?: string
  }
}

export const typeMapping = { k: "Mutual Fund", s: "Representative", q: "CIT" } as {[code: string]: string}

const WriteUpComponent: React.FC<WriteUpProps> = ({ writeUp: inWriteUp, editMode, handleManagerNoteDelete, handleUpdate, handleFocus, edited, version, type, currentUser, clients, clientList , subtype}: WriteUpProps) => {

  const [statusOpen, setStatusOpen] = useState(false)
  const [typeOpen, setTypeOpen] = useState(false)
  // if changed, default open.
  const [collapsed, setCollapsed] = useState(!edited)
  const [clientOpen, setClientOpen] = useState(false)

  const statusToggle = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    event?.stopPropagation()
    if(editMode){
      handleFocus()
    }
    setStatusOpen(!statusOpen)
  }

  const typeToggle = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    event?.stopPropagation()
    handleFocus()
    setTypeOpen(!typeOpen)
  }

  const clientToggle = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    event?.stopPropagation()
    handleFocus()
    setClientOpen(!clientOpen)
  }

  const selectClient = (client:ClientSelection) => {
    clientToggle()
    handleUpdate(client, "client")
  }

  let writeUp = inWriteUp
  if(inWriteUp && inWriteUp.__typename === "ProductOpinion" && inWriteUp.opinions){
    writeUp = inWriteUp.opinions // transfer type from productOpinion to Opinion
  }

  const managerNoteAuthorDetails = () => {
    // note author can't be changed by user.
    let note = writeUp as ManagerNoteFragment
    let isLegacyNote = !!note.legacy
    let authorName = ""
    let author: Person | undefined | null = null
    let date = ""

    // https://callanllc.atlassian.net/browse/CAL-1707?focusedCommentId=56787
    // if there's updatedBy or updateDate
    if(_.get(note, "updatedBy") || _.get(note, "updateDate")) {
      if(_.get(note, "updatedBy")) {
        author = _.get(note, "updatedBy")
        authorName = _.compact([author?.firstName, author?.lastName]).join(' ')
      }else {
        author = null
        authorName = "Unknown"
      }
      if(_.get(note, "updateDate")) {
        date = moment(_.get(note, 'updateDate')).format('MMM D, YYYY')
      }else {
        date = ""
      }
      return (
        <span className="transparent-bg">
          {isLegacyNote? "": "Last updated by "}
          { authorName } on {date}
        </span>
      )
    }

    if (_.get(note, "author") && !author){
      // never updated note has only author property
      author = _.get(note, "author")
      authorName =author?  _.compact([author?.firstName, author?.lastName]).join(' ') : "Unknown"
      date = _.get(note, 'createDate')? moment(_.get(note, 'createDate')).format('MMM D, YYYY'): "Unknown Date"
      return (
        <span className="transparent-bg">
          {isLegacyNote? "": "Last updated by "}
          { authorName } on {date}
        </span>
      )
    } else {
      date = _.get(note, 'createDate')? moment(_.get(note, 'createDate')).format('MMM D, YYYY'): "Unknown Date"

      // no author & no updatedBy
      if (!editMode) {
        authorName = "Unknown"
        return (
        <span className="transparent-bg">
          { authorName } on {date}
        </span>)
      }
      authorName =currentUser?  _.compact([currentUser?.firstName, currentUser?.lastName]).join(' ') : ""
      if (!authorName) {
        //  could be a not-yet-submit new note.
        if(type === "dnaNote"){
          authorName = "Select Author"
        }else if(type === "legacyNote") {
          // could be a legacy note without info.
          authorName = "Unknown"
        }
      }
      console.log(111, "no author, no update")
      return (
        <span className="transparent-bg">
          { authorName } on {date}
        </span>)
    }
  }

  const authorDetails = () => {
    if (!_.get(writeUp, 'author')) {
      let authorName = _.compact([currentUser?.firstName, currentUser?.lastName]).join(' ')
      if (!authorName) {
        authorName = "Select Author"
      }

      if (!editMode) {
        if(type === "callanRepOpinion" && subtype) {
          return (<span className="transparent-bg"><div></div></span>)
        }
        return (<span className="transparent-bg"></span>)
      }

      return (
        <EmployeePicker
          managerId={244}
          onClick={(person) => { handleUpdate(person, "author")}}
        >
          <span>
            <DropdownToggle className="author-picker btn-no-style">
              { authorName }
              &nbsp;
              <FontAwesomeIcon icon="caret-down" />
            </DropdownToggle>
          </span>
        </EmployeePicker>

      )
    }

    const noteAuthorChange = moment(_.get(writeUp, 'date'))
    const noteAuthor = _.get(writeUp, 'author', {} as Person)
    let authorName = "Author"
    if (noteAuthor?.firstName || noteAuthor?.lastName) {
      authorName = _.compact([noteAuthor?.firstName, noteAuthor?.lastName]).join(' ')
    }

    if (!editMode) {
      return (
        <span className="transparent-bg">
          Last updated by { authorName } on {noteAuthorChange.format('MMM D, YYYY')}
        </span>
      )
    }

    return (
      <EmployeePicker
        managerId={244}
        onClick={(person) => {handleFocus; handleUpdate(person, "author")}}
      >
        <span className="transparent-bg">
          Last updated by
          <DropdownToggle className="author-picker btn-no-style">
            { authorName }
            &nbsp;
            <FontAwesomeIcon icon="caret-down" />
          </DropdownToggle>
          on {noteAuthorChange.format('MMM D, YYYY')}
      </span>
      </EmployeePicker>
    )
  }

  const removeManagerNote = (event:MouseEvent) => {
    event.stopPropagation()
    if(type === "dnaNote" && handleManagerNoteDelete) {
      let note = writeUp as ManagerNoteFragment
      handleManagerNoteDelete()
      return
    }
  }

  if(type === "fact" && (!writeUp || writeUp.__typename === "Fact")){
    return(
      <>
        <div className="writeup-fact-container mt-2" id={WRITEUPS_ANCHOR_LIST[0].anchorId}>
          <div className="d-flex justify-content-between align-items-center px-2 writeup-fact-header-container">
            <h3 className="writeup-fact-header headline my-3">{WRITEUPS_ANCHOR_LIST[0].title}</h3>
            <div className={classnames({"text-right my-2 text-gray-80 author-detail": true, ".fadein-background-blue": edited})} onClick={handleFocus}>
              {authorDetails()}
            </div>
          </div>
          <div className="writeup-fact-contents">
            <div className="writeup-fact-contents-item" onClick={handleFocus}>
              {editMode && <h5 className={classnames("headline underline py-2 px-3")}>{WRITEUPS_ANCHOR_LIST[0].title.toUpperCase()}</h5>}
              <RichTextEditor
                key={"fact-text"}
                identifier={"fact-text"}
                value={writeUp?.text || ""}
                editMode={editMode}
                updateValue={value => {
                  handleUpdate(
                    value,
                    "text"
                  )
                }}
                // onFocus={() => handleFocus()}
                stopAutoFocus={true}
                toolbarOnFocus={true}
                placeholder={"None to show"}
                expandable={true}
                />
            </div>
            <div className="writeup-fact-contents-item" onClick={handleFocus}>
              {editMode && <h5 className={classnames("headline underline py-2 px-3")}>{WRITEUPS_ANCHOR_LIST[0].title.toUpperCase()} - Bullet list</h5>}
              <RichTextEditor
                key={"fact-bullets"}
                identifier={"fact-bullets"}
                value={writeUp?.bullets || ""}
                editMode={editMode}
                updateValue={value => {
                  handleUpdate(
                    value,
                    "bullets"
                  )
                }}
                // onFocus={() => handleFocus()}
                stopAutoFocus={true}
                toolbarOnFocus={true}
                placeholder={"None to show"}
                expandable={true}
                />
            </div>
          </div>
        </div>
      </>
    )
  } else if ((type === "opinion" || type === "callanRepOpinion") && (!writeUp || writeUp.__typename === "Opinion")){
    const clientPicker = () => {
      if(writeUp?.__typename === "Opinion"){
        const currentClient = writeUp.client?.name
        const currentType = (inWriteUp?.__typename === "ProductOpinion" && ` - ${typeMapping[inWriteUp.vehicle?.code || "s"]}`) || ""
        if(!editMode || !clientList){
          return `${currentClient}${currentType}`
        }

        let sortedClients = clients
        let allClients:ClientSelection[] = []
        if(!_.isUndefined(clients)){
          sortedClients = _.sortBy(clients, [(client)=>(client?.name||"").toLowerCase()])
          let {client244, client16467, client18737} = ClientsLookup
          if(inWriteUp?.__typename === "ProductOpinion") {
            if(inWriteUp.vehicle?.code === "s") {
              // hide callan-internal if representative is selected.
              allClients = [ client16467 as ClientSelection, client18737 as ClientSelection, ...sortedClients||[]]
            }else{
              allClients = [client244 as ClientSelection, client16467 as ClientSelection, client18737 as ClientSelection, ...sortedClients||[]]
            }
          }else {
            // opinion doesn't need callan-internal
            allClients = [client16467 as ClientSelection, client18737 as ClientSelection, ...sortedClients]
          }
        }
        if(edited && version === 1){
          // This is the creation of the writeup
          return(
            <>
              <ButtonDropdown
                isOpen={clientOpen}
                toggle={clientToggle}
                className={classnames("headline-dropdown client-picker-dropdown")}
              >
                <DropdownToggle caret={editMode}>
                  { currentClient }
                </DropdownToggle>
                <DropdownMenu onClick={(event)=>event.stopPropagation()}>
                  {!!allClients && <ClientSearch data={allClients} onClick={selectClient} /> }
                </DropdownMenu>
              </ButtonDropdown>
              {inWriteUp?.__typename === "ProductOpinion" &&
               <ButtonDropdown
                isOpen={typeOpen && editMode}
                toggle={typeToggle}
                className={classnames("mx-1 headline-dropdown vehicle-type-dropdown", {"has-error": !inWriteUp.vehicle?.code})}>
                <DropdownToggle caret={editMode}>
                  {inWriteUp?.__typename === "ProductOpinion" && !inWriteUp.vehicle?.code && "Choose Vehicle Type"}
                  {inWriteUp?.__typename === "ProductOpinion" && inWriteUp.vehicle?.code && typeMapping[inWriteUp.vehicle?.code]}
                </DropdownToggle>
                <DropdownMenu onClick={(event)=>event.stopPropagation()}>
                  <DropdownItem onClick={(e:any) => handleUpdate({code:"s", value:"Representative", __typename:"WriteupVehicleLookup"}, "vehicle")}>
                    Representative
                  </DropdownItem>
                  <DropdownItem onClick={(e:any) => handleUpdate({code:"q", value:"CIT", __typename:"WriteupVehicleLookup"}, "vehicle")}>
                    CIT
                  </DropdownItem>
                  <DropdownItem onClick={() => handleUpdate({code:"k", value:"Mutual Fund", __typename:"WriteupVehicleLookup"}, "vehicle")}>
                    Mutual Fund
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
              }
            </>
          )
        }
        return (
          `${currentClient}${currentType}`
        )
      } else {
        return <></>
      }
    }
    let opinionTextContent = (
      <div className="writeup-opinion-contents" onClick={handleFocus}>
        <h5 className="headline underline py-2 px-3">MERITS</h5>
        <RichTextEditor
          key={"opinion-meritsText"}
          identifier={`opinion-meritsText-${writeUp?.id}`}
          value={writeUp?.meritsText || ""}
          editMode={editMode}
          updateValue={value => {
            handleUpdate(
              value,
              "meritsText"
            )
          }}
          // onFocus={() => handleFocus()}
          stopAutoFocus={true}
          toolbarOnFocus={true}
          placeholder={"None to show"}
          expandable={true}
        />
        <h5 className="headline underline py-2 px-3">MERITS - Bullet List</h5>
        <RichTextEditor
          key={"opinion-meritsBullets"}
          identifier={`opinion-meritsBullets-${writeUp?.id}`}
          value={writeUp?.meritsBullets || ""}
          editMode={editMode}
          updateValue={value => {
            handleUpdate(
              value,
              "meritsBullets"
            )
          }}
          // onFocus={() => handleFocus()}
          stopAutoFocus={true}
          toolbarOnFocus={true}
          placeholder={"None to show"}
          expandable={true}
        />
        <h5 className="headline underline py-2 px-3">CONSIDERATIONS</h5>
        <RichTextEditor
          key={"opinion-considerationsText"}
          identifier={`opinion-considerationsText-${writeUp?.id}`}
          value={writeUp?.considerationsText || ""}
          editMode={editMode}
          updateValue={value => {
            handleUpdate(
              value,
              "considerationsText"
            )
          }}
          // onFocus={() => handleFocus()}
          stopAutoFocus={true}
          toolbarOnFocus={true}
          placeholder={"None to show"}
          expandable={true}
        />
        <h5 className="headline underline py-2 px-3">CONSIDERATIONS - Bullet list</h5>
        <RichTextEditor
          key={"opinion-considerationsBullets"}
          identifier={`opinion-considerationsBullets-${writeUp?.id}`}
          value={writeUp?.considerationsBullets || ""}
          editMode={editMode}
          updateValue={value => {
            handleUpdate(
              value,
              "considerationsBullets"
            )
          }}
          // onFocus={() => handleFocus()}
          stopAutoFocus={true}
          toolbarOnFocus={true}
          placeholder={"None to show"}
          expandable={true}
        />
      </div>
    )
    const buttonStatus = (writeUp:Opinion | undefined, wrapperClass?: string) => (
      <ButtonDropdown isOpen={statusOpen && editMode} toggle={statusToggle} disabled={!editMode} className={classnames("mr-1", {"has-error": writeUp && !writeUp?.status?.code})}>
        {/* show error when status.code is not selected by user. */}
        {(editMode || (!editMode && writeUp?.status?.value)) && (<DropdownToggle caret={editMode} className={classnames("btn-status d-flex", wrapperClass? wrapperClass : "", "justify-content-start")}>
          <FontAwesomeIcon icon="circle" className={`mr-2 text-${statusColor(writeUp?.status?.value || "")} ml-0`}/>
          <span className="expand-text">{writeUp?.status?.value}</span>
        </DropdownToggle>)}
        <DropdownMenu onClick={(event)=>event.stopPropagation()}>
          <DropdownItem onClick={() => handleUpdate({code:"_1", value:"Within Expectations", __typename:"statusLookup"}, "status")}>
            <FontAwesomeIcon icon="circle" className={`mr-2 text-${statusColor("Within Expectations")}`} />
            Within Expectations
          </DropdownItem>
          <DropdownItem onClick={() => handleUpdate({code:"_2", value:"Notable", __typename:"statusLookup"}, "status")}>
            <FontAwesomeIcon icon="circle" className={`mr-2 text-${statusColor("Notable")}`} />
            Notable
          </DropdownItem>
          <DropdownItem onClick={() => handleUpdate({code:"_3", value:"Cautionary", __typename:"statusLookup"}, "status")}>
            <FontAwesomeIcon icon="circle" className={`mr-2 text-${statusColor("Cautionary")}`} />
            Cautionary
          </DropdownItem>
          <DropdownItem onClick={() => handleUpdate({code:"_4", value:"Under Review", __typename:"statusLookup"}, "status")}>
            <FontAwesomeIcon icon="circle" className={`mr-2 text-${statusColor("Under Review")}`} />
            Under Review
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      )
    if (type === "callanRepOpinion") { // tweak layout for callan rep opinion
      if(subtype === "header") {
        return (
          <>
            {buttonStatus(writeUp)}
            <div className={classnames({"text-gray-80 text-right author-detail": true})} onClick={handleFocus}>
              {authorDetails()}
            </div>
          </>
        )
      }else if (subtype === "editor") {
        return opinionTextContent
      }else {
        console.log('like team opinion.')
      }
    }

    return(
      <>
        <div className="p-2 mb-1 ml-2 writeup-opinion-row">
          <div className="pr-2 justify-content-between align-items-center writeup-opinion-row-item row">
            <div onClick={() => setCollapsed(!collapsed)} className="client-wrapper">
              <h3 className="client headline cursor-pointer my-0">
                <FontAwesomeIcon className="mx-2" icon={collapsed ? "caret-right" : "caret-down"} />
                {clientPicker()}
              </h3>
            </div>
              {buttonStatus(writeUp, "transparent-bg")}
            <div className={classnames({"text-gray-80 text-right": true}, "author-detail mr-2")} onClick={handleFocus}>
              {authorDetails()}
            </div>
          </div>
          <Collapse isOpen={!collapsed} className="mb-2">
            {opinionTextContent}
          </Collapse>
        </div>
      </>
    )
  } else if (writeUp.__typename === "ManagerNote"){
    if (type !== "dnaNote") {
      return (
        <div className="writeup-legacy-note-row fadein">
          <div className={classNames("justify-content-between align-items-center writeup-legacy-note-row-item")}>
          <div className="d-flex justify-content-between align-items-center title-underline mb-2 py-1 pr-2">
              {editMode ? (<h5 className={classnames("headline my-0 px-3")}>LEGACY NOTE - Not editable</h5>): (<h5 className={classnames("headline my-0 px-3")}>LEGACY NOTE</h5>)}
              <div className={classnames({"text-right my-2 text-gray-80 author-detail": true})}>
                {managerNoteAuthorDetails()}
            </div>
          </div>
          <RichTextEditor
            key={`legacy-${writeUp?.id}`}
            identifier={`legacy-${writeUp?.id}`}
            value={writeUp?.body || ""}
            editMode={editMode}
            updateValue={() => {}}
            stopAutoFocus={true}
            toolbarOnFocus={true}
            placeholder={"None to show"}
            expandable={true}
            readOnly={true} //!!! important
          />
          </div>
        </div>)
    }else {
      return (
      <div className="writeup-dna-note-row fadein">
        <div className={classNames("justify-content-between align-items-center writeup-dna-note-row-item")} onClick={handleFocus}>
          <div className="d-flex justify-content-between align-items-center title-underline mb-2 py-1 pr-2">
            <h5 className={classnames("headline my-1 px-3")}>
              NOTE
              {editMode && (<FontAwesomeIcon className="ml-2" icon="trash" onClick={removeManagerNote}/>)}
            </h5>
            <div className={classnames({"text-right my-1 text-gray-80 author-detail": true})}>
              {managerNoteAuthorDetails()}
          </div>
        </div>
        <RichTextEditor
          key={`dnaNote-${writeUp?.id}`}
          identifier={`dnaNote-${writeUp?.id}`}
          value={writeUp?.body || ""}
          editMode={editMode}
          updateValue={value =>
            handleUpdate(
              value,
              "body"
            )
          }
          stopAutoFocus={true}
          toolbarOnFocus={true}
          placeholder={"None to show"}
          expandable={true}
        />
        </div>
      </div>)
    }
  } else {
    return(<div>Write-Up is not a Fact or Opinion</div>)
  }
}

interface ClientSearchProps {
  data: ClientSelection[]
  onClick: (product:ClientSelection) => void
}

interface ClientSearchState {
  clients: ClientSelection[]
  search: string
}

export class ClientSearch extends Component<ClientSearchProps, ClientSearchState> {
  state = {
    clients: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredPeople = _.filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p?.name?.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      clients: filteredPeople
    })
  }

  render() {
    return (
      <>
        <Row onClick={(e)=>e.stopPropagation()}>
          <Col sm="12">
            <Form>
              <FormGroup row className="relative m-0 pt-1">
                <Input
                  type="text"
                  placeholder="Find Client by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          { this.state.clients?.map((client) => {
            if (!client) { return <></> }
            return (<ListGroupItem
              tag="a"
              key={`pp-client-${client?.id}`}
              onClick={(event:React.MouseEvent<any, globalThis.MouseEvent>) => {
                event.stopPropagation()
                this.props.onClick(client)
              }}
              className={classNames({"divider": client?.id === 18737})}
            >
              {client?.name}
            </ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

interface HistoricalProps{
  historyId: number
  show: boolean
  toggle: (value: boolean) => void
  selectWriteUp: (id:number) => void
  writeUpList: Fact[] | Opinion[] | ProductOpinion[]
  handleDelete: (writeUp: Fact | Opinion) => void
  editMode: boolean
  auth?: Auth
  deleting: boolean
}

interface WriteUpHistoryState {
  writeUpList: Fact[] | Opinion[] | ProductOpinion[]
  deleting: boolean
  deleteModalOpen: boolean
  sortedWriteUpList:  Fact[] | Opinion[] | ProductOpinion[]
}

export class WriteUpHistory extends Component<HistoricalProps, WriteUpHistoryState> {
  constructor(props: HistoricalProps) {
    super(props)
    this.sortWriteUpList = this.sortWriteUpList.bind(this)
    this.state = {
      writeUpList: this.props.writeUpList||[],
      deleting: this.props.deleting || false,
      deleteModalOpen: false,
      sortedWriteUpList: this.sortWriteUpList()
    }
  }

  componentDidUpdate(prevProps:HistoricalProps) {
    if(!_.isEqual(prevProps.writeUpList, this.props.writeUpList)){
      this.setState({
        writeUpList: this.props.writeUpList || [],
        sortedWriteUpList: this.sortWriteUpList()
      }, () => {this.setDeleteModalOpen(false); this.setState({
        sortedWriteUpList: this.sortWriteUpList()
      })}) // any workaround?
    }
    if(prevProps.deleting && !this.props.deleting) {
      this.setDeleteModalOpen(false)
    }
  }

  sortWriteUpList = () => {
    let writeUpList = this.state?.writeUpList || this.props.writeUpList
    if(!writeUpList) { return writeUpList}
    let sortedWriteUpList = writeUpList
    if(writeUpList && writeUpList[0]?.__typename === "Fact"){
      sortedWriteUpList = _.sortBy(writeUpList, (f) => -moment(_.get(f, "lastUpdated")).valueOf()) as Fact[]
    }else if (writeUpList[0]?.__typename === "Opinion") {
      sortedWriteUpList = _.sortBy(writeUpList, (f) => -moment(_.get(f, "lastUpdated")).valueOf()) as Opinion[]
    }else if (writeUpList[0]?.__typename === "ProductOpinion") {
      sortedWriteUpList = _.sortBy(writeUpList, (f) => -moment(_.get(f, "opinions.lastUpdated")).valueOf()) as ProductOpinion[]
    }
    return sortedWriteUpList
  }

  setDeleteModalOpen = (state: boolean) =>this.setState({deleteModalOpen: state})

  historyDetail = (writeUp: Fact | Opinion) => {
    if(writeUp.__typename === "Fact"){
      return(
        <>
          <div className="history-title d-flex justify-content-between px-3 pt-3 pb-3 font-weight-bold m-1">
            <div>{WRITEUPS_ANCHOR_LIST[0].title} <span className="text-bluegray-50 pl-2 font-weight-normal">{moment(writeUp?.date, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}</span></div>
            <div className="history-title-author">{writeUp?.author?.firstName} {writeUp?.author?.lastName}</div>
          </div>
          <div className="m-1">
            <p className="intro px-3 m-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(writeUp?.text || "")}}></p>
            <p className="intro px-3 m-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(writeUp?.bullets || "")}}></p>
          </div>
        </>
      )
    } else if (writeUp.__typename === "Opinion") {
      return(
        <>
          <div className="history-title d-flex justify-content-between px-3 pt-3 pb-3 font-weight-bold m-1">
            <div>Opinion
              <span className="text-bluegray-50 pl-2 font-weight-normal">{moment(writeUp?.date, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}</span>
              <span className="writeup-status-container px-2 py-2 ml-4">
                <FontAwesomeIcon icon="circle" className={`mr-2 text-${statusColor(writeUp?.status?.value || "")}`} />
                {writeUp?.status?.value}
              </span>
            </div>
            <div className="history-title-author">{writeUp?.author?.firstName} {writeUp?.author?.lastName}</div>
          </div>
          <div className="m-1">
            <h5 className="headline underline-light m-3">Merits</h5>
            <p className="intro px-3 m-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(writeUp?.meritsText || "")}}></p>
            <p className="intro px-3 m-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(writeUp?.meritsBullets || "")}}></p>
            <h5 className="headline underline-light m-3">Considerations</h5>
            <p className="intro px-3 m-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(writeUp?.considerationsText || "")}}></p>
            <p className="intro px-3 m-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(writeUp?.considerationsBullets || "")}}></p>
          </div>
        </>
      )
    }
  }

  removeHistoryItem = (writeUp: Fact | Opinion) => {
    let {handleDelete} = this.props
    if(this.props.editMode) {
      return
    }
    handleDelete(writeUp)
  }

  historyListItem = (writeUp: Fact | Opinion, idx:number) => {
    if(writeUp.__typename === "Fact"){
      return (
        <div key={`${writeUp.__typename}-${writeUp.id}`} onClick={(event)=>event.stopPropagation()}>
          <div
            className={classnames({
              active: this.props.historyId === writeUp.id,
              "history-item pt-2 pb-2": true
            }, "d-flex justify-content-between")}
            onClick={() =>this.props.selectWriteUp(writeUp.id)}
          >
            {/* CAL-554 */}
            <span>
              Version {this.state.writeUpList.length - idx} - {writeUp.author?.firstName?.charAt(0)}. {writeUp.author?.lastName}
            </span>
            {!this.props.editMode && this.props.auth?.checkPermissions(["edit:writeups"]) && (<FontAwesomeIcon icon="trash" className={`ml-2 mr-2 history-item-trash`} onClick={() => this.setDeleteModalOpen(true)}/>)}
          </div>
        </div>
      )
    } else if (writeUp.__typename === "Opinion") {
      return (
        <div key={`${writeUp.__typename}-${writeUp.id}`} onClick={(event)=>event.stopPropagation()}>
          <div
            className={classnames({
              active: this.props.historyId === writeUp.id,
              "history-item pt-2 pb-2": true,
            }, "d-flex justify-content-between")}
            onClick={() =>
              this.props.selectWriteUp(writeUp.id)
            }
          >
            {/* CAL-554 */}
            <span>
              <FontAwesomeIcon icon="circle" className={`ml-2 mr-2 text-${statusColor(writeUp?.status?.value || "")}`} /> Version {this.state.writeUpList.length - idx} - {writeUp.author?.firstName?.charAt(0)}. {writeUp.author?.lastName}
            </span>
            {!this.props.editMode && this.props.auth?.checkPermissions(["edit:writeups"]) && (<FontAwesomeIcon icon="trash" className={`ml-2 mr-2 history-item-trash`} onClick={() => this.setDeleteModalOpen(true)}/>)}
          </div>
        </div>
      )
    }
  }

  deleteModalComponent = (writeUp: Fact | Opinion) => {
    let {deleting, deleteModalOpen, writeUpList} = this.state
    if(!writeUp) {
      return <></>
    }
    return (
      <div key={`${writeUp.id}-delete-modal`}>
        {!!deleting && <FontAwesomeIcon icon="spinner-third" spin />}
        <Modal size="md" className="mt-5" isOpen={deleteModalOpen} toggle={() => this.setDeleteModalOpen(!deleteModalOpen)} zIndex={1500}>
          <ModalHeader className="fee-modal-header">
            Delete Write-Up Version
          </ModalHeader>
          <ModalBody>
            Are you sure you want to permanently delete this version?
          </ModalBody>
          <ModalFooter>
            {!deleting && deleteModalOpen &&
              <>
                <Button onClick={() => this.setDeleteModalOpen(false)} color="secondary" className="mr-1 ml-auto">Cancel</Button>
                <Button onClick={() => this.removeHistoryItem(writeUp)} color="danger" className="mr-1">Delete Version</Button>
              </>
            }
            {deleting && deleteModalOpen &&
              (<Button color="danger" className="mr-1">
                Deleting
                <FontAwesomeIcon icon="spinner-third" className="ml-2" spin/>
              </Button>)
            }
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  render() {
    let writeUpList = this.state.writeUpList
    // fix opinion history sorting issue
    let sortedWriteUpList = this.state.sortedWriteUpList
    if(!sortedWriteUpList) {
      return <></>
    }
    let lastDate = moment(-1)
    let currentWriteUp
    if(sortedWriteUpList[0]?.__typename === "ProductOpinion"){
      let productOpinion = _.find(writeUpList,["opinions.id", this.props.historyId] ) as any as ProductOpinion
      currentWriteUp= _.get(productOpinion, "opinions") as Opinion
    } else {
      currentWriteUp= _.find(writeUpList,["id", this.props.historyId] ) as Opinion | Fact
    }
    return (
      <>
        <Modal isOpen={this.props.show} toggle={() => this.props.toggle(!this.props.show)} size="lg" zIndex={1500} key={`history-modal`}>
          <ModalHeader toggle={() => this.props.toggle(!this.props.show)} className="history-header">History</ModalHeader>
          <ModalBody className="pt-0">
            <Row noGutters={true}>
              <Col md="4" className="border-right">
                <h5 className="headline underline mt-3 mr-2 pb-3 text-uppercase text-gray-50">Versions</h5>
                  <div className="history-group">
                    {sortedWriteUpList.map((writeUp:Fact|Opinion|ProductOpinion, idx:number) => {
                      if(writeUp.__typename === "ProductOpinion") writeUp = writeUp.opinions as Opinion
                      let heading = <></>
                      if(Math.abs(moment(writeUp.date, DATE_API_FORMAT).diff(lastDate, "d")) > 0 ){
                        heading =<h5 className="mb-2 mt-3">{moment(writeUp.date, DATE_API_FORMAT).format("MMMM D, YYYY")}</h5>
                      }

                      lastDate = moment(writeUp.date, DATE_API_FORMAT)
                      return (
                        <React.Fragment key={writeUp.id}>
                          {heading}
                          {writeUp && this.historyListItem(writeUp, idx)}
                        </React.Fragment>
                      )
                    })}
                  </div>
              </Col>
              <Col md="8">
                {currentWriteUp && this.historyDetail(currentWriteUp)}
                {currentWriteUp && this.deleteModalComponent(currentWriteUp)}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export default WriteUpComponent