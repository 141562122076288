import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { debounce, isNull } from "lodash"
import { useContext, useEffect, useRef, useState } from "react"
import { Col, CustomInput, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

import EditButtons from "../../ui/EditButtons"
import iassign from "immutable-assign"
import { clone, get } from "lodash"
import { LandingPage, useCreateUserMutation, useUpdateUserMutation } from "../../../__generated__/graphql"
import { FormInput } from "../../ui/Forms/FormInput"
import { Maybe, PersonOfUserFragment, usePersonByEmailSearchQuery } from "../../../__generated__/graphql"
import React from "react"
import { TemporaryAlertContext } from "../../../Context/TemporaryAlertContext"
import { hasClientRole } from "./ManageUsers"

interface AddNewUserModalProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  callBackIfSuccess: () => void
  roleOptions: {code: string, value: string}[]
  allRoles: {code: string, value: string}[]
  subRolesOptions: {code: string, value: string}[]
}

type DefaultNewUserType = {
  email: string,
  sendWelcomeEmail: boolean,
  roles: {code: string, value: string}[],
  personId: number | null,
  landingPage: string,
  firms: number[],
  userId: number | null,
  user: {
    id: number | null,
    roles: {code: string, value: string}[],
  },
}

const defaultNewUser: DefaultNewUserType = {
  // __typename: "CreatePIDInput",
  email: "", // remove when creating new user
  sendWelcomeEmail: true,
  roles: [],
  personId: null,
  landingPage: "",
  firms: [],
  userId: null,
  user: {
    // store original user info
    id: null,
    roles: [],
  },
}

// generate editable roles from user.appMetadata.roles[string] to roleOptions[{code: string, value: string}
const generateNewUser = (newUser: any, roleOptions: {code: string, value: string}[], subRolesOptions?: {code: string, value: string}[]) => {
  let defaultRoleValue = roleOptions.filter(role => role.value === "manager")
  let roles: string[] = newUser?.roles || []
  let dnaTableShowRoles = roleOptions.filter(role => roles.some((r: string) => r === role.value))
  let dnaTableSubRoles = subRolesOptions?.filter(role => roles.some((r: string) => r === role.value))
  if(dnaTableShowRoles?.length) {
    defaultRoleValue =  dnaTableShowRoles
  }
  if(dnaTableSubRoles?.length) {
    defaultRoleValue = [...defaultRoleValue, ...dnaTableSubRoles]
  }
  
  let result = {...newUser, roles: defaultRoleValue}
  if(defaultRoleValue.find(role => role?.value === "manager")) {
    return {...result, sendWelcomeEmail: true}
  } else {
    return result
  }
}

const updateSendEmail = (roles: {code: string, value: string}[], roleOptions:  {code: string, value: string}[]) => {
  return roleOptions.some(option => option.value === "manager" && roles?.some(role => role.code === option.code))
}

const getCreateUser = (data: any) => {
  let {roles, firms, personId, sendWelcomeEmail} = data
  let updatedRoles = roles.map((role: {code: string}) => role.code)
  let updatedInput = {personId, roles: updatedRoles, landingPage: updatedRoles[0]? RoleLandingPageMapping[roles[0].value]: "", firms, sendWelcomeEmail}
  return updatedInput
}

const RoleLandingPageMapping: { [key: string]: LandingPage } =
  {
    "client_fs": LandingPage.client_portal,
    "client_iag": LandingPage.dashboard,
    "manager": LandingPage.manager_products
  }

const getUpdatedUser = (data: any, roleOptions: {code: string, value: string}[], allRoles: {code: string, value: string}[]) => {
  let {roles, userId, firms, sendWelcomeEmail} = data
  let updatedRoles = roles.map((role: {code: string}) => role.code) // new added/updated roles
  let existingRoles = allRoles?.filter((role: {code: string, value: string}) => data?.user?.roles?.some((option: string) => option === role.value)).map((role: {code: string}) => role.code) // existing roles
  let formattedRoles = [...existingRoles, ...updatedRoles]
  let updatedInput = { id: userId, patch: {roles: formattedRoles, landingPage: roles[0]? RoleLandingPageMapping[roles[0].value]: "", firms}}

  // if manager role is added/updated, sendWelcomeEmail is updated
  if(roles.some((role: {code: string, value: string}) => role.value === "manager")) {
    return {...updatedInput, sendWelcomeEmail}
  }
  return updatedInput
}

const getNewUserFromMatchedPerson = (newUser: any, matchedPerson: PersonOfUserFragment, roleOptions: {code: string, value: string}[], subRolesOptions: {code: string, value: string}[]) => {
  let { id: personId, employer, user} = matchedPerson
  let firms: number[] = employer?.id? [employer?.id]: []
  let userId = user?.id || newUser?.userId
  let matchedRoles = user?.appMetadata?.roles || []
  if(!matchedRoles?.length) {
    matchedRoles = ["manager"]
  }
  let landingPage = user?.appMetadata?.landingPage || (matchedRoles[0] ? RoleLandingPageMapping[matchedRoles[0]]: "")
  let allOptions = [...roleOptions, ...subRolesOptions]
  let allRolesInUserRoles = matchedRoles.filter(role => !allOptions.some(matchedRole => matchedRole.value === role))
  let newUserFromMatch = {
    personId, firms, userId, roles: matchedRoles, // [string]
    landingPage: landingPage,
    user: {
      id: userId,
      roles: allRolesInUserRoles // [string]
    }
  }
  let formattedUser = generateNewUser(newUserFromMatch, allOptions)
  return formattedUser
}

export const AddNewUserModal = (props: AddNewUserModalProps) => {
  const { modalOpen, setModalOpen, callBackIfSuccess, roleOptions, subRolesOptions, allRoles } = props

  const emailRef = useRef<HTMLInputElement>(null)
  const [newUser, setNewUser] = useState<any>(generateNewUser(defaultNewUser, roleOptions, subRolesOptions))

  const [matchedPerson, setPerson] = useState<Maybe<PersonOfUserFragment>>(null)
  const [showDetailedOptions, setShowDetailedOptions] = useState<boolean>(false)
  const [showSendEmail, setShowSendEmail] = useState<boolean>(updateSendEmail(newUser.roles, roleOptions))
  const [showSubRolesQuestion, setShowSubRolesQuestion] = useState<boolean>(false)

  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()

  const [saving, setSaving] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { addAlert } = useContext(TemporaryAlertContext)

  const closeModal = (refresh: boolean = false) => {
    setPerson(null)
    setShowDetailedOptions(false)
    setNewUser(generateNewUser(defaultNewUser, roleOptions, subRolesOptions))
    setModalOpen(false)
    if (refresh && callBackIfSuccess) {
      callBackIfSuccess()
    }
  }

  const onSubmit = () => {
    let earlyReturn = false
    if (isNull(matchedPerson)) {
      emailRef?.current?.focus()
      earlyReturn = true
    }
    if (earlyReturn) {
      console.log("early exit")
      return
    }
    setSuccessMessage("")
    setShowSuccessMessage(false)
    setSaving(true)
    let {email, user, userId, ...input} = newUser
    let type = "update"
    if(newUser?.user?.id === null) {
      type = "create"
      let input = getCreateUser(newUser)
      createUser({variables: {input}}).then(result => {
        if(result.data?.createUser) {
          let user = result.data.createUser.user
          setSuccessMessage("User created successfully.")
          setShowSuccessMessage(true)
          // success color: $accent-green
          addAlert({title: "Success | User added.", message: "", color: "userSuccess", timeout: 3000})
          closeModal(true)
        }
      }).catch(err => {
        setSuccessMessage(err.message)
        setShowSuccessMessage(true)
      }).finally(() => {
        setSaving(false)
      })
    }else {
      type = "update"
      let updatedInput = getUpdatedUser(newUser, roleOptions, allRoles)
      updateUser({variables: {input: updatedInput}}).then(result => {
        if(result.data?.updateUser) {
          setSuccessMessage("User created successfully.")
          setShowSuccessMessage(true)
          addAlert({title: "Success | User updated.", message: "", color: "userSuccess", timeout: 3000})
          closeModal(true)
        }
      }).catch(err => {
        setSuccessMessage(err.message)
        setShowSuccessMessage(true)
      }).finally(() => {
        setSaving(false)
      })
    }
  }

  const handleMultiSelectChange = (property: string, value: {code: string, value: string}[]) => {
    let newValue = clone(value)
    const newState = iassign(
      clone(newUser),
      [property],
      (array) => {
        let primaryRole = (array as []).shift()
        return [primaryRole, ...newValue]
      }
    )
    setNewUser(newState)
  }

  const handleChange = (property: string, value: any) => {
    let newValue = clone(value)
    if(property === "roles") {
      let v = roleOptions.find(role => role.code === value)?.value || ""
      newValue = [{code: value, value: v}]
    }

    const newState = iassign(
      clone(newUser),
      [property],
      (obj) => {
        return newValue
      }
    )
    setNewUser(newState)
  }

  useEffect(() => {
    if(matchedPerson?.email) {
      let updatedNewUser = getNewUserFromMatchedPerson(newUser, matchedPerson, roleOptions, subRolesOptions)
      setShowDetailedOptions(true)
      setNewUser({...updatedNewUser})
    }else if (!matchedPerson) {
      setNewUser(generateNewUser(defaultNewUser, roleOptions, subRolesOptions))
    }
  }, [matchedPerson?.id])

  useEffect(() => {
    let shouldShowSendEmail = updateSendEmail(newUser.roles, roleOptions)
    let shouldShowSubRolesQuestion = hasClientRole(newUser.roles)
    setShowSendEmail(shouldShowSendEmail)
    setShowSubRolesQuestion(shouldShowSubRolesQuestion)
    if(!shouldShowSendEmail) {
      handleChange("sendWelcomeEmail", false)
    }else {
      handleChange("sendWelcomeEmail", true)
    }
  }, [newUser.roles])

  const subRoleValues = newUser?.roles.filter((role: {code: string, value: string}) => subRolesOptions.find((dnaRole) => dnaRole.value === role.value))

  return (
    <Modal
      size='md'
      className='mt-5 filter-product-views'
      id='add-new-user-modal'
      isOpen={modalOpen}
      toggle={() => {
        setPerson(null)
        setShowDetailedOptions(false)
        setModalOpen(!modalOpen)
      }}
      zIndex={1500}
    >
      <ModalHeader className='fee-modal-header full-width-header'>
        <div className='d-flex justify-content-between'>
          <div>New DNA User</div>
          <div onClick={() => closeModal()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className='add-new-user pb-2'>
          <span>{`Find a user in the Salesforce database and create a matching DNA account for them.`}</span>
        </div>
        <div className="relative">
          <PersonByEmailSearch
            matchedPerson={matchedPerson}
            setPerson={setPerson}
            emailRef={emailRef}
          />
        </div>
        <div className="relative">
          <div className="form-group row">
            <div className={"col-sm-9 pr-2 pt-1 pb-2 ml-2 text-gray-50 text-right"}>
              {"Enter the email for the user's Salesforce account."}
            </div>
          </div>
        </div>
        {showDetailedOptions && <div className={"disable-hover-not-allowed"}>
          <FormInput
            property={"roles"}
            displayName={"Role"}
            type={"select"}
            subtype={"single"}
            idx={"1-role"}
            editMode={!!matchedPerson?.email}
            propertyVal={newUser?.roles[0].code}
            updateValue={(value) => handleChange("roles", value)}
            validateAlsoOnChange={true}
            required={true}
            options={roleOptions}
            subClasses={{ labelClasses: "col-sm-1 pl-3 mr-2", inputWrapperClasses: "col-sm-8" }}
          />
        </div>}
        {showSubRolesQuestion &&
          <FormInput
            property={"sub-roles"}
            displayName={"Additional Roles"}
            type={"select"}
            subtype={"multiple"}
            idx={"2-sub-role"}
            editMode={true}
            propertyVal={subRoleValues}
            updateValue={(value) => {
              handleMultiSelectChange("roles", value)
            }}
            validateAlsoOnChange={true}
            required={true}
            options={subRolesOptions}
            defaultOptions={subRoleValues}
            subClasses={{
              labelClasses: "col-sm-2 pl-3 legend-multiple",
              inputWrapperClasses: "col-sm-6 my-2"
            }}
          />
        }
        {showDetailedOptions && showSendEmail &&
          <div className={"pt-2 pl-1 send-welcome-email"}>
            <CustomInput
              className={"square-checkbox"}
              checked={newUser.sendWelcomeEmail === true}
              value={"true"}
              onChange={() => handleChange("sendWelcomeEmail", !newUser.sendWelcomeEmail)}
              id={"2-send"}
              label={"Send welcome email"}
              type={"checkbox"}
              disabled={!matchedPerson?.email}
            />
        </div>}
      </ModalBody>
      <ModalFooter>
        <EditButtons
          className={"disable-on-white"}
          editMode={true}
          setEditMode={() => true}
          cancelEdit={() => closeModal()}
          saving={saving}
          onSubmit={onSubmit}
          disableOnError={true}
          disabled={!matchedPerson}
        />
      </ModalFooter>
    </Modal>
  )
}

interface PersonByEmailSearchProps {
  matchedPerson: Maybe<PersonOfUserFragment>
  setPerson: (person: Maybe<PersonOfUserFragment>) => void
  emailRef: React.RefObject<HTMLInputElement>
}

const PersonByEmailSearch: React.FC<PersonByEmailSearchProps> = (props:PersonByEmailSearchProps) => {
  const {matchedPerson, setPerson, emailRef} = props
  const [search, setSearch] = useState<string>(matchedPerson?.email || "")
  const [resetDebounceSearch, setResetDebounceSearch] = useState<boolean>(false)

  const [hideResult, setHideResult] = useState(false)
  const debouncedHide = useRef(debounce((value:boolean) => setHideResult(value), 200)).current

  const { data, loading } = usePersonByEmailSearchQuery({ variables: {
    q: search,
  }})

  useEffect(() => {
    if(matchedPerson) {
      setResetDebounceSearch(true)
      setSearch(matchedPerson?.email || "")
    }else if(!resetDebounceSearch){
      setPerson(null)
      setSearch("")
    }
  }, [matchedPerson])

  useEffect(() => {
    if(!search && matchedPerson?.email) {
      setPerson(null)
    }
  }, [search])

  let results: (JSX.Element | undefined)[]
  if (!!data && data.search?.data?.length) {
    let searchData = (data.search?.data || [] )as PersonOfUserFragment[]
    results = searchData.map((d) => {
      if (d == null) {
        return <ListGroupItem className="horizontal with-category autocomplete"></ListGroupItem>
      }
      let type: string | undefined, id: number | undefined, firstName: Maybe<string> | undefined, lastName: Maybe<string> | undefined, email: Maybe<string> | undefined, name: Maybe<string> | undefined
      if(d.__typename === "Person") {
        type = "Person"
        id = d.id
        firstName = d.firstName
        lastName = d.lastName
        email = d.email
        name = `${firstName} ${lastName}`
      }
      const handleClick = () => {
        if(d.__typename === "Person"){
          setPerson(d)
        }else {
          setSearch("")
        }
      }
      if(type && id && email){
        return (
          <ListGroupItem tag="a" onClick={handleClick} key={`${type.toLocaleLowerCase()}-auto-${id}`}>
            <div className={`category ${type.toLocaleLowerCase()}`}>
              <div className="category-text">
                <h6>{type}</h6>
              </div>
            </div>
            <div className="w-100">
              <Row>
                <Col md="9">
                  <h3>{email}</h3>
                  <h3>{name}</h3>
                  <p>FIRM: {d?.employer?.name}</p>
                </Col>
                <Col md="3">
                  <h3>{type} ID</h3>
                  <p>{id}</p>
                </Col>
              </Row>
            </div>
          </ListGroupItem>
        )
      }
    })
  } else {
    if(search?.length > 1 && !loading) {
      results = [
      <ListGroupItem tag="a" key={`no-match`}>
        <div className="w-100 text-center align-self-center">
          No matching Salesforce users.
        </div>
      </ListGroupItem>]
    }else {
      results = [(<React.Fragment key="1"></React.Fragment>)]
    }
  }
  return (
    <FormGroup
      className="form-group row relative"
      key={"PersonByEmail"}
      onBlur={() => {
        debouncedHide(true)
      }}
      onFocus={() => {
        setHideResult(false)
      }}
    >
      <Label className="col-form-label col-sm-1 pl-3">
        <div className="d-flex w-100" id="">Email</div>
      </Label>
      <div className="input-group-col-fixer col-sm-8 pr-3 ml-2">
        <DebouncedSearchBar
          setSearchTerm={setSearch}
          search={matchedPerson?.email || ""}
          placeholder="Email"
          searchIcon={true}
          // refresh={resetDebounceSearch}
          inputRef={emailRef}
        />
      </div>
      {!hideResult && <ListGroup className="person-by-email horizontal with-category autocomplete above-picker">
        {!hideResult && results}
      </ListGroup>}
  </FormGroup>
  )
}

interface DebouncedSearchBarProps{
  setSearchTerm: (name:string) => void
  search: string
  debounceTimeout?: number
  placeholder?: string
  searchIcon?: boolean
  refresh?: boolean
  inputRef: React.RefObject<HTMLInputElement>
}

const DebouncedSearchBar:React.FC<DebouncedSearchBarProps> = (props:DebouncedSearchBarProps) => {
  const { debounceTimeout, setSearchTerm, placeholder, refresh, search, searchIcon, inputRef} = props
  const [searchString, setSearchString] = useState("")
  const debouncedSave = useRef(debounce((nextValue:string) => setSearchTerm(nextValue), debounceTimeout || 500)).current

  const handleSearchChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchString(value)
    debouncedSave(value)
  }
  const resetSearch = () => {
    setSearchTerm(search)
  }

  useEffect(() => {
    if(refresh) {
      debouncedSave(search)
      setSearchString(search)
    }
  }, [refresh])

  useEffect(() => {
     setSearchString(search)
  }, [search])

  return (
    <div>
      <Input
        type="text"
        idx="emailSearch"
        className={classNames("form-control", {"pl-2": !!searchIcon})}
        onChange={handleSearchChange}
        onFocus={resetSearch}
        placeholder={placeholder || "Search"}
        value={searchString}
        innerRef={inputRef}
      />
      {searchIcon &&
        <span className="o-88 absolute center-v pe-none right-1">
          <FontAwesomeIcon
            icon={["fas", "search"]}
            size="2x"
            className="fontawesome-icon dark-icon-color text-gray-50"
          />
        </span>
      }
    </div>
  )
}
