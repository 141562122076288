import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DocumentNode } from '@apollo/client'
import { differenceBy, find } from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledButtonDropdown } from 'reactstrap'

import { DocumentSubType, DocumentType, FileBasicInfoFragment, FileTypeMapType, FileTypeSubTypeMap, MeFragment, useFileTypeMappingQuery } from '../../__generated__/graphql'
import Auth from '../../Auth/Auth'
import { AddFileModal, FileActionLinks, ForceValuesProps, SearchFileModal } from '../Shared/Document'
import { FormInput } from '../ui/Forms/FormInput'
import _ from 'lodash'
import moment from 'moment'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, DATE_TIME_API_FORMAT } from '../../helpers/constant'

interface DocumentCompactListProps {
  associationId?: number
  associationType: "Manager" | "Plan" | "Product" | "Vehicle" | "GlidePath" | "Client"
  documents: FileBasicInfoFragment[]
  addDocument?: (document:FileBasicInfoFragment[]) => void
  removeDocument?: (docuemnt:FileBasicInfoFragment) => void
  user?: MeFragment
  auth: Auth
  queryDocument?: DocumentNode
  editMode?: boolean
  updateValue?: (document:FileBasicInfoFragment, property:string, value:any) => void
  defaultValues?: ForceValuesProps
  forceValues?: ForceValuesProps
  allowedSubtypes?: DocumentSubType[]
  allowedTypes?: DocumentType[]
  notEditable?: boolean
  includeSearch?: boolean
  searchableDocuments?: FileBasicInfoFragment[]
}

const DocumentCompactList:React.FC<DocumentCompactListProps> = (props) => {
  const { associationId, associationType, documents, addDocument, user, auth, queryDocument, removeDocument, editMode, updateValue, defaultValues, forceValues, notEditable, includeSearch, searchableDocuments } = props
  const { data: fileTypeData } = useFileTypeMappingQuery({variables: {type: associationType === "Client" ? FileTypeMapType.Client : FileTypeMapType.Manager}})
  const groupedTypes = _.groupBy(fileTypeData?.fileTypeMap as FileTypeSubTypeMap[], 'type')

  const [addFileModalOpen, setAddFileModalOpen] = useState(false)
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const addFileInputRef = useRef<HTMLInputElement>(null)
  const filteredSearchableDocuments = differenceBy((searchableDocuments || []), documents, 'id')

  const sortDocuments = (documents: FileBasicInfoFragment[]) => {
    return _.orderBy(documents, (i) => {
      return _.reduce(i.versions, (result, value) => {
        const currentValue = moment(value.updated, DATE_TIME_API_FORMAT).valueOf()
        return _.max([currentValue, result]) || 0
      }, 0)
    },["desc"])
  }
  const [sortedDocuments, setSortedDocuments] = useState(()=>sortDocuments(documents))

  useEffect(()=>{
    setSortedDocuments(()=>sortDocuments(props.documents))
  }, [props.documents])

  const handleDocsDelete = (document: FileBasicInfoFragment) => {
    // CAL-1588
    let newDocs = _.cloneDeep(sortedDocuments)
    let idx = _.findIndex(newDocs, {'id': document.id})
    newDocs?.splice(idx, 1)
    setSortedDocuments(newDocs)
    if(removeDocument) {
      removeDocument(document)
    }
  }

  const openFileModal = () => {
    // setAddFileModalOpen(!addFileModalOpen)
    addFileInputRef?.current?.click()
  }

  const uploadButtons = () => {
    if(notEditable || !auth.checkPermissions(["upload:documents"])){
      return <></>
    } else if (includeSearch) {
      return (
        <UncontrolledButtonDropdown className="mr-1 mt-2">
          <DropdownToggle caret>
            <FontAwesomeIcon
              icon="plus-circle"
              className="mr-2 text-blue-100"
            />
            Add
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={openFileModal}>
              <FontAwesomeIcon
                icon="upload"
                className="mr-2 text-blue-100"
              />
              Upload from computer
            </DropdownItem>
            <DropdownItem onClick={() => setSearchModalOpen(true)}>
              <FontAwesomeIcon
                icon="search"
                className="mr-2 text-blue-100"
              />
              Search DNA
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      )
    } else {
      return (
        <Button color="light btn-no-style mt-3 pb-0" onClick={openFileModal}>
          Add Document
          <FontAwesomeIcon
            icon="plus-circle"
            className="ml-2"
          />
        </Button>
      )
    }
  }

  return (
    <div className="mt-5">
        <h3 className="headline underline mt-4 d-flex justify-content-between">
          <div className="mt-4">Documents</div>
          {uploadButtons()}
        </h3>
        <AddFileModal
          inputRef={addFileInputRef}
          key={sortedDocuments.length}
          associationId={associationId}
          associationType={associationType}
          modalOpen={addFileModalOpen}
          setModalOpen={(open:boolean) => setAddFileModalOpen(open)}
          user={user}
          addDocument={(document) => addDocument && addDocument([document])}
          defaultValues={defaultValues || {type: DocumentType._1 }}
          forceValues={forceValues}
          queryDocument={queryDocument}
          allowedSubtypes={props.allowedSubtypes}
          auth={auth}
        />
        <SearchFileModal
          key={"search" + sortedDocuments.length}
          modalOpen={searchModalOpen}
          setModalOpen={(open:boolean) => setSearchModalOpen(open)}
          addDocument={addDocument}
          documents={filteredSearchableDocuments}
        />
      <div className="table-container">
      <Table className="left-aligned">
        <thead>
          <tr>
            <th>Name</th>
            <th>Uploaded</th>
            <th>Type</th>
            <th>Sub-Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          { sortedDocuments.map((document, idx) => {
            let currentVersion = find(document.versions, v => v.id === document.versionId)
            const fileType = document.type?.code || ""
            const optionFilterMapping = groupedTypes[fileType] || []
            const optionFilterRule = optionFilterMapping.reduce((acc, { type, subType, subTypeOrder }) => {
              if(props.allowedSubtypes && subType && !props.allowedSubtypes.includes(subType)){
                return {...acc, "": true }
              }
              return {...acc, [subType || ""]: subTypeOrder }
            }, {"": true})
            const typeFilterRule = !!props.allowedTypes ? props.allowedTypes.reduce((result, entry) => {
              return {...result, [entry || ""]: _.first(groupedTypes[entry])?.typeOrder }
            }, {"": true}) : {}
            return (
              <tr key={`document-entry-${document.id}`}>
                <td>
                  {!editMode &&
                    <a href={document?.url || ""} download target="_blank" rel="noreferrer">
                      {document.description || document.name}
                    </a>
                  }
                  {!!editMode &&
                    <FormInput
                      property={"type"}
                      displayName=""
                      placeholder={document.name}
                      type={"text"}
                      idx={idx}
                      editMode={true}
                      propertyVal={document.description}
                      updateValue={(value) => updateValue ? updateValue(document, "description", value) : null}
                      optionSource={"DocumentType"}
                    />
                  }
                </td>
                <td>{moment(currentVersion?.updated, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}</td>
                <td>
                  <FormInput
                    property={"type.code"}
                    displayName=""
                    type={"select"}
                    idx={idx}
                    editMode={editMode || false}
                    propertyVal={document.type?.code}
                    updateValue={(value) => updateValue ? updateValue(document, "type.code", value) : null}
                    optionSource={"DocumentType"}
                    optionFilterRule={typeFilterRule}
                    required={true}
                  />
                </td>
                <td>
                  <FormInput
                    property={"subtype.code2"}
                    displayName=""
                    type={"select"}
                    idx={idx}
                    editMode={editMode || false}
                    propertyVal={document.subType?.code}
                    updateValue={(value) => updateValue ? updateValue(document, "subType.code", value) : null}
                    optionSource={"DocumentSubType"}
                    optionFilterRule={optionFilterRule}
                    required={true}
                  />
                </td>
                <td>
                  <FileActionLinks
                    document={document}
                    associationId={associationId}
                    associationType={associationType}
                    auth={auth}
                    iconOnly={true}
                    queryDocument={queryDocument}
                    removeDocument={handleDocsDelete}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </div>
    </div>
  )
}


export default DocumentCompactList