import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditButtonContext, WarningProps } from "../../Context/EditButtonContext"
import { isEmpty } from 'lodash';
import classNames from 'classnames'

interface EditButtonsProps {
  editMode: boolean
  saving: boolean
  onSubmit: () => void
  setEditMode: (mode:boolean) => void
  cancelEdit?: () => void
  disabled?: boolean
  saveText?: string
  savingText?: string
  disableOnError?: boolean
  hideOnContext?: boolean
  hideCancelButton?: boolean
  className?: string
}

export const EditButtons: React.FC<EditButtonsProps> = props => {
  const { showButtons, resetErrors, errors, warningModal, setWarningModal } = useContext(EditButtonContext)
  const { editMode, saving, onSubmit, setEditMode, cancelEdit: propsCancelEdit, disableOnError, hideOnContext, disabled: disabledProp, hideCancelButton, className, savingText } = props
  const [disabled, setDisabled] = useState(disabledProp || (disableOnError && !isEmpty(errors)) || false)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  // const disabled = disabledProp || (disableOnError && !isEmpty(errors)) || false

  const cancelEdit = () => {
    setEditMode(false)
    propsCancelEdit && propsCancelEdit()
    resetErrors()
  }

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if(warningModal){
      setWarningModalOpen(true)
    } else {
      onSubmit()
    }
  }

  const submitWarning = () => {
    onSubmit()
    setWarningModalOpen(false)
    setWarningModal(undefined)
  }

  useEffect(() => {
    setDisabled(disabledProp || (disableOnError && !isEmpty(errors)) || false)
  }, [errors, disabledProp, disableOnError])

  // If component should be hidden exit edit mode
  useEffect(() => {
    if(editMode && hideOnContext && !showButtons && disableOnError){
      setEditMode(false)
      propsCancelEdit && propsCancelEdit()
      resetErrors()
    }
  }, [showButtons, editMode, propsCancelEdit, resetErrors, setEditMode, disableOnError, hideOnContext])

  // When component first loads reset errors
  useEffect(() => {
    resetErrors()
  }, [resetErrors])

  if(hideOnContext && !showButtons){
    return <></>
  }

  if (saving) {
    return (<Button color="primary" className="mr-1 ml-auto">
      {savingText || "Saving"}
      <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />
    </Button>)
  }

  if (editMode) {
    return (
      <>
        {!hideCancelButton && <Button onClick={cancelEdit} color="secondary" className={classNames("mr-1 ml-auto", className)}>Cancel</Button> }
        <Button type="submit"
          onClick={handleSave}
          color="primary"
          className={classNames("mr-1", className)} disabled={disabled}>{props.saveText || "Save"}</Button>
        <WarningModal
          open={warningModalOpen}
          toggle={() => setWarningModalOpen(!warningModalOpen)}
          submitWarning={submitWarning}
          warning={warningModal as WarningProps}
        />
      </>
    )
  }

  return (
    <Button onClick={() => setEditMode(true)} color="primary" className={classNames("mr-1 ml-auto", className)}>
      Edit
      <FontAwesomeIcon
        icon="pen"
        size="xs"
        className="ml-2"
      />
    </Button>
  )

}

interface WarningModalProps {
  open: boolean
  toggle: () => void
  submitWarning: () => void
  warning?: WarningProps
}

export const WarningModal: React.FC<WarningModalProps> = ({open, toggle, submitWarning, warning}) => {

  return(
  <Modal size="md" className="mt-5" isOpen={open} toggle={toggle} zIndex={1500}>
    <ModalHeader toggle={toggle}>{warning?.heading || "Warning"}</ModalHeader>
    <ModalBody>
      {warning?.message}
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle} className="mr-2">Cancel</Button>
      <Button color="primary" onClick={submitWarning}>Save</Button>
    </ModalFooter>
  </Modal>
  )
}

export default EditButtons