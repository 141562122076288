import { disableFragmentWarnings, gql } from "@apollo/client"
disableFragmentWarnings()

export const TARGET_DEFINITION_FRAGMENT = gql`
  fragment TargetDefinitionFragment on TargetDefinition {
    startDate
    endDate
    assetClass {
      ...CurrentAssetClassFragment
      children {
        id
        code: id
        fullName
      }
    }
    member {
      ...TargetMemberFragment
    }
    memberType {
      code
      value
    }
    minWeight
    weight
    maxWeight
  }
`

export const TARGET_MEMBER_FRAGMENT = gql`
  fragment TargetMemberFragment on TargetMember {
    __typename
    ... on ClientPortfolio {
      id
      name
    }
    ... on Index {
      indexId: id
      name
    }
    ... on Vehicle {
      vehicle {
        id: fundid
        name
      }
    }
    ... on Target {
      targetId: id
      name
      endDate
      useType {
        code
        value
      }
      lagMonths
      constantRebalance {
        startDate
        frequency {
          code
          value
        }
        constant {
          amount
          basis
        }
      }
    }
    ... on Group {
      groupId: id
      name: shortName
    }
      # TODO definition removed here
  }
`

export const TARGET_DETAIL_FRAGMENT = gql`
  fragment TargetDetailFragment on Target {
    targetId: id
    name
    endDate
    useType {
      code
      value
    }
    lagMonths
    constantRebalance {
      startDate
      frequency {
        code
        value
      }
      constant {
        amount
        basis
      }
    }
  }
`

export const TARGET_DEFINITION_QUERY = gql`
  query TargetDefinition($id: Int!) {
    target(id: $id) {
      targetId: id
      definition {
        ...TargetDefinitionFragment
      }
    }
  }
  ${TARGET_DEFINITION_FRAGMENT}
`

export const TARGET_DETAIL_QUERY = gql`
  query TargetDetail($id: Int!) {
    target(id: $id) {
      ...TargetDetailFragment
    }
  }
  ${TARGET_DETAIL_FRAGMENT}
`

export const CREATE_TARGET_MUTATION = gql`
  mutation CreateTarget($input: CreateTargetInput!) {
    createTarget(input: $input) {
      target {
        ...TargetDetailFragment
      }
    }
  }
  ${TARGET_DETAIL_FRAGMENT}
`

export const UPDATE_TARGET_MUTATION = gql`
  mutation UpdateTarget($input: UpdateTargetInput!) {
    updateTarget(input: $input) {
      target {
        ...TargetDetailFragment
      }
    }
  }
  ${TARGET_DETAIL_FRAGMENT}
`
