import { Maybe, ProductFields } from '../__generated__/graphql'

// All functions below should use product object as inputs.

export type ProductType = { product?: ProductFields; __typename: string }

export const isOpenEnded = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (typename.indexOf("OpenEnded") > -1) {
    return true
  }
  return false
}

export const isClosedEnded = (product: Maybe<ProductType> | undefined) => {
  let typename = product?.__typename
  if (!product) {
    return false
  }
  if (typename && typename.indexOf("ClosedEnded") > -1) {
    return true
  }
  return false
}

const __isPassiveTypename = (typename: Maybe<string> | undefined) => {
  return !!(typename && typename.toLowerCase().indexOf("pass") > -1)
}

export const isPassive = (product: Maybe<ProductType> | undefined) => {
  let typename = product?.__typename
  return __isPassiveTypename(typename)
}

export const isRealAssets = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (typename && typename.indexOf("RealAssets") > -1) {
    return true
  }
  return false
}
/**
 * NB: broad equity, can't tell if active/passive.
 * privateEquity is not considered Equity.
 */
export const isEquity = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (
    typename &&
    typename.indexOf("Private") === -1 &&
    typename.indexOf("Equity") > -1
  ) {
    return true
  }
  return false
}

/**
 * NB: private equity
 */
export const isPrivateEquity = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (typename && typename.indexOf("PrivateEquity") > -1) {
    return true
  }
  return false
}

/**
 * NB: broad MAC, can't tell if active
 */
export const isMAC = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (typename && typename.indexOf("MAC") > -1) {
    return true
  }
  return false
}

/**
 * NB: NB: broad fixedIncome, can't tell if passive.
 * targetDate is not considered fixedIncome
 */
export const isFixedIncome = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (typename && typename.indexOf("FixedIncome") > -1) {
    return true
  }
  return false
}

/**
 * NB: private credit
 */
export const isPrivateCredit = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (typename && typename.indexOf("PrivateCredit") > -1) {
    return true
  }
  return false
}

export const isHedgeFund = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (typename && typename.indexOf("HedgeFund") > -1) {
    return true
  }
  return false
}

export const isTargetDate = (product: Maybe<ProductType> | undefined) => {
  if (!product) {
    return false
  }
  let typename = product.__typename
  if (typename && typename.indexOf("TargetDate") > -1) {
    return true
  }
  return false
}

/**
 * NB: equity is different from passive**equity
 */
export const isPassiveEquity = (product: Maybe<ProductType> | undefined) => {
  return isEquity(product) && isPassive(product)
}

export const isPassiveFixedIncome = (
  product: Maybe<ProductType> | undefined
) => {
  return isFixedIncome(product) && isPassive(product)
}

export const isPassiveMAC = (product: Maybe<ProductType> | undefined) => {
  return isMAC(product) && isPassive(product)
}

/**
 *
 * @param {object} data get from product query, format {product: {product: ProductField, __typename: string }}
 * @return {[productType: string]: boolean} e.g. {openEnded: true, Equity: true} if it's an OpenEndedEquity product
 */
export const getProductType = (data: { product: Maybe<ProductType> }) => {
  let result: { [type: string]: boolean } = { product: true }
  if (isClosedEnded(data.product)) {
    result["closedEnded"] = true
  } else if (isOpenEnded(data.product)) {
    result["openEnded"] = true
  }

  // can't tell if blended/active from typename
  if (isPassive(data.product)) {
    result["passive"] = true
  }

  if (isEquity(data.product)) {
    result["equity"] = true
  } else if (isRealAssets(data.product)) {
    result["realAssets"] = true
  } else if (isPrivateEquity(data.product)) {
    result["privateEquity"] = true
  } else if (isPrivateCredit(data.product)) {
    result["privateCredit"] = true
  } else if (isHedgeFund(data.product)) {
    result["hedgeFund"] = true
  } else if (isFixedIncome(data.product)) {
    result["fixedIncome"] = true
  } else if (isTargetDate(data.product)) {
    result["targetDate"] = true
  } else if (isMAC(data.product)) {
    result["MAC"] = true
  }

  return result
}
