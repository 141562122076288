import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState, useRef, ReactNode } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {
  Container,
  Nav,
  Col,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  Row,
  Button,
  ButtonDropdown,
  DropdownItem,
} from 'reactstrap';

type TabMenuProps = {
  menuItems: MenuItems[];
  selected: string;
  onClick: (name: string) => void;
  setTab: (value: string) => void;
};

type MenuItems = {
  tableLength?: string | number;
  tabName: string;
  tableName: string;
};

type TabMenuItemProps = {
  menu: MenuItems;
  selected: string;
  index: number;
  itemId: string;
  handleClick: (tableName: string, index: number) => void;
};

type ArrowProps = {
  onClick: VoidFunction;
  disabled: boolean;
  children: ReactNode;
};

type MoreOptionsProps = {
  menuItems: MenuItems[];
  setTab: (value: string) => void;
  handleClick: (code: string, index: number) => void;
  selected: string;
};

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

/**
 * Creates a horizontally selectable scrolling menu
 * @param {MenuItems[]} menuItems - tableLength (Default is 100) and tabName
 * @param {string} selected - tabName selected onClick
 */
const TabMenu: React.FC<TabMenuProps> = ({ menuItems, selected, onClick, setTab }) => {
  const apiRef = useRef({} as scrollVisibilityApiType);

  const handleClick = (tableName: string, index: number) => {
    onClick(tableName);
    apiRef.current.scrollToItem(apiRef.current.getItemElementByIndex(index) as Element, 'smooth', 'center');
  };

  return (
    <Container fluid>
      <Row style={{ borderBottom: '1px solid #a3a6a8' }}>
        <Col xs={10} className='p-0'>
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            apiRef={apiRef}
            scrollContainerClassName='nav-horizontal'
          >
            {menuItems.map((menu: MenuItems, index) => {
              return (
                <TabMenuItem
                  menu={menu}
                  selected={selected}
                  itemId={menu.tableName}
                  index={index}
                  key={index}
                  handleClick={handleClick}
                />
              );
            })}
          </ScrollMenu>
        </Col>
        <Col className='pr-0 m-auto'>
          <MoreOptions menuItems={menuItems} setTab={setTab} handleClick={handleClick} selected={selected} />
        </Col>
      </Row>
    </Container>
  );
};

const Arrow: React.FC<ArrowProps> = ({ onClick, disabled, children }) => {
  return !disabled ? (
    <Button size='sm' onClick={onClick} disabled={disabled} className=' px-2 pb-0' color='link'>
      {children}
    </Button>
  ) : (
    <></>
  );
};

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev, visibleItemsWithoutSeparators, initComplete } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!initComplete || (initComplete && isFirstItemVisible));
  useEffect(() => {
    // NOTE: detect if whole component visible
    visibleItemsWithoutSeparators.length && setDisabled(isFirstItemVisible);
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow onClick={() => scrollPrev()} disabled={disabled}>
      <FontAwesomeIcon icon={'angle-left'} className='text-callan-blue' size='lg' />
    </Arrow>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } = useContext(VisibilityContext);
  const [disabled, setDisabled] = useState(!visibleItemsWithoutSeparators.length && isLastItemVisible);

  useEffect(() => {
    visibleItemsWithoutSeparators.length && setDisabled(isLastItemVisible);
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <Arrow onClick={() => scrollNext()} disabled={disabled}>
      <FontAwesomeIcon icon={'angle-right'} className='text-callan-blue' size='lg' />
    </Arrow>
  );
};

const MoreOptions: React.FC<MoreOptionsProps> = ({ menuItems, setTab, handleClick, selected }) => {
  const [openMore, setOpenMore] = useState(false);

  const onClick = (table: MenuItems, index: number) => {
    setTab(table.tableName);
    handleClick(table.tableName, index);
    setOpenMore(false);
  };

  return (
    <ButtonDropdown isOpen={openMore} toggle={() => setOpenMore(!openMore)} className='btn-sm float-right'>
      <DropdownToggle className='bg-white border border-white pr-0'>
        More
        <FontAwesomeIcon icon='bars' className='ml-2 text-blue-100' />
      </DropdownToggle>
      <DropdownMenu right>
        {menuItems.map((table, index) => {
          return (
            <DropdownItem
              key={table.tableName}
              active={table.tabName === selected}
              onClick={() => onClick(table, index)}
            >
              {table.tabName}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const TabMenuItem: React.FC<TabMenuItemProps> = ({ menu, selected, index, handleClick, itemId }) => {
  return (
    <Nav key={itemId} className='sub-nav sub-nav-lower-index bg-white' tabs role='group'>
      <NavItem style={{ width: menu.tableLength }}>
        <NavLink
          className={menu.tabName === selected ? 'active' : ''}
          role='button'
          onClick={() => handleClick(menu.tableName, index)}
        >
          {menu.tabName}
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default TabMenu;
