import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Button, Table } from "reactstrap"

import { InvestorRosterCreateInput, Maybe,  } from "../../__generated__/graphql"
import {
  FormInputSubClasses,
  InvestorRosterUnion
} from "../../helpers/constant"
import { getNewStateObject } from "../../helpers/helpers"
import { FormInput } from "../ui/Forms/FormInput"

type InvestorRosterTableProps = {
  data: Maybe<InvestorRosterUnion>[]
  handleChange: (state: any) => void
  editMode: boolean
}

type columnDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  optionSource?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  tooltip?: string,
  required?: boolean,
}

interface InvestorRosterTableRowProps {
  data: any
  columnDef: columnDefItem[]
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

const InvestorRosterTableColumnDef: columnDefItem[] = [
  {
    field: "investor",
    type: "text",
    title: "Investor",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    required: true
  },

  {
    field: "capitalCommitment",
    type: "float",
    title: "Capital Commitment",
    subtype: "currency",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    },
    tooltip: "capitalCommitment",
    required: true
  },
  {
    field: "investorType.code",
    type: "select",
    title: "Investor Type",
    optionSource: "InvestorTypeCode",
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    }
  },
  {
    field: "investorDomicile.code",
    type: "select",
    title: "Investor Domicile",
    optionSource: "CountryCode",
    required: true,
    subtype: 'required',
    subClasses: {
      inputWrapperClasses: "col-sm-12"
    }
  },
  {
    field: "advisorBoardSeat",
    type: "checkbox",
    title: "Advisory Board Seat",
    subtype: "boolean",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "justify-content-center col-sm-12"
    },
    tooltip: "advisorBoardSeat"
  }
]

const InvestorRosterTableRow = ({
  data,
  columnDef,
  idx,
  editMode,
  action,
  updateValue
}: InvestorRosterTableRowProps) => {
  return (
    <tr
      className={
        editMode
          ? "full-width hover-actions editing"
          : "full-width hover-actions"
      }
    >
      {columnDef.map(header => {
        return (
          <td key={header.field}>
            <FormInput
              key={header.field}
              property={header.field}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={idx}
              editMode={editMode}
              propertyVal={_.get(data, header.field)}
              optionSource={header.optionSource}
              placeholder={"-"}
              updateValue={value =>
                updateValue(value, header.type, header.field)
              }
              subClasses={header.subClasses}
              required={header.required}
            />
          </td>
        )
      })}
      {editMode && (
        <td className="actions">
          <Button
            color="link"
            size="sm"
            className="ml-auto"
            onClick={() => action.remove(idx)}
          >
            <FontAwesomeIcon icon="trash" className="ml-2 text-blue-100" />
          </Button>
        </td>
      )}
    </tr>
  )
}

const InvestorRosterTableDisplay = (seed: InvestorRosterTableProps) => {
  const tableName = "Investor-Roster-Table"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)
  useEffect(() => {
    setData(seed.data)
  }, [seed.data])

  const onAddInvestorRosterTableRow = (row: InvestorRosterCreateInput) => {
    const newData = [...tableState, row] as InvestorRosterUnion[]
    setData(newData)
    handleChange(newData)
  }
  const onRemoveInvestorRosterTableRow = (colIndex: number) => {
    let newData = [
      ...tableState.slice(0, colIndex),
      ...tableState.slice(colIndex + 1)
    ]
    setData(newData)
    handleChange(newData)
  }

  const actionMap = {
    add: onAddInvestorRosterTableRow,
    remove: onRemoveInvestorRosterTableRow
  }

  const onHandleChangeInvestorRosterTableRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {

    let newData = tableState.map((el, index) => {
      if (idx === index) {
        return getNewStateObject({
          state: el as { [name: string]: any },
          newValue: value,
          property,
          type
        }) as InvestorRosterUnion
      } else {
        return el
      }
    })
    setData(newData)
    handleChange(newData)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size py-2 mb-2"
        }
        key={`${tableName}-0`}
        id={`investorRosterTooltipContainer`}
      >
        {`Investor Roster`}
        <div className="d-inline-flex align-items-center tooltip-icon" id="investorRosterTooltip">
          <FontAwesomeIcon
            icon={"question-circle"}
            className="ml-2 mt-0"
            size="sm"
          />
        </div>
      </div>
      <div
        className={classNames(
          `${tableName}-table form-group pl-0`,
          {
            "col-sm-12": editMode,
            "col-sm-11": !editMode
          },
          "table-container"
        )}
      >
        <Table striped hover borderless size="sm" key={`${tableName}-table`} className={"exportable"} data-export-name={"Investment Roster"}>
          <thead className="">
            <tr className="table-header">
              {InvestorRosterTableColumnDef.map((header, index) => {
                const tooltip = header.tooltip
                return(
                  <th key={`${tableName}-header-${index}`}>
                    <div className="position-relative" id={tooltip ? `${tooltip}TooltipContainer` : undefined}>
                      {header.title}
                      {tooltip &&
                        <div className="d-inline-flex align-items-center tooltip-icon" id={`${tooltip}Tooltip`}>
                          <FontAwesomeIcon
                            icon={"question-circle"}
                            size="sm"
                          />
                        </div>
                      }
                    </div>
                  </th>
                )
              })}
              {editMode && (
                <th key={`Performance-header-edit`}>
                  <Button
                    color="link"
                    className="ml-auto"
                    onClick={() =>
                      onAddInvestorRosterTableRow({
                        investor: "",
                        advisorBoardSeat: false,
                        capitalCommitment: 0.0
                      })
                    }
                  >
                    Add Row
                    <FontAwesomeIcon
                      icon="plus-circle"
                      className="ml-2 text-blue-100"
                    />
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody key={0}>
            {tableState.map((row: any, idx: number) => {
              return (
                <InvestorRosterTableRow
                  columnDef={InvestorRosterTableColumnDef}
                  data={row}
                  key={idx}
                  idx={idx}
                  editMode={editMode}
                  action={actionMap}
                  updateValue={(value, type, property) =>
                    onHandleChangeInvestorRosterTableRow(
                      idx,
                      value,
                      type,
                      property
                    )
                  }
                />
              )
            })}
            <tr
              key={`{tableName}-${tableState.length}`}
              className={`border-top pl-0`}
            ></tr>
          </tbody>
        </Table>
        <div
          className="border-bottom-line-break row my-2"
          key={"Performance"}
        ></div>
      </div>
    </>
  )
}

export default InvestorRosterTableDisplay
