import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'

import { FormInputSubClasses } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'

type columnDefItem = {
  field: string
  type: string
  title: string
  className?: string
  subtype?: string
  optionSource?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
}

interface AssociatedSymbolsTableRowProps {
  data: any
  columnDef: columnDefItem[]
  idx: number
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

type AssociatedSymbolsTableProps = {
  data: any[]
  handleChange: (state: any) => void
  editMode: boolean
  hidden: boolean
}

const AssociatedSymbolsColumnDef: columnDefItem[] = [
  {
    field: "ticker",
    type: "text",
    title: "Ticker",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "col-sm-12 pl-1",
      wrapperClasses: "no-export-form"
    }
  },
  {
    field: "cusip",
    type: "text",
    title: "CUSIPs",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "col-sm-12 pl-1",
      wrapperClasses: "no-export-form"
    }
  }
]

const AssociatedSymbolsTableRow = ({
  data,
  columnDef,
  idx,
  editMode,
  updateValue,
  action
}: AssociatedSymbolsTableRowProps) => {
  return (
    <tr className={ editMode ? "hover-actions editing" : "hover-actions" }>
      {columnDef.map(header => {
        return (
          <td key={header.field}>
            <FormInput
              key={header.field}
              property={header.field}
              displayName={""}
              type={header.type}
              idx={idx}
              editMode={editMode}
              propertyVal={data[header.field]}
              updateValue={value =>
                updateValue(value, header.type, header.field)
              }
              optionSource={header.optionSource}
            />
          </td>
        )
      })}
      {editMode && (
        <td className="actions">
          <Button
            color="link"
            className="ml-auto px-0 btn-thin"
            disabled={!editMode}
            onClick={() => action.remove(idx)}
          >
            <FontAwesomeIcon icon="trash" className="ml-2 text-blue-100" />
          </Button>
        </td>
      )}
    </tr>
  )
}

const AssociatedSymbolsTableDisplay = (seed: AssociatedSymbolsTableProps) => {
  const tableName = "Associated-Symbols"
  const { data, editMode, handleChange, hidden } = seed
  const [tableState, setData] = useState(data)
  const [currentId, setId] = useState(1)

  useEffect(() => {
    setData(data)
  }, [data])
  const onAddAssociatedSymbolsRow = (row: any) => {
    let newData = [...tableState, row]
    setData(newData)
    handleChange(newData)
  }

  const onRemoveAssociatedSymbolsRow = (rowIndex: number) => {
    let newData = [
      ...tableState.slice(0, rowIndex),
      ...tableState.slice(rowIndex + 1)
    ]
    setData(newData)
    handleChange(newData)
  }
  const actionMap = {
    add: onAddAssociatedSymbolsRow,
    remove: onRemoveAssociatedSymbolsRow
  }

  const generateId = () => {
    // return a number as id
    let nextId = currentId + 1
    setId(nextId)
    return nextId
  }

  const onHandleChangeAssociatedSymbolsRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = tableState.map((el, index) => {
      if (idx === index) {
        return { ...tableState[index], [property]: value }
      } else {
        return el
      }
    })
    setData(newData)
    handleChange(newData)
  }

  return (
    <div
      className={classnames(`${tableName}-table row form-group pl-0`, {
        "col-sm-12": editMode,
        "col-sm-8": !editMode
      })}
    >
      <div className={"py-2"}>
        {
          "Supply all Tickers and CUSIPs for all Mutual Funds and CITs associated to this product."
        }
      </div>
      <Table
        hover
        size="sm"
        key={`AssociatedSymbols-table`}
        className={"exportable"}
        data-export-name={`AssociatedSymbols`}
      >
        <thead className="table-border-bottom">
          <tr className="table-header">
            {AssociatedSymbolsColumnDef.map((headerDef, idx) => (
              <th className="text-left" key={`AssociatedSymbols-header-${idx}`}>{headerDef.title}</th>
            ))}
            {editMode && (
              <th key={`AssociatedSymbols-header-2`}>
                <Button
                  color="link"
                  className="ml-auto px-0"
                  onClick={() => actionMap.add({ ticker: null, cusip: null, uniqueId: generateId() })}
                >
                  Add Row
                  <FontAwesomeIcon
                    icon="plus-circle"
                    className="ml-2 text-blue-100"
                  />
                </Button>
              </th>
            )}
          </tr>
        </thead>
        <tbody key={0}>
          {tableState.map((row: any, idx: number) => {
            return (
              <AssociatedSymbolsTableRow
                data={row}
                columnDef={AssociatedSymbolsColumnDef}
                key={row.uniqueId}
                idx={idx}
                editMode={editMode}
                action={actionMap}
                updateValue={(value, type, property) =>
                  onHandleChangeAssociatedSymbolsRow(idx, value, type, property)
                }
              />
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default AssociatedSymbolsTableDisplay
