import classnames from 'classnames'
import React, { useState } from 'react'
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap'

import ProductOverviewEmployee from './ProductOverviewEmployees'
import ProductOverviewEsg from './ProductOverviewEsg'
import ProductOverviewGPCommitments from './ProductOverviewGPCommitments'
import ProductOverviewGuidelines from './ProductOverviewGuidelines/ProductOverviewGuidelines'
import ProductOverviewManagement from './ProductOverviewManagement'
import ProductOverviewProcess from './ProductOverviewProcess'
import ProductOverviewSummary from './ProductOverviewSummary'
import ProductOverviewOutlook from './ProductOverviewOutlook'

const ProductOverviewSubTabs = {
  summary: "1",
  guidelines: "2",
  management: "3",
  employees: "4",
  gpcommitments: "5",
  process: "6",
  esg: "7",
  outlook: "8",
}

const getInitialTab = (subtype: string | undefined) => {
  if (!subtype) {
    return ProductOverviewSubTabs["summary"]
  }
  if (subtype in ProductOverviewSubTabs) {
    let s =
      ProductOverviewSubTabs[subtype as keyof typeof ProductOverviewSubTabs]
    return s
  } else {
    return ProductOverviewSubTabs["summary"]
  }
}

const ProductOverview: React.FC<any> = props => {
  const { id, outlookSurvey } = props.data.product.product
  const productType = props.data.product.__typename
  const params = useParams() as {
    subtype?: string
    type?: string
    productId?: number
  }
  const auth = props.auth

  const subtype = params.subtype || "summary"
  const [activeTab, setActiveTab] = useState(getInitialTab(subtype))
  const history = useHistory()
  const match = useRouteMatch() || props.match
  let urlWithoutSubtype =
    params && params.subtype
      ? match.url.slice(0, -params.subtype.length - 1)
      : match.url
  const handleRedirect = (tab: string) => {
    history.push(urlWithoutSubtype + "/" + tab)
  }
  const [showGpCommitments] = useState(
    ["ClosedEndedPrivateEquity", "ClosedEndedPrivateCredit", "ClosedEndedRealAssets"].indexOf(productType) > -1
  )
  const showOutlook = (!outlookSurvey?.inactive || auth.checkPermissions(["read:inactive_outlook_survey"])) && outlookSurvey?.editable
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Nav className="sub-nav sub-nav-secondary" tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      match.url.indexOf(`${urlWithoutSubtype}/summary`) === 0
                  })}
                  onClick={() => handleRedirect("summary")}
                >
                  Summary
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      match.url.indexOf(`${urlWithoutSubtype}/guidelines`) === 0
                  })}
                  onClick={() => handleRedirect("guidelines")}
                >
                  Guidelines
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      match.url.indexOf(`${urlWithoutSubtype}/management`) === 0
                  })}
                  onClick={() => handleRedirect("management")}
                >
                  Management
                </NavLink>
              </NavItem>
              {[
                "OpenEndedEquity",
                "OpenEndedPassiveEquity",
                "OpenEndedFixedIncome",
                "OpenEndedPassiveFixedIncome",
                "OpenEndedHedgeFund",
                "ClosedEndedHedgeFund",
                "OpenEndedMAC",
                "OpenEndedPassiveMAC",
                "OpenEndedTargetDate",
                "OpenEndedRealAssets",
                "OtherProduct",
                "OpenEndedPrivateCredit",
                "OpenEndedPrivateEquity",
              ].indexOf(productType) > -1 && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        match.url.indexOf(`${urlWithoutSubtype}/employees`) ===
                        0
                    })}
                    onClick={() => {
                      handleRedirect("employees")
                    }}
                  >
                    Employees
                  </NavLink>
                </NavItem>
              )}
              {showGpCommitments && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        match.url.indexOf(
                          `${urlWithoutSubtype}/gpcommitments`
                        ) === 0
                    })}
                    onClick={() => {
                      handleRedirect("gpcommitments")
                    }}
                  >
                    GP Commitments
                  </NavLink>
                </NavItem>
              )}
              {productType !== "OpenEndedTargetDate" && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active:
                        match.url.indexOf(`${urlWithoutSubtype}/process`) === 0
                    })}
                    onClick={() => {
                      handleRedirect("process")
                    }}
                  >
                    Process
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutSubtype}/esg`) === 0
                  })}
                  onClick={() => handleRedirect("esg")}
                >
                  ESG
                </NavLink>
              </NavItem>
              {showOutlook &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutSubtype}/outlook`) === 0
                    })}
                    onClick={() => handleRedirect("outlook")}
                  >
                    Outlook
                  </NavLink>
                </NavItem>
              }
            </Nav>
            <Switch>
              <Route
                exact
                path="/products/:productId(\d+)/overview/:subtype(summary)"
                component={() => <ProductOverviewSummary productId={id} auth={auth} />}
              />
              <Route
                exact
                path="/products/:productId(\d+)/overview/:subtype(guidelines)"
                component={() => <ProductOverviewGuidelines productId={id} auth={auth} />}
              />
              <Route
                exact
                path="/products/:productId(\d+)/overview/:subtype(management)"
                component={() => <ProductOverviewManagement productId={id} auth={auth} />}
              />
              <Route
                exact
                path="/products/:productId(\d+)/overview/:subtype(employees)"
                component={() => <ProductOverviewEmployee productId={id} auth={auth} />}
              />
              {showGpCommitments && (
                <Route
                  exact
                  path="/products/:productId(\d+)/overview/:subtype(gpcommitments)"
                  component={() => (
                    <ProductOverviewGPCommitments productId={id} auth={auth} />
                  )}
                />
              )}
              <Route
                // exact
                path="/products/:productId(\d+)/overview/:subtype(process)"
                component={() => <ProductOverviewProcess productId={id} auth={auth} />}
              />
              <Route
                exact
                path="/products/:productId(\d+)/overview/:subtype(esg)"
                component={() => <ProductOverviewEsg productId={id} auth={auth} />}
              />
              {showOutlook &&
                <Route
                  exact
                  path="/products/:productId(\d+)/overview/:subtype(outlook)"
                  component={() => <ProductOverviewOutlook productId={id} auth={auth} />}
                />
              }
              <Redirect
                from="/products/:productId(\d+)/overview"
                to="/products/:productId(\d+)/overview/summary"
              />
            </Switch>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductOverview
