import classnames from 'classnames'
import { sortBy, get, map, set, findIndex, cloneDeep, isEqual, find, uniqBy, isEmpty, isNil, flatten, remove, maxBy } from 'lodash'
import React, { Component, useState, useContext, useRef } from 'react'
import { Table, Row, Col, Button} from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import iassign from 'immutable-assign'
import moment, { Moment } from 'moment'
import omitDeep from 'omit-deep-lodash'
import deepMapKeys from 'deep-map-keys'
import { useHistory } from 'react-router-dom'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import { CalendarContext, appDate } from "../../Context/CalendarContext"
import { Maybe, useProductAssetsByAccountsQuery, ProductAssetsByAccountsQuery, ProductAccountTurnover, ProductAccountRanges, ClientType, useUpdateProductAssetsByAccountsSingleMutation, useUpdateProductAssetsByAccountsHistoricalMutation,  UpdateProductAssetsByAccountSizeSingleInput, UpdateProductAssetsByAccountSizeHistoricalInput, ProductAssetsByAccountSizeLargestAccountsHistoricalFields, ProductAssetsByAccountSizeLargestAccountsSingleFields, ProductAssetAccountRangeSingleInput, ProductAssetsByAccountSizeGainsAndLossesHistoricalFields, ProductAssetsByAccountSizeGainsAndLossesSingleFields, ProductAssetAccountRangeHistoricalInput } from '../../__generated__/graphql'
import { CalendarPicker } from '../CalendarPicker'
import PlaceHolder from '../ui/PlaceHolder'
import { FormInputField, DATE_API_FORMAT } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { listQuarters } from "../../helpers/helpers"
import ErrorDisplay from '../Shared/ErrorDisplay'
import exportTables from '../../helpers/exportTable'

interface ResultProps {
  setSearchDate: (searchDate:string) => void
  data: ProductAssetsByAccountsQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  historicView: boolean,
  onHistoricChange: (stateDiff:stateDiffType) => void,
  onSingleChange: (newState:singleStateDiff) => void,
  fetchMore: any,
}

interface HistoricalProps {
  data: ProductAssetsByAccountsQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  fetchMore: any
}

interface idProps {
  productId: number
  auth: Auth
}

interface AccountRangeInputField extends FormInputField{
  amountRange?: string
}

const firstHistoricalDate = moment(appDate).subtract(5,"years")

interface submissionDataHistoricType {
  gainsAndLosses: ProductAssetsByAccountSizeGainsAndLossesHistoricalFields[],
  largestAccounts: ProductAssetsByAccountSizeLargestAccountsHistoricalFields[],
  accountRanges: ProductAssetAccountRangeHistoricalInput[],
}

interface submissionDataSingleType {
  date: string
  gainsAndLosses: ProductAssetsByAccountSizeGainsAndLossesSingleFields | null
  largestAccounts: ProductAssetsByAccountSizeLargestAccountsSingleFields[],
  accountRanges: ProductAssetAccountRangeSingleInput[],
}

interface stateDiffType {
  accountTurnover: ProductAccountTurnover[],
  largestAccounts: ProductAssetsAccountsLargestFragment[],
  accountRanges: ProductAccountRanges[]
}

interface singleStateDiff {
  accountTurnover: ProductAccountTurnover | null,
  largestAccounts: ProductAssetsAccountsLargestFragment | null,
  accountRanges: ProductAccountRanges | null
}


const emptyStateDiff:stateDiffType = {
  accountTurnover: [],
  largestAccounts: [],
  accountRanges: []
}

const ProductAssetsAccounts: React.FC<idProps> = ({ productId, auth }: idProps) => {
  const context = useContext(CalendarContext)
  const history = useHistory()
  const [searchDate, setSearchDate] = useState(context.quarter)//"2019-03-31")//context.quarter)
  const resultRef = useRef<Result>(null)

  const [screen, setScreen] = useState(context.period === "historical" ? "historical" :"single")
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)

  let emptySingleSubmissionData:submissionDataSingleType = {
    date: '',
    gainsAndLosses: null,
    largestAccounts: [],
    accountRanges: [],
  }

  let emptyHistoricSubmissionData:submissionDataHistoricType = {
    gainsAndLosses: [],
    largestAccounts: [],
    accountRanges: [],
  }

  const [submissionSingleData, setSubmissionSingleData] = useState(emptySingleSubmissionData)
  const [submissionHistoricData, setSubmissionHistoricData] = useState(emptyHistoricSubmissionData)

  const [updateProductAssetsByAccountSingle] = useUpdateProductAssetsByAccountsSingleMutation()
  const [updateProductAssetsByAccountHistoric] = useUpdateProductAssetsByAccountsHistoricalMutation()

  let { data, loading, error, fetchMore } =
  useProductAssetsByAccountsQuery({
    variables: {
      id: productId,
      startDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT),
      endDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT)
    },
    // partialRefetch: true,
    fetchPolicy: 'network-only'
  })

  const handleEdit = () => {
    resultRef.current?.resetHistory()
    setSubmissionSingleData(emptySingleSubmissionData)
    setSubmissionHistoricData(emptyHistoricSubmissionData)
    setEditMode(!editMode)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    if (screen === "single") {

      if (submissionSingleData?.gainsAndLosses) {
        let submission:UpdateProductAssetsByAccountSizeSingleInput = {
          id: productId,
          date: submissionSingleData?.date,
          gainsAndLosses: submissionSingleData?.gainsAndLosses,
          largestAccounts: submissionSingleData?.largestAccounts,
          accountRanges: submissionSingleData?.accountRanges
        }

        remove(submission.largestAccounts || [], (la) => {
          return la.aggregateSize === null || la.aggregateSize === undefined
        })

        updateProductAssetsByAccountSingle({ variables: { input: submission, startDate: searchDate, endDate: searchDate } })
          .then(result => {
            setSaving(false)
            if (result && result.data) {
              let unformattedNewData = { assets: result.data.product?.product }
              resultRef.current?.resetHistory()
              setEditMode(false)
            }
          })
          .catch(err => {
            setSaving(false)
            console.error("Error updateProductAssetsByAccountSingle", err.message)
          })
      }else {
        setSaving(false)
        setEditMode(false)
      }
    } else {
      let submission:UpdateProductAssetsByAccountSizeHistoricalInput = {
        id: productId,
        gainsAndLosses: submissionHistoricData?.gainsAndLosses,
        largestAccounts: submissionHistoricData?.largestAccounts,
        accountRanges: submissionHistoricData?.accountRanges
      }

      remove(submission.largestAccounts || [], (la) => {
        return la.aggregateSize === null || la.aggregateSize === undefined
      })

      updateProductAssetsByAccountHistoric({ variables: { input: submission, startDate: firstHistoricalDate.format(DATE_API_FORMAT), endDate: appDate.format(DATE_API_FORMAT) } })
        .then(result => {
          setSaving(false)
          if (result && result.data) {
            let unformattedNewData = { assets: result.data.product?.product }
            resultRef.current?.resetHistory()
            setEditMode(false)
          }
        })
        .catch(err => {
          setSaving(false)
          console.error("Error updateProductAssetsByAccountHistoric", err.message)
        })
    }

  }

  const handleSingleChange = (newState: singleStateDiff) => {
    // const turnover = stateDiff.accountTurnover[0]
    // const largestAccounts = stateDiff.largestAccounts[0] ? omitDeep(stateDiff.largestAccounts[0].accounts, '__typename') : []
    // const accountRanges = stateDiff.accountRanges[0] ? omitDeep(stateDiff.accountRanges[0], '__typename') : []
    let { accountTurnover, largestAccounts, accountRanges } = newState
    const accountTurnoverSubmit:ProductAssetsByAccountSizeGainsAndLossesSingleFields = {
      assetsUnderManagementGained: accountTurnover?.gained?.assets,
      numberOfAccountsGained: accountTurnover?.gained?.accounts,
      assetsUnderManagementLost: accountTurnover?.lost?.assets,
      numberOfAccountsLost: accountTurnover?.lost?.accounts,
      percentGained: accountTurnover?.gained?.percentChange,
      percentLost: accountTurnover?.lost?.percentChange
    }

    const largestAccountsSubmit:ProductAssetsByAccountSizeLargestAccountsSingleFields[] = largestAccounts?.accounts?.map((account) => {
      let subLargestAccounts:ProductAssetsByAccountSizeLargestAccountsSingleFields = {
        rank: account?.rank,
        accountType: account?.type?.code,
        aggregateSize: account?.size
      }
      return subLargestAccounts
    }) || []

    // let a:ProductAccountRanges
    // let b:ProductAccountRangeItem
    const accountRangesSubmit:ProductAssetAccountRangeSingleInput = deepMapKeys(omitDeep(accountRanges, ['__typename', 'quarterEndDate']), (key) => {
      if (key === 'accountsInRange') return 'totalAccountsInRange'
      if (key === 'assetsInRange') return 'totalAssetsInRange'
      return key
    })

    let subData:submissionDataSingleType = {
      date: searchDate,
      gainsAndLosses: accountTurnoverSubmit,
      largestAccounts: largestAccountsSubmit,
      accountRanges: accountRangesSubmit ? [accountRangesSubmit] : []
    }

    // if (turnover) {
    //   subData.gainsAndLosses = {
    //     assetsUnderManagementGained: turnover.gained?.assets,
    //     numberOfAccountsGained: turnover.gained?.accounts,
    //     assetsUnderManagementLost: turnover.lost?.assets,
    //     numberOfAccountsLost: turnover.lost?.accounts,
    //     percentGained: turnover.gained?.percentChange,
    //     percentLost: turnover.lost?.percentChange
    //   }
    // }

    setSubmissionSingleData(subData)
  }

  const handleHistoricChange = (stateDiff:stateDiffType) => {
    let subData:submissionDataHistoricType = {
      gainsAndLosses: [],
      largestAccounts: [],
      accountRanges: []
    }

    if (stateDiff.accountTurnover) {
      stateDiff.accountTurnover.map((account, index) => {
        let subAccountTurnover:ProductAssetsByAccountSizeGainsAndLossesHistoricalFields = {
          date: account.quarterEndDate,
          assetsUnderManagementGained: account.gained?.assets,
          numberOfAccountsGained: account.gained?.accounts,
          assetsUnderManagementLost: account.lost?.assets,
          numberOfAccountsLost: account.lost?.accounts,
          percentGained: account.gained?.percentChange,
          percentLost: account.lost?.percentChange
        }
        return subData.gainsAndLosses && subData.gainsAndLosses.push(subAccountTurnover)
      })
    }


    if (stateDiff.largestAccounts) {
      stateDiff.largestAccounts.forEach((largestAccount, index) => {
        largestAccount?.accounts?.forEach((account) => {
          if (account?.rank && account?.rank > 0) {
            let subLargestAccounts:ProductAssetsByAccountSizeLargestAccountsHistoricalFields = {
              date: largestAccount.quarterEndDate,
              rank: account?.rank,
              accountType: account?.type?.code,
              aggregateSize: account?.size
            }

            subData.largestAccounts && subData.largestAccounts.push(subLargestAccounts)
          }
        })
      })
    }

    if (stateDiff.accountRanges) {
      stateDiff.accountRanges.map((account, index) => {
        let subAccountRanges:ProductAssetAccountRangeHistoricalInput = omitDeep(account, '__typename')
        subAccountRanges = deepMapKeys(subAccountRanges, (key) => {
          if (key === 'accountsInRange') return 'totalAccountsInRange'
          if (key === 'assetsInRange') return 'totalAssetsInRange'
          return key
        })

        return subData.accountRanges && subData.accountRanges.push(subAccountRanges)
      })
    }

    setSubmissionHistoricData(subData)
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={true}
          updateType={(type:string) => setScreen(type)}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        {screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>resultRef.current?.loadMore()}>
            {resultRef.current?.state.loadingMore && "Loading"}
            {!resultRef.current?.state.loadingMore && "Load 5 More Years"}
          </Button>
        }
        <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables()}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} cancelEdit={handleEdit}/>
        }
      </div>
    </>
  )

  if (loading) {
    return (
      <>
        {heading}
        <div className='pane pane-table'>
          <PlaceHolder />
        </div>
      </>
    );
  }
  if (error) {
    return (
      <>
        {heading}
        <ErrorDisplay error={error}/>
      </>
    );
  }
  if (data && data.product ) {
    return (
      <>
        {heading}
        <Result
          key={searchDate}
          editMode={editMode}
          setEditMode={setEditMode}
          setSearchDate={setSearchDate}
          data={data}
          searchDate={searchDate}
          historicView={ screen === 'historical'}
          onHistoricChange={handleHistoricChange}
          onSingleChange={handleSingleChange}
          fetchMore={fetchMore}
          ref={resultRef}
        />
      </>
    )
  }

  return <></>
}


interface ResultState {
  initialState: ProductAssetsByAccountsQuery,
  currentState: ProductAssetsByAccountsQuery,
  stateDiff: stateDiffType,
  loadingMore: boolean,
  historicalDate: Moment,
}

class Result extends Component<ResultProps> {
  state:ResultState = {
    initialState: this.props.data,
    currentState: this.props.data,
    stateDiff: emptyStateDiff,
    loadingMore: false,
    historicalDate: firstHistoricalDate
  }

  static getDerivedStateFromProps(props: ResultProps, state:ResultState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    return {
      currentState: resetDate && !props.editMode ? props.data : state.currentState,
      initialState: resetDate ? props.data : state.initialState,
      stateDiff: props.editMode ? state.stateDiff : emptyStateDiff,
      loadingMore: state.loadingMore,
      historicalDate: state.historicalDate
    }
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate), stateDiff: emptyStateDiff })
  }

  handleInputChange = (
    tableName: string,
    newState: ProductAccountTurnover[] | ProductAssetsAccountsLargestFragment[] | ProductAccountRanges[]
  ) => {
    let oldState = cloneDeep(this.state.currentState)

    if(!(oldState?.product?.product as any)[tableName]){
      set(oldState, ["product", "product", tableName], [])
    }
    let newCurrentState = iassign(
      oldState,
      ['product', 'product', tableName],
      () => newState
    )

    // Create State Diff
    if (this.state.initialState?.product?.product && newCurrentState?.product?.product ) {

      const { accountTurnover:newAccountTurnover, largestAccounts:newLargestAccounts, accountRanges:newAccountRanges} = newCurrentState?.product.product
      const { accountTurnover:oldAccountTurnover, largestAccounts:oldLargestAccounts, accountRanges:oldAccountRanges } = this.state.initialState.product.product

      let submissionData:stateDiffType = {
        accountTurnover: [],
        largestAccounts: [],
        accountRanges: []
      }

      if (newAccountTurnover && oldAccountTurnover && !isEqual(newAccountTurnover, oldAccountTurnover)) {
        newAccountTurnover.forEach((account, index) => {
          let oldAccount = find(oldAccountTurnover, { quarterEndDate: account?.quarterEndDate })

          if (account && !isEqual(account, oldAccount)) {
           submissionData?.accountTurnover?.push(account)
          }
        })
      }

      if (newLargestAccounts && oldLargestAccounts && !isEqual(oldLargestAccounts, newLargestAccounts)) {
        newLargestAccounts.forEach((account, index) => {
          let oldAccount = find(oldLargestAccounts, { quarterEndDate: account?.quarterEndDate })

          if (account && !isEqual(account, oldAccount)) {
            submissionData?.largestAccounts?.push(account)
          }
        })
      }

      if (newAccountRanges && oldAccountRanges && !isEqual(oldAccountRanges, newAccountRanges)) {
        newAccountRanges.forEach((account, index) => {
          let oldAccount = find(oldAccountRanges, { quarterEndDate: account?.quarterEndDate })

          if (account && !isEqual(account, oldAccount)) {
            submissionData?.accountRanges?.push(account)
          }
        })
      }

      if (this.props.historicView) {
        this.props.onHistoricChange(submissionData)
      } else {
        let singleSubmissionData:singleStateDiff

        singleSubmissionData = {
          accountTurnover: newAccountTurnover ? newAccountTurnover[0] : null,
          largestAccounts: newLargestAccounts ? newLargestAccounts[0] : null,
          accountRanges: newAccountRanges ? newAccountRanges[0] : null
        }

        this.props.onSingleChange(singleSubmissionData)
      }

      this.setState({ currentState: newCurrentState, stateDiff: submissionData })
    }
  }

  loadMore = () => {
    if(this.state.loadingMore){
      return
    }
    this.setState({ loadingMore: true})
    this.props.fetchMore({
      variables: {
        id: this.state.currentState.product?.product?.id,
        startDate: moment(this.state.historicalDate).subtract(5, "years").format(DATE_API_FORMAT),
        endDate: this.state.historicalDate.format(DATE_API_FORMAT),
      },
      updateQuery: (previousResult:ProductAssetsByAccountsQuery, { fetchMoreResult } :any) => {
        if (!fetchMoreResult) return previousResult;

        const previousProduct = previousResult.product?.product
        const newProduct = fetchMoreResult.product?.product

        if (!previousProduct || !this.state.currentState?.product?.product ){
          return
        }

        const returnedState = {
          product: { __typename: 'OpenEndedEquity', product: {
            id: this.state.currentState.product?.product?.id,
            accountTurnover: uniqBy([...(previousProduct.accountTurnover || []), ...newProduct.accountTurnover], 'quarterEndDate'),
            accountRanges: uniqBy([...(previousProduct.accountRanges || []), ...newProduct.accountRanges], 'quarterEndDate'),
            largestAccounts: uniqBy([...(previousProduct.largestAccounts || []), ...newProduct.largestAccounts], 'quarterEndDate'),
            managerTypes: newProduct.managerTypes,
            __typename: previousProduct.__typename
          },
        }}

        const currentState = {
          product: { __typename: 'OpenEndedEquity', product: {
            id: this.state.currentState.product?.product?.id,
            accountTurnover: uniqBy([...(this.state.currentState.product.product.accountTurnover || []), ...newProduct.accountTurnover],'quarterEndDate'),
            accountRanges: uniqBy([...(this.state.currentState.product.product.accountRanges || []), ...newProduct.accountRanges], 'quarterEndDate'),
            largestAccounts: uniqBy([...(this.state.currentState.product.product.largestAccounts || []), ...newProduct.largestAccounts], 'quarterEndDate'),
            __typename: previousProduct.__typename
          },
        }}

        this.setState({
          initialState: returnedState,
          currentState: currentState,
          historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
          loadingMore: false
        })

        return previousResult;
      }
    })
  }

  render() {
    const { editMode, historicView, searchDate } = this.props

    let accountTurnover:ProductAccountTurnover[] = get(this.state.currentState, 'product.product.accountTurnover', [])
    let largestAccounts:ProductAssetsAccountsLargestFragment[] = get(this.state.currentState, 'product.product.largestAccounts', [])
    let accountRanges:ProductAccountRanges[] = get(this.state.currentState, 'product.product.accountRanges', [])

    if (!historicView) {
      accountTurnover = !isEmpty(accountTurnover) ? [find(accountTurnover, { quarterEndDate: searchDate }) as ProductAccountTurnover] : []
      largestAccounts = !isEmpty(largestAccounts) ? [find(largestAccounts, { quarterEndDate: searchDate }) as ProductAssetsAccountsLargestFragment] : []
      accountRanges = !isEmpty(accountRanges) ? [find(accountRanges, { quarterEndDate: searchDate }) as ProductAccountRanges] : []
    }
    // const managerTypes = get(this.state.currentState, 'assets.managerTypes', [])
    // const managerType = managerTypeBreakdown(managerTypes)

    const defaultProps = { editMode, historicView, searchDate, historicalDate: this.state.historicalDate, product: this.state.currentState.product}
    return (
      <div className="pane pane-table">
        <div className="pane-title-alt">
          <h3 id="accountsAssetsGainedLostTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="accountsAssetsGainedLostTooltip">
              Accounts
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                className="ml-2 mt-0"
                size="sm"
              />
            </div>
          </h3>
        </div>
        <Row>
          <Col md="10">
           </Col>
        </Row>
        <Row>
          <Col md="6">
            <h4 className="text-center">Accounts &amp; Assets Gained</h4>
          </Col>
          <Col md="6">
            <h4 className="text-center">Accounts &amp; Assets Lost</h4>
          </Col>
        </Row>
        <AccountsGainedLostTable
          accountTurnover={accountTurnover}
          onChange={((newTable:ProductAccountTurnover[]) => this.handleInputChange('accountTurnover', newTable))}
          {...defaultProps}
        />
        <Row>
          <Col md={6} className="mt-4">
            <h4 className='text-center'>5 Largest Accounts by Aggregate Size ($M)</h4>
            <AccountsLargestTable
              largestAccounts={largestAccounts}
              onChange={((newTable:ProductAssetsAccountsLargestFragment[]) => this.handleInputChange('largestAccounts', newTable))}
              {...defaultProps}
            />
          </Col>
          <Col md={6} className="mt-4">
            <h4 className='text-center'>Assets &amp; Accounts by Mandate Size ($M)</h4>
            <AccountsManadateSizeTable
              accountRanges={accountRanges}
              onChange={((newTable:ProductAccountRanges[]) => this.handleInputChange('accountRanges', newTable))}
              // managerType={managerType}
              {...defaultProps}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

const AccountsGainedFields:FormInputField[] = [
  { property: "gained.accounts", label: "", type: "number", placeholder: '' },
  { property: "gained.assets", label: "", type: "float", subtype: "currency", placeholder: '' },
  { property: "gained.percentChange", label: "", type: "float", subtype: 'percent', placeholder: '' },
]
const AccountsLostFields:FormInputField[] = [
  { property: "lost.accounts", label: "", type: "number", placeholder: ''  },
  { property: "lost.assets", label: "", type: "float", subtype: "currency", placeholder: '' },
  { property: "lost.percentChange", label: "", type: "float", subtype: 'percent', placeholder: '' },
]

const AccountsGainedLostTable = ({ accountTurnover, editMode, historicView, onChange, searchDate, historicalDate, product}: {
  accountTurnover:ProductAccountTurnover[],
  editMode: boolean,
  historicView: boolean,
  historicalDate: Moment,
  searchDate: string,
  onChange: (newTable:ProductAccountTurnover[]) => void,
  product: ProductAssetsByAccountsQuery["product"],
} ) => {

  const generateBlankTurnover = (date:string):ProductAccountTurnover => {
    return {
      __typename: 'ProductAccountTurnover',
      quarterEndDate: date,
      gained: {
        __typename: 'ProductAccountTurnoverItem',
        accounts: null,
        assets: null,
        percentChange: null
      },
      lost: {
        __typename: 'ProductAccountTurnoverItem',
        accounts: null,
        assets: null,
        percentChange: null
      }
    }
  }

  if (accountTurnover.length === 0) {
    let blankData = generateBlankTurnover(searchDate)

    accountTurnover.push(blankData)
  } else {
    accountTurnover = sortBy(accountTurnover, [a => a?.quarterEndDate]).reverse()
  }

  const handleChange = (quarter:any, property:string, value:any) => {
    const dateIndex = findIndex(accountTurnover, (acc) => { return acc.quarterEndDate === quarter })
    let newTable = cloneDeep(accountTurnover)

    if (dateIndex < 0) {
      let blankTurnover = generateBlankTurnover(quarter)
      set(blankTurnover, property, value)
      newTable.push(blankTurnover)
    } else {
      newTable = iassign(
        newTable,
        [dateIndex],
        (acc:ProductAccountTurnover | undefined) => {

          let newAcc = cloneDeep(acc)
          if (newAcc) {
            set(newAcc, property, value)
            return newAcc
          }
        }
      )
    }
    onChange(newTable)
  }

  const accountsGainedRowEntry = (turnover:ProductAccountTurnover, row:number) => {
    return (
      <tr className={classnames({'fadein fadein-yellow': historicView })} key={`aseet-turnover-${turnover.quarterEndDate}`}>
        { historicView ? (<td className='text-nowrap'>{turnover.quarterEndDate}</td>) : <></>}
        {AccountsGainedFields.map(({property, label, type, subtype, placeholder, optionSource, readonly, subClasses, required}, idx) => {
          let propertyVal, onChangeCallback
          propertyVal = get(turnover, property)
          onChangeCallback = (value:any) => { handleChange(turnover.quarterEndDate, property, value)}
          if (property === "quarterEndDate" && !historicView) return <></>

          return(
            <td key={idx}>
              <FormInput
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={idx + (row*AccountsGainedFields.length)}
                editMode={editMode}
                showZero={true}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                readonly={readonly}
                subClasses={subClasses}
                required={required}
              />
            </td>
          )
        })}
      </tr>
    )
  }

  const accountsLostRowEntry = (turnover:ProductAccountTurnover, row:number) => {
    return (
      <tr className={classnames({'fadein fadein-yellow': historicView })} key={`aseet-turnover-${turnover.quarterEndDate}`}>
        {AccountsLostFields.map(({property, label, type, subtype, placeholder, optionSource, readonly, subClasses}, idx) => {
          let propertyVal, onChangeCallback
          propertyVal = get(turnover, property)
          onChangeCallback = (value:any) => { handleChange(turnover.quarterEndDate, property, value)}
          if (property === "quarterEndDate" && !historicView) return <></>

          return(
            <td key={idx}>
              <FormInput
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                placeholder={placeholder}
                idx={idx + (row*AccountsLostFields.length)}
                editMode={editMode}
                showZero={true}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                readonly={readonly}
                subClasses={subClasses}
              />
            </td>
          )
        })}
      </tr>
    )
  }

  const allDates = listQuarters(historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

  return (
    <Row>
      <Col md={6}>
        <Table hover className="table-bordered-internal exportable" data-export-name={`${product?.product?.name}-client`}>
          <thead>
            <tr className="row-border-olive-100">
              { historicView ? <th>Date</th> : <></> }
              <th>Accounts</th>
              <th>Assets ($M)</th>
              <th id="assetsPercentGainedTooltipContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="assetsPercentGainedTooltip">
                  % Gained
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            { historicView &&
              allDates.map((date:string, row:number) => {
                let turnover = accountTurnover.find(t => t.quarterEndDate === date)
                if (isNil(turnover)){
                  turnover = generateBlankTurnover(date)
                }

                return accountsGainedRowEntry(turnover, row)
              })
            }

            { !historicView &&
              accountTurnover.map( (turnover:ProductAccountTurnover, row:number) => {
                return accountsGainedRowEntry(turnover, row)
              })
            }
          </tbody>
        </Table>
      </Col>
      <Col md={6}>
        <Table hover className="table-bordered-internal exportable" data-export-name={`${product?.product?.name}-client2`}>
          <thead>
            <tr className="row-border-olive-100">
              <th>Accounts</th>
              <th>Assets ($M)</th>
              <th id="assetsPercentLostTooltipContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="assetsPercentLostTooltip">
                  % Lost
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            { historicView &&
              allDates.map((date:string, row:number) => {
                let turnover = accountTurnover.find(t => t.quarterEndDate === date)
                if (isNil(turnover)){
                  turnover = generateBlankTurnover(date)
                }

                return accountsLostRowEntry(turnover, row)
              })
            }

            { !historicView &&
              accountTurnover.map( (turnover:ProductAccountTurnover, row:number) => {
                return accountsLostRowEntry(turnover, row)
              })
            }
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

const AccountsLargestFields:FormInputField[] = [
  { property: "type.code", label: "", type: "select", placeholder: '', subtype: "single", optionSource: "ClientType"},
  { property: "size", label: "", type: "float", subtype: "currency", placeholder: '' },
]

type ProductAssetsAccountsLargestFragment = {
  __typename: 'ProductLargestAccounts';
  quarterEndDate?: Maybe<any>;
  accounts?: Maybe<Maybe<{
      __typename: 'ProductLargestAccountItem';
      rank: number;
      size?: number;
      type: {
          __typename: 'ClientTypeLookup';
          code: any;
          value?: Maybe<string>;
      };
  }>[]> | undefined;
}

const AccountsLargestTable = ({largestAccounts, editMode, historicView, onChange, searchDate, historicalDate}: {
  largestAccounts:ProductAssetsAccountsLargestFragment[],
  editMode:boolean,
  historicView: boolean,
  historicalDate: Moment,
  searchDate: string,
  onChange: (newTable:ProductAssetsAccountsLargestFragment[]) => void
}) => {

  const generateBlankAccounts = (date:string):ProductAssetsAccountsLargestFragment => {
    return {
      __typename: 'ProductLargestAccounts',
      quarterEndDate: date,
      accounts: [1,2,3,4,5].map( key => {
        return {
          __typename: 'ProductLargestAccountItem',
          rank: key,
          type: { __typename: 'ClientTypeLookup', code: 'Please Select', value: null },
          size: undefined}
        }
      )
    }
  }

  const fillRanks = (productLargestAccounts: ProductAssetsAccountsLargestFragment): ProductAssetsAccountsLargestFragment => {
    let productLargestAccountsEdited = cloneDeep(productLargestAccounts) || {}
    let accountArray = cloneDeep(sortBy(productLargestAccountsEdited.accounts, [a => a?.rank])) || []
    const maxRank = maxBy(productLargestAccounts.accounts, (account) => account?.rank)

    for (let i = 1; i <= (Math.max(5, maxRank?.rank || 0)); i++){
      if (accountArray.length < i) {
        accountArray.push({
          __typename: 'ProductLargestAccountItem',
          rank: i,
          type: { __typename: 'ClientTypeLookup', code: 'Please Select', value: null },
          size: undefined
        })
      } else if (accountArray[i - 1]?.rank !== i) {
        accountArray.splice(i-1,0,{
          __typename: 'ProductLargestAccountItem',
          rank: i,
          type: { __typename: 'ClientTypeLookup', code: 'Please Select', value: null },
          size: undefined
        })
      }
    }
    productLargestAccountsEdited.accounts = accountArray
    return productLargestAccountsEdited
  }

  if (largestAccounts.length === 0) {
    let blankData = generateBlankAccounts(searchDate)

    largestAccounts.push(blankData)
  } else {
    largestAccounts = sortBy(largestAccounts, [a => a?.quarterEndDate]).reverse()
  }

  const handleChange = (quarter: any, rank: number, property: string, value: any) => {
    const dateIndex = findIndex(largestAccounts, (acc) => {
      return acc.quarterEndDate === quarter;
    });
    let newTable = cloneDeep(largestAccounts);

    if (dateIndex < 0) {
      const updatedAccounts = generateBlankAccounts(quarter);
      const accIndex = findIndex(updatedAccounts.accounts, (acc) => {
        return acc?.rank === rank;
      });
      set(updatedAccounts, flatten(['accounts', accIndex, property.split('.')]), value);
      newTable.push(updatedAccounts);
    } else {
      const updatedAccounts = fillRanks(newTable[dateIndex]);
      const accIndex = findIndex(updatedAccounts.accounts, (acc) => acc?.rank === rank);

      let accounts = cloneDeep(updatedAccounts)
      const newAccount = iassign(
        accounts,
        ['accounts', accIndex],
        (acc: ProductAssetsAccountsLargestFragment | undefined) => {
          let newAcc = cloneDeep(acc);
          if (newAcc) {
            set(newAcc, property, value);
            return newAcc;
          }
        }
      );
      newTable[dateIndex] = newAccount;
    }
    onChange(newTable);
  };

  const rowEntry = (accountSet:ProductAssetsAccountsLargestFragment, count:number) => {
    let accounts = accountSet.accounts

    if (!accounts) return <></>
    accounts = sortBy(accounts, [a => a?.rank])

    return accounts.map((account, subCount) => {
      if (!account) return <></>


      return (
        <tr key={subCount} className={classnames({ 'row-border-olive-100': historicView && count !== 0 && subCount === 0, 'fadein fadein-yellow': historicView })}>
          { historicView ? (<td className='text-nowrap'>{accountSet.quarterEndDate}</td>) : <></>}
          <td>{ account.rank}</td>
          {AccountsLargestFields.map(({property, label, type, subtype, placeholder, optionSource, readonly}, idx) => {
            let propertyVal, onChangeCallback
            propertyVal = get(account, property)
            onChangeCallback = (value:any) => { handleChange(accountSet.quarterEndDate, account.rank, property, value)}
            if (property === "quarterEndDate" && !historicView) return <></>

            return(
              <td key={`largest-${subCount}-${idx}`}>
                <FormInput
                  property={property}
                  displayName={label}
                  type={type}
                  subtype={subtype}
                  placeholder={placeholder}
                  idx={idx + (subCount*AccountsGainedFields.length)}
                  editMode={editMode}
                  showZero={true}
                  propertyVal={propertyVal}
                  updateValue={onChangeCallback}
                  optionSource={optionSource}
                  readonly={readonly}
                />
              </td>
            )
          })}
        </tr>
      )
    })
  }

  const allDates = listQuarters(historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

  return (
    <Table className="table-bordered-internal exportable" data-export-name={`5 largest accounts by aggregate size`}>
      <thead>
        <tr className="row-border-olive-100">
          { historicView ? <th>Date</th> : <></> }
          <th id="assetsRankTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsRankTooltip">
              Rank
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                size="sm"
              />
            </div>
          </th>
          <th>Account Type</th>
          <th className='text-nowrap'>Aggregate Account Size</th>
        </tr>
      </thead>
      <tbody>
        { historicView &&
          allDates.map((date:string, row:number) => {
            let account = largestAccounts.find(t => t.quarterEndDate === date)
            if (isNil(account)){
              account = generateBlankAccounts(date)
            }
            account = fillRanks(account)
            return rowEntry(account, row)
          })
        }

        { !historicView &&
          largestAccounts.map((accountSet: ProductAssetsAccountsLargestFragment, row: number) => {
            const account = fillRanks(accountSet)
            return rowEntry(account, row)
          })
        }
      </tbody>
    </Table>
  )
}

const AccountsMandateSizeFields:AccountRangeInputField[] = [
  { property: "assetsInRange", label: "", type: "float", subtype: "currency", placeholder: ''},
  { property: "accountsInRange", label: "", type: "number", subtype: "", placeholder: ''},
]

const AccountsMandateRangeToName:{[key:string]: string} = {
  "lessThanOneMillion": "< $1 Million",
  "oneMillionToTenMillion": "$1 Million - $10 Million",
  "tenMillionToOneHundredMillion": "$10 Million - $100 Million",
  "oneHundredMillionToFiveHundredMillion": "$100 Million - $500 Million",
  "moreThanFiveHundredMillion": "> $500 Million"
}

const AccountsManadateSizeTable = ({accountRanges, editMode, historicView, onChange, searchDate, historicalDate}:{
  accountRanges: ProductAccountRanges[],
  editMode: boolean,
  historicView: boolean,
  historicalDate: Moment,
  onChange: (newTable:ProductAccountRanges[]) => void,
  searchDate: string
}) => {
  const allDates = listQuarters(historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

  const generateBlankRange = (date:string):ProductAccountRanges => {
    return {
      __typename: 'ProductAccountRanges',
      quarterEndDate: date,
      lessThanOneMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ProductAccountRangeItem' },
      oneMillionToTenMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ProductAccountRangeItem' },
      tenMillionToOneHundredMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ProductAccountRangeItem' },
      oneHundredMillionToFiveHundredMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ProductAccountRangeItem' },
      moreThanFiveHundredMillion: { assetsInRange: null, accountsInRange: null, __typename: 'ProductAccountRangeItem' }
    }
  }

  if (accountRanges.length === 0) {
    let blankData: ProductAccountRanges = generateBlankRange(searchDate)
    accountRanges.push(blankData)
  } else {
    accountRanges = sortBy(accountRanges, [a => a?.quarterEndDate]).reverse()
  }

  const handleChange = (quarter:any, rangeName:string, property:string, value:any) => {
    const rangeIndex = findIndex(accountRanges, (range) => { return range.quarterEndDate === quarter })
    let newTable = cloneDeep(accountRanges)

    if (rangeIndex < 0) {
      let blankRange = generateBlankRange(quarter)
      set(blankRange, [rangeName, property], value)
      newTable.push(blankRange)
    } else {
      newTable = iassign(
        newTable,
        [rangeIndex],
        (range:ProductAccountRanges | undefined) => {

          let newRange = cloneDeep(range)
          if (newRange) {
            set(newRange, [rangeName,property], value)
            return newRange
          }
        }
      )
    }
    onChange(newTable)


    // const rangeIndex = findIndex(accountRanges, (range) => { return range.quarterEndDate === quarter })
    // let newTable = iassign(
    //   accountRanges,
    //   [rangeIndex],
    //   (range:ProductAccountRanges | undefined) => {
    //     let newRange = cloneDeep(range)
    //     if (newRange) {
    //       set(newRange, [rangeName,property], value)
    //       return newRange
    //     }
    //   }
    // )
    // onChange(newTable)
  }

  const rowEntry = (accountRange:ProductAccountRanges, count:number) => {
    if (!accountRange) return <></>

    const { lessThanOneMillion, oneMillionToTenMillion, tenMillionToOneHundredMillion, oneHundredMillionToFiveHundredMillion, moreThanFiveHundredMillion } = accountRange

    const ranges = {lessThanOneMillion, oneMillionToTenMillion, tenMillionToOneHundredMillion, oneHundredMillionToFiveHundredMillion, moreThanFiveHundredMillion}

    return (
      <React.Fragment key={`account-range-set-${count}`}>
        {
          map(ranges, (rangeData, rangeName) => {
            return (
              <tr key={`account-ranges-${rangeName}`} className={classnames({ 'row-border-olive-100': historicView && rangeName === "lessThanOneMillion", 'fadein fadein-yellow': historicView })}>
                { historicView ? (<td className='text-nowrap' key={`${rangeName}-date`}>{accountRange.quarterEndDate}</td>) : <></>}
                <td key={`${rangeName}-name`} className='text-left text-nowrap'>{ AccountsMandateRangeToName[rangeName] }</td>
                {AccountsMandateSizeFields.map(({property, label, type, subtype, placeholder, optionSource, readonly }, idx) => {
                  let propertyVal, onChangeCallback
                  propertyVal = get(accountRange, `${rangeName}.${property}`)
                  onChangeCallback = (value:any) => { handleChange(accountRange.quarterEndDate, rangeName, property, value)}

                  // if (property === "accountsInRange") { return <></> }

                  return (<td key={`${rangeName}-${idx}`}>
                    <FormInput
                      property={`${rangeName}.${property}`}
                      type={type}
                      subtype={subtype}
                      placeholder={placeholder}
                      idx={parseFloat(`${count}${idx}`)}
                      editMode={editMode}
                      showZero={true}
                      propertyVal={propertyVal}
                      updateValue={onChangeCallback}
                      optionSource={optionSource}
                      readonly={readonly}
                    />
                  </td>)

                })}
              </tr>
            )
          })
        }
      </React.Fragment>
    )
  }

  return (
    <Table className="table-bordered-internal exportable" data-export-name={`Assets & Accounts by Mandate Size`}>
      <thead>
        <tr className="row-border-olive-100">
          { historicView ? <th>Date</th> : <></> }
          <th>Range</th>
          <th className='text-nowrap'>Total Assets in Range</th>
          <th className='text-nowrap'>Total Accounts in Range</th>
        </tr>
      </thead>
      <tbody>
        { historicView &&
          allDates.map((date:string, row:number) => {
            let account = accountRanges.find(t => t.quarterEndDate === date)
            if (isNil(account)){
              account = generateBlankRange(date)
            }

            return rowEntry(account, row)
          })
        }

        { !historicView &&
          accountRanges.map((accountRange:ProductAccountRanges, count:number) => {
            return rowEntry(accountRange, count)
          })
        }
      </tbody>
    </Table>
  )
}

export default ProductAssetsAccounts