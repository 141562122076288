import React from "react"
import { ICellRendererParams, ValueGetterParams, ValueSetterParams } from "@ag-grid-community/core"
import { compact, get } from "lodash"
import moment from "moment"
import { DATE_API_FORMAT, DATE_TIME_API_FORMAT } from "../../../helpers/constant"
import { dateFormatter, ReportFundProfileRenderer, falsyDateAtTopComparator, falsyFormatter } from "../../../helpers/helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface ReportDueDateCellRendererProps extends ICellRendererParams{
  values: {
    editMode: boolean
  }
}

export const dateSanityCheck = (value: any, dateConstraints: {min?: string, max?: string}) => {
  let {min, max} = dateConstraints
  let date = moment(value, DATE_API_FORMAT)
  let minDate = moment(min, DATE_API_FORMAT)
  let maxDate = moment(max, DATE_API_FORMAT)
  if(min && date.isBefore(minDate)) {
    return false
  }
  if(max && date.isAfter(maxDate)) {
    return false
  }
  return true
}

export const userRolesComparator = (valueA: any, valueB: any , order: {code?: any, value?: any}[] ) => {
  let indexFromA = order.findIndex(el => el?.value == valueA?.value)
  let indexFromB = order.findIndex(el => el?.value == valueB?.value)

  if(indexFromA === -1 && indexFromB === -1) {
    return valueA?.value > valueB?.value ? 1: -1
  }else if(indexFromA === -1) {
    return 1
  }else if(indexFromB === -1) {
    return -1
  }else if(indexFromA === indexFromB) {
    return 0
  }
  return (indexFromB > indexFromA) ? -1: 1
}

export const ReportDueDateColumnDef = (props: {quarterDate: string, lastQuarterDate: string, editMode: boolean, dateConstraints: {min?: string, max?: string}}) => {
  let {quarterDate, lastQuarterDate, editMode, dateConstraints} = props
  return compact([{
    headerName: "Plan",
    field: "plans",
    sortable: true,
    width: 280,
    valueGetter: (params: ValueGetterParams) => {
      return params.data && params.data.plans ? params.data.plans.map((plan:any) => plan?.name) : ""
    },
  },
  {
    headerName: "Report",
    field: "name",
    cellRenderer: (params: ICellRendererParams) => ReportFundProfileRenderer(params, true),
    sortable: true,
    width: 280,
  },
  {
    headerName: "Due Date",
    field: "dueDates",
    sortable: true,
    editable: true,
    type: "editableColumn",
    valueGetter: (params: ValueGetterParams) => {
      let dueDates = params.data.dueDates
      let date = dueDates? dueDates[0] : {quarterDate, dueDate: null}
      return date?.dueDate
    },
    valueFormatter: dateFormatter,
    comparator: falsyDateAtTopComparator,
    filterParams: {
      // AG-6046 fixed select blank <= V27
      caseSensitive: true,
      filterValueGetter: dateFormatter,
      comparator: falsyDateAtTopComparator,
    },
    cellRendererParams: {values: { editMode }},
    cellRenderer: (params: ReportDueDateCellRendererProps) => {
      let icon = params.values.editMode? <FontAwesomeIcon icon={["far", "calendar"]} className="ag-cell-right-icon text-blue-80"/>: <></>
      let value = params.value
      let prevDueDates = params.data.previousDueDates
      let prevDueDate = (prevDueDates? prevDueDates[0] : {quarterDate: lastQuarterDate, dueDate: null})?.dueDate
      let color = params.values.editMode && !dateSanityCheck(value, {min: prevDueDate?.dueDate})? "#c43b3b": "inherit"
      return (<span style={{height: "28px", color}}>{params.valueFormatted}{icon}</span>)
    },
    cellEditor: "formInputComponent",
    cellEditorPopup: true,
    cellEditorParams: {
      values: [ { quarterDate } ],
      formType: "date",
    },
    valueSetter: (params: ValueSetterParams) => {
      let newValue = params.newValue
      if(params.data.dueDates !== newValue){
        params.data.dueDates = [{quarterDate: lastQuarterDate, dueDate: newValue}]
        return true
      }
      return false
    },
    width: 130,
  },
  {
    headerName: "Previous Due Date",
    field: "previousDueDates",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      let dueDates = params.data.previousDueDates
      let date = dueDates? dueDates[0] : {quarterDate: lastQuarterDate, dueDate: null}
      return date?.dueDate
    },
    valueFormatter: dateFormatter,
    comparator: falsyDateAtTopComparator,
    filterParams: {
      caseSensitive: true,
      filterValueGetter: dateFormatter,
      comparator: falsyDateAtTopComparator,
    },
    width: 170,
  },
  {
    headerName: "Last Published",
    field: "lastPublished",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      let dateTime = get(params.data, params?.colDef?.field || "")
      let date = moment(dateTime, DATE_TIME_API_FORMAT).format(DATE_API_FORMAT)
      return dateTime? date : ""
    },
    valueFormatter: dateFormatter,
    comparator: falsyDateAtTopComparator,
    filterParams: {
      caseSensitive: true,
      filterValueGetter: dateFormatter,
      comparator: falsyDateAtTopComparator,
    },
    width: 140,
  },
  {
    headerName: "Consultant",
    field: "plans.consultant",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data && params.data.plans ? params.data.plans?.map((plan:any) => `${plan.consultant?.firstName} ${plan.consultant?.lastName}`) : ""
    },
    width: 170,
  },
  {
    headerName: "Analyst",
    field: "owner",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data && params.data.owner ? `${params.data.owner?.firstName} ${params.data.owner?.lastName}` : ""
    },
    width: 170,
  },
  {
    headerName: "Office",
    field: "plans.consultant.city",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data && params.data.plans ? params.data.plans?.map((plan:any) => plan?.consultant?.city) : ""
    },
    width: 130,
  },
  {
    headerName: "Data Entry",
    field: "dataEntryAnalyst",
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data && params.data.dataEntryAnalyst ? `${params.data?.dataEntryAnalyst?.firstName} ${params?.data.dataEntryAnalyst?.lastName}` : ""
    },
    width: 140,
  },
  ])
}

const PositiveOnlyFilterOptions = [
  "contains",
  "equals",
  "startsWith",
  "endsWith",
]

export const ManageUserColumnDef = (props: {editMode?: boolean, roles: {code?: any, value?: any}[]}) => {
  const {roles} = props
  return compact([
    {
      headerName: "Email",
      field: "email",
      colId: "emails",
      filterParams: {
        caseSensitive: true,
        filterOptions: PositiveOnlyFilterOptions,
      },
      sortable: true,
      width: 280,
    },
    {
      headerName: "Name",
      field: "name",
      colId: "names",
      valueGetter: (params: ValueGetterParams) => {
        let user = params.data
        let person = params.data?.person
        if(person?.firstName && person?.lastName) {
          return person?.firstName + " " + person?.lastName
        }
        if(user?.firstName && user?.lastName) {
          return user?.firstName + " " + user?.lastName
        }
        return user.name
      },
      valueFormatter: falsyFormatter,
      filterParams: {
        filterOptions: PositiveOnlyFilterOptions,
        caseSensitive: true,
      },
      sortable: true,
      minWidth: 130,
    },
    {
      headerName: "Firm ID",
      field: "person.employer.id",
      colId: "firms",
      filter: "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        defaultJoinOperator: 'OR',
        filterOptions: ["equals"],
        debounceMs: 500,
        caseSensitive: true,
      },
      width: 130,
    },
    {
      headerName: "Firm",
      field: "person.employer.name",
      suppressMenu: true,
      width: 320,
    },
    {
      headerName: "Role",
      field: "appMetadata.roles",
      colId: "roles",
      filter: "agSetColumnFilter",
      filterParams: {
        values: roles.map(el => el.value),
        comparator: (a: any, b: any) => {
          // order would change back without this :(
        },
        debounceMs: 500,
        caseSensitive: true,
      },
      width: 300,
    },
    {
      headerName: "Last Sign In",
      field: "lastLogin",
      colId: "last_login",
      valueFormatter: dateFormatter,
      // no filter for date.
      suppressMenu: true,
      sortable: true,
      width: 130,
    },
    // {
    //   headerName: "Sign Ins(30 Days)",
    //   field: "loginCount",
    //   colId: "logins_count",
    //   valueFormatter: falsyFormatter,
    //   // no filter for login count.
    //   suppressMenu: true,
    //   sortable: true,
    //   width: 160,
    // },
  ])
}
