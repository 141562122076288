import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import { clone, cloneDeep, findIndex, first, get, isUndefined, merge, set, uniqueId } from "lodash"
import moment from 'moment'
import React, { FormEvent, useCallback, useContext, useEffect, useState } from "react"
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap"
import { EditButtonContext } from "../../../../Context/EditButtonContext"

import { AssetClassFragment, ClientPortfolioBenchmarkFragment, ClientPortfolioDefinitionFragment, ClientPortfolioPlanFragment, ClientPortfolioTargetFragment, Maybe, SearchTypes, TargetDefinition, TargetDefinitionQuery, TargetDetailFragment, TargetMemberFragment, useCreateTargetMutation, useTargetDefinitionLazyQuery, useTargetFromIndexLazyQuery, UseTypeCode } from "../../../../__generated__/graphql"
import { DATE_API_FORMAT, FormInputField, FormInputSubClasses } from "../../../../helpers/constant"
import { getNewStateObject } from "../../../../helpers/helpers"
import { SearchTypeDisplays } from '../../../Search/SearchEnums'
import { FormInput } from "../../../ui/Forms/FormInput"
import LoadingOverlay from '../../../ui/Loading'
import { GetLookupDataToOptions } from "../../../ui/LookupOptions"
import { SingleCustomPerformanceProps } from '../ClientPortfolioCustomPerformance'
import { ClientPortfolioBenchmarkExtendedType, ClientPortfolioHistoryData, ClientPortfolioTargetExtendedType, DEFAULT_TARGET_DETAIL, formatTargetToCreateTargetInput, TargetDefinitionExtendedType, TargetDetailExtendedType, TraceableTableKey, TypeNameToMemberTypeMapping, UseMemberTypeOptions } from '../helper'
import { ClientPortfolioAddStatic } from '../Search'
import { CustomBenchmarkTargetOptionsComponent, TargetConstantComponent } from "./TargetConstant"
import { DEFAULT_EMPTY_FOOTNOTE, FootnoteComponent, FootnoteModifications } from "./TargetFootnotes"

type BenchmarkComponentProps = {
  data: ClientPortfolioBenchmarkExtendedType
  initialState: ClientPortfolioBenchmarkFragment | any
  handleChange: React.Dispatch<React.SetStateAction<ClientPortfolioBenchmarkExtendedType>>
  setEditedBenchmarks: React.Dispatch<React.SetStateAction<ClientPortfolioBenchmarkExtendedType>>
  editMode: boolean
  hidden?: boolean
  assetClasses: ({
    __typename: "AssetClass"
    children?: ({
      __typename: "AssetClass"
    } & AssetClassFragment)[]
  } & AssetClassFragment)[]
  planIds: {id: number, name: string, __typename: string}[]
  setLazyFetchedTargetData: React.Dispatch<React.SetStateAction<Maybe<TargetDetailExtendedType>[] | null>>
  clientPortfolio?: ClientPortfolioDefinitionFragment
}

interface SingleBenchmarkInput extends ClientPortfolioTargetFragment, FormInputField {}

interface SingleBenchmarkProps {
  data: ClientPortfolioTargetExtendedType
  idx: number
  updateValue: (idx: number, value: any, type: string, property: string) => void
  deleteRow: () => void
  editMode: boolean
  usedOrders: (Maybe<number> | undefined)[][]
  setEditedBenchmarks: React.Dispatch<React.SetStateAction<ClientPortfolioBenchmarkExtendedType>>
  orderIsSelected?: boolean
  planIds: {id: number, name: string, __typename: string}[]
  assetClasses: ({
    __typename: "AssetClass"
    children?: ({
      __typename: "AssetClass"
    } & AssetClassFragment)[]
  } & AssetClassFragment)[]
  setLazyFetchedTargetData: React.Dispatch<React.SetStateAction<Maybe<TargetDetailExtendedType>[] | null>>
  plan?: ClientPortfolioPlanFragment
}


const UseTypeBooleanOptions = [
    {
      code: "GENRIC",
      value: "Generic",
    },
    {
      code: "CUSTOM",
      value: "Custom",
    },
  ]

const CustomBenchmarkInput: FormInputField[] = [
  {
    property: "target.useType.code",
    label: "Type",
    type: "radio",
    subtype: "single",
    options: UseTypeBooleanOptions,
    // optionSource: "UseTypeCode",
    subClasses: {
      inputWrapperClasses: "col-sm-6 col-lg-4 ml-2",
    },
  },
  {
    property: "target.name",
    label: "Benchmark Name",
    type: "text",
    // searchTypes: [SearchTypes.Index],
    // placeholder: "Find Index by name or ID",
    subClasses: {
      // wrapperClasses: "vehicle-search",
      // inputClasses: "search-bar placeholder-gray-50 TargetConstantComponent mr-0",
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-6 col-lg-4"
    },
  },
  {
    property: "target.targetId",
    label: "Benchmark ID",
    type: "text",
    // searchTypes: [SearchTypes.Index],
    // placeholder: "Find Index by name or ID",
    subClasses: {
      // wrapperClasses: "vehicle-search",
      // inputClasses: "search-bar placeholder-gray-50 TargetConstantComponent mr-0",
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-6 col-lg-4"
    },
    readonly: true,
  },
  // { // TODO: stopped here, add editedName here. notice the index change.
  //   property: "target.editedName",
  //   label: "Name Override",
  //   type: "text",
  //   subClasses: {
  //     inputWrapperClasses: "col-sm-6 col-lg-4",
  //   },
  // },
  {
    property: "target.lagMonths",
    label: "Lag",
    type: "number",
    subtype: "months",
    subClasses: {
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-6 col-lg-4",
    },
  },
  {
    property: "editedOrder",
    label: "Benchmark Order",
    type: "select",
    subClasses: {
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-6 col-lg-4",
    },
  },
  // {
  //   property: "target.definition.minWeight",
  //   label: "Min Weight",
  //   type: "float",
  //   // options
  //   // optionSource: "UseTypeCode",
  // },
  // {
  //   property: "target.definition.weight",
  //   label: "Weight",
  //   type: "float",
  //   // options
  //   // optionSource: "UseTypeCode",
  // },
  // {
  //   property: "target.definition.maxWeight",
  //   label: "Max Weight",
  //   type: "float",
  //   // options
  //   // optionSource: "UseTypeCode",
  // },
]

const GenericBenchmarkInput: FormInputField[] = [
  {
    property: "target.useType.code",
    label: "Type",
    type: "radio",
    subtype: "single",
    options: UseTypeBooleanOptions,
    // optionSource: "UseTypeCode",
    subClasses: {
      inputWrapperClasses: "col-sm-6 col-lg-4 ml-2",
    },
  },
  {
    property: "memberType.code",
    label: "Member Type",
    type: "select",
    subtype: "single",
    options: [{code: "Index", value: "Index"}],
    readonly: true,
    subClasses: {
      inputWrapperClasses: "col-sm-6 col-lg-4",
    },
  },
  {
    property: "target",
    label: "Member Name",
    type: "search",
    searchTypes: [SearchTypes.Index],
    placeholder: "Find Index by name or ID",
    subClasses: {
      wrapperClasses: "vehicle-search",
      inputClasses: "search-bar placeholder-gray-50 TargetConstantComponent mr-0",
      labelClasses: "col-sm-4",
      inputWrapperClasses: "col-sm-6 col-lg-4"
    },
  },
  {
    property: "target.definition[0].member.indexId",
    label: "Member ID",
    type: "text",
    readonly: true,
    subClasses: {
      inputWrapperClasses: "col-sm-6 col-lg-4",
    },
  },
  // {
  //   property: "target.editedName",
  //   label: "Name Override",
  //   type: "text",
  //   subClasses: {
  //     inputWrapperClasses: "col-sm-6 col-lg-4",
  //   },
  // },
  {
    // index: 4, editedOrder
    property: "editedOrder",
    label: "Benchmark Order",
    type: "select",
    subClasses: {
      inputClasses: "text-left",
      inputWrapperClasses: "col-sm-6 col-lg-4",
    },
  },
]

// for members table.
const MembersGeneralInput: any[] = [
  {
    field: "memberType.code",
    title: "type",
    type: "select",
    subtype: "single",
    options: UseMemberTypeOptions,
    // optionSource: "TargetMemberTypeCode",
    readonly: true,
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-0 ml-0",
      inputClasses: "pl-0 ml-0",
      wrapperClasses: "ml-0 pl-0",
    },
    widthClass: "col-1 target-member-header",
  },
  {
    field: "member.id", // needs manipulation in value
    title: "id",
    type: "text",
    readonly: true,
    subClasses: {
    },
    widthClass: "col-1",
  },
  {
    field: "member.name",
    title: "member name",
    type: "text",
    readonly: true,
    subClasses: {
    },
    widthClass: "col-2",
  },
  {
    field: "editedEndDate",
    // field: "endDate",
    title: "End date",
    type: "date",
    readonly: true,
    subClasses: {
      inputClasses: "text-left",
    },
    widthClass: "",
  },
]

const TargetInput: any[] = [
  { // idx = 4
    field: "assetClass.parent.code",
    title: "asset Class",
    type: "select",
    subtype: "single",
    required: true,
    subClasses: {
    },
    widthClass: "col-2",
  },
  {
    field: "assetClass.code",
    title: "Strategy Type",
    type: "select",
    subtype: "single",
    required: true,
    subClasses: {
    },
    widthClass: "col-2",
  },
  {
    field: "minWeight",
    title: "Min Weight",
    type: "float",
    subtype: "percent",
    subClasses: { inputClasses: "text-right" },
    widthClass: "",
  },
  {
    field: "weight",
    title: "weight",
    type: "float",
    subtype: "percent",
    subClasses: { inputClasses: "text-right" },
    widthClass: "",
  },
  {
    field: "maxWeight",
    title: "Max Weight",
    type: "float",
    subtype: "percent",
    subClasses: { inputClasses: "text-right" },
    widthClass: "",
  },
  {
    field: "constantRebalance[0].constant",
    title: "constant",
    type: "custom Component",
    subClasses: {
    },
    widthClass: "target-constant",
  },
  {
    field: "constantRebalance[0].frequency.code",
    title: "reBalancing",
    type: "select",
    subtype: "single",
    optionSource: "TargetConstantRebalanceFrequencyCode",
    subClasses: {
    },
    widthClass: "col-1",
  },
  {
    field: "lagMonths",
    title: "lag (Months)",
    type: "number",
    subClasses: {
    },
    widthClass: "",
  },
]

interface columnDefItem {
  field: string
  type: string
  title: string
  className?: string
  readonly?: boolean
  subtype?: string
  optionSource?: string
  required?: boolean
  subClasses?: { [name in FormInputSubClasses]?: string }
  widthClass?: string
  textLinkParams?: {
    property?: string, // to get property value from state and then passed to input as text params
    url: string,
  },
  // displayData?: DisplayDataType
}
interface SingleTargetMemberInput extends columnDefItem {

}

const BatchEndDateInput: FormInputField[] = [
  {
    property: "startDate",
    label: "Start",
    type: "date",
    readonly: true,
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
  {
    property: "editedEndDate",
    label: "End",
    type: "date",
    subtype: "month",
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
]

const CombinedInput = [...MembersGeneralInput, ...TargetInput]

interface BenchmarkHistoryData extends ClientPortfolioHistoryData{
}
interface BenchmarkHistoryDataProps {
  idx: number // to tell which target it belongs to.
  isOpen: boolean
  title: string
  toggle: () => void
  data: BenchmarkHistoryData
}

export const BenchmarkHistoryModal: React.FC<BenchmarkHistoryDataProps> = ({
  idx,
  isOpen,
  title,
  toggle,
  data,
}) => {
  let targetId = idx
  const [openItems, setOpenItems] = useState<{[key:string]:number[]}>({})
  const [dataState, setDataState] = useState<BenchmarkHistoryData>(data)

  useEffect(() => {
    setDataState(data)
  }, [data])
  const handleRowValueChange = (rowIndex: any, idx: any, value: any, property: any) => {
    // only work for lazy fetch target member in benchmark history
    if(property !== "member") {
      return
    }
    let {headers, rows} = dataState
    let newRows = rows.map((row, index) => {
      if(index === rowIndex) {
        let {date, data} = row
        let newData = data.map((el, dataIdx) => {
          if(dataIdx === idx) {
            let newEl = cloneDeep(el)
            set(newEl, "member", value)
            return newEl
          }
          return el
        })
        return {date, data: newData}
      }
      return row
    })
    let newData = {headers, rows: newRows}
    setDataState(newData)
  }
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header">History | {title}</ModalHeader>
      <ModalBody>
        {dataState.rows?.map((row, rowIndex) => {
          let addMemberTotal = row.data.filter(el => !!(el.member && el.memberType))
          return (
            <div className="benchmark-modal-history-item" key={rowIndex}>
              <div className="benchmark-modal-as-of-date">As of {row.date}</div>
              <Table className="benchmark-modal-table">
                <thead>
                <tr className="text-uppercase">
                  {dataState.headers?.map((header, idx) => {
                    if(idx === 0) {
                      return (
                        <th key={idx} className={"text-left"}>
                          <div style={{marginLeft: 15}}>
                          {header}
                          </div>
                        </th>
                      )
                    }
                    if(idx === 3) {
                      return <React.Fragment key={idx}></React.Fragment>
                    }
                    return (<th key={idx} className={"text-left"}>{header}</th>)
                  })}
                </tr>
                </thead>
                <tbody>
                  {row.data.map((tr, idx) => {
                    let tableKey = "history"
                    let isTarget = tr?.memberType?.code === "T" && tr?.member?.__typename === "Target"
                    let target = isTarget ? (tr.member as TargetDetailExtendedType) : undefined
                    let targetDefinition = target?.definition as TargetDefinitionExtendedType[]
                    let rowOpenItems = openItems[`row${rowIndex}`] || []
                    let isSubMemberTableOpen = !!(target && rowOpenItems?.includes(target?.targetId))
                    const setRowOpenItems = (newOpenItems: number[]) => {
                      setOpenItems((oldOpenItems) => ({
                        ...oldOpenItems,
                        [`row${rowIndex}`]: newOpenItems,
                      }))
                    }
                    return (
                      <React.Fragment key={idx}>
                        <BenchmarkMemberTable
                          data={tr}
                          key={`${tableKey}-custom-benchmark-row-${idx}-${targetId}`}
                          row={`${tableKey}-${idx}`}
                          editMode={false}
                          columnDef={CombinedInput.slice(0, 9)}
                          removeRow={() => {}}
                          // updateValue={() => {}}
                          updateValue={(value, property) => handleRowValueChange(rowIndex, idx, value, property)}
                          tableKey={tableKey}
                          // target part
                          target={target}
                          // isTargetMember={true}
                          updateTargetValue={() => {}}
                          openItems={rowOpenItems}
                          currentHierarchy={[]}
                          setOpenItems={setRowOpenItems}
                          setLazyFetchedTargetData={() => {}}
                          // assetClassesOptions={assetClassesOptions}
                        />
                        {isSubMemberTableOpen &&
                        <React.Fragment key={`${tableKey}-custom-benchmark-row-${idx}-${targetId}-target-members`}>
                        {targetDefinition && targetDefinition?.map((targetMember: Maybe<TargetDefinitionExtendedType>, targetMemberIdx: number) => {
                          let isTarget = targetMember?.memberType?.code === "T" && targetMember?.member?.__typename === "Target"
                          let member = isTarget ? (targetMember?.member as TargetDetailExtendedType) : undefined
                          if(targetMember?.endDate !== "9999-12-31") {
                            // only show current members for member of benchmark(target)
                            return <React.Fragment key={`${tableKey}-custom-benchmark-target-member-${idx}-${targetMemberIdx}`}></React.Fragment>
                          }
                          {/* member of member of benchmark target */}
                          return (
                            <BenchmarkMemberTable
                              data={targetMember as TargetDefinitionExtendedType}
                              key={`${tableKey}-custom-benchmark-target-member-${idx}-${targetMemberIdx}-${targetId}`}
                              row={`${tableKey}-${idx}-${targetMemberIdx}`}
                              editMode={false}
                              columnDef={CombinedInput.slice(0, 9)}
                              removeRow={() =>{}}
                              updateValue={() => {}}
                              updateTargetValue={(value, property) => {
                                // handleTargetValueChange(idx, value, property)
                              }}
                              tableKey={tableKey}
                              // target={member}
                              isTargetMember={true}
                              setLazyFetchedTargetData={() =>{}}
                              // assetClassesOptions={assetClassesOptions}
                            />
                          )
                        })}
                        {/* total of  member of member target*/}
                        {isTarget && generateTotalComponent({idx, members: targetDefinition || [], children: null})}
                      </React.Fragment>
                      }
                    </React.Fragment>
                    )
                  })
                  }
                  {/* total of  member of benchmark target*/}
                  {generateTotalComponent({idx: "add-member", members: addMemberTotal || [], children: null})}
                </tbody>
              </Table>
            </div>
          )
        })}
      </ModalBody>
    </Modal>
  )
}

const getTargets = (data: ClientPortfolioBenchmarkFragment) => {
  return data.performanceTargetMap as ClientPortfolioTargetExtendedType[] || []
}

const isCustomType = (data: ClientPortfolioTargetExtendedType) => {
  return data?.target?.useType?.code === "CUSTOM"
}

const isEmptyTargetMembersTable = (props: TargetDefinitionExtendedType[]) => !(props && props?.length > 0)

type TableKeys = "new" | "current" | "previous"

export interface TargetMembersExtendedType extends TargetDefinition {

}
export interface TargetMembersTableProps {
  editMode: boolean
  showStatus: boolean
  tableKey: TableKeys
  data: TargetDefinitionExtendedType[]
  planIds: {id: number, name: string, __typename: string}[]
  setEditedTargetMembers?: (props: any) => void // TODO delete
  setEditedBenchmarks: (props: any) => void
  endCurrent: () => void
  revertEndCurrent: () => void
  setData: (data: any) => void // to update parent state for all data
  showSuffixEndDate?: boolean
  currentTableBatchEndDate?: string
  handleTableStateChange: (state: TargetDefinitionExtendedType[], tableName: TableKeys) => void
  assetClasses: ({
    __typename: "AssetClass"
    children?: ({
      __typename: "AssetClass"
    } & AssetClassFragment)[]
  } & AssetClassFragment)[]
  targetId: number
  targetIdx: number
  setLazyFetchedTargetData: React.Dispatch<React.SetStateAction<Maybe<TargetDetailExtendedType>[] | null>>
  hasFullHistoryButton?: boolean
  perviousEndDate?: string
}

interface TargetMembersPreviousTableProps extends TargetMembersTableProps {
  tableKey: 'previous'
  toggleFullHistoryModal?: () => void
}

interface TargetMembersNewTableProps extends TargetMembersTableProps {
  tableKey: 'new'
  currentTableBatchEndDate: string
}

interface TargetMembersCurrentTableProps extends TargetMembersTableProps {
  tableKey: 'current'
  showSuffixEndDate: boolean
  setCurrentTableBatchEndDate?: React.Dispatch<React.SetStateAction<string | null>>
  currentTableBatchEndDate: string
  greyOut: boolean
}

const DEFAULT_NEW_END_DATE = "9999-12-31"
const DEFAULT_NEW_START_DATE = "1900-01-01"
const getMembers = (data: EditedTargetMembersState) => data.members || []

const getPreviousEndDate = (props: {targetMembers: any, tableKey: string}): any| null => {
  let {targetMembers, tableKey} = props
  if(tableKey === "previous" && (!targetMembers || targetMembers.length < 1)) {
    return null
  }else {
    let member = targetMembers[0]
    return member?.endDate
  }
}

const isEmptyTable = (props: any) => !props?.length

interface SingleBenchmarkMemberProps extends SingleCustomPerformanceProps {
  target?: TargetDetailExtendedType | undefined
  data: TargetDefinitionExtendedType
  updateTargetValue: (value: any, property: string) => void
  setLazyFetchedTargetData: React.Dispatch<React.SetStateAction<Maybe<TargetDetailExtendedType>[] | null>>
  assetClasses?: any[]
}

interface SingleBenchmarkMemberPropsExpandableType extends SingleBenchmarkMemberProps {
  // target: TargetDetailExtendedType
  openItems: number[]
  currentHierarchy: number[]
  setOpenItems: (newOpenItems: number[]) => void
}

const BenchmarkMemberTable = (props: SingleBenchmarkMemberProps | SingleBenchmarkMemberPropsExpandableType) => {
  let { data, row, columnDef, editMode, removeRow, updateValue, tableKey, isTargetMember, updateTargetValue, assetClasses} = props as SingleBenchmarkMemberProps
  let {target, setLazyFetchedTargetData} = props as SingleBenchmarkMemberProps

  // expandable list
  let {openItems, currentHierarchy, setOpenItems} = props as SingleBenchmarkMemberPropsExpandableType

  const isOpen = !!(target && openItems?.includes(target?.targetId))

  const [targetDefinition, setDefinition] = useState<Maybe<TargetDefinitionExtendedType[]>>(null)

  const [fetchTargetDefinitionQuery, {loading, error, data: fetchedDefinitionData}] = useTargetDefinitionLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (fetchedDefinitionData) => {
      if(fetchedDefinitionData?.target?.definition) {
        let currentDefs = (fetchedDefinitionData?.target?.definition?.filter(el => el?.endDate === "9999-12-31") || []) as TargetDefinitionExtendedType[]
        updateValue({...target, definition: currentDefs}, "member")
        addDataToLazyFetchedData(fetchedDefinitionData)
      }
    },
  })

  /**
   * 1. click caret, fetch definition from api
   * 2. definition data fetched, updateTargetValue to include this data.
   * 3. see target.definition data change in props from parent
   * 4. setOpenItems to change parent openItems state
   * 5. see openItems change in props from parent, setOpen for target itself.
  */

  useEffect(() => {
    if(!!target?.definition) {
      setDefinition(target?.definition)
    }else {
      if(targetDefinition) {
        setDefinition(null)
      }
    }
  }, [target?.definition])

  useEffect(() => {
    if(fetchedDefinitionData && editMode) {
      // re-add data if data fetched in view and switch to edit
      let currentDefs = (fetchedDefinitionData?.target?.definition?.filter(el => el?.endDate === "9999-12-31") || []) as TargetDefinitionExtendedType[]
      updateValue({...target, definition: currentDefs}, "member")
    }
    if(!editMode && isOpen) {
      setOpenItems([])
    }
  }, [editMode])

  const toggleExpand = () => {
    if(!target) {
      return
    }
    if (isOpen) {
      let removedArray = openItems.filter((id) => target?.targetId !== id)
      setOpenItems(removedArray)
    } else {
      setOpenItems([...openItems, target.targetId])
    }
  }

  const handleExpand = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if(target && target?.targetId > 0) {
      if(targetDefinition || isOpen) {
        toggleExpand()
      }else {
        fetchTargetDefinitionQuery({variables: {id: target.targetId}})
        if(!isOpen && !openItems?.includes(target?.targetId)) {
          setOpenItems([...openItems, target?.targetId])
        }
      }
    }
  }

  const addDataToLazyFetchedData = (fetchedData: TargetDefinitionQuery ) => {
    let target = fetchedData?.target as TargetDetailExtendedType
    if(!target || !target?.definition) {
      return
    }
    setLazyFetchedTargetData((oldData: Maybe<TargetDetailExtendedType>[] | null | undefined) => {
      if(!oldData) {
        return [target]
      }else {
        let filteredData: Maybe<TargetDetailExtendedType>[] = oldData?.filter(el => el?.targetId !== target?.targetId) || [] as Maybe<TargetDetailExtendedType>[]
        return [...filteredData, target]
      }
    })
  }

  /**
   * Two types
   * 1. has target & updateTargetValue in props, expandable.
   * 3. has no target or updateTargetValue in props, not expandable
   * **/
  let rowId = get(data, "member.id")

  if (data?.memberType?.code === "F") {
    rowId = get(data, "member.vehicle.id")
  }else if (data?.memberType?.code === "X") {
    rowId = get(data, "member.indexId")
  }else if (data?.memberType?.code === "G") {
    rowId = get(data, "member.groupId")
  }else if (data?.memberType?.code === "T") {
    rowId = get(data, "member.targetId")
  }

  const getChildrenAssetClasses = useCallback(
    (code: number | undefined) => {
      if (isUndefined(code)) {
        return <>{"No data found"}</>
      }
      let parent = assetClasses?.find((assetClass) => assetClass.code.toString() === code.toString())

      const children = parent?.children
      if (!children) {
        return <>Data Not Found</>
      }
      return GetLookupDataToOptions({
        data: children as AssetClassFragment[],
      })
    },
    [assetClasses]
  )

  return (
    <React.Fragment key={`${tableKey}-${row}-${rowId}`}>
      <tr className={editMode ? "hover-actions editing" : "hover-actions"} key={row}>
        {columnDef.map((header, idx) => {
          let isTarget = data?.memberType?.code === "T" || target
          let memberAsTarget = (data?.member as TargetDetailExtendedType) || target
          let showTarget = isTarget && memberAsTarget?.name && idx > 3
          let { field, textLinkParams, type, subtype, optionSource, subClasses, options: overriddenOptions} = header
          let options: any = overriddenOptions
          let propertyVal = get(data, field)
          let readOnly = !!header?.readonly
          let editable = !!editMode

          let updateFunction = (value: any) => {
            if (memberAsTarget && idx > 8) {
              return updateTargetValue(value, header.field)
            }else {
              // update targetDefinition Itself
              return updateValue(value, header.field)
            }
          }

          if(field === "memberType.code") {
            return (
              <td key={`${idx}`} className={`flex-row target-members-caret-container ${header?.widthClass}`} onClick={handleExpand}>
                <div
                  className={`d-flex`}
                  style={{marginLeft: 10 + (!!isTargetMember? 10: 0)}}
                >
                <div
                  className={`target-members-caret`}
                >
                  {isTarget && target && target.targetId > 0 &&
                    <FontAwesomeIcon
                    icon={isOpen ? "caret-down" : "caret-right"}
                    size="sm"
                    />}
                </div>
                <FormInput
                  key={header.field}
                  property={`${row}-${idx}-${header.field}`}
                  displayName={""}
                  type={header.type}
                  subtype={header.subtype}
                  idx={`${tableKey}-benchmark-item-${row}-${idx}`}
                  editMode={editable}
                  propertyVal={propertyVal}
                  readonly={readOnly}
                  updateValue={updateFunction}
                  required={header.required}
                  optionSource={optionSource}
                  subClasses={subClasses || {}}
                  options={options}
                  showZero={true}
                />
                </div>
              </td>
            )
          }
          if(field === "member.id") {
            editable = false
            if(propertyVal < 1) {
              return <td key={idx}></td>
            }else if (data?.memberType?.code === "F") {
              field = "member.vehicle.id"
              propertyVal = get(data, field)
            }else if (data?.memberType?.code === "X") {
              field = "member.indexId"
              propertyVal = get(data, field)
            }else if (data?.memberType?.code === "G") {
              field = "member.groupId"
              propertyVal = get(data, field)
            }else if (idx === 1 && data?.memberType?.code === "T") {
              field = "member.targetId"
              propertyVal = get(data, field)
            }
          }else if(field === "member.name") {
            // all data editable
            // editable = editable && (!!data?.member?.newAdded)
            // if (displayData && !dataShouldShow(data, displayData)) {
            //   return <td key={idx}></td>
            // }
            if(data?.memberType?.code === "F") {
              field = "member.vehicle.name"
              propertyVal = get(data, field)
            }else if(data?.memberType?.code === "T" && (data?.member as TargetDetailExtendedType)?.newAdded) {
              readOnly = false
            }
          } else if(field === "editedEndDate") {
            return <React.Fragment key={idx}></React.Fragment>
          } else if(["assetClass.parent.code", "assetClass.code"].includes(field)) {
            if(tableKey === "history") {
              return (
                <td key={`${tableKey}-benchmark-item-${row}-${idx}`} className={`${header?.widthClass}`}>
                  <FormInput
                    key={header.field}
                    property={`${row}-${idx}-${header.field}`}
                    displayName={""}
                    type={"text"}
                    subtype={header.subtype}
                    idx={`${tableKey}-benchmark-item-${row}-${idx}`}
                    editMode={editable}
                    propertyVal={propertyVal}
                    readonly={readOnly}
                    updateValue={updateFunction}
                    required={header.required}
                    subClasses={subClasses || {}}
                    showZero={true}
                  />
                </td>
              )
            }
            if(field === "assetClass.parent.code"){
              options = props.assetClassesOptions
            } else {
              options = getChildrenAssetClasses(get(data, "assetClass.parent.code"))
            }
          } else if(idx <= 8) {
            propertyVal = propertyVal* 100
            updateFunction = (value: any) =>
            updateValue(value/100, header.field)
            // if(!!isTargetMember) {
            // }else {
            //   return <td key={idx}></td>
            // }
          } else {
            // idx > 7, target fragment
            propertyVal = get(memberAsTarget, field)
            if (!showTarget) {
              return <td key={idx}></td>
            }else if(data?.memberType?.code === "T" && (data?.member as TargetDetailExtendedType && isTargetMember)) {
              editable = false
            }
            if(header.field === "constantRebalance[0].constant") {
              return (
                <TargetConstantComponent
                  data={propertyVal || {}}
                  editMode={editable}
                  row={`${tableKey}-${idx}`}
                  key={`${tableKey}-${idx}`}
                  handleChange={(newState: any) =>{
                    updateFunction(newState)
                  }}
                >
                </TargetConstantComponent>
              )

            }
          }
          let textLinkResolved: Maybe<{ url: string }> = null
          if(type === "text" && subtype == "textLink" && !editMode && textLinkParams) {
            let {property: linkProperty, url} = textLinkParams
            let urlResolved: string = url
            if(linkProperty) {
              urlResolved +=  get(data, linkProperty)
            }
            textLinkResolved = {url: urlResolved}
          }
          if(data?.memberType?.code === "T" && (data?.member as TargetDetailExtendedType)?.newAdded && isTargetMember) {
            editable = false
          }

          return (
            <td key={`${tableKey}-benchmark-item-${row}-${idx}`} className={`${header?.widthClass}`}>
              <FormInput
                key={header.field}
                property={`${row}-${idx}-${header.field}`}
                displayName={""}
                type={header.type}
                subtype={header.subtype}
                idx={`${tableKey}-benchmark-item-${row}-${idx}`}
                editMode={editable}
                propertyVal={propertyVal}
                readonly={readOnly}
                updateValue={updateFunction}
                required={header.required}
                optionSource={header.optionSource}
                subClasses={subClasses || {}}
                textLinkParams={textLinkResolved}
                options={options}
                showZero={true}
              />
            </td>
          )
        })}
        {/* hide action column in history modal*/}
        {tableKey !== "history" &&
          <td className="actions" key={`actions`}>
            {editMode && (
              <Button color="link" className="btn-thin" disabled={!editMode} onClick={removeRow}>
                <FontAwesomeIcon icon="trash" className="text-blue-100" />
              </Button>
            )}
          </td>
        }
      </tr>
      {(loading || error) && (
      <tr key={`${tableKey}-${row}-dd`} className={"w-100 mx-0 px-0"} style={{height: "2em"}}>
        <td colSpan={5} className={"mx-0 px-0 py-0"}>
         {loading && <LoadingOverlay/>}
          {error &&(<div key={`error`}><p>{error.message}</p></div>)}
        </td>
      </tr>
      )}
      {/* {(loading || error) && (
        <tr key={`${tableKey}-${row}-dd`} className={"w-100 mx-0 px-0"} style={{height: "2em"}}>
          <td colSpan={12} className={"mx-0 px-0 py-0"}>
            {loading &&
              <div className={"row-loading"} key={"loading"}>
                <div  className={"animated-loading"}>
                </div>
              </div>
            }
            {error &&(<div key={`error`}><p>{error.message}</p></div>)}
          </td>
        </tr>
      )} */}
    </React.Fragment>
  )
}

const generateTotalComponent = (props: {idx: string| number, members: TargetDefinitionExtendedType[], children: JSX.Element | null}) => {
  let showCalculatedTotal = true
  let {members, idx, children} = props
  let result = {weight: 0.0, minWeight: 0.0, maxWeight: 0.0}
  let cnt = members.length
  let calculatedTotal = members?.reduce((acc, el, idx)=> {
    // if(el?.endDate !== "9999-12-31" && idx === "add-member") {
    //   // calculate current definition for members "add target member total"
    //   return acc
    // }
    if(el?.weight) {
      acc.weight = acc.weight + el?.weight
    }
    if(el?.minWeight) {
      acc.minWeight = acc.minWeight + el?.minWeight
    }
    if(el?.maxWeight) {
      acc.maxWeight = acc.maxWeight + el?.maxWeight
    }
    return acc
  }, result) || result
  // show red if there is a non 1 value
  let showWeightRed = (calculatedTotal.weight !== 1) && !!calculatedTotal.weight
  let showMinWeightRed = (calculatedTotal.minWeight !== 1) && !!calculatedTotal.minWeight
  let showMaxWeightRed = (calculatedTotal.maxWeight !== 1) && !!calculatedTotal.maxWeight
  let showTotalRed = showWeightRed && showMinWeightRed && showMaxWeightRed

  let label = (idx === "add-member") ? "Target's Totals": "Calculated Totals"
  return(
    <tr key={`add-target-member-${idx}`} className={"text-center"}>
      <td align="center" colSpan={3}>
          <div className={"font-weight-bold center"}>
            {children ? children: label}
          </div>
      </td>
      <td key={"placeholder"}></td>
      {showCalculatedTotal &&
        <>
          <td align="left" colSpan={1} className={classnames("text-left font-weight-bold", {"text-danger": showTotalRed})}>
            {children? label: ""}
          </td>
          <td align="right" colSpan={1} className={classnames("text-right font-weight-bold", {"text-danger": showMinWeightRed})}>
            {`${(calculatedTotal.minWeight * 100.0).toFixed(2)}%`}
          </td>
          <td align="right" colSpan={1} className={classnames("text-right font-weight-bold", {"text-danger": showWeightRed})}>
            {`${(calculatedTotal.weight * 100.0).toFixed(2)}%`}
          </td>
          <td align="right" colSpan={1} className={classnames("text-right font-weight-bold", {"text-danger": showMaxWeightRed && !!calculatedTotal.maxWeight})}>
            {`${(calculatedTotal.maxWeight * 100.0).toFixed(2)}%`}
          </td>
          <td align="left" colSpan={7} className={classnames("text-left font-weight-bold")}>
          </td>
        </>
      }
      {!showCalculatedTotal &&
        <>
          <td align="left" colSpan={9} className={classnames("text-left font-weight-bold")}>
          </td>
        </>
      }
    </tr>
  )
}

/** traceable members table(previous/current/new) inside a custom benchmark */
const TargetMembersTableComponent = (props: TargetMembersTableProps | TargetMembersPreviousTableProps | TargetMembersNewTableProps | TargetMembersCurrentTableProps) => {
  let {editMode, showStatus, tableKey, data, setData, planIds, setEditedTargetMembers, endCurrent, revertEndCurrent, showSuffixEndDate, currentTableBatchEndDate, targetId, handleTableStateChange: handleChange, assetClasses, hasFullHistoryButton, previousEndDate} = props
  let {setEditedBenchmarks, targetIdx, setLazyFetchedTargetData} = props
  // benchmark is a target, targetMembers is it's definition
  const [targetMembers, setTargetMembers] = useState<TargetDefinitionExtendedType[]>(data) // for add search

  let {toggleFullHistoryModal} = props as TargetMembersPreviousTableProps

  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(!!showSuffixEndDate && tableKey !== "previous")

  const [showSearchBar, setShowSearchBar] = useState(false)
  const [showTargetMemberSearchBar, setShowTargetMemberSearchBar] = useState<number[]>([])
  const toggleSearchBar = () => setShowSearchBar(!showSearchBar)
  const toggleTargetMemberSearchBar = (targetId: number) => {setShowTargetMemberSearchBar((showTargetMemberSearchBar) => {
    if(showTargetMemberSearchBar.includes(targetId)) {
      return showTargetMemberSearchBar.filter(el => el !== targetId)
    }else {
      return [...showTargetMemberSearchBar, targetId]
    }
  })}

  const [createTarget] = useCreateTargetMutation()
  const [customTargetSaving, setSaving] = useState(false)
  // only changed when new created
  const [savedNewTarget, setNewTarget] = useState<Maybe<TargetDetailExtendedType | null>>(null)

  const [currentId, setId] = useState(-1)
  const [assetClassesOptions] = useState(() =>
    GetLookupDataToOptions({
      data: assetClasses
    })
  )

  // Target only, expanded when clicked to show target members
  const [openItems, setOpenItems] = useState<number[]>([])

  const addTargetMemberComponent = (idx: number) => {
    let target = targetMembers[idx].member as TargetDetailExtendedType
    let members = (target.definition || []) as TargetDefinitionExtendedType[]
    let children = (
      <Button color="link" className="text-callan-blue btn-thin py-0 ml-4 border-bottom-line-break" onClick={() => toggleTargetMemberSearchBar(target.targetId)}>
        {"Add Target Member"}
        <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
      </Button>)
    return generateTotalComponent({idx, members, children})
  }

  const generateId = () => {
    let nextId = currentId - 1
    setId(nextId)
    return nextId
  }

  useEffect(() => {
    setShowDeleteButton(!!props?.showSuffixEndDate)
  }, [props?.showSuffixEndDate])

  useEffect(() => {
    let newTargetMembers = data
    setTargetMembers(newTargetMembers)
    if(editMode){
    }else {
      // setOpenItems([])
    }
  }, [data])

  useEffect(() => {
    if(!editMode) {
      setShowSearchBar(false)
      setShowTargetMemberSearchBar([])
    }
  }, [editMode])

  useEffect(() => {
    if(editMode && savedNewTarget) {
      updateCreatedTarget(savedNewTarget)
      // handleTargetValueChange(targetIdx, savedNewTarget, "", "member")
    }else if (!editMode) {
      setNewTarget(null)
    }
  }, [savedNewTarget])

  const convertAddedItem = (addItem: any) => {
    // for adding member of custom benchmark(target)
    let startDate: string| null | undefined = tableKey === "new" ? currentTableBatchEndDate : DEFAULT_NEW_START_DATE
    if(tableKey === "current") {
      startDate = first(data)?.startDate || previousEndDate || null
    }
    let endDate = DEFAULT_NEW_END_DATE
    if(tableKey === "previous") {
      startDate = (targetMembers && targetMembers?.length > 0) ? targetMembers[0]?.startDate : null
      // TODO
      endDate = currentTableBatchEndDate || targetMembers[0]?.editedEndDate
    }
    let newId = addItem?.id
    if(!newId) {
      newId = generateId()
    }
    setShowSearchBar(false)

    let typename = addItem.__typename
    let member: TargetMemberFragment = {id: (newId).toString(), name: addItem?.name || "", __typename: "ClientPortfolio"}
    if(typename === "ClientPortfolio") {
    }else if(typename === "Index") {
      let indexId = addItem.id.toString()
      member = {indexId, name: addItem.indexName, __typename: "Index"}
    }else if(typename === "Target") {
      // new created Target
      member = {...DEFAULT_TARGET_DETAIL, targetId: (newId).toString(), name: addItem?.name || "", __typename: "Target"}
      let input = formatTargetToCreateTargetInput(member)
      createTarget({variables: {input}}).then(result => {
        let resultTarget = result?.data?.createTarget?.target
        let targetId = resultTarget?.targetId || 0
        if(resultTarget && targetId > 0 ) {
          console.log("success on create Target id", {result})
          member = resultTarget
          setNewTarget(resultTarget)
          setOpenItems((openItems) => [...openItems, targetId])
        }
      }).catch(err => {
        console.error(err.message)
      })
      .finally(() => {
        setSaving(false)
      })
    }else if(typename === "Vehicle") {
      let vehicle = addItem.vehicle
      member = {vehicle } as any
    }else if(typename === "Group") {
      let groupId = addItem.id.toString()
      member = {groupId, name: addItem.groupName, __typename: "Group"} as any
    } else {
      console.log("what __typename",{addItem})
    }
    let memberType = (TypeNameToMemberTypeMapping[typename] || "P") as any
    let newMember = ({
      startDate: startDate || DEFAULT_NEW_START_DATE,
      endDate,
      memberType: {code: memberType, __typename: "TargetMemberTypeLookup"},
      member,
      assetClass: {id: "", code: ""} as any,
      source: tableKey,
      newAdded: true,
      weight: 0,
      __typename: "TargetDefinition"
    }) as TargetDefinition
    return newMember
  }

  const convertAddedMember = (addItem: any, idx: number) => {
    // for adding member of targetMember, member of member of customBenchmark(target)
    let startDate = tableKey === "new" ? currentTableBatchEndDate : DEFAULT_NEW_START_DATE
    let endDate = DEFAULT_NEW_END_DATE
    if(tableKey === "previous") {
      startDate = (targetMembers && targetMembers?.length > 0) ? targetMembers[idx]?.startDate : null
      // TODO
      // endDate = data.editedEndDate
    }
    let newId = addItem?.id
    if(!newId) {
      newId = generateId()
    }
    setShowTargetMemberSearchBar([])

    let typename = addItem.__typename
    let member: TargetMemberFragment = {id: (newId).toString(), name: addItem?.name || "", __typename: "ClientPortfolio"}
    if(typename === "ClientPortfolio") {
    }else if(typename === "Index") {
      let indexId = addItem.id.toString()
      member = {indexId, name: addItem.indexName, __typename: "Index"}
    }else if(typename === "Target") {
      // new created typename
      member = {...DEFAULT_TARGET_DETAIL, targetId: (newId).toString(), name: addItem?.name || "", __typename: "Target"}
    }else if(typename === "Vehicle") {
      let vehicle = addItem.vehicle
      member = {vehicle } as any
    }else {
      console.log("what __typename",{addItem})
    }
    setShowSearchBar(false)
    let memberType = (TypeNameToMemberTypeMapping[typename] || "P") as any

    let newMember = ({
      startDate: startDate || DEFAULT_NEW_START_DATE,
      endDate,
      memberType: {code: memberType || "P", __typename: "TargetMemberTypeLookup"},
      member,
      assetClass: {id: "", code: ""} as any,
      source: tableKey,
      newAdded: true,
      weight: 0,
      __typename: "TargetDefinition"
    }) as TargetDefinition
    return newMember
  }

  const addMemberComponent = () => {
    let children = (
      <Button color="link" className="text-callan-blue btn-thin py-0" onClick={toggleSearchBar}>
        {"Add Member"}
        <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
      </Button>)
    let members = targetMembers.filter(el => !!(el.member && el.memberType)) || []
    return generateTotalComponent({idx: "add-member", members, children})
  }

  const batchEndDateComponent = () => {
    let setCurrentTableBatchEndDate = (props as any)?.setCurrentTableBatchEndDate

    let labelClass = ""
    if(tableKey === "current") {
      labelClass = "background-private-background text-accent-red"
    }

    return (
      <div key={"batch-end-date"} className={classnames("d-flex w-100 justify-content mb-3", labelClass)}>
        {BatchEndDateInput.map((headerDef, idx) => {
          let property = headerDef.property
          let propertyVal:string|undefined = ""
          if(tableKey === "current" && property === "editedEndDate") {
            propertyVal = currentTableBatchEndDate
          }else if (property === "startDate") {
            propertyVal = moment(get(data[0], property) || "1900-01-01").add(1, 'day').format(DATE_API_FORMAT)
          }

          return(
            <div key={idx} className={classnames('d-flex ml-3')}>
              <div className="client-portfolio-date-label">
                {headerDef.label}
              </div>
              <FormInput
                key={headerDef.property}
                property={property}
                displayName={""}
                type={headerDef.type}
                subtype={headerDef.subtype}
                idx={`${tableKey}-benchmark-${targetId}-item-batch-end-date`}
                editMode={editMode}
                propertyVal={propertyVal}
                readonly={!!headerDef.readonly || tableKey ==="previous"}
                updateValue={(value: any) => {
                  // updateValue(value, headerDef.type, headerDef.field)
                  // handleBatchCurrentEndDateChange(value, data, key)
                  if(setCurrentTableBatchEndDate) setCurrentTableBatchEndDate(value)
                }}
                inputProps={{
                  min: first(data)?.startDate || "1900-01-01",
                }}
                // required={headerDef.required}
                // optionSource={headerDef.optionSource}
                subClasses={headerDef.subClasses || {}}
              />
            </div>
          )
        })}
      </div>
    )
  }

  let searchBar =  (
    <tr className="list-sidebar" key="add-member-search">
      <td key="caret-placeholder"></td>
      <td colSpan={3} className={"pl-0"}>
      <div className="d-flex flex-column">
          {
            <ClientPortfolioAddStatic
              list={{items: targetMembers} as any}
              setEditedList={setEditedBenchmarks}
              editMode={editMode}
              convertAddedItem={convertAddedItem}
              convertToClientPortfolioFragment={(item: any) => `${get(item, "member.id")}:${get(item, "startDate")}:${get(item, "endDate")}`}
              auth={{} as any}
              planIds={planIds}
              searchTypesOverride={[SearchTypeDisplays['Index'], SearchTypeDisplays['Portfolio'], 'Target', SearchTypeDisplays['Vehicles'], SearchTypeDisplays['Group']]}
              showMembers={false}
              jsonPath={`performanceTargetMap[${targetIdx}].target.definition`}
            />
          }
      </div>
      </td>
    </tr>
  )

  // add to targetDefinition
  const updateCreatedTarget = (item: TargetDetailExtendedType) => {
    let newMembers = cloneDeep(targetMembers)
    let replacementIndex = findIndex(newMembers, (el: TargetDefinitionExtendedType) => {
      return ((el?.member as TargetDetailFragment)?.targetId < 0) && (el?.member as TargetDetailFragment)?.name === item?.name
    })
    let replacement = cloneDeep(newMembers[replacementIndex])
    replacement.member = item
    newMembers[replacementIndex] = replacement
    console.log(newMembers)
    setData(newMembers)
    handleChange(newMembers, tableKey)
  }

  // remove ith targetDefinition
  const removeRow = (idx: number) => {
    let members = targetMembers || []
    let newMembers = [...members.slice(0, idx), ...members.slice(idx + 1)]
    console.log("remove", {members, newMembers, tableKey})
    setData(newMembers)
    handleChange(newMembers, tableKey)
  }

  const removeMemberOfRow = (idx: number, memberIdx: number) => {
    let members = targetMembers || []
    let newMember = clone(members[idx])
    if(newMember?.memberType?.code !== "T" || newMember?.member?.__typename !== "Target"){
      return
    }
    let defs = (newMember?.member as TargetDetailExtendedType)?.definition
    if(Array.isArray(defs)) {
      let newDefs = [...defs.slice(0, memberIdx), ...defs.slice(memberIdx+1)]
      let {member, ...memberRest} = newMember
      let {definition, ...definitionRest} = member as TargetDetailExtendedType
      let updatedMember = {...memberRest, member: {definition: newDefs, ...definitionRest}} as TargetDefinitionExtendedType
      let newMembers = [...members.slice(0, idx), updatedMember, ...members.slice(idx + 1)]
      setData(newMembers)
      handleChange(newMembers, tableKey)
    }
  }

  const handleRowValueChange = (idx: any, value: any, property: any) => {
    let newMembers: TargetDefinitionExtendedType[] =
    targetMembers?.map((el: TargetDefinitionExtendedType, index) => {
        if (idx === index) {
          let newValue = cloneDeep(el)
          if (newValue) {
            if(property) {
              set(newValue, property, value)
            }
          }
          return newValue
        } else {
          return el
        }
      }) || []
    setData(newMembers)
    handleChange(newMembers, tableKey)
  }
 // TODO Test if it works, new table, copy current members.
  const handleTargetValueChange = (idx: any, value: any, property: any) => {
    let members = cloneDeep(targetMembers)
    let member = members[idx].member as TargetDetailExtendedType
    let newMember = cloneDeep(member)
    set(newMember, property, value)
    handleRowValueChange(idx, newMember, "member")
  }

  const handleTargetMemberValueChange = (performanceMemberIdx: any, targetMemberIdx: any, value: any, property: any) => {
    let member = targetMembers? targetMembers[performanceMemberIdx]?.member : null
    let target = member as TargetDetailExtendedType

    let newTargetMembers = target.definition?.map((el, index) => {
      if (targetMemberIdx === index) {
        let newValue = cloneDeep(el) || {}
        if (newValue) {
          set(newValue, property, value)
        }
        return newValue
      } else {
        return el
      }
    }) || []
    let newTarget = {...target, definition: newTargetMembers}
    handleRowValueChange(performanceMemberIdx, newTarget, "member")
  }

  const showEndDate = tableKey ==="current" && editMode && (props as any)?.showSuffixEndDate
  if(!showStatus) {
    return <div key={`no-${tableKey}`}></div>
  }else {
    let greyClass = (props as any)?.greyOut ? "bg-gray-full": ""
    return (
      <div
        key={`custom-benchmark-${tableKey}`}
        onClick={(event:FormEvent<any>) => {
          event.stopPropagation()
          event.preventDefault()
          }
        }
      >
        <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
          <div className="white-space-nowrap">
            <h5 className="headline mt-3 text-nowrap d-inline-block">
              {`Members `}
            </h5>
            <h5 className="headline text-uppercase text-gray-70 pl-1 mt-3 d-inline-block">{(showEndDate || tableKey !== "current") && ` (${tableKey})`}</h5>
          </div>
          {/* {`greyState ${!!(props as any)?.greyOut}`} */}
          <div className={classnames("mt-3")}>
            <div className="d-flex flex-column">
            </div>
            {editMode && tableKey === "current" && (
              !showDeleteButton && <Button color="link" className="text-callan-blue btn-thin pt-0" onClick={() => endCurrent()}>
                {"End Current & Start New"}
                <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
              </Button>)}
            {editMode && (tableKey !== "previous") &&
              (showDeleteButton && <Button color="link" className="text-callan-blue btn-thin pt-0" onClick={() => revertEndCurrent()}>
                {"Delete"}
                <FontAwesomeIcon icon="trash" className="ml-2 text-callan-blue" />
              </Button>)
            }
            {tableKey === "current" &&
              (<Button color="link" className="text-callan-blue btn-thin pt-0" onClick={toggleFullHistoryModal}>
                {"View Full History"}
                <FontAwesomeIcon icon="history" className="ml-2 text-callan-blue" />
              </Button>)
            }
            {tableKey === "current" && hasFullHistoryButton &&
              <>
                Beginning {moment(data[0]?.startDate || previousEndDate).add(1, "day").format("MM/DD/YYYY")}
              </>
            }
          </div>
        </div>
        <div>
          <Table hover size="sm" className={classnames(greyClass, {"mb-0": showEndDate})}>
            <thead className="table-border-bottom">
              <tr className="table-header">
                {CombinedInput.map((headerDef, idx) => {
                  // for constant rebalancing lag title
                  let showTargetTitle = targetMembers.some(el => el?.memberType?.code === "T" && el?.member?.__typename === "Target") || idx < 9
                  if(!showTargetTitle || idx === 3) {
                    return <React.Fragment key={idx}></React.Fragment>
                  }
                  if(idx === 0) {
                    return (
                      <th key={`custom-benchmark-header-${idx}`} className={classnames(headerDef.widthClass, "text-uppercase font-weight-normal text-gray-80 font-small text-left")}>
                        <div style={{marginLeft: 15}}>
                          {headerDef.title}
                        </div>
                      </th>
                    )
                  }
                  return (
                    <th key={`custom-benchmark-header-${idx}`} className={classnames(headerDef.widthClass, headerDef?.subClasses?.inputClasses ,"text-uppercase font-weight-normal text-gray-80 font-small text-left")}>
                      {headerDef.title}
                    </th>
                  )
                })}
                <th key={"actions"} className={""}>
                </th>
              </tr>
            </thead>
            <tbody>
              {!isEmptyTable(data) &&
                targetMembers?.map((el: TargetDefinitionExtendedType, idx: number) => {
                  if(!el.member) {
                    return <React.Fragment key={idx}></React.Fragment>
                  }
                  let isTarget = el?.memberType?.code === "T" && el.member.__typename === "Target"
                  let target = isTarget ? (el.member as TargetDetailExtendedType) : undefined
                  let members = target && target?.definition
                  let targetMemberSearchBar =  (
                    <tr className="list-sidebar" key="target-member-search">
                      <td key="caret-placeholder"></td>
                      <td colSpan={3} className={"pl-0"}>
                      <div className="d-flex flex-column">
                        <ClientPortfolioAddStatic
                          list={{items: members || []} as any}
                          setEditedList={setEditedBenchmarks}
                          editMode={editMode}
                          convertAddedItem={(value: any) =>convertAddedMember(value, idx)}
                          convertToClientPortfolioFragment={(item: any) => `${get(item, "member.id")}:${get(item, "startDate")}:${get(item, "endDate")}`}
                          auth={{} as any}
                          planIds={planIds}
                          searchTypesOverride={[SearchTypeDisplays['Portfolio'], SearchTypeDisplays['Vehicles'], SearchTypeDisplays['Index']]}
                          showMembers={false}
                          jsonPath={`performanceTargetMap[${targetIdx}].target.definition[${idx}].member.definition`}
                        />
                      </div>
                      </td>
                    </tr>
                    )
                  let targetDefinition = target?.definition as TargetDefinitionExtendedType[]
                  let isSubMemberTableOpen = !!(target && openItems?.includes(target?.targetId))
                  return (
                    <React.Fragment key={`${tableKey}-custom-benchmark-row-${idx}-${targetId}`}>
                      {/* member of benchmark target, has target as props */}
                      <BenchmarkMemberTable
                        data={el}
                        key={`${tableKey}-custom-benchmark-row-${idx}-${targetId}`}
                        row={`${tableKey}-${idx}`}
                        editMode={editMode}
                        columnDef={CombinedInput}
                        removeRow={() => removeRow(idx)}
                        updateValue={(value, property) => handleRowValueChange(idx, value, property)}
                        tableKey={tableKey}
                        // target part
                        target={target}
                        // isTargetMember={true}
                        updateTargetValue={(value, property) => handleTargetValueChange(idx, value, property)}
                        assetClassesOptions={assetClassesOptions}
                        assetClasses={assetClasses}
                        openItems={openItems}
                        currentHierarchy={[]}
                        setOpenItems={setOpenItems}
                        setLazyFetchedTargetData={setLazyFetchedTargetData}
                      />
                      {isSubMemberTableOpen &&
                      <React.Fragment key={`${tableKey}-custom-benchmark-row-${idx}-${targetId}-${target?.targetId}-target-members`}>
                        {targetDefinition && !isEmptyTargetMembersTable(targetDefinition) &&
                        targetDefinition?.map((targetMember: Maybe<TargetDefinitionExtendedType>, targetMemberIdx: number) => {
                          let isTarget = targetMember?.memberType?.code === "T" && targetMember?.member?.__typename === "Target"
                          let member = isTarget ? (targetMember?.member as TargetDetailExtendedType) : undefined
                          if(targetMember?.endDate !== "9999-12-31") {
                            // only show current members for member of benchmark(target)
                            return <React.Fragment key={`${tableKey}-custom-benchmark-target-member-${idx}-${targetMemberIdx}`}></React.Fragment>
                          }
                          {/* member of member of benchmark target */}
                          return (
                            <BenchmarkMemberTable
                              data={targetMember as TargetDefinitionExtendedType}
                              key={`${tableKey}-custom-benchmark-target-member-${idx}-${targetMemberIdx}-${targetId}-${target?.targetId}`}
                              row={`${tableKey}-${idx}-${targetMemberIdx}`}
                              editMode={editMode}
                              columnDef={CombinedInput}
                              removeRow={() => removeMemberOfRow(idx, targetMemberIdx)}
                              updateValue={(value, property) => handleTargetMemberValueChange(idx, targetMemberIdx, value, property)}
                              updateTargetValue={(value, property) => {
                                // handleTargetValueChange(idx, value, property)
                              }}
                              tableKey={tableKey}
                              // target={member}
                              isTargetMember={true}
                              assetClassesOptions={assetClassesOptions}
                              assetClasses={assetClasses}
                              setLazyFetchedTargetData={setLazyFetchedTargetData}
                              // openItems={openItems}
                              // currentHierarchy={[]}
                              // // TODO
                              // // currentHierarchy={[...currentHierarchy, list.id]}
                              // setOpenItems={setOpenItems}
                            />
                          )
                        })}
                      </React.Fragment>
                      }
                      {isSubMemberTableOpen && editMode && target && target?.name && (tableKey !== "previous") && addTargetMemberComponent(idx)}
                      {editMode && target && target?.name && showTargetMemberSearchBar.includes(target.targetId) && targetMemberSearchBar}
                    </React.Fragment>
                  )
                })}
              {editMode && isEmptyTable(data) && !showSearchBar &&(
                <tr key={"empty"} className={"text-center"}>
                  <td align="center" colSpan={5}>
                    Add members to define this custom benchmark.
                  </td>
                </tr>
              )}
              {/* {editMode && newTarget[0]?.member?.name && showTargetMemberSearchBar && targetMemberSearchBar} */}
              {editMode && showSearchBar && searchBar}
              {editMode && (tableKey !== "previous") && props.targetId >= 0 && addMemberComponent()}
            </tbody>
          </Table>
        </div>
        {showEndDate && (
          batchEndDateComponent()
        )}
      </div>
    )
  }
}

interface TargetMembersComponentProps {
  data: TargetDetailExtendedType
  editMode: boolean
  handleChange: (value: any) => void
  setEditedBenchmarks: (props: any) => void
  targetIdx: number
  targetId: number
  planIds: {id: number, name: string, __typename: string}[]
  assetClasses: ({
    __typename: "AssetClass"
    children?: ({
      __typename: "AssetClass"
    } & AssetClassFragment)[]
  } & AssetClassFragment)[]
  setLazyFetchedTargetData: React.Dispatch<React.SetStateAction<Maybe<TargetDetailExtendedType>[] | null>>
}

type EditedTargetMembersState = {source: TraceableTableKey, members: TargetDefinitionExtendedType[]| null}

const getInitialTargetMembers = (data: TargetDetailExtendedType) => {
  return (data?.definition || []) as TargetDefinitionExtendedType[]
}

const getNewTableData = (data: TargetDefinitionExtendedType[]) => {
  return data.filter(def => def.source === "new")
}

const getCurrentTableData = (data: TargetDefinitionExtendedType[]) => {
  return data.filter(def => def.source === "current")
}

const getPreviousTableData = (data: TargetDefinitionExtendedType[]) => {
  return data.filter(def => def.source === "previous")
}

const getFirstPreviousTableData = (previousData: TargetDefinitionExtendedType[]) => {
  if (previousData.length){
    let firstDate = previousData[0].endDate
    return previousData.filter(el => el?.endDate === firstDate)
  }else {
    return []
  }
}

const getHistoryTableData = (initialData: TargetDefinitionExtendedType[]) => {
  let current = getCurrentTableData(initialData)
  let previous = getPreviousTableData(initialData)
  // let data = [...(current?.members || []), ...(previous?.members || [])]
  let data = [...current, ...previous]
  let headers = CombinedInput.slice(0, 9).map(row => row.title)
  let result = data.reduce((acc, row) => {
    let endDate = row?.endDate || ""
    if(!endDate || !row.member) {
      return acc
    }
    if(!acc[endDate]) {
      acc[endDate] = {
        date: endDate,
        data: []
      }
    }
    acc[endDate].data.push(row)
    return acc
  }, {} as any)
  let rows =  Object.values(result) as {date: string, data: any[]}[]
  return ({headers, rows}) as BenchmarkHistoryData
}

// members table inside a custom benchmark(target), stay below options
const TargetMembersComponent: React.FC<TargetMembersComponentProps> = (props) => {
  let {data, editMode, targetId, planIds, assetClasses, handleChange} = props
  let {setEditedBenchmarks, targetIdx, setLazyFetchedTargetData} = props
  // array of target definition, with source property
  const [currentData, setData] = useState<TargetDefinitionExtendedType[]>(() =>getInitialTargetMembers(data))
  const [initialState, setInitial] = useState<TargetDefinitionExtendedType[]>(currentData)

  const [initialCurrentData, setInitialCurrentData] = useState<TargetDefinitionExtendedType[]>(() => getCurrentTableData(currentData))
  const [newTableData, setNewTableData] =  useState<TargetDefinitionExtendedType[]>(() => getNewTableData(currentData))
  const [currentTableData, setCurrentTableData] = useState<TargetDefinitionExtendedType[]>(() => getCurrentTableData(currentData))
  const [previousTableData, setPreviousTableData] =  useState<TargetDefinitionExtendedType[]>(() => getPreviousTableData(currentData))
  const [firstPreviousTable, setFirstPreviousTableData] = useState<TargetDefinitionExtendedType[]>(() => getFirstPreviousTableData(previousTableData))

  const [greyOutCurrentTable, setGreyOutCurrentTable] = useState<boolean>(false)
  const [showCurrentTable, setShowCurrentTable] = useState<boolean>(!!currentTableData)

  const [currentTableBatchEndDate, setCurrentTableBatchEndDate] = useState<string | null>(null)
  const [showCurrentTableEndDate, setShowCurrentTableEndDate] = useState<boolean>(false)

  const [showNewTable, setShowNewTable] = useState<boolean>(false)

  const [showPreviousTable, setShowPreviousTable] = useState<boolean>(false)

  const [historyData, setHistoryData] = useState<BenchmarkHistoryData>({headers: [], rows: [{date: "", data: []}]})
  const [showFullHistoryModal, setShowFullHistoryModal] = useState<boolean>(false)
  const toggleFullHistoryModal = () => setShowFullHistoryModal(!showFullHistoryModal)

  const endCurrent = () => {
    console.log('end current')
    // setShowDeleteButton(true)
    setShowCurrentTableEndDate(true)
    setGreyOutCurrentTable(true)
    if(editMode) {
      // setCurrentTableBatchEndDate(DEFAULT_NEW_START_DATE)
      setShowNewTable(true)
      feedNewTable()
    }
  }

  const revertEndCurrent = () => {
    handleChange(initialState)
    // setShowDeleteButton(false)
    setShowCurrentTableEndDate(false)
    setCurrentTableBatchEndDate(null)
    setShowNewTable(false)
    setGreyOutCurrentTable(false)
  }

  const showHistoryButtonComponent = () => {
    return (<div className="expand-link" key={"custom-benchmark-history"}>
      <Button color="link" onClick={(event) => {event.stopPropagation(); setShowPreviousTable(!showPreviousTable)}} className={"wider-link-on-hover"}>
        <FontAwesomeIcon
          icon={showPreviousTable  ? "chevron-up" : "chevron-down"}
          size="sm"
        />
        <span className="pl-2 expand-text">{showPreviousTable  ? "Hide History" : "Show History"}</span>
      </Button>
    </div>)
  }

  const handleTableStateChange = (state: any, tableName: string) => {
    let newState: any = state
    switch (tableName) {
      case "current":
        newState = [...newTableData, ...state, ...previousTableData]
        break
      case "new":
        newState = [...state, ...currentTableData, ...previousTableData]
        break
      case "previous":
        let cnt = firstPreviousTable.length
        let restPrevious = previousTableData.slice(cnt)
        let newPreviousState = [...state, ...restPrevious]
        newState = [...newTableData, ...currentTableData, ...newPreviousState]
        break
    }
    handleChange(newState)
  }

  const feedNewTable = () => {
    // handleChange
    let newTableData = initialCurrentData?.map(el => {
      let {source, ...rest} = el
      return {...rest, source: "new"}
    }) || []
    handleTableStateChange(newTableData, "new")
  }

  useEffect(() => {
    let newState = getInitialTargetMembers(data)
    setData(newState)
    let newPreviousTableData = getPreviousTableData(newState)
    let newCurrentData = getCurrentTableData(newState)
    setPreviousTableData(newPreviousTableData)
    setNewTableData(getNewTableData(newState))
    setCurrentTableData(newCurrentData)
    setFirstPreviousTableData(getFirstPreviousTableData(newPreviousTableData))
    if(!editMode) {
      setInitialCurrentData(newCurrentData)
      setHistoryData(getHistoryTableData(newState))
    }
  }, [data])

  useEffect(() => {
    if(!editMode) {
      setShowNewTable(false)
      if(newTableData?.length > 0) {
        // reset after save/cancel
        let defaultNewData = {
          members: [],
          source: "new",
        } as EditedTargetMembersState
        setNewTableData([])
      }
      setShowPreviousTable(false)
      setShowCurrentTableEndDate(false)
      setCurrentTableBatchEndDate(null)
      setGreyOutCurrentTable(false)
    }
  }, [editMode])

  useEffect(() => {
    if(showNewTable && editMode && newTableData?.length === 0) {
      feedNewTable()
    }
  }, [showNewTable])

  useEffect(() => {
    let newDate = currentTableBatchEndDate || null
    if(!editMode || !newDate) {
      return
    }
    let nextCurrentTableData: Maybe<TargetDefinitionExtendedType[]> =
    currentTableData?.map(el => {
      let newValue = cloneDeep(el)
      if (newValue) {
        set(newValue, "editedEndDate", newDate)
        if(el?.source) {
          set(newValue, "source", "current")
        }
      }
      return newValue
      }) || []

    let nextNewTableData: Maybe<TargetDefinitionExtendedType[]> =
    currentTableData?.map(el => {
      let newValue = cloneDeep(el)
      if (newValue) {
        merge(newValue, {startDate: newDate, endDate: DEFAULT_NEW_END_DATE, editedEndDate: DEFAULT_NEW_END_DATE, })
        if(el?.source) {
          set(newValue, "source", "new")
          return {...newValue, isNewAdded: true}
        }
      }
      return newValue
      }) || []
    setNewTableData(nextNewTableData)
    let newState = [...nextNewTableData, ...nextCurrentTableData, ...previousTableData]
    handleChange(newState)
  }, [currentTableBatchEndDate])

  const setEditedTargetMembers = (data: any) => {
  }

  let componentProps = {editMode, planIds, endCurrent, revertEndCurrent, handleTableStateChange, assetClasses, setEditedTargetMembers, targetId, setEditedBenchmarks, targetIdx, setLazyFetchedTargetData}

  let newTableProps = {...componentProps, showStatus: showNewTable, tableKey: "new" as TraceableTableKey, data: newTableData, setData: setNewTableData, currentTableBatchEndDate, showSuffixEndDate: true,}
  let currentTableProps = {...componentProps, showStatus: showCurrentTable, tableKey: "current" as TraceableTableKey, data: currentTableData, setData: setCurrentTableData, showSuffixEndDate: showCurrentTableEndDate, setCurrentTableBatchEndDate, currentTableBatchEndDate, editMode: editMode, greyOut: greyOutCurrentTable, toggleFullHistoryModal, hasFullHistoryButton: previousTableData.length > 0, previousEndDate: previousTableData[0]?.endDate}
  let previousTableProps = {...componentProps, showStatus: showPreviousTable, tableKey: "previous" as TraceableTableKey, data: firstPreviousTable, setData: setFirstPreviousTableData, showSuffixEndDate: true, editMode: editMode, toggleFullHistoryModal, hasFullHistoryButton: true}

  return (
    <Row className={classnames("pl-0")} key={`custom-benchmark-target-members`}>
      <Col className={"px-2"}>
        {/* "---new Table below ------------------------------------------" */}
        {TargetMembersTableComponent(newTableProps as TargetMembersNewTableProps)}
        {/* "---current Table below --------------------------------------" */}
        {TargetMembersTableComponent(currentTableProps as TargetMembersCurrentTableProps )}
        {/* {TargetMembersTableComponent(previousTableProps as TargetMembersPreviousTableProps )}
        {previousTableData.length > 0 && showHistoryButtonComponent()} */}
      </Col>
      <BenchmarkHistoryModal
        isOpen={showFullHistoryModal}
        title={'Custom Benchmark Members'}
        toggle={toggleFullHistoryModal}
        data={historyData}
        idx={targetId}
      />
  </Row>
   )

}

const SingleBenchmarkDisplay: React.FC<SingleBenchmarkProps> = ({ data, idx, editMode, usedOrders, updateValue, deleteRow, setEditedBenchmarks, orderIsSelected, assetClasses, planIds, setLazyFetchedTargetData, plan }) => {
  const [isCustomTarget, setTargetType] = useState(isCustomType(data))
  const [showRed, setShowRed] = useState<boolean>(editMode && !!orderIsSelected)
  const { setError } = useContext(EditButtonContext)
  let constantTableData = data?.target?.constantRebalance || []
  const footnoteModification = data.footnoteModification
  const totalAccount = usedOrders.length

  // searched indexId state, save for adding new target
  // const [indexId, setIndexId] = useState<string>("")
  // target associated with the index
  const [queryTargetFromIndex, {loading: targetLoading, data: targetData, error: targetError}] = useTargetFromIndexLazyQuery({ fetchPolicy: "no-cache" })
  const [createTarget] = useCreateTargetMutation()
  const [customTargetSaving, setSaving] = useState(false)
  // only changed when new created
  const [savedNewTarget, setNewTarget] = useState<Maybe<TargetDetailExtendedType | null>>(null)

  const getTargetFromIndex = (indexId: string) => queryTargetFromIndex({variables: {id: indexId}})
  const convertAddedItem = (addItem: any) => {
    let newTarget = {...DEFAULT_TARGET_DETAIL, targetId: data?.target?.targetId, name: addItem?.name || "", __typename: "Target"}
    console.log("converted", {addItem, data, newTarget})
    return ({
      target: newTarget, __typename: 'ClientPortfolioTarget',
      order: newTarget.targetId,
      editedOrder: null}) as ClientPortfolioTargetExtendedType
  }

  const createNewTargetByClick = (addItem: any) => {
    // send createTarget mutation to api first.
    setSaving(true)
    let newTarget = {...DEFAULT_TARGET_DETAIL, targetId: data?.target?.targetId, name: addItem?.name || "", __typename: "Target"}
    let input = formatTargetToCreateTargetInput(newTarget)
    createTarget({variables: {input}}).then(result => {
      let resultTarget = result?.data?.createTarget?.target
      let targetId = resultTarget?.targetId || 0
      if(resultTarget && targetId > 0 ) {
        console.log("success on create Target id", {result})
        setNewTarget(resultTarget)
      }
    }).catch(err => {
      console.error(err.message)
    })
    .finally(() => {
      setSaving(false)
    })
  }


  useEffect(() => {
    if(editMode) {
      setShowRed(editMode && (!!orderIsSelected || (!!data?.editedOrder && data?.editedOrder > totalAccount)))
    }else {
      setShowRed(false)
    }
  }, [editMode, orderIsSelected])

  useEffect(() => {
    if(editMode && savedNewTarget) {
      // Update the targetId for footnoteModification
      let targetRelation = footnoteModification?.relation.target?.map(relation => {
        if(relation.target < 0) {
          return {...relation, target: savedNewTarget?.targetId}
        }
        return relation
      })

      let value = {...footnoteModification, relation: {
        ...footnoteModification?.relation,
        target: targetRelation,
      }}
      updateValue(idx, value, "", "footnoteModification")
      updateValue(idx, savedNewTarget, "", "target")
    }
  }, [savedNewTarget])

  useEffect(() => {
    if(targetData) {
      let target = targetData?.index?.target
      // Update the targetId for footnoteModification
      let targetRelation = footnoteModification?.relation.target?.map(relation => {
        if(relation.target < 0) {
          return {...relation, target: target?.targetId}
        }
        return relation
      })

      let value = {...footnoteModification, relation: {
        ...footnoteModification?.relation,
        target: targetRelation,
      }}
      updateValue(idx, value, "", "footnoteModification")
      updateValue(idx, target, "", "target")
    }
  }, [targetData])

  useEffect(() => {
    setTargetType(isCustomType(data))
  }, [data?.target?.useType?.code])

  useEffect(() => {
    if(orderIsSelected) {
      setError(`order-${idx}`, ["Please select an order for this benchmark"])
    } else {
      setError(`order-${idx}`, [])
    }
  }, [orderIsSelected])

  useEffect(() => {
    if(targetLoading || customTargetSaving) {
      setError(`target-${idx}`, ["Target loading..."])
    } else {
      setError(`target-${idx}`, [])
    }
    return () => {
      setError(`target-${idx}`, [])
    }
  }, [targetLoading, customTargetSaving])

  return (
    <div key={`${idx}-${data?.target?.targetId}`} className={"row pl-3 mb-4"}>
      <div className={classnames("d-flex border-bottom-line-break justify-content-between w-100 pt-1")}>
        <h5 className={"headline text-uppercase mt-1 py-1"} key={"benchmark-name"}>
          {data?.target?.name || " "}
        </h5>
        {editMode && (
          <Button color="link" className="text-callan-blue btn-thin pt-0" onClick={deleteRow}>
            {"Delete Benchmark"}
            <FontAwesomeIcon icon="trash" className="ml-2 text-callan-blue" />
          </Button>
        )}
      </div>
      <Col sm={6} key={"left"}>
        <div className={"pt-3 pl-2"}>
          {(targetLoading || customTargetSaving )&& <React.Fragment key={"loading"}>
            <LoadingOverlay loadingMessage={"Loading..."} />
          </React.Fragment>}
          <React.Fragment key={"generic"}>
          {!targetLoading && !isCustomTarget && GenericBenchmarkInput.map((el, index) => {
            let { property, type, label, subtype, placeholder, subClasses, searchTypes } = el
            let propertyVal = get(data, property)
            let onChangeCallback = (value: any) => updateValue(idx, value, type, property)
            let { optionSource, readonly } = el
            let options: any
            let wrappedColor = ""
            if (property === "target.useType.code") {
              // if useType changed, target reset to null.
              options = el?.options
              readonly = get(data, "target.targetId", 0) > 0
              // insert a default generic benchmark table
              // let newTarget = {...DEFAULT_TARGET_DETAIL, useType: {code: value}, targetId: newId, name: "", __typename: "Target", footNotes: []}
              // onChangeCallback = (value: any) => updateValue(idx, {targetId:"", name:"", useType: {code: value}}, type, "target")
              onChangeCallback = (value: any) => updateValue(idx, value, type, "target.useType.code")
            } else if (property === "memberType.code") {
              propertyVal = "Index"
              options = el?.options
            } else if (property === "target.definition[0].member.indexId") {
              if (propertyVal < 0) {
                // do not show
                propertyVal = 0
              }
            } else if (property === "editedOrder") {
              // TODO: order change
              if(totalAccount === 1) {
                return <React.Fragment key={`generic-${index}`}></React.Fragment>
              }
              let array = Array.from({length: totalAccount}, (x, i) => i+1)
              options = GetLookupDataToOptions({
                data: array.map(el => ({code: el, value: `${el}`})),
                hideBlank: true,
              })
              onChangeCallback = (value: any) => updateValue(idx, parseInt(value || ""), type, property)
              wrappedColor = editMode && (showRed || !propertyVal || propertyVal > totalAccount) ? "order-error": ""
            } else if(property === "target" && type === "search") {
              let resetSearchOnViewMode = true
              let useType = get(data, "target.useType.code")
              readonly = get(data, "target.targetId", 0) > 0
              return (
                <div key={`generic-${index}`}>
                  <FormInput
                    property={property}
                    displayName={label}
                    type={"search"}
                    subtype={"single"}
                    placeholder={placeholder}
                    staticText=""
                    idx={`generic-benchmark-${idx}-${index}-${data.target?.targetId}`}
                    editMode={editMode}
                    propertyVal={propertyVal || null}
                    updateValue={(value: any) => {
                      console.log("step1, get Index", {value})
                      if(value.id) {
                        getTargetFromIndex(value.id)
                        // setIndexId(value.id)
                      }else {
                        console.log(236, {value})
                      }
                    }}
                    readonly={readonly}
                    searchTypes={searchTypes || []}
                    subClasses={subClasses}
                    resetSearchOnViewMode={resetSearchOnViewMode}
                    // clearValueCallback={() => {
                    //   updateValue(idx, {id:"", name:"", useType: {code: useType}}, type, property)
                    // }}
                  />
                </div>)
            }
            return (
              <div key={`generic-${index}`} className={wrappedColor}>
                <FormInput
                  key={index}
                  property={property}
                  displayName={label}
                  type={type}
                  subtype={subtype}
                  idx={`generic-benchmarks-${idx}-${index}-${data.target?.targetId}`}
                  editMode={editMode}
                  readonly={!!readonly}
                  propertyVal={propertyVal}
                  placeholder={placeholder}
                  subClasses={subClasses}
                  optionSource={optionSource}
                  updateValue={onChangeCallback}
                  options={options}
                  showZero={true}
                />
              </div>
            )
          })}
          </React.Fragment>
          <React.Fragment key={'custom'}>
            {!customTargetSaving && isCustomTarget && CustomBenchmarkInput.map((el, index) => {
              let { property, type, label, subtype, placeholder, subClasses, searchTypes } = el
              let propertyVal = get(data, property)
              let onChangeCallback = (value: any) => updateValue(idx, value, type, property)
              let { optionSource, readonly } = el
              let options: any
              if (property === "target.useType.code") {
                options = el?.options
                readonly = get(data, "target.targetId", 0) > 0
                // if useType changed, target reset to null.
                // onChangeCallback = (value: any) => updateValue(idx, {id:"", name:"", useType: {code: value}}, type, "target")
                onChangeCallback = (value: any) => updateValue(idx, value, type, "target.useType.code")
              }
              if (property === "target.name" && data?.target?.targetId === -1) {
                // there is target name, don't add a new one
                readonly = get(data, "target.targetId", 0) > 0
                return (
                  <FormGroup
                    className={classnames("form-group row")}
                    key={`custom-${index}`}
                  >
                    <Label
                      className={classnames("col-form-label", "col-sm-4")}
                      for={`${index}-${property}`}
                      id={property}
                    >
                      <div
                        className={classnames("d-flex w-100 justify-content-between")}
                      >
                        {label}
                      </div>
                    </Label>
                    <ClientPortfolioAddStatic
                      list={{items: [data]} as any}
                      setEditedList={setEditedBenchmarks}
                      editMode={editMode}
                      convertAddedItem={createNewTargetByClick}
                      convertToClientPortfolioFragment={(item: any) => `${get(item, "target.targetId")}`}
                      auth={{} as any}
                      searchTypesOverride={['Target']}
                      showMembers={true}
                      jsonPath={`performanceTargetMap[${idx}]`}
                      wrapperClasses={"col-sm-6 col-lg-4 ml-1 pl-2"}
                      showTargetName={true}
                    />
                  </FormGroup>)
              }
              let wrappedColor = ""
              if (property === "editedOrder") {
                // benchmark order
                if(totalAccount === 1) {
                  return <React.Fragment key={`generic-${index}`}></React.Fragment>
                }
                let array = Array.from({length: totalAccount}, (x, i) => i+1)
                options = GetLookupDataToOptions({
                  data: array.map(el => ({code: el, value: `${el}`})),
                  hideBlank: true,
                })
                onChangeCallback = (value: any) => updateValue(idx, parseInt(value || ""), type, property)
                wrappedColor = editMode && (showRed || !propertyVal || propertyVal > totalAccount) ? "order-error": ""
              }
              return (
                <div key={`custom-${index}`} className={wrappedColor}>
                  <FormInput
                    key={index}
                    property={property}
                    displayName={label}
                    type={type}
                    subtype={subtype}
                    searchTypes={searchTypes}
                    idx={`custom-benchmark-input-${idx}-${index}-${data.target?.targetId}`}
                    editMode={editMode}
                    readonly={!!readonly}
                    propertyVal={propertyVal}
                    placeholder={placeholder}
                    subClasses={subClasses}
                    optionSource={optionSource}
                    options={options}
                    updateValue={onChangeCallback}
                    showZero={true}
                  />
                </div>
              )
            })}
          </React.Fragment>
          {!customTargetSaving && isCustomTarget && (
            <CustomBenchmarkTargetOptionsComponent
              data={constantTableData}
              editMode={editMode}
              key={idx}
              targetId={data?.target?.targetId || -1}
              // notice this value changes the whole array
              handleChange={(value) =>updateValue(idx, value, "", "target.constantRebalance")}
            />
          )}
        </div>
      </Col>
      <Col sm={5} key={"right"} >
        <FootnoteComponent
          footnoteModification={footnoteModification}
          handleChange={(value: FootnoteModifications) => updateValue(idx, value, "", "footnoteModification")}
          editMode={editMode}
          page={"benchmark"}
          plan={plan}
        />
      </Col>
      {isCustomTarget && data.target &&
        (<Col sm={12} className={""} key={'benchmark-members'}>
          <TargetMembersComponent
            data={data.target}
            handleChange={(value: any) => updateValue(idx, value, "", "target.definition")}
            editMode={editMode}
            targetId={data.target?.targetId}
            planIds={planIds}
            targetIdx={idx}
            assetClasses={assetClasses}
            setEditedBenchmarks={setEditedBenchmarks}
            setLazyFetchedTargetData={setLazyFetchedTargetData}
          />
        </Col>)}
    </div>
  )
}

type OrderSelectedState = {
  [orderId: string]: Maybe<number>[]
}

const getInitialOrderSelectedType = (data: ClientPortfolioTargetExtendedType[] | null | undefined) => {
  let result: OrderSelectedState = {}
  if(!data) {
    return result
  }

  data.map(el => {
    if(el?.target && el?.editedOrder) {
      if(result.hasOwnProperty(el?.editedOrder)) {
        let newState = [...result[el?.editedOrder], el?.target?.targetId]
        result[el?.editedOrder] = newState
      }else {
        result[el?.editedOrder] = [el?.target?.targetId]
      }
    }
  })
  // console.log("result", {data, result})
  return result
}

// if this order is selected by other benchmarks
const orderIsSelected = (data: ClientPortfolioTargetExtendedType, state: OrderSelectedState) => {
  let currentOrder = data?.editedOrder
  if(!currentOrder) {
    return false
  }else {
    let currentSelectedIds = state[currentOrder]
    return currentSelectedIds?.length > 1
  }
}

export const ClientPortfolioBenchmarks = (props: BenchmarkComponentProps) => {
  const componentName = "Benchmarks"
  const { data, initialState, editMode, handleChange, setEditedBenchmarks, assetClasses, planIds, clientPortfolio } = props
  const plan = clientPortfolio?.plan || undefined
  const {setLazyFetchedTargetData} = props
  const [currentData, setData] = useState(data)
  const [currentTargets, setTargets] = useState<ClientPortfolioTargetExtendedType[]>(getTargets(currentData))
  const [currentId, setId] = useState(-1)
  // const [currentNewTableOrder, setNewTableOrder] = useState(-1)
  const [orderSelectedState, setOrderSelected] = useState<OrderSelectedState>(getInitialOrderSelectedType(data?.performanceTargetMap))
  useEffect(() => {
    let newCurrentData = props.data
    setData(newCurrentData)
    setTargets(getTargets(newCurrentData))
    setOrderSelected(getInitialOrderSelectedType(newCurrentData?.performanceTargetMap))
  }, [props.data])

  useEffect(() => {
    let newCurrentData = initialState
    setData(newCurrentData)
    setTargets(getTargets(newCurrentData))
  }, [editMode])

  const updateTargetValue = (idx: number, value: any, type: string, property: string) => {
    handleChange((currentBenchmark) => {
      let target = cloneDeep(get(currentBenchmark, `performanceTargetMap[${idx}]`, {}))
      let newTarget = getNewStateObject({
        state: target,
        newValue: value,
        property: `${property}`,
        type,
      }) as ClientPortfolioTargetExtendedType
      let updateTargets = currentBenchmark.performanceTargetMap || []
      let newCurrentTargets = [...updateTargets.slice(0, idx), newTarget, ...updateTargets.slice(idx + 1)]
      let newData = { ...currentBenchmark,  performanceTargetMap: newCurrentTargets, targetFootnotes: currentData.targetFootnotes }
      return newData
    })
  }
  const usedOrders = currentTargets.map(el => [el.target?.targetId, el?.editedOrder])

  const addNewBenchmark = () => {
    handleChange((currentBenchmark) => {
      let newId = parseInt(uniqueId()) * -1
      // insert a default generic benchmark table
      let newTarget = {...DEFAULT_TARGET_DETAIL, useType: {code: UseTypeCode.GENRIC, value: "Generic", __typename: "UseTypeLookup"}, targetId: newId, name: "", __typename: "Target", footNotes: []} as TargetDetailExtendedType
      let newClientPortfolioTarget = {
        target: newTarget,
        order: newId,
        editedOrder: usedOrders.length + 1,
        footnoteModification: {
          footnote: DEFAULT_EMPTY_FOOTNOTE,
          previousFootnote: undefined,
          change: "none",
          relation: {
            target: [{clientPortfolio: clientPortfolio?.id, target: newId}]
          }
        },
        __typename: "ClientPortfolioTarget"
      } as ClientPortfolioTargetExtendedType
      let updateTargets = currentBenchmark.performanceTargetMap || []

      let newTargets = [...updateTargets, newClientPortfolioTarget]
      return {...currentBenchmark, performanceTargetMap: newTargets, targetFootnotes: currentData.targetFootnotes}
    })
  }

  const deleteBenchmark = (idx: number) => {
    handleChange((currentBenchmark) => {
      let updateTargets = currentBenchmark.performanceTargetMap || []
      let newCurrentTargets = [...updateTargets.slice(0, idx), ...updateTargets.slice(idx + 1)]
      let newData = { performanceTargetMap: newCurrentTargets, targetFootnotes: currentData.targetFootnotes }
      return {...currentBenchmark, ...newData}
    })
  }

  return (
    <div className={"pl-0 position-relative"} key={`${componentName}`}>
      <div className="add-benchmark-position">
        {editMode && <Button color="link" className="text-callan-blue btn-thin pt-0" onClick={() => addNewBenchmark()}>
          Add New Benchmark
          <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
        </Button>}
      </div>
      {currentTargets?.map((target: ClientPortfolioTargetExtendedType | null, idx: number) => {
        if (!target) {
          return <React.Fragment key={idx}></React.Fragment>
        }
        let selected = orderIsSelected(target, orderSelectedState)
        return (
          <SingleBenchmarkDisplay
            data={target}
            key={idx}
            idx={idx}
            usedOrders={usedOrders}
            updateValue={updateTargetValue}
            deleteRow={() => deleteBenchmark(idx)}
            editMode={editMode}
            setEditedBenchmarks={setEditedBenchmarks}
            setLazyFetchedTargetData={setLazyFetchedTargetData}
            orderIsSelected={selected}
            planIds={planIds}
            assetClasses={assetClasses}
            plan={plan}
          />
        )
      })}
    </div>
  )
}
