import React, { Component, useState, useRef, RefObject } from "react"
import _ from "lodash"
import iassign from "immutable-assign"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Popover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parse, ParsedQuery } from 'query-string'
import classnames from "classnames"
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useParams,
  useLocation,
} from "react-router-dom"
import moment from "moment"
import {Location} from 'history'
import {
  convertLookupToString,
  excludePropertyArray,
  reShapeObject,
} from "../../helpers/object"
import { ManagerType } from "../../helpers/helpers"
import {
  useManagerEmployeesQuery,
  UpdatePersonInput,
  useUpdatePersonMutation,
  useCreatePersonMutation,
  CreatePersonInput,
  Person,
  ManagerEmployeesDocument,
  PersonInvestment,
  useUpdatePersonEquityMutation,
  UpdatePrivateEquityInvestmentInput,
  useCreatePersonEquityMutation,
  CreatePrivateEquityInvestmentInput,
  useDeletePersonEquityMutation,
  DeletePrivateEquityInvestmentInput,
} from "../../__generated__/graphql"
import PlaceHolder from "../ui/PlaceHolder"
import {
  PersonPicker,
  PersonDetail,
  PreShapeActions,
  BlankPerson,
  QueryPeopleDetails,
} from "../Shared/Person"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import { DATE_API_FORMAT } from "../../helpers/constant"
import EditButtons from '../ui/EditButtons'
import Auth from '../../Auth/Auth'

interface ManagerAssetClientProps {
  id: number
  managerType?: ManagerType
  auth: Auth
}

const ManagerPeople: React.FC<ManagerAssetClientProps> = ({
  id,
  managerType,
  auth,
}: ManagerAssetClientProps) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [search, setSearch] = useState("")
  const [modal, setModal] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [newPerson, setNewPerson] = useState("")
  const [expandSearchBar, setExpandSearchBar] = useState(false)
  const resultRef = useRef<PersonDetail>(null)
  const searchBarRef = useRef<HTMLInputElement>(null)
  const [updatePerson] = useUpdatePersonMutation()
  const [createPerson] = useCreatePersonMutation()
  const [updatePersonEquity] = useUpdatePersonEquityMutation()
  const [createPersonEquity] = useCreatePersonEquityMutation()
  const [deletePersonEquity] = useDeletePersonEquityMutation()
  const location = useLocation() as Location
  let match = useRouteMatch()
  const history = useHistory()
  let { data, loading, error } = useManagerEmployeesQuery({
    variables: {
      id,
    },
  })

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const updateStateFromResult = (result: any) => {
    setSaving(false)
    if (result && result.data) {
      let unformattedNewData = reShapeObject(
        _.cloneDeep(result.data.person || {}),
        PreShapeActions
      )
      let previousState = resultRef!.current?.state
      setEditMode(false)
      if (newPerson !== "") {
        setNewPerson("")
        history.push(`${match.path}/${result.data.person?.id}`)
      }
      resultRef!.current?.setState({
        ...previousState,
        currentState: unformattedNewData,
        initialState: unformattedNewData,
      })
    }
  }

  const handleSubmit = () => {
    let currentPerson = resultRef!.current?.state.currentState
    let initialPerson = resultRef!.current?.state.initialState
    if (!auth.checkPermissions(['edit:manager'])){
      return
    }
    if (!currentPerson) {
      return
    }
    if (!initialPerson) {
      return
    }
    setSaving(true)

    let formattedData = convertLookupToString(currentPerson, false, [
      "OwnershipAmount",
      "PersonAllocationTimeType",
      "PersonApproximationTime",
      "InvestmentType",
      "PersonProductTitle",
    ])

    _.set(formattedData, "street", _.get(formattedData, "street").join("\n"))
    // fix names for save
    _.set(
      formattedData,
      "background.ownershipAmtCode",
      _.get(formattedData, "background.ownershipAmt")
    )

    delete formattedData.background.ownershipAmt
    delete formattedData.id
    _.set(formattedData, "orgId", _.get(formattedData, "employer.id"))
    let equityList = formattedData.privateEquityInvestments
    delete formattedData.privateEquityInvestments
    delete formattedData.employer
    let formattedDataRemove = iassign(
      formattedData,
      (currentState) => currentState?.products,
      (selectedTable) => {
        let rows = _.cloneDeep(selectedTable)
        rows = rows.map((row: any) => {
          const productId = row.product?.product?.id
          if(productId){
            row.productId = productId
            delete row.product
            if (!currentPerson.active && row.active) {
              row.active = false
              row.dateLeftProduct = currentPerson.terminatedDate
              row.reasonLeftProduct = "Left the firm."
            }
            if (!row.prodTitleNum){
              row.prodTitleNum = "_14"
            }
            return row
          } else {
            return null
          }
        })
        return _.compact(rows)
      }
    )

    const formattedRemoveNull = formattedDataRemove //_.pickBy(formattedDataRemove, _.identity);

    if (currentPerson.id == 0) {
      const createData = {
        patch: excludePropertyArray(formattedRemoveNull, ["__typename", "active"]),
      } as CreatePersonInput

      createPerson({
        variables: { input: createData },
        update: (cache, { data }) => {
          const org: any = cache.readQuery({
            query: ManagerEmployeesDocument,
            variables: { id: id },
          })
          let updatedOrg = iassign(
            org,
            (currentOrg) => currentOrg?.org?.employees,
            (selectedOrg) => {
              let rows = _.cloneDeep(selectedOrg as any[])
              rows.push(data?.person)
              return rows
            }
          )
          cache.writeQuery({
            query: ManagerEmployeesDocument,
            variables: { id: id },
            data: updatedOrg,
          })
        },
      })
        .then(updateStateFromResult)
        .catch((err) => {
          setSaving(false)
          console.log("Error testManagerSummary", err.message)
          // throw new Error(`${err.message}`)
        })
    } else {
      const updateData = {
        id: currentPerson.id,
        patch: excludePropertyArray(formattedRemoveNull, ["__typename", "active"]),
      } as UpdatePersonInput

      updatePerson({ variables: { input: updateData } })
        .then(updateStateFromResult)
        .catch((err) => {
          setSaving(false)
          console.log("Error testManagerSummary", err.message)
          // throw new Error(`${err.message}`)
        })
    }

    const deletedEquity = initialPerson.privateEquityInvestments.filter(
      (oldEquity: PersonInvestment) => {
        return !_.find(equityList, (currentEquity) => {
          return currentEquity.investmentId === oldEquity.investmentId
        })
      }
    )
    if (deletedEquity.length > 0) {
      const deleteData = {
        id: currentPerson.id,
        investmentIds: _.map(deletedEquity, (equity) => equity.investmentId),
      } as DeletePrivateEquityInvestmentInput

      deletePersonEquity({ variables: { input: deleteData } }).catch((err) => {
        console.log("Error testManagerSummary", err.message)
        // throw new Error(`${err.message}`)
      })
    }

    let newEquity = _.remove(equityList, (equ: PersonInvestment) => {
      return equ.investmentId == null
    })
    if (equityList.length > 0) {
      const updateData = {
        id: currentPerson.id,
        patch: excludePropertyArray({ patch: equityList }, ["__typename"])
          .patch,
      } as UpdatePrivateEquityInvestmentInput

      updatePersonEquity({ variables: { input: updateData } })
        .then(updateStateFromResult)
        .catch((err) => {
          console.log("Error testManagerSummary", err.message)
          // throw new Error(`${err.message}`)
        })
    }

    if (newEquity.length > 0) {
      const newData = {
        id: currentPerson.id,
        patch: excludePropertyArray({ patch: newEquity }, [
          "investmentId",
          "__typename",
        ]).patch,
      } as CreatePrivateEquityInvestmentInput

      createPersonEquity({ variables: { input: newData } })
        .then(updateStateFromResult)
        .catch((err) => {
          console.log("Error testManagerSummary", err.message)
          // throw new Error(`${err.message}`)
        })
    }
  }

  const createNewPerson = () => {
    if (search !== "" && auth.checkPermissions(['edit:manager'])) {
      setNewPerson(search)
      setSearch("")
      setEditMode(true)
      history.push(`${match.path}/new`)
      setShowCreate(false)
      setModal(false)
    } else {
      setShowCreate(true)
    }
  }

  const openPopover = () => {
    if (search !== "") {
      setModal(true)
      setShowCreate(false)
    } else {
      searchBarRef.current?.focus()
      setShowCreate(!showCreate)
    }
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      {auth.checkPermissions(['edit:manager']) &&
        <Button
          color="light btn-thin"
          className="mr-1"
          onClick={openPopover}
          id={"create-person-button"}
        >
          <FontAwesomeIcon icon="plus-circle" />
        </Button>
      }
      <Popover
        placement={"top"}
        isOpen={showCreate}
        target={"search-bar-input"}
        // toggle={() => setShowCreate(!showCreate)}
        className={"search-popover"}
      >
        <PopoverHeader>
          Add Person
          <Button className="close" onClick={() => setShowCreate(!showCreate)}>
            &times;
          </Button>
        </PopoverHeader>
        <PopoverBody>
          Enter name and click create to add a new person.
        </PopoverBody>
      </Popover>
      <Form className={classnames({"mr-2 pr-3 border-right": true, "w-35": expandSearchBar || search !== ""})}>
        <FormGroup row className="relative m-0 mr-1">
          <Input
            type="text"
            id="search-bar-input"
            placeholder="Search Results"
            value={search}
            onChange={(e) => {
              const value = e.target.value
              if(showCreate && value.length >= 3){
                setShowCreate(false)
              }
              setSearch(value)
            }}
            innerRef={searchBarRef}
            onFocus={()=> setExpandSearchBar(true)}
            onBlur={()=> setExpandSearchBar(false)}
            onKeyDown={handleEnterKeyDown}
          />
          {search === "" && (
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          )}
          {(search !== "" && auth.checkPermissions(['edit:manager']) )&& (
            <Button
              className="absolute center-v searchbar-add-button pt-1 pb-1 px-3"
              color="primary"
              onClick={openPopover}
            >
              Add Person
            </Button>
          )}
          {(expandSearchBar || search !== "") &&
            <Button
              className="absolute center-v right-1 pt-1 pb-1 px-3"
              color="secondary"
              onClick={() => setSearch("")}
            >
              Cancel
            </Button>
          }
          <Modal
            isOpen={modal}
            toggle={() => {
              setModal(!modal)
            }}
            zIndex={1500}
          >
            <ModalHeader
              toggle={() => {
                setModal(!modal)
              }}
            >
              Create New Person?
            </ModalHeader>
            <ModalBody>
              Are you sure you want to create <b>{search}</b> as a new person.
              People cannot be deleted.
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  setModal(!modal)
                }}
                className="mr-2"
              >
                Cancel
              </Button>
              <Button color="primary" onClick={createNewPerson}>
                Create New Person
              </Button>
            </ModalFooter>
          </Modal>
        </FormGroup>
      </Form>
      { auth.checkPermissions(['edit:manager']) &&
        <EditButtons
          editMode={editMode}
          setEditMode={handleEdit}
          saving={saving}
          onSubmit={handleSubmit}
        />
      }
    </div>
  )

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (data && data.org && data.org.__typename === "Manager") {
    let people = _.cloneDeep(data.org.employees) as Person[]
    if (
      newPerson !== "" &&
      !_.find(people, (o) => {
        return o.id === 0
      })
    ) {
      let newPersonObject = BlankPerson
      const [firstName, lastName] = newPerson.split(" ", 2)
      _.set(newPersonObject, "id", 0)
      _.set(newPersonObject, "firstName", firstName)
      _.set(newPersonObject, "lastName", lastName)
      _.set(newPersonObject, "employer.id", id)
      _.set(newPersonObject, "officeId", 0)
      _.set(
        newPersonObject,
        "officeChangeDate",
        moment().format(DATE_API_FORMAT)
      )
      _.set(
        newPersonObject,
        "personChangeDate",
        moment().format(DATE_API_FORMAT)
      )
      _.set(newPersonObject, "officeChangeFromSfdc", false)
      _.set(newPersonObject, "personChangeFromSfdc", 0)
      _.set(newPersonObject, "showInQuest", 1)
      people?.push(newPersonObject)
    }
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Result
              detailRef={resultRef}
              editMode={editMode}
              people={people}
              managerType={managerType}
              search={search}
              match={match}
              history={history}
              productList={data.org.products}
              location={location}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

interface Props {
  people: any
  editMode: boolean
  managerType?: ManagerType
  search: string
  detailRef: RefObject<PersonDetail>
  match: any
  history: any
  productList: any
  location?: Location
}

class Result extends Component<Props> {
  state = {
    currentPerson: this.props.people[0],
  }
  constructor(props: any) {
    super(props)
  }
  render() {
    const parsedParams:ParsedQuery = parse(this.props.location?.search || "")
    const initialKeyExecFilter = Array.isArray(parsedParams.keypeople) ? parsedParams.keypeople[0] : parsedParams.keypeople || null
    return (
      <Row>
        <Switch>
          <Route path={`${this.props.match.path}/:personId?`}>
            <PersonHolder
              detailRef={this.props.detailRef}
              people={this.props.people}
              editMode={this.props.editMode}
              search={this.props.search}
              history={this.props.history}
              match={this.props.match}
              productList={this.props.productList}
              managerType={this.props.managerType}
              initialKeyExecFilter={!!initialKeyExecFilter}
            />
          </Route>
        </Switch>
      </Row>
    )
  }
}

function PersonHolder({
  detailRef,
  people,
  editMode,
  search,
  history,
  match,
  productList,
  managerType,
  initialKeyExecFilter,
}: any) {
  let { personId } = useParams() as {personId?: string}
  if (personId === "new") {
    personId = "0"
  }
  const person = _.find(people, (o) => {
    return o.id == personId
  })
  if (!person) {
    return (
      <>
        <RouteLeavingGuard
          when={editMode}
          navigate={(path) => history.push(path)}
        />
        <PersonPicker
          people={people}
          search={search}
          history={history}
          match={match}
          type={"manager"}
          initialKeyExecFilter={initialKeyExecFilter}
        />
        <Col md="8" lg="9" className="pl-1">
          <div className="pane">
            <Row>
              <Col>
                <h3>No Person Selected</h3>
              </Col>
            </Row>
          </div>
        </Col>
      </>
    )
  }
  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={(path) => history.push(path)}
      />
      <PersonPicker
        people={people}
        search={search}
        history={history}
        match={match}
        type={"manager"}
        selectedPerson={person}
        initialKeyExecFilter={initialKeyExecFilter}
      />
      { personId === "0" &&
      // Bypass the query when dealing with a new person as they won't exist
        <PersonDetail
          ref={detailRef}
          person={person}
          editMode={editMode}
          key={personId}
          productList={productList}
          managerType={managerType}
        />
      }
      { personId !== "0" &&
        <QueryPeopleDetails
          detailRef={detailRef}
          personId={person.id}
          editMode={editMode}
          key={personId}
          productList={productList}
          managerType={managerType}
        />
      }
    </>
  )
}

export default ManagerPeople
