import React from "react"

import {
  Row,
  Col,
  ListGroupItem
} from "reactstrap"
import { SearchGlidePathResultFragment } from "../../../__generated__/graphql"
import { isGlidePathVersionActive as isVersionActive, } from "../../../helpers/helpers"

export const TargetDateResult = (props:SearchGlidePathResultFragment) => {
  const { tdName: name, philosophy, glidePath } = props
  // let inactiveText = '';
  // if (inactive === true) inactiveText = 'INACTIVE';
  // else if (inactive === false) inactiveText = 'ACTIVE';
  const status = isVersionActive(props?.terminationDate) ? 'Active' : 'Inactive'

  return (
    <ListGroupItem tag="a" href={`/glidepaths/${glidePath?.id}/overview`}>
      <div className="category target">
        <div className="category-text">
          <h6>TargetDate</h6>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md="3">
            <h3>{ name }</h3>
            <h5 className="d-inline mr-1">Manager:</h5>
            <p className="d-inline">{ glidePath?.manager?.name }</p>
          </Col>
          <Col md="9">
            <Row>
              <Col md={{ size:"4", offset: "6" }}>
                <h5>Glide Path Philosophy</h5>
                <p>{philosophy?.value}</p>
              </Col>
              <Col md="2">
                <h5>Status</h5>
                <p>{status}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroupItem>
  )
}