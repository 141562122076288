import { disableFragmentWarnings, gql } from '@apollo/client'

import {
  PERSON_FRAGMENT,
  ME_FRAGMENT,
  ME_DEPARTMENT_FRAGMENT,
  ME_REPORT_FRAGMENT,
  PERSON_BASIC_FRAGMENT,
} from './PersonFragments'

disableFragmentWarnings()

export const ME_QUERY = gql`
  query Me {
    me {
      ...MeFragment
    }
  }
  ${ME_FRAGMENT}
`

export const ME_DEPARTMENT_QUERY = gql`
  query MeDepartment {
    me {
      ...MeDepartmentFragment
    }
  }
  ${ME_DEPARTMENT_FRAGMENT}
`

export const ME_REPORT_QUERY = gql`
  query MeReport {
    me {
      ...MeReportFragment
    }
  }
  ${ME_REPORT_FRAGMENT}
`

export const PERSON_QUERY = gql`
  query Person($id: Int!) {
    person(id: $id) {
      ...PersonFragment
    }
  }
  ${PERSON_FRAGMENT}
`

export const PERSON_BASIC_QUERY = gql`
  query PersonBasic($id: Int!) {
    person(id: $id) {
      ...PersonBasicFragment
    }
  }
  ${PERSON_BASIC_FRAGMENT}
`

export const UPDATE_PERSON_MUTATION = gql`
  mutation UpdatePerson(
      $input: UpdatePersonInput!,
    ) {
    person: updatePerson(input: $input) {
      ...PersonFragment
    }
  }
  ${PERSON_FRAGMENT}
`

export const CREATE_PERSON_MUTATION = gql`
  mutation CreatePerson(
      $input: CreatePersonInput!,
    ) {
    person: createPerson(input: $input) {
      ...PersonFragment
    }
  }
  ${PERSON_FRAGMENT}
`

export const UPDATE_PERSON_EQUITY_MUTATION = gql`
  mutation UpdatePersonEquity(
      $input: UpdatePrivateEquityInvestmentInput!,
    ) {
    person: updatePrivateEquityInvestment(input: $input) {
      ...PersonFragment
    }
  }
  ${PERSON_FRAGMENT}
`

export const CREATE_PERSON_EQUITY_MUTATION = gql`
  mutation CreatePersonEquity(
      $input: CreatePrivateEquityInvestmentInput!,
    ) {
    person: createPrivateEquityInvestment(input: $input) {
      ...PersonFragment
    }
  }
  ${PERSON_FRAGMENT}
`

export const Delete_PERSON_EQUITY_MUTATION = gql`
  mutation DeletePersonEquity(
      $input: DeletePrivateEquityInvestmentInput!,
    ) {
    person: deletePrivateEquityInvestment(input: $input) {
      status
      message
    }
  }
`

export const FETCH_CURRENT_USER_PERSON_ID = gql`
  query FetchCurrentUserPersonId {
    me {
      id
      person {
        id
        firstName
        lastName
      }
    }
  }
`

export const UPDATE_USER_METADATA = gql`
  mutation UpdateUserMetadata(
      $input: UpdateUserMetadataInput!,
    ) {
    updateUserMetadata(input: $input) {
      ...MeReportFragment
    }
  }
  ${ME_REPORT_FRAGMENT}
`
