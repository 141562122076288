import React from "react"

// import { initialState, IState, reducer } from './reducer';

export interface stateProps {
  isSideBarOpen?: boolean
  isSideBarCollapse?: boolean
}

export interface FuncProps {
  toggleSideBar?: () => void
  toggleSideBarCollapse?: () => void
}
export interface ContextProps extends FuncProps {
  state: stateProps
}

const initialState = {
  isSideBarOpen: true,
  isSideBarCollapse: true,
  toggleSideBar: () =>
    (initialState.isSideBarOpen = !initialState.isSideBarOpen),
  toggleSideBarCollapse: () =>
    (initialState.isSideBarCollapse = !initialState.isSideBarCollapse)
}

const IState = {
  state: {
    isSideBarOpen: initialState.isSideBarOpen,
    isSideBarCollapse: initialState.isSideBarCollapse
  },
  toggleSideBar: initialState.toggleSideBar,
  toggleSideBarCollapse: initialState.toggleSideBarCollapse
}

export const MyContext = React.createContext<ContextProps>(IState)

// Provider for sidebar
export class MyProvider extends React.Component {
  state = {
    isSideBarOpen: IState.state.isSideBarOpen,
    isSideBarCollapse: initialState.isSideBarCollapse
  }
  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          toggleSideBar: () => {
            console.log("state.isSideBar is now ", this.state.isSideBarOpen)
            this.setState({ isSideBarOpen: !this.state.isSideBarOpen }, () => {
              console.log(this.state.isSideBarOpen)
            })
          },
          toggleSideBarCollapse: () => {
            console.log(
              "state.isSideBarCollapse is now ",
              this.state.isSideBarCollapse
            )
            this.setState(
              { isSideBarCollapse: !this.state.isSideBarCollapse },
              () => {
                console.log(this.state.isSideBarCollapse)
              }
            )
          }
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    )
  }
}
