import React from "react"
import { Col, Container, Row } from "reactstrap"
import { WithTopNav } from "./Components/ui/LayoutWrapper"
import PlaceHolder from "./Components/ui/PlaceHolder"
import { appProps } from "./queries/extended/appProps"
import { LandingPage, useMeQuery } from "./__generated__/graphql"
// This is no longer used

const RedirectLogin: React.FC<appProps> = (props: appProps) => {

  let { data, loading, error } = useMeQuery()

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  const firms = data?.me?.appMetadata?.firms || []
  const landingPage = data?.me?.appMetadata?.landingPage
  if(landingPage === LandingPage.client_portal && firms.length > 0) {
    props.history.replace(`/clients/${firms[0]}`)
  } else if (landingPage === LandingPage.manager_products && firms.length > 0) {
    props.history.replace(`/managers/${firms[0]}/products`)
  } else {
    props.history.replace(`/`)
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="pane">
            <p>
              New to the Callan questionnaire? Please go{" "}
              <a href="http://callandna.helpdocsonline.com/contact-us">here</a>{" "}
              for instructions on how to get set up.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default WithTopNav(RedirectLogin)
