import React, { Component, useState, useContext, useRef, RefObject } from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { Table, Container, Row, Col, Button } from 'reactstrap'
import classnames from 'classnames'
import iassign from 'immutable-assign'
import moment, { Moment } from 'moment'

import { CalendarContext, appDate } from "../../Context/CalendarContext"
import { CalendarPicker } from '../CalendarPicker'
import { excludePropertyArray, convertLookupToString } from '../../helpers/object'
import { FormInput } from '../ui/Forms/FormInput'
import { FormInputField, DATE_API_FORMAT } from '../../helpers/constant'
import { managerTypeBreakdown, listQuarters, numbroFormatter } from '../../helpers/helpers'
import { useAssetsByLocationQuery, useUpdateManagerAssetsByLocationMutation, AssetsByRegion, AssetsByLocationQuery, ManagerAssetsByAssetClass, ManagerAssetsInput, AssetClassType, useGetLookupQuery, GetLookupQuery, OtherAssetExplanation } from '../../__generated__/graphql'
import { useHistory } from 'react-router-dom'
import { WorldMap } from '../Shared/WorldMap'
import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import PlaceHolder from '../ui/PlaceHolder'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import exportTables from '../../helpers/exportTable'

interface Props {
  setSearchDate: (searchDate:string) => void
  data: AssetsByLocationQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<Result>
  searchDate: string
  regionData: GetLookupQuery
}

interface HistoricalProps {
  data: AssetsByLocationQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<HistoricalResult>
  fetchMore: any
}

interface idProps {
  id: number
  auth: Auth
}

interface AssetClassInputField extends FormInputField{
  assetClass?: string
}

const locationOrder = [
  {code: "USA", value: "United States"},
  {code: "CAN", value: "Canada"},
  {code: "UK", value: "United Kingdom"},
  {code: "JPN", value: "Japan"},
  {code: "AUL", value: "Australia"},
  {code: "HK", value: "Hong Kong"},
  {code: "SIN", value: "Singapore"},
  {code: "OAXJP", value: "Other Asia ex-Japan"},
  {code: "AME", value: "Africa/Middle East"},
  {code: "LA", value: "Latin America"},
  {code: "OTH", value: "Other"},
  {code: "EXUK", value: "Europe ex-UK"},
  {code: "DEN", value: "Denmark"},
  {code: "EEU", value: "Eastern Europe"},
  {code: "FIN", value: "Finland"},
  {code: "FRA", value: "France"},
  {code: "GER", value: "Germany"},
  {code: "ITA", value: "Italy"},
  {code: "NET", value: "Netherlands"},
  {code: "NOR", value: "Norway"},
  {code: "SPA", value: "Spain"},
  {code: "SWE", value: "Sweden"},
  {code: "SWI", value: "Switzerland"},
  {code: "OEU", value: "Other Europe"},
]

export const europeExUkCountries = [
  {code: "DEN", value: "Denmark"},
  {code: "EEU", value: "Eastern Europe"},
  {code: "FIN", value: "Finland"},
  {code: "FRA", value: "France"},
  {code: "GER", value: "Germany"},
  {code: "ITA", value: "Italy"},
  {code: "NET", value: "Netherlands"},
  {code: "NOR", value: "Norway"},
  {code: "SPA", value: "Spain"},
  {code: "SWE", value: "Sweden"},
  {code: "SWI", value: "Switzerland"},
  {code: "OEU", value: "Other Europe"}
]

const regionOrder = [
  {code: "USA", value: "United States"},
  {code: "CAN", value: "Canada"},
  {code: "UK", value: "United Kingdom"},
  {code: "EXUK", value: "Europe ex-UK"},
  {code: "AUL", value: "Australia"},
  {code: "JPN", value: "Japan"},
  {code: "HK", value: "Hong Kong"},
  {code: "SIN", value: "Singapore"},
  {code: "OAXJP", value: "Other Asia ex-Japan"},
  {code: "CHN", value: "China"},
  {code: "LA", value: "Latin America"},
  {code: "AME", value: "Africa/Middle East"},
  {code: "EAFE", value: "Developed Intl Mkts (EAFE)"},
  {code: "GBL", value: "Global"},
  {code: "EMKT", value: "Emerging Markets"},
  {code: "OTH", value: "Other"},
  // {code: "DEN", value: "Denmark"},
  // {code: "EEU", value: "Eastern Europe"},
  // {code: "FIN", value: "Finland"},
  // {code: "FRA", value: "France"},
  // {code: "GER", value: "Germany"},
  // {code: "ITA", value: "Italy"},
  // {code: "NET", value: "Netherlands"},
  // {code: "NOR", value: "Norway"},
  // {code: "SPA", value: "Spain"},
  // {code: "SWE", value: "Sweden"},
  // {code: "SWI", value: "Switzerland"},
  // {code: "OEU", value: "Other Europe"},
]

const tooltipMapping = {
  "OAXJP": "OtherAsia",
  "AME": "AfricaMiddleEast",
  "LA": "LatinAmerica",
  "EXUK": "EuropeExUk",
  "OEU": "OtherEurope",
  "EAFE": "DevelopedIntl",
  "GBL": "Global",
  "EMKT": "EmergingMarkets"
}

const orangeDotMapping = {
  "EXUK": "EuropeExUk",
}

const firstHistoricalDate = moment(appDate).subtract(5, "years")

export const handleCountryIndent = (val: any) => {
  // CAL-687 Indent European countries under Europe ex-UK
  return europeExUkCountries.some((country) => val.code === country.code)
    ? <div style={{ textIndent: '1.5em' }}>{val.value}</div>
    : val.value
}

const ManagerAssetsLocation: React.FC<idProps> = ({ id, auth }: idProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [screen, setScreen] = useState(context.period === "historical" ? "historical" :"single")
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const resultRef = useRef<Result>(null)
  const historicalResultRef = useRef<HistoricalResult>(null)
  const [updateManagerAssetsByLocation] = useUpdateManagerAssetsByLocationMutation()
  const history = useHistory()
  let { data, loading, error, fetchMore } =
  useAssetsByLocationQuery({
    variables: {
      id,
      startDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT),
      endDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT)
    },
    // partialRefetch: true,
    fetchPolicy: 'network-only'
  })
  const { loading: regionLoading, error: regionError, data: regionData } = useGetLookupQuery({
    variables: { name: "RegionType" }
  })

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let currentAssets = resultRef!.current?.state.currentState.assets
    let initialAssets = resultRef!.current?.state.initialState.assets
    if(screen === "historical"){
      currentAssets = historicalResultRef!.current?.state.currentState.assets
      initialAssets = historicalResultRef!.current?.state.initialState.assets
    }

    if(!currentAssets || currentAssets.__typename !== "Manager"){
      return
    }
    if(!initialAssets || initialAssets.__typename !== "Manager"){
      return
    }
    let assets = initialAssets
    let assetsByRegion = currentAssets.assetsByRegion.filter(x => {
      if(assets && assets.__typename === "Manager" && assets.assetsByRegion){
        if(!_.find(assets.assetsByRegion, (o) => JSON.stringify(o) === JSON.stringify(x))){
          return true
        }
        return false
      }
      return true
    })
    let assetsByAssetClass = currentAssets.assetsByAssetClass.filter(x => {
      if(assets && assets.__typename === "Manager" && assets.assetsByAssetClass){
        if(!_.find(assets.assetsByAssetClass, (o) => JSON.stringify(o) === JSON.stringify(x))){
          return true
        }
        return false
      }
      return true
    })
    let otherExplanation:any = []
    if(currentAssets.otherAssetExplanation){
      otherExplanation = currentAssets.otherAssetExplanation.filter(x => {
        if(assets && assets.__typename === "Manager" && assets.otherAssetExplanation){
          if(!_.find(assets.otherAssetExplanation, (o) => JSON.stringify(o) === JSON.stringify(x))){
            return true
          }
          return false
        }
        return true
      })
    }

    const formattedAssetsByRegion = convertLookupToString(assetsByRegion, true)
    const formattedAssetsByAssetClass = convertLookupToString(assetsByAssetClass, true)
    const updateData = {
      id: currentAssets.id,
      patch: {
        assetsByRegion: _.map(formattedAssetsByRegion, (fabr) => {return excludePropertyArray(fabr, ["__typename", "updateDate", "originType"])}),
        assetsByAssetClass: _.map(formattedAssetsByAssetClass, (fabac) => {return excludePropertyArray(fabac, ["__typename", "updateDate", "originType"])}),
        otherAssetExplanation: _.map(otherExplanation, (oe) => {return excludePropertyArray(oe, ["__typename"])})
      }
    } as ManagerAssetsInput
    updateManagerAssetsByLocation({
      variables: {
        input: updateData,
        startDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT),
        endDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT)
      }
    })
      .then(result => {
        setSaving(false)
        if (result && result.data) {
          // let unformattedNewData = { assets: result.data.assets?.org }
          // resultRef!.current?.setState({ currentState: unformattedNewData, initialState: unformattedNewData })
          setEditMode(false)
          historicalResultRef.current?.resetHistory()
        }
      })
      .catch(err => {
        setSaving(false)
        console.error("Error testManagerSummary", err.message)
        // throw new Error(`${err.message}`)
      })
  }
  let explanation = _.get(data, 'assets.otherAssetExplanation[0].region', "")
  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={true}
          updateType={(type:string) => setScreen(type)}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className=" mt-1 ml-1 text-callan-blue" onClick={()=> exportTables({extraRows: [{values:["Other Explanation",explanation]}]})}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
        {screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>historicalResultRef.current?.loadMore()}>
            {historicalResultRef.current?.state.loadingMore && "Loading"}
            {!historicalResultRef.current?.state.loadingMore && "Load 5 More Years"}
          </Button>
        }
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} />
        }
      </div>
    </>
  )

  if (loading || regionLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error || regionError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{regionError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (data && data.assets && data.assets.__typename === "Manager") {
    if(screen === "single" && regionData){
      return (
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <Result
                key={searchDate}
                ref={resultRef}
                editMode={editMode}
                setEditMode={setEditMode}
                setSearchDate={setSearchDate}
                data={data}
                searchDate={searchDate}
                regionData={regionData}
              />
            </Col>
          </Row>
        </Container>
      )
    } else if(screen === "historical") {
      return (
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <HistoricalResult
                ref={historicalResultRef}
                editMode={editMode}
                setEditMode={setEditMode}
                data={data}
                fetchMore={fetchMore}
              />
            </Col>
          </Row>
        </Container>
      )
    }
  }
  return <div>data doesn't exist</div>
}

const AssetRegionInputList:FormInputField[] = [
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency"},
  { property: "boundary", label: "", type: "boundary"},
  { property: "numberOfAccounts", label: "", type: "number"},
]

const AssetClassInputList:AssetClassInputField[] = [
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "EQ"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "FI"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "BAL"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "ALT"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "OTH"},
]

class Result extends Component<Props> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data
  }

  static getDerivedStateFromProps(props: Props, state:any) {
    return {
      currentState: props.editMode ? state.currentState : props.data,
      initialState: props.data,
    }
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    region: string,
    assetClass?: string
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["assets", table]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as any[])
        var selectedRow = _.find(rows, (o) => {return (o?.region.code === region) && (!assetClass || o?.assetClass.code === assetClass)})
        if(selectedRow){
          _.set(selectedRow, property, value);
        } else {
          let newRow
          if(table === "assetsByRegion"){
            newRow = {
              assetsUnderManagement: null,
              numberOfAccounts: null,
              quarterEndDate: this.props.searchDate,
              region: {..._.find(this.props.regionData.__type?.enumValues, (o) => {return o.code === region}), __typename: "RegionTypeLookup"}
            }
            _.set(newRow, property, value)
          } else {
            // assetsByAssetClass
            newRow = {
              assetClass: {code: assetClass, __typename: "AssetClassTypeLookup"},
              assetsUnderManagement: null,
              quarterEndDate: this.props.searchDate,
              region: {..._.find(this.props.regionData.__type?.enumValues, (o) => {return o.code === region}), __typename: "RegionTypeLookup"}
            }
            _.set(newRow, property, value)
          }

          rows.push(newRow)
        }
        return rows
      }
    )
    // dont need to put "...this.state" here, only need to set currentState, the rest will stay the same
    //this.setState({ ...this.state, currentState: newState })
    this.setState({ currentState: newState })
  }

  handleOtherChange = (
    value: any
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      currentState => (currentState?.assets as any)?.otherAssetExplanation,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable)
        var selectedRow = rows[0]
        if(selectedRow){
          _.set(selectedRow, "region", value);
        } else {
          const newRow: OtherAssetExplanation = {
            __typename: "OtherAssetExplanation",
            quarterEndDate: this.props.searchDate,
            region: value
          }
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  formatData = (sourceData:any, mappingList:any) => {
    let mappingListHolder = mappingList
    if(mappingList.__type?.enumValues) mappingListHolder= mappingList.__type?.enumValues
    return _.map(mappingListHolder, (type:any) => {
      const foundData = _.find(sourceData, (o:any) => {return o.region.code === type.code})
      if(foundData){
        return foundData
      } else {
        return {
          assetsUnderManagement: undefined,
          numberOfAccounts: undefined,
          quarterEndDate: this.props.searchDate,
          region: {...type, __typename: "RegionTypeLookup"}
        }
      }
    })
  }

  render() {
    const assetLocations = _.get(this.state.currentState, 'assets.assetsByRegion', [])
    const assetClass = _.get(this.state.currentState, 'assets.assetsByAssetClass', [])
    const otherExplanations = _.get(this.state.currentState, 'assets.otherAssetExplanation', [] as OtherAssetExplanation[])
    const managerTypes = _.get(this.state.currentState, 'assets.managerTypes', [])
    const managerType = managerTypeBreakdown(managerTypes)
    const formattedLocationsData = this.formatData(assetLocations, locationOrder)
    const totalLocations = formattedLocationsData.reduce((result:any,entry:any) => {
      if(entry.region.code !== "EXUK"){
        result.assetsUnderManagement += entry.assetsUnderManagement || 0
        result.numberOfAccounts += entry.numberOfAccounts || 0
      }
      return result;
    },{ assetsUnderManagement: 0, numberOfAccounts: 0 })

    const assetByRegionTotals = _.chain(assetClass)
      .groupBy('assetClass.code')
      .reduce((current, value, key) => {
        _.set(current, key, _.sumBy(value, 'assetsUnderManagement'))
        return current
      }, {})
      .value()

    const sortedLocations = formattedLocationsData

    const sortedRegions = regionOrder
    return(
      <>
      <div className="pane pane-table">
        <div className="pane-title">
          <h3 id="assetsByLocationTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByLocationTooltip">
              Assets by Location
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                className="ml-2 mt-0"
                size="sm"
              />
            </div>
          </h3>
        </div>
        <Row>
          <Col md="5">
            <div className="table-container">
              <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.assets?.name}-Assets by Location`}>
                <thead>
                  <tr className="table-title row-border-olive-100">
                    <th></th>
                    <th className="py-2">Assets ($M)</th>
                    <th className="boundary-left"></th>
                    <th className="boundary-right"></th>
                    <th className="py-2"># Accounts</th>
                  </tr>
                  <tr>
                    <th className="vw-16">Location</th>
                    <th className={"text-right"}>Total</th>
                    <th className="boundary-left"></th>
                    <th className="boundary-right"></th>
                    <th className={"text-right"}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedLocations.map((arr:AssetsByRegion, row:number) => {
                    if(!this.props.editMode && (!arr.assetsUnderManagement && arr.assetsUnderManagement !== 0) && (!arr.numberOfAccounts && arr.numberOfAccounts !== 0)){
                      return
                    }
                    let tooltip = _.get(tooltipMapping, arr.region.code)
                    return (
                      <tr key={row}>
                        <td className="text-left text-nowrap">
                          <div className={classnames('position-relative', { 'tooltip-container' : tooltip })} id={tooltip ? `location${tooltip}TooltipContainer` : undefined}>
                            {arr.region.code === "EXUK" &&
                              <span className="align-items-center ml-n2 mr-1 orange-dot"/>
                            }
                            {tooltip &&
                              <div className="d-inline-flex align-items-center tooltip-icon row-icon" id={`location${tooltip}Tooltip`}>
                                <FontAwesomeIcon
                                  icon={"question-circle" as IconName}
                                  className="mt-0"
                                  size="sm"
                                />
                              </div>
                            }
                            {handleCountryIndent(arr.region)}
                          </div>
                        </td>
                        {AssetRegionInputList.map(({property, label, type, subtype, placeholder, optionSource, readonly}, idx) => {
                          if(type === "boundary"){
                            return (
                              <React.Fragment key={idx}>
                                <td className="boundary-left"></td>
                                <td className="boundary-right"></td>
                              </React.Fragment>
                            )
                          }
                          let propertyVal, onChangeCallback
                          propertyVal = _.get(arr, property)

                          onChangeCallback = (value:any) => this.handleInputChange(value, property, "assetsByRegion", arr.region.code)
                          return(
                            <td key={idx}>
                              <div className={classnames('position-relative')}>
                                <FormInput
                                  property={property}
                                  displayName={label}
                                  type={type}
                                  subtype={subtype}
                                  placeholder={placeholder}
                                  idx={idx + (row*AssetRegionInputList.length)}
                                  editMode={this.props.editMode}
                                  showZero={true}
                                  propertyVal={propertyVal}
                                  updateValue={onChangeCallback}
                                  optionSource={optionSource}
                                  readonly={readonly}
                                />
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  <tr key="Calc">
                    <td className="text-right">Calculated Total</td>
                    <td className="text-right">{numbroFormatter(totalLocations.assetsUnderManagement ,"$0,0.00")}</td>
                    <td className="boundary-left"></td>
                    <td className="boundary-right"></td>
                    <td className="text-right">{numbroFormatter(totalLocations.numberOfAccounts ,"0,0")}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="row-indicator-legend">
              <div className="position-relative">
                <span className="row-indicator orange-dot" />
                Excluded from total
              </div>
            </div>
          </Col>
          <Col md="7" className="pt-4">
            <WorldMap data={assetLocations}/>
          </Col>
        </Row>
      </div>
      { (managerType.LGSH || managerType.RA || managerType.HF || managerType.HFFOF) &&
        <>
          <div className="pane pane-table">
            <div className="pane-title">
              <h3>Assets by Region</h3>
            </div>
            <div className="table-container">
              <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.assets?.name}-Assets by Region`}>
                <thead>
                  <tr>
                    <th className="vw-16">Region</th>
                    <th className={"text-center"}>Equity</th>
                    <th className={"text-center"}>Fixed Income</th>
                    <th className={"text-center"}>Balanced</th>
                    <th className={"text-center"}>Alternatives</th>
                    <th className={"text-center"}>Other</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedRegions.map((region, idx:number) => {
                    return(
                      <AssetClassRow
                        key={idx}
                        data={assetClass}
                        row={idx}
                        editMode={this.props.editMode}
                        updateValue={(value, property, assetClass) => this.handleInputChange(value,property,"assetsByAssetClass",region.code,assetClass)}
                        region={region}
                      />
                    )
                  })}
                   <tr key="Calc">
                    <td className="text-right">Calculated Total</td>
                    <td className="text-right">{numbroFormatter(_.get(assetByRegionTotals, 'EQ') ,"$0,0.00")}</td>
                    <td className="text-right">{numbroFormatter(_.get(assetByRegionTotals, 'FI') ,"$0,0.00")}</td>
                    <td className="text-right">{numbroFormatter(_.get(assetByRegionTotals, 'BAL') ,"$0,0.00")}</td>
                    <td className="text-right">{numbroFormatter(_.get(assetByRegionTotals, 'ALT') ,"$0,0.00")}</td>
                    <td className="text-right">{numbroFormatter(_.get(assetByRegionTotals, 'OTH') ,"$0,0.00")}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="pane pane-table">
            <div className="pane-title">
              Other Explanation
            </div>
            <div className={"m-3"}>
              <FormInput
                property={"region"}
                displayName={""}
                type={"textarea"}
                subtype={""}
                placeholder={""}
                idx={1}
                editMode={this.props.editMode}
                propertyVal={otherExplanations[0] && otherExplanations[0].region}
                updateValue={this.handleOtherChange}
              />
            </div>
          </div>
        </>
      }
    </>
    )
  }
}

interface AssetClassRowProps {
  data: ManagerAssetsByAssetClass[]
  row: number
  editMode: boolean
  region: {
    __typename?: "__EnumValue";
    code: string;
    value?: string | null | undefined;
}
  updateValue: (value:any, property:string, assetClass?:string) => void
}

const AssetClassRow = ({data, row, editMode, region, updateValue}: AssetClassRowProps) => {
  const rowData = data.reduce((result:any,entry:any) => {
    if(entry.region.code === region.code){
      result[entry.assetClass.code] = entry;
    }
    return result;
  },{})
  if(!editMode && (_.find(rowData, (o) => {return !!o.assetsUnderManagement || o.assetsUnderManagement === 0}) === undefined) ){
    return(
      <></>
    )
  }
  const tooltip = _.get(tooltipMapping, region.code)
  return (
    <tr>
      <td className="text-left">
        <div className="position-relative" id={tooltip ? `region${tooltip}TooltipContainer` : undefined}>
          {tooltip &&
              <div className="d-inline-flex align-items-center tooltip-icon row-icon" id={`region${tooltip}Tooltip`}>
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  className="mt-0"
                  size="sm"
                />
              </div>
            }
          {region.value}
        </div>
      </td>
      {AssetClassInputList.map(({property, label, type, subtype, placeholder, optionSource, readonly, assetClass}, idx) => {
        let propertyVal, onChangeCallback, dataObject
        if(assetClass){
          dataObject = rowData[assetClass]
          propertyVal = _.get(dataObject, property)
          onChangeCallback = (value:any) => updateValue(value, property, assetClass)
        } else {
          dataObject = _.find(data,(ob) => ob.region.code === region.code)
          propertyVal = _.get(dataObject, property)
          onChangeCallback = (value:any) => updateValue(value, property)
        }

        return(
          <td key={idx}>
            <FormInput
              property={property+"-ac"}
              displayName={label}
              type={type}
              subtype={subtype}
              placeholder={placeholder}
              idx={idx + (row*AssetClassInputList.length)}
              editMode={editMode}
              showZero={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              readonly={readonly}
            />
          </td>
        )
      })}
    </tr>
  )
}

interface HistoricalResultState {
  currentState: AssetsByLocationQuery
  initialState: AssetsByLocationQuery
  assetClass: string
  displayValue: string
  historicalDate: Moment
  loadingMore: boolean
}

class HistoricalResult extends Component<HistoricalProps> {
  state:HistoricalResultState = {
    currentState: this.props.data,
    initialState: this.props.data,
    assetClass: AssetClassType["EQ"],
    displayValue: "assetsUnderManagement",
    historicalDate: moment(firstHistoricalDate),
    loadingMore: false
  }

  static getDerivedStateFromProps(props: HistoricalProps, state:HistoricalResultState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    return {
      currentState: resetDate && !props.editMode ? props.data : state.currentState,
      initialState: resetDate ? props.data : state.initialState,
      loadingMore: state.loadingMore,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
      assetClass: state.assetClass,
    }
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    region: string,
    date: string,
    assetClass?: string
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["assets", table]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as any[])
        var selectedRow = _.find(rows, (o) => {return (o?.region.code === region && o?.quarterEndDate === date && (!assetClass || o?.assetClass.code === assetClass))})
        if(selectedRow){
          _.set(selectedRow, property, value);
        } else {
          let newRow
          if( table === "assetsByAssetClass" ){
            newRow = {
              __typename: "assetsByAssetClass",
              quarterEndDate: date,
              region: {
                __typename: "RegionTypeLookup",
                code: region
              },
              assetClass: {
                __typename: "AssetClassLookup",
                code: assetClass
              },
              assetsUnderManagement: null,
            }
            _.set(newRow, property, value)
            rows.push(newRow)
          } else if( table === "assetsByRegion" ){
            newRow = {
              __typename: "AssetsByRegion",
              quarterEndDate: date,
              region: {
                __typename: "RegionTypeLookup",
                code: region
              },
              assetsUnderManagement: null,
              numberOfAccounts: null,
            }
            _.set(newRow, property, value)
            rows.push(newRow)
          } else {
            console.error("Error: Object not found to update")
          }
        }
        return rows
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  setAssetClass= (assetClass:AssetClassType) => {
    this.setState({
      ...this.state,
      assetClass: assetClass
    })
  }

  setDisplayValue = (displayValue:string) => {
    this.setState({
      ...this.state,
      displayValue: displayValue
    })
  }

  loadMore = () =>{
    if(this.state.loadingMore){
      return
    }
    this.setState({...this.state, loadingMore: true})
    this.props.fetchMore({
      variables: {
        id: this.state.currentState.assets?.id,
        startDate: this.state.historicalDate.format(DATE_API_FORMAT),
        endDate: moment(this.state.historicalDate).subtract(5, "years").format(DATE_API_FORMAT)
      },
      updateQuery: (previousResult:AssetsByLocationQuery, { fetchMoreResult } :any) => {
        if(!fetchMoreResult){
          return previousResult
        }
        const previousAssets = previousResult.assets
        const newAssets = fetchMoreResult.assets
        if(previousAssets?.__typename !== "Manager" || this.state.currentState.assets?.__typename !== "Manager"){
          return
        }
        let otherAsset = previousAssets.otherAssetExplanation || []
        const returnedState = {
          assets: {
            id: this.state.currentState.assets?.id,
            assetsByRegion: [...previousAssets.assetsByRegion, ...newAssets.assetsByRegion],
            assetsByAssetClass: [...previousAssets.assetsByAssetClass, ...newAssets.assetsByAssetClass],
            otherAssetExplanation: [...otherAsset, ...newAssets.otherAssetExplanation],
            managerTypes: newAssets.managerTypes,
            __typename: previousAssets.__typename
          },
        }
        const currentState = {
          assets: {
            id: this.state.currentState.assets?.id,
            assetsByRegion: [...this.state.currentState.assets.assetsByRegion, ...newAssets.assetsByRegion],
            assetsByAssetClass: [...this.state.currentState.assets.assetsByAssetClass, ...newAssets.assetsByAssetClass],
            otherAssetExplanation: [...otherAsset, ...newAssets.otherAssetExplanation],
            managerTypes: newAssets.managerTypes,
            __typename: previousAssets.__typename
          },
        }
        this.setState({
          ...this.state,
          initialState: returnedState,
          currentState: currentState,
          historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
          loadingMore: false
        })
        return previousResult;
      }
    })
  }

  render() {
    const assetLocations = _.get(this.state.currentState, 'assets.assetsByRegion', [] as AssetsByRegion[])
    let visibleCountries:string[] = []
    const groupedCountries = assetLocations.reduce((result:any,entry:any) => {
      result[entry.quarterEndDate] = result[entry.quarterEndDate] || {};
      result[entry.quarterEndDate][entry.region.code] = result[entry.quarterEndDate][entry.region.code] || {};
      result[entry.quarterEndDate][entry.region.code] = entry;
      visibleCountries = _.union(visibleCountries, [entry.region.code])
      return result;
    },{})

    const assetClass = _.get(this.state.currentState, 'assets.assetsByAssetClass', [] as ManagerAssetsByAssetClass[])
    let visibleRegions:string[] = []
    const groupedRegions = assetClass.reduce((result:any,entry:any) => {
      result[entry.quarterEndDate] = result[entry.quarterEndDate] || {};
      result[entry.quarterEndDate][entry.region.code] = result[entry.quarterEndDate][entry.region.code] || {};
      result[entry.quarterEndDate][entry.region.code][entry.assetClass.code] = entry;
      visibleRegions = _.union(visibleRegions, [entry.region.code])
      return result;
    },{})

    const managerTypes = _.get(this.state.currentState, 'assets.managerTypes', [])
    const managerType = managerTypeBreakdown(managerTypes)
    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

    return(
      <>
      <div className="pane pane-table">
        <div className="pane-title-alt">
          <h3 id="assetsByLocationHistoricalTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByLocationHistoricalTooltip">
              Assets by Location
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                className="ml-2 mt-0"
                size="sm"
              />
            </div>
          </h3>
        </div>
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-item",{ active: this.state.displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets ($M)</li>
          <li className={classnames("horizontal-picker-item",{ active: this.state.displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
        <div className="table-container">
          <Table hover className="table-bordered-internal table-condensed exportable" data-export-name={`${this.props.data.assets?.name}-Assets by Location`}>
            <thead>
              <tr>
                <th>Date</th>
                {locationOrder.map((country) => {
                  if(this.props.editMode || visibleCountries.includes(country.code)){
                    const tooltip = _.get(tooltipMapping, country.code)
                    const orangeDot = _.get(orangeDotMapping, country.code)
                    return(
                      <th key={country.code}>
                        <div className="position-relative" id={tooltip ? `location${tooltip}TooltipContainer` : undefined}>
                          {country.value}
                          {orangeDot && <span className="row-indicator orange-dot" /> }
                          {tooltip &&
                              <div className="d-inline-flex align-items-center tooltip-icon" id={`location${tooltip}Tooltip`}>
                                <FontAwesomeIcon
                                  icon={"question-circle" as IconName}
                                  size="sm"
                                />
                              </div>
                            }
                        </div>
                      </th>
                    )
                  }
                })}
                <th>Calculated Total</th>
              </tr>
            </thead>
            <tbody>
              {allDates.map((quarter:string, row:number) => {
                  return (
                    <AssetClassHistoricalRow
                      date={quarter}
                      key={row + this.state.displayValue}
                      data={groupedCountries}
                      row={row}
                      editMode={this.props.editMode}
                      updateValue={(value, property, region, date) => this.handleInputChange(value,property,"assetsByRegion",region,date)}
                      regions={locationOrder}
                      displayValue={this.state.displayValue}
                      visibleRegions={visibleCountries}
                    />
                  )
                })}
            </tbody>
          </Table>
        </div>
        <div className="row-indicator-legend">
          <div className="position-relative">
            <span className="row-indicator orange-dot" />
            Excluded from total
          </div>
        </div>
      </div>
      { (managerType.LGSH || managerType.RA || managerType.HF || managerType.HFFOF) &&
        <>
          <div className="pane pane-table">
            <div className="pane-title-alt">
              <h3>Assets by Region</h3>
            </div>
            <ul className={"horizontal-picker"}>
              <li className={classnames("horizontal-picker-item",{ active: this.state.assetClass === "EQ" })} onClick={() => this.setAssetClass(AssetClassType["EQ"])}>Equity</li>
              <li className={classnames("horizontal-picker-item",{ active: this.state.assetClass === "FI" })}  onClick={() => this.setAssetClass(AssetClassType["FI"])}>Fixed Income</li>
              <li className={classnames("horizontal-picker-item",{ active: this.state.assetClass === "BAL" })}  onClick={() => this.setAssetClass(AssetClassType["BAL"])}>Balanced</li>
              <li className={classnames("horizontal-picker-item",{ active: this.state.assetClass === "ALT" })}  onClick={() => this.setAssetClass(AssetClassType["ALT"])}>Alternatives</li>
              <li className={classnames("horizontal-picker-item",{ active: this.state.assetClass === "OTH" })}  onClick={() => this.setAssetClass(AssetClassType["OTH"])}>Other</li>
            </ul>
            <div className="table-container">
              <Table hover className="table-bordered-internal table-condensed exportable" data-export-name={`${this.props.data.assets?.name}-Assets by Region`}>
                <thead>
                  <tr>
                    <th>Date</th>
                    {regionOrder.map((country) =>{
                      if(this.props.editMode || visibleRegions.includes(country.code)){
                        const tooltip = _.get(tooltipMapping, country.code)
                        return(
                          <th key={country.code}>
                            <div className="position-relative" id={tooltip ? `region${tooltip}TooltipContainer` : undefined}>
                              {country.value}
                              {tooltip &&
                                <div className="d-inline-flex align-items-center tooltip-icon" id={`region${tooltip}Tooltip`}>
                                  <FontAwesomeIcon
                                    icon={"question-circle" as IconName}
                                    size="sm"
                                  />
                                </div>
                              }
                            </div>
                          </th>
                        )
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {allDates.map((quarter:string, row:number) => {
                    return (
                      <AssetClassHistoricalRow
                        date={quarter}
                        key={row + this.state.assetClass}
                        data={groupedRegions}
                        row={row}
                        editMode={this.props.editMode}
                        updateValue={(value, property, region, date) => this.handleInputChange(value,property,"assetsByAssetClass",region,date,this.state.assetClass)}
                        regions={regionOrder}
                        displayValue={"assetsUnderManagement"}
                        assetClass={this.state.assetClass}
                        visibleRegions={visibleRegions}
                      />
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      }
    </>
    )
  }
}

interface AssetClassHistoricalRowProps {
  data: any
  row: number
  editMode: boolean
  date: string
  regions: any[]
  displayValue: string
  assetClass?: string
  visibleRegions: string[]
  updateValue: (value:any, property:string, region:string, date:string) => void
}

const AssetClassHistoricalRow = ({data, row, editMode, regions, updateValue, displayValue, date, assetClass, visibleRegions}: AssetClassHistoricalRowProps) => {
  let rowTotal = 0
  return (
    <tr className="fadein">
      <td className="nowrap">
        {date}
      </td>
      {regions.map((region, idx) => {
        if(!editMode && !visibleRegions.includes(region.code)){
            return <React.Fragment key={idx} />
        }
        let propertyVal, onChangeCallback
        if(assetClass){
          propertyVal = _.get(data, date+'.'+region.code+'.'+assetClass+'.'+displayValue)
          onChangeCallback = (value:any) => updateValue(value, displayValue, region.code, date)
        } else {
          propertyVal = _.get(data, date+'.'+region.code+'.'+displayValue)
          onChangeCallback = (value:any) => updateValue(value, displayValue, region.code, date)
          if(region.code !== "EXUK"){
            rowTotal += propertyVal || 0
          }
        }

        return(
          <td key={idx}>
            <FormInput
              property={displayValue}
              displayName={""}
              type={"number"}
              subtype={displayValue === "assetsUnderManagement" ? "currency" : ""}
              placeholder={""}
              idx={idx + (row*AssetClassInputList.length) + (assetClass || "")}
              editMode={editMode}
              showZero={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
            />
          </td>
        )
      })}
      {!assetClass &&
        <td className="text-right">
          { displayValue === 'numberOfAccounts' ? numbroFormatter(rowTotal , "0,0" ) : numbroFormatter(rowTotal , "$0,0.00" )}
        </td>
      }
    </tr>
  )
}

export default ManagerAssetsLocation
