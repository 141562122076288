import { gql } from "@apollo/client"

const SEARCH_RESULT_ORG_FRAGMENT = gql`
  fragment SearchResultOrgFragment on Org {
    id
    name
    address {
      street
      city
      state
      zip
      country
    }
    isInactive
  }
`

const SEARCH_RESULT_INDEX_FRAGMENT = gql`
  fragment SearchResultIndexFragment on Index {
    indexId: id
    indexName: name
    org {
      id
      name
    }
    identifiers {
      bloomberg
    }
    assetClass {
      id
      shortName
      parent {
        id
        shortName
      }
    }
    status {
      code
      value
    }
  }
`

const SEARCH_PRODUCT_RESULT_FRAGMENT = gql`
  fragment SearchProductResultFragment on ProductFields {
    id
    name
    inactive
    manager {
      id
      name
      address {
        city
        state
        country
      }
    }
    assetClass {
      id
      shortName
      parent {
        id
        shortName
      }
    }
    investmentManagementStrategy {
      value
    }
  }
`

const SEARCH_GLIDE_PATH_RESULT_FRAGMENT = gql`
  fragment SearchGlidePathResultFragment on GlidePathVersion {
    id
    tdName: name
    terminationDate
    philosophy {
      value
    }
    glidePath {
      id
      manager {
        id
        name
      }
    }
    series {
      product {
        id
        name
      }
    }
  }
`

const SEARCH_VEHICLE_RESULT_FRAGMENT = gql`
  fragment SearchVehicleResultFragment on VehicleFields {
    fundid
    vehicleName: name
    status {
      value
    }
    type {
      value
    }
    identifiers{
      ticker
    }
    category {
      value
    }
    product {
      product {
        id
        name
        manager{
          id
          name
        }
        assetClass {
          id
          shortName
        }
      }
    }
  }
`

const SEARCH_PERSON_RESULT_FRAGMENT = gql`
  fragment SearchPersonResultFragment on Person {
    id
    firstName
    lastName
    title
    city
    state {
      code
      value
    }
    employer {
      id
      name
    }
    active
  }
`

const SEARCH_FILE_RESULT_FRAGMENT = gql`
  fragment SearchFileResultFragment on File {
    fileid: id
    fileName: name
    description
    type{
      code
      value
    }
    subType{
      code
      value
    }
    managers {
      id
      name
    }
    versions {
      updated
      person {
        firstName
        lastName
      }
    }
    products {
      product {
        id
        name
      }
    }
    plans{
      id
      name
    }
    vehicles{
      vehicle {
        fundid
        name
      }
    }
    glidePaths {
      id
      name
    }
    mimeType
    url
  }
`

const SEARCH_RESULT_GROUP_FRAGMENT = gql`
  fragment SearchResultGroupFragment on Group {
    groupId: id
    groupName: shortName
    assetClass {
      id
      shortName
      parent {
        id
        shortName
      }
    }
    groupStatus: status {
      code
      value
    }
    groupClass: class {
      code
      value
    }
    source
  }
`

export const SEARCH_RESULT_FRAGMENT = gql`
  fragment SearchResultFragment on SearchResults {
    hits
    data {
      ... on Manager {
        ...SearchResultOrgFragment
      }
      ... on Bank {
        ...SearchResultOrgFragment
      }
      ... on RecordKeeper {
        ...SearchResultOrgFragment
      }
      ... on Client {
        ...SearchResultOrgFragment
      }
      ... on ProductFields {
        ...SearchProductResultFragment
      }
      ... on GlidePathVersion {
        ...SearchGlidePathResultFragment
      }
      ... on VehicleFields {
        ...SearchVehicleResultFragment
      }
      ... on Person {
        ...SearchPersonResultFragment
      }
      ... on File {
        ...SearchFileResultFragment
      }
      ... on Index {
        ...SearchResultIndexFragment
      }
      ... on Group {
        ...SearchResultGroupFragment
      }
    }
  }
  ${SEARCH_RESULT_ORG_FRAGMENT}
  ${SEARCH_PRODUCT_RESULT_FRAGMENT}
  ${SEARCH_GLIDE_PATH_RESULT_FRAGMENT}
  ${SEARCH_VEHICLE_RESULT_FRAGMENT}
  ${SEARCH_PERSON_RESULT_FRAGMENT}
  ${SEARCH_FILE_RESULT_FRAGMENT}
  ${SEARCH_RESULT_INDEX_FRAGMENT}
  ${SEARCH_RESULT_GROUP_FRAGMENT}
`

export const SEARCH_FILTER_FETCH_FRAGMENT = gql`
  fragment SearchFilterFetchFragment on SearchResults {
    hits
    metaData {
      resultCounts {
        managers
        custodians
        record_keepers
        clients
        people
        products
        documents
        vehicles
        target_dates
        indices
        groups
      }
      managerCounts {
        manager
        count
      }
      assetClassCounts {
        assetClass
        count
      }
      vehicleCounts {
        vehicle
        count
      }
      locationCounts {
        location
        count
        cities {
          city
          count
        }
      }
    }
  }
`

export const AUTOCOMPLETE_MANAGER_FRAGMENT = gql`
  fragment AutocompleteManagerFragment on ManagerAutocomplete {
    id
    name
  }
`

export const AUTOCOMPLETE_CLIENT_FRAGMENT = gql`
  fragment AutocompleteClientFragment on ClientAutocomplete {
    id
    clientName
  }
`

export const AUTOCOMPLETE_CUSTODIAN_FRAGMENT = gql`
  fragment AutocompleteCustodianFragment on CustodianAutocomplete {
    id
    custodianName
  }
`

export const AUTOCOMPLETE_RECORD_KEEPER_FRAGMENT = gql`
  fragment AutocompleteRecordKeeperFragment on RecordKeeperAutocomplete {
    id
    recordKeeperName
  }
`

export const AUTOCOMPLETE_PRODUCT_FRAGMENT = gql`
  fragment AutocompleteProductFragment on ProductAutocomplete {
    id
    productName
    managerName
  }
`

export const AUTOCOMPLETE_TARGET_DATE_FRAGMENT = gql`
  fragment AutocompleteTargetDateFragment on TargetDateAutocomplete {
    id
    versionName
    managerName
    glideId
  }
`

export const AUTOCOMPLETE_VEHICLE_FRAGMENT = gql`
  fragment AutocompleteVehicleFragment on VehicleAutocomplete {
    vehicleId: id
    ticker
    cusip
    vehicleName
    managerName
    productId
    vehicleProductName
    vehicleType
  }
`

export const AUTOCOMPLETE_PEOPLE_FRAGMENT = gql`
  fragment AutocompletePeopleFragment on PeopleAutocomplete {
    id
    firstName
    lastName
    firmName
    firmId
  }
`

export const AUTOCOMPLETE_FILE_FRAGMENT = gql`
  fragment AutocompleteFileFragment on FileAutocomplete {
    fileid: id
    fileName
    description
    type
    subType
    managerNames
    clientNames
    productNames
    fundNames
  }
`

export const AUTOCOMPLETE_PLAN_FRAGMENT = gql`
  fragment AutocompletePlanFragment on PlanAutocomplete {
    id
    planName
    clientId
  }
`

export const AUTOCOMPLETE_INDEX_FRAGMENT = gql`
  fragment AutocompleteIndexFragment on IndexAutocomplete {
    indexId: id
    indexName
    providerId
    providerName
    bloombergId
  }
`
export const AUTOCOMPLETE_GROUP_FRAGMENT = gql`
  fragment AutocompleteGroupFragment on GroupAutocomplete {
    groupId: id
    groupName
    classCode
    classCodeDesc
  }
`

export const SEARCH_AUTOCOMPLETE_FRAGMENT = gql`
  fragment SearchAutocompleteFragment on AutocompleteResults {
    ... on ManagerAutocomplete {
      ...AutocompleteManagerFragment
    }
    ... on CustodianAutocomplete {
      ...AutocompleteCustodianFragment
    }
    ... on RecordKeeperAutocomplete {
      ...AutocompleteRecordKeeperFragment
    }
    ... on ClientAutocomplete {
      ...AutocompleteClientFragment
    }
    ... on ProductAutocomplete {
      ...AutocompleteProductFragment
    }
    ... on TargetDateAutocomplete {
      ...AutocompleteTargetDateFragment
    }
    ... on VehicleAutocomplete {
      ...AutocompleteVehicleFragment
    }
    ... on PeopleAutocomplete {
      ...AutocompletePeopleFragment
    }
    ... on FileAutocomplete {
      ...AutocompleteFileFragment
    }
    ... on PlanAutocomplete {
      ...AutocompletePlanFragment
    }
    ... on IndexAutocomplete {
      ...AutocompleteIndexFragment
    }
    ... on GroupAutocomplete {
      ...AutocompleteGroupFragment
    }
  }
  ${AUTOCOMPLETE_MANAGER_FRAGMENT}
  ${AUTOCOMPLETE_CLIENT_FRAGMENT}
  ${AUTOCOMPLETE_PRODUCT_FRAGMENT}
  ${AUTOCOMPLETE_TARGET_DATE_FRAGMENT}
  ${AUTOCOMPLETE_VEHICLE_FRAGMENT}
  ${AUTOCOMPLETE_PEOPLE_FRAGMENT}
  ${AUTOCOMPLETE_FILE_FRAGMENT}
  ${AUTOCOMPLETE_PLAN_FRAGMENT}
  ${AUTOCOMPLETE_CUSTODIAN_FRAGMENT}
  ${AUTOCOMPLETE_RECORD_KEEPER_FRAGMENT}
  ${AUTOCOMPLETE_INDEX_FRAGMENT}
  ${AUTOCOMPLETE_GROUP_FRAGMENT}
`
