import React, { Component } from "react"
import { default as classnames, default as classNames } from "classnames"
import { History } from "history"
import _, { uniq } from "lodash"
import { match } from "react-router-dom"
import { ListGroupItem } from "reactstrap"

import { Vehicle } from "."
import { OpenEndedVehicle } from "../../../__generated__/graphql"
import { OptionItem, Picker } from "../../Shared/Picker"

export interface VehiclePickerProps {
  history: History
  match: match
  search: string
  vehicles: Vehicle[]
  selectedVehicle?: string
}

export interface VehiclePickerState {
  activeFilters: number[]
  pickerSortOptions: OptionItem[]
  activeSort: number
  pickerFilters: OptionItem[]
}

const showTerminatedFilterId = 99

export class VehiclePicker extends Component<
  VehiclePickerProps,
  VehiclePickerState
> {
  formatter: Intl.NumberFormat
  state: VehiclePickerState

  constructor(props: VehiclePickerProps) {
    super(props)
    let activeFilters: number[] = []
    let pickerFilters: OptionItem[] = []
    this.formatter = new Intl.NumberFormat("en-Us", {
      style: "currency",
      currency: "USD",
    })

    const categories = uniq(this.props.vehicles.map((vehicle) => _.get(vehicle, "vehicle.category.value", ""))).sort()
    categories.map((category: string) => category && pickerFilters.push({ id: pickerFilters.length+1, name: category }))
    
    pickerFilters.push({ id: showTerminatedFilterId, name: 'Show Terminated'})
    
    this.state = {
      pickerFilters,
      activeFilters: activeFilters,
      pickerSortOptions: [
        {
          id: 1,
          name: "Name ( A to Z )",
        },
        {
          id: 2,
          name: "Name ( Z to A )",
        },
      ],
      activeSort: 1,
    }
  }

  onFilter = (filterId: number) => {
    const { activeFilters } = this.state
    const index = activeFilters.indexOf(filterId)
    if (index === -1) {
      activeFilters.push(filterId)
    } else {
      activeFilters.splice(index, 1)
    }
    this.setState({ activeFilters })
  }

  onSort = (sortId: number) => {
    this.setState({ activeSort: sortId })
  }

  emptyMessage() {
    return `There are no vehicles associated with this product.`
  }

  sortVehicles = () => {
    const { vehicles, search } = this.props
    const { activeFilters, activeSort } = this.state
    return vehicles
      .filter((vehicle) => {
        if (!activeFilters.includes(showTerminatedFilterId)) {
          return vehicle.vehicle?.status?.code !== "t"
        }
        return true
      })
      .filter((vehicle) => {
        const activeFiltersWithoutTerminated = activeFilters.filter(
          (i) => ![showTerminatedFilterId].includes(i)
        )
        if (search) {
          const name = (vehicle.vehicle?.name || "").toLocaleLowerCase().trim()
          if (!name.includes(this.props.search.toLowerCase().trim()))
            return false
        } else if (activeFiltersWithoutTerminated.length > 0) {
          return _.some(activeFilters, (id: number) => {
            const matchingFilter = _.find(this.state.pickerFilters, { id: id })
            return matchingFilter?.name === vehicle.vehicle?.category?.value
          })
        }
        return true
      })
      .sort((vehicleA, vehicleB) => {
        const nameA = (vehicleA.vehicle?.name || "").toLocaleLowerCase().trim()
        const nameB = (vehicleB.vehicle?.name || "").toLocaleLowerCase().trim()

        switch (activeSort) {
          case 1:
            return nameA.localeCompare(nameB)
          case 2:
            return nameB.localeCompare(nameA)
          default:
            throw new Error(`unrecognized sort choice ${activeSort}`)
        }
      })
  }

  vehicleListItem = (TopLevelVehicle: Vehicle, selectedVehicle?: string) => {
    let openEndedVehicle
    const vehicle = TopLevelVehicle.vehicle
    if ((vehicle as OpenEndedVehicle).openEndedVehicle) {
      openEndedVehicle = (vehicle as OpenEndedVehicle).openEndedVehicle
    }
    const fundid = _.get(vehicle, "id")

    if (vehicle) {
      let status = "N/A"
      if (vehicle?.status?.code === "c") status = "Closed"
      if (vehicle?.status?.code === "o") status = "Open"
      if (vehicle?.status?.code === "t") status = "Terminated"

      return (
        <ListGroupItem
          tag="a"
          key={fundid}
          className={classNames({
            active: selectedVehicle === fundid,
          })}
          onClick={() => {
            this.props.history.push(`${this.props.match.url}/${fundid}`)
          }}
        >
          <h5>{vehicle.name}</h5>
          <dl>
            <dt>Vehicle Category:</dt>
            <dd>{vehicle.category?.value || "-"}</dd>

            <dt>Identifier:</dt>
            <dd>
              {vehicle.identifiers?.ticker ||
                vehicle?.identifiers?.cusip ||
                "—"}
            </dd>

            <dt>Vehicle ID:</dt>
            <dd>{vehicle.id || "-"}</dd>
          </dl>
          <div
            className={classnames({
              "list-group-item-status": true,
              "background-olive-10": status === "Open",
              "background-orange-30": status === "Terminated",
              "background-gray-20": status === "Closed",
            })}
          >
            {status}
          </div>
        </ListGroupItem>
      )
    }
  }

  render() {
    const { activeFilters, pickerSortOptions, activeSort } = this.state
    const { search, selectedVehicle } = this.props
    const sortedVehicles = this.sortVehicles()

    const uniqPickerFilters: OptionItem[] = Array.from(
      new Set(
        this.state.pickerFilters.map((filter) => {
          return { id: filter.id, name: filter.name }
        })
      )
    )

    return (
      <Picker
        filters={uniqPickerFilters}
        activeFilters={activeFilters}
        sortOptions={pickerSortOptions}
        activeSort={activeSort}
        onFilter={this.onFilter}
        onSort={this.onSort}
        searching={search ? true : false}
      >
        {sortedVehicles.length > 0
          ? sortedVehicles?.map((vehicle) =>
              this.vehicleListItem(vehicle, selectedVehicle)
            )
          : this.emptyMessage()}
      </Picker>
    )
  }
}
