import { defaultProductOverviewSummaryConfig, RealAssetsProductSummaryConfig } from './default'
import { OpenEndedEquityProductOverviewSummaryOverrideConfig } from './OpenEndedEquity'

export const OpenEndedRealAssetsProductSummaryOverrideConfig = {
  openEnded: {
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig.openEnded
  },
  product: {
    ...defaultProductOverviewSummaryConfig,
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig.product,
    targetBenchmarkAdd: true,
    incomeReturnTarget: true
  },
  realAssets: {
    ...RealAssetsProductSummaryConfig
  }
}
