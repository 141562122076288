import { disableFragmentWarnings, gql } from "@apollo/client"

disableFragmentWarnings()

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on Person {
    id
    salutation
    firstName
    lastName
    email
    phone
    title
    designation
    fax
    extension
    mobile
    showInQuest
    street
    city
    state {
      code
      value
    }
    zipcode
    country {
      code
      value
    }
    active
    officeId
    allocationTimes {
      allocationType {
        code
        value
      }
      approximationTime {
        code
        value
      }
    }
    terminatedDate
    reasonLeftFirm
    background {
      keyPerson
      bio
      yearStartedInIndustry
      yearStartedWithFirm
      employeeContract
      ownershipAmt {
        code
        value
      }
    }
    privateEquityInvestments {
      investmentId
      investmentName
      investmentSourced
      investmentLed
      investmentBoard
    }
    products {
      product {
        product {
          id
          name
        }
      }
      prodTitleNum {
        code
        value
      }
      yearStartedWithProduct
      investCommMem
      reasonLeftProduct
      dateLeftProduct
      carriedInterest
      active
    }
    employer {
      id
      name
    }
  }
`

export const PERSON_SUMMARY_FRAGMENT = gql`
  fragment PersonSummaryFragment on Person {
    id
    salutation
    firstName
    lastName
    active
    title
    terminatedDate
    showInQuest
    background {
      keyPerson
    }
    products {
      product {
        product {
          id
          name
        }
      }
      dateLeftProduct
      active
    }
    employer {
      id
      name
    }
  }
`

export const ME_FRAGMENT = gql`
  fragment MeFragment on User {
    id
    firstName
    lastName
    email
    person {
      id
      firstName
      lastName
      title
      department {
        code
        value
      }
      employer {
        id
        name
      }
    }
    appMetadata {
      firms
      landingPage
    }
  }
`

export const ME_DEPARTMENT_FRAGMENT = gql`
  fragment MeDepartmentFragment on User {
    id
    person {
      id
      firstName
      lastName
      title
      department {
        code
        value
      }
    }
    appMetadata {
      firms
      landingPage
    }
  }
`

export const ME_REPORT_FRAGMENT = gql`
  fragment MeReportFragment on User {
    id
    userMetadata {
      showPortfolioRankings
      visibleQuartiles
    }
  }
`

export const PERSON_OF_USER_FRAGMENT = gql`
  fragment PersonOfUserFragment on Person {
    id
    firstName
    lastName
    email
    employer {
      id
      name
    }
    user {
      id
      appMetadata {
        roles
        landingPage
        firms
      }
    }
  }
`

export const PERSON_BASIC_FRAGMENT = gql`
  fragment PersonBasicFragment on Person {
    id
    firstName
    lastName
    email
    employer {
      id
      name
      shortName
    }
  }
`