import React, { Component } from "react"
import {
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
} from "reactstrap"
import { renderToString } from 'react-dom/server'
import { CsvExportParams, GridApi, GridReadyEvent, RowSelectedEvent, SelectionChangedEvent } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { ExcelExportParams, GetRowIdParams } from '@ag-grid-community/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"

import AumTooltip from '../../../helpers/agGridAumTooltip'
import { get } from "lodash"
import LoadingOverlay from "../../ui/Loading"

const Loading = () => renderToString(<LoadingOverlay/>)

interface SortableTableProps {
  loading?: boolean
  columnDefs: any
  onReady?: (props: GridReadyEvent) => void // {api, columnApi}
  onFilterChanged?: (props?: any) => void
  rowId?: string
  noPagination?: boolean
  rowClassRules?: {[cssClassName: string]: string | ((params: any) => boolean)}
  subClasses?: { [name in SSRMTableSubClasses]?: string }
  suppressFocusAfterRefresh?: boolean
  exportParams?: CsvExportParams | ExcelExportParams
  setSelectedRows?:(rows: any[]) => void
  onRowSelected?:(node: any, data: any) => void
  treeData?: boolean
  getDataPath?: (data: any) => string[]
  autoGroupColumnDef?: any
  pageSize?: number
}

interface SSRMTableProps extends SortableTableProps {
  // setDataSource: () => IServerSideDatasource
  serverSide: true
}

const sortableTableSubClasses = [
  "tableWrapperClasses",
  "paginationWrapperClasses",
] as const
export type SSRMTableSubClasses = typeof sortableTableSubClasses[number]

export default class SSRMTable extends Component<SSRMTableProps> {
  state = {
    api: {} as GridApi,
    pageSize: this.props.pageSize || 100,
    pageSizeMenu: false,
    apiInitialized: false,
    pageState: {
      currentPage: 1,
      maxPage: 1,
    },
    totalResults: 0,
  }

  /* Grid Events we're listening to */
  onGridReady = (event: GridReadyEvent) => {
    if(this.props.onReady) this.props.onReady(event)
    if (this.props.loading) {
      event.api.showLoadingOverlay()
    } else {
      event.api.hideOverlay()
    }
    this.setState({
      pageState: {
        currentPage: event.api.paginationGetCurrentPage() +1,
        maxPage: event.api.paginationGetTotalPages()
      },
      totalResults: event.api.paginationGetRowCount(),
      api: event.api,
      apiInitialized:true,
    })
  }

  onBtPrevious = () => {
    this.state.api.paginationGoToPreviousPage();
  };

  toPage = (page:number) => {
    this.state.api.paginationGoToPage(page - 1);
  };

  onBtNext = () => {
    this.state.api.paginationGoToNextPage();
  };

  onPaginationChanged = () => {
    if (this.state.apiInitialized) {
      this.setState({
        pageState: {
          currentPage: this.state.api.paginationGetCurrentPage() +1,
          maxPage: this.state.api.paginationGetTotalPages()
        },
        totalResults: this.state.api.paginationGetRowCount(),
      })
    }
  }

  setPaginationSize = (pageSize:number) => {
    this.setState({pageSize: pageSize})
    this.state.api.paginationSetPageSize(pageSize)
  }

  createPaginationNumbers = () => {
    let numbers = []
    let lastRendered = true
    for (let i = 1; i <= this.state.pageState.maxPage; i++){
      if (i === 1 || i === this.state.pageState.maxPage || (i >= this.state.pageState.currentPage - 2 && i <= this.state.pageState.currentPage + 2)) {
        lastRendered = true
        numbers.push(
          <Button key={i} className={classnames({ active: this.state.pageState.currentPage === i })} onClick={() =>  this.toPage(i)} color="page-number">
            {i}
          </Button>
        );
      } else if (lastRendered){
        lastRendered = false
        numbers.push(
          <Button key={i} color="page-number">
              ...
          </Button>
        );
      }
    }

    return numbers
  }

  onSelectionChanged = (event: SelectionChangedEvent) => {
    if(this.props?.setSelectedRows) {
      let selectedRows = event?.api?.getSelectedRows() || []
      this.props.setSelectedRows(selectedRows)
    }
  }

  onRowSelected = (event: RowSelectedEvent) => {
    if(this.props?.onRowSelected) {
      this.props.onRowSelected(event?.node, event?.data)
    }
  }

  table() {
    return (
      <AgGridReact
        key="ssrm-Table"
        tooltipShowDelay={0}
        blockLoadDebounceMillis={500}
        rowHeight={30} // downgrade to v26.1 doesn't fix autoHeight, set here for now.
        columnDefs={this.props.columnDefs}
        defaultCsvExportParams={this.props.exportParams as CsvExportParams || {}}
        defaultExcelExportParams={this.props.exportParams as ExcelExportParams || {}}
        onRowSelected={this.onRowSelected}
        onSelectionChanged={this.onSelectionChanged}
        defaultColDef={{
          minWidth: 50,
          resizable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            maxNumConditions: 1,
            debounceMs: 500,
          },
          menuTabs: ['filterMenuTab'],
          headerComponentParams: {
            menuIcon: "fa-bars",
          }
        }}
        // fix double icons using headerTemplate.
        icons={{
          sortAscending: renderToString(<FontAwesomeIcon icon={"arrow-down"} className="ml-1"/>),
          sortDescending: renderToString(<FontAwesomeIcon icon={"arrow-up"} className="ml-1"/>),
          filter: renderToString(<FontAwesomeIcon icon="filter"/>),
          tooltip: renderToString(<FontAwesomeIcon icon="question-circle" className="mx-1"/>),
        }}
        pagination={true}
        paginationPageSize={this.state.pageSize}
        suppressPaginationPanel={true}
        onPaginationChanged={this.onPaginationChanged}
        onFilterChanged={this.props.onFilterChanged}
        getRowId={(params: GetRowIdParams) => get(params.data, this.props.rowId || "")}
        // serverSideSortOnServer={true}
        // serverSideFilterOnServer={true}
        overlayLoadingTemplate={Loading()}
        overlayNoRowsTemplate={
          '<span className="ag-overlay-loading-center">No data.</span>'
        }
        rowSelection="multiple"
        rowModelType="serverSide"
        rowClassRules={this.props.rowClassRules}
        enableCellTextSelection={true}
        suppressFocusAfterRefresh={this.props.suppressFocusAfterRefresh}
        animateRows={true}
        onGridReady={this.onGridReady}
        suppressScrollOnNewData={true}
        components={{aumTooltip: AumTooltip}}
        getDataPath={this.props.getDataPath}
        treeData={this.props.treeData}
        autoGroupColumnDef={this.props.autoGroupColumnDef}
        suppressMenuHide={true} // always show column hamburger menu
        //fix error of ResizeObserver for v29. could be removed on v30. https://github.com/ag-grid/ag-grid/issues/6562
        // alwaysShowHorizontalScroll={true}
      />
    )
  }

  pagination() {
    return (
      <>
        <div>
          <Button onClick={() => this.onBtPrevious()} color="link" className="mr-1">
            <FontAwesomeIcon
              icon="chevron-left"
              className="ml-2"
            />
          </Button>
          {this.createPaginationNumbers()}
          <Button onClick={() => this.onBtNext()} color="link" className="mr-1">
            <FontAwesomeIcon
              icon="chevron-right"
              className="ml-2"
            />
          </Button>
          <ButtonDropdown isOpen={this.state.pageSizeMenu} toggle={()=> this.setState({pageSizeMenu: !this.state.pageSizeMenu})} className="mr-1">
            <DropdownToggle caret>
              {this.state.pageSize} per page
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={()=> this.setPaginationSize(5)}>5 per page</DropdownItem>
              <DropdownItem onClick={()=> this.setPaginationSize(10)}>10 per page</DropdownItem>
              <DropdownItem onClick={()=> this.setPaginationSize(25)}>25 per page</DropdownItem>
              <DropdownItem onClick={()=> this.setPaginationSize(50)}>50 per page</DropdownItem>
              <DropdownItem onClick={()=> this.setPaginationSize(100)}>100 per page</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          viewing rows {this.state.pageSize * (this.state.pageState.currentPage - 1) + 1} - {Math.min(this.state.totalResults, this.state.pageSize * (this.state.pageState.currentPage))} out of {this.state.totalResults} results.
        </div>
      </>
    )
  }

  render() {
    let tableWrapperClasses = this.props.subClasses?.tableWrapperClasses || ""
    let paginationWrapperClasses = this.props.subClasses?.paginationWrapperClasses || ""
    return (
      <>
        <div className={classnames("pane pane-table p-0 d-flex flex-grow-1", tableWrapperClasses)}>
          <Row className="flex-grow-1">
            <Col className="detail-table-container ag-grid ag-grid-view ag-theme-balham">
                {this.table()}
            </Col>
          </Row>
        </div>
        {!this.props.noPagination &&
          <Row className={classnames("py-2", paginationWrapperClasses)}>
            <Col>
              {this.pagination()}
            </Col>
          </Row>
        }
      </>
    )
  }
}


