import { ConfigType } from '../../../helpers/constant'

export const OpenEndedPrivateEquityProductManagementOverrideConfig: {
  [property: string]: ConfigType | boolean
} = {
  managementType: true,
  currencyHedging: true,
  compliance: {
    currentTeamStartDate: true,
    trackRecordComments: true,
    GIPSMethodology: true,
    GIPSAuditor: true,
    Auditor: true,
    LastAuditDate: true,
    performanceExamConcluded: true,
    verifiedPerformanceBegan: true,
    previousFirmTrackRecord: true
  }
}
