import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import _, { uniqueId } from "lodash"
import React, { FormEvent, Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Col, Container, Form, Label, Navbar, Row, Table } from "reactstrap"

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import {
  DeleteProductManagementPerformanceCompositeDispersionInput,
  Equity,
  FixedIncome,
  Maybe,
  PassiveProduct,
  Product,
  ProductManagementQuery,
  RealAssets,
  UpdateProductManagementInput,
  useDeletePerformanceCompositeDispersionMutation,
  useProductManagementQuery,
  useUpdateProductManagementMutation,
  ValueAddedWeightCode
} from "../../__generated__/graphql"
import {
  DATE_API_FORMAT,
  FormInputField,
  PerformanceItemUnion,
  ValueAddedItemUnion
} from "../../helpers/constant"
import {
  GetLookupDataToOptions
} from "../ui/LookupOptions"
import { getNewStateObject, useForceUpdate } from "../../helpers/helpers"
import { excludePropertyArray, keys } from "../../helpers/object"
import { getProductType, isTargetDate } from "../../helpers/productFunction"
import ProductManagementConfig from "../../LayoutConfig/Product/ProductManagement/ProductManagement"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import { FormInput } from "../ui/Forms/FormInput"
import PlaceHolder from "../ui/PlaceHolder"
import PerformanceTableDisplay from "./ProductOverviewManagementPerformanceTable"
import ValueAddedTableDisplay from "./ProductOverviewManagementValueAddedTable"
import classnames from 'classnames'
import moment from "moment"
import ErrorDisplay from "../Shared/ErrorDisplay"
import exportTables from "../../helpers/exportTable"

type ValueAddedLocalItem = {
  code: ValueAddedWeightCode
  weight: string | number
}

interface TypedFormInput extends FormInputField {
  displayTypes?: string[]
}

const StrategyDefinitionInput: TypedFormInput[] = [
  { 
    property: "", 
    label: "Strategy Definition", 
    type: "",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 mb-2"
    }
  },
  {
    property: "compliance.currentTeamStartDate",
    label: "Current Team Start Date",
    type: "date",
    placeholder: "MM/DD/YYYY",
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4",
      inputClasses: "text-left"
    }
  },
  {
    property: "compliance.trackRecordComments",
    label: "Track Record Comments",
    type: "text",
    placeholder: "Track Record Comments",
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4 text-right"
    },
    tooltip: {
      icon: "question-circle",
      id: "trackRecordCommentTooltip"
    }
  },
  {
    property: "compliance.previousFirmTrackRecord",
    label: "Previous Firm Track Record",
    type: "radio",
    subtype: "boolean",
    displayTypes: ["openEnded", "hedgeFund"]
  },
  {
    property: "compliance.GIPSMethodology",
    label: "GIPS Methodology",
    type: "radio",
    subtype: "boolean"
  },
  {
    property: "compliance.GIPSAuditor",
    label: "GIPS Auditor",
    type: "text",
    placeholder: "Auditor",
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4 text-right"
    }
  },
  {
    property: "compliance.Auditor",
    label: "Fund Auditor",
    type: "text",
    placeholder: "Auditor",
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4 text-right"
    }
  },
  {
    property: "compliance.LastAuditDate",
    label: "Last Audit Date",
    type: "date",
    placeholder: "MM/DD/YYYY",
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4",
      inputClasses: "text-left"
    }
  },
  {
    property: "compliance.performanceExamConcluded",
    label: "Performance Exam Concluded",
    type: "date",
    placeholder: "MM/DD/YYYY",
    displayTypes: ["openEnded", "hedgeFund"],
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4",
      inputClasses: "text-left"
    }
  },
  {
    property: "compliance.verifiedPerformanceBegan",
    label: "Verified Performance Began",
    type: "date",
    placeholder: "MM/DD/YYYY",
    displayTypes: ["openEnded", "hedgeFund"],
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4",
      inputClasses: "text-left"
    }
  },
  {
    property: "compliance.administrator",
    label: "Administrator",
    type: "text",
    placeholder: "Administrator",
    displayTypes: ["hedgeFund"],
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4 text-right"
    }
  },
  {
    property: "compliance.custodian",
    label: "Custodian",
    type: "text",
    placeholder: "Custodian",
    displayTypes: ["hedgeFund"],
    subClasses: {
      inputWrapperClasses: "col-sm-6 pl-4 text-right"
    }
  }
]

const ManagementSectionInput: TypedFormInput[] = [
  { 
    property: "", 
    label: "Management", 
    type: "",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 my-2"
    }
  },
  {
    property: "managementType.code",
    label: "Security Management Style",
    type: "select",
    subtype: "single",
    optionSource: "ManagementType",
    subClasses: {
      labelClasses: "col-sm-5",
      inputWrapperClasses: "col-sm-6 pl-4"
    },
    dataValues: [{name: "export-restart", value: "Management"}]
  },
  {
    property: "portfolioConstructionMethod.code",
    label: "Portfolio Construction Method",
    type: "select",
    subtype: "single",
    optionSource: "PortfolioConstructionType",
    displayTypes: ["equity", "targetDate", "fixedIncome", "passive.MAC", "openEnded.realAssets"],
    subClasses: {
      labelClasses: "col-sm-5",
      inputWrapperClasses: "col-sm-6 pl-4"
    },
    tooltip: {
      icon: "question-circle",
      id: "portfolioConstructionMethodTooltip"
    }
  },
  {
    property: "investmentManagementStrategy.code",
    label: "Investment Strategy",
    type: "select",
    subtype: "single",
    // optionSource: "InvestmentManagementStrategyCode",
    displayTypes: ["openEnded.-privateEquity.-privateCredit", "hedgeFund"],
    subClasses: {
      labelClasses: "col-sm-5",
      inputWrapperClasses: "col-sm-6 pl-4"
    }
  },
  {
    property: "currencyHedging.code",
    label: "Currency Hedging",
    type: "select",
    subtype: "single",
    optionSource: "CurrencyHedgingCode",
    subClasses: {
      labelClasses: "col-sm-5",
      inputWrapperClasses: "col-sm-6 pl-4"
    }
  }
]

const valueAddedInput: TypedFormInput[] = [
  { 
    property: "valueAdded", 
    label: "Value Added", 
    type: "table", 
    displayTypes: ["hedgeFund", "openEnded.-passive"],
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 my-2"
    }
  }
]

const performanceCompositeDispersionInput: TypedFormInput[] = [
  {
    property: "performanceCompositeDispersion",
    label: "Performance Composite Dispersion",
    displayTypes: ["openEnded", "hedgeFund"],
    type: "table",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 my-2"
    }
  }
]

const passivePortfolioConstructionInput: TypedFormInput[] = [
  { 
    property: "", 
    label: "Passive Policy", 
    type: "",
    subClasses: {
      wrapperClasses: "row form-section-title headline underline small-font-size py-2 my-2"
    }
  },
  {
    property: "passivePortfolioConstruction.code",
    label: "Portfolio Construction",
    type: "select",
    subtype: "single",
    displayTypes: ["passive"],
    optionSource: "PassivePortfolioConstructionCode"
  },
  {
    property: "",
    label: "Attributed Tracking Error",
    type: "",
    displayTypes: ["passive"],
    subClasses: {
      wrapperClasses: "row col-form-label font-weight-bold"
    }
  },
  {
    property: "attributedTrackingError.securitiesLending",
    label: "Securities Lending",
    type: "float",
    subtype: "percent",
    displayTypes: ["passive"],
    subClasses: {
      wrapperClasses: "pl-2",
      inputClasses: "text-left",
    }
  },
  {
    property: "attributedTrackingError.constructionMethodology",
    label: "Construction Methodology",
    type: "float",
    subtype: "percent",
    displayTypes: ["passive"],
    subClasses: {
      wrapperClasses: "pl-2",
      inputClasses: "text-left",
    }
  },
  {
    property: "attributedTrackingError.other",
    label: "Other",
    type: "float",
    subtype: "percent",
    displayTypes: ["passive"],
    subClasses: {
      wrapperClasses: "pl-2",
      inputClasses: "text-left",
    }
  }
]

const ManagementInput = [
  ...StrategyDefinitionInput,
  ...ManagementSectionInput,
  ...valueAddedInput,
  ...passivePortfolioConstructionInput
]

const DefaultComplianceObject = {
  currentTeamStartDate: null,
  trackRecordComments: null,
  previousFirmTrackRecord: null,
  GIPSMethodology: null,
  GIPSAuditor: null,
  Auditor: null,
  LastAuditDate: null,
  performanceExamConcluded: null,
  verifiedPerformanceBegan: null,
  administrator: null,
  custodian: null
}
const ProductOverviewManagement = (props: { productId: number, auth: Auth }) => {
  const { productId, auth } = props
  const { loading, error, data } = useProductManagementQuery({
    variables: { id: productId },
    fetchPolicy: "cache-and-network",
  })
  let optionsList = null
  // let optionsList = GetProductManagementLookupsToOptions() || null
  if (loading) {
    return <PlaceHolder />
  }
  if (error) {
    return (
      <ErrorDisplay error={error}/>
    )
  }
  if (!data || !data.product) {
    return <p>Product Management Data not found</p>
  }
  return <Display {...data} optionsList={optionsList} auth={auth} />
}

const itemToInput = (data: Maybe<any>[]) => {
  if (!data) {
    return []
  }
  return data.map(el => ({
    code: el.code || el?.type?.code,
    weight: el?.weight
  })) as ValueAddedItemUnion[]
}

const getInitialData = (
  data: any,
  productType: { [name: string]: boolean }
) => {
  let { product } = data.product as Product
  const { passiveProduct } = data.product as PassiveProduct
  const { realAssets } = data.product as RealAssets
  const { equity } = data.product as Equity
  const { fixedIncome } = data.product as FixedIncome
  let formattedData = {
    ...product,
    __typename: data.product?.__typename
  } as any

  if (!formattedData.compliance) {
    _.set(formattedData, "compliance", DefaultComplianceObject)
  }

  //handle portfolioConstructionType
  if (productType["openEnded"]) {
    let portfolioConstructionMethod: any = {
      code: null,
      __typename: "PortfolioConstructionLookup"
    }
    let defaultPortfolioConstructionMethod = {
      code: null,
      value: null,
      __typename: "PortfolioConstructionLookup"
    }
    if (productType["equity"] || productType["MAC"]) {
      let result =
        equity?.portfolioConstructionMethod ||
        defaultPortfolioConstructionMethod
      portfolioConstructionMethod = _.isEmpty(result)
        ? defaultPortfolioConstructionMethod
        : result
    } else if (productType["realAssets"]) {
      let result =
        realAssets?.portfolioConstructionMethod ||
        defaultPortfolioConstructionMethod
      portfolioConstructionMethod = _.isEmpty(result)
        ? defaultPortfolioConstructionMethod
        : result
    } else if (productType["fixedIncome"]) {
      let result =
        fixedIncome?.portfolioConstructionMethod ||
        defaultPortfolioConstructionMethod
      portfolioConstructionMethod = _.isEmpty(result)
        ? defaultPortfolioConstructionMethod
        : result
    }
    formattedData["portfolioConstructionMethod"] = portfolioConstructionMethod
  }
  if (productType["passive"]) {
    let passivePortfolioConstruction =
      passiveProduct?.passivePortfolioConstruction
    let attributedTrackingError = passiveProduct?.attributedTrackingError
    formattedData["passivePortfolioConstruction"] =
      passivePortfolioConstruction || {}
    formattedData["attributedTrackingError"] = attributedTrackingError || {}
  }
  return formattedData
}

const getDeleteDates = (currentState: any, initialState: any) => {
  const currentArray = currentState.performanceCompositeDispersion || []
  const initialArray = initialState.performanceCompositeDispersion || []

  const deletedItems = _.differenceBy(
    initialArray,
    currentArray,
    "quarterEndDate"
  ) as PerformanceItemUnion[]
  const date = deletedItems.map(
    el =>
      el.quarterEndDate as DeleteProductManagementPerformanceCompositeDispersionInput
  )
  return date
}

const getCodes = (state: {
  managementType: any
  portfolioConstructionMethod: any
  investmentManagementStrategy: any
  currencyHedging: any
}) => {
  let result = {} as any
  keys(state).forEach(key => {
    result[key] = state[key]?.code || null
  })
  return result as typeof state
}

const getInputs = (
  currentState: any,
  productType: { [name: string]: boolean }
) => {
  let { id } = currentState
  const {
    managementType,
    portfolioConstructionMethod,
    investmentManagementStrategy,
    currencyHedging,
    compliance,
    attributedTrackingError
  } = currentState

  const preProductManagement = {
    managementType,
    portfolioConstructionMethod,
    investmentManagementStrategy,
    currencyHedging
  }
  let productManagement = getCodes(preProductManagement)
  let rePatch: { [name: string]: any } = {
    compliance,
    productManagement
  }
  if (productType["passive"]) {
    rePatch["passivePortfolioConstruction"] =
      currentState.passivePortfolioConstruction.code || null
    /* attributedTrackingError schema change needed. */
    rePatch["attributedTrackingError"] = attributedTrackingError
  }
  if (productType["openEnded"]) {
    let performanceCompositeDispersion =
      currentState.performanceCompositeDispersion
    if(Array.isArray(performanceCompositeDispersion)) {
      // remove invalid date input CAL-2016
      performanceCompositeDispersion = (performanceCompositeDispersion as any[]).filter(obj => {
        let date = obj?.quarterEndDate
        if(date && moment(date, DATE_API_FORMAT).isAfter("0999-12-31")) {
          return true
        }
        return false
      })
    }
    rePatch["performanceCompositeDispersion"] = performanceCompositeDispersion
  }
  if (productType["openEnded"] && !productType["passive"]) {
    const valueAdded = currentState.valueAdded as ValueAddedLocalItem[]
    const valueAddedInput = itemToInput(valueAdded)
    rePatch["valueAdded"] = valueAddedInput
  }
  const patch = excludePropertyArray(rePatch, ["__typename"])
  const result = {
    id,
    patch
  } as UpdateProductManagementInput
  return result
}

const Display: React.FC<ProductManagementQuery & {
  optionsList?: Maybe<{ [name: string]: JSX.Element[] }>
  auth: Auth
}> = data => {
  let productBasics = data.product as NonNullable<
    ProductManagementQuery["product"]
  >
  const assetClasses = data.assetClassMaps?.investmentManagementStrategyCodeAssetClassMap
  let assetClassesDataFiltered = assetClasses?.filter(
    el => el?.assetMixNum === data.product?.product?.assetClass?.parent?.id
  ) || []
  const mappedAssetClasses = assetClassesDataFiltered.map((assetClass, idx)=> {
    return {
      code: assetClass?.code || idx,
      value: _.find(data.investmentCodes?.enumValues, (investmentEnum) => { return assetClass?.code === investmentEnum.code})?.value || ""
    }
  })
  const assetClassesOptions = GetLookupDataToOptions({ data: mappedAssetClasses })
  const typename = productBasics.__typename
  const auth = data.auth
  let config = ProductManagementConfig[typename]

  const [productType] = useState<{ [type: string]: boolean }>(
    getProductType(data as any)
  )
  const history = useHistory()
  const [currentState, setState] = useState(
    () => getInitialData(data, productType) as any
  )
  const [initialState, setInitial] = useState(currentState)

  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const forceUpdateNow = useForceUpdate()
  const [forceUpdateFlag, setForceUpdateFlag] = useState<boolean>(false)
  const [updateProductManagement] = useUpdateProductManagementMutation()
  const [
    deletePerformanceCompositeDispersion
  ] = useDeletePerformanceCompositeDispersionMutation()

  const showPerformanceDispersion = !["OpenEndedPrivateEquity", "OpenEndedPrivateCredit", "ClosedEndedPrivateEquity", "ClosedEndedPrivateCredit", "ClosedEndedRealAssets"].includes(productBasics.__typename)
  useEffect(() => {
    if(!editMode) {
      setState(() => getInitialData(data, productType))
    }
  }, [data])

  useEffect(()=> {
    if(!editMode && forceUpdateFlag && !saving) {
      forceUpdateNow()
    }
  }, [forceUpdateFlag, editMode])
  let { performanceCompositeDispersion, valueAdded } = currentState
  const handleChangeValueAddedItemList = (newList: ValueAddedItemUnion[]) => {
    setState({ ...currentState, valueAdded: newList })
  }
  const handleChangePerformanceItemList = (newList: PerformanceItemUnion[]) => {
    setState({ ...currentState, performanceCompositeDispersion: newList })
  }
  const handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    setForceUpdateFlag(true)
    const { id } = currentState
    let input = getInputs(currentState, productType)
    let deletedDates = getDeleteDates(currentState, initialState)
    if (!_.isEmpty(deletedDates)) {
      const deletedVariables = {
        variables: { input: { id, date: deletedDates } }
      }
      deletePerformanceCompositeDispersion(deletedVariables)
        .then(result => {
          const data =
            result.data?.deleteProductManagementPerformanceCompositeDispersion
          console.log(data?.status, data?.message)
        })
        .catch(err => {
          console.error("Error delete Performance Composite Dispersion", err.message)
        })
    }
    updateProductManagement({ variables: { input } })
      .then(result => {
        setSaving(false)
        if (result.data?.updateProductManagement) {
          console.log("success")
          setEditMode(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log(err.message)
      })
  }
  const handleReset = () => {
    setState(initialState)
  }

  const handleInputChange = (value: any, property: string) => {
    const newState = getNewStateObject({
      state: currentState,
      newValue: value,
      property
    }) as typeof currentState
    setState(newState)
  }

  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Form onSubmit={handleEnterKeyDown}>
        <Container fluid className={"px-0"}>
          <Row>
            <Col>
              <div className="pane pane-toolbar sticky-top">
                <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
                  Export CSV
                  <img src='/assets/CSV.svg' className="ml-2"/>
                </Button>
                {auth.checkPermissions(["edit:manager"]) &&
                  <EditButtons 
                    editMode={editMode} 
                    setEditMode={setEditMode} 
                    saving={saving} 
                    onSubmit={handleSubmit}
                    cancelEdit={() => setState(initialState)}
                  />
                }
              </div>
              {/*
              // test component
              <div className="pane px-4 py-2">
                <span
                  className={"bg-success"}
                >{`ProductType: ${currentState.__typename}`}</span>
              </div> */}
              <div className="pane">
                <Row>
                  <Col sm="8" className="px-4 exportable-form data-export-restart" data-export-name="Strategy">
                    {ManagementInput.map(
                      (
                        {
                          property,
                          label,
                          type,
                          subtype,
                          placeholder,
                          subClasses,
                          optionSource,
                          readonly,
                          required,
                          tooltip,
                          displayTypes,
                          dataValues,
                        },
                        idx
                      ) => {
                        if (!subClasses) {
                          subClasses = {
                            labelClasses: "col-sm-5",
                            inputWrapperClasses: "col-sm-5"
                          }
                        } else if (
                          !subClasses["inputWrapperClasses"] ||
                          !subClasses["labelClasses"]
                        ) {
                          subClasses = {
                            ...subClasses,
                            labelClasses: subClasses.labelClasses || "col-sm-5",
                            inputWrapperClasses:
                              subClasses.inputWrapperClasses || "col-sm-5"
                          }
                        }

                        let propertyVal: any = _.get(currentState, property)
                        let onChangeCallback = (value: any) =>
                          handleInputChange(value, property)
                        let hidden: boolean = false
                        if (displayTypes) {
                          hidden = !displayTypes.some((displayType) => {
                            const types = displayType.split('.')
                            return !types.some((type) => {
                              if(type.charAt(0) === "-"){
                                return productType[type.split('-')[1]]
                              } else {
                                return !productType[type]
                              }
                            })
                          })
                        }
                        if (hidden) {
                          return <Fragment key={idx}></Fragment>
                        }
                        // several tables
                        if (type && type === "table") {
                          switch (property) {
                            // case "performanceCompositeDispersion":
                            //   return (
                            //     <Fragment key={`${idx}`}>
                            //       <div
                            //         className={"row form-section-title py-2"}
                            //         key={`${idx}-0`}
                            //       >
                            //         {label}
                            //       </div>
                            //       <PerformanceTableDisplay
                            //         data={performanceCompositeDispersion || []}
                            //         editMode={editMode}
                            //         handleChange={
                            //           handleChangePerformanceItemList
                            //         }
                            //         key={`${idx}-1`}
                            //       />
                            //     </Fragment>
                            //   )
                            case "valueAdded":
                              return (
                                !hidden && (
                                  <Fragment key={`${idx}`}>
                                    <div
                                      className={subClasses["wrapperClasses"] || ""}
                                      key={`${idx}-0`}
                                      id="privateEquityAssetsCapitalAltTooltipContainer">
                                      <div className="d-inline-flex align-items-center tooltip-icon" id="privateEquityAssetsCapitalAltTooltip">
                                        {label}
                                        <FontAwesomeIcon
                                          icon={"question-circle" as IconName}
                                          className="ml-2 mt-0"
                                          size="sm"
                                        />
                                      </div>

                                    </div>
                                    <ValueAddedTableDisplay
                                      data={itemToInput(valueAdded || [])}
                                      editMode={editMode}
                                      handleChange={
                                        handleChangeValueAddedItemList
                                      }
                                    />
                                  </Fragment>
                                )
                              )
                            default:
                              return (
                                !hidden && (
                                  <Fragment key={`${idx}`}>
                                    <div
                                      className={subClasses["wrapperClasses"] || ""}
                                      key={`${idx}-0`}
                                    >
                                      {label}
                                    </div>
                                    <ValueAddedTableDisplay
                                      data={itemToInput(valueAdded || [])}
                                      editMode={editMode}
                                      handleChange={
                                        handleChangeValueAddedItemList
                                      }
                                    />
                                  </Fragment>
                                )
                              )
                          }
                        } else {
                          if (!type) {
                            if (
                              label === "Attributed Tracking Error" &&
                              !_.get(config, "attributedTrackingError")
                            ) {
                              return <Fragment key={`${idx}`}></Fragment>
                            } else if (
                              label === "Passive Policy" &&
                              !_.get(config, "passivePortfolioConstruction")
                            ) {
                              return <Fragment key={`${idx}`}></Fragment>
                            }
                          }
                        }
                        
                        switch(property){
                          case "investmentManagementStrategy.code":
                            if (isTargetDate(data.product as Product["product"])) hidden = true;
                        }

                        return (
                          !hidden && (
                            <div key={idx}>
                              <FormInput
                                key={idx}
                                property={property}
                                displayName={label}
                                subClasses={subClasses}
                                type={type}
                                subtype={subtype}
                                placeholder={placeholder}
                                idx={idx}
                                editMode={editMode}
                                propertyVal={propertyVal}
                                updateValue={onChangeCallback}
                                optionSource={optionSource}
                                options={assetClassesOptions}
                                required={required}
                                tooltip={tooltip}
                                dataValues={dataValues}
                              />
                            </div>
                          )
                        )
                      }
                    )}
                  </Col>
                </Row>
                {showPerformanceDispersion &&
                  <Row key={`PerformanceCompositeDispersion`}>
                    <Col sm="12">
                      <div
                        className={"row form-section-title headline underline small-font-size py-2 mb-2"}
                        key={`PerformanceCompositeDispersion`}
                      >
                        {`Performance Composite Dispersion`}
                      </div>
                      <PerformanceTableDisplay
                        data={performanceCompositeDispersion || []}
                        editMode={editMode}
                        handleChange={handleChangePerformanceItemList}
                        key={`PerformanceCompositeDispersion-1`}
                      />
                    </Col>
                  </Row>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  )
}
export default ProductOverviewManagement
