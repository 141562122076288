import { disableFragmentWarnings, gql } from '@apollo/client'
import { PERSON_OF_USER_FRAGMENT } from './PersonFragments'
import { SEARCH_RESULT_FRAGMENT, SEARCH_FILTER_FETCH_FRAGMENT, SEARCH_AUTOCOMPLETE_FRAGMENT } from './SearchFragments'

disableFragmentWarnings()

export const SEARCH_QUERY = gql`
  query Search(
    $q: String!
    $types: [SearchTypes]
    $filters: SearchFilters
    $sortBy: SearchSortBy
    $limit: Int! = 10
    $offset: Int! = 0
  ) {
    search(
      q: $q
      types: $types
      filters: $filters
      sortBy: $sortBy
      limit: $limit
      offset: $offset
    ) {
      ...SearchResultFragment
    }
    filtersAvailable: search(
      q: $q
      types: $types
      filters: $filters
      limit: $limit
      offset: $offset
    ) {
      ...SearchFilterFetchFragment
    }
  }
  ${SEARCH_RESULT_FRAGMENT}
  ${SEARCH_FILTER_FETCH_FRAGMENT}
`

export const AUTOCOMPLETE_QUERY = gql`
  query Autocomplete($q: String!, $types: [SearchTypes], $filters: AutocompleteFilters) {
    autocomplete(q: $q, types: $types, filters: $filters) {
      ...SearchAutocompleteFragment
    }
  }
  ${SEARCH_AUTOCOMPLETE_FRAGMENT}
`

export const ASSOCIATION_SEARCH = gql`
  query AssociationSearch(
    $q: String!
    $parent_firm: String
    $types: [SearchTypes]
    $limit: Int! = 10
    $offset: Int! = 0
  ) {
    search: search(
      q: $q
      filters: {
        manager: { id: $parent_firm }
        product: { managerId: $parent_firm, inactive: false }
        targetDate: { managerId: $parent_firm }
        client: { id: $parent_firm }
        plan: { clientId: $parent_firm }
      }
      types: $types
      limit: $limit
      offset: $offset
    ) {
      hits
      data {
        __typename
        ... on Manager {
          id
          name
        }
        ... on Bank {
          id
          name
        }
        ... on RecordKeeper {
          id
          name
        }
        ... on Client {
          id
          name
        }
        ... on ProductFields {
          id
          name
          manager {
            id
            name
          }
        }
        ... on GlidePathVersion {
          id
          tdName: name
          philosophy {
            value
          }
          glidePath {
            id
            manager {
              id
              name
            }
          }
        }
        ... on VehicleFields {
          fundid
          vehicleName: name
          status {
            value
          }
          type {
            value
          }
          product {
            product {
              manager {
                id
                name
              }
            }
          }
        }
        ... on Plan {
          id
          name
          client {
            id
            name
          }
        }
        ... on Index {
          indexId: id
          indexName: name
          org {
            id
            name
          }
          identifiers {
            bloomberg
          }
        }
      }
    }
  }
`

export const EMAIL_SEARCH = gql`
  query PersonByEmailSearch(
    $q: String!
    $types: [SearchTypes] = [People]
    $limit: Int! = 10
    $offset: Int! = 0
  ) {
    search: search(
      q: $q
      filters: {
        person: {emailQuery: true}
      }
      types: $types
      limit: $limit
      offset: $offset
    ) {
      hits
      data {
        __typename
        ... on Person {
          ...PersonOfUserFragment
        }
      }
    }
  }
  ${PERSON_OF_USER_FRAGMENT}
`
