import classNames from 'classnames'
import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import { compact, first, isEmpty, merge, sortBy } from 'lodash'
import moment from 'moment'
import { default as React, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Row, Table } from 'reactstrap'

import { ComponentOverrideSettings, ComponentType, LayoutSectionType, ReportAssetAllocationDataQueryVariables, ReportAssetAllocationFetchFragment, ReportAssetAllocationTableFragment, ReportsFragment, useListSimpleQuery, useNewAssetAllocationDataQuery, useReportAssetAllocationDataQuery } from '../../../__generated__/graphql'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import { ProfileAssetPieDefaults } from '../../../helpers/highCharts'
import { excludePropertyArray } from '../../../helpers/object'
import { convertComponentForMutation } from '../../../helpers/report'
import { useStoredTab } from '../../../helpers/usehook'
import { ApprovalsReportDisplay } from '../Shared/ReportApprovals'
import { AggregatedComponentProps, AggregatedExportSettingsProps, BaseComponent, ErrorComponent, FootnotableComponent, FootnoteLink, IndividualComponentProps, LoadingComponent, ReportDisplayType, ReportTab, TemplateComponent, ToolbarProps, getPortfolioRowLinks } from '../Shared/ReportComponent'
import { AdditionalReportingEdit, AggregatedReportEditProps, ReportEditField, ReportEditHeading, ReportEditInfoFields, ReportEditInfoTotalField, ReportEditInstances, SimpleReportEdit } from '../Shared/ReportEdit'

const AssetAllocation: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportAssetAllocationFetchFragment>
      expectedTypename={"AssetAllocation"}
      reactComponent={AssetAllocationDisplay}
      {...props}
    />
  )
}

// Used to store state that must be shared with export
type componentState = {
  tab?: ReportTab
  selectedDrillDown?: string | null
}

const AssetAllocationDisplay: React.FC<IndividualComponentProps<ReportAssetAllocationFetchFragment>> = ({component, auth, settings, selected, handleSelect, editMode, sectionNumber, componentNumber, report, setEditedDraftLayout, editedDraftLayout, setSelectedComponentId, view, setReportState, reportState: inState, hideSingleExport, clientId, overwriteDate, portfolioId }) => {
  const name = component.name || ""
  const [initSettings, setInitSettings] = useState(settings)

  const reportState = inState as componentState
  const componentId = component.id
  const usedDate = overwriteDate || settings.date

  const currentDate = moment(usedDate, DATE_API_FORMAT)
  const primaryTab = {label: currentDate.format("[Q]Q YYYY"), value: usedDate, primary: true} as ReportTab
  const tabs = sortBy([primaryTab, ...(settings.monthlyOptions?.dates?.map((date) => ({label: moment(date).format("MMM YYYY"), value: date})) || [])] as ReportTab[], (tab) => (tab.primary ? 0 : 1) - moment(tab.value).valueOf() )
  const mostRecentTab = tabs[0]
  const selectedDrillDown = reportState?.selectedDrillDown || null
  const setSelectedDrillDown = (value:null | string) => {
    setReportState({selectedDrillDown: value})
  }

  const {fetchTab, storeTab} = useStoredTab(componentId, tabs)

  useEffect(() => {
    const storedTab = fetchTab()
    if (storedTab && storedTab.value !== usedDate) {
      setReportState({ selectedDrillDown: null, tab: storedTab })
    }
  }, [])

  const setTab = (value:any) => {
    storeTab(value)
    setReportState({ tab: value, selectedDrillDown: null })
  }

  useEffect(()=>{
    if(editMode === false && initSettings !== settings) setInitSettings(settings)
  }, [editMode, initSettings, settings])

  const tab = reportState?.tab || mostRecentTab

  useEffect(() => {
    if (!tabs.some((t) => t.value === searchDate) || !settings.monthlyOptions?.show) {
      setTab(mostRecentTab)
    }
  }, [settings])

  const searchDate = tab?.value || usedDate

  const usePreview = (initSettings !== settings)
  const useNew = component.id < 1
  let usedSettings = {}

  if(usePreview || useNew) {
    let convertedSettings = excludePropertyArray(convertComponentForMutation(settings, ComponentType.AssetAllocation), ["__typename"])
    usedSettings = {
      assetAllocation: {...convertedSettings, date: searchDate}
    }
  }

  const queryVariables:ReportAssetAllocationDataQueryVariables = {
    ids: [component.id],
    liveView: (view === ReportDisplayType.Live) && !usePreview,
    draftView: (view === ReportDisplayType.Draft) && !usePreview,
    preview: usePreview,
    settings: usedSettings,
  }

  if (!tab.primary) {
    queryVariables.date = searchDate
  }

  const asOfDate = moment(tab.value).format(DATE_DISPLAY_FORMAT)

  const { loading: baseLoading, data: baseData, error: baseError } = useReportAssetAllocationDataQuery({
    variables: queryVariables,
    skip: useNew,
  })

  const { loading: newLoading, data: newData, error: newError } = useNewAssetAllocationDataQuery({
    variables: {settings: usedSettings},
    skip: !useNew,
  })

  let loading, error
  if(useNew) {
    loading = newLoading
    error = newError
  } else {
    loading = baseLoading
    error = baseError
  }

  if(loading) return <LoadingComponent name={name} rightText={`As of ${asOfDate}`} tabs={settings?.monthlyOptions?.show ? tabs : undefined} currentTab={tab} onTabChange={(tab) => setTab(tab)} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
  if(error) return <ErrorComponent name={name} error={error?.message} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} setEditedDraftLayout={setEditedDraftLayout} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber}/>

  if (baseData || newData){
    let tooltipProps:ToolbarProps | undefined

    if(componentNumber === -1){
      tooltipProps = {'exportOptions': {
        name: name,
        slides: [{
          title: name,
          sections: [{
            components: [componentId],
            type: LayoutSectionType.SingleColumnSection
          }]
        }],
        settings: {
          live: view === ReportDisplayType.Live,
          footerName: report?.exportSettings?.footerName || report?.client?.name,
          componentOverrideSettings: {
            assetAllocation: [
              {
                componentId: componentId,
                selectedDate: tab.value,
                drilldown: selectedDrillDown || ""
              }
            ]
          }
        }
      },
      }
    }

    let displayedData = baseData?.components?.[0]?.liveData
    if(useNew && newData?.componentPreviewData) {
      displayedData = newData?.componentPreviewData
    } else if(view === ReportDisplayType.Draft && baseData?.components?.[0]?.draftData) {
      displayedData = baseData?.components?.[0]?.draftData
    } else if (usePreview && baseData?.components?.[0]?.previewData) {
      displayedData = baseData?.components?.[0]?.previewData
    }

    if (displayedData?.__typename !== "AssetAllocationData" || isEmpty(displayedData?.chart)) {
      return <ErrorComponent name={name} error={"Invalid Table or Chart Data."} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
    }

    // use drilldown to fetch table
    let usedTable = first(displayedData?.tables)
    if (selectedDrillDown) {
      usedTable = compact(displayedData?.tables)?.find((value: ReportAssetAllocationTableFragment) => {
        return value.drilldownId === selectedDrillDown
      })
    }

    if (!usedTable) {
      return <ErrorComponent name={name} error={"No table data returned"} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
    }

    const chartJSON = JSON.parse(displayedData.chart || '{}')

    return (
      <TemplateComponent
        name={name}
        componentTypeName='Asset Allocation'
        rightText={`As of ${asOfDate}`}
        selected={selected}
        onClick={handleSelect}
        tooltipProps={tooltipProps}
        tabs={settings?.monthlyOptions?.show ? tabs : undefined}
        currentTab={tab}
        onTabChange={(tab) => setTab(tab)}
        editMode={editMode}
        sectionNumber={sectionNumber}
        componentId={componentId}
        report={report}
        setEditedDraftLayout={setEditedDraftLayout}
        editedDraftLayout={editedDraftLayout}
        componentNumber={componentNumber}
        setSelectedComponentId={setSelectedComponentId}
        view={view}
        auth={auth}
        hideExport={hideSingleExport}
        portfolioId={portfolioId}
    >
        <Row className="no-gutters">
          { displayedData && chartJSON && (
            <Col sm="4">
              <AssetAllocationGraph
                chartData={chartJSON}
                setSelectedDrillDown={setSelectedDrillDown}
                selectedDrillDown={selectedDrillDown}
              />
            </Col>
          )}
          { usedTable && (
            <Col sm="8" className="overflow-auto">
              <AssetAllocationTableDisplay
                settings={settings}
                tableData={usedTable}
                selectedDrillDown={!!selectedDrillDown}
                date={tab.value}
                report={report}
                clientId={clientId}
                view={view}
              />
            </Col>
          )}
        </Row>
      </TemplateComponent>
    )

  }
  return <ErrorComponent name={name} error={"No data returned"} rightText={`As of ${asOfDate}`} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
}

interface AssetAllocationGraphProps {
  selectedDrillDown: string | null
  setSelectedDrillDown: (e:any) => void
  chartData?: string
}

const AssetAllocationGraph: React.FC<AssetAllocationGraphProps> = ({
  chartData, selectedDrillDown, setSelectedDrillDown
}) => {
  const ref = useRef<HighchartsReactRefObject | null>(null)
  const [componentWidth, setComponentWidth] = useState(0)

  const chartOptions = merge(
    ProfileAssetPieDefaults,
    chartData,
    {
      chart: {
        height: null,
        width: null,
        marginRight: 20,
        events:  {
          drilldown: (e:any) => (e.seriesOptions && setSelectedDrillDown) ? setSelectedDrillDown(e.seriesOptions.id) : undefined,
          drillup: (e:any) => setSelectedDrillDown ? setSelectedDrillDown(e.seriesOptions.id) : undefined,
        }
      }
    }
  )

  useEffect(() => {
    const htmlContainer = ref.current?.container.current;
    const chart = ref.current?.chart;
    if (!htmlContainer || !chart) return;

    if (htmlContainer) {
      const resizeObserver = new ResizeObserver(() => {
        if (!!chart && chart.options) {
          chart.reflow();
        }
      });

      resizeObserver.observe(htmlContainer);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const graph = useMemo(() => {
    return (
      <HighchartsReact
        ref={ref}
        options={chartOptions}
        highcharts={Highcharts}
        containerProps={{ style: { height: "400px" }}}
      />
    )
  }, [chartOptions])

  return graph
}

interface AssetAllocationTableDisplayProps {
  settings: ReportAssetAllocationFetchFragment
  selectedDrillDown: boolean
  date: string
  report?: ReportsFragment
  view: ReportDisplayType
  clientId?: number
  tableData: ReportAssetAllocationTableFragment
}

const AssetAllocationTableDisplay: React.FC<AssetAllocationTableDisplayProps> =({tableData, settings, selectedDrillDown, date, report, view, clientId}) => {
  const draftView = view === ReportDisplayType.Draft
  const columns = tableData.columns || [];

  const tableRows = compact(tableData.rows) || [];

  return (
    <Table hover className={"report-table"}>
      <thead>
        <tr className="row-border-olive-100">
          <th className="pl-1 pr-0"></th>
          <th className="pl-0 text-left">Asset Class</th>
          {
            columns.map((column, idx) => (
              <th className="pl-0 text-right" key={`asset-column-heaader-${idx}`}>{ column }</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row, idx: number) => {

          const links: FootnoteLink[] = getPortfolioRowLinks({componentId: row.rowId, reportId: report?.id, clientId, view, productId: row.productId || undefined})
          const errors = compact([row?.error])
          const rowId = `asset-allocation-row-${row.rowId}-${row.total ? 'total' : ''}`

          return(
            <React.Fragment key={rowId}>
              <tr
                className={classNames({
                  "report-tr-title": true,
                  "report-tr-total": row.total,
                  "row-border-olive-100": row.total
                })}
              >
                <td className="pl-1 pr-2">
                  <div
                    className="example-color-box"
                    style={{ backgroundColor: row.color || 'none' }}>
                  </div>
                </td>
                <td className="pl-0 text-left d-flex">
                  <FootnotableComponent
                    bareFootnote={row.footnote}
                    errors={errors}
                    showErrors={draftView}
                    showWarnings={draftView}
                    selectedDate={date}
                    links={links}
                  >
                    <div className={"report-table-title"}>
                      {row.name}
                    </div>
                  </FootnotableComponent>
                </td>
                { row?.columnData?.map((column, rowIdx) => (
                  <td
                    className={classNames('text-right', {'background-error': rowIdx === 0 && errors.length > 0})}
                    key={`asset-row-${idx}-${rowIdx}`}
                  >
                    { column?.formattedValue || ' - '}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          )
        })}
      </tbody>
    </Table>
  )
}

// stays the same
const AssetAllocationTableFields:ReportEditField[] = [
  {
    property: "showTarget",
    label: "Benchmark",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
]

const AssetAllocationAdditionalFields:ReportEditField[] = [
  {
    property: "inPercent",
    label: "Show in percent",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
]

const AssetAllocationReportEditFootnoteFields:ReportEditField[] = [
  {
  property: "showFootnote",
  label: "Portfolio",
  type: "checkbox",
  subtype: "show",
  required: true,
  }
]

export const AssetAllocationEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, reportId}) => {
  const settings = component.draftSettings as ReportAssetAllocationFetchFragment
  const { data: listData } = useListSimpleQuery({
    variables: {id: settings.list?.id || 0},
    skip: !settings || !settings.list?.id,
    errorPolicy: "all",
  })
  if(!settings){
    return(
      <></>
    )
  }

  const ownedComponent = component.owner?.id === user?.person?.id
  let infoColumns = [...ReportEditInfoFields(handleInputChange, {resetDates: true}),  ...ReportEditInfoTotalField(listData?.list || undefined)]
  return (
    <>
      <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={"Asset Allocation"}
        componentType={ComponentType.AssetAllocation}
        reportId={reportId}
        handleInputChange={handleInputChange}
      />
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <ReportEditInstances component={component}/>
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      />
      <AdditionalReportingEdit
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"table"}
        retractable={true}
        fields={AssetAllocationTableFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"footnotes"}
        retractable={true}
        fields={AssetAllocationReportEditFootnoteFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
      <SimpleReportEdit
        name={"other"}
        retractable={true}
        fields={AssetAllocationAdditionalFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
    </>
  )
}

export const AssetAllocationExportSettings = (props: AggregatedExportSettingsProps): ComponentOverrideSettings =>  {
  const { component } = props
  const reportState = props.reportState as componentState
  const tab = reportState?.tab
  const selectedDrillDown = reportState?.selectedDrillDown

  return {assetAllocation: [
    {
      componentId: component?.id,
      selectedDate: tab?.value,
      drilldown: selectedDrillDown || ""
    }
  ]}
}

export default AssetAllocation