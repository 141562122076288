import { gql } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { default as classNames } from 'classnames'
import { compact } from 'lodash'
import React from 'react'
import { Button, ButtonDropdown, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap'

import { BulkUpdateComponentApprovalsInput, ClientPortfolioDetailComponentFragment, ComponentApprovalCode, ReportsListClientPortfolioFragment, UpdateComponentInput, useBulkUpdateComponentApprovalsMutation, useUpdateComponentApprovalMutation } from '../../../__generated__/graphql'


interface ReportEditApprovalsProps {
  allComponents: ClientPortfolioDetailComponentFragment[]
  portfolio: ReportsListClientPortfolioFragment
}

export const ReportApprovals: React.FC<ReportEditApprovalsProps> = ({ allComponents, portfolio }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [bulkUpdateComponentApprovals] = useBulkUpdateComponentApprovalsMutation()
  const [saving, setSaving] = React.useState(false)

  const handleBulkUpdate = (approvalCode: ComponentApprovalCode) => {
    if(saving){
      return
    }
    setSaving(true)
    const updateData = {
      components: compact(allComponents.map((component, idx) => {
        return component?.id || undefined
      })),
      approval: approvalCode,
    } as BulkUpdateComponentApprovalsInput

    bulkUpdateComponentApprovals({ variables: { input: updateData, liveView: false, draftView: true} })
      .then(result => {
        setSaving(false)
      })
      .catch(err => {
        setSaving(false)
        console.log("Error Component Save", err.message)
      })
  }

  return(
    <>
      <div className='background-gray-10 pl-2 py-1'>
        <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} color="light btn-thin" className="mr-1 text-callan-blue">
          <DropdownToggle caret>
            Set Status
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleBulkUpdate(ComponentApprovalCode._1)}>
              <div className="simple-icon-circle blank d-inline-block mr-2">
                <FontAwesomeIcon icon={"times"} size={"xs"} className="w-inherit"/>
              </div>
              {ComponentApprovalCodeMapping[ComponentApprovalCode._1]}
            </DropdownItem>
            <DropdownItem onClick={() => handleBulkUpdate(ComponentApprovalCode._2)}>
              <div className="simple-icon-circle dotted d-inline-block mr-2">
                <FontAwesomeIcon icon={"check"} size={"xs"} className="w-inherit"/>
              </div>
              {ComponentApprovalCodeMapping[ComponentApprovalCode._2]}
            </DropdownItem>
            <DropdownItem onClick={() => handleBulkUpdate(ComponentApprovalCode._3)}>
              <div className="simple-icon-circle solid d-inline-block mr-2">
                <FontAwesomeIcon
                  icon={"check"}
                  size={"xs"}
                  className="text-inherit w-inherit"
                />
              </div>
              {ComponentApprovalCodeMapping[ComponentApprovalCode._3]}
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
      <div className='background-gray-10'>
        {portfolio.name}
      </div>
        {allComponents?.map((component, idx) => {
          if(!component){
            return (
              <React.Fragment key={idx} />
            )
          }
          return (
            <ApprovalsReportEdit
              key={component.id}
              value={component.approval?.code || undefined}
              name={component.name || undefined}
              componentId={component.id}
            />
          )
        })}
    </>
  )
}

interface ApprovalsReportEditProps {
  handleInputChange?: (value: any) => void
  value?: ComponentApprovalCode
  name?: string
  componentId: number
}

const ComponentApprovalCodeMapping:{[key: string]: string} = {
  '_1': 'Needs Review',
  '_2': 'Reviewed',
  '_3': 'Approved',
}

export const ApprovalsReportEdit: React.FC<ApprovalsReportEditProps> = ({value, handleInputChange, name, children, componentId}) => {
  const [updateComponentApprovalMutation] = useUpdateComponentApprovalMutation()
  const [saving, setSaving] = React.useState(false)

  const handleChange = handleInputChange ? handleInputChange : (value: any) => {
    if(saving){
      return
    }
    // setSaving(true)
    const updateData = {
      id: componentId,
      patch: {
        approval: value,
      }
    } as UpdateComponentInput

    updateComponentApprovalMutation({
      variables: { input: updateData },
      optimisticResponse: {
        updateComponent: {
          component: {
            id: componentId,
            approval: {
              code: value,
              value: ComponentApprovalCodeMapping[value],
              __typename: "ComponentApprovalLookup",
            },
            __typename: "Component",
          },
          __typename: "ComponentPayload",
        },
        __typename: 'Mutation',
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `Component:${componentId}`,
          fragment: gql`
            fragment ComponentApproval on Component {
              id
              approval {
                code
                value
              }
            }
          `,
          data: {
            id: componentId,
            approval: {
              code: value,
              value: ComponentApprovalCodeMapping[value],
            },
            __typename: "Component",
          },
        });
      }, })
      .then(result => {
        // setSaving(false)
      })
      .catch(err => {
        // setSaving(false)
        console.log("Error Component Save", err.message)
      })
  }

  return (
    <div className={classNames("client-report-edit-template")}>
      <Row className="justify-content-between">
        <Col sm={6}>
          <div
            className={classNames("client-report-approvals-title p-2 my-1", {
              "needs-review": value === ComponentApprovalCode._1,
              reviewed: value === ComponentApprovalCode._2,
              approved: value === ComponentApprovalCode._3,
            })}
          >
            {name || "Approval"}
          </div>
        </Col>
        <Col sm={6} className="align-self-center">
          <ButtonGroup className="">
            <Button
              color={value === ComponentApprovalCode._1 ? "primary" : "light"}
              onClick={() => handleChange(ComponentApprovalCode._1)}
              className="p-2 btn-approval"
              disabled={saving}
            >
              <div className="simple-icon-circle blank">
                <FontAwesomeIcon icon={"times"} size={"xs"} />
              </div>
            </Button>
            <Button
              color={value === ComponentApprovalCode._2 ? "primary" : "light"}
              onClick={() => handleChange(ComponentApprovalCode._2)}
              className="p-2 btn-approval"
              disabled={saving}
            >
              <div className="simple-icon-circle dotted">
                <FontAwesomeIcon icon={"check"} size={"xs"} />
              </div>
            </Button>
            <Button
              color={value === ComponentApprovalCode._3 ? "approval1" : "light"}
              onClick={() => handleChange(ComponentApprovalCode._3)}
              className="p-2 btn-approval"
              disabled={saving}
            >
              <div className="simple-icon-circle solid">
                <FontAwesomeIcon
                  icon={"check"}
                  size={"xs"}
                  className="text-inherit"
                />
              </div>
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  )
}

interface ApprovalsReportDisplayProps {
  value?: ComponentApprovalCode
  name?: string
}

export const ApprovalsReportDisplay: React.FC<ApprovalsReportDisplayProps> = ({value, name}) => {
  return (
    <div className={classNames("client-report-edit-template")}>
      <Row className="justify-content-between">
        <Col sm={4}>
          <div
            className={classNames("client-report-approvals-title p-2 my-1", {
              "needs-review": value === ComponentApprovalCode._1,
              reviewed: value === ComponentApprovalCode._2,
              approved: value === ComponentApprovalCode._3,
            })}
          >
            {name || "Approval"}
          </div>
        </Col>
        <Col sm={8} className="align-self-center">
            {value === ComponentApprovalCode._1 &&
              <div>
                <div className="simple-icon-circle blank d-inline-block mr-2">
                  <FontAwesomeIcon icon={"times"} size={"xs"} className="w-inherit"/>
                </div>
                Needs Review
              </div>
            }
            {value === ComponentApprovalCode._2 &&
              <div>
                <div className="simple-icon-circle dotted d-inline-block mr-2">
                  <FontAwesomeIcon icon={"check"} size={"xs"} className="w-inherit"/>
                </div>
                Reviewed
              </div>
            }
            {value === ComponentApprovalCode._3 &&
              <div>
                <div className="simple-icon-circle solid d-inline-block mr-2">
                  <FontAwesomeIcon
                    icon={"check"}
                    size={"xs"}
                    className="text-inherit w-inherit"
                  />
                </div>
                Approved
              </div>
            }
        </Col>
      </Row>
    </div>
  )
}