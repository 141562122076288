import moment from "moment"
import React from "react"
import { FormGroup, Input } from "reactstrap"
import { DATE_API_FORMAT } from "../../../../../helpers/constant"
import { PercentInput } from "../../FeeFormComponents"
import { NumericInputData, OtherFeesGenericProps } from "../FeeRows"
import { HurdleBenchmarkOptions } from "../getFeeData"

interface HedgeFundHurdleRowProps extends OtherFeesGenericProps {
  date: string
  feeInput: NumericInputData<string>
  benchmarkInput: {
    options: HurdleBenchmarkOptions
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
}

export const HedgeFundHurdleRow: React.FC<HedgeFundHurdleRowProps> = ({
  label,
  editing,
  date,
  benchmarkInput,
  feeInput,
}) => {
  const benchmark = benchmarkInput.options.find(
    (item) => item.code === benchmarkInput.value
  )
  const benchmarkDisplay = benchmark ? benchmark.value : ""
  return (
    <tr>
      <td>{label}</td>
      <td>
        {editing ? (
          <Input
            type="select"
            bsSize="sm"
            onChange={benchmarkInput.onChange}
            value={benchmarkInput.value}
          >
            <option value="">Select Benchmark</option>
            {benchmarkInput.options.map((item) => (
              <option key={item.code} value={item.code}>
                {item.value}
              </option>
            ))}
          </Input>
        ) : (
          benchmarkDisplay
        )}
      </td>
      <td>
        <FormGroup>
          <div>
            <PercentInput
              disabled={!editing}
              value={+feeInput.value}
              onChange={feeInput.onChange}
            />
          </div>
        </FormGroup>
      </td>
      <td>{moment(date, DATE_API_FORMAT).format("MM/DD/YYYY")}</td>
    </tr>
  )
}
