import React, { Component } from "react"
import { filter, compact, sortBy } from "lodash"
import { UncontrolledDropdown, DropdownMenu, Row, Col, Form, FormGroup, Input, ListGroup, ListGroupItem } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PlanClientPortfolioFragment, usePlanPortfoliosQuery } from "../../../__generated__/graphql"
import classNames from "classnames"

interface ClientPortfolioPickerProps {
  clientportfolioId: number
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (plan_id: number) => void
}

const ClientPortfolioPicker: React.FC<ClientPortfolioPickerProps> = ({ clientportfolioId, title, children, dropdownClass, onClick }: ClientPortfolioPickerProps) => {
  const { data } = usePlanPortfoliosQuery({
    variables: { id: clientportfolioId, includeTargetsAndGroup: false },
  })

  let clientPortfolios = compact(filter(data?.plan?.clientPortfolios, (cp) => cp?.isActive) || []) as PlanClientPortfolioFragment[]

  return (
    <UncontrolledDropdown className={classNames("headline-dropdown client-portfolio-picker-dropdown", dropdownClass)}>
      {children}
      <DropdownMenu>
        <h5>{title}</h5>
        <ClientPortfolioSearch key={clientPortfolios.length} data={clientPortfolios} onClick={onClick} />
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

interface ClientPortfolioSearchProps {
  data: PlanClientPortfolioFragment[]
  onClick: (plan_id: number) => void
}
interface ClientPortfolioSearchState {
  portfolios: PlanClientPortfolioFragment[]
  search: string
}

export class ClientPortfolioSearch extends Component<ClientPortfolioSearchProps> {
  state: ClientPortfolioSearchState = {
    portfolios: this.props.data,
    search: "",
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredPortfolios = filter(this.props.data, (p) => {
      const terms = value.split(" ")
      const regexes = terms.map((t) => new RegExp("\\b" + t, "ig"))

      let results = regexes.map((r) => !!p?.name?.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      portfolios: filteredPortfolios,
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="6">
            <Form>
              <FormGroup row className="relative m-0">
                <Input type="text" placeholder="Find Client Portfolio by name" onChange={this.onChange} value={this.state.search} />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon icon={["fas", "search"]} size="2x" className="fontawesome-icon dark-icon-color text-gray-50" />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          {sortBy(this.state.portfolios, [(obj) => obj?.name?.trim().toLowerCase()]).map((portfolio) => {
            if (!portfolio) {
              return <></>
            }
            return (
              <ListGroupItem tag="a" key={`pp-portfolio-${portfolio?.id}`} onClick={() => this.props.onClick(portfolio.id)}>
                {portfolio?.name}
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </>
    )
  }
}

export default ClientPortfolioPicker