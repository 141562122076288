import {
  VehicleOtherFeesQuery,
  MutualFundFields,
  CollectiveInvestmentFundFields,
  CollectiveInvestmentFundCompositeFields,
  VariableAnnuityFields,
  PooledVehicleFields,
  MutualFund,
  CollectiveInvestmentFund,
  CollectiveInvestmentFundComposite,
  ExchangeTradedFund,
  VariableAnnuity,
  PooledVehicle,
  PrivateCreditVehicle,
  PrivateEquityVehicle,
  HedgeFundVehicle,
  CatchupFee,
  AlternativeFee,
  PrivateCreditVehicleFields,
  PrivateEquityVehicleFields,
  Maybe,
  WaterfallCode,
  FeeReturnCode,
  FeeCalculationCode,
  RealAssetsVehicle,
  RealAssetsVehicleFields,
  ClosedEndedVehicle,
  ClosedEndedVehicleFields,
} from "../../../../__generated__/graphql"
import { previousQuarterEnd } from "../helper"

type TypeFromQuery<T> = NonNullable<
  Extract<NonNullable<VehicleOtherFeesQuery["vehicle"]>, { __typename: T }>
>

type MutualFundFees = NonNullable<
  NonNullable<
    NonNullable<TypeFromQuery<"OpenEndedMutualFund">["mutualFund"]>["datedFees"]
  >[0]
>

type CollectiveInvestmentFee = NonNullable<
  NonNullable<
    NonNullable<
      TypeFromQuery<"OpenEndedCollectiveInvestmentFund">["collectiveInvestmentFund"]
    >["datedFees"]
  >[0]
>

type CollectiveInvestmentCompositeFees = NonNullable<
  NonNullable<
    NonNullable<
      TypeFromQuery<"OpenEndedCollectiveInvestmentFundComposite">["collectiveInvestmentFundComposite"]
    >["datedFees"]
  >[0]
>

type ExchangeTradedFundFees = NonNullable<
  NonNullable<
    NonNullable<
      TypeFromQuery<"OpenEndedExchangeTradedFund">["exchangeTradedFund"]
    >["datedFees"]
  >[0]
>

type VariableAnnuityFee = NonNullable<
  NonNullable<
    NonNullable<
      TypeFromQuery<"OpenEndedVariableAnnuity">["variableAnnuity"]
    >["datedFees"]
  >[0]
>

type DatedFeesTypes =
  | MutualFundFees
  | CollectiveInvestmentFee
  | CollectiveInvestmentCompositeFees
  | ExchangeTradedFundFees
  | VariableAnnuityFee

type KeyOfFee<T> = keyof Omit<T, "date" | "__typename">

type LatestFees<T> = {
  [key in KeyOfFee<T>]?: {
    date: string
    value: string
  }
}

interface RedemptionFees {
  redemptionFees: boolean
  redemptionFeePct: string
  redemptionDays: string
}

export interface EntryAndRedemptionFees extends RedemptionFees {
  entryFees: boolean
  entryFeePct: string
}

type ClosedEndedFeeData = {
  clawBackProvisions: boolean
  catchupFee: {
    date: string
    value: string
  }
}

type MutualFundFeeData = {
  datedFees: LatestFees<MutualFundFees>
} & EntryAndRedemptionFees

type CITFeeData = {
  datedFees: LatestFees<CollectiveInvestmentFee>
} & EntryAndRedemptionFees

type CITCompositeFeeData = {
  datedFees: LatestFees<CollectiveInvestmentCompositeFees>
} & EntryAndRedemptionFees

type ETFFeeData = {
  datedFees: LatestFees<ExchangeTradedFundFees>
}

type VariableAnnuityFeeData = {
  datedFees: LatestFees<VariableAnnuityFee>
} & EntryAndRedemptionFees

type EnumOptions<T> = {
  code: T
  value: string
}[]

export type HurdleBenchmarkOptions = EnumOptions<string>
export type WaterfallOptions = EnumOptions<WaterfallCode>
export type HurdleReturnOptions = EnumOptions<FeeReturnCode>
export type HurdleCalculationOptions = EnumOptions<FeeCalculationCode>

export interface AltFee {
  date: string
  rate: string
  details: string
}

interface HurdleFee {
  date: string
  hurdle: string
  hurdleReturnType: FeeReturnCode | ""
  hurdleReturnOptions: HurdleReturnOptions
  hurdleCalculationType: FeeCalculationCode | ""
  hurdleCalculationOptions: HurdleCalculationOptions
}

export interface PrivateFeeData {
  carry: AltFee
  offset: AltFee
  waterfall: WaterfallCode | ""
  waterfallFurtherDetails: string
  waterfallOptions: WaterfallOptions
  hurdle: HurdleFee
  // clawBackProvisions: boolean
  // catchupFee: {
  //   date: string
  //   value: string
  // }
}

interface RealAssetsFeeData {
  hurdle: HurdleFee
  performanceFee: AltFee
  acquisitionFee: AltFee
  dispositionFee: AltFee
  waterfall: WaterfallCode | ""
  waterfallFurtherDetails: string
  waterfallOptions: WaterfallOptions
  carry: AltFee
}

interface HedgeFundFeeData extends RedemptionFees {
  highWaterMark: boolean
  custodyIncludedInFee: boolean
  catchupFee: {
    date: string
    value: string
  }
  mostFavoredNation: boolean
  hurdleBenchmark: {
    date: string
    benchmark: string
    benchmarkAdd: string
  }
  rebates: boolean
  hurdleBenchmarkOptions: HurdleBenchmarkOptions
}

class FeeDataParser {
  data?: VehicleOtherFeesQuery
  private defaultRedemptionFees = {
    redemptionFees: false,
    redemptionFeePct: "",
    redemptionDays: "",
  }
  private defaultRedemptionAndEntryFees = {
    ...this.defaultRedemptionFees,
    entryFees: false,
    entryFeePct: "",
  }
  private defaultStringFee = {
    date: previousQuarterEnd,
    value: "",
  }

  private altFeeDefault = {
    date: previousQuarterEnd,
    rate: "",
    details: "",
  }

  private hurdleFeeDefault = {
    date: previousQuarterEnd,
    hurdle: "",
    hurdleReturnType: "" as FeeReturnCode | "",
    hurdleReturnOptions: [] as HurdleReturnOptions,
    hurdleCalculationType: "" as FeeCalculationCode | "",
    hurdleCalculationOptions: [] as HurdleCalculationOptions,
  }

  private defaultPrivateFees = {
    carry: this.altFeeDefault,
    offset: this.altFeeDefault,
    // catchupFee: this.defaultStringFee,
    waterfall: "" as WaterfallCode | "",
    waterfallFurtherDetails: "",
    waterfallOptions: [] as WaterfallOptions,
    hurdle: this.hurdleFeeDefault,
    // clawBackProvisions: false,
  }

  private defaultRealAssetsFees = {
    hurdle: this.hurdleFeeDefault,
    performanceFee: this.altFeeDefault,
    acquisitionFee: this.altFeeDefault,
    dispositionFee: this.altFeeDefault,
    waterfall: "" as WaterfallCode | "",
    waterfallFurtherDetails: "",
    waterfallOptions: [] as WaterfallOptions,
    carry: this.altFeeDefault,
  }

  constructor(data?: VehicleOtherFeesQuery) {
    this.data = data
  }

  private getEntryAndRedemptionFees = (
    fees?:
      | MutualFundFields
      | CollectiveInvestmentFundFields
      | CollectiveInvestmentFundCompositeFields
      | VariableAnnuityFields
      | PooledVehicleFields
      | null
  ) => {
    return {
      entryFees: fees?.entryFees || false,
      entryFeePct: fees?.entryFeePct?.toString() || "",
      redemptionFees: fees?.redemptionFees || false,
      redemptionFeePct: fees?.redemptionFeePct?.toString() || "",
      redemptionDays: fees?.redemptionDays?.toString() || "",
    }
  }

  private getLatestFees = <T extends DatedFeesTypes>(
    datedFeeList: T[]
  ): LatestFees<T> => {
    datedFeeList.sort((a: any, b: any) =>
      a.date === b.date ? 0 : a.date > b.date ? -1 : 1
    )
    const keys = Object.keys(datedFeeList[0]).filter(
      (item) =>
        datedFeeList[0].hasOwnProperty(item) &&
        item !== "date" &&
        item !== "__typename"
    ) as KeyOfFee<T>[]

    let fees: LatestFees<T> = {} as LatestFees<T>
    datedFeeList.forEach((fee) => {
      for (let idx = keys.length; idx >= 0; idx--) {
        const key = keys[idx]
        if (fee[key] !== null && fee[key] !== undefined) {
          fees[key] = {
            date: fee.date,
            value: (fee[key] as any).toString(),
          }
          keys.splice(idx, 1)
        }
      }
    })
    keys.forEach((key) => {
      fees[key] = {
        date: previousQuarterEnd,
        value: "",
      }
    })
    return fees
  }

  private getAltFee = (altFees: Maybe<AlternativeFee>[] | null | undefined) => {
    const altFee = {
      date: previousQuarterEnd,
      rate: "",
      details: "",
    }
    if (altFees && altFees.length > 0) {
      altFees.sort((a, b) =>
        !a || !b || a.date === b.date ? 0 : a.date < b.date ? 1 : -1
      )
      const fee = altFees[0] as AlternativeFee

      altFee.date = fee.date
      altFee.rate = fee.rate?.toString() || ""
      altFee.details = fee.details || ""
    }
    return altFee
  }

  private getHurdleFee = (
    data:
      | PrivateCreditVehicleFields
      | PrivateEquityVehicleFields
      | RealAssetsVehicleFields
  ) => {
    let hurdleFee: HurdleFee
    if (data?.hurdle && data.hurdle.length > 0) {
      data.hurdle.sort((a, b) =>
        !a || !b || a.date === b.date ? 0 : a.date < b.date ? 1 : -1
      )
      const hurdle = data.hurdle[0]
      hurdleFee = {
        date: hurdle?.date || previousQuarterEnd,
        hurdle: hurdle?.hurdle?.toString() || "",
        hurdleReturnType: hurdle?.hurdleReturnType?.code || "",
        hurdleReturnOptions: this.data?.hurdleReturnOptions?.enumValues?.map(
          (item) => ({ code: item.name, value: item.description })
        ) as HurdleReturnOptions,
        hurdleCalculationType: hurdle?.hurdleCalculationType?.code || "",
        hurdleCalculationOptions:
          this.data?.hurdleCalculationOptions?.enumValues?.map((item) => ({
            code: item.name,
            value: item.description,
          })) as HurdleCalculationOptions,
      }
    } else {
      hurdleFee = this.defaultPrivateFees.hurdle
      hurdleFee.hurdleReturnOptions =
        this.data?.hurdleReturnOptions?.enumValues?.map((item) => ({
          code: item.name,
          value: item.description,
        })) as HurdleReturnOptions
      hurdleFee.hurdleCalculationOptions =
        this.data?.hurdleCalculationOptions?.enumValues?.map((item) => ({
          code: item.name,
          value: item.description,
        })) as HurdleCalculationOptions
    }
    return hurdleFee
  }

  private getPrivateFees = (
    data: PrivateCreditVehicleFields | PrivateEquityVehicleFields ) => {
    let fees = {} as PrivateFeeData
    fees.carry = this.getAltFee(data?.carry)
    fees.offset = this.getAltFee(data?.offset)
    // fees.catchupFee = this.defaultStringFee
    fees.waterfall = data?.waterfall?.code || ""
    fees.waterfallFurtherDetails = data?.waterfallFurtherDetails || ""
    // fees.clawBackProvisions = closeEndedData?.clawBackProvisions ? true : false
    fees.waterfallOptions = this.data?.waterfallOptions?.enumValues?.map(
      (item) => ({ code: item.name, value: item.description })
    ) as WaterfallOptions

    // if (closeEndedData?.catchupFee && closeEndedData.catchupFee.length > 0) {
    //   closeEndedData.catchupFee.sort((a, b) =>
    //     !a || !b || a.date === b.date ? 0 : a.date < b.date ? 1 : -1
    //   )
    //   const catchupFee = closeEndedData.catchupFee[0] as CatchupFee
    //   fees.catchupFee = {
    //     date: catchupFee.date || previousQuarterEnd,
    //     value: catchupFee.catchupFee?.toString() || "",
    //   }
    // }
    if (data?.hurdle && data.hurdle.length > 0) {
      data.hurdle.sort((a, b) =>
        !a || !b || a.date === b.date ? 0 : a.date < b.date ? 1 : -1
      )
      const hurdle = data.hurdle[0]
      fees.hurdle = {
        date: hurdle?.date || previousQuarterEnd,
        hurdle: hurdle?.hurdle?.toString() || "",
        hurdleReturnType: hurdle?.hurdleReturnType?.code || "",
        hurdleReturnOptions: this.data?.hurdleReturnOptions?.enumValues?.map(
          (item) => ({ code: item.name, value: item.description })
        ) as HurdleReturnOptions,
        hurdleCalculationType: hurdle?.hurdleCalculationType?.code || "",
        hurdleCalculationOptions:
          this.data?.hurdleCalculationOptions?.enumValues?.map((item) => ({
            code: item.name,
            value: item.description,
          })) as HurdleCalculationOptions,
      }
    } else {
      fees.hurdle = this.defaultPrivateFees.hurdle
      fees.hurdle.hurdleReturnOptions =
        this.data?.hurdleReturnOptions?.enumValues?.map((item) => ({
          code: item.name,
          value: item.description,
        })) as HurdleReturnOptions
      fees.hurdle.hurdleCalculationOptions =
        this.data?.hurdleCalculationOptions?.enumValues?.map((item) => ({
          code: item.name,
          value: item.description,
        })) as HurdleCalculationOptions
    }
    return fees
  }

  public getClosedEndedVehicleFees = (closedEndedData: ClosedEndedVehicleFields):  ClosedEndedFeeData => {
    let fees = {
      catchupFee: this.defaultStringFee,
      clawBackProvisions: false,
    } as ClosedEndedFeeData
    if(!closedEndedData){
      return {} as ClosedEndedFeeData
    }
    if (closedEndedData?.catchupFee && closedEndedData.catchupFee.length > 0) {
      const sortedFees = closedEndedData.catchupFee.sort((a, b) =>
        !a || !b || a.date === b.date ? 0 : a.date < b.date ? 1 : -1
      )
      const catchupFee = sortedFees[0] as CatchupFee
      fees.catchupFee = {
        date: catchupFee.date || previousQuarterEnd,
        value: catchupFee.catchupFee?.toString() || "",
      }
    }
    if(closedEndedData){
      fees.clawBackProvisions = closedEndedData?.clawBackProvisions ? true : false
    }
    return fees
  }

  public getMutualFundFees = (): MutualFundFeeData => {
    let mutualFund = {
      datedFees: {
        managementFee: this.defaultStringFee,
        fee12b1: this.defaultStringFee,
        administrativeOperatingExpenses: this.defaultStringFee,
        subAdvisor: this.defaultStringFee,
        totalExpense: this.defaultStringFee,
      } as LatestFees<MutualFundFees>,
      ...this.defaultRedemptionAndEntryFees,
    }
    if ((this.data?.vehicle as MutualFund)?.mutualFund) {
      const fees = (this.data?.vehicle as MutualFund).mutualFund
      const datedFeesRaw = fees?.datedFees?.filter(
        (fee) => fee
      ) as MutualFundFees[]
      const datedFees =
        datedFeesRaw.length > 0
          ? this.getLatestFees<MutualFundFees>(datedFeesRaw)
          : mutualFund.datedFees
      const otherFees = this.getEntryAndRedemptionFees(fees)
      mutualFund = {
        datedFees,
        ...otherFees,
      }
    }
    return mutualFund
  }

  public getCollectiveInvestmentFundFees = (): CITFeeData => {
    let collectiveInvestmentFund = {
      datedFees: {
        managementFee: this.defaultStringFee,
        totalExpense: this.defaultStringFee,
      } as LatestFees<CollectiveInvestmentFee>,
      ...this.defaultRedemptionAndEntryFees,
    }

    if (
      (this.data?.vehicle as CollectiveInvestmentFund)?.collectiveInvestmentFund
    ) {
      const fees = (this.data?.vehicle as CollectiveInvestmentFund)
        .collectiveInvestmentFund
      const datedFeesRaw = fees?.datedFees?.filter(
        (fee) => fee
      ) as CollectiveInvestmentFee[]
      const datedFees =
        datedFeesRaw.length > 0
          ? this.getLatestFees<CollectiveInvestmentFee>(datedFeesRaw)
          : collectiveInvestmentFund.datedFees
      const otherFees = this.getEntryAndRedemptionFees(fees)
      collectiveInvestmentFund = {
        datedFees,
        ...otherFees,
      }
    }
    return collectiveInvestmentFund
  }

  public getCollectiveInvestmentFundCompositeFees = (): CITCompositeFeeData => {
    let collectiveInvestmentFundComposite = {
      datedFees: {
        totalExpense: this.defaultStringFee,
      } as LatestFees<CollectiveInvestmentCompositeFees>,
      ...this.defaultRedemptionAndEntryFees,
    }

    if (
      (this.data?.vehicle as CollectiveInvestmentFundComposite)
        ?.collectiveInvestmentFundComposite
    ) {
      const fees = (this.data?.vehicle as CollectiveInvestmentFundComposite)
        .collectiveInvestmentFundComposite
      const datedFeesRaw = fees?.datedFees?.filter(
        (fee) => fee
      ) as CollectiveInvestmentCompositeFees[]
      const datedFees =
        datedFeesRaw.length > 0
          ? this.getLatestFees<CollectiveInvestmentCompositeFees>(datedFeesRaw)
          : collectiveInvestmentFundComposite.datedFees
      const otherFees = this.getEntryAndRedemptionFees(fees)
      collectiveInvestmentFundComposite = {
        datedFees,
        ...otherFees,
      }
    }

    return collectiveInvestmentFundComposite
  }

  public getExchangeTradedFundFees = (): ETFFeeData => {
    let exchangeTradedFund = {
      datedFees: {
        totalExpense: this.defaultStringFee,
      } as LatestFees<ExchangeTradedFundFees>,
    }

    if ((this.data?.vehicle as ExchangeTradedFund)?.exchangeTradedFund) {
      const fees = (this.data?.vehicle as ExchangeTradedFund).exchangeTradedFund
      const datedFeesRaw = fees?.datedFees?.filter(
        (fee) => fee
      ) as ExchangeTradedFundFees[]
      const datedFees =
        datedFeesRaw.length > 0
          ? this.getLatestFees<ExchangeTradedFundFees>(datedFeesRaw)
          : exchangeTradedFund.datedFees
      exchangeTradedFund = {
        datedFees,
      }
    }
    return exchangeTradedFund
  }

  public getVariableAnnuityFees = (): VariableAnnuityFeeData => {
    let variableAnnuity = {
      datedFees: {
        totalExpense: this.defaultStringFee,
      } as LatestFees<VariableAnnuityFee>,
      ...this.defaultRedemptionAndEntryFees,
    }

    if ((this.data?.vehicle as VariableAnnuity)?.variableAnnuity) {
      const fees = (this.data?.vehicle as VariableAnnuity).variableAnnuity
      const datedFeesRaw = fees?.datedFees?.filter(
        (fee) => fee
      ) as VariableAnnuityFee[]
      const datedFees =
        datedFeesRaw.length > 0
          ? this.getLatestFees<VariableAnnuityFee>(datedFeesRaw)
          : variableAnnuity.datedFees
      const otherFees = this.getEntryAndRedemptionFees(fees)
      variableAnnuity = {
        datedFees,
        ...otherFees,
      }
    }
    return variableAnnuity
  }

  public getPooledVehicleFees = (): EntryAndRedemptionFees => {
    let pooledVehicle = {
      ...this.defaultRedemptionAndEntryFees,
    }

    if ((this.data?.vehicle as PooledVehicle)?.pooledVehicle) {
      const fees = (this.data?.vehicle as PooledVehicle).pooledVehicle
      const otherFees = this.getEntryAndRedemptionFees(fees)
      pooledVehicle = {
        ...otherFees,
      }
    }

    return pooledVehicle
  }

  public getPrivateCreditFees = (): PrivateFeeData => {
    let privateCreditVehicle = this.defaultPrivateFees
    if ((this.data?.vehicle as PrivateCreditVehicle)?.privateCreditVehicle) {
      const fees = (this.data?.vehicle as PrivateCreditVehicle)
        .privateCreditVehicle
      if (fees) {
        privateCreditVehicle = this.getPrivateFees(fees)
      }
    }
    return privateCreditVehicle
  }

  public getPrivateEquityFees = (): PrivateFeeData => {
    let privateEquityVehicle = this.defaultPrivateFees
    if ((this.data?.vehicle as PrivateEquityVehicle)?.privateEquityVehicle) {
      const fees = (this.data?.vehicle as PrivateEquityVehicle)
        .privateEquityVehicle
      if (fees) {
        privateEquityVehicle = this.getPrivateFees(fees)
      }
    }
    return privateEquityVehicle
  }

  public getRealAssetsFees = (): RealAssetsFeeData => {
    let realAssetsVehicle = this.defaultRealAssetsFees
    if ((this.data?.vehicle as RealAssetsVehicle)?.realAssetsVehicle) {
      const fees = (this.data?.vehicle as RealAssetsVehicle).realAssetsVehicle
      if (fees) {
        realAssetsVehicle.hurdle = this.getHurdleFee(fees)
        realAssetsVehicle.performanceFee = this.getAltFee(fees.performanceFee)
        realAssetsVehicle.acquisitionFee = this.getAltFee(fees.acquisitionFee)
        realAssetsVehicle.dispositionFee = this.getAltFee(fees.dispositionFee)

        const vehicleType = this.data?.vehicle?.__typename
        if(vehicleType === "ClosedEndedPrivateNonRegisteredRealAssets"){
          realAssetsVehicle.waterfall = fees?.waterfall?.code || ""
          realAssetsVehicle.waterfallFurtherDetails = fees?.waterfallFurtherDetails || ""
          realAssetsVehicle.waterfallOptions = this.data?.waterfallOptions?.enumValues?.map(
            (item) => ({ code: item.name, value: item.description })
          ) as WaterfallOptions
        }
        // CAL-3343 Add carry fee.
        const hasCarryFee = ["OpenEndedPooledVehicleRealAssets", "OpenEndedCollectiveInvestmentFundCompositeRealAssets", "ClosedEndedPrivateNonRegisteredRealAssets"]
        if(vehicleType && hasCarryFee.includes(vehicleType)) {
          realAssetsVehicle.carry = this.getAltFee(fees.carry)
        }else if (vehicleType){
          console.log({vehicleType, hasCarryFee})
        }
      }
    }
    return realAssetsVehicle
  }

  public getHedgeFundFees = (): HedgeFundFeeData => {
    let hedgeFundVehicleFees: HedgeFundFeeData = {
      highWaterMark: false,
      custodyIncludedInFee: false,
      catchupFee: this.defaultStringFee,
      mostFavoredNation: false,
      hurdleBenchmark: {
        date: previousQuarterEnd,
        benchmark: "",
        benchmarkAdd: "",
      },
      hurdleBenchmarkOptions: [],
      rebates: false,
      ...this.defaultRedemptionFees,
    }

    if ((this.data?.vehicle as HedgeFundVehicle)?.hedgeFundVehicle) {
      const fees = (this.data?.vehicle as HedgeFundVehicle)?.hedgeFundVehicle
      hedgeFundVehicleFees.hurdleBenchmarkOptions =
        fees?.hurdleBenchmarkOptions as HurdleBenchmarkOptions
      hedgeFundVehicleFees.highWaterMark = fees?.highWaterMark || false
      hedgeFundVehicleFees.custodyIncludedInFee =
        fees?.custodyIncludedInFee || false
      hedgeFundVehicleFees.mostFavoredNation = fees?.mostFavoredNation || false
      hedgeFundVehicleFees.rebates = fees?.rebates || false
      if (fees?.catchupFee && fees.catchupFee.length > 0) {
        fees.catchupFee.sort((a, b) =>
          !a || !b || a.date === b.date ? 0 : a.date < b.date ? 1 : -1
        )
        const catchupFee = fees.catchupFee[0] as CatchupFee
        hedgeFundVehicleFees.catchupFee = {
          date: catchupFee.date || previousQuarterEnd,
          value: catchupFee.catchupFee?.toString() || "",
        }
      }
      if (fees?.hurdleBenchmark && fees.hurdleBenchmark.length > 0) {
        fees.hurdleBenchmark.sort((a, b) =>
          !a || !b || a.date === b.date ? 0 : a.date < b.date ? 1 : -1
        )
        const hurdleBenchmark = fees.hurdleBenchmark[0]
        hedgeFundVehicleFees.hurdleBenchmark = {
          date: hurdleBenchmark?.date || previousQuarterEnd,
          benchmark: hurdleBenchmark?.benchmark?.vehicle?.fundid || "",
          benchmarkAdd: hurdleBenchmark?.benchmarkAdd?.toString() || "",
        }
      }
      hedgeFundVehicleFees.redemptionFees = fees?.redemptionFees || false
      hedgeFundVehicleFees.redemptionFeePct =
        fees?.redemptionFeePct?.toString() || ""
      hedgeFundVehicleFees.redemptionDays =
        fees?.redemptionDays?.toString() || ""
    }

    return hedgeFundVehicleFees
  }
}

export interface FeeData {
  mutualFund: MutualFundFeeData
  collectiveInvestmentFund: CITFeeData
  collectiveInvestmentFundComposite: CITCompositeFeeData
  exchangeTradedFund: ETFFeeData
  variableAnnuity: VariableAnnuityFeeData
  pooledVehicle: EntryAndRedemptionFees
  privateCreditVehicle: PrivateFeeData
  privateEquityVehicle: PrivateFeeData
  realAssetsVehicle: RealAssetsFeeData
  hedgeFundVehicle: HedgeFundFeeData
  closedEndedVehicle: ClosedEndedFeeData
}

export const getFeeData = (data?: VehicleOtherFeesQuery): FeeData => {
  const feeData = new FeeDataParser(data)
  const closedEndedVehicle = feeData.getClosedEndedVehicleFees((data?.vehicle as ClosedEndedVehicle)?.closedEndedVehicle as ClosedEndedVehicleFields)
  const mutualFund = feeData.getMutualFundFees()
  const collectiveInvestmentFund = feeData.getCollectiveInvestmentFundFees()
  const collectiveInvestmentFundComposite =
    feeData.getCollectiveInvestmentFundCompositeFees()
  const exchangeTradedFund = feeData.getExchangeTradedFundFees()
  const variableAnnuity = feeData.getVariableAnnuityFees()
  const pooledVehicle = feeData.getPooledVehicleFees()
  const privateCreditVehicle = feeData.getPrivateCreditFees()
  const privateEquityVehicle = feeData.getPrivateEquityFees()
  const realAssetsVehicle = feeData.getRealAssetsFees()
  const hedgeFundVehicle = feeData.getHedgeFundFees()

  return {
    closedEndedVehicle,
    mutualFund,
    collectiveInvestmentFund,
    collectiveInvestmentFundComposite,
    exchangeTradedFund,
    variableAnnuity,
    pooledVehicle,
    privateCreditVehicle,
    privateEquityVehicle,
    realAssetsVehicle,
    hedgeFundVehicle,
  }
}
