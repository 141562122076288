import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, Label, Table } from 'reactstrap'

import { BlendedBenchmarkCode } from '../../__generated__/graphql'
import { FormInputSubClasses } from '../../helpers/constant'
import { getNewStateObject } from '../../helpers/helpers'
import { FormInput } from '../ui/Forms/FormInput'

type columnDefItem = {
  field: string
  type: string
  title: string
  className?: string
  subtype?: string
  placeholder?: string
  optionSource?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  widthClass?: string
}

interface BlendedBenchmarkTableRowProps {
  data: any
  columnDef: columnDefItem[]
  idx: number
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

type NullableBlendedBenchmarkData = {
  percent: number | null
  explanation: string | null
  benchmark: { code: BlendedBenchmarkCode | null }
}

type BlendedBenchmarkTableProps = {
  data: NullableBlendedBenchmarkData[]
  handleChange: (state: any) => void
  editMode: boolean
}

const BlendedBenchmarkColumnDef: columnDefItem[] = [
  {
    field: "percent",
    type: "float",
    subtype: "percent",
    placeholder: "0",
    title: "Allocation",
    subClasses: {
      inputClasses: "text-right",
      labelClasses: "hidden",
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    widthClass: "col-2"
  },
  {
    field: "benchmark.code",
    type: "select",
    title: "Benchmark",
    optionSource: "BlendedBenchmarkCode",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    widthClass: "col-5"
  },
  {
    field: "explanation",
    type: "text",
    title: "Explanation",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    widthClass: "col-3"
  }
]

const BlendedBenchmarkTableRow = ({
  data,
  columnDef,
  idx,
  editMode,
  updateValue,
  action
}: BlendedBenchmarkTableRowProps) => {
  // console.log(data)
  return (
    <tr className={"d-flex"}>
      {columnDef.map((header, index) => {
        let widthClass = header.widthClass
        if (!editMode) {
          switch (index) {
            case 0:
              widthClass = "col-2"
              break
            case 1:
              widthClass = "col-6"
              break
            case 2:
              widthClass = "col-4"
          }
        }
        // console.log(header, header.field)
        let propertyVal = _.get(data, header.field)
        let defaultValue = null
        if (header.type === "text") {
          defaultValue = ""
        }
        return (
          <td key={header.field} className={widthClass}>
            <FormInput
              key={header.field}
              property={header.field}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              placeholder={header.placeholder}
              idx={idx}
              editMode={editMode}
              propertyVal={propertyVal || defaultValue}
              updateValue={value =>
                updateValue(value, header.type, header.field)
              }
              optionSource={header.optionSource}
              subClasses={header.subClasses || {}}
            />
          </td>
        )
      })}
      {editMode && (
        <td className="col-2">
          <Button
            color="link"
            className="ml-auto px-0 py-0"
            onClick={() => action.remove(idx)}
          >
            Remove
            <FontAwesomeIcon
              icon="minus-circle"
              className="ml-2 text-blue-100"
            />
          </Button>
        </td>
      )}
    </tr>
  )
}

const BlendedBenchmarkTableDisplay = (seed: BlendedBenchmarkTableProps) => {
  const tableName = "Blended-Benchmark"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)

  useEffect(() => {
    setData(data)
  }, [data])

  const onAddBlendedBenchmarkRow = (row: NullableBlendedBenchmarkData) => {
    let newData = [...tableState, row]
    setData(newData)
    handleChange(newData)
  }

  const onRemoveBlendedBenchmarkRow = (rowIndex: number) => {
    let newData = [
      ...tableState.slice(0, rowIndex),
      ...tableState.slice(rowIndex + 1)
    ]
    setData(newData)
    handleChange(newData)
  }
  const actionMap = {
    add: onAddBlendedBenchmarkRow,
    remove: onRemoveBlendedBenchmarkRow
  }

  const onHandleChangeBlendedBenchmarkRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = tableState.map((el, index) => {
      if (idx === index) {
        let newData = getNewStateObject({
          state: el,
          newValue: value,
          property
        }) as typeof el
        return newData
      } else {
        return el
      }
    })
    setData(newData)
    handleChange(newData)
  }

  return (
    <div
      className={classnames(
        `${tableName}-table row form-group pl-0 pt-1`,
        "col-sm-12"
      )}
    >
      <Label className={`row col-sm-6`}>Blended Benchmark</Label>
      <Table
        striped
        hover
        size="sm"
        key={`BlendedBenchmark-table`}
        className={"header-no-border"}
      >
        <thead className="table-border-bottom">
          <tr className="table-header d-flex">
            {BlendedBenchmarkColumnDef.map((headerDef, idx) => {
              let widthClass = headerDef.widthClass
              if (!editMode) {
                switch (idx) {
                  case 0:
                    widthClass = "col-2"
                    break
                  case 1:
                    widthClass = "col-6"
                    break
                  case 2:
                    widthClass = "col-4"
                }
              }
              return (
                <th
                  key={`BlendedBenchmark-header-${idx}`}
                  className={`${widthClass}`}
                >
                  {headerDef.title}
                </th>
              )
            })}
            {editMode && (
              <th key={`BlendedBenchmark-header-2`} className={"px-0 col-2"}>
                <Button
                  color="link"
                  className="ml-auto px-0 py-0"
                  onClick={() =>
                    actionMap.add({
                      percent: 0,
                      explanation: null,
                      benchmark: { code: null }
                    })
                  }
                >
                  Add Row
                  <FontAwesomeIcon
                    icon="plus-circle"
                    className="ml-2 text-blue-100"
                  />
                </Button>
              </th>
            )}
          </tr>
        </thead>
        <tbody key={0}>
          {tableState.map((row: any, idx: number) => {
            return (
              <BlendedBenchmarkTableRow
                data={row}
                columnDef={BlendedBenchmarkColumnDef}
                key={idx}
                idx={idx}
                editMode={editMode}
                action={actionMap}
                updateValue={(value, type, property) =>
                  onHandleChangeBlendedBenchmarkRow(idx, value, type, property)
                }
              />
            )
          })}
        </tbody>
      </Table>
      <div
        className={`border-bottom-line-break my-2 col-sm-12`}
        key={"blendedBenchmark"}
      ></div>
    </div>
  )
}

export default BlendedBenchmarkTableDisplay
