import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  IconDefinition,
  IconName,
  IconPrefix
} from "@fortawesome/fontawesome-common-types";

export const section2Col: IconDefinition = {
  prefix: "callan" as IconPrefix,
  iconName: "section-2col" as IconName,
  icon: [
    17,
    16,
    [],
    "",
    "M1 2C1 1.44772 1.44772 1 2 1H9V15H2C1.44772 15 1 14.5523 1 14V2ZM8 1H15C15.5523 1 16 1.44772 16 2V14C16 14.5523 15.5523 15 15 15H8V1Z"
  ],
};

export const section2ColLeftSidebar: IconDefinition = {
  prefix: "callan" as IconPrefix,
  iconName: "section-2col-left-sidebar" as IconName,
  icon: [
    16,
    14,
    [],
    "",
    "M15 13.5L4.5 13.5L4.5 0.500001L15 0.5C15.2761 0.5 15.5 0.723858 15.5 1L15.5 13C15.5 13.2761 15.2761 13.5 15 13.5ZM4.5 13.5L1 13.5C0.723858 13.5 0.5 13.2761 0.5 13L0.499999 1C0.499999 0.723858 0.723857 0.5 0.999999 0.5L4.5 0.5L4.5 13.5Z"
  ],
};

export const section2ColRightSidebar: IconDefinition = {
  prefix: "callan" as IconPrefix,
  iconName: "section-2col-right-sidebar" as IconName,
  icon: [
    16,
    14,
    [],
    "",
    "M1 0.5H11.5V13.5H1C0.723858 13.5 0.5 13.2761 0.5 13V1C0.5 0.723858 0.723858 0.5 1 0.5ZM11.5 0.5H15C15.2761 0.5 15.5 0.723858 15.5 1V13C15.5 13.2761 15.2761 13.5 15 13.5H11.5V0.5Z"
  ],
};

export const section3Col: IconDefinition = {
  prefix: "callan" as IconPrefix,
  iconName: "section-3col" as IconName,
  icon: [
    16,
    14,
    [],
    "",
    "M1 0.5 H5.5V13.5H1C0.723858 13.5 0.5 13.2761 0.5 13V1C0.5 0.723858 0.723858 0.5 1 0.5ZM10.5 0.5H15C15.2761 0.5 15.5 0.723858 15.5 1V13C15.5 13.2761 15.2761 13.5 15 13.5H10.5V0.5Z M6 0.5 H10 M6 13.5 H 10"
  ],
};

export const section3ColWithSidebars: IconDefinition = {
  prefix: "callan" as IconPrefix,
  iconName: "section-3col-with-sidebars" as IconName,
  icon: [
    16,
    14,
    [],
    "",
    "M1 0.5H4.5V13.5H1C0.723858 13.5 0.5 13.2761 0.5 13V1C0.5 0.723858 0.723858 0.5 1 0.5ZM11.5 0.5H15C15.2761 0.5 15.5 0.723858 15.5 1V13C15.5 13.2761 15.2761 13.5 15 13.5H11.5V0.5ZM5 0.5 H11 M5 13.5 H 11"
  ],
};



const Icon = ({ icon='callan', width = 15, height = 15, className = '' }) => {
  if (icon === 'quarter') {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width={ width } height={height} viewBox="0 0 20.999 20" className={`svg-icon ${ className }`}>
        <g transform="translate(-13099 3879)">
          <path fill="currentColor" d="M 13107.9990234375 -3859.49951171875 C 13103.3125 -3859.49951171875 13099.5 -3863.3134765625 13099.5 -3868.001708984375 C 13099.5 -3872.52001953125 13103.044921875 -3876.22607421875 13107.4990234375 -3876.48583984375 L 13107.4990234375 -3868.001708984375 L 13107.4990234375 -3867.501708984375 L 13107.9990234375 -3867.501708984375 L 13116.486328125 -3867.501708984375 C 13116.2265625 -3863.045654296875 13112.51953125 -3859.49951171875 13107.9990234375 -3859.49951171875 Z"/>
          <path fill="currentColor" d="M 13107.9990234375 -3859.99951171875 C 13112.0732421875 -3859.99951171875 13115.4453125 -3863.059326171875 13115.9384765625 -3867.001708984375 L 13107.9990234375 -3867.001708984375 L 13106.9990234375 -3867.001708984375 L 13106.9990234375 -3868.001708984375 L 13106.9990234375 -3875.938232421875 C 13103.05859375 -3875.444580078125 13100 -3872.073486328125 13100 -3868.001708984375 C 13100 -3863.58935546875 13103.587890625 -3859.99951171875 13107.9990234375 -3859.99951171875 M 13107.9990234375 -3858.99951171875 C 13103.037109375 -3858.99951171875 13099 -3863.037841796875 13099 -3868.001708984375 C 13099 -3872.963623046875 13103.037109375 -3877.00048828125 13107.9990234375 -3877.00048828125 L 13107.9990234375 -3868.001708984375 L 13117.0009765625 -3868.001708984375 C 13117.0009765625 -3863.037353515625 13112.9619140625 -3858.99951171875 13107.9990234375 -3858.99951171875 Z"/>
        </g>
        <g stroke="currentColor" fill="transparent" transform="translate(-13096 3875)">
          <path d="M 13115.9365234375 -3866.99951171875 L 13108 -3866.99951171875 L 13108 -3873.999755859375 C 13108.0009765625 -3873.999755859375 13108.0009765625 -3873.999755859375 13108.001953125 -3873.999755859375 C 13112.0732421875 -3873.999755859375 13115.443359375 -3870.9404296875 13115.9365234375 -3866.99951171875 Z"/>
          <path d="M 13114.7080078125 -3867.99951171875 C 13113.9384765625 -3870.57861328125 13111.71875 -3872.53857421875 13109 -3872.9287109375 L 13109 -3867.99951171875 L 13114.7080078125 -3867.99951171875 M 13116.9990234375 -3865.99951171875 L 13107 -3865.99951171875 L 13107 -3874.944580078125 C 13107.3291015625 -3874.98095703125 13107.6630859375 -3874.999755859375 13108.001953125 -3874.999755859375 C 13112.970703125 -3874.999755859375 13116.9990234375 -3870.968994140625 13116.9990234375 -3865.99951171875 Z"/>
        </g>
      </svg>
    );
  }
  if (icon === 'callan') {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width={ width } height={height} viewBox="0 0 23 23" className={`svg-icon ${ className }`}>
        <rect fill="currentColor" width="5" height="5"/>
        <rect fill="currentColor"  width="5" height="5" transform="translate(0 9)"/>
        <rect fill="currentColor"  width="5" height="5" transform="translate(0 18)"/>
        <rect fill="currentColor"  width="5" height="5" transform="translate(9)"/>
        <rect fill="currentColor"  width="5" height="5" transform="translate(9 9)"/>
        <rect fill="currentColor"  width="5" height="5" transform="translate(9 18)"/>
        <rect fill="currentColor"  width="5" height="5" transform="translate(18)"/>
        <rect fill="currentColor"  width="5" height="5" transform="translate(18 9)"/>
        <rect fill="currentColor"  width="5" height="5" transform="translate(18 18)"/>
      </svg>
    );
  }
  return <></>
}

export const InteractionIcon = ({type}:{type?: string | null}) => {
  switch(type){
    case "In House":
      return (<FontAwesomeIcon icon={["fal", "home-alt"]}/>)
    case "On Site":
      return (<FontAwesomeIcon icon={["fal", "building"]}/>)
    case "Off Site":
      return <FontAwesomeIcon icon={["fal", "map-marker-alt"]}/>
    case "Conference Call":
      return <FontAwesomeIcon icon={["fal", "phone"]}/>
    case "Client":
      return <FontAwesomeIcon icon={["fal", "user-friends"]}/>
    case "Video Call":
      return <FontAwesomeIcon icon={["fal", "video"]}/>
    case "Webcast":
      return <FontAwesomeIcon icon={["fal", "tv-alt"]}/>
    case "Press Release":
      return <FontAwesomeIcon icon={["fal", "newspaper"]}/>
    case "Check":
      return <FontAwesomeIcon icon={["fas", "check"]}/>
    case "Email":
      return <FontAwesomeIcon icon={["fal", "envelope"]}/>
    default:
      return <FontAwesomeIcon icon={["fal", "question"]}/>

  }
}

export default Icon