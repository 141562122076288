import React from "react"
import { Row, Col, FormGroup, Label, Input } from "reactstrap"
import { CustomBooleanInput } from "."
import { PercentInput } from "../../FeeFormComponents"
import {
  OtherFeesGenericProps,
  NumericInputData,
  OtherInputData,
} from "../FeeRows"

interface RedemptionFeeRowProps extends OtherFeesGenericProps {
  boolInput: OtherInputData<boolean>
  feeInput: NumericInputData<string>
  daysInput: OtherInputData<string>
}

export const RedemptionFeeRow: React.FC<RedemptionFeeRowProps> = ({
  label,
  editing,
  boolInput,
  feeInput,
  daysInput,
}) => {
  return editing || boolInput.value ? (
    <tr>
      <td>{label}</td>
      <td>
        {editing && (
          <CustomBooleanInput
            id="redemption-fee"
            value={boolInput.value}
            onChange={boolInput.onChange}
          />
        )}
      </td>
      <td colSpan={2}>
        <Row form>
          <Col sm={6}>
            <FormGroup row>
              <Label sm={2}>Fee</Label>
              <Col sm={5} className="pl-0">
                <PercentInput
                  value={+feeInput.value}
                  disabled={!editing}
                  onChange={feeInput.onChange}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup row>
              <Col sm={1}></Col>
              <Label sm={3}># Days</Label>
              <Col sm={6} className="pl-0">
                <Input
                  type="text"
                  bsSize="sm"
                  style={{ width: "100%" }}
                  value={daysInput.value}
                  disabled={!editing}
                  onChange={daysInput.onChange}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </td>
    </tr>
  ) : (
    <tr>
      <td>{label}</td>
      <td></td>
      <td>{boolInput.value === false ? "No" : ""}</td>
      <td></td>
    </tr>
  )
}
