import { disableFragmentWarnings, gql } from '@apollo/client'
import {
  VEHICLE_CHARACTERISTICS_FRAGMENT,
  VEHICLE_PERFORMANCE_RETURN_FRAGMENT,
  VEHICLE_PERFORMANCE_CASHFLOW_FRAGMENT,
  VEHICLE_PERFORMANCE_CASHFLOW_ONLY_FRAGMENT
} from './VehicleFragments'
disableFragmentWarnings()

export const VEHICLE_CHARACTERISTICS_QUERY = gql`
  query VehicleCharacteristics(
    $id: String!
    $startDate: Date!
    $endDate: Date!
    $filters: CharacteristicsFilters!
    ) {
    vehicle(id: $id) {
      ...VehicleCharacteristicsFragment
    }
  }
  ${VEHICLE_CHARACTERISTICS_FRAGMENT}
`

export const VEHICLE_CHARACTERISTICS_MUTATION = gql`
  mutation UpdateVehicleCharacteristics (
    $input: UpdateVehicleInput!,
    $startDate: Date!
    $endDate: Date!
    $filters: CharacteristicsFilters!
  ) {
    vehicle: updateVehicle(input: $input) {
      vehicle{
        ...VehicleCharacteristicsFragment
      }
    }
  }
  ${VEHICLE_CHARACTERISTICS_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_RETURNS = gql`
  query VehiclePerformanceReturns($id: String!, $startDate: Date, $endDate: Date, $period: PerformancePeriodType! ) {
    vehicle_returns: vehicle(id: $id ) {
      ...VehiclePerformanceReturnFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURN_FRAGMENT}
`

export const UPDATE_VEHICLE_PERFORMANCE_RETURNS = gql`
  mutation UpdateVehiclePerformanceReturns(
    $input: UpdateVehicleInput!,
    $startDate: Date,
    $endDate: Date!,
    $period: PerformancePeriodType!
  ) {
    vehicle: updateVehicle(input: $input) {
      vehicle {
        ...VehiclePerformanceReturnFragment
        vehicle {
          id: fundid
          cashFlows(date: $endDate) {
            ...VehiclePerformanceCashflowOnlyFragment
          }
          product {

            __typename
            ...on ClosedEndedPrivateCredit {
              closedEnded {
                finalCommitSize
              }
            }
            ...on ClosedEndedPrivateEquity {
              closedEnded {
                finalCommitSize
              }
            }
            ...on ClosedEndedRealAssets {
              closedEnded {
                finalCommitSize
              }
            }
            ...on ClosedEndedHedgeFund {
              closedEnded {
                finalCommitSize
              }
            }
          }
        }
      }
    }
  }
  ${VEHICLE_PERFORMANCE_RETURN_FRAGMENT}
  ${VEHICLE_PERFORMANCE_CASHFLOW_ONLY_FRAGMENT}
`

export const DELETE_VEHICLE_RETURN_PERFORMANCE = gql`
  mutation DeleteVehicleReturnPerformance(
    $input: DeletePerformanceInput!
  ) {
    vehicle: deletePerformance(input: $input) {
      status
      message
    }
  }
`

export const UPDATE_VEHICLE_PERFORMANCE_BARE = gql`
  mutation UpdateVehiclePerformanceBare(
    $input: UpdateVehicleInput!,
    $date: Date!
  ) {
    vehicle: updateVehicle(input: $input) {
      vehicle {
        ...VehiclePerformanceCashflowFragment
      }
    }
  }
  ${VEHICLE_PERFORMANCE_CASHFLOW_FRAGMENT}
`



export const VEHICLE_PERFORMANCE_CASHFLOWS = gql`
  query VehiclePerformanceCashflow($id: String!, $date: Date!) {
    vehicle_cashflows: vehicle(id: $id) {
      ...VehiclePerformanceCashflowFragment
    }
    assetClassMaps {
      uniqueTransactionTypeCodeAssetClassMap {
        code
        assetMixNum
      }
    }
  }
  ${VEHICLE_PERFORMANCE_CASHFLOW_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_OPEN_CASHFLOWS = gql`
  query VehiclePerformanceOpenCashflow($id: String!, $date: Date!) {
    vehicle_open_cashflows: vehicle(id: $id) {
      ...VehiclePerformanceCashflowFragment
    }
  }
  ${VEHICLE_PERFORMANCE_CASHFLOW_FRAGMENT}
`

export const DELETE_VEHICLE_CASHFLOW_TRANSACTION = gql`
  mutation DeleteVehicleCashflowTransaction(
    $input: DeleteVehicleCashFlowTransactionInput!
  ) {
    vehicle: deleteVehicleCashFlowTransactions(input: $input) {
      status
      message
    }
  }
`

export const VEHICLE_PERFORMANCE_CASHFLOWS_PERFORMANCE = gql`
  query VehiclePerformanceCashflowPerformance($id: String!, $date: Date!, $performanceInput: CashFlowPerformanceParams!) {
    vehicle_cashflow_performance: vehicle(id: $id) {
      ... on ClosedEndedVehicle {
        closedEndedVehicle {
          vintageFirstCashFlow
        }
      }
      vehicle {
        id: fundid
        cashFlowsPerformance (date: $date, input: $performanceInput){
          net
          tvpi
          rvpi
          dpi
          irr
          committedCapital
          uncalledCapital
          calledCapital
          distributedCapital
          irrMessage
          startDate
        }
      }
    }
  }
`

export const VEHICLE_PERFORMANCE_CASHFLOW_HISTORIC = gql`
  query VehiclePerformanceCashflowHistoric($id: String!) {
    vehicle(id: $id) {
      __typename
      vehicle {
        id: fundid
        name
        cashFlowsHistory {
          signedURL
        }
      }
    }
  }
`

export const VEHICLE_PERFORMANCE_CASHFLOW_SUMMARY = gql`
  query VehiclePerformanceCashflowSummary($id: String!, $date: Date!, $prevQuarter: Date!) {
    vehicle_cashflow_summary: vehicle(id: $id) {
      vehicle {
        id: fundid
        __typename
        cashFlowsSummary( date: $date) {
          cashFlowSummary {
            periodEndDate
            currency {
              code
              value
            }
            totalPartnerships
            activePartnerships
            liquidatedPartnerships
            activeCompanies
            committedCapital
            paidInCapital
            uncalledCapital
            distributedCapital
            netAssetValue
            grossIRR
            netIRR
          }
          cashFlowSummaryVintageYear {
            vintageYear
            cashFlowSummary {
              periodEndDate
              currency {
                code
                value
              }
              totalPartnerships
              activePartnerships
              liquidatedPartnerships
              activeCompanies
              committedCapital
              paidInCapital
              uncalledCapital
              distributedCapital
              netAssetValue
              grossIRR
              netIRR
            }
          }
        }
        prevQuarterCashflowSummary: cashFlowsSummary( date: $prevQuarter) {
          cashFlowSummary {
            periodEndDate
            currency {
              code
              value
            }
            totalPartnerships
            activePartnerships
            liquidatedPartnerships
            activeCompanies
            committedCapital
            paidInCapital
            uncalledCapital
            distributedCapital
            netAssetValue
            grossIRR
            netIRR
          }
          cashFlowSummaryVintageYear {
            vintageYear
            cashFlowSummary {
              periodEndDate
              currency {
                code
                value
              }
              totalPartnerships
              activePartnerships
              liquidatedPartnerships
              activeCompanies
              committedCapital
              paidInCapital
              uncalledCapital
              distributedCapital
              netAssetValue
              grossIRR
              netIRR
            }
          }
        }
      }
    }
  }
`

