import { disableFragmentWarnings, gql } from "@apollo/client"
import { CHARACTERISTICS_DEFAULT_FRAGMENT, CHARACTERISTICS_FRAGMENT, MISC_PORTFOLIO_INFO_FRAGMENT, PORTFOLIO_CHARACTERISTICS_FRAGMENT } from "./Fragments"

disableFragmentWarnings()

export const VEHICLE_SIMPLE_FRAGMENT = gql`
  fragment VehicleSimpleFragment on VehicleFields {
    id: fundid
    name
    status {
      code
      value
    }
    identifiers {
      ticker
      cusip
    }
  }
`

export const VEHICLE_CHARACTERISTICS_FRAGMENT = gql`
  fragment VehicleCharacteristicsFragment on Vehicle {
    vehicle {
      id: fundid
      name
      status {
        code
        value
      }
      inceptionDate
      category {
        code
        value
      }
      identifiers {
        ticker
        cusip
      }
      characteristics (filters: $filters) {
        ...MiscPortfolioInfoFragment
        ...PortfolioCharacteristicsFragment
        ...CharacteristicsDefaultFragment
      }
      weightedExposures {
        ...CharacteristicsFragment
      }
      holdingStatus {
        quarterDate
        unlock
        submittedDate
        person {
          id
          firstName
          lastName
        }
      }
      vehicleDataCollection {
        characteristicsRequired
        holdingsRequired
      }
    }
    ... on OpenEndedMutualFund {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedExchangeTradedFund {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedPooledVehicle {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedVariableAnnuity {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedCollectiveInvestmentFundComposite {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedSeparateAccount {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedPooledVehiclePrivateEquity {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedPooledVehicleRealAssets {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedSeparateAccountRealAssets {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedPrivateNonRegisteredHedgeFund {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedCollectiveInvestmentFundStableValueComposite {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedCollectiveInvestmentFundStableValue {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedSeparateAccountStableValue {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedCollectiveInvestmentFund {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on OpenEndedPooledVehiclePrivateCredit {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
  }
  ${CHARACTERISTICS_DEFAULT_FRAGMENT}
  ${MISC_PORTFOLIO_INFO_FRAGMENT}
  ${PORTFOLIO_CHARACTERISTICS_FRAGMENT}
  ${CHARACTERISTICS_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_RETURNS_FRAGMENT = gql`
  fragment VehiclePerformanceReturnsFragment on Performance {
    startDate
    endDate
    value {
      active
      frozen
    }
    projection {
      code
      value
    }
    period
    currency {
      code
    }
    dividendReinvestment {
      code
    }
  }
`

export const VEHICLE_PERFORMANCE_RETURN_NET_TOTAL_FRAGMENT = gql`
  fragment VehiclePerformanceReturnNetTotalFragment on VehicleFields {
    netTotal: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: n
        type: t
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`
export const VEHICLE_PERFORMANCE_RETURN_GROSS_TOTAL_FRAGMENT = gql`
  fragment VehiclePerformanceReturnGrossTotalFragment on VehicleFields {
    grossTotal: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: g
        type: t
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_RETURN_NET_TOTAL_MARKET_FRAGMENT = gql`
  fragment VehiclePerformanceReturnNetMarketTotalFragment on VehicleFields {
    netTotalMarket: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: n
        type: p
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_RETURN_NET_RETAIL_FRAGMENT = gql`
  fragment VehiclePerformanceReturnNetRetailFragment on VehicleFields {
    netRetail: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: n
        type: r
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`
export const VEHICLE_PERFORMANCE_RETURN_NET_INSTITUTIONAL_FRAGMENT = gql`
  fragment VehiclePerformanceReturnNetInstitutionalFragment on VehicleFields {
    netInstitutional: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: n
        type: m
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`
export const VEHICLE_PERFORMANCE_RETURN_NET_EQUAL_WEIGHTED_FRAGMENT = gql`
  fragment VehiclePerformanceReturnNetEqualWeightedFragment on VehicleFields {
    netEqualWeighted: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: n
        type: n
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_RETURN_GROSS_INCOME_FRAGMENT = gql`
  fragment VehiclePerformanceReturnGrossIncomeFragment on VehicleFields {
    grossIncome: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: g
        type: i
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`
export const VEHICLE_PERFORMANCE_RETURN_GROSS_APPRECIATION_FRAGMENT = gql`
  fragment VehiclePerformanceReturnGrossAppreciationFragment on VehicleFields {
    grossAppreciation: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: g
        type: a
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_RETURN_NET_INCOME_FRAGMENT = gql`
  fragment VehiclePerformanceReturnNetIncomeFragment on VehicleFields {
    netIncome: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: n
        type: i
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`
export const VEHICLE_PERFORMANCE_RETURN_NET_APPRECIATION_FRAGMENT = gql`
  fragment VehiclePerformanceReturnNetAppreciationFragment on VehicleFields {
    netAppreciation: performance(
      filters: {
        period: { type: $period }
        currency: u
        dividendReinvestment: g
        fees: n
        type: a
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...VehiclePerformanceReturnsFragment
    }
  }
  ${VEHICLE_PERFORMANCE_RETURNS_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_RETURN_FRAGMENT = gql`
  fragment VehiclePerformanceReturnFragment on Vehicle {
    vehicle {
      id: fundid
      fundid
      name
      vehicleDataCollection {
        performanceNeeded
        vehicleInfoNeeded
      }
    }
    ... on OpenEndedMutualFund {
      vehicle {
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetRetailFragment
        ...VehiclePerformanceReturnNetInstitutionalFragment
        ...VehiclePerformanceReturnNetEqualWeightedFragment
      }
    }
    ... on OpenEndedExchangeTradedFund {
      vehicle {
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnNetMarketTotalFragment
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
      }
    }
    ... on OpenEndedVariableAnnuity {
      vehicle {
        ...VehiclePerformanceReturnNetTotalFragment
      }
    }
    ... on OpenEndedSeparateAccount {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
      }
    }
    ... on OpenEndedSeparateAccountRealAssets {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnGrossIncomeFragment
        ...VehiclePerformanceReturnGrossAppreciationFragment
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnNetIncomeFragment
        ...VehiclePerformanceReturnNetAppreciationFragment
      }
    }
    ... on OpenEndedCollectiveInvestmentFundComposite {
      vehicle {
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetRetailFragment
        ...VehiclePerformanceReturnNetInstitutionalFragment
        ...VehiclePerformanceReturnNetEqualWeightedFragment
      }
    }
    ... on OpenEndedCollectiveInvestmentFund {
      vehicle {
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetRetailFragment
        ...VehiclePerformanceReturnNetInstitutionalFragment
        ...VehiclePerformanceReturnNetEqualWeightedFragment
      }
    }
    ... on OpenEndedPooledVehicle {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
      }
    }
    ... on OpenEndedPooledVehicleRealAssets {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnGrossIncomeFragment
        ...VehiclePerformanceReturnGrossAppreciationFragment
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnNetIncomeFragment
        ...VehiclePerformanceReturnNetAppreciationFragment
      }
    }
    ... on OpenEndedPrivateNonRegisteredHedgeFund {
      vehicle {
        ...VehiclePerformanceReturnNetTotalFragment
      }
    }
    ... on OpenEndedPooledVehiclePrivateCredit {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
      }
    }
    ... on OpenEndedPooledVehiclePrivateEquity {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
      }
    }
    ... on OpenEndedCollectiveInvestmentFundStableValue {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnNetRetailFragment
        ...VehiclePerformanceReturnNetInstitutionalFragment
        ...VehiclePerformanceReturnNetEqualWeightedFragment
      }
    }
    ... on OpenEndedCollectiveInvestmentFundStableValueComposite {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnNetRetailFragment
        ...VehiclePerformanceReturnNetInstitutionalFragment
        ...VehiclePerformanceReturnNetEqualWeightedFragment
      }
    }
    ... on OpenEndedSeparateAccountStableValue {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
      }
    }
    ... on OpenEndedVehicle {
      openEndedVehicle {
        latestAssetsUnderManagement
        historicalMarketValues {
          date
          value
        }
        historicalTotalFundMarketValues {
          date
          value
        }
      }
    }
    ... on ClosedEndedVehicle {
      closedEndedVehicle {
        latestAssetsUnderManagement
        historicalMarketValues {
          date
          value
        }
      }
    }

    ... on OpenEndedCollectiveInvestmentFundRealAssets {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnNetRetailFragment
        ...VehiclePerformanceReturnNetInstitutionalFragment
        ...VehiclePerformanceReturnNetEqualWeightedFragment
      }
    }
    ... on OpenEndedCollectiveInvestmentFundCompositeRealAssets {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnNetRetailFragment
        ...VehiclePerformanceReturnNetInstitutionalFragment
        ...VehiclePerformanceReturnNetEqualWeightedFragment
      }
    }
    ... on ClosedEndedPrivateNonRegisteredRealAssets {
      vehicle {
        ...VehiclePerformanceReturnGrossTotalFragment
        ...VehiclePerformanceReturnGrossIncomeFragment
        ...VehiclePerformanceReturnGrossAppreciationFragment
        ...VehiclePerformanceReturnNetTotalFragment
        ...VehiclePerformanceReturnNetIncomeFragment
        ...VehiclePerformanceReturnNetAppreciationFragment
      }
    }
  }
  ${VEHICLE_PERFORMANCE_RETURN_GROSS_TOTAL_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_NET_TOTAL_MARKET_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_NET_RETAIL_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_NET_INSTITUTIONAL_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_NET_EQUAL_WEIGHTED_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_GROSS_INCOME_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_GROSS_APPRECIATION_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_NET_INCOME_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_NET_APPRECIATION_FRAGMENT}
  ${VEHICLE_PERFORMANCE_RETURN_NET_TOTAL_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_CASHFLOWS_NET_ASSET_FRAGMENT = gql`
  fragment VehiclePerformanceCashflowsNetAssetFragment on CashFlowNetAssetValue {
    date
    amount
    accruedInterests
    currency {
      code
      value
    }
    assetClassAbbreviation
    country {
      code
      value
    }
  }
`
export const VEHICLE_PERFORMANCE_CASHFLOW_ONLY_FRAGMENT = gql`
  fragment VehiclePerformanceCashflowOnlyFragment on CashFlows {
    beginningNetAssetValue {
      ...VehiclePerformanceCashflowsNetAssetFragment
    }
    endingNetAssetValue {
      ...VehiclePerformanceCashflowsNetAssetFragment
    }
    cashFlowTransactionTypes {
      transactionType {
        code
      }
      transactions {
        periodEndDate
        transactionDate
        amount
        currency {
          code
        }
        assetClassAbbreviation {
          code
        }
        country {
          code
        }
        transactionNumber
      }
      netValue
    }
    cashFlowCashActivity {
      periodEndDate
      grossAssetValue
      entryQueue
      exitQueue
      netAssetValue
      grossAssetValueJointVenture
      netAssetValueJointVenture
      cashValue
      expectedCalledCapitalNextQuarter
      expectedCalledCapitalFundLife
      expectedDistributedCapitalNextQuarter
      expectedDistributedCapital
    }
  }
  ${VEHICLE_PERFORMANCE_CASHFLOWS_NET_ASSET_FRAGMENT}
`

export const VEHICLE_PERFORMANCE_CASHFLOW_FRAGMENT = gql`
  fragment VehiclePerformanceCashflowFragment on Vehicle {
    vehicle {
      id: fundid
      cashFlows(date: $date) {
        ...VehiclePerformanceCashflowOnlyFragment
      }
      cashFlowDefaultKeys {
        currency
        country
        assetClassAbbreviation
      }
      product {
        product {
          id
          assetClass {
            id
            parent {
              id
            }
          }
        }
        __typename
        ... on ClosedEndedPrivateCredit {
          closedEnded {
            finalCommitSize
          }
        }
        ... on ClosedEndedPrivateEquity {
          closedEnded {
            finalCommitSize
          }
        }
        ... on ClosedEndedRealAssets {
          closedEnded {
            finalCommitSize
          }
        }
        ... on ClosedEndedHedgeFund {
          closedEnded {
            finalCommitSize
          }
        }
      }
    }
  }
  ${VEHICLE_PERFORMANCE_CASHFLOW_ONLY_FRAGMENT}
`

export const VEHICLE_DETAILS_FRAGMENT = gql`
  fragment vehicleDetailsFragment on Vehicle {
    __typename
    vehicle {
      id: fundid
      graphName @include(if: $viewPrivate)
      name
      category {
        code
        value
      }
      type {
        code
        value
      }
      typeOptions {
        category
        code
        value
      }
      identifiers {
        cusip
        ticker
      }
      capitalStructure
      status {
        code
        value
      }
      inceptionDate
      closeDate
      is529
      privatePlacement
      isRegisteredWithSecuritiesAndExchangeComission
      domicile {
        code
        value
      }
      countryOfIncorporation {
        code
        value
      }
      stateOfIncorporation {
        code
        value
      }
      currency {
        code
        value
      }
      frequencyOfValuation {
        code
        value
      }
      frequencyOfDistribution {
        code
        value
      }
      liquidityOnPurchases {
        code
        value
      }
      liquidityOnSales {
        code
        value
      }
      dividendReinvest
      employeeRetirementIncomeSecurityActTaxExempt
      unitedStatesTaxable
      unitedStatesTaxExempt
      lendSecurities
      offsetGainsWithLosses
    }
    ... on MutualFund {
      mutualFund {
        shareClass {
          code
          value
        }
      }
    }
    ... on OpenEndedVehicle {
      openEndedVehicle {
        latestAssetsUnderManagement
      }
    }
    ... on ClosedEndedVehicle {
      closedEndedVehicle {
        vintageFirstCashFlow
      }
    }
    ... on StableValue {
      stableValue {
        putProvision
      }
    }
    ... on HedgeFundVehicle {
      hedgeFundVehicle {
        subjectTo25PercentLimit
        unrelatedBusinessIncomeTax
        subscriptionPeriod {
          code
          value
        }
        redemptionPeriod {
          code
          value
        }
        lockupPeriod {
          code
          value
        }
        noticePeriod
        initialPayout
        finalPayout
        gateFundProvision
        gateInvestorProvision
        gateProvisionExplanation
      }
    }
  }
`
