import moment from "moment"
import React from "react"
import { FormGroup } from "reactstrap"
import { DATE_API_FORMAT } from "../../../../../helpers/constant"
import { PercentInput } from "../../FeeFormComponents"
import { NumericInputData, OtherFeesGenericProps } from "../FeeRows"

interface DatedFeeRowProps extends OtherFeesGenericProps {
  date: string
  input: NumericInputData<string>
}

export const DatedFeeRow: React.FC<DatedFeeRowProps> = ({
  label,
  editing,
  date,
  input,
}) => {
  return (
    <tr>
      <td>{label}</td>
      <td></td>
      <td>
        <FormGroup>
          <div>
            <PercentInput
              disabled={!editing}
              value={input.value ? +input.value : null}
              onChange={input.onChange}
            />
          </div>
        </FormGroup>
      </td>
      <td>{moment(date, DATE_API_FORMAT).format("MM/DD/YYYY")}</td>
    </tr>
  )
}
