import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import { ReportScheduleColumnDef } from '../../../helpers/columnDefs';
import { MeFragment, ReportScheduleFragment, useMeQuery, useReportScheduleQuery } from '../../../__generated__/graphql';
import { ErrorComponent, LoadingComponent } from '../../Report/Shared/ReportComponent';
import SortableTable from '../../Shared/SortableTable';
import { ConsultantDashboardAggregatedComponentProps, ConsultantDashboardBaseComponent, ConsultantDashboardIndividualComponentProps } from './ConsultantDashboardComponent';


const Stoplight: React.FC<ConsultantDashboardAggregatedComponentProps> = (props) => {
  return (
    <ConsultantDashboardBaseComponent<ReportScheduleFragment>
      {...props}
      expectedTypename={"ReportSchedule"}
      reactComponent={ReportScheduleDisplay}
    />
  )
}

const ReportScheduleDisplay:React.FC<ConsultantDashboardIndividualComponentProps<ReportScheduleFragment>> = (props) => {
  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })

  if(userLoading) return <LoadingComponent name={props.component.name || ""}/>
  if(userError) return <ErrorComponent name={props.component.name || ""} error={userError?.message}/>
  if(userData?.me) return <ReportScheduleDisplayWithUser {...props} user={userData.me} />
  return <ErrorComponent name={props.component.name || ""} error={"No data"}/>
}

interface ReportScheduleDisplayWithUserProps extends ConsultantDashboardIndividualComponentProps<ReportScheduleFragment> {
  user: MeFragment
}

const ReportScheduleDisplayWithUser:React.FC<ReportScheduleDisplayWithUserProps> = (props) => {
  const {component, user, auth, settings} = props
  const name = component.name || ""
  const queryVariables = { userId: user.person?.id || -1 }
  const [search, setSearch] = useState("")
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const getDataPath = useCallback((data) => {
    return data.group;
  }, []);

  const { loading, data, error } = useReportScheduleQuery({
    fetchPolicy: "no-cache",
    variables: queryVariables
  })

  if(loading) return <LoadingComponent name={name}/>
  if(error) return <ErrorComponent name={name} error={error?.message}/>
  let rowData = uniqBy([...(data?.owners || []), ...(data?.consultants || []), ...(data?.backupConsultants || [])], "id").filter((row) => row.active)
  if(rowData.length === 0) return <ErrorComponent name={name} error={"No data"}/>

  const colDef = ReportScheduleColumnDef()
  const onReady = (event:GridReadyEvent) => {
    setGridApi(event.api)
  }
  return (
    <>
      <div className="pane pane-toolbar sticky-top above-picker w-100">
        <Form className="mr-2 pr-3">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
      </div>
      <div className={"pane pane-table p-0 w-100 d-flex flex-grow-1 flex-direction-column view-port-80"}>
        <SortableTable
          loading={loading}
          filterText={search}
          columnDefs={colDef}
          tableData={rowData}
          getDataPath={getDataPath}
          // setSelectedRows={setSelectedRows}
          // onRowSelected={onRowSelected}
          onReady={onReady}
        />
      </div>
    </>
  )
}

export default Stoplight