import React, { ComponentType, ReactNode } from 'react'
import { Breadcrumb, BreadcrumbProps, BreadcrumbsProps } from 'react-breadcrumbs'
import { Route } from 'react-router-dom'

import Auth from '../Auth/Auth'
import Login from '../Pages/Login'
import { MyProvider } from '../Providers'
import { CalendarProvider } from '../Context/CalendarContext'
import { EditButtonProvider } from '../Context/EditButtonContext'
import { TemporaryAlertProvider } from '../Context/TemporaryAlertContext'
import { AgGridProvider } from '../Context/AgGridContext'

// import { appProps } from '../queries/extended/appProps'

interface Props extends BreadcrumbsProps {
  component?: ComponentType<any>
  includeSearch?: boolean
  title: ReactNode
  render?(routeProps: BreadcrumbProps | noDataBreadcrumbProps): React.ReactNode
  path: string
  exact?: boolean
  auth?: Auth
}

type noDataBreadcrumbProps = Omit<BreadcrumbProps, "data">
// type ExcludeDataField<A> = { [K in Exclude<keyof A, "data">]: A[K] }
// type PrivateRouteProps = {
//   auth: Auth
//   path: string
//   exact?: boolean
//   component: ComponentType<appProps | any>
// }

// Create and export the component
export const CrumbRoute: React.FC<Props> = props => {
  const {
    component: Component,
    render,
    auth,
    includeSearch,
    title,
    ...rest
  } = props
  return (
    <Route
      {...rest}
      render={routeProps => {
        let data = {
          title: props.title,
          pathname: routeProps.match.url
        }
        let authorizedPage = (
          <Breadcrumb data={data}>
            {Component ? (
              <Component {...routeProps} {...rest} auth={auth} />
            ) : render ? (
              render(routeProps as BreadcrumbProps | noDataBreadcrumbProps)
            ) : (
              ""
            )}
          </Breadcrumb>
        )
        if (auth) {
          return auth.isAuthenticated() ? (
            <AgGridProvider><MyProvider><CalendarProvider {...routeProps}><EditButtonProvider><TemporaryAlertProvider>{authorizedPage}</TemporaryAlertProvider></EditButtonProvider></CalendarProvider></MyProvider></AgGridProvider>
          ) : (
            <Login auth={auth} />
          )
        } else {
          return authorizedPage
        }
      }}
    />
  )
}
