import { OpenEndedEquityProductOverviewSummaryOverrideConfig } from './OpenEndedEquity'

export const OpenEndedPrivateCreditProductOverviewSummaryOverrideConfig = {
  ...OpenEndedEquityProductOverviewSummaryOverrideConfig,

  product: {
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig.product,
    primaryGeographicRegion: true
  }
}
