import { disableFragmentWarnings, gql } from "@apollo/client"
import { DATA_LOAD_BASIC_FRAGMENT, DATA_LOAD_DATA_ASSETS_FRAGMENT, DATA_LOAD_DATA_CASH_FLOW_FRAGMENT, DATA_LOAD_DATA_FRAGMENT, DATA_LOAD_DETAILS_FRAGMENT, DATA_LOAD_MESSAGE_FRAGMENT, DATA_LOAD_TEMPLATE_FRAGMENT } from "./ImporterFragments"

disableFragmentWarnings()

export const DATA_LOADERS_QUERY = gql`
  query DataLoadersBasic($filters: DataLoadFilters) {
    dataLoads(filters: $filters) {
      ...DataLoadBasicFragment
    }
  }
  ${DATA_LOAD_BASIC_FRAGMENT}
`

// for single loaded file
export const DATA_LOADER_DETAILS_QUERY = gql`
  query DataLoaderDetails($id: Int!) {
    dataLoad(id: $id) {
      ...DataLoadDetailsFragment
    }
  }
  ${DATA_LOAD_DETAILS_FRAGMENT}
`

export const DATA_LOADER_BASIC_QUERY = gql`
  query DataLoaderBasic($id: Int!) {
    dataLoad(id: $id) {
      ...DataLoadBasicFragment
    }
  }
  ${DATA_LOAD_BASIC_FRAGMENT}
`

export const DATA_LOADER_DATA_QUERY = gql`
  query DataLoaderData($id: Int!) {
    dataLoad(id: $id) {
      id
      status {
        code
        value
      }
      template {
        id
      }
      data {
        ...DataLoadDataFragment
      }
    }
  }
  ${DATA_LOAD_DATA_FRAGMENT}
`

export const DATA_LOADER_DATA_CASH_FLOW_QUERY = gql`
  query DataLoaderDataCashFlow($id: Int!) {
    dataLoad(id: $id) {
      id
      status {
        code
        value
      }
      existingRows
      template {
        id
      }
      data {
        ...DataLoadDataCashFlowFragment
      }
    }
  }
  ${DATA_LOAD_DATA_CASH_FLOW_FRAGMENT}
`

export const DATA_LOADER_DATA_ASSETS_QUERY = gql`
  query DataLoaderDataAssets($id: Int!) {
    dataLoad(id: $id) {
      ...DataLoadBasicFragment
      data {
        ...DataLoadDataAssetsFragment
      }
    }
  }
  ${DATA_LOAD_BASIC_FRAGMENT}
  ${DATA_LOAD_DATA_ASSETS_FRAGMENT}
`

export const CREATE_DATA_LOAD_MUTATION = gql`
  mutation CreateDataLoad($input: CreateDataLoadInput!) {
    createDataLoad(input: $input) {
      ...DataLoadBasicFragment
    }
  }
  ${DATA_LOAD_BASIC_FRAGMENT}
`

export const SIGNED_DATA_LOAD_URL_QUERY = gql`
  query GetDataLoadSignedUrl($mimeType: String!) {
    getFileUploadSignedUrl: getDataLoadSignedUrl(mimeType: $mimeType) {
      signedUrl{
        id
        url
      }
      error{
        name
        message
      }
    }
  }
`

export const DATA_LOAD_TEMPLATES_QUERY = gql`
  query GetAllDataLoadTemplates {
    dataLoadTemplates {
      ...DataLoadTemplateFragment
    }
  }
  ${DATA_LOAD_TEMPLATE_FRAGMENT}
`

export const DOWNLOAD_DATA_LOAD_TEMPLATES_QUERY = gql`
  query DownloadAllDataLoadTemplatesUrl {
    dataLoadTemplates {
      id
      assetClass {
        id
        fullName
        parent {
          id
          fullName
        }
      }
      # TODO: lazy download ?
      downloadUrl
      vendor {
        ... on Bank {
          id
        }
      }
    }
  }
`

export const UPLOAD_NEW_DATA_LOAD_VERSION_MUTATION = gql`
  mutation UploadNewDataLoadVersionDataLoad($input: NewDataLoadVersion!) {
    newVersion: uploadNewDataLoadVersion(input: $input) {
      ...DataLoadBasicFragment
      data {
        ...DataLoadMessageFragment
      }
    }
  }
  ${DATA_LOAD_BASIC_FRAGMENT}
  ${DATA_LOAD_MESSAGE_FRAGMENT}
`

export const PUBLISH_DATA_LOAD_MUTATION = gql`
  mutation PublishDataLoad($input: PublishDataLoadInput!) {
    publishDataLoad(input: $input) {
      ...DataLoadBasicFragment
      data {
        ...DataLoadDataFragment
      }
    }
  }
  ${DATA_LOAD_BASIC_FRAGMENT}
  ${DATA_LOAD_DATA_FRAGMENT}
`