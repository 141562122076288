import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { uniqueId } from "lodash"
import numbro from "numbro"
import { useMemo } from "react"
import { UncontrolledTooltip } from "reactstrap"
import { ColumnHeader, DataPointAttribute, DataPointAttributeType, DataPointStatus } from "../../../__generated__/graphql"
import { ShownQuartiles } from "../Components/ManagerPerformance"
import { rankFormat } from "./ReportComponent"


interface ReportDataPointProps {
  dataPoint?: ReportColumnDataFragment
  column?: ColumnHeader
  draftView: boolean
  shownQuartiles?: ShownQuartiles
  emptyValue?: string
}

export const ReportDataPoint: React.FC<ReportDataPointProps> = ({column, dataPoint, draftView, shownQuartiles, emptyValue}) => {
  // if(performanceSource === "NO"){
  //   return<td key={heading.graphqlVariableTarget + performanceType + "NO"}></td>
  // }
  // if((settings.performanceType === ManagerPerformanceType.Net && portfolio?.performanceType?.code ===  ClientPerformanceTypeCode.GROSS) || (settings.performanceType === ManagerPerformanceType.Gross && portfolio?.performanceType?.code ===  ClientPerformanceTypeCode.NET)){
  //   return(<td key={heading.graphqlVariableTarget + performanceType + "-"}>-</td>)
  // }
  // const graphqlVariable = get(heading, "graphqlVariable" + performanceType)
  // const latestInstance = maxBy(get(portfolio, graphqlVariable), (i: Performance) => (moment(i.endDate, DATE_API_FORMAT).valueOf()))
  let tooltip
  let cellClass = ""
  let usedEmptyValue = emptyValue === undefined ? "-" : emptyValue
  const loading = dataPoint?.status === DataPointStatus.LOADING
  const value = dataPoint?.formattedValue
  const rawValue = dataPoint?.rawValue
  // const hideValue = heading.booleanVariable === "fiscalYear" && settings.performancePeriodicity === ManagerPerformancePeriodicity.Quarterly && fiscalMonths % 3 !== 0
  const rankingAttribute = dataPoint?.additionalAttributes?.find((attr) => attr?.type === DataPointAttributeType.PEER_GROUP_RANKING)
  const ranking = rankingAttribute?.intValue
  const tooltipId = useMemo(() => `value-tooltip-${uniqueId()}`, [])

  const inceptionAttribute = dataPoint?.additionalAttributes?.find((attr) => attr?.type === DataPointAttributeType.INCEPTION_DATE_TOOLTIP)
  const inception = inceptionAttribute?.stringValue
  if(inception){
    tooltip = inception
    cellClass += " hover-transition-underline"
  }

  // don't show errors or warnings when not draft view
  if(!draftView){
    cellClass = ""
  } else if(dataPoint?.status === DataPointStatus.ERROR){
    cellClass += " has-error"
  } else if (dataPoint?.status === DataPointStatus.WARNING) {
    cellClass += " has-warning"
  }
  return(
    <td className={classNames("text-right")}>
      {!!tooltip &&
        <UncontrolledTooltip placement='top' target={tooltipId} delay={200} autohide={false}>
          {tooltip}
        </UncontrolledTooltip>
      }
      <div className={classNames("data-cell-inner position-relative",cellClass)} id={tooltipId}>
        {loading && <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />}
        {!loading && dataPoint && (value == null ? usedEmptyValue : value)}
        { rankingAttribute && ranking && shownQuartiles &&
          <QuartileComponent
            shownQuartiles={shownQuartiles}
            attribute={rankingAttribute}
          />
        }
      </div>
    </td>
  )
}

// QUARTILE COMPONENT
interface QuartileDisplayProps {
  quartile: keyof ShownQuartiles
  className?: string
}

export const QuartileDisplay: React.FC<QuartileDisplayProps> = ({quartile, className}) => {
  let usedImage
  if(quartile === "top") usedImage = '/assets/Icon_Quartile_Top.svg'
  if(quartile === "second") usedImage = '/assets/Icon_Quartile_Second.svg'
  if(quartile === "third") usedImage = '/assets/Icon_Quartile_Third.svg'
  if(quartile === "bottom") usedImage = '/assets/Icon_Quartile_Bottom.svg'
  return (
    <div className={classNames("quartile-display", className)}>
      <img src={usedImage} alt="fireSpot"/>
    </div>
  )
}

interface QuartileComponentProps {
  shownQuartiles: ShownQuartiles
  hide?: boolean
  attribute?: DataPointAttribute
}

export const QuartileComponent: React.FC<QuartileComponentProps> =({shownQuartiles, attribute, hide}) => {
  if(hide) return <></>
  const rank = attribute?.intValue
  if(rank){
    let quartile: keyof ShownQuartiles | undefined = undefined
    if(attribute.stringValue === "BOTTOM_QUARTILE"){quartile = "bottom"}
    else if(attribute.stringValue === "THIRD_QUARTILE"){quartile = "third"}
    else if(attribute.stringValue === "SECOND_QUARTILE"){quartile = "second"}
    else {quartile = "top"}

    if(shownQuartiles[quartile]){
      return (
        <div className={classNames("quartile-container", {"show-rankings": shownQuartiles.showRankings})}>
          <QuartileDisplay quartile={quartile} />
          <div className="quartile-percentage ml-1">{numbro(rank).format(rankFormat)}</div>
        </div>
      )
    } else {
      return (
        <div className={classNames("quartile-container", {"show-rankings": shownQuartiles.showRankings})}>
          <div className='d-inline-block' style={{width: 16}} />
          <div className="quartile-percentage ml-1">{numbro(rank).format(rankFormat)}</div>
        </div>
      )
    }
  }
  return <></>
}
