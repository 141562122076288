import classnames from 'classnames'
import React, { EventHandler, useEffect, useState } from 'react'
import { Button, FormGroup, FormText, Input, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import _ from 'lodash'
import { InputTooltip } from './TextFieldInput'

interface TextAreaInputProps {
  idx: string | number
  property: string
  displayName: string
  propertyValue: string
  editMode: boolean
  onChange: (event: any) => void
  wrapperClasses?: string
  labelClasses?: string
  inputClasses?: string
  inputWrapperClasses?: string
  placeholder?: string
  rows?: number
  required?: boolean
  tooltip?: InputTooltip
  charactersLimit?: number
  inputRef?: React.RefObject<HTMLInputElement>
}

export const TextAreaInput: React.FC<TextAreaInputProps> = props => {
  const {
    idx,
    property,
    displayName,
    propertyValue,
    editMode,
    wrapperClasses,
    labelClasses,
    inputWrapperClasses,
    inputClasses,
    placeholder,
    rows = 4,
    charactersLimit,
    tooltip,
    inputRef,
  } = props
  const [expanded, expandBox] = useState(false || editMode)
  const [height, setHeight] = useState(rows * 20)
  const onChange: EventHandler<any> = props.onChange
  const [charactersRemaining, updateCharactersRemaining] = useState(charactersLimit)
  const hasCharactersLimitSetting = !_.isUndefined(charactersLimit)
  const handleChangeWithLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const characters = e.target.value.length
    if (charactersLimit && characters > charactersLimit) {
      return
    }
    if(charactersLimit) {
      updateCharactersRemaining(charactersLimit - characters)
    }
    onChange(e)
  }
  let shortProperty = property.split(".").pop()
  shortProperty = shortProperty ? shortProperty : property
  let required = props.required || false
  const fieldId = `${idx}-${shortProperty}`

  const setFullHeight = () => {
    // Set textAreaInput inside function as does not initilise property when set as part of init
    const textAreaInput = document.getElementById(fieldId)
    if (textAreaInput) {
      const scrollHeight = textAreaInput.scrollHeight
      setHeight(textAreaInput.scrollHeight > 0 ? textAreaInput.scrollHeight : 78)
    }
  }

  const setCollapseHeight = () => {
    const textAreaInput = document.getElementById(fieldId)
    // Set textAreaInput inside function as does not initilise property when set as part of init
    if (textAreaInput) {
      setHeight(rows * 20)
    }
  }

  const toggle = () => {
    if (!expanded) {
      setFullHeight()
    } else {
      setCollapseHeight()
    }
    expandBox(!expanded)
  }

  useEffect(() => {
    if (editMode) {
      if (!expanded) {
        toggle()
      } else {
        expandBox(true)
        setFullHeight()
      }
      // reset to current value when into editMode.
      if(charactersLimit) {
        updateCharactersRemaining(!!charactersLimit? charactersLimit-(propertyValue?.length||0): charactersLimit)
      }
    } else {
      setCollapseHeight()
      expandBox(false)
    }
  }, [editMode])

  return (
    <FormGroup
      className={classnames("form-group row", wrapperClasses)}
      key={idx}
    >
      {displayName !== "" && (
        <Label
          className={classnames(
            "col-sm-12 mt-2 mb-1 textarea-label",
            labelClasses
          )}
          for={`${idx}-${shortProperty}`}
          id={property}
        >
          <div
            className={classnames("d-inline-flex align-items-center", tooltip && "tooltip-icon")}
            id={tooltip ? tooltip.id : ""}
          >
            {displayName}
            {tooltip &&
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                className={"mt-0" }
                size="sm"
                onClick={() => { tooltip.onClick && tooltip.onClick() }}
              />
            }
          </div>
          <div className="border-bottom-line-break mt-2" />
        </Label>
      )}
      <div
        className={classnames(inputWrapperClasses || "", {
          "col-sm-12": !inputWrapperClasses
        })}
      >
        <div
          className={classnames( "exportable-form-input",
            editMode
              ? "expandable-textarea-container editing"
              : "expandable-textarea-container"
          )}
        >
          <Input
            id={fieldId}
            bsSize="sm"
            name={property}
            label={property}
            type="textarea"
            value={propertyValue || ""}
            onChange={hasCharactersLimitSetting? handleChangeWithLimit: onChange}
            disabled={!editMode}
            className={classnames(inputClasses)}
            style={{ height: height }}
            placeholder={editMode ? placeholder : undefined}
            innerRef={inputRef}
            required={required}
          ></Input>
          {!!editMode && hasCharactersLimitSetting && (
            <FormText>{charactersRemaining} characters remaining</FormText>
          )}
          <div className="expand-link">
            <Button color="link" onClick={() => toggle()}>
              <FontAwesomeIcon
                icon={expanded ? "chevron-up" : "chevron-down"}
                size="sm"
              />
              <span className="pl-2 expand-text">{expanded ? "Collapse" : "Expand"}</span>
            </Button>
          </div>
        </div>
      </div>
    </FormGroup>
  )
}
