import React from "react"
import classnames from "classnames"
import { Form, Col, FormGroup, Label, CustomInput } from "reactstrap"

interface FeeToggleProps {
  onChange(input: boolean): void
  toggle: boolean
  displayOptions: { title?: string; default: string; alternate: string }
}

export const FeeToggle: React.FC<FeeToggleProps> = ({
  onChange,
  toggle,
  displayOptions,
}) => {
  return (
    <Form>
      <Col lg={10}>
        <FormGroup row name={`fee-structure`}>
          {displayOptions.title && (
            <Label sm={3} htmlFor={`fee-structure`}>
              {displayOptions.title}:
            </Label>
          )}
          <div
            className={classnames(
              "d-flex align-items-center justify-content-between",
              "col-sm-9"
            )}
          >
            <CustomInput
              id="fee-structure-flat-fee"
              className="boolean-radio custom-radio custom-radio-sm custom-control custom-control-inline"
              bsSize="sm"
              type="radio"
              value="true"
              checked={toggle}
              onChange={() => {
                onChange(!toggle)
              }}
              label={displayOptions.default}
              inline
            />
            <CustomInput
              id="fee-structure-tiered-pricing"
              className="boolean-radio custom-radio custom-radio-sm custom-control custom-control-inline"
              bsSize="sm"
              type="radio"
              value="false"
              checked={!toggle}
              onChange={() => {
                onChange(!toggle)
              }}
              label={displayOptions.alternate}
              inline
            />
          </div>
        </FormGroup>
      </Col>
    </Form>
  )
}
