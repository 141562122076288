import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

interface chartContainerProps {
  options: any
  hasData: boolean
  height: number
  modalName?: string
}

interface defaultChartModalProps {
  options: any
  modalName?: string
}

export const ChartContainer: React.FC<chartContainerProps> = ({ children, options, hasData, height, modalName }) => {
  const [modalOpen, setModalOpen] = useState(false)
  if(!hasData){
    return (
      <div className='d-flex flex-column w-100 align-items-center justify-content-center background-gray-10' style={{ height: height ? height : 200 }}>
        <FontAwesomeIcon
          icon={["far", "chart-line"]}
          size="lg"
          className="m-0"
        />
        <div className="text-gray-50">
          No data available
        </div>
      </div>
    )
  }

  return (
    <div onClick={() => setModalOpen(!modalOpen)}>
      <HighchartsReact
        options={options}
        highcharts={Highcharts}
        containerProps = {{ style: {height: `${height ? height : 200}px`} }}
      />
      <Modal size="xl" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
        {children ? children : <DefaultChartModal options={options} modalName={modalName} />}
      </Modal>
    </div>
  )
}

const DefaultChartModal: React.FC<defaultChartModalProps> = ({options, modalName}) => {
  return (
    <>
      <ModalHeader className="chart-modal-header">
        {modalName || "Chart"}
      </ModalHeader>
      <ModalBody className="chart-modal-body">
        <HighchartsReact
          options={options}
          highcharts={Highcharts}
          containerProps = {{ style: {height: `500px`} }}
        />
      </ModalBody>
    </>
  )
}