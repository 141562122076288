import React from "react"
import { CustomBooleanInput } from "."
import { OtherFeesGenericProps, OtherInputData } from "../FeeRows"

interface BooleanFeeRowProps extends OtherFeesGenericProps {
  boolInput: OtherInputData<boolean>
  id: string
}

export const BooleanFeeRow: React.FC<BooleanFeeRowProps> = ({
  label,
  editing,
  boolInput,
  id,
}) => {
  let displayValue = ""
  if (!editing) {
    if (boolInput.value === true) displayValue = "Yes"
    if (boolInput.value === false) displayValue = "No"
  }
  return (
    <tr>
      <td>{label}</td>
      <td>
        {editing && (
          <CustomBooleanInput
            id={id}
            value={boolInput.value}
            onChange={boolInput.onChange}
          />
        )}
      </td>
      <td>{displayValue}</td>
      <td></td>
    </tr>
  )
}
