import {
  GridOptions,
  GridReadyEvent,
  ICombinedSimpleModel,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  NumberFilterModel,
  SetFilterModel,
  TextFilterModel,
} from "@ag-grid-community/core"
import { Maybe, SortDirection, UserSortField } from "../../../../__generated__/graphql"
import { SortModelDirection } from "../../../ResearchProducts/FilterViewModal"

const FilterModelFieldMapping: {[field: string]: string} =  {
  "roles": "roles",
  "names": "names",
  "emails": "emails",
  "firms": "firms"
}

/** convert FilterModel to apiFilterField[] */
const convertFilterModel = (props: Maybe<{ [key: string]: any}>) => {
  if (!props) return null
  let fields = Object.keys(props)
  if(fields?.length === 0) return null
  let results: any[] = fields.reduce((acc, field) => {
    let settings = props[field]
    let userFilterField: string = FilterModelFieldMapping[field]
    if(!settings || !userFilterField) return acc
    let { filterType, operator, type } = settings
    let {values} = settings as SetFilterModel
    let {filter: value, filterTo: toValue} = settings as NumberFilterModel
    let {condition1, condition2} = settings as ICombinedSimpleModel<NumberFilterModel>
    let {filter: textValue} = settings as TextFilterModel
    let shortTextValue = textValue && textValue?.length <=2
    switch (settings.filterType) {
      case "set":
        acc.push({ [userFilterField]: values })
        break
      case "number":
        // The only use case for number here is for firm id. Only equals with OR is supported.
        if (operator === "AND") {
          // and suppressed now
        } else if (operator === "OR") {
          // condition1 & condition2 are used
          acc.push({ [userFilterField]: [condition1.filter, condition2.filter]})
        } else {
          // equals, value is number and used, no condition1 or condition2
          acc.push({ [userFilterField]: [value]})
        }
        break
      case "text":
        switch (type) {
          case "contains":
            acc.push({ [userFilterField]: `${shortTextValue? "": "*"}${textValue}*` })
            break
          case "equals":
            acc.push({ [userFilterField]: `${textValue || ""}*` })
            break
          case "startsWith":
            acc.push({ [userFilterField]: `${textValue || ""}*` })
            break
          case "endsWith":
            acc.push({ [userFilterField]: `${shortTextValue? "": "*"}${textValue || ""}` })
            break
          case "sort":
            // Not support, should be handled by sort model.
            break
          default:
            break
        }
        break
      default:
        console.log(`filter type of ${filterType} is not supported.`)
        break
    }
    return acc
  }, [] as any[])
  let mergedResult = results.reduce((acc, el) => {
    let key = Object.keys(el)[0]
    if(acc[key]) {
      acc[key] = [...acc[key], ...el[key]]
    }else {
      acc[key] = el[key]
    }
    return acc
  }, {} as any)
  return mergedResult
}

const mergeDefaultFilters = (tableFilters: any, defaultFilters: any) => {
  if(tableFilters && defaultFilters) {
    return {...defaultFilters, ...tableFilters}
  }else if (tableFilters) {
    return tableFilters
  }else {
    return defaultFilters
  }
}

const convertSortStateToSavedFilterState = (state: { sort: SortModelDirection; colId: string }[]) => {
  if (!state) return null
  let controls: any[] = state.reduce((acc, el, idx) => {
    let { sort, colId: field } = el
    if(field in UserSortField) {
      acc.push({
        field: field,
        direction: sort.toUpperCase() as SortDirection,
      })
    }
    return acc
  }, [] as any[])
  return controls
}

// variables: {filters: {controls, limit}, options, viewOpinion, viewClientExposure}
const mergeVariables = (variables: any, tableFilters: any[], request: IServerSideGetRowsRequest) => {
  const { startRow, endRow, filterModel, sortModel } = request
  const sortControls = convertSortStateToSavedFilterState(sortModel) || []
  let { filters, ...rest } = variables
  let mergedControls = mergeDefaultFilters(tableFilters, (filters || [] as any[]))
  // TODO what to do if  >=1000, not available in api.
  let mergedFilters = {
    // only one sort allowed.
    sort: sortControls[0] || null,
    ...mergedControls,
    offset: startRow? startRow/100: 0,
    limit: startRow &&  startRow >= 1000 ? 0: filters?.limit,
  }
  return { filters: mergedFilters, ...rest }
}

export const createManagerUsersDatasource: (
  query: (tVariables: any) => any,
  variables: any,
  gridOptions?: GridOptions,
  gridReadyEvent?: GridReadyEvent,
) => IServerSideDatasource = (query, variables, gridOptions, gridReadyEvent) => {
  return {
    getRows: (params: IServerSideGetRowsParams, options: GridOptions | undefined = gridOptions, event: GridReadyEvent| undefined = gridReadyEvent) => {
      const { startRow, endRow, filterModel, sortModel } = params.request
      event?.api?.showLoadingOverlay()
      const filters = convertFilterModel(filterModel) || []
      let mergedVariables = mergeVariables(variables, filters, params.request)
      query(mergedVariables)
        .then((response: any) => {
          const data = response?.data?.users || []
          let {users: rows, total: rowCount} = data
          if (!rowCount) {
            // fix for no rows overlay not showing on first empty rows.
            setTimeout(() => {
              event?.api?.showNoRowsOverlay()
            }, 1000)
            params.success({ rowData: rows, rowCount })
          } else {
            event?.api?.hideOverlay()
            params.success({ rowData: rows, rowCount })
          }
        })
        .catch((err: any) => {
          console.error({ err })
          event?.api?.showNoRowsOverlay()
          params.fail()
        })
    },
  }
}
