import Highcharts from "./highcharts.js"
import HighchartsMore from "./highcharts-more.js"
HighchartsMore(Highcharts);

export const periodSeries = [
  {
    name: "10th Percentile",
    color: "#E1E1E1",
    data: [
      [0.0738, 0.0635],
      [-0.0624, -0.0916],
      [0.0708, 0.0612],
      [0.0729, 0.0654],
      [0.0847, 0.0818],
      [0.0862, 0.0818],
    ],
  },
  {
    name: "25th Percentile",
    color: "#E1E1E1",
    data: [
      [0.0635, 0.0551],
      [-0.0916, -0.1121],
      [0.0612, 0.0522],
      [0.0654, 0.0589],
      [0.0818, 0.0763],
      [0.0818, 0.0753],
    ],
  },
  {
    name: "75th Percentile",
    color: "#E1E1E1",
    data: [
      [0.0551, 0.0469],
      [-0.1121, -0.1308],
      [0.0522, 0.0366],
      [0.0589, 0.0476],
      [0.0763, 0.0681],
      [0.0753, 0.0681],
    ],
  },
  {
    name: "90th Percentile",
    color: "#E1E1E1",
    data: [
      [0.0469, 0.0391],
      [-0.1308, -0.144],
      [0.0366, 0.0282],
      [0.0476, 0.0395],
      [0.0681, 0.0615],
      [0.0681, 0.0599],
    ],
  },
  {
    name: "Portfolio Return",
    data: [0.0313, -0.0476, 0.081, 0.0762, 0.0862, 0.0841],
    type: "scatter",
    pointPlacement: 0.2,
    marker: {
      symbol: "circle",
    },
    color: "#00649A",
  },
  {
    name: "Benchmark Return",
    data: [0.0514, -0.0905, 0.0528, 0.0607, 0.0738, 0.0722],
    color: "#669966",
    type: "scatter",
    pointPlacement: -0.2,
    marker: {
      symbol: "triangle",
    },
  },
]

export const scatterSeries = {
  "Last Quarter": [
    {
      name: "Members",
      data: [
        [0.0, 8.23],
        [0.0, 8.18],
        [0.0, 7.99],
        [0.0, 7.99],
        [0.0, 7.56],
        [0.0, 7.47],
        [0.0, 7.41],
        [0.0, 7.31],
        [0.0, 7.19],
        [0.0, 7.11],
        [0.0, 7.01],
        [0.0, 6.66],
        [0.0, 6.63],
        [0.0, 6.61],
        [0.0, 6.53],
        [0.0, 6.46],
        [0.0, 6.37],
        [0.0, 6.36],
        [0.0, 6.32],
        [0.0, 6.26],
        [0.0, 6.21],
        [0.0, 6.14],
        [0.0, 6.12],
        [0.0, 6.1],
        [0.0, 6.1],
        [0.0, 6.01],
        [0.0, 6.01],
        [0.0, 5.85],
        [0.0, 5.82],
        [0.0, 5.78],
        [0.0, 5.74],
        [0.0, 5.72],
        [0.0, 5.67],
        [0.0, 5.66],
        [0.0, 5.58],
        [0.0, 5.53],
        [0.0, 5.48],
        [0.0, 5.42],
        [0.0, 5.42],
        [0.0, 5.35],
        [0.0, 5.29],
        [0.0, 5.24],
        [0.0, 5.23],
        [0.0, 5.2],
        [0.0, 5.2],
        [0.0, 5.03],
        [0.0, 5.0],
        [0.0, 4.92],
        [0.0, 4.89],
        [0.0, 4.84],
        [0.0, 4.82],
        [0.0, 4.79],
        [0.0, 4.79],
        [0.0, 4.79],
        [0.0, 4.65],
        [0.0, 4.61],
        [0.0, 4.6],
        [0.0, 4.55],
        [0.0, 4.49],
        [0.0, 4.38],
        [0.0, 4.31],
        [0.0, 4.19],
        [0.0, 4.17],
        [0.0, 4.15],
        [0.0, 3.99],
        [0.0, 3.88],
        [0.0, 3.88],
        [0.0, 3.52],
        [0.0, 3.5],
        [0.0, 2.96],
        [0.0, 2.91],
        [0.0, 2.15],
      ],
      marker: {
        symbol: "square",
      },
      color: "#f28015",
    },
    {
      name: "Benchmark",
      data: [[0.0, 5.14]],
      color: "#669966",
      marker: {
        symbol: "triangle",
      },
    },
    {
      name: "Portfolio",
      data: [[0.0, 3.13]],
      marker: {
        symbol: "circle",
      },
      color: "#00649A",
    },
  ],
  "Last Year": [
    {
      name: "Members",
      data: [
        [18.19, -17.21],
        [17.46, -16.36],
        [16.45, -14.52],
        [16.24, -14.82],
        [16.07, -12.85],
        [15.7, -11.7],
        [15.6, -14.53],
        [15.33, -13.58],
        [14.86, -13.0],
        [14.69, -12.77],
        [14.43, -12.87],
        [14.36, -14.12],
        [14.01, -13.11],
        [14.0, -13.9],
        [13.93, -13.43],
        [13.72, -11.13],
        [13.55, -12.52],
        [13.45, -12.94],
        [13.39, -13.13],
        [13.33, -10.79],
        [13.25, -12.08],
        [13.22, -12.94],
        [13.15, -13.62],
        [13.01, -16.66],
        [13.01, -11.15],
        [13.0, -11.68],
        [12.9, -11.5],
        [12.88, -11.45],
        [12.75, -13.73],
        [12.63, -10.17],
        [12.61, -11.26],
        [12.33, -11.48],
        [12.25, -14.05],
        [12.18, -9.53],
        [12.16, -10.88],
        [12.16, -13.41],
        [12.12, -17.22],
        [11.89, -8.19],
        [11.77, -11.76],
        [11.51, -9.15],
        [11.49, -7.91],
        [11.48, -9.2],
        [11.43, -12.34],
        [11.42, -9.7],
        [11.36, -11.78],
        [10.98, -9.9],
        [10.97, -11.41],
        [10.96, -6.24],
        [10.9, -9.83],
        [10.89, -9.8],
        [10.85, -10.52],
        [10.71, -9.12],
        [10.49, -10.83],
        [10.25, -8.15],
        [10.1, -9.48],
        [9.88, -7.26],
        [9.83, -5.17],
        [9.82, -10.91],
        [9.81, -8.59],
        [9.78, -6.26],
        [9.43, -7.54],
        [9.25, -7.83],
        [9.12, -10.06],
        [9.02, -13.92],
        [8.98, -7.59],
        [8.63, -11.11],
        [8.49, -4.26],
        [8.41, -4.89],
        [8.09, -9.7],
        [8.01, -5.89],
        [7.8, -4.72],
        [5.25, -4.35],
      ],
      marker: {
        symbol: "square",
      },
      color: "#f28015",
    },
    {
      name: "Benchmark",
      data: [[10.89, -9.05]],
      color: "#669966",
      marker: {
        symbol: "triangle",
      },
    },
    {
      name: "Portfolio",
      data: [[7.16, -4.76]],
      marker: {
        symbol: "circle",
      },
      color: "#00649A",
    },
  ],
  "Last 3 Years": [
    {
      name: "Members",
      data: [
        [21.25, 3.9],
        [19.69, 4.75],
        [19.58, 4.92],
        [18.39, 4.78],
        [18.26, 5.41],
        [18.22, 4.44],
        [18.1, 2.75],
        [18.04, 2.82],
        [17.3, 4.61],
        [17.01, 1.6],
        [17.0, 5.44],
        [16.98, 6.84],
        [16.91, 4.97],
        [16.85, 3.39],
        [16.82, 5.22],
        [16.73, 7.14],
        [16.73, 5.45],
        [16.57, 3.15],
        [16.54, 3.49],
        [16.42, 3.02],
        [16.33, 4.99],
        [16.21, 6.05],
        [16.2, 3.92],
        [16.19, 5.0],
        [15.97, 3.52],
        [15.96, 5.72],
        [15.75, 4.27],
        [15.74, 2.03],
        [15.65, 5.0],
        [15.56, 2.35],
        [15.42, 5.47],
        [15.29, 5.32],
        [15.16, 5.83],
        [15.03, 5.69],
        [15.02, 3.7],
        [14.87, 5.45],
        [14.81, 5.57],
        [14.71, 3.63],
        [14.58, 3.85],
        [14.47, 3.86],
        [14.45, 6.67],
        [14.41, 5.99],
        [14.4, 3.66],
        [14.23, 6.47],
        [14.23, 3.16],
        [14.22, 6.48],
        [14.09, 5.92],
        [13.99, 4.95],
        [13.98, 6.33],
        [13.91, 4.93],
        [13.89, 5.57],
        [13.88, 6.56],
        [13.88, 6.12],
        [13.75, 7.15],
        [13.72, 3.17],
        [13.61, 5.89],
        [13.43, 5.74],
        [13.15, 6.33],
        [12.92, 6.58],
        [12.75, 5.58],
        [12.41, 7.71],
        [12.35, 8.2],
        [12.27, 7.78],
        [11.85, 6.81],
        [11.16, 7.97],
        [10.86, 2.83],
        [10.26, 10.7],
        [10.07, 6.73],
        [9.86, 3.37],
        [8.37, 0.8],
        [8.11, -1.48],
      ],
      marker: {
        symbol: "square",
      },
      color: "#f28015",
    },
    {
      name: "Benchmark",
      data: [[12.61, 5.28]],
      color: "#669966",
      marker: {
        symbol: "triangle",
      },
    },
    {
      name: "Portfolio",
      data: [[12.52, 8.1]],
      marker: {
        symbol: "circle",
      },
      color: "#00649A",
    },
  ],
  "Last 5 Years": [
    {
      name: "Members",
      data: [
        [18.15, 5.11],
        [16.84, 5.65],
        [16.56, 6.24],
        [15.67, 5.87],
        [15.54, 6.08],
        [15.35, 5.09],
        [15.35, 4.25],
        [15.11, 3.69],
        [14.74, 5.85],
        [14.39, 3.67],
        [14.19, 7.41],
        [14.14, 4.63],
        [14.13, 6.88],
        [14.12, 4.24],
        [14.07, 6.26],
        [14.04, 6.33],
        [14.03, 5.07],
        [13.96, 4.35],
        [13.95, 5.19],
        [13.9, 4.77],
        [13.75, 5.58],
        [13.67, 5.99],
        [13.62, 5.92],
        [13.6, 4.52],
        [13.58, 4.52],
        [13.4, 6.6],
        [13.23, 5.77],
        [13.13, 4.84],
        [13.07, 3.83],
        [12.95, 6.14],
        [12.94, 6.32],
        [12.93, 5.31],
        [12.85, 3.6],
        [12.83, 5.43],
        [12.68, 6.57],
        [12.46, 5.95],
        [12.38, 5.73],
        [12.33, 4.42],
        [12.24, 6.23],
        [12.18, 4.83],
        [12.09, 5.63],
        [12.03, 4.77],
        [12.03, 6.19],
        [12.02, 6.15],
        [12.02, 7.31],
        [11.99, 6.84],
        [11.97, 6.85],
        [11.96, 6.76],
        [11.91, 4.16],
        [11.67, 5.85],
        [11.66, 7.43],
        [11.62, 6.53],
        [11.51, 6.37],
        [11.5, 6.28],
        [11.35, 3.93],
        [11.16, 6.81],
        [11.14, 6.11],
        [10.81, 6.74],
        [10.56, 7.13],
        [10.51, 8.03],
        [10.49, 6.13],
        [10.39, 7.31],
        [9.89, 6.92],
        [9.34, 7.64],
        [9.33, 4.37],
        [8.44, 4.73],
        [8.26, 10.01],
        [8.14, 6.27],
        [7.02, 2.63],
        [6.8, 1.08],
      ],
      marker: {
        symbol: "square",
      },
      color: "#f28015",
    },
    {
      name: "Benchmark",
      data: [[10.69, 6.07]],
      color: "#669966",
      marker: {
        symbol: "triangle",
      },
    },
    {
      name: "Portfolio",
      data: [[10.21, 7.62]],
      marker: {
        symbol: "circle",
      },
      color: "#00649A",
    },
  ],
  "Last 7 Years": [
    {
      name: "Members",
      data: [
        [15.42, 7.41],
        [14.24, 7.63],
        [14.01, 8.27],
        [13.32, 8.08],
        [13.18, 7.74],
        [13.07, 7.14],
        [13.06, 6.49],
        [12.87, 6.25],
        [12.56, 7.85],
        [12.31, 6.15],
        [12.1, 6.54],
        [12.05, 6.83],
        [12.03, 7.19],
        [12.02, 9.08],
        [11.98, 7.38],
        [11.96, 8.08],
        [11.95, 8.05],
        [11.94, 8.26],
        [11.91, 6.41],
        [11.82, 6.62],
        [11.71, 7.58],
        [11.66, 7.71],
        [11.63, 6.9],
        [11.61, 7.53],
        [11.57, 6.36],
        [11.38, 8.34],
        [11.3, 6.88],
        [11.27, 7.63],
        [11.18, 5.74],
        [11.0, 8.03],
        [10.99, 7.74],
        [10.99, 5.78],
        [10.98, 7.06],
        [10.9, 6.98],
        [10.79, 8.19],
        [10.55, 7.51],
        [10.55, 6.31],
        [10.52, 7.28],
        [10.45, 7.95],
        [10.43, 6.96],
        [10.3, 6.74],
        [10.3, 7.22],
        [10.27, 7.87],
        [10.22, 7.62],
        [10.21, 6.09],
        [10.2, 8.4],
        [10.19, 8.47],
        [10.18, 8.17],
        [10.17, 8.17],
        [9.96, 7.84],
        [9.91, 8.71],
        [9.9, 8.34],
        [9.89, 6.03],
        [9.88, 8.2],
        [9.81, 7.89],
        [9.53, 8.27],
        [9.44, 6.98],
        [9.31, 8.43],
        [8.99, 7.87],
        [8.99, 7.89],
        [8.98, 9.3],
        [8.87, 8.71],
        [8.47, 8.31],
        [8.14, 6.18],
        [7.96, 8.68],
        [7.32, 6.33],
        [7.07, 10.08],
        [7.05, 7.83],
        [6.09, 4.26],
        [6.0, 2.2],
      ],
      marker: {
        symbol: "square",
      },
      color: "#f28015",
    },
    {
      name: "Benchmark",
      data: [[9.08, 7.38]],
      color: "#669966",
      marker: {
        symbol: "triangle",
      },
    },
    {
      name: "Portfolio",
      data: [[8.65, 8.62]],
      marker: {
        symbol: "circle",
      },
      color: "#00649A",
    },
  ],
  "Last 10 Years": [
    {
      name: "Members",
      data: [
        [13.63, 7.96],
        [12.63, 7.86],
        [12.3, 9.09],
        [11.83, 8.69],
        [11.63, 7.96],
        [11.5, 7.5],
        [11.46, 6.79],
        [11.4, 7.21],
        [11.14, 8.0],
        [10.88, 6.19],
        [10.87, 7.41],
        [10.74, 6.93],
        [10.67, 6.96],
        [10.58, 7.26],
        [10.56, 9.41],
        [10.55, 8.25],
        [10.51, 8.22],
        [10.49, 7.07],
        [10.47, 8.63],
        [10.43, 7.92],
        [10.39, 6.78],
        [10.27, 7.89],
        [10.25, 6.19],
        [10.18, 6.75],
        [10.18, 6.87],
        [10.08, 8.32],
        [9.95, 6.19],
        [9.94, 6.5],
        [9.84, 7.36],
        [9.79, 8.09],
        [9.73, 5.7],
        [9.72, 7.52],
        [9.71, 7.32],
        [9.68, 7.54],
        [9.49, 7.58],
        [9.32, 6.81],
        [9.22, 7.78],
        [9.21, 7.91],
        [9.15, 6.96],
        [9.13, 8.18],
        [9.13, 6.51],
        [9.12, 8.18],
        [9.1, 5.83],
        [9.07, 7.89],
        [9.05, 5.05],
        [9.02, 8.04],
        [9.0, 8.59],
        [9.0, 6.26],
        [8.93, 8.25],
        [8.92, 7.22],
        [8.87, 8.21],
        [8.83, 7.53],
        [8.83, 7.73],
        [8.76, 8.82],
        [8.59, 5.97],
        [8.58, 7.89],
        [8.51, 7.9],
        [8.25, 8.3],
        [8.13, 6.96],
        [8.04, 9.13],
        [8.02, 7.39],
        [7.92, 8.21],
        [7.66, 7.18],
        [7.3, 5.55],
        [7.05, 8.49],
        [6.64, 6.47],
        [6.43, 9.59],
        [6.37, 7.35],
        [5.28, 4.15],
        [5.26, 2.26],
      ],
      marker: {
        symbol: "square",
      },
      color: "#f28015",
    },
    {
      name: "Benchmark",
      data: [[8.0, 7.22]],
      color: "#669966",
      marker: {
        symbol: "triangle",
      },
    },
    {
      name: "Portfolio",
      data: [[7.56, 8.41]],
      marker: {
        symbol: "circle",
      },
      color: "#00649A",
    },
  ],
}

const TestGraph = () => {
  document.addEventListener("DOMContentLoaded", function () {
    let currentSeries = "Last 5 Years"
    const medians = {
      "Last Quarter": {
        return: 5.505,
        risk: 0,
      },
      "Last Year": {
        return: -11.205,
        risk: 12.14,
      },
      "Last 3 Years": {
        return: 5.22,
        risk: 14.87,
      },
      "Last 5 Years": {
        return: 5.895,
        risk: 12.57,
      },
      "Last 7 Years": {
        return: 7.63,
        risk: 10.67,
      },
      "Last 10 Years": {
        return: 7.525,
        risk: 9.405,
      },
    }
    const tooltipMap = {
      portfolio: [95, 4, 3, 4, 9, 13],
      benchmark: [62, 23, 49, 45, 59, 62],
    }
    function getSymbol(symbolName) {
      let symbol = ""
      switch (symbolName) {
        case "circle":
          symbol = "●"
          break
        case "diamond":
          symbol = "♦"
          break
        case "square":
          symbol = "■"
          break
        case "triangle":
          symbol = "▲"
          break
        case "triangle-down":
          symbol = "▼"
          break
      }
      return symbol
    }
    function getTableLegendCoords(category, seriesName) {
      let x = null,
        y = null
      switch (category) {
        case "Last Quarter":
          x = 1
          break
        case "Last Year":
          x = 2
          break
        case "Last 3 Years":
          x = 3
          break
        case "Last 5 Years":
          x = 4
          break
        case "Last 7 Years":
          x = 5
          break
        case "Last 10 Years":
          x = 6
          break
      }
      switch (seriesName) {
        case "10th Percentile":
          y = 0
          break
        case "25th Percentile":
          y = 1
          break
        case "75th Percentile":
          y = 2
          break
        case "90th Percentile":
          y = 3
          break
        case "Portfolio Return":
          y = 4
          break
        case "Benchmark Return":
          y = 5
          break
      }
      return { x, y }
    }
    Highcharts.chart("floatingBar", {
      chart: {
        zoomType: "x",
        type: "columnrange",
        plotBorderWidth: 1,
        events: {
          load: function () {
            const chart = this
            const series = chart.series
            const table = document.getElementById("floatingBarLegend")
            series.forEach((series) => {
              const tr = document.createElement("tr")
              const td = document.createElement("td")
              const color = document.createElement("span")
              color.className = "legend-color"
              color.style.backgroundColor = series.color
              td.appendChild(color)
              const name = document.createElement("span")
              name.innerHTML = series.userOptions.name
              if (series.userOptions.marker?.symbol) {
                const symbol = getSymbol(series.userOptions.marker.symbol)
                const span = document.createElement("span")
                span.style.color = series.userOptions.color
                span.innerHTML = ` ${symbol}`
                name.appendChild(span)
              }
              td.addEventListener("mouseover", function () {
                chart.series.forEach((subSeries) => {
                  if (series.index === subSeries.index) {
                    subSeries.setState("hover")
                  } else subSeries.setState("inactive")
                })
              })
              td.addEventListener("mouseout", function () {
                chart.series.forEach((subSeries) => {
                  subSeries.setState("normal")
                })
              })
              td.appendChild(name)
              tr.appendChild(td)
              series.data.forEach((item) => {
                const td = document.createElement("td")
                td.style.width = "155px"
                td.style.textAlign = "center"
                let value = (item.y * 100).toFixed(2)
                if (item.y < 0) {
                  value = `(${Math.abs(value)})`
                  td.style.color = "#ff0000"
                }
                td.innerHTML = value
                td.addEventListener("mouseover", function () {
                  const index = this.cellIndex - 1
                  series.data[index].setState("hover")
                  chart.tooltip.refresh(series.data[index])
                })
                td.addEventListener("mouseout", function () {
                  const index = this.cellIndex - 1
                  series.data[index].setState("")
                  chart.tooltip.hide()
                })
                tr.appendChild(td)
              })
              table.appendChild(tr)
            })
          },
        },
      },
      title: {
        text: "Performance vs Style Group Trailing",
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [
          "Last Quarter",
          "Last Year",
          "Last 3 Years",
          "Last 5 Years",
          "Last 7 Years",
          "Last 10 Years",
        ],
        gridLineWidth: 1,
      },
      yAxis: {
        tickWidth: 1,
        tickLength: 5,
        gridLineWidth: 0,
        lineWidth: 1,
        plotLines: [
          {
            width: 1,
            value: 0,
          },
        ],
        title: null,
        labels: {
          formatter: function () {
            return this.value * 100 + "%"
          },
        },
      },
      tooltip: {
        formatter: function () {
          const symbol = getSymbol(this.point.graphic.symbolName)
          const value = this.point.high
            ? `From ${(this.point.high * 100).toFixed(2)}%
            To ${(this.point.low * 100).toFixed(2)}%`
            : (this.y * 100).toFixed(2) + "%"
          return `<span style="color:${this.point.color}">${symbol}</span> ${this.point.series.name}: <b>${value}</b><br/>`
        },
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: function () {
                if (currentSeries !== this.category) {
                  currentSeries = this.category
                  scatter.series.forEach((_, i) => {
                    scatter.series[i].setData(
                      scatterSeries[this.category][i].data,
                      true
                    )
                  })
                  scatter.yAxis[0].update({
                    plotLines: [
                      {
                        value: medians[this.category]["return"],
                        color: "black",
                        width: 2,
                      },
                    ],
                  })
                  scatter.xAxis[0].update({
                    plotLines: [
                      {
                        value: medians[this.category]["risk"],
                        color: "black",
                        width: 2,
                      },
                    ],
                  })
                  scatter.title.update({
                    text: `${this.category} Annualized Risk vs Return`,
                  })
                }
                const { x, y } = getTableLegendCoords(
                  this.category,
                  this.series.name
                )
                if (x !== null && y !== null) {
                  const table = document.getElementById("floatingBarLegend")
                  const tr = table.querySelectorAll("tr")
                  const td = tr[y].querySelectorAll("td")
                  td[x].classList.add("hover")
                }
              },
              mouseOut: function () {
                const { x, y } = getTableLegendCoords(
                  this.category,
                  this.series.name
                )
                if (x !== null && y !== null) {
                  const table = document.getElementById("floatingBarLegend")
                  const tr = table.querySelectorAll("tr")
                  const td = tr[y].querySelectorAll("td")
                  td[x].classList.remove("hover")
                }
              },
            },
          },
        },
        columnrange: {
          grouping: false,
          borderWidth: 1,
          borderColor: "#A7A7A7",
        },
        scatter: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              let rank = null
              if (this.point.series.name === "Portfolio Return") {
                rank = tooltipMap.portfolio[this.point.index]
                // className = "portfolio-rank-label"
              }
              if (this.point.series.name === "Benchmark Return") {
                rank = tooltipMap.benchmark[this.point.index]
                // className = "benchmark-rank-label"
              }
              if (rank)
                return `<span style="color:${this.point.color}">(${rank})</span>`
              else return null
            },
          },
        },
      },
      series: periodSeries,
    })

    const scatter = Highcharts.chart("scatter", {
      chart: {
        zoomType: "xy",
        type: "scatter",
        plotBorderWidth: 1,
        plotBackgroundColor: "#EEEEEE",
      },
      title: {
        text: "Last 5 Years Annualized Risk vs Return",
      },
      yAxis: {
        title: { text: "Returns" },
        gridLineWidth: 0,
        plotLines: [
          {
            value: medians["Last 5 Years"]["return"],
            color: "black",
            width: 2,
          },
        ],
        labels: {
          formatter: function () {
            return this.value + "%"
          },
        },
      },
      xAxis: {
        title: { text: "Standard Deviation" },
        gridLineWidth: 0,
        min: 0,
        plotLines: [
          {
            value: medians["Last 5 Years"]["risk"],
            color: "black",
            width: 2,
          },
        ],
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          let name = this.point.series.name
          if (this.point.series.name === "Members") name = "Group Member"
          const symbol = getSymbol(this.point.graphic.symbolName)
          return `<span style="color:${
            this.point.color
          }">${symbol}</span> ${name}<br/><span>Risk: </span><b>${
            this.x
          }</b><br/><span>Return: </span><b>${this.y + "%"}</b>`
        },
      },
      plotOptions: {
        scatter: {
          dataLabels: {
            enabled: true,
            backgroundColor: "auto",
            color: "#FFFFFF",
            formatter: function () {
              if (this.point.series.name === "Portfolio") {
                return `Portfolio`
              }
              if (this.point.series.name === "Benchmark") {
                return `Benchmark`
              }
              return null
            },
          },
        },
      },
      series: JSON.parse(JSON.stringify(scatterSeries["Last 5 Years"])),
    })
  })
}

export default TestGraph