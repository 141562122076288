import React from 'react'
import { WithTopNav } from '../ui/LayoutWrapper'
import classnames from 'classnames'
import {
  ResearchProductStrategyTypeColorSetting as ColorSetting,
  ResearchProductStrategyTypeScript as Script,
  ResearchProductStrategyTypeScriptOrder as ScriptOrder,
  ResearchProductStrategyTypeSearchType as SearchType,
  ResearchProductStrategyTypeUrlMapping as UrlMapping
} from './ResearchProductConfig'
import { Col, Container, ListGroup, ListGroupItem, Row, UncontrolledAlert } from 'reactstrap'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { appProps } from '../../queries/extended/appProps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface ResearchProductsRouteProps
  extends RouteComponentProps<{
    type?: string
  }> {}

const getColor = (type: SearchType | null) => {
  let colorClass: string = ''
  if (type && type in ColorSetting) {
    colorClass = ColorSetting[type]
  } else {
    colorClass = ColorSetting['_0']
  }
  return colorClass
}

const ResearchProducts: React.FC<appProps & ResearchProductsRouteProps> = (props) => {
  const history = useHistory()
  const { match, auth } = props

  const { type } = match.params
  const urlWithoutType = type ? match.url.slice(0, -type.length - 1) : match.url

  const assetClassesOptions = () => {
    return (
      <Row className={'research-product-type-container'}>
        {ScriptOrder.map((el, idx) => {
          let color = getColor(el)
          let scripts = Script[el]
          let url = UrlMapping[el].url
          return (
            <Col
              key={idx}
              className={classnames(color, 'm-4 p-4 d-flex flex-column')}
              onClick={() => history.push(`${urlWithoutType}/${url}`)}
            >
              <div key={0} className={'title'}>
                {scripts.title}
              </div>
              <div key={1} className={'description pr-4'}>
                {scripts.description}
              </div>
            </Col>
          )
        })}
      </Row>
    )
  }
  if(!auth.checkPermissions(["view:research_products"])) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline mb-4">Report</h2>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  return (
    <div>
      <h2 className={'research-product-header'}>Research Products</h2>
      {assetClassesOptions()}
    </div>
  )
}

export default WithTopNav(ResearchProducts)
