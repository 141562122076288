import { gql } from '@apollo/client'

import { ASSET_CLASS_FRAGMENT } from './Fragments'

/*
  These are all introspection queries, usually used for dropdown/selections as {code, value} .
*/
export const INTROSPECTION_QUERY = gql`
  query GetLookup($name: String!) {
    __type(name: $name) {
      enumValues {
        code: name
        value: description
      }
    }
  }
`
/**
 * Get managementType, portfolioConstructionMethod, investmentManagementStrategy and currencyHedging options from introspection query.
 * naming alias are based on Input field name for ProductManagementFields.
 */
export const INTROSPECTION_PRODUCT_MANAGEMENT_QUERY = gql`
  query GetProductManagementLookup {
    managementType: __type(name: "ManagementType") {
      enumValues {
        code: name
        value: description
      }
    }
    portfolioConstructionMethod: __type(name: "PortfolioConstructionType") {
      enumValues {
        code: name
        value: description
      }
    }
    investmentManagementStrategy: __type(
      name: "InvestmentManagementStrategyCode"
    ) {
      enumValues {
        code: name
        value: description
      }
    }
    currencyHedging: __type(name: "CurrencyHedgingCode") {
      enumValues {
        code: name
        value: description
      }
    }
  }
`

export const INTROSPECTION_PRODUCT_ESG_QUERY = gql`
  query GetProductEsgLookup {
    esgExclusionType: __type(name: "ESGExclusionType") {
      enumValues {
        code: name
        value: description
      }
    }
    esgComponentType: __type(name: "ESGComponentType") {
      enumValues {
        code: name
        value: description
      }
    }
  }
`

export const INTROSPECTION_MANAGER_ESG_QUERY = gql`
  query GetManagerEsgLookup {
    esgSignatoryBodyType: __type(name: "ESGSignatoryBodyType") {
      enumValues {
        code: name
        value: description
      }
    }
    carbonFootprintAssetClassCode: __type(name: "CarbonFootprintAssetClassCode") {
      enumValues {
        code: name
        value: description
      }
    }
    carbonFootprintScopeCode: __type(name: "CarbonFootprintScopeCode") {
      enumValues {
        code: name
        value: description
      }
    }
    carbonEmissionMeasurementSourceCode: __type(name: "CarbonEmissionMeasurementSourceCode") {
      enumValues {
        code: name
        value: description
      }
    }
    diversityOversightTypeCode: __type(name: "DiversityOversightTypeCode") {
      enumValues {
        code: name
        value: description
      }
    }
    numberVendorCode: __type(name: "NumberVendorCode") {
      enumValues {
        code: name
        value: description
      }
    }
  }
`

/**
 * Treat as Lookup for easier manipulation
 * ref CAL-223
 */
export const ASSET_CLASSES_QUERY = gql`
  query GetAllAssetClasses {
    assetClasses {
      ...AssetClassFragment
      # Asset Class Label
      children {
        # Strategy Type Label
        ...AssetClassFragment
      }
    }
  }
  ${ASSET_CLASS_FRAGMENT}
`

export const INTROSPECTION_CLIENTPORTFOLIO_DEFINITION_QUERY = gql`
  query GetClientDefinitionLookup {
    contactTypeCode: __type(name: "ContactTypeCode") {
      enumValues {
        code: name
        value: description
      }
    }
    dataRequestTypeCode: __type(name: "DataRequestTypeCode") {
      enumValues {
        code: name
        value: description
      }
    }
  }
`

export const JOB_NAME_QUERY = gql`
  query GetJobNameLookup {
    jobName: __type(name: "JobName") {
      enumValues {
        code: name
        value: description
      }
    }
  }
`

export const LIST_CATEGORY_QUERY = gql`
  query GetListCategoryLookup {
    listCategoryMap {
      category
      subCategory
    }
  }
`
