import React from 'react'
import { History } from 'history'
import { useHistory } from 'react-router'
import { ConsultantDashboardComponentFragment } from '../../../__generated__/graphql'
import { ConsultantDashboardAggregatedComponentProps, ConsultantDashboardBaseComponent, ConsultantDashboardIndividualComponentProps } from './ConsultantDashboardComponent'
import { RecentManagers, RecentProducts } from '../../../App'
import { getRecentDocuments } from '../../../helpers/session'
import { Col, ListGroup, ListGroupItem } from 'reactstrap'
import classnames from 'classnames'


const RecentlyViewed: React.FC<ConsultantDashboardAggregatedComponentProps> = ({component, auth, draftView}) => {
  return (
    <ConsultantDashboardBaseComponent<ConsultantDashboardComponentFragment>
      component={component}
      auth={auth}
      draftView={draftView}
      expectedTypename={"RecentlyViewed"}
      reactComponent={RecentlyViewedDisplay}
    />
  )
}
interface RecentProps {
  history: History
  width: number
  stackable?: boolean
  limit?: number
}

const DEFAULT_STACKABLE_ITEM_LIMIT = 5

export const RecentDocuments: React.FC<RecentProps> = ({history, width, stackable, limit}) => {
  const recentDocs = getRecentDocuments(limit || DEFAULT_STACKABLE_ITEM_LIMIT)
  const title = stackable? "DOCUMENTS": "Recent Documents"
  return(
    <Col md={stackable? 12: width}>
      <div className={classnames("pane dashboard-pane", {stackable})}>
        <h3 className="headline underline green-underline">
          {title}
        </h3>
        {recentDocs.length === 0 && (
          <>
            <span className="muted-text">
              Recently viewed Documents will appear here.
            </span>

            <ListGroup className="recent">
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "85%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "55%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
            </ListGroup>
          </>
        )}

        {recentDocs.length > 0 && (
          <ListGroup className="recent">
            {recentDocs.map((document) => {
              let redirectPath: string
              let {sourceType, sourceId, id} = document
              let standAlonePath = `/documents/${id}`
              switch (sourceType) {
                case "Client":
                  redirectPath = `/clients/${sourceId}/documents/${id}`
                  break
                case "Manager":
                  redirectPath = `/managers/${sourceId}/documents/${id}`
                  break
                case "Product":
                  redirectPath = `/products/${sourceId}/documents/${id}`
                  break
                case "GlidePath":
                  redirectPath = `/glidepaths/${sourceId}/documents/${id}`
                  break
                case "Plan": // TODO can't get clientId
                case "Vehicle":
                case "Document":
                default: // standalone, when can't get managerId or clientId
                  redirectPath = standAlonePath
                  break
              }
              if(!sourceId) {
                redirectPath = standAlonePath
              }
              return (
                <ListGroupItem
                  tag="a"
                  key={document.id}
                  onClick={() => history.push(redirectPath)}
                >
                  <h3>
                    {document.name}
                    <span className="chevron">&rsaquo;</span>
                  </h3>
                </ListGroupItem>
              )
            })}
          </ListGroup>
        )}
      </div>
    </Col>
  )

}

const RecentlyViewedDisplay:React.FC<ConsultantDashboardIndividualComponentProps<ConsultantDashboardComponentFragment>> = ({component, auth, settings}) => {
  const history = useHistory()
  return (
    <>
      <RecentManagers history={history} width={3} stackable={true} limit={DEFAULT_STACKABLE_ITEM_LIMIT}/>
      <RecentProducts history={history} width={3} stackable={true} limit={DEFAULT_STACKABLE_ITEM_LIMIT}/>
      <RecentDocuments history={history} width={3} stackable={true} limit={DEFAULT_STACKABLE_ITEM_LIMIT}/>
      </>
  )
}

export default RecentlyViewed