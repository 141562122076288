import React from 'react'
import {
  UncontrolledDropdown,
  DropdownMenu,
  ListGroup,
  ListGroupItem
} from 'reactstrap'
import classnames from 'classnames'
import { ControlCenterComponentConfig } from '../../ControlCenter/ControlCenterDashboard'
import Auth from '../../../Auth/Auth'

interface ControlCenterPickerProps {
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (type: string) => void
  auth: Auth
}

const ControlCenterPicker: React.FC<ControlCenterPickerProps> = ({
  title,
  children,
  dropdownClass,
  onClick,
  auth,
}: ControlCenterPickerProps) => {
  return (
    <UncontrolledDropdown className={classnames('headline-dropdown product-picker-dropdown', dropdownClass)}>
      {children}
      <DropdownMenu>
        <h5>{title}</h5>
        <ListGroup className='headline-dropdown-links'>
          {ControlCenterComponentConfig.map(({type, title: name, permission}, idx) => {
            if(auth.checkPermissions([permission]) === false) return <React.Fragment key={type}></React.Fragment>
            return (
              <ListGroupItem tag='a' key={`control-center-${type}`} onClick={() => onClick(type)}>
                {name}
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default ControlCenterPicker
