import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'

import { DateFieldInput } from '../ui/Forms/DateFieldInput'
import { FormInput } from '../ui/Forms/FormInput'

type ProjectedCloseDatesTableProps = {
  data: Date[]
  handleChange: (state: any) => void
  editMode: boolean
  hidden: boolean
}

interface ProjectedCloseDatesTableRowProps {
  data: Date
  tag: string
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (value: any) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

// const handleInputValueToStateValue = (
//   event: React.ChangeEvent<HTMLInputElement>,
//   updateValue: (value: any) => void
// ) => {
//   const targetValue = event.target.value
//   let value
//   if (targetValue === "") {
//     value = null
//   } else {
//     value = targetValue
//   }
//   updateValue(value)
// }

const CloseRoundTag = {
  first: "First Close",
  next: "Next Close",
  last: "Final Close"
}

/*
 If one date is entered, the Close Round shows as "Final Close".
 If there are two dates, the max date is "Final Close" and min date is "First Close".
 If more than 2 dates are entered, the min/ max still show as "First Close" and "Final Close".
 Any dates in between show as "Next Close". The table is always ordered by dates from earliest to latest.
*/
const NamingTag = (idx: number, length: number) => {
  if (idx === 0 && length > 1) {
    return CloseRoundTag.first
  } else if (idx < length - 1) {
    return CloseRoundTag.next
  } else {
    return CloseRoundTag.last
  }
}

const ProjectedCloseDatesTableRow = ({
  data,
  tag,
  idx,
  editMode,
  action,
  updateValue
}: ProjectedCloseDatesTableRowProps) => {
  return (
    <tr className={ editMode ? "hover-actions editing" : "hover-actions" }>
      <td>{tag}</td>
      <td key={"Date"}>
        <FormInput
          idx={idx}
          property={"date"}
          type={"date"}
          displayName={""}
          propertyVal={data?.toString() || ""}
          editMode={editMode}
          updateValue={updateValue}
          required={true}
          subClasses={{
            wrapperClasses: "no-export-form",
            inputClasses: "text-right"
          }}
          inline
        />
      </td>
      {editMode && (
        <td className={"actions"}>
          <Button
            color="link"
            className="ml-auto"
            disabled={!editMode}
            onClick={() => action.remove(idx)}
          >
            <FontAwesomeIcon icon="trash" className="ml-2 text-blue-100" />
          </Button>
        </td>
      )}
    </tr>
  )
}

const getSorted = (data: Date[]) => {
  if (!data) {
    return data
  }
  data.sort()
  return data
}

const ProjectedCloseDatesTableDisplay = (
  seed: ProjectedCloseDatesTableProps
) => {
  const tableName = "Projected-Close-Date"
  const { data, editMode, handleChange } = seed
  const [tableData, setTableData] = useState(data)

  useEffect(() => {
    setTableData(data)
  }, [data])

  const onAddProjectedCloseDatesRow = (row: Date) => {
    const newData = [...tableData, row]
    setTableData(newData)
    handleChange(newData)
  }

  const onRemoveProjectedCloseDatesRow = (colIndex: number) => {
    let newData = [
      ...tableData.slice(0, colIndex),
      ...tableData.slice(colIndex + 1)
    ]
    setTableData(newData)
    handleChange(newData)
  }
  const actionMap = {
    add: onAddProjectedCloseDatesRow,
    remove: onRemoveProjectedCloseDatesRow
  }

  const onHandleChangeProjectedCloseDatesRow = (idx: number, value: any) => {
    let newData = tableData.map((el, index) => {
      if (idx === index) {
        return value
      } else {
        return el
      }
    })
    setTableData(newData)
    handleChange(newData)
  }

  const sortDateByAscendingOrder = (a: any,b:any) => new Date(a).getTime() - new Date(b).getTime()

  return (
    <div
      className={classNames(`${tableName}-table row pl-0`, {
        "col-sm-9": editMode,
        "col-sm-7": !editMode
      })}
    >
      <Table
        hover
        responsive
        size="sm"
        key={`${tableName}-table`}
        className={"equal-width exportable"}
        data-export-name="ProjectCloseDate"
      >
        <thead className="">
          <tr className="table-header">
            <th key={`{tableName}-header-0`}>Close Round</th>
            <th key={`{tableName}-header-1`}>Date</th>
            {editMode && (
              <th key={`Performance-header-2`}>
                <Button
                  color="link"
                  className="ml-auto"
                  onClick={() =>
                    onAddProjectedCloseDatesRow(moment("YYYY-MM-DD").toDate())
                  }
                >
                  Add Row
                  <FontAwesomeIcon
                    icon="plus-circle"
                    className="ml-2 text-blue-100 btn-thin"
                  />
                </Button>
              </th>
            )}
          </tr>
        </thead>
        <tbody key={0}>
          {tableData.map((row: any, idx: number) => {
            return (
              <ProjectedCloseDatesTableRow
                tag={NamingTag(idx, tableData.length)}
                data={row}
                key={idx}
                idx={idx}
                editMode={editMode}
                action={actionMap}
                updateValue={value =>
                  onHandleChangeProjectedCloseDatesRow(idx, value)
                }
              />
            )
          })}
          <tr
            key={`{tableName}-${tableData.length}`}
            className={`border-top pl-0`}
          ></tr>
        </tbody>
      </Table>
    </div>
  )
}

export default ProjectedCloseDatesTableDisplay
