import { disableFragmentWarnings, gql } from "@apollo/client"

disableFragmentWarnings()

export const CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS_NET_ASSET_FRAGMENT = gql`
  fragment ClientPortfolioPerformanceCashflowsNetAssetFragment on CashFlowNetAssetValue {
    date
    amount
    accruedInterests
    currency {
      code
      value
    }
    assetClassAbbreviation
    country {
      code
      value
    }
  }
`
export const CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_ONLY_FRAGMENT = gql`
  fragment ClientPortfolioPerformanceCashflowOnlyFragment on CashFlows {
    beginningNetAssetValue {
      ...ClientPortfolioPerformanceCashflowsNetAssetFragment
    }
    endingNetAssetValue {
      ...ClientPortfolioPerformanceCashflowsNetAssetFragment
    }
    cashFlowTransactionTypes {
      transactionType {
        code
      }
      transactions {
        periodEndDate
        transactionDate
        amount
        currency {
          code
        }
        assetClassAbbreviation {
          code
        }
        country {
          code
        }
        transactionNumber
      }
      netValue
    }
    cashFlowCashActivity {
      periodEndDate
      grossAssetValue
      entryQueue
      exitQueue
      netAssetValue
      grossAssetValueJointVenture
      netAssetValueJointVenture
      cashValue
      expectedCalledCapitalNextQuarter
      expectedCalledCapitalFundLife
      expectedDistributedCapitalNextQuarter
      expectedDistributedCapital
    }
  }
  ${CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS_NET_ASSET_FRAGMENT}
`

export const CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_FRAGMENT = gql`
  fragment ClientPortfolioPerformanceCashflowFragment on ClientPortfolio {
    id
    serviceStartDate
    cashFlows(date: $date) {
      ...ClientPortfolioPerformanceCashflowOnlyFragment
    }
    cashFlowDefaultKeys {
      currency
      country
      assetClassAbbreviation
    }
    relatedVehicle {
      vehicle {
        id: fundid
        product {
          product {
            id
            assetClass {
              id
              parent {
                id
              }
            }
          }
          __typename
          ... on ClosedEndedPrivateCredit {
            closedEnded {
              finalCommitSize
            }
          }
          ... on ClosedEndedPrivateEquity {
            closedEnded {
              finalCommitSize
            }
          }
          ... on ClosedEndedRealAssets {
            closedEnded {
              finalCommitSize
            }
          }
          ... on ClosedEndedHedgeFund {
            closedEnded {
              finalCommitSize
            }
          }
        }
      }
    }
  }
  ${CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_ONLY_FRAGMENT}
`
