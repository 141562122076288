import classnames from 'classnames'
import React, { useState } from 'react'
import _ from 'lodash'
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Nav, NavItem, NavLink, Col, Row, Container } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { ProductBasicQuery, useProductCharacteristicsQuery, ClientPortfolio, VehicleFields } from '../../__generated__/graphql'
import { appProps } from '../../queries/extended/appProps'
import { ProductRouteProps } from '../../queries/extended/types/Product'
import PlaceHolder from "../ui/PlaceHolder"

import ProductCharacteristicsVehicles from './ProductCharacteristicsVehicles'
import ProductCharacteristicsClient from './ProductCharacteristicsClient'

interface ProductCharacteristicsProps {
  data: ProductBasicQuery
  id: number
  auth: Auth
}

const ProductAssets: React.FC<appProps & ProductRouteProps & ProductCharacteristicsProps> = props => {
  const { id, data, auth } = props
  const productType = data.product?.__typename

  const params = useParams() as {
    subtype?: string
    type?: string
    productId?: string
  }
  const history = useHistory()
  const match = useRouteMatch() || props.match

  const subtype = _.get(props, "match.params.subtype")
  let urlWithoutSubtype =
      params && subtype
        ? match.url.slice(0, -subtype.length - 1)
        : match.url

  let { data: productCharacteristics, loading, error } = useProductCharacteristicsQuery({
    variables: {
      id: id
    },
  })

  if (!productType) return (<>No Product Type, shouldn't happen</>)

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (productCharacteristics && productCharacteristics.product && productCharacteristics.product.product?.vehicles) {
    const hasClient = productCharacteristics.product.product.vehicles?.some(
      (vehicle: any) => {
        return vehicle?.vehicle?.relatedClientPortfolioManagerDataCollect?.some(
          (relatedClient: ClientPortfolio) => {
            return relatedClient.clientSpecificDataCollectionFields?.questionnaireHoldingsRequired || relatedClient.clientSpecificDataCollectionFields?.questionnaireCharacteristicsRequired
          }
      )}
    )
    const canEditClientPortfolio = auth.checkPermissions(["edit:client_portfolios"])
    const canSeeClientPortfolio = auth.checkPermissions(["view:client_portfolios"])
    const clientCanSeeClientPortfolio = auth.checkPermissions(["view:my_client_portfolio"])
    const clientPortfolioPermissionCheck = (canEditClientPortfolio || canSeeClientPortfolio || clientCanSeeClientPortfolio) && hasClient
    const heading = (
      <Nav className="sub-nav sub-nav-secondary" tabs>
        <NavItem>
        <NavLink
            className={classnames({
              active: match.url.indexOf(`${urlWithoutSubtype}/vehicles`) === 0
            })}
            onClick={() => history.push(`${urlWithoutSubtype}/vehicles`)}
          >
            Vehicles
          </NavLink>
        </NavItem>
        {clientPortfolioPermissionCheck &&
          <NavItem>
            <NavLink
              className={classnames({
                active: match.url.indexOf(`${urlWithoutSubtype}/portfolios`) === 0
              })}
              onClick={() => history.push(`${urlWithoutSubtype}/portfolios`)}
            >
              Client Portfolios
            </NavLink>
          </NavItem>
        }
      </Nav>
    )
    const definedCharacteristics = productCharacteristics
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <Switch>
              <Route
                path="/products/:productId(\d+)/characteristics/:subtype(vehicles)"
                render={(props) => <ProductCharacteristicsVehicles productId={id} auth={auth} productCharacteristics={definedCharacteristics} {...props} />}
              />
              {clientPortfolioPermissionCheck &&
                <Route
                  path="/products/:productId(\d+)/characteristics/:subtype(portfolios)"
                  render={(props) => <ProductCharacteristicsClient productId={id} auth={auth} productCharacteristics={definedCharacteristics} {...props} />}
                />
              }
              <Redirect
                from="/products/:productId(\d+)/characteristics"
                to="/products/:productId(\d+)/characteristics/vehicles"
              />
            </Switch>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            No Data Found
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductAssets
