import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { History, Location } from 'history'
import React, { useState } from 'react'
import { match } from 'react-router-dom'
import { Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { PlanListColumnDef } from "../../helpers/columnDefs"
import { AccountTypeCode, FileFullDetailsFragment, PlanFilters, PlanSimpleFragment, useMeDepartmentQuery, usePlanListQuery } from '../../__generated__/graphql'
import SortableTable from "../Shared/SortableTable"
import { WithTopNav } from '../ui/LayoutWrapper'
import PlaceHolder from '../ui/PlaceHolder'
import { sortBy, compact } from 'lodash';

type PlanListProps = {
  history: History
  location: Location
  auth: Auth
  match: match
}

export type documentTableData = {
  clientId: number
  reportId: number
  associations: string[]
} & FileFullDetailsFragment

const PlanList: React.FC<PlanListProps> = ({ history, location, auth, match }) => {
  const authAccess = auth.checkPermissions(["edit:lists"])
  if(!authAccess){
    history.replace('/')
  }

  // const [addListModalOpen, setAddListModalOpen] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  // const addFileInputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState("")
  // used so that filter changes update the grid
  const [filterUpdates, setFilterUpdates] = useState(0)
  // const { resetErrors } = useContext(EditButtonContext)

  const { loading:userLoading, error:userError, data:userData } = useMeDepartmentQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  // const [favoriteList] = useFavoriteListMutation()
  // const [unfavoriteList] = useUnfavoriteListMutation()

  let planFilter: PlanFilters = {limit: 5_000, accountType: [AccountTypeCode.CUST, AccountTypeCode.FCUST]}
  // if(!showAll && user?.person){
  //   const person = user.person
  //   planFilter.owner = [person?.id]
  //   planFilter.people = [person?.id]
  //   planFilter.orgs = compact(user.appMetadata?.firms)
  //   planFilter.departments = person.department?.code ? [parseInt(person.department.code.substring(1))] : []
  // }

  const { loading, data, error } = usePlanListQuery({
    fetchPolicy: "cache-and-network",
    variables: { filters: planFilter },
    errorPolicy: "all",
  })

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const heading = (
    <>
      <div className="mb-4">
        <h2 className="headline">Plans</h2>
      </div>
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="pr-2 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Find Plans by name"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="wide-search"
              onKeyDown={handleEnterKeyDown}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        {/* <div className="border-right">
          <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={openFileModal} disabled={!data}>
            New Plan
          </Button>
        </div> */}
      </div>
    </>
  )

  if ((loading) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!data && (error || userError)) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data && user) {
    const toggleFavorite = (row:PlanSimpleFragment) => {
      // if(!userData?.me?.person?.id || !row.id){
      //   return
      // }
      // const favoriteInput:ListFavoriteInput = {
      //   listIds: [row.id],
      //   personId: userData.me.person.id,
      // }
      // const favoriteFunction = row.isCurrentUsersFavorite ? unfavoriteList : favoriteList
      // favoriteFunction({
      //   variables: {input: favoriteInput},
      //   update: (cache) => {
      //     cache.writeFragment({
      //       id: `List:${row.id}`,
      //       fragment: gql`
      //         fragment ListWithFavorite on List {
      //           isCurrentUsersFavorite
      //         }
      //       `,
      //       data: {
      //         isCurrentUsersFavorite: !row.isCurrentUsersFavorite,
      //         __typename: "List"
      //       },
      //     })
      //   },
      // })
    }
    let colDef = PlanListColumnDef()
    const onReady = (event: GridReadyEvent) => {
      setGridApi(event.api)
      event.api!.setFilterModel({
        'accountType.code': {
          filterType: 'set',
          values: [`Active`]
        }
      })
    }

    const sortedPlans = compact(sortBy(data.plans, (plan) => {
      return plan?.name?.toLowerCase()
    }))

    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column">
        {heading}
        <SortableTable
          loading={loading}
          filterText={search}
          columnDefs={colDef}
          tableData={sortedPlans}
          rowId={"id"}
          onReady={onReady}
          onFilterChanged={() => setFilterUpdates(filterUpdates + 1)}
        />
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}



export default WithTopNav(PlanList)
