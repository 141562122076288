import { disableFragmentWarnings, gql } from "@apollo/client"

disableFragmentWarnings()

export const GLIDE_PATH_SUMMARY_FRAGMENT = gql`
  fragment GlidePathSummaryFragment on GlidePath {
    id
    name
    manager {
      id
      name
    }
    shortName
    yearsToTermination
    eqExposureRetirement
    eqExposureTermination
    retirementIncomeFeature
    constructionEvaluationFrequency {
      code
      value
    }
    designDescription
    rebalancingProcessDescription
    seriesChanges {
      year
      changes
    }
    # CAL-1686
    dataCollect
  }
`

export const GLIDE_PATH_ALLOCATION_FRAGMENT = gql`
  fragment GlidePathAllocationFragment on GlidePath {
    id
    name
    manager {
      id
      name
    }
    allocationConfirmation {
      confirmedBy {
        id
        firstName
        lastName
      }
      confirmDate
      saveDate
      inserted
      modified
    }
    allocations {
      assetClass {
        value
        code
        aggregate
      }
      benchmarkIndex
      year1
      year5
      year10
      year15
      year20
      year25
      year30
      year35
      year40
      year45
      year50
      year55
      year60
      year65
      year70
    }
  }
`

export const GLIDE_PATH_RETURNS_FRAGMENT = gql`
  fragment GlidePathReturnsFragment on GlidePath {
    id
    name
    versions {
      id
      name
      series {
        product {
          id
        }
        ... on OpenEndedTargetDate {
          targetDate {
            vintageYear
            performance(filters: { startDate: $startDate, endDate: $endDate }) {
              value {
                active
                frozen
              }
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`

export const GLIDE_PATH_VERSION_FRAGMENT = gql`
  fragment GlidePathVersionFragment on GlidePathVersion {
    id
    name
    implementationPhilosophy {
      code
      value
    }
    inceptionYear
    latestAum {
      date
      aum
    }
    terminationDate
    philosophy {
      code
      value
    }
    passiveImplementation
    primaryBenchmark
    secondaryBenchmark
    tacticalAllocation
    tacticalDescription
    vehicles {
      code
      value
      otherExplanation
    }
    lossPreventionDescription
    inflationPreventionDescription
    alternativeAssetsDescription
    illiquidAssetsDescription
    activePassiveExposure(
      filter: { startDate: $startDate, endDate: $endDate }
    ) {
      year
      structureData {
        vintageYear
        percentActive
        percentPassive
      }
    }
    proprietaryNonProprietary(
      filter: { startDate: $startDate, endDate: $endDate }
    ) {
      year
      structureData {
        vintageYear
        percentProprietary
        percentNonProprietary
      }
    }
    tacticalAssetAllocation(
      filter: { startDate: $startDate, endDate: $endDate }
    ) {
      year
      structureData {
        vintageYear
        equityExposureAboveStrategicAllocation
        equityExposureBelowStrategicAllocation
        trackingError
      }
    }
    series {
      product {
        id
        name
        latestAum {
          date
          aum
        }
        vehicles {
          vehicle {
            fundid
            name
            category {
              code
              value
            }
          }
          ... on OpenEndedMutualFund {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedExchangeTradedFund {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedPooledVehicle {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedVariableAnnuity {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedCollectiveInvestmentFundComposite {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedSeparateAccount {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedPooledVehiclePrivateEquity {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedPooledVehicleRealAssets {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedSeparateAccountRealAssets {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedPrivateNonRegisteredHedgeFund {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedCollectiveInvestmentFundStableValueComposite {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedCollectiveInvestmentFundStableValue {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedSeparateAccountStableValue {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedCollectiveInvestmentFund {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
          ... on OpenEndedPooledVehiclePrivateCredit {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
        }
      }
      ... on OpenEndedTargetDate {
        targetDate {
          vintageYear
        }
      }
    }
  }
`

export const BREADCRUMB_GLIDE_PATH_FRAGMENT = gql`
  fragment BreadcrumbGlidePathFragment on GlidePath {
    id
    name
    dataCollect
  }
`
