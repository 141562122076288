import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import React, { useEffect, useState } from "react"
import { Button, Table } from "reactstrap"

import { ValueAddedWeightCode } from "../../__generated__/graphql"
import {
  FormInputSubClasses,
  ValueAddedItemUnion
} from "../../helpers/constant"
import { FormInput } from "../ui/Forms/FormInput"

type columnDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  optionSource?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  columnClasses?: string
  editDecimalPlaces?: number
  noFormat?: boolean
}

interface ValueAddedTableRowProps {
  data: any
  columnDef: columnDefItem[]
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

type ValueAddedTableProps = {
  data: ValueAddedItemUnion[]
  handleChange: (state: any) => void
  editMode: boolean
}

const typeDefault = "ASALL" as ValueAddedWeightCode

const ValueAddedColumnDef: columnDefItem[] = [
  {
    field: "code",
    type: "select",
    title: "",
    subtype: "single",
    subClasses: { wrapperClasses: "no-export-form" },
    optionSource: "ValueAddedWeightCode",
    columnClasses: "medium",
  },
  {
    field: "weight",
    type: "number",
    title: "Weight (%)",
    subtype: "percent",
    subClasses: { inputClasses: "text-right", wrapperClasses: "no-export-form" },
    editDecimalPlaces: 0,
    noFormat: true,
    columnClasses: "shrink",
  }
]

const ValueAddedTableRow = ({
  data,
  columnDef,
  idx,
  editMode,
  updateValue,
  action
}: ValueAddedTableRowProps) => {
  return (
    <tr className={editMode ? "hover-actions editing" : "hover-actions"}>
      {columnDef.map(header => {
        return (
          <td key={header.field} className={header.columnClasses}>
            <FormInput
              key={header.field}
              property={header.field}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={idx}
              editMode={editMode}
              propertyVal={data[header.field]}
              updateValue={value =>
                updateValue(value, header.type, header.field)
              }
              optionSource={header.optionSource}
              subClasses={header.subClasses}
              required={true}
              editDecimalPlaces={header.editDecimalPlaces}
              noFormat={header.noFormat}
            />
          </td>
        )
      })}
      {editMode && (
        <td className="actions">
          <Button
            color="link"
            size="sm"
            className="ml-auto"
            onClick={() => action.remove(idx)}
          >
            <FontAwesomeIcon icon="trash" className="ml-2 text-blue-100" />
          </Button>
        </td>
      )}
    </tr>
  )
}

const ValueAddedTableDisplay = (seed: ValueAddedTableProps) => {
  let total = 0
  const tableName = "Value-Added"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)

  useEffect(() => {
    setData(data)
  }, [data])

  const onAddValueAddedRow = (row: ValueAddedItemUnion) => {
    let newData = [...tableState, row]
    handleChange(newData)
    // setData(newData)
  }

  const onRemoveValueAddedRow = (rowIndex: number) => {
    let newData = [
      ...tableState.slice(0, rowIndex),
      ...tableState.slice(rowIndex + 1)
    ]
    handleChange(newData)
    // setData(newData)
  }
  const actionMap = {
    add: onAddValueAddedRow,
    remove: onRemoveValueAddedRow
  }

  const onHandleChangeValueAddedRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = tableState.map((el, index) => {
      const parsedValue = type == "number" ? parseInt(value) : value
      if (idx === index) {
        return { ...tableState[index], [property]: parsedValue }
      } else {
        return el
      }
    })
    handleChange(newData)
    // setData(newData)
  }

  return (
    <>
      <div
        className={classnames(`${tableName}-table row pl-0`, {
          "col-sm-10": !editMode,
          "col-sm-12": editMode
        })}
      >
        <Table striped borderless hover size="sm" key={`ValueAdded-table`} className={"exportable"} data-export-name={"Value Added"}>
          <thead className="table-border-bottom">
            <tr className="table-header">
              <th key={`ValueAdded-header-0`}>Value Added</th>
              <th key={`ValueAdded-header-1`}>Weight (%)</th>
              {editMode && (
                <th key={`ValueAdded-header-2`}>
                  <Button
                    color="link"
                    size="sm"
                    className="ml-auto"
                    onClick={() =>
                      onAddValueAddedRow({ code: typeDefault, weight: 0 })
                    }
                  >
                    Add Row
                    <FontAwesomeIcon
                      icon={"plus-circle"}
                      size="xs"
                      className="ml-2 text-blue-100"
                    />
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody key={0}>
            {tableState.map((row: any, idx: number) => {
              let weight = row?.weight
              if (weight === "0") {
              } else if (isNaN(parseFloat(weight))) {
              } else {
                total += parseFloat(weight)
              }
              return (
                <ValueAddedTableRow
                  data={row}
                  columnDef={ValueAddedColumnDef}
                  key={row.code}
                  idx={idx}
                  editMode={editMode}
                  action={actionMap}
                  updateValue={(value, type, property) =>
                    onHandleChangeValueAddedRow(idx, value, type, property)
                  }
                />
              )
            })}
            <tr
              key={`{tableName}-${tableState.length}`}
              className={`border-top pl-0`}
              hidden={tableState.length === 0}
            >
              <td
                key={`{tableName}-${tableState.length}-0`}
                className={`text-left pl-4`}
              >
                {`Calculated Total`}
              </td>
              <td
                key={`{tableName}-${tableState.length}-1`}
                className={"text-right narrow-number"}
              >
                <span className={`input-group-addon dollar ${(total > 100 || total < 0) && "text-danger"} `}>
                  {`${total} ` || "-"} &#37;
                </span>
              </td>
              {editMode && <td key={`{tableName}-${tableState.length}-2`}></td>}
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default ValueAddedTableDisplay
