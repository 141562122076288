import { ConfigType, ConfigTypeUnion } from '../../../helpers/constant'
import { defaultProductOverviewSummaryConfig } from './default'

const OpenEndedProductSummaryConfig: ConfigTypeUnion = {
  associatedSymbols: true
}

const ProductOverrideConfig: ConfigType = {
  totalCapacity: true,
  materialChanges: true
}

export const OpenEndedEquityProductOverviewSummaryOverrideConfig: {
  [productType: string]: ConfigTypeUnion
} = {
  openEnded: OpenEndedProductSummaryConfig,
  product: {
    ...defaultProductOverviewSummaryConfig,
    ...ProductOverrideConfig
  }
}
