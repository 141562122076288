import React, { Component, useState, useContext, useRef, FormEvent, RefObject } from 'react'
import _ from 'lodash'
import { appDate, CalendarContext } from "../../Context/CalendarContext"
import iassign from 'immutable-assign'
import { useHistory } from 'react-router-dom'
import { History } from 'history'

import { GetLookupDataToOptions } from "../ui/LookupOptions"
import { FormInput } from '../ui/Forms/FormInput'
import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import { useProductOverviewOutlookQuery, useUpdateProductOverviewOutlookMutation, ProductOverviewOutlookQuery, UpdateProductInput, OutlookSurveyResponse, OutlookSurveyQuestionResponse } from '../../__generated__/graphql'
import { parseDate } from '../../helpers/helpers'
import { ManagerDetails_overview_Manager } from '../../queries/types/ManagerDetails'
import { convertLookupToString, excludePropertyArray, reShapeObject, shouldUseYearInput } from '../../helpers/object'

import { EmployeeTable } from '../ui/EmployeeTable'
// import CustomTable from '../ui/SimpleEditableTable'

import { Container, Row, Col, Table, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { CalendarPicker } from '../CalendarPicker'
import PlaceHolder from '../ui/PlaceHolder'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { DATE_API_FORMAT } from '../../helpers/constant'
import moment from 'moment';

interface Props {
  setSearchDate: (searchDate:string) => void
  data: ProductOverviewOutlookQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<Result>
  searchDate: string
  history: History
}

interface idProps {
  productId: number
  auth: Auth
}

/**
 * query ProductOverviewOutlook by managerId, return editable form
 * @param {id:number} idProps for query ManagerSummary
 * **/
const ProductOverviewOutlook: React.FC<idProps> = ({ productId, auth }: idProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const resultRef = useRef<Result>(null)
  const history = useHistory()
  const [updateOutlook] = useUpdateProductOverviewOutlookMutation()
  let { data, loading, error } =
  useProductOverviewOutlookQuery({
    variables: {
      id: productId,
      startDate: searchDate,
      endDate: searchDate,
    }
  })

  const editable = (data?.product?.product?.outlookSurvey?.editable && auth.checkPermissions(["edit:outlook_survey"]) && searchDate === appDate.format(DATE_API_FORMAT))

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const handleSubmit = () => {
    if(!editable){
      return
    }
    let currentProduct = resultRef!.current?.state.currentState.product?.product
    if(!currentProduct){
      return
    }
    setSaving(true)

    const updateData = {
      id: currentProduct.id,
      patch: {
        outlookSurvey: excludePropertyArray({
          date: searchDate,
          responses: _.find(currentProduct.outlookSurvey?.responses, (o) => {return (o?.date === searchDate) })?.questionResponses,
        }, ["__typename"])
      }
    } as UpdateProductInput

    updateOutlook({ variables: { input: updateData, startDate: searchDate, endDate: searchDate } })
      .then(result => {
        if (result && result.data) {
          let unformattedNewData = result.data.product
          let previousState = resultRef!.current?.state
          resultRef!.current?.setState({ ...previousState, currentState: unformattedNewData, initialState: unformattedNewData })
          setEditMode(false)
          setSaving(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error testManagerSummary", err.message)
      })
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <CalendarPicker
        updateValue={(searchDate) => setSearchDate(searchDate)}
        editMode={editMode}
        setEditMode={(value:boolean) => setEditMode(value)}
      />
      {editable &&
        <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} disabled={loading}/>
      }
    </div>
  )

  if (loading) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <div className='pane'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <div className='pane'>
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (data && data.product) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <Result
              key={searchDate}
              ref={resultRef}
              editMode={editMode}
              setEditMode={setEditMode}
              setSearchDate={setSearchDate}
              data={data}
              searchDate={searchDate}
              history={history}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}



class Result extends Component<Props> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data
  }
  constructor(props: any) {
    super(props)
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleEnterKeyDown = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  handleInputChange = (
    value: any,
    questionId: number,
    answerId: number,
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["product", "product", "outlookSurvey", "responses"]
    if(!_.get(oldState, path)){
      _.set(oldState, path, [] as OutlookSurveyResponse[])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable || []) as OutlookSurveyResponse[]
        var selectedRow = _.find(rows, (o) => {return (o.date === this.props.searchDate) })
        if(!selectedRow){
          selectedRow = {
            date: this.props.searchDate,
            questionResponses: [],
            __typename: "OutlookSurveyResponse",
          } as OutlookSurveyResponse
          rows.push(selectedRow)
        }
        var selectedResponse = _.find(selectedRow.questionResponses, (o) => {return (o?.questionId === questionId && o?.answerId === answerId)})

        if(selectedResponse) {
          _.set(selectedResponse, "responseText", value)
        } else {
          let newRow: OutlookSurveyQuestionResponse
          newRow = {
            questionId: questionId,
            answerId: answerId,
            responseText: value,
            __typename: "OutlookSurveyQuestionResponse"
          }
          if(!selectedRow.questionResponses) {
            _.set(selectedRow, "questionResponses", [] as OutlookSurveyQuestionResponse[])
          }
          (selectedRow.questionResponses as OutlookSurveyQuestionResponse[]).push(newRow)
        }

        return rows
      }
    )
    this.setState({ ...this.state, currentState: newState })
  }

  render() {
    const survey = this.state.currentState.product?.product?.outlookSurvey
    const responses = survey?.responses?.find((response) => response?.date === this.props.searchDate)
    return(
      <>
        <RouteLeavingGuard
          when={this.props.editMode}
          navigate={path => this.props.history.push(path)}
        />
        <div className='pane pane-table'>
          <div
            className={
              "form-section-title headline underline small-font-size py-2 mb-2 w-100"
            }
          >
            Outlook {moment(this.props.searchDate).add(3, "months").format("Q[Q]YYYY")}
          </div>
          {survey?.questions?.map((question, idx) => {
            return(
              <div key={idx}>
                <h4 className={"py-2 outlook-question"}>{question?.questionText}</h4>
                <Row>
                  {question?.answers.map((answer, idx) => {
                    const value = responses?.questionResponses?.find((questionResponse) => questionResponse?.questionId === question.id && questionResponse.answerId === answer.answerId)?.responseText
                    let options = undefined
                    let type = "text"
                    let cols = 6
                    let spacer = false
                    let order = 12
                    if(answer.answerOptions.length > 0){
                      options = GetLookupDataToOptions({
                        data: answer.answerOptions,
                        placeholder: "Please Select",
                      })
                      type = "select"
                    } else if (answer.answerText === "Explanation" || answer.answerText === "Main Concern 1" || answer.answerText === "Main Concern 2"){
                      type = "textarea"
                      cols = 12
                    }

                    if (answer.answerText === "Risk Budget"){
                      spacer = true
                      cols = 4
                    } else if (answer.answerText === "Peak" || answer.answerText === "Trough" || answer.answerText === "Average"){
                      // spacer = true
                      cols = 4
                    } else if (answer.answerText === "Overweight Sector 1" || answer.answerText === "Overweight Industry 1"){
                      order = 0
                    } else if (answer.answerText === "Overweight Sector 2" || answer.answerText === "Overweight Industry 2"){
                      order = 2
                    } else if (answer.answerText === "Overweight Sector 3" || answer.answerText === "Overweight Industry 3"){
                      order = 4
                    } else if (answer.answerText === "Underweight Sector 1" || answer.answerText === "Underweight Industry 1"){
                      order = 1
                    } else if (answer.answerText === "Underweight Sector 2" || answer.answerText === "Underweight Industry 2"){
                      order = 3
                    } else if (answer.answerText === "Underweight Sector 3" || answer.answerText === "Underweight Industry 3"){
                      order = 5
                    }

                    return(
                      <React.Fragment key={idx}>
                        <Col sm={cols} className={type !== "textarea" ? `pl-30px order-${order}` : `order-${order}`}>
                          <FormInput
                            property={answer.answerText + question.id}
                            displayName={answer.answerText}
                            type={type}
                            idx={idx}
                            editMode={this.props.editMode}
                            propertyVal={value}
                            updateValue={(value:any) => {this.handleInputChange(value, question.id, answer.answerId)}}
                            options={options}
                            subClasses={{
                              labelClasses: type !== "textarea" ? "col-sm-4" : "",
                              inputClasses: type !== "textarea" ? "border text-gray-50 mx-0" : "text-gray-50"
                            }}
                          />
                        </Col>
                        {spacer &&
                          <Col sm={6} className={`order-${order}`}/>
                        }
                      </React.Fragment>
                    )
                  })}
                </Row>
              </div>
            )
          })}
        </div>
      </>
    )
  }
}

export default ProductOverviewOutlook
