import React from "react"
import { FormGroup, Input } from "reactstrap"
import { CustomBooleanInput } from "."
import { PercentInput } from "../../FeeFormComponents"
import {
  OtherFeesGenericProps,
  NumericInputData,
  OtherInputData,
} from "../FeeRows"

interface EntryFeeRowProps extends OtherFeesGenericProps {
  boolInput: OtherInputData<boolean>
  feeInput: NumericInputData<string>
}

export const EntryFeeRow: React.FC<EntryFeeRowProps> = ({
  label,
  editing,
  boolInput,
  feeInput,
}) => {
  return (
    <tr>
      <td>{label}</td>
      <td>
        {editing && (
          <CustomBooleanInput
            id="entry-fee"
            value={boolInput.value}
            onChange={boolInput.onChange}
          />
        )}
      </td>
      <td>
        {editing || boolInput.value ? (
          <FormGroup>
            <div>
              <PercentInput
                disabled={!editing}
                value={+feeInput.value}
                onChange={feeInput.onChange}
              />
            </div>
          </FormGroup>
        ) : boolInput.value === false ? (
          "No"
        ) : (
          ""
        )}
      </td>
      <td></td>
    </tr>
  )
}
