import { Fact, ManagerNoteFragment, Maybe, Opinion, ProductOpinion, ResearchCategoryCode } from "../__generated__/graphql"
import _ from "lodash"
import { containsOnlyLineBreaks } from "./interaction"
import { DnaNote, LegacyNote } from "../queries/extended/types/WriteUps"

// CAL-1545 TODO change from null to "" or "/n" is not changed, shouldn't update
/**
 * compare text to see if there is change
 * note: params order matters
 * @params {string|null|undefined} a the current string
 * @params {string|null|undefined} b the initial string
 * @return boolean
 */
const containsMaterialChange = (a: string|null|undefined, b: string|null|undefined) => {
  if(_.isUndefined(a) && _.isUndefined(b) || (_.isNull(a) && _.isNull(b))) {
    return false
  }
  if(!b || containsOnlyLineBreaks(b)) {
    // if initial as empty or "/n"
    if(a && !containsOnlyLineBreaks(a)) {
      return true
    }else {
      return false
    }
  }else {
    return a !== b
  }
  
}

// CAL-1545 TODO change from null to "" or "/n" is not changed, shouldn't update
/**
 * compare text to see if factual has changed
 * @params {Fact} a the current Fact
 * @params {Fact} b the initial Fact
 * @return boolean
 */
export const factualHasChanged = (a: Maybe<Fact> | undefined, b: Maybe<Fact> | undefined) => {
  if(_.isUndefined(a) && _.isUndefined(b) || (_.isNull(a) && _.isNull(b))) {
    return false
  }
  return (containsMaterialChange(a?.text, b?.text) || containsMaterialChange(a?.bullets, b?.bullets))
}

// CAL-1545 TODO change from null to "" or "/n" is not changed, shouldn't update
/**
 * compare text to see if opinion has changed
 * @params {Opinion} a the current opinion
 * @params {Opinion} b the initial opinion
 * @return boolean
 */
export const opinionHasChanged = (a: Maybe<Opinion> | undefined, b: Maybe<Opinion> | undefined) => {
  if(_.isUndefined(a) && _.isUndefined(b) || (_.isNull(a) && _.isNull(b))) {
    return false
  }
  
  let result = containsMaterialChange(a?.meritsText, b?.meritsText) || 
  containsMaterialChange(a?.meritsBullets, b?.meritsBullets) || 
  containsMaterialChange(a?.considerationsText, b?.considerationsText) || containsMaterialChange(a?.considerationsBullets, b?.considerationsBullets) ||
  containsMaterialChange(a?.status?.code, b?.status?.code)

  return result
}

export const productOpinionHasChanged = (a: ProductOpinion, b: ProductOpinion) => {
  return (opinionHasChanged(a?.opinions, b?.opinions))
}

export const dnaNoteHasChanged = (a: Maybe<ManagerNoteFragment> | undefined, b: Maybe<ManagerNoteFragment> | undefined) => {
  if(_.isUndefined(a) && _.isUndefined(b) || (_.isNull(a) && _.isNull(b))) {
    return false
  }
  let result = containsMaterialChange(a?.body, b?.body)
  return result
}

/**
 * dnaNotes come first, then legacyNotes, second sort by updateDate desc
 */
export const sortAndFilterNotes = (notes: ManagerNoteFragment[] | null | undefined) => { 
  if(!notes || _.isEmpty(notes)) return {legacyNotes: [] as LegacyNote[], dnaNotes: [] as DnaNote[]}
  let orderedNotes = _.orderBy(notes, [note=> (note.updateDate || note.createDate), "id"], ["desc", "desc"])
  let legacyNotes = _.filter(orderedNotes, obj=>!!obj?.legacy) as LegacyNote[]
  let dnaNotes = _.filter(orderedNotes, obj=>!obj?.legacy) as DnaNote[]
  return {legacyNotes, dnaNotes}
}