import { ConfigType, ConfigTypeUnion } from '../../../helpers/constant'
import { ClosedEndedPrivateCreditProductOverviewSummaryOverrideConfig } from './ClosedEndedPrivateCredit'
import { ClosedEndedPrivateEquityProductOverviewSummaryOverrideConfig } from './ClosedEndedPrivateEquity'
import { ClosedEndedRealAssetsProductOverviewSummaryOverrideConfig } from './ClosedEndedRealAssets'
import { ClosedEndedHedgeFundProductOverviewSummaryOverrideConfig } from './ClosedEndedHedgeFund'
import { defaultProductOverviewSummaryConfig } from './default'
import { OpenEndedEquityProductOverviewSummaryOverrideConfig } from './OpenEndedEquity'
import { OpenEndedFixedIncomeProductOverviewSummaryOverrideConfig } from './OpenEndedFixedIncome'
import { OpenEndedHedgeFundProductOverviewSummaryOverrideConfig } from './OpenEndedHedgeFund'
import { OpenEndedMACProductOverviewSummaryOverrideConfig } from './OpenEndedMAC'
import { OpenEndedPassiveEquityProductOverviewSummaryOverrideConfig } from './OpenEndedPassiveEquity'
import { OpenEndedPassiveFixedIncomeProductOverviewSummaryOverrideConfig } from './OpenEndedPassiveFixedIncome'
import { OpenEndedPrivateCreditProductOverviewSummaryOverrideConfig } from './OpenEndedPrivateCredit'
import { OpenEndedPrivateEquityProductOverviewSummaryOverrideConfig } from './OpenEndedPrivateEquity'
import { OpenEndedPassiveMACProductOverviewSummaryOverrideConfig } from './OpenEndedPassiveMAC'
import { OpenEndedRealAssetsProductSummaryOverrideConfig } from './OpenEndedRealAssets'
import { OpenEndedTargetDatedProductOverviewSummaryOverrideConfig } from './OpenEndedTargetDate'

export const ProductOverviewSummaryConfig: {
  [productType: string]:
    | ConfigType
    | ConfigTypeUnion
    | { [productType: string]: ConfigTypeUnion }
} = {
  Product: { ...defaultProductOverviewSummaryConfig },
  OpenEndedEquity: {
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig
  },
  OpenEndedPassiveEquity: {
    ...OpenEndedPassiveEquityProductOverviewSummaryOverrideConfig
  },
  OpenEndedFixedIncome: {
    ...OpenEndedFixedIncomeProductOverviewSummaryOverrideConfig
  },
  OpenEndedPassiveFixedIncome: {
    ...OpenEndedPassiveFixedIncomeProductOverviewSummaryOverrideConfig
  },
  OpenEndedPrivateCredit: {
    ...OpenEndedPrivateCreditProductOverviewSummaryOverrideConfig
  },
  OpenEndedPrivateEquity: {
    ...OpenEndedPrivateEquityProductOverviewSummaryOverrideConfig
  },
  OpenEndedHedgeFund: {
    ...OpenEndedHedgeFundProductOverviewSummaryOverrideConfig
  },
  OpenEndedMAC: {
    ...OpenEndedMACProductOverviewSummaryOverrideConfig
  },
  OpenEndedPassiveMAC: {
    ...OpenEndedPassiveMACProductOverviewSummaryOverrideConfig
  },
  OpenEndedRealAssets: {
    ...OpenEndedRealAssetsProductSummaryOverrideConfig
  },
  OpenEndedTargetDate: {
    ...OpenEndedTargetDatedProductOverviewSummaryOverrideConfig
  },
  ClosedEndedRealAssets: {
    ...ClosedEndedRealAssetsProductOverviewSummaryOverrideConfig
  },
  ClosedEndedPrivateEquity: {
    ...ClosedEndedPrivateEquityProductOverviewSummaryOverrideConfig
  },
  ClosedEndedPrivateCredit: {
    ...ClosedEndedPrivateCreditProductOverviewSummaryOverrideConfig
  },
  ClosedEndedHedgeFund: {
    ...ClosedEndedHedgeFundProductOverviewSummaryOverrideConfig
  }
}
