import { defaultProductOverviewSummaryConfig } from './default'
import { OpenEndedHedgeFundProductOverviewSummaryOverrideConfig } from './OpenEndedHedgeFund'

export const ClosedEndedHedgeFundProductOverviewSummaryOverrideConfig = {
  ...OpenEndedHedgeFundProductOverviewSummaryOverrideConfig,
  product: {
    ...defaultProductOverviewSummaryConfig,
    targetBenchmarkAdd: true,
    primaryGeographicRegion: true
  },
  hedgeFund: {
    ...OpenEndedHedgeFundProductOverviewSummaryOverrideConfig.hedgeFund
  }
}
