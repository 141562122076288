import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { History } from 'history'
import Auth from '../../../../Auth/Auth'
import { matchProps } from '../../../Shared/Header'
import { MatchParams, WithTopNav } from '../../../ui/LayoutWrapper'
import { Breadcrumb, BreadcrumbItem, BreadcrumbProps, Button, Col, Container, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Nav, NavItem, NavLink, Row, UncontrolledDropdown } from 'reactstrap'
import { ControlCenterComponentConfig } from '../../ControlCenterDashboard'
import { DataLoadBasicFragment, DataLoadDataFragment, DataLoadDetailsFragment, DataLoadTemplateFragment, File as Document, LoadMessage, LoadUploadFileInfo, Maybe, MessageSeverity, useDataLoaderDetailsLazyQuery, useDataLoaderDetailsQuery, useMeQuery } from '../../../../__generated__/graphql'
import { ErrorComponent, LoadingComponent } from '../../../Report/Shared/ReportComponent'
import { fileNameFormatter, ImportedFileMessagesColumnDef, MessageSeverityMapping } from './ImportedFilesConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SortableTable from '../../../Shared/SortableTable'
import { GetRowIdParams, GridReadyEvent } from '@ag-grid-community/core'
import classnames from 'classnames'
import { Switch } from 'react-router'
import { CrumbRoute } from '../../../../routes/crumb-route'
import { compact, flatten, get } from 'lodash'
import { ReportEditTemplate } from '../../../Report/Shared/ReportEdit'
import moment from 'moment'
import ImportedFileDataTable from './ImportedFileData'
import { VersionModal } from '../../../Shared/Document'
import { useForceUpdate } from '../../../../helpers/helpers'
import { TemporaryAlertContext } from '../../../../Context/TemporaryAlertContext'
import LoadingOverlay from '../../../ui/Loading'

type ImportedFileDetailsProps = {
  auth: Auth
  match: matchProps<MatchParams>
  history: History
}

const getActiveTab = (props: any, url: string) => {
  if (props.match.url.indexOf(`${url}/messages`) === 0) {
    return 'Messages'
  }
  if (props.match.url.indexOf(`${url}/data`) === 0) {
    return 'Data'
  }
  return 'Menu'
}

const ImportedFileDetailsComponent: React.FC<ImportedFileDetailsProps> = (
  props
) => {
  const { auth, history, match } = props
  const {id, subtype } = props.match.params
  const config = ControlCenterComponentConfig.find(el => el.type === props.match.params.type) || ControlCenterComponentConfig[2]
  const {title, type} = config || {title: '', type: ''}

  const {data, loading, error, refetch } = useDataLoaderDetailsQuery({
    variables: {
      id: parseInt(id || '')
    }
  })

  const urlWithoutType = subtype
    ? match.url.slice(0, -subtype.length - 1)
    : match.url

  const name = 'Imported File Details'
  if(loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if(error) {
    return <ErrorComponent name={name} error={error?.message}/>
  }

  if(data?.dataLoad?.uploadFileInfo){
    const dataLoad = data?.dataLoad as DataLoadBasicFragment
    const {uploadFileInfo, template} = dataLoad
    const {filename} = uploadFileInfo as LoadUploadFileInfo
    const name = fileNameFormatter({value: filename} as any)
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <Col xs='10' sm='8' md='6'>
                  <Breadcrumb>
                    <BreadcrumbItem className='headline-breadcrumbs' key='control-center' onClick={() => history.push(`/control-center`)}>
                      {`Control Center`}
                    </BreadcrumbItem>
                    <BreadcrumbItem className='headline-breadcrumbs' key='imported-files' onClick={() => history.push(`/control-center/${type}`)}>
                      {title}
                    </BreadcrumbItem>
                  </Breadcrumb>
                  <h2 className='headline'>{name}</h2>
                </Col>
              </Row>
              <Nav className="sub-nav sub-nav-secondary collapsed" tabs role="group">
                <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
                  <DropdownToggle caret>
                    { getActiveTab(props, urlWithoutType) }
                  </DropdownToggle>
                  <DropdownMenu>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/messages`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/messages`)}
                      >
                        Messages
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: match.url.indexOf(`${urlWithoutType}/data`) === 0
                        })}
                        onClick={() => history.push(`${urlWithoutType}/data`)}
                      >
                        Data
                      </NavLink>
                    </NavItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Switch>
                <CrumbRoute
                  exact
                  title="messages"
                  path={`${urlWithoutType}/messages`}
                  render={(params: BreadcrumbProps) => (<ImportedFileMessagesTable dataLoadId={dataLoad.id}/>)}
                />
                <CrumbRoute
                  exact
                  title="data"
                  path={`${urlWithoutType}/data`}
                  render={(params: BreadcrumbProps) => (<ImportedFileDataTable dataLoadId={dataLoad.id} template={template as DataLoadTemplateFragment} />)}
                />
            </Switch>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return <div>No data found</div>
}

type ImportedFileTableProps = {
  dataLoadId: number
}

type LoadMessageType = LoadMessage & {type: string, value?: string}

const getInitialState = (state: DataLoadDetailsFragment) => {
  if(!state?.data) return []
  let {summaryPositions, summaryTransactions} = state?.data as DataLoadDataFragment
  let positions = summaryPositions?.map((position) => {
    if(!position) return null
    let {id, messages} = position
    return compact(messages?.map((message: Maybe<LoadMessage>) => {
      if(!message) return null
      return {type: "summaryPositions", id, ...message}
    })) as LoadMessageType[]
  }) || []
  let transactions = summaryTransactions?.map((transaction) => {
    if(!transaction) return null
    let {id, messages} = transaction
    return compact(messages?.map((message: Maybe<LoadMessage>) => {
      if(!message) return null
      return {type: "summaryTransactions", id, ...message}
    })) as LoadMessageType[]
  }) || []
  return compact(flatten([...positions, ...transactions]))
}

interface LoadMessageSubtotalDisplayProps {
  handleInputChange?: (value: any) => void
  value: MessageSeverity
  count?: number
  name?: string
}

const LoadMessageSubtotalDisplay: React.FC<LoadMessageSubtotalDisplayProps> = ({value, count, children}) => {
  return (
    <div className={classnames("client-report-edit-template")}>
      <Row className="justify-content-between">
        <Col sm={4}>
          <div
            className={classnames("client-report-approvals-title p-2 my-0", {
              "error": value === "ERROR",
              "warning": value === "WARNING",
              "info": value === "INFO",
            })}
          >
            {MessageSeverityMapping[value]}
          </div>
        </Col>
        <Col sm={8} className="align-self-center">
          {count}
        </Col>
      </Row>
    </div>
  )
}

const isPublished = (data: Maybe<DataLoadDetailsFragment> | undefined) => {
  return data?.status?.code === "_3" && data?.lastPublished
}

const ImportedFileMessagesTable: React.FC<ImportedFileTableProps> = (props) => {
  const {dataLoadId} = props
  const [search, setSearch] = useState("")

  const [gridReadyEvent, setGridApi] = useState<Maybe<GridReadyEvent>>(null)
  const [dataState, setDataState] = useState<Maybe<LoadMessageType[]>>(null)
  const { addAlert } = useContext(TemporaryAlertContext)

  // import new version button
  const [versionModalOpen, setVersionModalOpen] = useState(false)
  const [needPolling, setPollingState] = useState(false)
  const [forceUpdateFlag, setForceUpdate] = useState(false)
  const [monitorIds, setMonitorIds] = useState<number[]>([])
  const [refetchNew] = useDataLoaderDetailsLazyQuery({fetchPolicy: 'no-cache'})
  const forceUpdateNow = useForceUpdate()

  // use ref to store interval, so that it can be easily cleared on unmount.
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null)

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const {loading, data, error, refetch} = useDataLoaderDetailsQuery({
    variables: {id: dataLoadId},
    fetchPolicy: "no-cache"
  })
  let {data: currentUserData, loading: currentUserLoading, error: currentUserError } = useMeQuery({fetchPolicy: "cache-first"})

  useEffect(() => {
    if(data?.dataLoad) {
      let newData = data?.dataLoad
      let formattedData = getInitialState(newData)
      setDataState(formattedData)
    }
  }, [data])

  useEffect(() => {
    if(needPolling) {
      gridReadyEvent?.api?.showLoadingOverlay()
      refetchInterval()
    } else {
      resetInterval()
    }
  },[needPolling])

  const refetchInterval = () => {
    let id = monitorIds.length > 0 ? monitorIds[0]: null
    if(!id ) return
    intervalRef.current = setInterval(() => {
      refetchNew({variables: {id}}).then((result) => {
        console.log("refetch Starts")
        const data = result.data?.dataLoad as DataLoadDetailsFragment
        const status = data?.status?.code
        const message = data?.messages
        let isInProcess = status === "_1" && !message
        let isSuccess = status === "_1" && message
        let isFailed = status === "_2"
        if(!isInProcess) {
          if(isSuccess ) {
            addAlert({title: "Import Complete | No issues found. Review and publish the data.", message: "", color: "userSuccess", timeout: 3000})
          }else if(isFailed){
            addAlert({title: "Import Complete | Data imported. Review messages and resolve any errors before publishing.", message: "", color: "importMessage", timeout: 3000})
          }
          setMonitorIds([])
          setPollingState(false)
          setForceUpdate(true)
        }else {
          console.log("refetch Ends, still in Process")
        }
      }).catch((error) => {
        console.log("Import New Version Error", error.message)
      })
    }, 10000)
  }

  const resetInterval = () => {
    clearInterval(intervalRef.current as NodeJS.Timeout)
    intervalRef.current = null
  }

  useEffect(() => {
    let id = monitorIds.length > 0 ? monitorIds[0]: null
    if(!id) return
    if(needPolling) {
      console.log("polling Starts", {monitorIds})
      if(intervalRef.current) {
        resetInterval()
      }
      refetchInterval()
    }else {
      resetInterval()
      if(!forceUpdateFlag){
        setForceUpdate(true)
      }
    }
  }, [monitorIds.length])

  useEffect(() => {
    if(forceUpdateFlag) {
      refetch().then((result) => {
        console.log("refetch Ends, status updated")
        gridReadyEvent?.api?.setFilterModel({})
        setPollingState(false)
        setForceUpdate(false)
        forceUpdateNow()
      })
    }
  }, [forceUpdateFlag])

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params)
  }

  const onExport = () => {
    if(!gridReadyEvent?.api) return
    gridReadyEvent.api!.exportDataAsCsv()
  }

  const getRowId = (params: GetRowIdParams) => {
    let data = params.data
    return `${data.id}- ${data.row}-${data.column}-${data.type}-${data.severity}`
  }

  const onImport = () => {
    setVersionModalOpen(true)
    console.log("import triggered")
  } 
  
  const document = {
    __typename: "File",
    id: data?.dataLoad?.uploadFileInfo?.id || "",
    versionId: "",
    name: "",
    mimeType: "",
    lastUpdated: "",
  } as Document

  const colDef: any[] = ImportedFileMessagesColumnDef()
  const name = ""
  const importedDate = moment(data?.dataLoad?.uploadFileInfo?.uploaded).format("LLL")
  const lastPublished = moment(data?.dataLoad?.lastPublished).format("LLL")

  if(error) return <ErrorComponent name={name} error={error?.message}/>

  let owner = data?.dataLoad?.person
  let ownerName = `${get(owner,"firstName")} ${get(owner,"lastName")}`
  let description = ""
  if(isPublished(data?.dataLoad)) {
    description = `Published on ${lastPublished}`
  }else {
    description = `Imported on ${importedDate}`
  }
  let backgroundColor = "background-gray-70"
  let textColor = "text-light"
  if(currentUserData?.me?.person?.id === owner?.id) {
    ownerName = "me"
    backgroundColor = "background-blue-100"
    textColor = "text-blue-50"
  }

  let state = dataState || []
  let totals = state?.reduce((acc, message) => {
    if(message.severity === "ERROR") {
      acc.error++
    }else if(message.severity === "WARNING") {
      acc.warning++
    }else if(message.severity === "INFO") {
      acc.info++
    }else {
      // if no severity, count as info
      acc.info++
    }
    return acc
  }, {error: 0, warning: 0, info: 0}) || {error: 0, warning: 0, info: 0}
  return (
    <>
      <Container fluid className="px-0 d-flex flex-grow-1 flex-direction-column">
        <Row className="px-0 pane pane-toolbar sticky-top above-picker w-100">
          <Form className="mr-2 pr-2" onKeyDown={handleEnterKeyDown}>
            <FormGroup row className="relative m-0">
              <Input
                type="text"
                placeholder="Find"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="mid-search"
              />
              <span className="o-88 absolute center-v right-1 pe-none">
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="2x"
                  className="fontawesome-icon dark-icon-color text-gray-50"
                />
              </span>
            </FormGroup>
          </Form>
          <div className="d-flex align-items-center justify-content-between pl-1">
            <Button className="px-3 text-callan-blue pb-2 mr-2" color="secondary btn-thin" onClick={() => {onImport()}}>
              <span>Import New Version</span>
            </Button>
            <Button className="px-3 text-callan-blue pb-2" color="secondary btn-thin" onClick={()=>{
              console.log("download triggered",)
              onExport()
            }}>
              <span>Export</span>
              <FontAwesomeIcon icon="download" className="ml-2 text-callan-blue"/>
            </Button>
          </div>
        </Row>
        <SortableTable
          filterText={search}
          columnDefs={colDef}
          tableData={state} // test
          onReady={onReady}
          editMode={false}
          loading={loading || needPolling}
          getRowId={getRowId}
          >
            <Col md={3}  className="px-0 pr-3 pt-1 pb-0 pl-1 b-1 mx-0 d-flex">
              <div className="px-0 mx-0 pb-1 mb-0 view-port-80 background-white box-shadow client-report-edit-sidebar">
                <ReportEditTemplate className={classnames('text-white', backgroundColor)}>
                  <div className="d-flex my-2">
                    <div className="ml-2">
                      <strong>{data?.dataLoad?.uploadFileInfo?.filename}</strong>
                      <br/>
                      <span className={classnames('small-text',  textColor)}>
                        Owned by {ownerName}
                      </span>
                      <br/>
                      <small className={classnames('small-text',  textColor)}>
                        {description}
                      </small>
                    </div>
                  </div>
                </ReportEditTemplate>
                {Object.keys(totals)?.map((severity) => {
                  return <LoadMessageSubtotalDisplay key={severity} value={severity.toUpperCase() as MessageSeverity} count={totals[severity as keyof typeof totals]}></LoadMessageSubtotalDisplay>
                })}
              </div>
            </Col>
        </SortableTable>
        <VersionModal
          modalOpen={versionModalOpen}
          setModalOpen={setVersionModalOpen}
          document={document}
          type="import"
          dataLoadState={{id: dataLoadId}}
          setSelectedDocument={(data: DataLoadBasicFragment) => {
            const status = data?.status?.code
            const message = data?.messages
            let isInProcess = message === null
            let isSuccess = status === "_1" && message === 'OK'
            let isFailed = status === "_2"
            if(isInProcess) {
              addAlert({title: "Import In Process | Data is being imported. Review messages and resolve any errors before publishing.", message: ``, color: "importPending", timeout: 3000})
              setPollingState(true)
              setMonitorIds([dataLoadId])
              return
            }
            if(isSuccess ) {
              addAlert({title: "Import Complete | No issues found. Review and publish the data.", message: ``, color: "userSuccess", timeout: 3000})
            }else if(isFailed){
              addAlert({title: "Import Complete | Data imported. Review messages and resolve any errors before publishing.", message: "", color: "importMessage", timeout: 3000})
            }
            setMonitorIds([])
            setPollingState(false)
            setForceUpdate(true)
          }}
        />
      </Container>
    </>
  )
}

export default WithTopNav(ImportedFileDetailsComponent)
