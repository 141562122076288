import { Query } from '@apollo/client/react/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { History, Location } from 'history'
import _ from 'lodash'
import React, { Component, RefObject, useContext, useRef, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Button, Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'

import { File as Document, FileBasicInfoFragment, FileDetailsQuery, FileTypeMapType, FileTypeSubTypeMap, MeFragment, UpdateFileMetadataInput, useManagerDocumentsQuery, useMeQuery, useUpdateFileMetadataMutation } from '../../__generated__/graphql'
import Auth from '../../Auth/Auth'
import { convertLookupToString, excludePropertyArray } from "../../helpers/object"
import { FILE_QUERY } from '../../queries/File'
import { AddFileModal, DisplayDocs, DocumentsSidebar, getDocumentPermission } from '../Shared/Document'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import EditButtons from '../ui/EditButtons'
import PlaceHolder from '../ui/PlaceHolder'
import { EditButtonContext } from '../../Context/EditButtonContext'
import ErrorDisplay from '../Shared/ErrorDisplay'

type idProps = {
  managerId: number
  history: History
  location: Location
  auth: Auth
}

const ManagerDocuments: React.FC<idProps> = ({ managerId, history, location, auth }) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [addFileModalOpen, setAddFileModalOpen] = useState(false)

  const resultRef = useRef<DisplayDocs>(null)
  const addFileInputRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState("")
  const [hasError, setHasError] = useState(false)
  let match = useRouteMatch()
  const { resetErrors } = useContext(EditButtonContext)

  const [updateFileMutation] = useUpdateFileMetadataMutation()

  const { loading, data, error } = useManagerDocumentsQuery({
    // fetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    variables: { id: managerId }
  })

  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  const handleSubmit = () => {
    const currentDocument = resultRef!.current?.state.currentState
    const documentPermissions = getDocumentPermission({document: currentDocument as Document, page: "Manager", auth})
    if(!documentPermissions.edit){
      return
    }

    if(!currentDocument){
      return
    }
    if(hasError){
      return
    }
    setSaving(true)
    const { id, ...rest } = currentDocument
    const patch = { ...rest }
    let formattedPatch = convertLookupToString(
      patch,
      false,
    )
    let updateData = excludePropertyArray(formattedPatch, ["__typename", "name", "versions", "body", "mimeType", "versionId", "url", "lastUpdated"])
    updateData.managers = updateData.managers.map((ob:any) => ob.id)
    updateData.plans = updateData.plans.map((ob:any) => ob.id)
    updateData.products = updateData.products.map((ob:any) => ob.product.id)
    updateData.vehicles = updateData.vehicles.map((ob:any) => ob.vehicle.fundid)
    updateData.glidePaths = updateData.glidePaths.map((ob:any) => ob.id)
    updateData.clients = updateData.clients.map((ob:any) => ob.id)

    const input = { id, patch: updateData } as UpdateFileMetadataInput

    updateFileMutation({ variables: { input } })
      .then(result => {
        if (result && result.data) {
          //let unformattedNewData = { assets: result.data.assets?.org }
          setEditMode(false)
          setSaving(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error testManagerSummary", err.message)
        // throw new Error(`${err.message}`)
      })
  }

  const selectDocument = (id:string) => {
    resetErrors()
    history.push(`/managers/${managerId}/documents/${id}`)
  }

  const openFileModal = () => {
    // setAddFileModalOpen(!addFileModalOpen)
    addFileInputRef?.current?.click()
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // prevent enter from submitting form
    e.key === 'Enter' && e.preventDefault()
  }

  const heading = (
    <>
      <div className="pane pane-toolbar sticky-top above-picker">
        {auth.checkPermissions(["upload:documents"]) &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={openFileModal}>
            Add Document
            <FontAwesomeIcon
              icon="plus-circle"
              className="ml-2 text-callan-blue"
            />
          </Button>
        }
        <Form className="mr-2 pr-3 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyDown={handleEnterKeyDown}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        <AddFileModal
          inputRef={addFileInputRef}
          associationId={managerId}
          associationType={"Manager"}
          user={user}
          modalOpen={addFileModalOpen}
          setModalOpen={setAddFileModalOpen}
          setSelectedDocument={selectDocument}
          auth={auth}
        />
        <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} disableOnError={true} hideOnContext={true}/>
      </div>
    </>
  )

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (userError || user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error) {
    return (
      <Container fluid>
        {heading}
        <ErrorDisplay error={error}/>
      </Container>
    );
  }

  if (!loading && !!data && data.org?.__typename === 'Manager' && data.org.documents) {
    return (
      <Container fluid>
        {heading}
        <Result
          editMode={editMode}
          setEditMode={setEditMode}
          search={search}
          history={history}
          match={match}
          resultRef={resultRef}
          documents={data.org.documents as FileBasicInfoFragment[]}
          user={user}
          auth={auth}
          setHasError={setHasError}
          managerId={managerId}
        />
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

interface Props {
  editMode: boolean
  search: string
  history: any
  match: any
  resultRef: RefObject<DisplayDocs>
  documents: FileBasicInfoFragment[]
  user?: MeFragment
  auth: Auth
  setHasError: (value:boolean) => void
  managerId: number
  setEditMode:(mode:boolean) => void
}

class Result extends Component<Props> {
  render() {
    return (
      <Row>
        <Switch>
          <Route path={`${this.props.match.path}/:documentId?`}>
            <DocumentHolder
              editMode={this.props.editMode}
              setEditMode={this.props.setEditMode}
              search={this.props.search}
              history={this.props.history}
              resultRef={this.props.resultRef}
              documents={this.props.documents}
              user={this.props.user}
              auth={this.props.auth}
              setHasError={this.props.setHasError}
              managerId={this.props.managerId}
              key={"test"}
            />
          </Route>
        </Switch>
      </Row>
    )
  }
}

function DocumentHolder({
  resultRef,
  documents,
  editMode,
  setEditMode,
  search,
  history,
  user,
  auth,
  setHasError,
  managerId,
  }: any) {
  let { documentId } = useParams() as {documentId?: string}
  const context = useContext(EditButtonContext)
  const filteredDocuments = documents.filter((document: FileBasicInfoFragment) => {
    const permissions = getDocumentPermission({document: document as Document, page: "Manager", auth})
    return permissions.view
  })
  const { setOptions, showButtons, resetErrors } = context
  if (documentId === "new" || !documentId) {
    documentId = "0"
  }
  let document = _.find(filteredDocuments, (o) => {
    return o.id === documentId
  })
  const documentPermissions = getDocumentPermission({document: document as Document, page: "Manager", auth})
  if(showButtons !== documentPermissions.edit)
    setOptions({showButtons: documentPermissions.edit})
  const selectDocument = (id:string) => {
    resetErrors()
    // Replace when no previous id to keep back button
    if(documentId !== "0"){
      history.push(`/managers/${managerId}/documents/${id}`)
    } else {
      history.replace(`/managers/${managerId}/documents/${id}`)
    }
  }
  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={(path) => history.push(path)}
      />
      <DocumentsSidebar
        documents={filteredDocuments}
        selectDocument={(id:string) => selectDocument(id)}
        selectedDocument={documentId}
        search={search}
      />
      {!document &&
        <Col md="8" lg="9" className="pl-1">
          <div className="pane">
            <Row>
              <Col>
                <h3>No Document Selected</h3>
              </Col>
            </Row>
          </div>
        </Col>
      }
      {!!document &&
        <Query<FileDetailsQuery> query={FILE_QUERY} variables={{ id: documentId, type: FileTypeMapType.Manager }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
          {
            (results) => {
              if (results.loading) {
                return (
                  <Col md="8" lg="9" className="pl-1">
                    <div className="pane">
                      <PlaceHolder />
                    </div>
                  </Col>
                )
              }

              if (results.error) {
                return (
                  <Col md="8" lg="9" className="pl-1">
                    <div className="pane">
                      <UncontrolledAlert color="danger">
                        <h4>Error Fetching Interaction</h4>
                        <p>{results.error.message}</p>
                      </UncontrolledAlert>
                    </div>
                  </Col>
                )
              }

              const document = results.data?.file
              const typeMapping = results.data?.fileTypeMap
              if (!document) {
                return (
                  <Col md="8" lg="9" className="pl-1">
                    <div className="pane">
                      Document not Returned
                    </div>
                  </Col>
                )
              }
              if(document && typeMapping?.length){
                return(
                  <DisplayDocs
                    editMode={editMode}
                    setEditMode={setEditMode}
                    document={document as Document}
                    typeMapping={typeMapping as FileTypeSubTypeMap[]}
                    ref={resultRef}
                    user={user}
                    auth={auth}
                    refetchQuery={results.refetch}
                    associationId={managerId}
                    associationType={"Manager"}
                    hasError={(value:boolean) => setHasError(value)}
                  />
                )
              }
              return <PlaceHolder />
            }
          }
        </Query>
      }
    </>
  )
}


export default ManagerDocuments
