import classNames from 'classnames';
import React from 'react';
import Loading from './Loading';

const PlaceHolder = (props: {height?: number}) => {
  let style: {height?: number} = {}
  if (props.height) {
    style.height = props.height
  }
  return(
    <div className={classNames('d-flex flex-column w-100 align-items-center justify-content-center background-gray-10', {"placeholder-default-height": !props.height})} style={style}>
      <Loading />
    </div>
  )
}

export default PlaceHolder