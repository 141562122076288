import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import classnames from "classnames"
import { cloneDeep, compact, debounce, find, first, groupBy, remove, set, sortBy } from "lodash"
import moment from 'moment'
import React, { useEffect, useMemo, useState } from "react"
import { Button, Col, CustomInput, FormGroup, Label, Row, Table } from 'reactstrap'
import { ClientPortfolioFeeScheduleFragment } from '../../../__generated__/graphql'
import { FormInput } from "../../ui/Forms/FormInput"
import { DATE_API_FORMAT, FormInputField } from "../../../helpers/constant"
import classNames from "classnames"
import { HistoryModal } from "./HistoryModal"


type FeesProps = {
  data: ClientPortfolioFeeScheduleFragment[]
  editMode: boolean
  initialState: ClientPortfolioFeeScheduleFragment[]
  handleChange: (values: ClientPortfolioFeeScheduleFragment[]) => void
  endAssetDate?: string
  showFeeCollectOption?: boolean | null
  questionnaireFeesRequired?: boolean | null
  handleFeeRequiredChange: (value: boolean) => void
}

enum FeeTypeCode {
  Flat = 1,
  Tiered = 2,
}

type FeeTypeLookUp = {
  code: FeeTypeCode
  value: string
}

interface CumulativeFeeSchedule {
  date: string
  position: number
  cumulativeAccountSize: number | null
  managementFee?: number | null
}

const DEFAULT_NEW_END_DATE = "9999-12-31"
const DEFAULT_START_DATE = "1900-01-01"
const DEFAULT_LARGEST_ACCOUNT_SIZE = 10_000_000_000_000

const BatchEndDateInput: FormInputField[] = [
  {
    property: "previousDate",
    label: "Start",
    type: "date",
    readonly: true,
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
  {
    property: "date",
    label: "End",
    type: "date",
    subtype: "month",
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
]

interface FeeHistoryModalProps {
  isOpen: boolean
  toggle: () => void
  data: CumulativeFeeSchedule[]
}

export const FeeHistoryModal: React.FC<FeeHistoryModalProps> = ({
  isOpen,
  toggle,
  data,
}) => {

  const [historyData, sortedKeys, extraRows] = useMemo(() => {
    let groupedData = groupBy(data, 'date')
    let sortedKeys = Object.keys(groupedData).sort((a, b) => moment(b).diff(moment(a)))
    let extraRows = []
    extraRows.push({values: ['Date','Account Balance','','','Mangement Fee']})
    sortedKeys.forEach((key) => {
      let fees = groupedData[key]
      let displayDate = key === DEFAULT_NEW_END_DATE ? "Current" : moment(key).format("MM/DD/YYYY")
      if(fees.length > 1){
        fees.forEach((fee, idx) => {
          let fieldLabel = ""
          let firstFee:CumulativeFeeSchedule | undefined, secondFee:CumulativeFeeSchedule | undefined
          if(idx === 0){
            fieldLabel = "Less than"
            secondFee = fees[0]
          } else if(idx === fees.length - 1) {
            fieldLabel = "More than"
            firstFee = fees[fees.length - 2]
          } else {
            fieldLabel = "Between"
            firstFee = fees[idx - 1]
            secondFee = fees[idx]
          }
          extraRows.push({values: [displayDate, fieldLabel, firstFee?.cumulativeAccountSize?.toString() || "", secondFee?.cumulativeAccountSize?.toString() || "", Number(fee.managementFee?.toPrecision(6))?.toString()]})
        })
      } else if (fees.length === 1) {
        extraRows.push({values: [displayDate, "Flat Fee", "", "", Number(fees[0].managementFee?.toPrecision(6))?.toString()]})
      }
    })
    return [groupedData, sortedKeys, extraRows]
  }, [data])

  return (
    <HistoryModal isOpen={isOpen} toggle={toggle} title={"Fees"} xlSize>
      <div className="exportable-form" data-export-name={"Fees"} data-export-settings={JSON.stringify({extraRows})}><div></div></div>
      {sortedKeys.map((key) => {
        return (
          <CompositeFeesHistoryTable
            key={key}
            title={"fee-history"}
            data={historyData[key]}
          />
        )
      })}
    </HistoryModal>
  )
}

interface CompositeFeesHistoryTableProps {
  title: string
  data: CumulativeFeeSchedule[]
}

export const CompositeFeesHistoryTable: React.FC<CompositeFeesHistoryTableProps> = ({
  title,
  data,
}) => {
  const toDate = data[0]?.date === DEFAULT_NEW_END_DATE ? "Current" : `Ending ${moment(first(data)?.date).format("MM/DD/YYYY")}`

  return (
    <div className="">
      <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
        <h5 className="headline mt-3 text-nowrap d-inline-block">
          {toDate}
        </h5>
      </div>
      {data.length <= 1 && (
        <FormGroup row className={classNames("m-2")}>
          <Label sm={4}>Flat Fee</Label>
          <Col sm={6}>
            <FormInput
              property={"managementFee"}
              displayName={""}
              type={"float"}
              subtype={"percent"}
              placeholder={"0.000%"}
              idx={0}
              editMode={false}
              propertyVal={data[0]?.managementFee}
              updateValue={(value: any) =>  null}
              subClasses={{ inputClasses: "text-left" }}
              baseDecimalPlaces={3}
            />
          </Col>
        </FormGroup>
      )}
      {data.length > 1 && (
        <Table className={classNames("fee-table")}>
          <thead>
            <tr>
              <th>ACCOUNT BALANCE</th>
              <th></th>
              <th>MANAGEMENT FEE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((fee, idx) => {
              let fieldLabel = ""
              let firstFee:CumulativeFeeSchedule | undefined, secondFee:CumulativeFeeSchedule | undefined
              if(idx === 0){
                fieldLabel = "Less than"
                firstFee = data[0]
                if(data.length > 2) {
                  secondFee = data[1]
                }
              } else if(idx === data.length - 1) {
                fieldLabel = "More than"
                firstFee = data[data.length - 2]
              } else {
                fieldLabel = "Between"
                firstFee = data[idx - 1]
                secondFee = data[idx]
              }

              return (
                <tr key={idx}>
                  <td>
                    {fieldLabel}
                  </td>
                  <td>
                    <Row>
                      <Col sm={5}>
                        <FormInput
                          property={"accountSize-first"}
                          displayName={""}
                          type={"number"}
                          subtype={"currency"}
                          idx={idx}
                          editMode={false}
                          propertyVal={firstFee.cumulativeAccountSize}
                          updateValue={(value: any) =>  null}
                          subClasses={{ inputClasses: "text-right" }}
                        />
                      </Col>
                      <Col sm={2}>
                      {fieldLabel === "Between" && (
                        "-"
                      )}
                      </Col>
                      <Col sm={5}>
                        {fieldLabel === "Between" && (
                            <FormInput
                              property={"accountSize-second"}
                              displayName={""}
                              type={"number"}
                              subtype={"currency"}
                              idx={idx}
                              editMode={false}
                              propertyVal={secondFee?.cumulativeAccountSize}
                              updateValue={(value: any) =>  null}
                              subClasses={{ inputClasses: "text-left" }}
                            />
                        )}
                      </Col>
                    </Row>
                  </td>
                  <td>
                  <FormInput
                    property={"managementFee"}
                    displayName={""}
                    type={"float"}
                    subtype={"percent"}
                    placeholder={"0.000%"}
                    idx={idx}
                    editMode={false}
                    propertyVal={fee.managementFee}
                    baseDecimalPlaces={3}
                    updateValue={(value: any) =>  null}
                    subClasses={{ inputClasses: "text-left" }}
                  />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
    </div>
  )
}

const DEFAULT_FEE_TYPE: FeeTypeLookUp[] =
[
  {
    code: FeeTypeCode.Flat,
    value: "Flat fee for all accounts",
  },
  {
    code: FeeTypeCode.Tiered,
    value: "Tiered pricing",
  }
]


interface FeeTypePickerProps {
  onChange: (type: FeeTypeCode | null) => void
  state: FeeTypeCode | null
  displayConfig: { title: string; default?: FeeTypeCode}
  uniqId?: string
}

export const FeeTypePicker: React.FC<FeeTypePickerProps> = ({
  onChange,
  state,
  displayConfig,
  uniqId,
}) => {
  return (
    <div className="ml-4 pl-2">
      <FormGroup row name={`fee-structure`}>
        {displayConfig.title && (
          <Label sm={3} htmlFor={`fee-structure`}>
            {displayConfig.title}:
          </Label>
        )}
        <div
          className={classnames(
            "d-flex align-items-center justify-content-between",
            "col-sm-9"
          )}
        >
          {DEFAULT_FEE_TYPE.map(({code, value}, idx) => {
            return (
              <CustomInput
                key={idx}
                id={`fee-structure-${uniqId}-${idx}-${code}`}
                className="boolean-radio custom-radio custom-radio-sm custom-control custom-control-inline ml-4"
                bsSize="sm"
                type="radio"
                value={code}
                checked={state === code}
                onChange={() => {
                  onChange(code)
                }}
                label={value}
                inline
              />
            )
          })}
        </div>
      </FormGroup>
    </div>
  )
}

// const sortData = (props: ClientPortfolioFeeScheduleFragment[]): FormattedFeeScheduleData[] => {
//   let {negotiatedFee, statedFeeAsOfDate, } =  props
//   let sorted = _.orderBy(negotiatedFee, ["date", "rate"],["desc", 'desc'])
//   let groupedData = _.groupBy(sorted, 'date')
//   let result = Object.keys(groupedData).map(date => {
//     let value = groupedData[date]
//     return {date, ...getFeeTiers({date, feeSchedule: value}), statedFeeAsOfDate, isStatedFee: !!statedFeeAsOfDate?.includes(date)}
//   })
//   // console.log("sortData", {result})
//   return result
// }

type TableKey = "new" | "current"

type TableVisibility = {[key in TableKey]: boolean}

const getCurrentFee = (props: CumulativeFeeSchedule[]) => {
  return props.filter((fee: CumulativeFeeSchedule) => fee?.date === "9999-12-31")
}

const getFeeHistoryData = (props: CumulativeFeeSchedule[]) => {
  return props.filter((fee: CumulativeFeeSchedule) => fee?.date !== "9999-12-31")
}

const cumulateFeeSchedule = (fees: ClientPortfolioFeeScheduleFragment[]) => {
  let result: CumulativeFeeSchedule[] = []
  let clonedFees = cloneDeep(fees)
  const sortedFeeSchedule = clonedFees
    .sort((a, b) => {
      if (a?.date && b?.date && a?.date !== b?.date) {
        return moment(b.date).diff(moment(a.date))
      }
      if (a?.managementFee && b?.managementFee) {
        return b.managementFee - a.managementFee
      }
      return 0
    })
  let cumulativeAccountSize:{[key in string]: {value: number, position: number}} = {}
  sortedFeeSchedule.forEach((fee) => {
    let currentDateFee = cumulativeAccountSize[fee.date] || {value: 0, position: 0}
    currentDateFee.value = (currentDateFee?.value || 0) + (fee.accountSize || 0)
    result.push({date: fee.date, cumulativeAccountSize: currentDateFee.value, managementFee: (fee.managementFee || 0)*100, position: currentDateFee.position})
    currentDateFee.position += 1
    cumulativeAccountSize[fee.date] = currentDateFee
  })
  return result
}

const unCumulateFeeSchedule = (fees: CumulativeFeeSchedule[]) => {
  let result: ClientPortfolioFeeScheduleFragment[] = []
  let clonedFees = cloneDeep(fees)
  const sortedFeeSchedule = clonedFees
    .sort((a, b) => {
      if (a?.date && b?.date && a?.date !== b?.date) {
        return moment(b.date).diff(moment(a.date))
      }
      if (a?.position !== undefined && b?.position !== undefined) {
        return a.position - b.position
      }
      return 0
    })
  let previousAccountSize:{[key in string]: number} = {}
  sortedFeeSchedule.forEach((fee, idx) => {
    let accountSize = (fee.cumulativeAccountSize || 0) - (previousAccountSize[fee.date] || 0)
    if(idx === fees.length - 1) {
      accountSize = DEFAULT_LARGEST_ACCOUNT_SIZE
    }
    result.push({date: fee.date, accountSize, managementFee: (fee.managementFee || 0)/100, __typename: "ClientPortfolioFeeSchedule" as "ClientPortfolioFeeSchedule"})
    previousAccountSize[fee.date] = fee.cumulativeAccountSize || 0
  })
  return result
}

export const ClientPortfolioFees: React.FC<FeesProps> = (props) => {
  let {data, initialState, editMode, handleChange, endAssetDate, questionnaireFeesRequired, showFeeCollectOption, handleFeeRequiredChange} = props

  const [formattedData, setFormattedData] = useState(cumulateFeeSchedule(data))
  const [newTableData, setNewTableData] = useState<CumulativeFeeSchedule[]>([])
  const [currentTableData, setCurrentTableData] = useState(getCurrentFee(formattedData))
  const [currentTableBatchEndDate, setCurrentTableBatchEndDate] = useState<string | null>(null)
  // history modal
  const [feeHistoryData, setFeeHistoryData] = useState(getFeeHistoryData(formattedData))
  const [showFullHistoryModal, setShowFullHistoryModal] = useState<boolean>(false)

  const [tableVisibility, setTableVisibility] = useState<TableVisibility>({
    new: false,
    current: !!currentTableData,
  })

  const toggleFullHistoryModal = () => setShowFullHistoryModal(!showFullHistoryModal)

  const endCurrent = () => {
    console.log('end current')
    setTableVisibility((prevState) => ({
      ...prevState,
      new: true,
    }))
    setNewTableData(cloneDeep(currentTableData))
  }

  const revertEndCurrent = () => {
    setCurrentTableBatchEndDate(null)
    setTableVisibility((prevState) => ({
      ...prevState,
      new: false,
    }))
  }

  const onChangeCallback = debounce((callback: (values: CumulativeFeeSchedule[]) => CumulativeFeeSchedule[]) => {
    let newValues = callback(currentTableData)
    const newDataset = [...newTableData, ...newValues, ...feeHistoryData]
    console.log("onChangeCallback", {newValues, newDataset})
    setFormattedData(newDataset)
    setCurrentTableData(newValues)
    // setFeeHistoryData(getFeeHistoryData(newDataset))
    handleChange(unCumulateFeeSchedule(newDataset))
  }, 800)

  const onChangeNewCallback = debounce((callback: (values: CumulativeFeeSchedule[]) => CumulativeFeeSchedule[]) => {
    let newValues = callback(newTableData)
    const newDataset = [...newValues, ...currentTableData, ...feeHistoryData]
    console.log("onChangeNewCallback", {newValues, newDataset})
    setFormattedData(newDataset)
    setNewTableData(newValues)
    // setFeeHistoryData(getFeeHistoryData(newDataset))
    handleChange(unCumulateFeeSchedule(newDataset))
  }, 800)

  // useEffect(() => {
  //   let newState = cumulateFeeSchedule(data)
  //   setFormattedData(newState)
  //   setCurrentTableData(getCurrentFee(newState))
  //   // setNewTableData(getCurrentFee(newState))
  //   if(!editMode) {
  //     setFeeHistoryData(getFeeHistoryData(newState))
  //   }
  // }, [data])

  useEffect(() => {
    if(!editMode) {
      let newState = cumulateFeeSchedule(initialState)
      // setNewTableData(getCurrentFee(newState)) TODO: GET New table data
      setNewTableData([])
      setFormattedData(newState)
      setCurrentTableData(getCurrentFee(newState))
      setFeeHistoryData(getFeeHistoryData(newState))
      setCurrentTableBatchEndDate(null)
      setTableVisibility((prevState) => ({
        ...prevState,
        new: false,
      }))
    }
  }, [editMode, initialState])

  useEffect(() => {
    if(currentTableBatchEndDate) {
      let nextCurrentTableData: CumulativeFeeSchedule[] =
        currentTableData?.map((el, index) => {
        let newValue = cloneDeep(el)
        if (newValue) {
          set(newValue, "date", currentTableBatchEndDate)
        }
        return newValue
        }) || []
      const newDataset = [...newTableData, ...nextCurrentTableData, ...feeHistoryData]
      setCurrentTableData(nextCurrentTableData)
      handleChange(unCumulateFeeSchedule(newDataset))
    }
  }, [currentTableBatchEndDate])

  let managementFeeTableData = {editMode, endCurrent, revertEndCurrent, tableVisibility, setTableVisibility, endAssetDate, toggleFullHistoryModal}
  let currentTableProps = {...managementFeeTableData, onChangeCallback, tableKey: "current", data: currentTableData, setData: setCurrentTableData, setCurrentTableBatchEndDate, currentTableBatchEndDate, editMode: editMode, hasFullHistoryButton: feeHistoryData.length > 0, previousDate: feeHistoryData[0]?.date || DEFAULT_START_DATE} as ManagementFeeTableProps
  let newTableProps = {...managementFeeTableData, onChangeCallback: onChangeNewCallback, tableKey: "new", data: newTableData, setData: setNewTableData, currentTableBatchEndDate, showSuffixEndDate: true} as ManagementFeeTableProps

  return (
  <Row className={classnames("pl-0")} key={`ClientPortfolioFees`}>
    <Col className={"px-0"}>
      {tableVisibility.new &&
        <ManagementFeeTable
          {...newTableProps}
        />
      }
      {tableVisibility.current &&
        <ManagementFeeTable
          {...currentTableProps}
        />
      }
    </Col>
    <FeeHistoryModal
      isOpen={showFullHistoryModal}
      toggle={toggleFullHistoryModal}
      data={formattedData}
    />
    {showFeeCollectOption && (
      <>
        <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
          <div className="white-space-nowrap">
            <h5 className="headline mt-3 text-nowrap d-inline-block">
              Fee Options
            </h5>
          </div>
        </div>
        <div className="w-100 ml-3">
          <FormInput
            key={`clientSpecificDataCollectionFields.questionnaireFeesRequired`}
            property={"clientSpecificDataCollectionFields.questionnaireFeesRequired"}
            displayName={"Allow the manager to update these fees"}
            subClasses={{
              labelClasses: "col-sm-5",
              inputWrapperClasses: "col-sm-4 pl-4 left-aligned-boolean",
            }}
            type={"radio"}
            subtype={"boolean"}
            idx={1}
            editMode={editMode}
            propertyVal={questionnaireFeesRequired}
            updateValue={(value) => handleFeeRequiredChange(value)}
          />
        </div>
      </>
    )}
  </Row>)
}

type changeCallback = (values:CumulativeFeeSchedule[]) => CumulativeFeeSchedule[]

export interface ManagementFeeTableProps {
  editMode: boolean,
  tableKey: TableKey,
  data: CumulativeFeeSchedule[],
  endCurrent: () => void,
  revertEndCurrent: () => void,
  currentTableBatchEndDate?: string
  endAssetDate?: string
  hasFullHistoryButton?: boolean
  onChangeCallback: (changeCallback:changeCallback) => void
  // [property: string]: any
  tableVisibility: TableVisibility
  setTableVisibility: React.Dispatch<React.SetStateAction<TableVisibility>>
  setCurrentTableBatchEndDate?: React.Dispatch<React.SetStateAction<string | null>>
  toggleFullHistoryModal?: () => void
  previousDate?: string
}

const ManagementFeeTable:React.FC<ManagementFeeTableProps> = (props) => {
  let {editMode, data, onChangeCallback, tableKey, endCurrent, revertEndCurrent, currentTableBatchEndDate, tableVisibility, setTableVisibility, endAssetDate, toggleFullHistoryModal, hasFullHistoryButton, previousDate} = props
  const [initialData, setInitialData] = useState(data)
  const showFullHistoryButton = tableKey ==="current" && !!hasFullHistoryButton
  const showEndDate = (tableKey === "current" && tableVisibility["new"]) && data.length > 0
  let greyClass = ""
  if (tableKey === "current" && tableVisibility["new"]) {
    greyClass = "background-gray-20"
  }
  const addTier = () => {
    onChangeCallback((updateData) => {
      let newData = cloneDeep(updateData)
      let newFee = {date: data[0]?.date || DEFAULT_NEW_END_DATE, cumulativeAccountSize: data[data.length - 2].cumulativeAccountSize, managementFee: data[data.length - 1].managementFee, position: data.length}
      newData.splice(-1, 0, newFee)
      newData = newData.map((item, idx) => {
        item.position = idx
        return item
      })
      return newData
    })
  }
  const removeTier = (fee?: CumulativeFeeSchedule) => {
    if(!fee){
      return
    }
    onChangeCallback((updateData) => {
      let newData = cloneDeep(updateData)
      let idx = newData.findIndex((item) => item.date === fee.date && item.cumulativeAccountSize === fee.cumulativeAccountSize && item.managementFee === fee.managementFee)
      newData.splice(idx, 1)
      newData = newData.map((item, idx) => {
        item.position = idx
        return item
      })
      return newData
    })
  }
  useEffect(() => {
    if(!editMode) {
      setInitialData(data)
    }
  },[editMode])

  const batchEndDateComponent = () => {
    let setCurrentTableBatchEndDate = props?.setCurrentTableBatchEndDate

    let labelClass = ""
    if(tableKey === "current") {
      labelClass = "background-private-background text-accent-red"
    }

    return (
      <div key={"batch-end-date"} className={classnames("d-flex w-100 justify-content mb-3", labelClass)}>
        {BatchEndDateInput.map((headerDef, idx) => {
          let property = headerDef.property
          let propertyVal:string|null = ""
          if(tableKey === "current" && property === "date") {
            propertyVal = props?.currentTableBatchEndDate || null
          }else if (property === "previousDate") {
            if(previousDate) {
              propertyVal = moment(previousDate || "").add(1, 'day').format(DATE_API_FORMAT)
            } else {
              propertyVal = moment(DEFAULT_START_DATE).add(1, 'day').format(DATE_API_FORMAT)
            }
          }

          return(
            <div key={idx} className={classnames('d-flex ml-3')}>
              <div className="client-portfolio-date-label">
                {headerDef.label}
              </div>
              <FormInput
                key={headerDef.property}
                property={property}
                displayName={""}
                type={headerDef.type}
                subtype={headerDef.subtype}
                idx={`${tableKey}-composite-member-item-batch-end-date`}
                editMode={editMode}
                propertyVal={propertyVal}
                readonly={!!headerDef.readonly}
                updateValue={(value: any) => {
                  // updateValue(value, headerDef.type, headerDef.field)
                  // handleBatchCurrentEndDateChange(value, data, key)
                  setCurrentTableBatchEndDate && setCurrentTableBatchEndDate(value)
                }}
                inputProps={{
                  min: !!previousDate ? previousDate : "1900-01-01",
                }}
                required={headerDef.required}
                // optionSource={headerDef.optionSource}
                subClasses={headerDef.subClasses || {}}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      <div className={"d-flex justify-content-between w-100 border-bottom border-gray-50"}>
        <div className="white-space-nowrap">
          <h5 className="headline text-uppercase mt-3 text-nowrap d-inline-block">
            {`Fees `}
          </h5>
          <h5 className="headline text-uppercase text-gray-70 pl-1 mt-3 d-inline-block">{(showEndDate || tableKey !== "current") && ` (${tableKey})`}</h5>
        </div>
        <div className="mt-3">
          {editMode && tableKey === "current" && endAssetDate === DEFAULT_NEW_END_DATE && (
            !tableVisibility["new"] && <Button color="link" className="text-callan-blue btn-thin pt-0 pb-0" onClick={() => endCurrent()}>
              {"End Current & Start New"}
              <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
            </Button>)
          }
          {editMode && (tableKey === "new") &&
            (<Button color="link" className="text-callan-blue btn-thin pt-0" onClick={() => revertEndCurrent()}>
              {"Delete"}
              <FontAwesomeIcon icon="trash" className="ml-2 text-callan-blue" />
            </Button>)
          }
          {tableKey === "current" && showFullHistoryButton &&
            (<Button color="link" className="text-callan-blue btn-thin pt-0 pb-0" onClick={toggleFullHistoryModal}>
              {"View Full History"}
              <FontAwesomeIcon icon="history" className="ml-2 text-callan-blue" />
            </Button>)
          }
          {/* {tableKey === "current" && endAssetDate === DEFAULT_NEW_END_DATE &&
            <>
              Beginning {moment(data[0]?.date).add(1, "day").format("MM/DD/YYYY")}
            </>
          } */}
          {tableKey === "current" &&
            <>
              Ending {moment(data[0]?.date).format("MM/DD/YYYY")}
            </>
          }
        </div>
      </div>
      {editMode && !showEndDate &&
        <div className={"d-flex justify-content-between w-100 background-gray-20"}>
          <div className="white-space-nowrap">
            <FeeTypePicker
              onChange={(feeType) => {
                if(feeType === FeeTypeCode.Flat) {
                  onChangeCallback((updateData) => {
                    if(initialData.length === 1){
                      return initialData
                    } else {
                      let newData = cloneDeep(updateData)
                      let currentFees = remove(newData, (fee) => fee.date === data[0].date)
                      let lastFee = find(currentFees, (fee) => fee.cumulativeAccountSize === DEFAULT_LARGEST_ACCOUNT_SIZE)
                      if(lastFee) {
                        return [...newData, {date: DEFAULT_NEW_END_DATE, cumulativeAccountSize: DEFAULT_LARGEST_ACCOUNT_SIZE, managementFee: lastFee.managementFee, position: 0}]
                      } else {
                        return [...newData, {date: DEFAULT_NEW_END_DATE, cumulativeAccountSize: DEFAULT_LARGEST_ACCOUNT_SIZE, managementFee: null, position: 0}]
                      }
                    }
                  })
                } else if (feeType === FeeTypeCode.Tiered) {
                  onChangeCallback((updateData) => {
                    if(initialData.length > 1){
                      return initialData
                    } else {
                      let newData = cloneDeep(updateData)
                      let currentFees = remove(newData, (fee) => fee.date === data[0].date)
                      if(currentFees.length === 0) {
                        return [...newData, {date: DEFAULT_NEW_END_DATE, cumulativeAccountSize: null, managementFee: null, position: 0}, {date: DEFAULT_NEW_END_DATE, cumulativeAccountSize: DEFAULT_LARGEST_ACCOUNT_SIZE, managementFee: null, position: 1}]
                      } else if (currentFees.length === 1) {
                        return [...newData, {date: currentFees[0]?.date || DEFAULT_NEW_END_DATE, cumulativeAccountSize: null, managementFee: currentFees[0].managementFee, position: 1}, ...currentFees]
                      } else {
                        return updateData
                      }
                    }
                  })
                }
              }}
              state={data.length <= 1 ? FeeTypeCode.Flat : FeeTypeCode.Tiered}
              displayConfig={{title: "Fee Structure"}}
              uniqId={tableKey}
            />
          </div>
        </div>
      }
      {data.length <= 1 && (
        <FormGroup row className={classNames("m-0 p-2", greyClass)}>
          <Label sm={4}>Flat Fee</Label>
          <Col sm={6}>
            <FormInput
              property={"managementFee"}
              displayName={""}
              type={"float"}
              subtype={"percent"}
              placeholder={"0.000%"}
              idx={0}
              editMode={editMode}
              propertyVal={data[0]?.managementFee}
              updateValue={(value: any) => {
                onChangeCallback((updateData) => {
                  let newData = cloneDeep(updateData)
                  let updatedFeeIndex = newData.findIndex((fee) => fee.date === data[0].date && fee.cumulativeAccountSize === data[0].cumulativeAccountSize)
                  if(updatedFeeIndex >= 0) {
                    newData[updatedFeeIndex].managementFee = value
                  } else if(updatedFeeIndex === -1) {
                    newData.push({date: DEFAULT_NEW_END_DATE, cumulativeAccountSize: DEFAULT_LARGEST_ACCOUNT_SIZE, managementFee: value, position: 0})
                  }
                  return newData
                })
              }}
              required={data.length > 0}
              subClasses={{ inputClasses: "text-left" }}
              baseDecimalPlaces={3}
            />
          </Col>
        </FormGroup>
      )}
      {data.length > 1 && (
        <Table className={classNames("fee-table", greyClass)}>
          <thead>
            <tr>
              <th>ACCOUNT BALANCE</th>
              <th></th>
              <th>
                <div className="d-inline-flex align-items-center tooltip-icon" id="managementFeeTooltip">
                  MANAGEMENT FEE
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    className="ml-2 mt-0"
                    size="sm"
                  />
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((fee, idx) => {
              let fieldLabel = ""
              let firstFee:CumulativeFeeSchedule | undefined, secondFee:CumulativeFeeSchedule | undefined, previousFee:CumulativeFeeSchedule | undefined, nextFee:CumulativeFeeSchedule | undefined
              if(idx === 0){
                fieldLabel = "Less than"
                previousFee = {cumulativeAccountSize: 0, date: DEFAULT_NEW_END_DATE, managementFee: data[0].managementFee, position: -1}
                firstFee = data[0]
                if(data.length > 2) {
                  secondFee = data[1]
                }
              } else if(idx === data.length - 1) {
                fieldLabel = "More than"
                if(data.length > 2) {
                  previousFee = data[data.length - 3]
                }
                firstFee = data[data.length - 2]
              } else {
                fieldLabel = "Between"
                previousFee = data[idx - 2]
                firstFee = data[idx - 1]
                secondFee = data[idx]
                if(data.length > idx) {
                  nextFee = data[idx + 1]
                }
              }
              // console.log(idx, {fieldLabel, previousFee, firstFee, secondFee, nextFee})

              return (
                <tr key={idx}>
                  <td>
                    {fieldLabel}
                  </td>
                  <td>
                    <Row>
                      <Col sm={5}>
                        <FormInput
                          property={"accountSize-first"}
                          displayName={""}
                          type={"number"}
                          subtype={"currency"}
                          idx={idx}
                          editMode={editMode}
                          propertyVal={firstFee.cumulativeAccountSize}
                          updateValue={(value: any) => {
                            onChangeCallback((updateData) => {
                              let newData = cloneDeep(updateData)
                              let updatedFeeIndex = newData.findIndex((fee) => fee.date === firstFee?.date && fee.cumulativeAccountSize === firstFee?.cumulativeAccountSize && fee.position === firstFee?.position)
                              if(updatedFeeIndex >= 0) {
                                newData[updatedFeeIndex].cumulativeAccountSize = value
                              }
                              return newData
                            })
                          }}
                          required
                          subClasses={{ inputClasses: "text-right" }}
                          fieldValidations={{
                            min: previousFee?.cumulativeAccountSize || undefined,
                            max: secondFee?.cumulativeAccountSize || undefined
                          }}
                        />
                      </Col>
                      <Col sm={2}>
                      {fieldLabel === "Between" && (
                        "-"
                      )}
                      </Col>
                      <Col sm={5}>
                        {fieldLabel === "Between" && (
                            <FormInput
                              property={"accountSize-second"}
                              displayName={""}
                              type={"number"}
                              subtype={"currency"}
                              idx={idx}
                              editMode={editMode}
                              propertyVal={secondFee?.cumulativeAccountSize}
                              updateValue={(value: any) => {
                                onChangeCallback((updateData) => {
                                  let newData = cloneDeep(updateData)
                                  let updatedFeeIndex = newData.findIndex((fee) => fee.date === secondFee?.date && fee.cumulativeAccountSize === secondFee?.cumulativeAccountSize && fee.position === secondFee?.position)
                                  if(updatedFeeIndex >= 0) {
                                    newData[updatedFeeIndex].cumulativeAccountSize = value
                                  }
                                  return newData
                                })
                              }}
                              required
                              subClasses={{ inputClasses: "text-left" }}
                              fieldValidations={{
                                min: firstFee?.cumulativeAccountSize || undefined,
                                max: nextFee?.cumulativeAccountSize || undefined
                              }}
                            />
                        )}
                      </Col>
                    </Row>
                  </td>
                  <td>
                  <FormInput
                    property={"managementFee"}
                    displayName={""}
                    type={"float"}
                    subtype={"percent"}
                    placeholder={"0.000%"}
                    idx={idx}
                    editMode={editMode}
                    propertyVal={fee.managementFee}
                    updateValue={(value: any) => {
                      onChangeCallback((updateData) => {
                        let newData = cloneDeep(updateData)
                        let updatedFeeIndex = newData.findIndex((fee) => fee.date === data[idx].date && fee.cumulativeAccountSize === data[idx].cumulativeAccountSize && fee.position === data[idx]?.position)
                        if(updatedFeeIndex >= 0) {
                          newData[updatedFeeIndex].managementFee = value
                        }
                        return newData
                      })
                    }}
                    required
                    subClasses={{ inputClasses: "text-left" }}
                    fieldValidations={{
                      max: idx > 0 ? data[idx - 1]?.managementFee || undefined : undefined,
                      min: idx <= (data.length -1) ? data[idx + 1]?.managementFee || 0 : 0
                    }}
                    baseDecimalPlaces={3}
                  />
                  </td>
                  <td>
                    {editMode && fieldLabel === "Between" && (
                      <FontAwesomeIcon icon="trash" onClick={() => removeTier(firstFee)} />
                    )}
                  </td>
                </tr>
              )
            })}
            {editMode && !showEndDate && (
              <tr>
                <td colSpan={4}>
                  <Button color="link" className="text-callan-blue btn-thin pt-0 pb-0" onClick={() => addTier()}>
                    {"Add Tier"}
                    <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
      {showEndDate && (
        batchEndDateComponent()
      )}
    </div>)
}




