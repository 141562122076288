import classnames from 'classnames'
import React, { useState } from 'react'
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Nav, NavItem, NavLink, Col, Row, Container } from 'reactstrap'

import Auth from "../../Auth/Auth"
import {ProductBasicQuery} from '../../__generated__/graphql'
import { appProps } from '../../queries/extended/appProps'
import { ProductRouteProps } from '../../queries/extended/types/Product'

import ProductAssetsAccounts from './ProductAssetsAccounts'
import ProductAssetsClientType from './ProductAssetsClientType'
import ProductAssetsLocation from './ProductAssetsLocation'
import ProductAssetsVehicle from './ProductAssetsVehicle'

const ProductAssetsSubTabs = {
  client_type: "1",
  location: "2",
  vehicle: "3",
  accounts: "4"
}

const AccountsViewingTypes = [
  'OpenEndedEquity',
  'OpenEndedPassiveEquity',
  'OpenEndedFixedIncome',
  'OpenEndedPassiveFixedIncome',
  'OpenEndedHedgeFund',
  'ClosedEndedHedgeFund',
  'OpenEndedMAC',
  'OpenEndedPassiveMAC',
  'OpenEndedTargetDate',
  'OpenEndedRealAssets',
  'OtherProduct'
]

const VehicleViewingTypes = [
  'OpenEndedEquity',
  'OpenEndedFixedIncome',
  'OpenEndedHedgeFund',
  'ClosedEndedHedgeFund',
  'OpenEndedMAC',
  'OpenEndedPassiveEquity',
  'OpenEndedPassiveFixedIncome',
  'OpenEndedTargetDate',
  'OpenEndedPassiveMAC',
  'OpenEndedRealAssets',
  'OtherProduct'
]

const LocationViewingTypes = [
  'OpenEndedEquity',
  'OpenEndedFixedIncome',
  'OpenEndedHedgeFund',
  'OpenEndedMAC',
  'OpenEndedPassiveEquity',
  'OpenEndedPassiveFixedIncome',
  'OpenEndedPassiveMAC',
  'OpenEndedRealAssets',
  'OtherProduct'
]

const getInitialTab = (subtype: string | undefined) => {
  if (!subtype) {
    return ProductAssetsSubTabs["client_type"]
  }
  if (subtype in ProductAssetsSubTabs) {
    let s =
      ProductAssetsSubTabs[subtype as keyof typeof ProductAssetsSubTabs]
    return s
  } else {
    return ProductAssetsSubTabs["client_type"]
  }
}

interface ProductAssetsProps {
  data: ProductBasicQuery,
  id: number,
  auth: Auth
}

const ProductAssets: React.FC<appProps & ProductRouteProps & ProductAssetsProps> = props => {
  const { id, data, auth } = props
  const productType = data.product?.__typename

  const params = useParams() as {
    subtype?: string
    type?: string
    productId?: string
  }
  const history = useHistory()
  const match = useRouteMatch() || props.match
  let urlWithoutSubtype =
    params && params.subtype
      ? match.url.slice(0, -params.subtype.length - 1)
      : match.url
  const handleRedirect = (tab: string) => {
    history.push(urlWithoutSubtype + "/" + tab)
  }

  if (!productType) return (<>No Product Type, shouldnt happen</>)

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Nav className="sub-nav sub-nav-secondary" tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutSubtype}/client_type`) === 0
                  })}
                  onClick={() => handleRedirect("client_type")}
                >
                  Client Type
                </NavLink>
              </NavItem>
              { LocationViewingTypes.indexOf(productType) !== -1 &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutSubtype}/location`) === 0
                    })}
                    onClick={() => handleRedirect("location")}
                  >
                    Location
                  </NavLink>
                </NavItem>
              }
              { VehicleViewingTypes.indexOf(productType) !== -1 &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutSubtype}/vehicle`) === 0
                    })}
                    onClick={() => handleRedirect("vehicle")}
                  >
                    Vehicle
                  </NavLink>
                </NavItem>
              }
              { AccountsViewingTypes.indexOf(productType) !== -1 &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutSubtype}/accounts`) === 0
                    })}
                    onClick={() => {
                      handleRedirect("accounts")
                    }}
                  >
                    Accounts
                  </NavLink>
                </NavItem>
              }


            </Nav>
            <Switch>
              <Route
                exact
                path="/products/:productId(\d+)/assets/:subtype(client_type)"
                component={() => <ProductAssetsClientType productId={id} auth={auth}/>}
              />
              { LocationViewingTypes.indexOf(productType) !== -1 &&
                <Route
                  exact
                  path="/products/:productId(\d+)/assets/:subtype(location)"
                  component={() => <ProductAssetsLocation productId={id} auth={auth}/>}
                />
              }
              { VehicleViewingTypes.indexOf(productType) !== -1 &&
                <Route
                  exact
                  path="/products/:productId(\d+)/assets/:subtype(vehicle)"
                  component={() => <ProductAssetsVehicle productId={id} auth={auth}/>}
                />
              }
              { AccountsViewingTypes.indexOf(productType) !== -1 &&
                <Route
                  exact
                  path="/products/:productId(\d+)/assets/:subtype(accounts)"
                  component={() => <ProductAssetsAccounts productId={id} auth={auth}/>}
                />
              }
              <Redirect
                from="/products/:productId(\d+)/assets"
                to="/products/:productId(\d+)/assets/client_type"
              />
            </Switch>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductAssets
