import {
  CellClassParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core"
import _ from "lodash"
import moment from "moment"
import numbro from "numbro"
import { appDate } from "../../Context/CalendarContext"
import { DATE_API_FORMAT } from "../../helpers/constant"
import {
  currencyFormatter,
  dateFilterParams,
  dateFormatter,
  headerComponentWithIcon,
  mappingValueGetter,
  recentAUMValueGetter,
  simpleMappingFormatter,
  statusEnumToValue,
} from "../../helpers/helpers"
import { AssetClassFragment, GetAllAssetClassesQuery, Performance, ProductOpinion, StatusCode } from "../../__generated__/graphql"
import { checkOrCrossRenderer, textLink } from "../../helpers/agGridHelpers"

const managersLinkRenderer = (params: ICellRendererParams) => {
  return textLink({
    text: params.value,
    url: "/managers/" + params?.data?.product?.manager?.id + "/profile",
    showHref: true,
    newTab: true,
  })
}

const productLinkRenderer = (params: ICellRendererParams) => {
  return textLink({ text: params.value, url: "/products/" + params?.data?.product?.id + "/profile", showHref: true, newTab: true })
}

const assetClassCodeFormatter = (params: ValueFormatterParams, assetClasses: {code: any, value?: any}[]) => assetClasses.find(el => el.code === params.value)?.value

const assetClassComparator = (valueA: any, valueB: any ,assetClasses: {code: any, value?: any}[] ) => {
  let valueFromA = assetClasses.find(el => el.code === valueA)?.value
  let valueFromB = assetClasses.find(el => el.code === valueB)?.value
  if (valueFromA === valueFromB) {
    return 0
  }
  return (valueFromA > valueFromB) ? 1: -1
}

const fundSizeCurrencyFormatter = (params: ValueFormatterParams) => {
  // ag-grid helper method
  let value = params.value
  let ending
  if (Number.isFinite(value) && value !== 0) {
    return numbro((value || 0)/1_000_000).format("$0,0.00") + (ending || "")
  } else {
    return "-"
  }
}

const ProductBasicInfoColumnsDef = (assetClasses: GetAllAssetClassesQuery["assetClasses"]) => {
  let acc: ({ __typename: "AssetClass" } & AssetClassFragment)[] = []
  let result = assetClasses.reduce((acc, el) => {
    let children = el?.children
    return [...acc, ...children]
  }, acc)
  let strategyTestOptions = result.map((el) => {
    let {code, value} = el
    return {code, value}
  })
  return [
    {
      headerName: "Manager Name",
      field: "product.manager.name",
      filterParams: {
        maxNumConditions: 1,
      },
      sortable: true,
      width: 300,
      cellRenderer: managersLinkRenderer,
    },
    {
      headerName: "Product Name",
      field: "product.name",
      filterParams: {
        maxNumConditions: 1,
      },
      cellRenderer: productLinkRenderer,
      sortable: true,
      width: 350,
    },
    {
      headerName: "Product ID",
      field: "product.id",
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: '\\d\\-',
        maxNumConditions: 1,
      },
      sortable: true,
      width: 130,
      cellClass: "ag-right-aligned-cell",
    },
    {
      headerName: "Asset Class",
      field: "product.assetClass.parent.code",
      filter: "agSetColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => assetClassCodeFormatter(params, assetClasses),
      filterParams: {
        values: assetClasses.map(el => el.code),
        valueFormatter: (params: ValueFormatterParams) => assetClassCodeFormatter(params, assetClasses),
        comparator: (a: any, b: any) => assetClassComparator(a, b, assetClasses),
        debounceMs: 500,
      },
      sortable: true,
      width: 200,
    },
    {
      headerName: "Strategy Type",
      field: "product.assetClass.code",
      filter: "agSetColumnFilter",
      valueFormatter: (params: ValueFormatterParams) => assetClassCodeFormatter(params, strategyTestOptions),
      filterParams: {
        values: strategyTestOptions.map(el => el.code),
        valueFormatter: (params: ValueFormatterParams) => assetClassCodeFormatter(params, strategyTestOptions),
        comparator: (a: any, b: any) => assetClassComparator(a, b, result),
        debounceMs: 500,
      },
      sortable: true,
      width: 200,
    },
    {
      headerName: "Asset Class ID",
      field: "product.assetClass.parent.code",
      colId: "hiddenAssetCLass",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [],
      },
      hide: true,
    },
  ]
}

const activePassiveMapping = {
  ACTIVE: "Active",
  Active: "Active",
  PASS: "Passive",
  Passive: "Passive",
  BLEND: "Blend",
  "Active/Passive Blend": "Blend",
}

const InactiveMapping = {
  true: "Inactive",
  false: "Active",
}

const trailingQuarterReturnGetter = (params: ValueGetterParams) => {
  let quarterly: Performance[] = params?.data?.product?.quarterly || []
  let value = _.maxBy(quarterly, (i: Performance) => moment(i.endDate, DATE_API_FORMAT).valueOf())?.value?.active
  return _.isNumber(value) ? numbro(value).format(`0.00`) : ""
}

const trailingQuarterTooltipValueGetter = (params: ValueGetterParams) => {
  let quarterly: Performance[] = params?.data?.product?.quarterly || []
  let value = _.maxBy(quarterly, (i: Performance) => moment(i.endDate, DATE_API_FORMAT).valueOf())?.endDate
  return value
}

const trailingYearReturnGetter = (params: ValueGetterParams) => {
  let yearly: Performance[] = params?.data?.product?.yearly || []
  let value = _.maxBy(yearly, (i: Performance) => moment(i.endDate, DATE_API_FORMAT).valueOf())?.value?.active
  return _.isNumber(value) ? numbro(value).format(`0.00`) : ""
}

const trailingYearTooltipValueGetter = (params: ValueGetterParams) => {
  let yearly: Performance[] = params?.data?.product?.yearly || []
  let value = _.maxBy(yearly, (i: Performance) => moment(i.endDate, DATE_API_FORMAT).valueOf())?.endDate
  return value
}

const trailingReturnFormatter = (params: ValueFormatterParams) => {
  // format  undefined , null , NaN , 0 , "" (empty string), and false into "-"
  if (!params.value) {
    return "-"
  } else {
    return params.value + "%"
  }
}

const floatFalsyAtBottomComparator = (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
  // falsy value at bottom
  if (valueA === "") {
    return 1
  } else if (valueB === "") {
    return -1
  }
  let floatA = parseFloat(valueA)
  let floatB = parseFloat(valueB)
  return floatA === floatB ? 0 : floatA > floatB ? 1 : -1
}

const BooleanMapping: { [key: string]: string } = {
  true: "true",
  false: "false",
}

const ProductStructureCodeMapping: { [key: string]: string } = {
  DIRCT: "Direct",
  FOF: "Fund of Funds",
}

const CapitalStructureCodeMapping: { [key: string]: string } = {
  OPEN: "Open-ended fund",
  CLOSED: "Closed-ended fund",
}

const noInRangeFilterOptions = [
  "equals",
  "notEqual",
  "lessThan",
  "lessThanOrEqual",
  "greaterThan",
  "greaterThanOrEqual",
]
// column filter value based on another column value

const StatusCodeValues = Object.values(StatusCode)

const StatusCodeMapping = StatusCodeValues.reduce((acc, code) => {
  return { ...acc, [code]: statusEnumToValue(code as StatusCode) }
}, {} as { [key: string]: string })

const StatusCodeComparator = (valueA: string, valueB: string) => {
  if (valueA === valueB) return 0
  if (!valueA) return 1
  if (!valueB) return -1
  // sort order "_4", "Market Overview", then alphabet order.
  let vA = parseInt(valueA.replace("_", ""), 10)
  let vB = parseInt(valueB.replace("_", ""), 10)
  return vA > vB ? 1 : -1
}

// {low, high} value comparator rules, sort low first, then high.
const riskTargetComparator = (valueA: any, valueB: any) => {
  let { low: lowA, high: highA } = valueA
  let { low: lowB, high: highB } = valueB
  if (lowA === lowB) {
    if (highA === highB) {
      return 0
    }
    if (_.isNull(highA)) {
      return 1
    } else if (_.isNull(highB)) {
      return -1
    }
    return highA > highB ? 1 : -1
  } else if (_.isNull(lowA)) {
    return 1
  } else if (_.isNull(lowB)) {
    return -1
  } 
  return lowA > lowB ? 1 : -1 
}

const riskTargetFormatter = (params: ValueFormatterParams) => {
  let { low, high } = params.value
  if(_.isNull(low) && _.isNull(high)) return "-"
  return `${low || 0} - ${high || 0} bps`
}

const opinionValueGetter = (params: ValueGetterParams) => {
  let opinions: ProductOpinion[] = params?.data?.product?.writeUps?.opinions || []
  let sortedOpinions = _.orderBy(opinions, ["opinions.date"], ["desc"])
  return sortedOpinions?.length ? sortedOpinions[0]?.opinions?.status?.value : null
}

export const ResearchProductPublicEquityColumnDef = (
  assetClasses: GetAllAssetClassesQuery["assetClasses"],
  viewOpinion: boolean = false,
  viewClientExposure: boolean = false
) => {
  let basicInfo = ProductBasicInfoColumnsDef(assetClasses)
  return [
    ...basicInfo,
    {
      headerName: "Benchmark",
      field: "product.primaryBenchmark.name",
      filterParams: {
        maxNumConditions: 1,
      },
      sortable: true,
    },
    {
      headerName: "Product AUM ($M)",
      field: "product.latestAum.aum",
      valueGetter: recentAUMValueGetter,
      tooltipField: "product.latestAum.date",
      tooltipComponent: "aumTooltip",
      valueFormatter: currencyFormatter,
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: '\\d(.)\\-',
        maxNumConditions: 1,
      },
      cellClass: "ag-right-aligned-cell",
      cellClassRules: {
        "est-aum": (params: CellClassParams) => {
          return params?.data?.product?.latestAum?.date !== appDate.format(DATE_API_FORMAT)
        },
      },
      sortable: true,
      width: 220,
      headerComponentParams: {
        template: headerComponentWithIcon("productAumTooltip"),
      },
    },
    {
      // used for exporting
      headerName: "As Of Date",
      field: "product.latestAum.date",
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      hide: true,
    },
    {
      headerName: "Offers Commingled",
      field: "product.hasCommingled",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      },
      cellRenderer: checkOrCrossRenderer,
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      sortable: true,
      width: 170,
    },
    {
      headerName: "Offers Mutual Fund",
      field: "product.hasMutualFund",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      },
      cellRenderer: checkOrCrossRenderer,
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      sortable: true,
      width: 170,
    },
    {
      headerName: "1 Qtr Return",
      field: "product.quarterly",
      filter: "agNumberColumnFilter",
      tooltipValueGetter: trailingQuarterTooltipValueGetter,
      tooltipComponent: "aumTooltip",
      valueGetter: trailingQuarterReturnGetter,
      valueFormatter: trailingReturnFormatter,
      filterParams: {
        allowedCharPattern: '\\d(.)\\-',
        maxNumConditions: 1,
        // get string to percent or ""
        filterValueGetter: trailingQuarterReturnGetter,
        valueFormatter: trailingReturnFormatter,
        comparator: floatFalsyAtBottomComparator,
      },
      comparator: floatFalsyAtBottomComparator,
      sortable: true,
      width: 170,
      cellClass: "ag-right-aligned-cell",
      // cellClassRules: {
      //   "est-aum": (params: CellClassParams) => {
      //     return params?.value != appDate.format(DATE_API_FORMAT)
      //   },
      // },
      headerComponentParams: {
        template: headerComponentWithIcon("trailingQtrReturnTooltip"),
      },
    },
    {
      // used for exporting
      headerName: "As Of Date",
      field: "product.quarterly",
      colId: "quarterlyDate",
      valueGetter: trailingQuarterTooltipValueGetter,
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      hide: true,
    },
    {
      headerName: "1 Yr Return",
      field: "product.yearly",
      filter: "agNumberColumnFilter",
      tooltipValueGetter: trailingYearTooltipValueGetter,
      tooltipComponent: "aumTooltip",
      valueGetter: trailingYearReturnGetter,
      valueFormatter: trailingReturnFormatter,
      filterParams: {
        allowedCharPattern: '\\d(.)\\-',
        maxNumConditions: 1,
        valueFormatter: trailingReturnFormatter,
        comparator: floatFalsyAtBottomComparator,
        filterValueGetter: trailingYearReturnGetter,
      },
      comparator: floatFalsyAtBottomComparator,
      sortable: true,
      width: 170,
      cellClass: "ag-right-aligned-cell",
      // cellClassRules: {
      //   "est-aum": (params: CellClassParams) => {
      //     return params?.value != appDate.format(DATE_API_FORMAT)
      //   },
      // },
      headerComponentParams: {
        template: headerComponentWithIcon("trailingYrReturnTooltip"),
      },
    },
    {
      // used for exporting
      headerName: "As Of Date",
      field: "product.yearly",
      colId: "yearlyDate",
      valueGetter: trailingYearTooltipValueGetter,
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      hide: true,
    },
    {
      headerName: "Active/Passive",
      field: "product.activePassive",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ACTIVE", "PASS", "BLEND"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, activePassiveMapping),
      },
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, activePassiveMapping),
      sortable: true,
      width: 140,
    },
    {
      headerName: "Last Interaction (date)",
      field: "product.lastActivity",
      filter: "agDateColumnFilter",
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      sortable: true,
      hide: !viewOpinion,
      suppressColumnsToolPanel: !viewOpinion,
      width: 180,
    },
    {
      headerName: "# Client Plans",
      field: "product.numberOfClientsInvested",
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: '\\d\\-',
        maxNumConditions: 1,
      },
      sortable: true,
      hide: !viewClientExposure,
      suppressColumnsToolPanel: !viewClientExposure,
      width: 165,
      cellClass: "ag-right-aligned-cell",
    },
    {
      headerName: "Product Overall Opinion",
      field: "product.writeUps.opinions",
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return "-"
        }
        return simpleMappingFormatter(params, StatusCodeMapping)
      },
      comparator: StatusCodeComparator,
      filter: "agSetColumnFilter",
      filterParams: {
        values: StatusCodeValues,
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return "-"
          }
          return simpleMappingFormatter(params, StatusCodeMapping)
        },
        comparator: StatusCodeComparator,
      },
      sortable: true,
      valueGetter: opinionValueGetter,
      hide: !viewOpinion,
      suppressColumnsToolPanel: !viewOpinion,
      width: 200,
    },
    {
      headerName: "Status",
      field: "product.inactive",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) =>
          params.value === true || params.value === "true" ? InactiveMapping["true"] : InactiveMapping["false"],
      },
      valueGetter: (params: ValueGetterParams) => mappingValueGetter(params, "product.inactive", InactiveMapping),
      sortable: true,
      width: 100,
    },
  ]
}

export const ResearchProductPublicFixedIncomeColumnDef = (
  assetClasses: GetAllAssetClassesQuery["assetClasses"],
  viewOpinion: boolean = false,
  viewClientExposure: boolean = false
) => {
  let basicInfo = ProductBasicInfoColumnsDef(assetClasses)
  return [
    ...basicInfo,
    {
      headerName: "Benchmark",
      field: "product.primaryBenchmark.name",
      sortable: true,
    },
    {
      headerName: "Product AUM ($M)",
      field: "product.latestAum.aum",
      valueGetter: recentAUMValueGetter,
      tooltipField: "product.latestAum.date",
      tooltipComponent: "aumTooltip",
      valueFormatter: currencyFormatter,
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: '\\d(.)\\-',
        maxNumConditions: 1,
      },
      cellClass: "ag-right-aligned-cell",
      cellClassRules: {
        "est-aum": (params: CellClassParams) => {
          return params?.data?.product?.latestAum?.date !== appDate.format(DATE_API_FORMAT)
        },
      },
      sortable: true,
      width: 220,
      headerComponentParams: {
        template: headerComponentWithIcon("productAumTooltip"),
      },
    },
    {
      // used for exporting
      headerName: "As Of Date",
      field: "product.latestAum.date",
      colId: "aumDate",
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      hide: true,
    },
    {
      headerName: "Offers Commingled",
      field: "product.hasCommingled",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      },
      cellRenderer: checkOrCrossRenderer,
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      sortable: true,
      width: 170,
    },
    {
      headerName: "Offers Mutual Fund",
      field: "product.hasMutualFund",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      },
      cellRenderer: checkOrCrossRenderer,
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      sortable: true,
      width: 170,
    },
    {
      headerName: "1 Qtr Return",
      field: "product.quarterly",
      filter: "agNumberColumnFilter",
      tooltipValueGetter: trailingQuarterTooltipValueGetter,
      tooltipComponent: "aumTooltip",
      valueGetter: trailingQuarterReturnGetter,
      valueFormatter: trailingReturnFormatter,
      filterParams: {
        allowedCharPattern: '\\d(.)\\-',
        filterValueGetter: trailingQuarterReturnGetter,
        valueFormatter: trailingReturnFormatter,
        comparator: floatFalsyAtBottomComparator,
        maxNumConditions: 1,
      },
      comparator: floatFalsyAtBottomComparator,
      sortable: true,
      width: 170,
      cellClass: "ag-right-aligned-cell",
      // cellClassRules: {
      //   "est-aum": (params: CellClassParams) => {
      //     return params?.value != appDate.format(DATE_API_FORMAT)
      //   },
      // },
      headerComponentParams: {
        template: headerComponentWithIcon("trailingQtrReturnTooltip"),
      },
    },
    {
      // used for exporting
      headerName: "As Of Date",
      field: "product.quarterly",
      colId: "quarterlyDate",
      valueGetter: trailingQuarterTooltipValueGetter,
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      hide: true,
    },
    {
      headerName: "1 Yr Return",
      field: "product.yearly",
      filter: "agNumberColumnFilter",
      tooltipValueGetter: trailingYearTooltipValueGetter,
      tooltipComponent: "aumTooltip",
      valueGetter: trailingYearReturnGetter,
      valueFormatter: trailingReturnFormatter,
      filterParams: {
        allowedCharPattern: '\\d(.)\\-',
        filterValueGetter: trailingYearReturnGetter,
        valueFormatter: trailingReturnFormatter,
        comparator: floatFalsyAtBottomComparator,
        maxNumConditions: 1,
      },
      comparator: floatFalsyAtBottomComparator,
      sortable: true,
      width: 170,
      cellClass: "ag-right-aligned-cell",
      // cellClassRules: {
      //   "est-aum": (params: CellClassParams) => {
      //     return params?.value != appDate.format(DATE_API_FORMAT)
      //   },
      // },
      headerComponentParams: {
        template: headerComponentWithIcon("trailingYrReturnTooltip"),
      },
    },
    {
      // used for exporting
      headerName: "As Of Date",
      field: "product.yearly",
      colId: "yearlyDate",
      valueGetter: trailingYearTooltipValueGetter,
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      hide: true,
    },
    {
      headerName: "Active/Passive",
      field: "product.activePassive",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ACTIVE", "PASS", "BLEND"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, activePassiveMapping),
      },
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, activePassiveMapping),
      sortable: true,
      width: 140,
    },
    {
      headerName: "Last Interaction (date)",
      field: "product.lastActivity",
      filter: "agDateColumnFilter",
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      sortable: true,
      hide: !viewOpinion,
      suppressColumnsToolPanel: !viewOpinion,
      width: 180,
    },
    {
      headerName: "Excess Return Min/Max Target (bps)",
      field: "openEnded.riskStatisticsTargets.excessReturn",
      filter: 'agMultiColumnFilter',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agNumberColumnFilter',
            filterParams: {
              allowedCharPattern: '\\d(.)\\-',
              maxNumConditions: 1,
            },
            display: 'subMenu',
            title: "Min Value",
          },
          {
            filter: 'agNumberColumnFilter',
            filterParams: {
              allowedCharPattern: '\\d(.)\\-',
              maxNumConditions: 1,
            },
            display: 'subMenu',
            title: "Max Value",
          },
        ],
      }, // TODO: IMultiFilterParams type available >= v27.0
      valueGetter: (params: ValueGetterParams) => {
        return params?.data?.openEnded?.riskStatisticsTargets?.excessReturn || {low: null, high: null}
      },
      comparator: riskTargetComparator,
      sortable: true,
      valueFormatter: riskTargetFormatter,
      cellClass: "ag-right-aligned-cell",
    },
    {
      headerName: "Tracking Error Min/Max Target (bps)",
      field: "openEnded.riskStatisticsTargets.trackingError",
      filter: 'agMultiColumnFilter',
      filterParams: {
        maxNumConditions: 1,
        filters: [
          {
            filter: 'agNumberColumnFilter',
            filterParams: {
              allowedCharPattern: '\\d(.)\\-',
              maxNumConditions: 1,
            },
            display: 'subMenu',
            title: "Min Value",
          },
          {
            filter: 'agNumberColumnFilter',
            filterParams: {
              allowedCharPattern: '\\d(.)\\-',
              maxNumConditions: 1,
            },
            display: 'subMenu',
            title: "Max Value",
          },
        ],
      }, // TODO: IMultiFilterParams type available >= v27.0
      valueGetter: (params: ValueGetterParams) => {
        return params?.data?.openEnded?.riskStatisticsTargets?.trackingError || {low: null, high: null}
      },
      sortable: true,
      comparator: riskTargetComparator,
      valueFormatter: riskTargetFormatter,
      cellClass: "ag-right-aligned-cell",
    },
    {
      headerName: "# Client Plans",
      field: "product.numberOfClientsInvested",
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: '\\d\\-',
        maxNumConditions: 1,
      },
      sortable: true,
      hide: !viewClientExposure,
      suppressColumnsToolPanel: !viewClientExposure,
      width: 165,
      cellClass: "ag-right-aligned-cell",
    },
    {
      headerName: "Product Overall Opinion",
      field: "product.writeUps.opinions",
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return "-"
        }
        return simpleMappingFormatter(params, StatusCodeMapping)
      },
      comparator: StatusCodeComparator,
      filter: "agSetColumnFilter",
      filterParams: {
        values: StatusCodeValues,
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return "-"
          }
          return simpleMappingFormatter(params, StatusCodeMapping)
        },
        comparator: StatusCodeComparator,
      },
      sortable: true,
      valueGetter: opinionValueGetter,
      hide: !viewOpinion,
      suppressColumnsToolPanel: !viewOpinion,
      width: 200,
    },
    {
      headerName: "Status",
      field: "product.inactive",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) =>
          params.value === true || params.value === "true" ? InactiveMapping["true"] : InactiveMapping["false"],
      },
      valueGetter: (params: ValueGetterParams) => mappingValueGetter(params, "product.inactive", InactiveMapping),
      sortable: true,
      width: 100,
    },
  ]
}

export const ResearchProductPrivateMarketColumnDef = (
  assetClasses: GetAllAssetClassesQuery["assetClasses"],
  viewOpinion: boolean = false,
  viewClientExposure: boolean = false
) => {
  let basicInfo = ProductBasicInfoColumnsDef(assetClasses)
  return [
    ...basicInfo,
    {
      headerName: "Product Structure",
      field: "product.structure.code",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["DIRCT", "FOF"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, ProductStructureCodeMapping)
      },
      valueGetter: (params: ValueGetterParams) => mappingValueGetter(params, "product.structure.code", ProductStructureCodeMapping) || "-",
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, ProductStructureCodeMapping),
      sortable: true,
      width: 170,
    },
    {
      headerName: "Capital Structure",
      field: "product.capitalStructure",
      valueFormatter: (params: ValueFormatterParams) => {
        let code = params.value || ""
        return CapitalStructureCodeMapping[code] || "-"
      },
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["OPEN", "CLOSED"],
        valueFormatter: (params: ValueFormatterParams) => {
          let code = params.value || ""
          return CapitalStructureCodeMapping[code] || "-"
        },
      },
      sortable: true,
      width: 160,
    },
    { headerName: "FundRaising Status", field: "product.fundraisingStatus", sortable: true },
    {
      headerName: "Vintage Year",
      field: "closedEnded.vintageYearFirstCashflow",
      filter: "agDateColumnFilter",
        valueFormatter: dateFormatter,
        filterParams: {
          valueFormatter: dateFormatter,
          comparator: dateFilterParams.comparator,
        },
        sortable: true
      },
    {
      headerName: "Fund Size ($M)",
      field: "closedEnded.fundRaising",
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: '\\d(.)\\-',
        maxNumConditions: 1,
        valueFormatter: fundSizeCurrencyFormatter,
      },
      valueFormatter: fundSizeCurrencyFormatter,
      valueGetter: (params: ValueGetterParams) => {
        let fundRaisings = params?.data?.closedEnded?.fundRaising || []
        let sortedFundRaisings = _.sortBy(fundRaisings, ["date"], ["desc"])
        return sortedFundRaisings?.length ? (sortedFundRaisings[0]?.committedCapital || 0) : null
      },
      sortable: true,
      cellClass: "ag-right-aligned-cell",
    },
    {
      headerName: "Last Interaction (date)",
      field: "product.lastActivity",
      filter: "agDateColumnFilter",
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      sortable: true,
      hide: !viewOpinion,
      suppressColumnsToolPanel: !viewOpinion,
      width: 180,
    },
    {
      headerName: "# Client Plans",
      field: "product.numberOfClientsInvested",
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: '\\d\\-',
        maxNumConditions: 1,
      },
      sortable: true,
      hide: !viewClientExposure,
      suppressColumnsToolPanel: !viewClientExposure,
      width: 165,
      cellClass: "ag-right-aligned-cell",
    },
    {
      headerName: "Status",
      field: "product.inactive",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) =>
          params.value === true || params.value === "true" ? InactiveMapping["true"] : InactiveMapping["false"],
      },
      valueGetter: (params: ValueGetterParams) => mappingValueGetter(params, "product.inactive", InactiveMapping),
      sortable: true,
      width: 100,
    },
  ]
}

export const ResearchProductHedgeFundDivsColumnDef = (
  assetClasses: GetAllAssetClassesQuery["assetClasses"],
  viewOpinion: boolean = false,
  viewClientExposure: boolean = false
) => {
  let basicInfo = ProductBasicInfoColumnsDef(assetClasses)
  return [
    ...basicInfo,
    {
      headerName: "Benchmark",
      field: "product.primaryBenchmark.name",
      sortable: true,
    },
    {
      headerName: "Product AUM ($M)",
      field: "product.latestAum.aum",
      valueGetter: recentAUMValueGetter,
      tooltipField: "product.latestAum.date",
      tooltipComponent: "aumTooltip",
      valueFormatter: currencyFormatter,
      filter: "agNumberColumnFilter",
      filterParams: {
        maxNumConditions: 1,
        allowedCharPattern: '\\d(.)\\-',
      },
      cellClass: "ag-right-aligned-cell",
      cellClassRules: {
        "est-aum": (params: CellClassParams) => {
          return params?.data?.product?.latestAum?.date !== appDate.format(DATE_API_FORMAT)
        },
      },
      sortable: true,
      width: 220,
      headerComponentParams: {
        template: headerComponentWithIcon("productAumTooltip"),
      },
    },
    {
      // used for exporting
      headerName: "As Of Date",
      field: "product.latestAum.date",
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      hide: true,
    },
    {
      headerName: "Product Structure",
      field: "product.structure.code",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["DIRCT", "FOF"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, ProductStructureCodeMapping),
      },
      valueGetter: (params: ValueGetterParams) => mappingValueGetter(params, "product.structure.code", ProductStructureCodeMapping) || "-",
      sortable: true,
      width: 170,
    },
    {
      headerName: "Capital Structure",
      field: "product.capitalStructure",
      valueFormatter: (params: ValueFormatterParams) => {
        let code = params.value || ""
        return CapitalStructureCodeMapping[code] || "-"
      },
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["OPEN", "CLOSED"],
        valueFormatter: (params: ValueFormatterParams) => {
          let code = params.value || ""
          return CapitalStructureCodeMapping[code] || "-"
        },
      },
      sortable: true,
      width: 160,
    },
    {
      headerName: "Offers Commingled",
      field: "product.hasCommingled",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      },
      cellRenderer: checkOrCrossRenderer,
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      sortable: true,
      width: 170,
    },
    {
      headerName: "Offers Mutual Fund",
      field: "product.hasMutualFund",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      },
      cellRenderer: checkOrCrossRenderer,
      valueFormatter: (params: ValueFormatterParams) => simpleMappingFormatter(params, BooleanMapping),
      sortable: true,
      width: 170,
    },
    {
      headerName: "Last Interaction (date)",
      field: "product.lastActivity",
      filter: "agDateColumnFilter",
      valueFormatter: dateFormatter,
      filterParams: {
        valueFormatter: dateFormatter,
        comparator: dateFilterParams.comparator,
      },
      sortable: true,
      hide: !viewOpinion,
      suppressColumnsToolPanel: !viewOpinion,
      width: 180,
    },
    {
      headerName: "# Client Plans",
      field: "product.numberOfClientsInvested",
      filter: "agNumberColumnFilter",
      filterParams: {
        allowedCharPattern: '\\d\\-',
        maxNumConditions: 1,
      },
      sortable: true,
      hide: !viewClientExposure,
      suppressColumnsToolPanel: !viewClientExposure,
      width: 165,
      cellClass: "ag-right-aligned-cell",
    },
    {
      headerName: "Product Overall Opinion",
      field: "product.writeUps.opinions",
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params.value) {
          return "-"
        }
        return simpleMappingFormatter(params, StatusCodeMapping)
      },
      comparator: StatusCodeComparator,
      filter: "agSetColumnFilter",
      filterParams: {
        values: StatusCodeValues,
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return "-"
          }
          return simpleMappingFormatter(params, StatusCodeMapping)
        },
        comparator: StatusCodeComparator,
      },
      sortable: true,
      valueGetter: opinionValueGetter,
      hide: !viewOpinion,
      suppressColumnsToolPanel: !viewOpinion,
      width: 200,
    },
    {
      headerName: "Status",
      field: "product.inactive",
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["false", "true"],
        valueFormatter: (params: ValueFormatterParams) =>
          params.value === true || params.value === "true" ? InactiveMapping["true"] : InactiveMapping["false"],
      },
      valueGetter: (params: ValueGetterParams) => mappingValueGetter(params, "product.inactive", InactiveMapping),
      sortable: true,
      width: 100,
    },
  ]
}
