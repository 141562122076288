import _ from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useQuery } from "@apollo/client"
import { Redirect, Route, Switch } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import {
  useUserSavedProductFilterQuery,
  UserSavedProductFilterFragment,
  useGetAllAssetClassesQuery,
  GetAllAssetClassesQuery,
  CallanDateType,
  useCallanDateQuery,
} from "../../__generated__/graphql"
import PlaceHolder from "../ui/PlaceHolder"
import {
  ResearchProductStrategyTypeColumnDefsMapping as ColumnDefs,
  ResearchProductStrategyTypeSearchType as SearchType,
  ResearchProductStrategyTypeUrlMapping as UrlMapping,
} from "./ResearchProductConfig"
import { ResearchProductDetailsProps } from "./ResearchProductDetails"
import { ResearchProductsRouteProps } from "./ResearchProducts"
import ResearchProductView from "./ResearchProductView"

// return true if all ASSET_CLASS_ID in filter is included in researchCategoryClassFilter
const matchCategory = (filter: UserSavedProductFilterFragment, type: SearchType) => {
  let controls = filter?.filters
  let assetClassesControl = controls.filter((el) => el?.field === "ASSET_CLASS_ID")
  // result = _in minus notIn to get all included ASSET_CLASS_ID
  let result: { _in: string[]; notIn: string[] } = { _in: [], notIn: [] }
  let resultFilters = assetClassesControl.reduce((result, i) => {
    let { _in, notIn } = i?.controls
    if (_in) {
      result._in = _.union(result._in, _in)
    }
    if (notIn) {
      result.notIn = _.union(result.notIn, notIn)
    }
    return result
  }, result)
  let researchCategoryClassFilter = UrlMapping[type as SearchType].criteria
  let calculatedFilters = _.difference(resultFilters._in, resultFilters.notIn)
  return calculatedFilters.every(filter => researchCategoryClassFilter.includes(filter))
}

export const matchedFilters = (filterData: UserSavedProductFilterFragment[], type: SearchType) => {
  return filterData?.filter((el: UserSavedProductFilterFragment) =>
  matchCategory(el, type as SearchType)
  ) || []
}

const getFilterId = (location: Location) => {
  let urlParams = new URLSearchParams(location.search)
  let filterId = urlParams.get("filterId") || undefined
  return _.isUndefined(filterId) || _.isNull(filterId) || _.isNaN(filterId) ? undefined : parseInt(filterId)
}

const ResearchProductMain: React.FC<ResearchProductDetailsProps & ResearchProductsRouteProps> = (props) => {
  const { location, match, auth } = props
  const [filterId, setFilterId] = useState<number | undefined>(getFilterId(location))
  const { type } = match.params
  const { colDef, document, filters } = ColumnDefs[type as SearchType]

  const { loading: callanDateLoading, data: callanDate} = useCallanDateQuery({variables: {type: CallanDateType.CMS_CURRENT_MONTH}})
  const otherVariables = useMemo(() => ({
    viewOpinion: auth.checkPermissions(["view:num_client_exposures"]),
    viewClientExposure: auth.checkPermissions(["view:num_client_exposures"]),
    endDate: callanDate?.callanDate,
  }), [auth, callanDate])

  const variables = {
    filters,
    ...otherVariables,
    options: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    },
  }

  const needCallanDate = type === SearchType._1 || type === SearchType._2

  const { loading, refetch } = useQuery(document, { variables, skip: needCallanDate && !callanDate?.callanDate, fetchPolicy: "network-only" })

  useEffect(() => {
    setFilterId(getFilterId(location))
  }, [location])

  const {
    loading: filterLoading,
    error: filterError,
    data: filterData,
    refetch: _filterRefetch,
  } = useUserSavedProductFilterQuery()

  const {
    loading: assetClassesLoading,
    error: assetClassesError,
    data: assetClassesData
  } = useGetAllAssetClassesQuery()
  let assetClassesFilters = UrlMapping[type as SearchType].criteria
  let assetClasses: GetAllAssetClassesQuery["assetClasses"] = []

  const filterRefetch = useCallback(() => {
    return _filterRefetch()
  }, [])

  if (callanDateLoading || filterLoading || assetClassesLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (filterError || assetClassesError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{filterError?.message}</p>
              <p>{assetClassesError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!filterData) {
    console.log("user doesn't have pre-saved filters in db.")
  }
  if(assetClassesData?.assetClasses) {
    assetClasses = assetClassesData?.assetClasses.filter(el => assetClassesFilters.includes(el.code.toString()))
  }

  if (!!filterData) {
    let savedFilters = matchedFilters(filterData?.savedProductFilters || [], type as SearchType)
    let sortedFilters = _.sortBy(savedFilters, (filter) => filter.id)
    let filterIdIncluded = !!filterId && savedFilters.some((filter) => filterId === filter.id)
    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column">
        <Switch>
          {_.isNumber(filterId) && filterIdIncluded && (
            <Route
              render={(params) => (
                <ResearchProductView
                  loading={loading}
                  key={type}
                  type={type as SearchType}
                  colDef={colDef}
                  refetch={refetch}
                  filtersRefetch={filterRefetch}
                  filters={sortedFilters}
                  filterId={filterId}
                  variables={variables}
                  assetClasses={assetClasses}
                  {...otherVariables}
                />
              )}
            ></Route>
          )}
          {!!_.isUndefined(filterId) && (
            <Route
              exact
              path={`${match.url}`}
              render={(params) => (
                <ResearchProductView
                  loading={loading}
                  key={type}
                  type={type as SearchType}
                  colDef={colDef}
                  refetch={refetch}
                  filtersRefetch={filterRefetch}
                  filters={sortedFilters}
                  variables={variables}
                  assetClasses={assetClasses}
                  {...otherVariables}
                />
              )}
            ></Route>
          )}
          {_.isNumber(filterId) && !filterIdIncluded && <Redirect to={`${match.url}/`} />}
        </Switch>
      </Container>
    )
  } else {
    return <div>data doesn't exist.</div>
  }
}

export default ResearchProductMain
