import classnames from "classnames"
import React from "react"

import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormGroup, Label } from "reactstrap"

interface CustomInputProps {
  idx: string | number
  property: string
  displayName: string
  wrapperClasses?: string
  labelClasses?: string
  inputWrapperClasses?: string
  tooltip?: { icon: string; id: string }
}

export const CustomInput: React.FC<CustomInputProps> = (props) => {
  const {
    idx,
    property,
    displayName,
    labelClasses,
    inputWrapperClasses,
    tooltip,
    children,
    wrapperClasses,
  } = props

  let shortProperty = property.split(".").pop() || property
  const displayLabel = displayName !== ""

  return (
    <FormGroup
      className={classnames("form-group row", wrapperClasses || "")}
      key={idx}
    >
      {displayLabel && (
        <Label
          className={classnames("col-form-label", labelClasses, {
            "col-sm-4": !labelClasses,
          })}
          for={`${idx}-${shortProperty}`}
          id={property}
        >
          <div
            className={classnames("d-flex w-100", {
              "tooltip-icon": tooltip,
              // "justify-content-start": tooltip?.onClick,
              // "justify-content-between": !tooltip?.onClick,
            })}
            id={tooltip ? tooltip.id : ""}
          >
            {displayName}
            {tooltip && (
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={() => {
                  // tooltip.onClick && tooltip.onClick()
                }}
              />
            )}
          </div>
        </Label>
      )}
      <div
        className={classnames(
          inputWrapperClasses || "",
          {
            "col-sm-8": displayLabel && !inputWrapperClasses,
            "col-sm-12": !(displayLabel || inputWrapperClasses),
          },
          tooltip && "tooltip-icon d-flex after-value"
        )}
        id={tooltip ? tooltip.id : ""}
      >
        {children}
      </div>
    </FormGroup>
  )
}
