import React, { useState, useEffect } from "react"
import {
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  Input,
  Table,
  FormGroup,
  FormFeedback,
  FormText,
} from "reactstrap"
import {
  useVehicleClosedEndedFeesQuery,
  ClosedEndedVehicle,
  ClosedEndedManagementFees,
  ClosedEndedManagementFeesByYear,
  ClosedEndedManagementFeesItem,
  FeeBasisCode,
  ClosedEndedManagementFeesByAum,
  Maybe,
} from "../../../__generated__/graphql"
import { FeeToggle } from "./FeeToggle"
import PlaceHolder from "../../ui/PlaceHolder"
import { formatPercent } from "./helper"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { CurrencyInput, PercentInput } from "./FeeFormComponents"

interface ClosedEndedFeesProps {
  handleInputChange: (value: any, property: string) => void
  editing: boolean
  fundid: string
}

type Fees = Omit<ClosedEndedManagementFees, "__typename">

type FeeSubType = keyof Pick<
  ClosedEndedManagementFeesItem,
  "feesByYear" | "feesByAum"
>

interface FeeYearRowProps {
  year: boolean
  fees: (
    | Maybe<ClosedEndedManagementFeesByYear>
    | Maybe<ClosedEndedManagementFeesByAum>
  )[]
  type: keyof Fees
  editing: boolean
  onChange: (
    value: string,
    key: { yearNumber: number } | { aumTier: number },
    path: string
  ) => void
  removeRow: (type: keyof Fees, subType: FeeSubType) => void
  onAumChange: (type: keyof Fees, idx: number, value: string) => void
}

const FeeRows: React.FC<FeeYearRowProps> = ({
  year,
  fees,
  type,
  editing,
  onChange,
  removeRow,
  onAumChange,
}) => {
  const [aumTierInvalid, setAumTierInvalid] = useState(false)
  const [feeRateInvalid, setFeeRateInvalid] = useState(
    new Array(fees.length).fill(false)
  )
  const subType: FeeSubType = year ? "feesByYear" : "feesByAum"
  const isLastRow = (idx: number) => {
    return editing && idx !== 0 && idx === (fees.length as number) - 1
  }
  if (fees.length === 0) {
    //set a default row if we don't have any fees
    fees.push(
      year
        ? {
            __typename: "ClosedEndedManagementFeesByYear",
            yearNumber: 1,
          }
        : { __typename: "ClosedEndedManagementFeesByAum", aumTier: 0 }
    )
  }
  useEffect(() => {
    let aumTier = -1
    for (let i = 0; i < fees.length; i++) {
      const fee = fees[i]
      if ((fee as ClosedEndedManagementFeesByAum).aumTier <= aumTier) {
        setAumTierInvalid(true)
        return
      } else {
        aumTier = (fee as ClosedEndedManagementFeesByAum).aumTier
      }
    }
    setAumTierInvalid(false)
  }, [fees])

  return (
    <>
      {fees.map((fee, idx) => {
        return (
          <tr
            className={classNames("management-fee-table-row", {
              "last-tier-row": isLastRow(idx),
            })}
            key={idx}
          >
            <td>
              {year ? (
                (fee as ClosedEndedManagementFeesByYear).yearNumber
              ) : (
                <FormGroup>
                  <div>
                    <CurrencyInput
                      disabled={!editing}
                      invalid={aumTierInvalid}
                      value={(fee as ClosedEndedManagementFeesByAum).aumTier}
                      onChange={(value) => onAumChange(type, idx, value)}
                    />
                  </div>
                </FormGroup>
              )}
            </td>
            <td>
              <FormGroup>
                <div>
                  <PercentInput
                    value={fee?.rate}
                    disabled={!editing}
                    invalid={feeRateInvalid[idx]}
                    onChange={(value) => {
                      const key = year
                        ? {
                            yearNumber: (fee as ClosedEndedManagementFeesByYear)
                              .yearNumber,
                          }
                        : {
                            aumTier: (fee as ClosedEndedManagementFeesByAum)
                              .aumTier,
                          }
                      onChange(value, key, `${type}.${subType}[${idx}]`)
                      const rate = parseFloat(value)
                      if (isNaN(rate)) return
                      if (rate >= 0.2) {
                        const temp = [...feeRateInvalid]
                        temp[idx] = true
                        setFeeRateInvalid(temp)
                      } else {
                        const temp = [...feeRateInvalid]
                        temp[idx] = false
                        setFeeRateInvalid(temp)
                      }
                    }}
                  />
                  <FormFeedback>
                    Fee Rates aren't typically higher than 20%, did you enter
                    the fee correctly?
                  </FormFeedback>
                </div>
              </FormGroup>
            </td>
            <td>
              {isLastRow(idx) && (
                <FontAwesomeIcon
                  icon="trash"
                  onClick={() => {
                    removeRow(type, subType)
                  }}
                />
              )}
            </td>
          </tr>
        )
      })}
    </>
  )
}

interface AddRowProps {
  title: string
  onAdd: () => void
}

const AddRow: React.FC<AddRowProps> = ({ title, onAdd }) => {
  return (
    <tr>
      <td colSpan={3}>
        {title}
        <FontAwesomeIcon icon="plus-circle" className="ml-1" onClick={onAdd} />
      </td>
    </tr>
  )
}

interface FeeBasisProps {
  disabled: boolean
  value: FeeBasisCode | null | undefined
  options: { description?: string | null; name: string }[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FeeBasis: React.FC<FeeBasisProps> = ({
  disabled,
  value,
  options,
  onChange,
}) => {
  return (
    <tr>
      <td style={{ borderRight: "none" }}>Fee Basis</td>
      <td colSpan={2} style={{ borderLeft: "none" }}>
        <FormGroup>
          <div>
            <Input
              type="select"
              value={value || ""}
              disabled={disabled}
              onChange={onChange}
              bsSize="sm"
            >
              <option value=""></option>
              {options.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.description}
                </option>
              ))}
            </Input>
          </div>
        </FormGroup>
      </td>
    </tr>
  )
}

interface FurtherDetailsProps {
  disabled: boolean
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FurtherDetails: React.FC<FurtherDetailsProps> = ({
  disabled,
  value,
  onChange,
}) => {
  const MAX_CHARS = 200
  const [charactersRemaining, updateCharactersRemaining] = useState(MAX_CHARS)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const characters = e.target.value.length
    if (characters > MAX_CHARS) {
      return
    }
    updateCharactersRemaining(MAX_CHARS - characters)
    onChange(e)
  }
  return (
    <tr>
      <td style={{ borderRight: "none" }}>Further Details</td>
      <td colSpan={2} style={{ borderLeft: "none" }}>
        <FormGroup>
          <div>
            <Input
              type="text"
              value={value}
              disabled={disabled}
              onChange={handleChange}
              bsSize="sm"
            ></Input>
          </div>
          {!disabled && (
            <FormText>{charactersRemaining} characters remaining</FormText>
          )}
        </FormGroup>
      </td>
    </tr>
  )
}

interface ManagementFeeTableProps {
  fees: Fees
  year: boolean
  type: keyof Fees
  editing: boolean
  feeBasisOptions?: { description?: string | null; name: string }[] | null
  onFeeChange: (
    value: string,
    key: { yearNumber: number } | { aumTier: number },
    path: string
  ) => void
  addRow: (type: keyof Fees, subType: FeeSubType) => void
  removeRow: (type: keyof Fees, subType: FeeSubType) => void
  updateBasis: (type: keyof Fees, code: FeeBasisCode) => void
  onDetailsChange: (type: keyof Fees, details: string) => void
  onAumChange: (type: keyof Fees, idx: number, value: string) => void
}

const ManagementFeeTable: React.FC<ManagementFeeTableProps> = ({
  fees,
  year,
  type,
  editing,
  feeBasisOptions,
  onFeeChange: onChange,
  addRow,
  removeRow,
  updateBasis,
  onDetailsChange,
  onAumChange,
}) => {
  const subType: FeeSubType = year ? "feesByYear" : "feesByAum"
  return (
    <>
      {" "}
      {fees[type] && (
        <>
          <h4 className="management-fee-title">
            {type === "investmentPeriod"
              ? "Investment Period"
              : "Post Investment Period"}
          </h4>
          <h5 className="management-fee-subtitle">
            {year ? "Management Fee by Year" : "Management Fee by AUM"}
          </h5>
          <Table className="management-fee-table exportable" data-export-name={type + ` table`} size="sm">
            <thead>
              <tr>
                <th>{year ? "Year" : "AUM Tier"}</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {
                <FeeRows
                  year={year}
                  fees={
                    year
                      ? (fees[type]
                          ?.feesByYear as ClosedEndedManagementFeesByYear[])
                      : (fees[type]
                          ?.feesByAum as ClosedEndedManagementFeesByAum[])
                  }
                  type={type}
                  editing={editing}
                  onChange={onChange}
                  removeRow={removeRow}
                  onAumChange={onAumChange}
                />
              }
              {editing && (
                <AddRow
                  title={year ? "Add Another Year" : "Add Another Tier"}
                  onAdd={() => {
                    addRow(type, subType)
                  }}
                />
              )}
              {feeBasisOptions && (
                <FeeBasis
                  disabled={!editing}
                  value={fees[type]?.feeBasis?.code}
                  onChange={(e) =>
                    updateBasis(type, e.target.value as FeeBasisCode)
                  }
                  options={feeBasisOptions}
                />
              )}
              <FurtherDetails
                disabled={!editing}
                value={fees[type]?.details || ""}
                onChange={(e) => {
                  onDetailsChange(type, e.target.value)
                }}
              />
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export const ClosedEndedFees: React.FC<ClosedEndedFeesProps> = ({
  handleInputChange,
  editing,
  fundid,
}) => {
  const [yearFee, setYearFee] = useState(true)
  const [fees, setFees] = useState<Fees>({})

  const { error, loading, data } = useVehicleClosedEndedFeesQuery({
    variables: {
      fundid,
    },
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    const fees = (data?.vehicle as ClosedEndedVehicle)?.closedEndedVehicle
      ?.managementFees
    if (fees) {
      setFees(fees)
    } else {
      setFees({})
    }
  }, [data])

  //update top level vehicle on change
  useEffect(() => {
    const updateFees = {
      investmentPeriod: {
        ...fees.investmentPeriod,
        feesByAum: fees.investmentPeriod?.feesByAum?.filter((fee) => fee?.rate),
        feesByYear: fees.investmentPeriod?.feesByYear?.filter(
          (fee) => fee?.rate
        ),
      },
      postInvestmentPeriod: {
        ...fees.postInvestmentPeriod,
        feesByAum: fees.postInvestmentPeriod?.feesByAum?.filter(
          (fee) => fee?.rate && fee.aumTier
        ),
        feesByYear: fees.postInvestmentPeriod?.feesByYear?.filter(
          (fee) => fee?.rate
        ),
      },
    }
    handleInputChange(
      updateFees,
      `vehicle.closedEndedVehicleFees.managementFees`
    )
  }, [fees])

  const onFeeChange = (
    value: string,
    key: { yearNumber: number } | { aumTier: number },
    path: string
  ) => {
    const fee = value
    const tempFees = JSON.parse(JSON.stringify(fees))
    _.set(tempFees, `${path}.rate`, fee)
    handleInputChange(
      { rate: fee, ...key },
      `vehicle.closedEndedVehicleFees.managementFees.${path}`
    )
    setFees(tempFees)
  }

  const removeRow = (type: keyof Fees, subType: FeeSubType) => {
    const temp: Fees = JSON.parse(JSON.stringify(fees))
    temp[type]?.[subType]?.pop()
    setFees(temp)
  }

  const addRow = (type: keyof Fees, subType: FeeSubType) => {
    const temp: Fees = JSON.parse(JSON.stringify(fees))
    if (subType === "feesByYear") {
      const yearNumber = (fees[type]?.[subType]?.length as number) + 1
      temp[type]?.[subType]?.push({
        __typename: "ClosedEndedManagementFeesByYear",
        yearNumber,
        rate: 0,
      })
    } else if (subType === "feesByAum") {
      temp[type]?.[subType]?.push({
        __typename: "ClosedEndedManagementFeesByAum",
        aumTier: 0,
        rate: 0,
      })
    }
    setFees(temp)
  }

  const updateBasis = (type: keyof Fees, code: FeeBasisCode) => {
    const temp: Fees = JSON.parse(JSON.stringify(fees))
    if (temp[type]) {
      ;(temp[type] as ClosedEndedManagementFeesItem).feeBasis = {
        code,
        __typename: "FeeBasisLookup",
      }
    }
    setFees(temp)
  }

  const onDetailsChange = (type: keyof Fees, details: string) => {
    const temp: Fees = JSON.parse(JSON.stringify(fees))
    if (temp[type]) {
      ;(temp[type] as ClosedEndedManagementFeesItem).details = details
    }
    setFees(temp)
  }

  const onAumChange = (type: keyof Fees, idx: number, value: string) => {
    const aumTier = parseInt(value, 10)
    if (isNaN(aumTier)) {
      value = "0"
    }
    const temp: Fees = JSON.parse(JSON.stringify(fees))
    if (temp[type]) {
      ;(
        (temp[type] as ClosedEndedManagementFeesItem)
          .feesByAum as ClosedEndedManagementFeesByAum[]
      )[idx].aumTier = parseInt(value, 10)
    }
    setFees(temp)
  }

  return (
    <Card>
      {error ? (
        <div>{error.message}</div>
      ) : loading ? (
        <PlaceHolder />
      ) : fees ? (
        <>
          <CardHeader>
            <Row style={{ display: "table" }}>
              <Col className={"pl-1"} style={{ display: "table-cell" }}>
                Additional Management Fee Table
              </Col>
              <Col>
                <FeeToggle
                  onChange={setYearFee}
                  toggle={yearFee}
                  displayOptions={{
                    default: "Year",
                    alternate: "AUM",
                  }}
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="management-fee-card">
            <Row className="management-fee-row" style={{ margin: 0 }}>
              <Col sm={6}>
                <ManagementFeeTable
                  fees={fees}
                  year={yearFee}
                  type={"investmentPeriod"}
                  editing={editing}
                  feeBasisOptions={data?.feeBasisCodes?.enumValues}
                  onFeeChange={onFeeChange}
                  addRow={addRow}
                  removeRow={removeRow}
                  updateBasis={updateBasis}
                  onDetailsChange={onDetailsChange}
                  onAumChange={onAumChange}
                />
              </Col>
              <Col sm={6}>
                <ManagementFeeTable
                  fees={fees}
                  year={yearFee}
                  type={"postInvestmentPeriod"}
                  editing={editing}
                  feeBasisOptions={data?.feeBasisCodes?.enumValues}
                  onFeeChange={onFeeChange}
                  addRow={addRow}
                  removeRow={removeRow}
                  updateBasis={updateBasis}
                  onDetailsChange={onDetailsChange}
                  onAumChange={onAumChange}
                />
              </Col>
            </Row>
          </CardBody>
        </>
      ) : (
        <div>Data not found</div>
      )}
    </Card>
  )
}
