import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Nav,
  NavItem,
  NavLink,
  ModalBody,
  ModalHeader
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { History} from 'history'
import _, { cloneDeep, compact, findIndex, get, isNull, isNumber, last, remove, sortBy } from "lodash"
import moment from 'moment'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numbro from 'numbro'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import DOMPurify from 'dompurify';

import { listQuarters, ManagerType, statusColor } from '../../helpers/helpers'
import PlaceHolder from "./../ui/PlaceHolder"
import { ChartContainer } from "../Shared/ChartContainer"
import {
  useManagerProfileQuery,
  ManagerProfileQuery,
  Interaction,
  Person,
  File,
  Opinion,
  Fact,
  ManagerRelatedClientExposureTotal,
  FundRaising,
  Manager,
  Maybe,
  CallanEsgScores,
  CallanDeiScores,
  ManagerRelatedClientPlanExposure,
} from "../../__generated__/graphql"
import { InteractionIcon } from '../ui/Icon'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from "../../helpers/constant"
import { appDate } from '../../Context/CalendarContext'
import { GraphData, ProfileAssetPieOptions, ProfileEmployeePieOptions, ProfileLineOptions, ProfileStackedColumnOptions, PieData, ProfileColOptions, graphColors } from "../../helpers/highCharts"
import Auth from "../../Auth/Auth"
import handleZeroAndNull from "../../helpers/handleZeroAndNull"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import exportTables, { ExtraRow } from "../../helpers/exportTable"

interface idProps {
  id: number
  auth: Auth
  managerType?: ManagerType
}

interface writeUpList {
  [key: string]: Fact | Opinion
}

interface EmployeeCode {
  __typename: string
  value: any
}

interface SortedEmployees {
  __typename?: string | null
  count?: number | null
  date?: string | null
  type: EmployeeCode
}

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

const ManagerProfile: React.FC<idProps> = ({ id, managerType, auth }: idProps) => {
  const history = useHistory()
  const { data, error, loading } = useManagerProfileQuery({
    variables: {
      id,
      startDate: moment(appDate).subtract(6, "years").format(DATE_API_FORMAT),
      endDate: appDate.format(DATE_API_FORMAT),
      showOpinions: auth.checkPermissions(["view:writeups_opinion"]),
    },
    errorPolicy: "all"
  })

  const heading = (<></>)

  if (loading) {
    return (
      <Container fluid>
        <div className="background-white py-4 px-3 ph-xl-7">
          <Row>
            <Col>
              {heading}
              <PlaceHolder />
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
  if (error && !data) {
    return (
      <Container fluid>
        <div className="background-white py-4 px-3 ph-xl-7">
          <Row>
            <Col>
              {heading}
              <p>{error?.message}</p>
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
  if (data && data.org && data.org.__typename === "Manager"){
    return (
      <Container fluid>
        <div className="background-white py-4 px-3 ph-xl-7">
          <Row>
            <Col>
              {heading}
              <Result
                data={data}
                history={history}
                auth={auth}
                managerType={managerType}
              />
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

interface ResultProps {
  data: ManagerProfileQuery
  history: History
  auth: any
  managerType?: ManagerType
}

interface ResultState {
  overviewExpanded: boolean;
  peopleExpanded: boolean;
  overviewOpinionExpanded: boolean;
  peopleOpinionExpanded: boolean;
  commonClientsExpanded: boolean;
  assetTab: string;
  assetHistoricalYears: number;
  employeeTab: string;
}

interface InteractionList extends Omit<Interaction, 'otherManagerAssociations'|'productAssociations'|'glidePathAssociations'|'documentAssociations'>{
}

interface EmployeeTurnoverData extends PieData {
  gained: number
  lost: number
}

const clientFundLinkRenderer = (clientExposure: ManagerRelatedClientPlanExposure) => {
  let clientId = clientExposure?.plan?.client?.id
  let reportsAndTotalFunds = clientExposure?.plan?.reportsAndTotalFunds
  if(reportsAndTotalFunds?.length) {
    let report = reportsAndTotalFunds.find(el => _.isNumber(el?.reportId) && _.isNumber(el?.totalFundPid))
    if(report) {
      let {reportId, totalFundPid} = report
      return `/clients/${clientId}/${reportId}/report${totalFundPid ? ("/" + totalFundPid) : ""}`
    }
  }
  return `/clients/${clientId}/documents`

}

const managerInteractionLinkRenderer = (interaction: any, managerId: number | undefined) => {
  return `/managers/${managerId}/interactions/${interaction?.id || ""}`
}

const productProfileLinkRenderer = (product: any) => {
  return `/products/${product.product?.id || ""}/profile`
}

const managerPeopleLinkRenderer = (person: any, managerId: number| undefined) => {
  return `/managers/${managerId}/people/${person?.id}`
}

const managerDocumentLinkRenderer = (document: File, managerId: number| undefined) => {
  return `/managers/${managerId}/documents/${document?.id}`
}

const scrollToResolver = (elem: any) => {
  if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
  } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
  }
}

const OPEN_END_SECTION_ID = "OpenEnd"
const CLOSED_END_SECTION_ID = "ClosedEnd"

class Result extends Component<ResultProps> {
  state: ResultState = {
    overviewExpanded: false,
    peopleExpanded: false,
    overviewOpinionExpanded: false,
    peopleOpinionExpanded: false,
    commonClientsExpanded: false,
    assetTab: "assetsByClient",
    assetHistoricalYears: 5,
    employeeTab: "employeeTurnover",
  }

  navigateToScreen = (url: string, hash?: string) => {
    let history = this.props.history
    const previousUrl = history?.location?.pathname
    if(hash) {
      this.props.history.replace(previousUrl+hash)
    }else {
      this.props.history.replace(previousUrl)
    }
    this.props.history.push(url)
  }

  componentDidMount = () => {
    let history = this.props.history
    let hash = history?.location?.hash
    let element = document.getElementById(hash.slice(1))
    scrollToResolver(element)
  }

  componentDidUpdate = (prevProps: ResultProps, prevState: ResultState) => {
    let hash = this.props.history?.location?.hash
    if((this.props !== prevProps) && hash) {
      let element = document.getElementById(hash.slice(1))
      scrollToResolver(element)
    }
  }

  exposureComponent = (exposures?:ManagerRelatedClientExposureTotal[], totalAssets?: number) => {
    let clientTotals =  {assets: 0, clients: 0}
    let clientAssetsMillions = '-'
    let clientAssets = '-'
    let clientAssetsTotal = '-'
    let clientAssetsMillionsTotal = '-'
    return (
      <>
        <h3 className="headline underline">Callan Exposure</h3>
        <Table hover className= " exportable" data-export-name={`${this.props.data.org?.name}-Callan Exposure`}>
          <thead>
            <tr className="row-border-olive-100">
              <th className="text-left px-2">Clients</th>
              <th className="text-right text-nowrap px-2">Assets ($M)</th>
              <th className="text-right text-nowrap px-2"># Clients</th>
              <th className="text-right text-nowrap px-2">% Assets</th>
            </tr>
          </thead>
          <tbody>
            {exposures?.map((exposure:ManagerRelatedClientExposureTotal, idx:number) => {
              if(exposure.discretionaryFlag?.code === "BOTH") return (<></>)
              const assetsMillion = (exposure.clientAssets || 0)/1000000
              clientTotals["assets"] += assetsMillion
              clientTotals["clients"] += exposure.numberOfClients || 0
              if(totalAssets && totalAssets > 0){
                clientAssets = numbro(assetsMillion / totalAssets).format('0.00%')
                clientAssetsTotal = numbro(clientTotals["assets"]/ (totalAssets)).format("0.00%")
              }
              if((handleZeroAndNull(exposure.numberOfClients) == 1) && (exposure.clientAssets == null)){
                clientAssetsMillions = "N/A"
                clientAssets = "N/A"
                clientAssetsTotal = "N/A"
                clientAssetsMillionsTotal = "N/A"
              }
              else{
                clientAssetsMillions = numbro(assetsMillion || 0).format(`${assetsMillion ? '$0,0.00' : '0,0.00' }`)
                clientAssetsMillionsTotal = numbro(clientTotals["assets"] || 0).format(`${clientTotals["assets"] ? '$0,0.00' : '0,0.00'}`)
              }
              return(
                <tr key={idx}>
                  <td className="text-left text-nowrap px-2">{handleZeroAndNull(exposure.discretionaryFlag?.value)}</td>
                  <td className="text-right px-2">{clientAssetsMillions}</td>
                  <td className="text-right px-2">{handleZeroAndNull(exposure.numberOfClients)}</td>
                  <td className="text-right px-2">{clientAssets}</td>
                </tr>
              )
            })}
            <tr key={"total"}>
              <td className="text-left px-2">Total</td>
              <td className="text-right px-2">{clientAssetsMillionsTotal}</td>
              <td className="text-right px-2">{handleZeroAndNull(clientTotals["clients"])}</td>
              <td className="text-right px-2">{clientAssetsTotal}</td>
            </tr>
          </tbody>
        </Table>
      </>
    )
  }

  totalAssetsComponent = (manager:ManagerProfileQuery["org"]) => {
    const sortedAssets = _.sortBy((manager as Manager).totalAssetsUnderManagement, [i => moment(i.quarterEndDate, DATE_API_FORMAT).valueOf()])
    let initialValue: Maybe<number>|undefined = 0
    let initialDate = appDate.valueOf()
    const startDate = moment(last(sortedAssets)?.quarterEndDate, DATE_API_FORMAT).subtract(5, "years").valueOf()
    let graphData:any = {
      name: "Assets Under Management",
      data: [],
    }

    let growthRate = 0
    sortedAssets.forEach((dataObject) => {
      const currentDateValue = moment(dataObject.quarterEndDate, DATE_API_FORMAT).valueOf()
      if(currentDateValue > startDate){
        if (!initialValue) {
          initialValue = dataObject.assetsUnderManagement || 0
          initialDate = currentDateValue
        }
        growthRate = (((dataObject.assetsUnderManagement || 0) - initialValue) / initialValue) * 100
        // data source
        graphData.data.push([ currentDateValue, dataObject.assetsUnderManagement ])
        // return  moment(dataObject.quarterEndDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)
      }
      // return null
    })
    if (graphData.data[graphData.data.length - 1] === 0) {
      graphData.data.pop()
    }

    return(
      <>
        <div className="position-relative">
          <h3 className="headline underline">
            Assets Under Management
          </h3>
          <div className="position-absolute" style={{ right: 0, bottom: 2}}>
            <h3 className="headline m-0" style={{ fontSize: "16px" }}>{numbro((_.last(sortedAssets)?.assetsUnderManagement || 0)).format("$0,0.[00]")}M</h3>
            <p className="font-tiny m-0">{numbro(growthRate).format("0.00")}% 5-Year Growth</p>
          </div>
        </div>
        <div>
          <ChartContainer
            options={ProfileLineOptions({heading: "", subHeading: "", data: [graphData], startDate: initialDate})}
            height={200}
            hasData={graphData.data.length > 0 && !!initialDate}
            modalName={"Assets Under Management"}
          />
          <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/assets/clientType`)}>
            <span className="pr-1">View All Assets</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        </div>
      </>
    )
  }

  closedEndedComponent = (manager:any) => {
    const sortedAssets = _.sortBy(manager.closedEndedFundraising, [i => i.year])
    let initialDate = moment(_.first(sortedAssets)?.quarterEndDate, DATE_API_FORMAT).valueOf()
    const startDate = moment(last(sortedAssets)?.quarterEndDate, DATE_API_FORMAT).subtract(5, "years").valueOf()
    let graphData:any = [
      {name: "Raised", data:[]},
      {name: "NAV", data:[]}
    ]

    compact(sortedAssets.map((dataObject) => {
      if(moment(dataObject.quarterEndDate, DATE_API_FORMAT).valueOf() > startDate){
        graphData[0].data.push([ dataObject.quarterEndDate, dataObject?.uncalledCapital || 0 ])
        graphData[1].data.push([ dataObject.quarterEndDate, dataObject?.netAssetValue || 0 ])
      }
      return null
    }))
    return(
      <>
        <div className="position-relative">
          <h3 className="headline underline">
            Assets Under Management
          </h3>
          <div className="position-absolute" style={{ right: 0, bottom: 2}}>
            {/* <h3 className="headline m-0" style={{ fontSize: "16px" }}>{numbro(_.last(sortedAssets)?.assetsUnderManagement).format("$0a")}</h3>
            <p className="font-tiny m-0">{numbro(_.last(graphData.data)).format("0.00")}% 5-Year Growth</p> */}
          </div>
        </div>
        <div>
          <ChartContainer
            options={ProfileColOptions({heading: "", subHeading: "", data: graphData, startDate: initialDate, colours: graphColors.map((cl) => cl.hex)}) }
            height={200}
            hasData={sortedAssets.length > 0}
            modalName={"Assets Under Management"}
          />
        </div>
      </>
    )
  }

  capitalComponent = (manager:any) => {
    const sortedAssets = _.sortBy(manager.totalAssetsUnderManagement, [i => moment(i.quarterEndDate, DATE_API_FORMAT).valueOf()])
    let initialDate = moment(_.first(sortedAssets)?.quarterEndDate, DATE_API_FORMAT).valueOf()
    const startDate = moment(last(sortedAssets)?.quarterEndDate, DATE_API_FORMAT).subtract(5, "years").valueOf()
    let graphData:any = [
      {name: "Uninvested", data:[]},
      {name: "Total", data:[]}
    ]

    compact(sortedAssets.map((dataObject) => {
      if(moment(dataObject.quarterEndDate, DATE_API_FORMAT).valueOf() > startDate){
        const capital = _.find(manager.realAssetsAssetsByCapitalType, (o) => o.quarterEndDate === dataObject.quarterEndDate)
        graphData[0].data.push([ dataObject.quarterEndDate, capital?.uncalledCapital || 0 ])
        graphData[1].data.push([ dataObject.quarterEndDate, dataObject?.assetsUnderManagement || 0 ])
      }
      return null
    }))

    return(
      <>
        <div className="position-relative">
          <h3 className="headline underline">
            Assets Under Management
          </h3>
          <div className="position-absolute" style={{ right: 0, bottom: 2}}>
            {/* <h3 className="headline m-0" style={{ fontSize: "16px" }}>{numbro(_.last(sortedAssets)?.assetsUnderManagement).format("$0a")}</h3>
            <p className="font-tiny m-0">{numbro(_.last(graphData.data)).format("0.00")}% 5-Year Growth</p> */}
          </div>
        </div>
        <div>
          <ChartContainer
            options={ProfileColOptions({heading: "", subHeading: "", data: graphData, startDate: initialDate, colours: graphColors.map((cl) => cl.hex)})}
            height={200}
            hasData={sortedAssets.length > 0}
            modalName={"Assets Under Management"}
          />
        </div>
      </>
    )
  }

  clientComponent = (clients: Maybe<ManagerRelatedClientPlanExposure>[] | undefined | null, totalAssets?: number) => {
    let clientAssetMillions = "-"
    let clientAsset = "-"
    if(!clients?.length || !this.props.auth.checkPermissions(["view:interactions_private"])) return (<></>)
    const sortedClients = _.sortBy(clients, [i => i?.clientAssets]).reverse()

    return(
      <>
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div id="CommonClients" className="pane-title">
            <h3>Common Clients</h3>
          </div>
          <Table hover className= " exportable" data-export-name={`${this.props.data.org?.name}-Common Clients`}>
            <thead>
              <tr className="row-border-olive-100">
                <th className="text-left">Plan</th>
                <th className="text-right">Assets Invested ($M)</th>
                <th className="text-left">Plan Type</th>
                <th className="text-right">% of Firm Assets</th>
              </tr>
            </thead>
            <tbody>
              {sortedClients.slice(0, this.state.commonClientsExpanded ? sortedClients.length : 5).map((client, idx) => {
                if(!client) {
                  return <React.Fragment key={idx}></React.Fragment>
                }
                const assetsMillion = (client.clientAssets || 0)/1_000_000
                if(totalAssets && totalAssets > 0){
                  clientAsset = numbro(assetsMillion / (totalAssets)).format("0.00%")
                }
                if(client.clientAssets == null){
                  clientAssetMillions = "N/A"
                  clientAsset = "N/A"
                }
                else {
                  clientAssetMillions = numbro(assetsMillion || 0).format(`${assetsMillion ? '$0,0.00' : '$0,0.00' }`)
                }
                return(
                  <tr key={idx}>
                    <td className="text-left">
                      <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(clientFundLinkRenderer(client))}>
                        {client?.plan?.name}
                      </Button>
                    </td>
                    <td className="text-right">{clientAssetMillions}</td>
                    <td className="text-left">{client.plan?.fundType?.value}</td>
                    <td className="text-right">{clientAsset}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <div className="expand-link">
            <Button color="link" href="#CommonClients" onClick={()=> this.setState({ commonClientsExpanded: !this.state.commonClientsExpanded })}>
              <FontAwesomeIcon
                icon={this.state.commonClientsExpanded  ? "chevron-up" : "chevron-down"}
                size="sm"
              />
              <span className="pl-2 expand-text">{this.state.commonClientsExpanded  ? "Collapse" : "Expand"}</span>
            </Button>
          </div>
        </div>
      </>
    )
  }

  interactionsTable = (interactions:InteractionList[]) => {
    const interactionsCount = interactions.reduce((result, entry) => {
      // Get type default to other if not another type
      let type = "other"
      if(entry.type === "In House")        type = "inHouse"
      if(entry.type === "On Site")         type = "onSite"
      if(entry.type === "Off Site")        type = "offSite"
      if(entry.type === "Conference Call") type = "conference"
      if(entry.type === "Client")          type = "client"
      if(entry.type === "Video Call")      type = "video"
      if(entry.type === "Webcast")         type = "webcast"
      if(entry.type === "Email")           type = "email"
      // Get related entry
      let typeObject = _.get(result, type)
      let totalObject = _.get(result, 'total')
      // Update values in entry
      const interactionDate = moment(entry.date, DATE_API_FORMAT)
      if(interactionDate.diff(moment().startOf('quarter'),'month', true) > 0){
        typeObject.qtd += 1
        totalObject.qtd += 1
      }
      if(interactionDate.diff(moment().subtract(1, 'year'),'month', true) > 0){
        typeObject.ytd += 1
        totalObject.ytd += 1
      }
      if(interactionDate.diff(moment(),'year', true) > -3){
        typeObject.thy += 1
        totalObject.thy += 1
      }
      // Save values back to result object
      _.set(result, type, typeObject)
      _.set(result, 'total', totalObject)
      return result
    }, {
      inHouse:    { qtd: 0, ytd:0, thy: 0 },
      onSite:     { qtd: 0, ytd:0, thy: 0 },
      offSite:    { qtd: 0, ytd:0, thy: 0 },
      conference: { qtd: 0, ytd:0, thy: 0 },
      client:     { qtd: 0, ytd:0, thy: 0 },
      video:      { qtd: 0, ytd:0, thy: 0 },
      webcast:    { qtd: 0, ytd:0, thy: 0 },
      email:      { qtd: 0, ytd:0, thy: 0 },
      other:      { qtd: 0, ytd:0, thy: 0 },
      total:      { qtd: 0, ytd:0, thy: 0 },
    })
    const interactionOrder = [
      {
        field: 'inHouse',
        title: 'In House',
        name: 'In House',
      },{
        field: 'onSite',
        title: 'On Site',
        name: 'On Site',
      },{
        field: 'offSite',
        title: 'Off Site',
        name: 'Off Site',
      },{
        field: 'conference',
        title: 'Conf. Call',
        name: 'Conference Call',
      },{
        field: 'client',
        title: 'Client',
        name: 'Client',
      },{
        field: 'email',
        title: 'Email',
        name: 'Email',
      },{
        field: 'video',
        title: 'Video Call',
        name: 'Video Call',
      },{
        field: 'webcast',
        title: 'Webcast',
        name: 'Webcast',
      },{
        field: 'other',
        title: 'Other',
        name: 'Other',
      }
    ]

    const sortedInteractions = _.sortBy(interactions, [i => moment(i.date).valueOf()]).reverse()
    return(
      <div className="pane pane-table pane-profile mt-4 pb-5">
        <div className="pane-title d-flex">
          <h3>Interactions</h3>
        </div>
        <Row>
          <Col sm={12} lg="5" className="border-right border-color-gray-10">
            <Table hover className= "table-sm exportable" data-export-name={`${this.props.data.org?.name}-Interactions`}>
              <thead>
                <tr className="table-title">
                  <th colSpan={6} className="py-3 border-0">Summary</th>
                </tr>
                <tr className="row-border-olive-100">
                  <th className="text-left text-no-wrap" colSpan={2}>Interactions</th>
                  <th className="text-right">QTD</th>
                  <th className="text-right">1 Year</th>
                  <th className="text-right">3 Years</th>
                </tr>
              </thead>
              <tbody>
                {interactionOrder.map((heading, idx) => {
                  const interactionGroup = _.get(interactionsCount, heading.field)
                  if(interactionGroup.qtd === 0 && interactionGroup.ytd === 0 && interactionGroup.thy === 0){
                    return(
                      <React.Fragment key={idx}></React.Fragment>
                    )
                  }
                  return(
                    <tr key={idx}>
                      <td className="pr-0">{<InteractionIcon type={heading.name}/>}</td>
                      <td className="text-left pl-0">{heading.title}</td>
                      <td className="text-right">{handleZeroAndNull(interactionGroup.qtd) }</td>
                      <td className="text-right">{handleZeroAndNull(interactionGroup.ytd) }</td>
                      <td className="text-right">{handleZeroAndNull(interactionGroup.thy) }</td>
                    </tr>
                  )
                })}
                <tr className="row-border-olive-100">
                  <td></td>
                  <td className="text-left pl-0">Total</td>
                  <td className="text-right">{handleZeroAndNull(interactionsCount.total.qtd)}</td>
                  <td className="text-right">{handleZeroAndNull(interactionsCount.total.ytd)}</td>
                  <td className="text-right">{handleZeroAndNull(interactionsCount.total.thy)}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col sm={12} lg="7" className={"overflow-auto"}>
            <Table hover className= "table-sm exportable" data-export-name={`${this.props.data.org?.name}-Details`}>
              <thead>
                <tr className="table-title">
                  <th colSpan={6} className="py-3 border-0">Details</th>
                </tr>
                <tr className="row-border-olive-100">
                  <th className="text-left">Type</th>
                  <th className="text-left">Date</th>
                  <th className="text-left">Contact</th>
                  <th className="text-left">Callan</th>
                  <th className="text-left">Subject</th>
                  <th className="text-left">Note</th>
                </tr>
              </thead>
              <tbody>
                {sortedInteractions.slice(0, 5).map((interaction, idx) => {
                  const date = moment(interaction.date, DATE_API_FORMAT)
                  const contact = _.first(interaction.contacts)
                  const callan = _.first(interaction.callanPeople)
                  return (
                    <tr key={idx}>
                      <td className="text-left"><InteractionIcon type={interaction.type}/></td>
                      <td className="text-left">{date.format(DATE_DISPLAY_FORMAT)}</td>
                      <td className="text-left">{contact && `${contact.firstName?.charAt(0)}. ${contact.lastName}`}</td>
                      <td className="text-left">{callan && `${callan.firstName?.charAt(0)}. ${callan.lastName}`}</td>
                      <td className="text-left">
                        <Button color="blue-link" className="float-left my-0 py-0 pl-0 d-inline-block text-truncate"  style={{maxWidth: "400px"}} onClick={() => this.navigateToScreen(managerInteractionLinkRenderer(interaction, this.props.data.org?.id))} title={interaction.subject || ""}>
                        {interaction.subject}
                        </Button>
                      </td>
                      <td className="text-left">{interaction.notes?.hasNotes && <InteractionIcon type='Check'/>}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        {this.props.auth.checkPermissions(["view:interactions"]) &&
          <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/interactions`)}>
            <span className="pr-1">View All Interactions</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        }
      </div>
    )
  }
  /*

    ASSETS

  */
  assetsTable = (manager:any) => {
    const fetchedAssets = _.get(manager, this.state.assetTab)
    const groupedAssets = _.groupBy(fetchedAssets, 'quarterEndDate')
    const assetDate = _.maxBy(Object.keys(groupedAssets), i => moment(i).valueOf()) || ""
    const sortedAssets = _.sortBy(_.get(groupedAssets, assetDate), [abc => abc.assetsUnderManagement || 0]).reverse()
    let assetTotal = {assets: 0, accounts: 0}
    let assetReceivedTotal = {assets: 0, accounts: 0}
    let extraTotal = {name: "", assets: 0, accounts: 0}
    let nameFetch = ""
    let displayName = ""
    let modalName =""
    switch(this.state.assetTab){
      case "assetsByClient":
        nameFetch = "clientType.value"
        displayName = "Client Type"
        modalName = "Client Type"
        break
      case "assetsByVehicle":
        nameFetch = "vehicleType.value"
        displayName = "Vehicle"
        modalName = "Vehicle"
        break
      case "assetsByRegion":
        nameFetch = "region.value"
        displayName = "Location"
        modalName = "Location"
        break
    }
    let tableData: any[] = []
    let graphData = sortedAssets.reduce(
      (
        result: any[],
        entry: any
      ) => {
        const name = _.get(entry, nameFetch)
        if(name === "Total"){
          assetReceivedTotal.assets = entry.assetsUnderManagement || 0
          assetReceivedTotal.accounts = entry.numberOfAccounts || 0
        } else if(result.length <= 3) {
          const data = {
            name: name,
            y: entry.assetsUnderManagement || 0,
            accounts: entry.numberOfAccounts || 0,
          }
          if(name === "Europe ex-UK" || name === "Defined Contribution"){
            extraTotal.name = name
            extraTotal.assets = entry.assetsUnderManagement || 0
            extraTotal.accounts = entry.numberOfAccounts || 0
          } else {
            result.push(data)
            tableData.push(data)
            assetTotal.assets += entry.assetsUnderManagement || 0
            assetTotal.accounts += entry.numberOfAccounts || 0
          }
        } else {
          let additional = cloneDeep(result.pop())
          let additionalTable = cloneDeep(tableData.pop())
          if (name === "Europe ex-UK" || name === "Defined Contribution"){
            extraTotal.name = name
            extraTotal.assets = entry.assetsUnderManagement || 0
            extraTotal.accounts = entry.numberOfAccounts || 0
          } else {
            additional.name = "Additional"
            additional.y += entry.assetsUnderManagement || 0
            additional.accounts += entry.numberOfAccounts || 0
            additionalTable.name = "Additional"
            additionalTable.y += entry.assetsUnderManagement || 0
            additionalTable.accounts += entry.numberOfAccounts || 0
            assetTotal.assets += entry.assetsUnderManagement || 0
            assetTotal.accounts += entry.numberOfAccounts || 0
          }
          tableData.push(additionalTable)
          result.push(additional)
        }
        return result
      },
      []
    ) || []
    // track number of cols that need to be added to later data if not part of first data set
    let colCount = 0
    const diffDate = moment(assetDate).subtract(this.state.assetHistoricalYears, "years")
    const startDate = _.minBy(Object.keys(groupedAssets), (i) => {
      const dateMoment = moment(i,DATE_API_FORMAT)
      return (dateMoment && dateMoment.diff(diffDate,"month") > 0) ? dateMoment.valueOf() : assetDate.valueOf()
    }) || ""
    const sortedHistoricalAssets = listQuarters(startDate, assetDate).reverse()
    let startHistoricalDate = 0
    let historicalGraphData = _.reduce(sortedHistoricalAssets,
      (
        result: GraphData[],
        date: any
      ) => {
        const entry = _.get(groupedAssets, date)
        const dateMoment = moment(date,DATE_API_FORMAT)
        let assetNames:string[] = entry?.map((o:any) => _.get(o, nameFetch))
        // Remove Total from possible values
        remove(assetNames, (name) => name === "Total")
        remove(assetNames, (name) => name === "Europe ex-UK")
        remove(assetNames, (name) => name === "Defined Contribution")
        // Only use data for the correct time period
        if(dateMoment && dateMoment.diff(diffDate,"month") > 0){
          if(startHistoricalDate === 0) startHistoricalDate = dateMoment.valueOf()
          result.forEach((assetType: any) => {
            const matchedResult = _.find(entry, (o) => _.get(o, nameFetch) === assetType.name)
            if(matchedResult){
              assetType.data.push([matchedResult.quarterEndDate, matchedResult.assetsUnderManagement])
              const name = _.get(matchedResult, nameFetch)
              const index = assetNames.indexOf(name)
              if (index > -1) {
                assetNames.splice(index, 1);
              }
            } else {
              assetType.data.push(0)
            }
          })
          // If no match fill out missing 0s to make data line up
          assetNames?.forEach((name) => {
            const matchedResult = _.find(entry, (o) => _.get(o, nameFetch) === name)
            let data = Array(colCount).fill(0)
            data.push([ matchedResult.quarterEndDate , matchedResult.assetsUnderManagement])
            result.push({ name, data })
          })
          colCount += 1
        }
        return result
      },
      []
    ) || []
    const sortedHistoricalGraphData = sortBy(historicalGraphData, (historicalEntry:GraphData) => {
      const index = findIndex(graphData, {"name": historicalEntry.name})
      return index === -1 ? 900 : index
    })
    return(
    <>
      <h3 className="mt-4 mb-3">Assets</h3>
      <div className="pane pane-table pane-profile mt-4 pb-5">
        <div className="pane-title d-flex">
            <h3>Assets {assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}</h3>
        </div>
        <Nav role="group" className="nav-bordered">
          <NavItem>
            <NavLink
              className={classnames({active: this.state.assetTab === "assetsByClient"})}
              onClick={()=>this.setState({assetTab: "assetsByClient"})}
            >
              Client Type
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: this.state.assetTab === "assetsByVehicle"})}
              onClick={()=>this.setState({assetTab: "assetsByVehicle"})}
            >
              Vehicle
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: this.state.assetTab === "assetsByRegion"})}
              onClick={()=>this.setState({assetTab: "assetsByRegion"})}
            >
              Location
            </NavLink>
          </NavItem>
        </Nav>
        <Row>
         <Col xl="2" sm="4">
            <ChartContainer
              options={ProfileAssetPieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
              height={200}
              hasData={graphData.length > 0 && assetTotal.assets > 0 && graphData.some((data) => data.y > 0)}
              modalName={`Assets by ${modalName} ${assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}`}
            >
              <ModalHeader className="chart-modal-header">
                Assets by {modalName} {assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}
              </ModalHeader>
              <ModalBody className="chart-modal-body">
                <Row>
                  <Col sm={4}>
                    <HighchartsReact
                      options={ProfileAssetPieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
                      highcharts={Highcharts}
                      containerProps = {{ style: {height: `400px`} }}
                    />
                  </Col>
                  <Col sm={8}>
                    {this.assetTable({displayName, tableData, assetTotal, assetReceivedTotal, extraTotal})}
                  </Col>
                </Row>
              </ModalBody>
            </ChartContainer>
          </Col>
          <Col xl="4" sm="8" className="border-right border-callan-olive overflow-auto">
            {this.assetTable({displayName, tableData, assetTotal, assetReceivedTotal, extraTotal})}
          </Col>
          <Col xl="6">
            <div className="d-flex w-100 align-items-center">
              <h5 className="mb-0">Historical Assets</h5>
              <Nav role="group" className="ml-auto">
                <NavItem>
                  <NavLink
                    className={classnames({active: this.state.assetHistoricalYears === 1})}
                    onClick={()=>this.setState({assetHistoricalYears: 1})}
                  >
                    1Y
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: this.state.assetHistoricalYears === 3})}
                    onClick={()=>this.setState({assetHistoricalYears: 3})}
                  >
                    3Y
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: this.state.assetHistoricalYears === 5})}
                    onClick={()=>this.setState({assetHistoricalYears: 5})}
                  >
                    5Y
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div>
              <ChartContainer
                options={ProfileStackedColumnOptions({heading: "", data: sortedHistoricalGraphData, startDate: startHistoricalDate, colours: graphColors.map((cl) => cl.hex)})}
                height={200}
                hasData={sortedHistoricalGraphData.length > 0}
                modalName={`Historical Assets by ${modalName} ${assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}`}
              />
            </div>
          </Col>
          </Row>
          <div className="expand-link">
            <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/assets/clientType`)}>
              <span className="pr-1">View All Assets</span>
              <FontAwesomeIcon
                icon={"chevron-right"}
                size="sm"
              />
            </Button>
          </div>
      </div>
    </>
    )
  }
  assetTable = ({displayName, tableData, assetTotal, assetReceivedTotal, extraTotal}:any) => {
    return(
      <Table hover className= " exportable" data-export-name={`${this.props.data.org?.name}-Assets`}>
      <thead>
        <tr className="row-border-olive-100">
          <th className="pl-1 pr-0"></th>
          <th className="pl-0 text-left">{displayName}</th>
          <th className="text-left">AUM($M)</th>
          <th className="text-left">Accounts</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((graphObject: any, idx: number) => {
          return(
            <tr key={idx}>
              <td className="pl-1 pr-0"><div className={`example-color-box background-${graphColors[idx % graphColors.length].name}`}></div></td>
              <td className="pl-0 text-left">{graphObject.name}</td>
              <td className="text-left">{numbro(graphObject.y || 0).format(`${ graphObject.y ? '$0,0.00' : '0,0.00'}`)}</td>
              <td className="text-left">{numbro(graphObject.accounts || 0).format("0")}</td>
            </tr>
          )
        })}
        <tr className="row-border-olive-100">
          <td></td>
          <td className="text-left pl-0">Total</td>
          <td className="text-left">{numbro(assetTotal.assets || assetReceivedTotal?.assets || 0).format(`${ assetTotal.accounts || assetReceivedTotal?.accounts ? '$0,0.00' : '0,0.00'}`)}</td>
          <td className="text-left">{numbro(assetTotal.accounts || assetReceivedTotal?.accounts || 0).format("0")}</td>
        </tr>
        {extraTotal?.assets > 0 &&
          <tr>
            <td></td>
            <td className="text-left pl-0">{extraTotal.name}</td>
            <td className="text-left">{numbro(extraTotal.assets || 0).format("$0,0.00")}</td>
            <td className="text-left">{numbro(extraTotal.accounts || 0).format("0")}</td>
          </tr>
        }
      </tbody>
    </Table>
    )
  }
  /*

    PRODUCTS

  */
  productsComponent = (products:any) => {
    if(products?.length === 0 ) return (<></>)
    const groupedProducts = _.groupBy(products, 'product.capitalStructure')
    const openProducts = _.get(groupedProducts, "OPEN", [])
    const activeOpenProducts = _.filter(openProducts, (el => !el?.product?.inactive))
    const sortedOpenProducts = _.sortBy(activeOpenProducts, [i => i.product?.inactive, i => -i.product?.latestAum?.aum])
    const closedProducts = _.get(groupedProducts, "CLOSED", [])
    const sortedClosedProducts = _.sortBy(closedProducts, [i => moment(i.closedEnded?.vintageYearFirstCashflow, DATE_API_FORMAT).valueOf() || 0]).reverse()
    const activePassiveMapping= {
      "ACTIVE": 'Active',
      "PASS": 'Passive',
      "BLEND": 'Blended'
    }

    return(
      <>
        <h3 className="mt-4 mb-3">Products</h3>
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div className="pane-title" id={OPEN_END_SECTION_ID}>
            <h3>Open-End</h3>
          </div>
          <Table hover className= " exportable" data-export-name={`${this.props.data.org?.name}-Products`}>
            <thead>
              <tr className="row-border-olive-100">
                <th className="text-left">Name</th>
                <th className="text-left">ID</th>
                <th className="text-left">Asset Class</th>
                <th className="text-left">Strategy Type</th>
                <th className="text-left">Active/Passive</th>
                <th className="text-left">AUM ($M)</th>
              </tr>
            </thead>
            <tbody>
              {sortedOpenProducts.slice(0, 5).map((product, idx) => {
                return(
                  <tr key={idx}>
                    <td className="text-left">
                      <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(productProfileLinkRenderer(product), `#${OPEN_END_SECTION_ID}`)}>
                      {product.product?.name}
                      </Button>
                    </td>
                    <td className="text-left">{product.product?.id}</td>
                    <td className="text-left">{product.product?.assetClass?.parent?.shortName}</td>
                    <td className="text-left">{product.product?.assetClass?.shortName}</td>
                    <td className="text-left">{_.get(activePassiveMapping, product.product?.activePassive)}</td>
                    <td className="text-left">{numbro(product.product?.latestAum?.aum || 0).format(`${product.product?.latestAum?.aum ? '$0,0.00' : '0,0.00'}`)}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/products`)}>
            <span className="pr-1">View All Products</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        </div>
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div className="pane-title" id={CLOSED_END_SECTION_ID}>
            <h3>Closed-End</h3>
          </div>
          <Table hover className= " exportable" data-export-name={`${this.props.data.org?.name}-Closed-End`}>
            <thead>
              <tr className="row-border-olive-100">
                <th className="text-left">Name</th>
                <th className="text-right">Vintage Year</th>
                <th className="text-left">Strategy Type</th>
                <th className="text-right">Fund Size ($M)</th>
                <th className="text-right">Projected Close</th>
              </tr>
            </thead>
            <tbody>
              {sortedClosedProducts.slice(0, 5).map((product, idx) => {
                const lastFunds = _.maxBy(product.closedEnded?.fundRaising, (i:FundRaising) => moment(i.date, DATE_API_FORMAT).valueOf())
                const lastDate = _.maxBy(product.closedEnded?.projectedCloseDates, (i:Date) => moment(i, DATE_API_FORMAT).valueOf())
                return(
                  <tr key={idx}>
                    <td className="text-left">
                      <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(productProfileLinkRenderer(product), `#${CLOSED_END_SECTION_ID}`)}>
                      {product.product?.name}
                      </Button>
                    </td>
                    <td className="text-right">{!!product.closedEnded?.vintageYearFirstCashflow ? moment(product.closedEnded?.vintageYearFirstCashflow, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT) : ""}</td>
                    <td className="text-left">{product.product.assetClass?.shortName}</td>
                    <td className="text-right">{numbro((lastFunds?.committedCapital || 0)/1_000_000).format(`${lastFunds?.committedCapital ? '$0,0.00' : '0,0.00' }`)}</td>
                    <td className="text-right">{lastDate ? moment(lastDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT) : ""}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/products`)}>
            <span className="pr-1">View All Products</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        </div>
      </>
    )
  }
  /*

    PEOPLE

  */
  peopleContent = (manager: any, factWriteUp: any, opinionWriteUp: any) => {
    const employeeRaceTypes = this.props.data.employeeRaceTypes?.enumValues
    const employeeGenderTypes = this.props.data.employeeGenderTypes?.enumValues
    const keyPeople = manager.employees.filter((person:Person) => person.background?.keyPerson && person.active)
    const fetchedEmployees = _.get(manager, this.state.employeeTab)
    const groupedEmployees = _.groupBy(fetchedEmployees, 'date')
    const assetDate = _.maxBy(Object.keys(groupedEmployees), i => moment(i, DATE_API_FORMAT).valueOf()) || ""
    const sortedEmployees: SortedEmployees[] = _.sortBy(_.get(groupedEmployees, assetDate), [abc => abc.count]).reverse()

    let graphData
    let employeeTotal = {count: 0, gained: 0, lost: 0}
    let tableHeader = ""
    let modalName = ""
    switch(this.state.employeeTab){
      case "employeeTurnover":
        tableHeader = "Asset Class"
        modalName = "Asset Class"
        break
      case "employeeBreakdownByJobCategory":
        tableHeader = "Employees"
        modalName = "Job Role"
        break
      case "employeeBreakdownByDiversityProfile":
        tableHeader = "Diversity Profile"
        modalName = "Diversity"
        break
      case "employeeBreakdownByGender":
        tableHeader = "Gender Breakdown"
        modalName = "Gender"
        break
    }

    const employeeSortedRaceType: SortedEmployees[] = [];
    const employeeSortedGenderType: SortedEmployees[] = [];

    employeeGenderTypes?.forEach((employeeGender) => {
      employeeSortedGenderType.push({
        __typename: null,
        count: null,
        date: null,
        type: {
          __typename: employeeGender.__typename,
          value: employeeGender.value,
        },
      });
    });

    employeeGenderTypes?.forEach((employeeGender) => {
      sortedEmployees.forEach((sortedEmployee) => {
        if (sortedEmployee.type.value === employeeGender.value) {
          const empSortedGenderTypeIndex = employeeSortedGenderType.map((el) => el.type.value).indexOf(sortedEmployee.type.value);
          employeeSortedGenderType[empSortedGenderTypeIndex] = {
            __typename: sortedEmployee.__typename,
            count: sortedEmployee.count,
            date: sortedEmployee.date,
            type: {
              __typename: employeeGender.__typename,
              value: employeeGender.value,
            },
          };
        }
      });
    });

    employeeRaceTypes?.forEach((employeeRace) => {
      employeeSortedRaceType.push({
        __typename: null,
        count: null,
        date: null,
        type: {
          __typename: employeeRace.__typename,
          value: employeeRace.value,
        },
      });
    });

    employeeRaceTypes?.forEach((employeeRace) => {
      sortedEmployees.forEach((sortedEmployee) => {
        if (sortedEmployee.type.value === employeeRace.value) {
          const empSortedRaceTypeIndex = employeeSortedRaceType.map((el) => el.type.value).indexOf(sortedEmployee.type.value);
          employeeSortedRaceType[empSortedRaceTypeIndex] = {
            __typename: sortedEmployee.__typename,
            count: sortedEmployee.count,
            date: sortedEmployee.date,
            type: {
              __typename: employeeRace.__typename,
              value: employeeRace.value,
            },
          };
        }
      });
    });

    const handleSortEmployees = () => tableHeader === 'Diversity Profile' ? employeeSortedRaceType :  tableHeader === "Gender Breakdown" ? employeeSortedGenderType : sortedEmployees

    if(this.state.employeeTab === "employeeTurnover"){
      const groupedTurnoverEmployees = _.groupBy(fetchedEmployees, 'assetClass.shortName')
      const assetDateCompare = moment(assetDate, DATE_API_FORMAT).subtract(3,"years")
      const turnoverData = _.reduce(groupedTurnoverEmployees,
        (
          result: any[],
          entry: any[]
        ) => {
          const total = entry.reduce(
            (
              totals: EmployeeTurnoverData,
              single: any
            ) => {
              // Only want the current count for main date
              if(single.date === assetDate){
                totals.y += single.count
              }
              // Sum up gained and lost from last 3 years
              if(assetDateCompare.diff(moment(single.date, DATE_API_FORMAT)) < 0 ){
                totals.gained += single.gained
                totals.lost += single.lost
              }
              return totals
            },
            {
              name: _.get(_.first(entry), "assetClass.fullName"),
              y: 0,
              gained: 0,
              lost: 0,
            }
          )
          result.push(total)
          return result
        },
        []
      )
      const sortedTurnoverData = _.sortBy(turnoverData, [tod => tod.y]).reverse()
      graphData = sortedTurnoverData.reduce(
        (
          result: any[],
          entry: any
        ) => {
          if(entry.y === 0 || entry.y == null){

          } else if(result.length < 3) {
            result.push(entry)
            employeeTotal.count += entry.y
            employeeTotal.gained += entry.gained
            employeeTotal.lost += entry.lost
          } else if(result.length === 3) {
            result.push({
              name: "Additional",
              y: entry.y || 0,
              gained: entry.gained || 0,
              lost: entry.lost || 0,
            })
            employeeTotal.count += entry.y
            employeeTotal.gained += entry.gained
            employeeTotal.lost += entry.lost
          } else {
            let additional = result.pop()
            additional.y += entry.y || 0
            additional.gained += entry.gained || 0
            additional.lost += entry.lost || 0
            result.push(additional)
            employeeTotal.count += entry.y
            employeeTotal.gained += entry.gained
            employeeTotal.lost += entry.lost
          }
          return result
        },
        []
      ) || []
    } else {
      graphData = handleSortEmployees().reduce(
        (
          result: any[],
          entry: any
        ) => {
          let name = _.get(entry, "type.value")
          if(name === "Total Employees"){

          } else if(result.length < 10) {
            result.push({
              name: name,
              y: entry.count,
            })
            employeeTotal.count += entry.count
          } else if(result.length === 10) {
            result.push({
              name: "Additional",
              y: entry.count,
            })
            employeeTotal.count += entry.count
          } else {
            let additional = result.pop()
            additional.y += entry.count || 0
            result.push(additional)
            employeeTotal.count += entry.count
          }
          return result
        },
        []
      ) || []
    }

    return(
      <>
        <h3 className="mt-4 mb-3">People</h3>
        {this.writeUpComponent("People Overview", factWriteUp, "peopleExpanded", "Fact", "people")}
        {this.writeUpComponent("Callan People Opinion",opinionWriteUp, "peopleOpinionExpanded", "Opinion", "people")}
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div className="pane-title d-flex">
            <h3>Firm Leadership</h3>
          </div>
          <Table hover className= " exportable" data-export-name={`${this.props.data.org?.name}-Firm Leadership`}>
            <thead>
              <tr className="row-border-olive-100">
                <th className="text-left">Name</th>
                <th className="text-left">Title</th>
                <th className="text-left">Firm Experience (Yrs.)</th>
                <th className="text-left">Industry Experience (Yrs.)</th>
              </tr>
            </thead>
            <tbody>
              {keyPeople.map((person:Person, idx:number) => {
                return(
                  <tr key={idx}>
                    <td className="text-left">
                      <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(managerPeopleLinkRenderer(person, this.props.data.org?.id))}>
                      {person.firstName} {person.lastName}
                      </Button>
                    </td>
                    <td className="text-left">{person.title}</td>
                    <td className="text-left">{!!person.background?.yearStartedWithFirm ? appDate.year() - person.background?.yearStartedWithFirm : ""}</td>
                    <td className="text-left">{!!person.background?.yearStartedInIndustry ? appDate.year() - person.background?.yearStartedInIndustry : ""}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/people?keypeople=true`)}>
          <span className="pr-1">View all Executives</span>
          <FontAwesomeIcon
            icon={"chevron-right"}
            size="sm"
          /></Button>
        </div>
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div className="pane-title d-flex">
            <h3>Employee Count &amp; Turnover {assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`} </h3>
          </div>
          <Nav role="group" className="nav-bordered">
            <NavItem>
              <NavLink
                className={classnames({active: this.state.employeeTab === "employeeTurnover"})}
                onClick={()=>this.setState({employeeTab: "employeeTurnover"})}
              >
                Asset Class
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: this.state.employeeTab === "employeeBreakdownByJobCategory"})}
                onClick={()=>this.setState({employeeTab: "employeeBreakdownByJobCategory"})}
              >
                Job Role
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: this.state.employeeTab === "employeeBreakdownByDiversityProfile"})}
                onClick={()=>this.setState({employeeTab: "employeeBreakdownByDiversityProfile"})}
              >
                Diversity
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: this.state.employeeTab === "employeeBreakdownByGender"})}
                onClick={()=>this.setState({employeeTab: "employeeBreakdownByGender"})}
              >
                Gender
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <Col md="2">
              <ChartContainer
                options={ProfileEmployeePieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
                height={200}
                hasData={graphData.length > 0 && employeeTotal.count > 0}
              >
                <ModalHeader className="chart-modal-header">
                  Employee Count & Turnover by {modalName} {assetDate && `as of ${moment(assetDate, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}`}
                </ModalHeader>
                <ModalBody className="chart-modal-body">
                  <Row>
                    <Col sm={4}>
                      <HighchartsReact
                        options={ProfileEmployeePieOptions({heading: "", dataName: "AUM ($M)", data: graphData, colours: graphColors.map((cl) => cl.hex)})}
                        highcharts={Highcharts}
                        containerProps = {{ style: {height: `400px`} }}
                      />
                    </Col>
                    <Col sm={8}>
                      {this.employeeTable({graphData, tableHeader, employeeTotal})}
                    </Col>
                  </Row>
                </ModalBody>
              </ChartContainer>
            </Col>
            <Col>
              {this.employeeTable({graphData, tableHeader, employeeTotal})}
            </Col>
          </Row>
          <div className="expand-link">
            <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/overview/employees`)}>
              <span className="pr-1">View All Employees</span>
              <FontAwesomeIcon
                icon={"chevron-right"}
                size="sm"
              />
            </Button>
          </div>
        </div>
      </>
    )
  }

  employeeTable({ graphData, tableHeader, employeeTotal }: any) {
    return(
      <Table hover className= " exportable" data-export-name={`${this.props.data.org?.name}-Employee Count & Turnover`}>
      <thead>
        <tr className="row-border-olive-100">
          <th className="pl-1 pr-0"></th>
          <th className="pl-0 text-left">{tableHeader}</th>
          <th className="text-left"># of Employees</th>
          {this.state.employeeTab === "employeeTurnover" &&
            <>
              <th className="text-left">3-Year Gain</th>
              <th className="text-left">3-Year Loss</th>
            </>
          }
        </tr>
      </thead>
      <tbody>
          {graphData.map((graphObject: any, idx: number) => {
          const handleOnNullValue = (value: any) => !isNull(value) ? numbro(value).format("0,0") : '-'
          return(
            <tr key={idx}>
              <td className="pl-1 pr-0"><div className={`example-color-box background-${graphColors[idx % graphColors.length].name}`}></div></td>
              <td className="pl-0 text-left">{graphObject.name}</td>
              <td className="text-left">{handleOnNullValue(graphObject.y)}</td>
              {this.state.employeeTab === "employeeTurnover" &&
                <>
                  <td className="text-left">{handleOnNullValue(graphObject.gained)}</td>
                  <td className="text-left">{handleOnNullValue(graphObject.lost)}</td>
                </>
              }
            </tr>
          )
        })}
        <tr className="row-border-olive-100">
          <td></td>
          <td className="text-left pl-0">Total</td>
          <td className="text-left">{numbro(employeeTotal.count || 0).format("0,0")}</td>
          {this.state.employeeTab === "employeeTurnover" &&
            <>
              <td className="text-left">{numbro(employeeTotal.gained || 0).format("0,0")}</td>
              <td className="text-left">{numbro(employeeTotal.lost || 0).format("0,0")}</td>
            </>
          }
        </tr>
      </tbody>
    </Table>
    )
  }
  /*

    DOCUMENTS

  */
  documentContent(documents:File[]) {
    if(!(this.props.auth.checkPermissions(["view:documents"]) || this.props.auth.checkPermissions(["view:my_documents"]) || this.props.auth.checkPermissions(["view:manager_documents"]) || this.props.auth.checkPermissions(["view:all_documents"]))) return <></>
    const sortedDocuments = _.sortBy(documents, (i) => {
      return _.reduce(i.versions, (result, value) => {
        const currentValue = moment(value.updated, DATE_API_FORMAT).valueOf()
        return _.max([currentValue, result]) || 0
      }, 0)
    }).reverse()
    return(
      <>
        <h3 className="mt-4 mb-3">Documents</h3>
        <div className="pane pane-table pane-profile mt-4 pb-5">
          <div className="pane-title d-flex">
            <h3>Documents</h3>
          </div>
          <Table hover className= " exportable" data-export-name={`${this.props.data.org?.name}-Documents`}>
            <thead>
              <tr className="row-border-olive-100">
                <th className="text-left">Date</th>
                <th className="text-left">Type</th>
                <th className="text-left">Topic</th>
                <th className="text-left">Description</th>
                <th className="text-left">Download</th>
              </tr>
            </thead>
            <tbody>
              {sortedDocuments.slice(0, 5).map((document:File, idx:number) => {
                const latestVersion = _.maxBy(document.versions, i => moment(i.updated, DATE_API_FORMAT).valueOf())
                return(
                  <tr key={idx}>
                    <td className="text-left">{latestVersion?.updated && moment(latestVersion?.updated, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)}</td>
                    <td className="text-left">{document.type?.value}</td>
                    <td className="text-left">{document.subType?.value}</td>
                    <td className="text-left">
                      <Button color="blue-link" className="float-left my-0 py-0 pl-0" onClick={() => this.navigateToScreen(managerDocumentLinkRenderer(document, this.props.data.org?.id))}>
                        {document.description || document.name}
                      </Button>
                    </td>
                    <td className="text-left">
                      {document.url &&
                        <a href={document.url} download={document.name} rel="noreferrer" target="_blank"><FontAwesomeIcon size="sm" icon="download"/></a>
                      }
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/documents`)}>
            <span className="pr-1">View All Documents</span>
            <FontAwesomeIcon
              icon={"chevron-right"}
              size="sm"
            />
          </Button>
        </div>
      </>
    )
  }

  writeUpFactDisplay(writeUp:any) {
    if(!writeUp) return {__html: ""}
    if(writeUp.text){
      return {__html: DOMPurify.sanitize(writeUp.text)}
    }
    if(writeUp.bullets){
      return {__html: DOMPurify.sanitize(writeUp.bullets)}
    }
    return {__html: ""}
  }

  writeUpOpinionDisplay(writeUp:any, type: "merits" | "considerations") {
    if(!writeUp) return {__html: ""}
    let bullets:string = ""
    let text:string = ""
    if(type === "merits"){
      text = writeUp.meritsText
      bullets = writeUp.meritsBullets
    } else if(type === "considerations"){
      text = writeUp.considerationsText
      bullets = writeUp.considerationsBullets
    }
    if(bullets && bullets.localeCompare("\n") !== 0){
      return {__html: DOMPurify.sanitize(bullets)}
    } else if (text){
      return {__html: DOMPurify.sanitize(text)}
    }
    return {__html: ""}
  }

  groupWriteUps(writeUpList:any[] | undefined | null){
    if(!writeUpList) return {}
    return writeUpList.reduce(
      (
        result: writeUpList,
        entry: Fact | Opinion
      ) => {
        const code = (entry.__typename === "Fact" && entry.researchCategory?.code) || (entry.__typename === "Opinion" && `${entry.researchCategory?.code}:${entry.client?.id}`) || ""
        const foundWriteUp = _.get(result, code)
        if(foundWriteUp){
          if(moment(entry.lastUpdated).diff(moment(foundWriteUp.lastUpdated)) >= 0) _.set(result, code || "", entry)
        } else {
          _.set(result, code || "", entry)
        }
        return result
      },
      {}
    )
  }

  writeUpComponent(heading: string, writeUp: Fact | Opinion, expandedVariable: string, type: "Fact" | "Opinion", linkName?: string){
    const expanded = _.get(this.state, expandedVariable)
    const stateToggle = _.set({}, expandedVariable, !expanded)
    let firmOverview  
    let extraRows: ExtraRow[] = []
    if (this.props.data && this.props.data.org && this.props.data.org.__typename === "Manager"){
      const manager = this.props.data.org
      const factWriteUps = this.groupWriteUps(manager.writeUps?.facts)
      firmOverview = get(factWriteUps, "OOVER", null)?.text
      extraRows.push({ values: ['Firm Overview']})
      extraRows.push({ values: [firmOverview]})
    }
    if(type === "Fact"){
      return (
        <div className="pane pane-table pane-profile mt-4 exportable" data-export-name={`${this.props.data.org?.name}-Firm Overview`} data-export-settings={(JSON.stringify({extraRows}))}>
          <div className="pane-title">
            <h3>{heading}</h3>
          </div>
          <div className="expandable-textarea-container">
            <div className={`expandable-container${expanded ? ' expanded' : ''}`}>
              <p className="intro" dangerouslySetInnerHTML={this.writeUpFactDisplay(writeUp)}></p>
            </div>
            <div className="expand-link">
              <Button color="link" onClick={()=> this.setState(stateToggle)}>
                <FontAwesomeIcon
                  icon={expanded  ? "chevron-up" : "chevron-down"}
                  size="sm"
                />
                <span className="pl-2 expand-text">{expanded  ? "Collapse" : "Expand"}</span>
              </Button>
              {this.props.auth.checkPermissions(["edit:writeups"]) &&
                <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/write-ups/${linkName}`)}>
                  <span className="pr-1">View All Write-ups</span>
                  <FontAwesomeIcon
                    icon={"chevron-right"}
                    size="sm"
                  />
                </Button>
              }
            </div>
          </div>
        </div>
      )
    } else if (type === "Opinion"){
      if(!this.props.auth.checkPermissions(["view:writeups_opinion"])) return <></>
      const opinion = writeUp as Opinion
      return (
        <div className="pane pane-table pane-profile mt-4">
          <div className="pane-title">
            <h3>{heading}</h3>
          </div>
          <p>Status <FontAwesomeIcon icon="circle" className={`ml-4 mr-2 text-${statusColor(opinion?.status?.value)}`} />{opinion?.status?.value}</p>
          <div className="expandable-textarea-container">
            <div className={`expandable-container${expanded ? ' expanded' : ''}`}>
              <Row>
                <Col>
                  <h3 className="headline underline border-callan-olive">Merits</h3>
                  <p className="intro" dangerouslySetInnerHTML={this.writeUpOpinionDisplay(opinion, "merits")}></p>
                </Col>
                <Col>
                  <h3 className="headline underline border-callan-olive">Considerations</h3>
                  <p className="intro" dangerouslySetInnerHTML={this.writeUpOpinionDisplay(opinion, "considerations")}></p>
                </Col>
              </Row>
            </div>
            <div className="expand-link">
              <Button color="link" onClick={()=> this.setState(stateToggle)}>
                <FontAwesomeIcon
                  icon={expanded  ? "chevron-up" : "chevron-down"}
                  size="sm"
                />
                <span className="pl-2 expand-text">{expanded  ? "Collapse" : "Expand"}</span>
              </Button>
              {this.props.auth.checkPermissions(["edit:writeups"]) &&
                <Button color="blue-link" className="float-right" onClick={() => this.navigateToScreen(`/managers/${this.props.data.org?.id}/write-ups/${linkName}`)}>
                  <span className="pr-1">View All Write-ups</span>
                  <FontAwesomeIcon
                    icon={"chevron-right"}
                    size="sm"
                  />
                </Button>
              }
            </div>
          </div>
        </div>
      )
    } else {
      return(<></>)
    }
  }

  esgScoreDisplay = (callanEsgScores: Maybe<CallanEsgScores> | undefined) => {
    let esgScore: any
    let showScore = callanEsgScores?.completeAnswer === 1
    let score: Maybe<number> | undefined = callanEsgScores?.weightedScore
    if (showScore && _.isNumber(score)) {
      esgScore = (numbro(((score as any) || 0) as number)).format({mantissa: 2})
    } else {
      esgScore = "N/A"
    }
    return  (
      <>
        <dt className="d-inline-flex justify-content-between tooltip-icon" id="callanESGScoreTooltip">
          {"Callan ESG Score"}
          <FontAwesomeIcon
            icon={"question-circle" as IconName}
            className={"align-self-start mt-1 mr-2"}
            size="sm"
          />
        </dt>
        <dd className="align-self-start">
          {esgScore}
        </dd>
      </>
    )
  }

  deiScoreDisplay = (callanDeiScores: Maybe<CallanDeiScores> | undefined) => {
    let deiScore: any
    let showScore = callanDeiScores?.completeAnswer === 1
    let score: Maybe<number> | undefined = callanDeiScores?.weightedScore
    if (showScore && score) {
      deiScore = (numbro(((score as any) || 0) as number)).format({mantissa: 2})
    } else {
      deiScore = "N/A"
    }
    return  (
      <>
        <dt className="d-inline-flex justify-content-between tooltip-icon" id="callanDEIScoreTooltip">
          {"Callan DEI Score"}
          <FontAwesomeIcon
            icon={"question-circle" as IconName}
            className={"align-self-start mt-1 mr-2"}
            size="sm"
          />
        </dt>
        <dd className="align-self-start">
          {deiScore}
        </dd>
      </>
    )
  }

  render() {
    var colwidth = 4
    if(!this.props.auth.checkPermissions(["view:all_clients"])){
        colwidth =  6
    }
    if (this.props.data && this.props.data.org && this.props.data.org.__typename === "Manager"){
      const manager = this.props.data.org
      const factWriteUps = this.groupWriteUps(manager.writeUps?.facts)
      const opinionWriteUps = this.groupWriteUps(manager.writeUps?.opinions)
      const totalAssets = _.maxBy(manager.totalAssetsUnderManagement, (i) => moment(i.quarterEndDate, DATE_API_FORMAT).valueOf())?.assetsUnderManagement || 0
      let extraRows: ExtraRow[] = []
      extraRows.push({ values: ['Details']})
      extraRows.push({ values: ['Founded', manager.structure?.yearFounded?.toString()]})
      extraRows.push({ values: ['Headquarters', manager.address?.city, manager.address?.state]})
      extraRows.push({ values: ['Ownership', manager.structure?.ownershipType?.value]})
      extraRows.push({ values: ['Parent', manager.structure?.parentCompany]})
      extraRows.push({ values: ['MWDO', ((manager.structure?.womanOwned || manager.structure?.disabledOwned || manager.structure?.minorityOwned || manager.structure?.minorityWomanOwned) ? "Yes" : "No")]})
      extraRows.push({ values: ['Callan ESG Score', manager?.callanEsgScores?.weightedScore?.toString()]})
      extraRows.push({ values: ['Callan DEI Score', manager?.callanDeiScores?.weightedScore?.toString()]})
      return (
        <>
        {/*

          OVERVIEW

        */}
        <div>
            <Row>
              <Col className="mt-2">
                <h3>
                  Overview
                </h3>
              </Col>
              <Col className="text-right">
                <Button color="secondary btn-thin" className="text-callan-blue mb-3" onClick={()=> exportTables()}>
                  Export CSV
                  <img src='/assets/CSV.svg' className="ml-2"/>
                </Button>
              </Col>
            </Row>
        </div>
        <div className="exportable" data-export-name={`${this.props.data.org?.name}-Overview Details`} data-export-settings={(JSON.stringify({extraRows}))}></div>
          <div className="pane pane-table pane-profile">
            <Row>
              <Col md={colwidth}>
                <h3 className="headline underline">Details</h3>
                <dl>
                  <dt>Founded</dt>
                  <dd>{manager.structure?.yearFounded}</dd>
                  <dt>Headquarters</dt>
                  <dd>{manager.address?.city}, {manager.address?.state}</dd>
                  <dt>Ownership</dt>
                  <dd>{manager.structure?.ownershipType?.value}</dd>
                  <dt>Parent</dt>
                  <dd>{manager.structure?.parentCompany}</dd>
                  <dt>MWDO</dt>
                  <dd>{(manager.structure?.womanOwned || manager.structure?.disabledOwned || manager.structure?.minorityOwned || manager.structure?.minorityWomanOwned) ? "Yes" : "No"}</dd>
                  {this.esgScoreDisplay(manager?.callanEsgScores)}
                  {this.deiScoreDisplay(manager?.callanDeiScores)}
                </dl>
              </Col>
              {this.props.auth.checkPermissions(["view:all_clients"]) &&
                <Col md={colwidth}>
                  {manager.managerRelatedClientExposureTotal && this.exposureComponent(manager.managerRelatedClientExposureTotal as ManagerRelatedClientExposureTotal[], totalAssets)}
                </Col>
              }
              <Col md={colwidth}>
                {this.totalAssetsComponent(manager)}
              </Col>
            </Row>
          </div>
          {this.writeUpComponent("Firm Overview",_.get(factWriteUps, "OOVER"), "overviewExpanded", "Fact", "overall")}
          {this.clientComponent((manager as Manager)?.managerRelatedClientPlanExposure, totalAssets)}
          {this.interactionsTable(manager.interactions)}
          {this.writeUpComponent("Callan Firm Opinion",_.get(opinionWriteUps, "OOVER:244"), "overviewOpinionExpanded", "Opinion", "overall")}
          {this.assetsTable(manager)}
          {this.productsComponent(manager.products)}
          {this.peopleContent(manager, _.get(factWriteUps, "OPEO"), _.get(opinionWriteUps, "OPEO:244"))}
          {this.documentContent(manager.documents as File[])}
        </>
      )
    } else {
      return (<div>data doesn't exist</div>)
    }
  }
}

export default ManagerProfile
