import _ from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import { DebtMaturityScheduleItemUnion, FormInputSubClasses } from "../../helpers/constant"
import { FormInput } from "../ui/Forms/FormInput"

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  optionSource?: string
  noFormat?: true
  readonly?: true
}

interface DebtMaturityScheduleTableItemProps {
  data: any
  rowDefItem: RowDefItem
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (idx: number, type: string, value: any, property: string) => void
  isLastItem?: boolean
}

type DebtMaturityScheduleTableProps = {
  data: (DebtMaturityScheduleItemUnion & {uniqueId?: number}) []
  handleChange: (state: any) => void
  editMode: boolean
  hidden: boolean
}

const DebtMaturityScheduleRowDef: RowDefItem[] = [
  {
    field: "year",
    type: "text",
    title: "Debt Maturity",
    noFormat: true,
    readonly: true,
    subClasses: {
      inputWrapperClasses: "pl-1",
      inputClasses: "text-center"
    }
  },
  {
    field: "property",
    type: "float",
    subtype: "currency",
    title: "Property ($M)",
    subClasses: {
      inputWrapperClasses: "pl-1"
    }
  },
  {
    field: "fund",
    type: "float",
    subtype: "currency",
    title: "Fund ($M)",
    subClasses: {
      inputWrapperClasses: "pl-1"
    }
  }
]

interface DebtMaturityScheduleTableRowProps {
  data: any[]
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

const DebtMaturityScheduleTableRow = ({
  data,
  rowDef,
  editMode,
  updateValue
}: DebtMaturityScheduleTableRowProps) => {
  let field = rowDef.field
  let dataLength = data.length
  while(dataLength < 5){
    let nextYear = (_.last(data)?.year || moment().year()) + 1
    data.push({year: nextYear, property: 0, fund: 0, uniqueId: nextYear})
    dataLength = data.length
  }
  return (
    <tr>
      <td key={`DebtMaturitySchedule-${field}`}>{rowDef.title}</td>
      {data.map((item: any, idx: number) => {
        let isLastItem = false
        if (rowDef.field === "year" && idx === dataLength - 1) {
          isLastItem = true
        }
        return (
          <DebtMaturityScheduleTableItem
            data={item}
            rowDefItem={rowDef}
            key={`${item.uniqueId}-${idx}`}
            idx={idx}
            editMode={editMode}
            updateValue={updateValue}
            isLastItem={isLastItem}
          />
        )
      })}
    </tr>
  )
}

const DebtMaturityScheduleTableItem = ({
  data,
  rowDefItem,
  idx,
  editMode,
  updateValue,
  isLastItem
}: DebtMaturityScheduleTableItemProps) => {
  let { field, type, title, subtype, subClasses, noFormat, readonly } = rowDefItem
  let value = data[field]
  if (isLastItem && field == "year") {
    value = `${value} +`
  }
  return (
    <td key={`${data.uniqueId}-${idx}`} className={""}>
      <FormInput
        key={`${data.uniqueId}-${idx}`}
        property={field}
        displayName={""}
        type={type}
        subtype={subtype}
        idx={idx}
        editMode={editMode}
        propertyVal={value}
        placeholder={"0"}
        subClasses={subClasses}
        noFormat={noFormat}
        readonly={readonly}
        updateValue={value => updateValue(idx, value, type, field)}
      />
    </td>
  )
}

const DebtMaturityScheduleTableDisplay = (
  seed: DebtMaturityScheduleTableProps
) => {
  const tableName = "Debt-Maturity-Schedule"
  const widthClassName = "col-sm-12"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)
  const [currentId, setId] = useState(1)
  const generateId = () => {
    // return a number as id (since year number is primary key in data)
    let nextId = currentId + 1
    setId(nextId)
    return nextId
  }

  useEffect(() => {
    setData(data)
  }, [data])

  const onAddDebtMaturityScheduleRow = (row: DebtMaturityScheduleItemUnion & {uniqueId?: number}) => {
    const newData = [...tableState, row]
    setData(newData)
    handleChange(newData)
  }
  const onRemoveDebtMaturityScheduleRow = (colIndex: number) => {
    let newData = [
      ...tableState.slice(0, colIndex),
      ...tableState.slice(colIndex + 1)
    ]
    setData(newData)
    handleChange(newData)
  }
  const actionMap = {
    add: onAddDebtMaturityScheduleRow,
    remove: onRemoveDebtMaturityScheduleRow
  }

  const onHandleChangeDebtMaturityScheduleRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = tableState.map((el, index) => {
      if (idx === index) {
        return { ...tableState[index], [property]: value }
      } else {
        return el
      }
    })
    // setData(newData)
    handleChange(newData)
  }

  return (
    <div className={`${tableName}-table ${widthClassName} row form-group pl-0`}>
      <div className={"py-2"}>
        {
          "Provide the upcoming debt maturity schedule as of current quarter. Year20XX+ should include all future maturities."
        }
      </div>
      <Table
        hover
        size="sm"
        key={`DebtMaturitySchedule-table`}
        className={""}
      >
        <tbody key={0}>
          {DebtMaturityScheduleRowDef.map((rowDef, rowIndex) => (
            <DebtMaturityScheduleTableRow
              key={rowIndex}
              data={data}
              rowDef={rowDef}
              editMode={editMode}
              updateValue={onHandleChangeDebtMaturityScheduleRow}
            />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default DebtMaturityScheduleTableDisplay
