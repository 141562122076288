import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  Button,
  ButtonGroup,
  ButtonDropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card, CardBody,
  CardHeader,
  CardText,
  Form,
  FormGroup,
  Input,
  InputProps,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  ListGroup,
  ListGroupItem,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  UncontrolledAlert
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icon from "./../Components/ui/Icon"
import Loading from "./../Components/ui/Loading"
import { WithTopNav } from "./../Components/ui/LayoutWrapper"
import Header from "./../Components/Shared/Header"
import PlaceHolder from "./../Components/ui/PlaceHolder"

const StyleGuide = () => {

  const [dropdownOpen, setOpen] = useState(false);
  const [locked, setLock] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const lock = () => setLock(!locked);
  const toggleModal = () => setModal(!modal);

  return (
    <div>
    <Container fluid>
      <Row>
        <Col>
          <h1>Style Guide</h1>
          <Loading />
        </Col>
      </Row>
      {/*

        COLORS

      */}
      <Row className="mt-3">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Color Palette</h2>
        </Col>
      </Row>
      <Row xs="4" md="6" className="mt-3">
        <Col>
          <div className="background-blue-100 pt-5" />
          <p className="p-2 background-white">$callan-blue</p>
        </Col>
        <Col>
          <div className="background-olive-100 pt-5" />
          <p className="p-2 background-white">$callan-olive</p>
        </Col>
        <Col>
          <div className="background-gray-90 pt-5" />
          <p className="p-2 background-white">$title-color</p>
        </Col>
        <Col>
          <div className="background-accent-gray pt-5" />
          <p className="p-2 background-white">$text-color</p>
        </Col>
      </Row>
      <Row xs="3" md="6" className="mt-3">
        <Col>
          <h5>Blue</h5>
          <div className="background-blue-140 pt-5" />
          <p className="p-2 background-white">$blue-140</p>
          <div className="background-blue-120 pt-5" />
          <p className="p-2 background-white">$blue-120</p>
          <div className="background-blue-100 pt-5" />
          <p className="p-2 background-white">$blue-100</p>
          <div className="background-blue-80 pt-5" />
          <p className="p-2 background-white">$blue-80</p>
          <div className="background-blue-70 pt-5" />
          <p className="p-2 background-white">$blue-70</p>
          <div className="background-blue-50 pt-5" />
          <p className="p-2 background-white">$blue-50</p>
          <div className="background-blue-30 pt-5" />
          <p className="p-2 background-white">$blue-30</p>
          <div className="background-blue-20 pt-5" />
          <p className="p-2 background-white">$blue-10</p>
        </Col>
        <Col>
          <h5>Green + Teal</h5>
          <div className="background-green-100 pt-5" />
          <p className="p-2 background-white">$green-100</p>
          <div className="background-green-80 pt-5" />
          <p className="p-2 background-white">$green-80</p>
          <div className="background-green-50 pt-5" />
          <p className="p-2 background-white">$green-50</p>
          <div className="background-green-30 pt-5" />
          <p className="p-2 background-white">$green-30</p>
        </Col>
        <Col>
          <h5>Gray</h5>
          <div className="background-gray-100 pt-5" />
          <p className="p-2 background-white">$gray-100</p>
          <div className="background-gray-90 pt-5" />
          <p className="p-2 background-white">$gray-90</p>
          <div className="background-gray-80 pt-5" />
          <p className="p-2 background-white">$gray-80</p>
          <div className="background-gray-70 pt-5" />
          <p className="p-2 background-white">$gray-70</p>
          <div className="background-gray-50 pt-5" />
          <p className="p-2 background-white">$gray-50</p>
          <div className="background-gray-30 pt-5" />
          <p className="p-2 background-white">$gray-30</p>
          <div className="background-gray-20 pt-5" />
          <p className="p-2 background-white">$gray-20</p>
          <div className="background-gray-10 pt-5" />
          <p className="p-2 background-white">$gray-10</p>
        </Col>
        <Col>
          <h5>Orange</h5>
          <div className="background-orange-100 pt-5" />
          <p className="p-2 background-white">$orange-100</p>
          <div className="background-orange-80 pt-5" />
          <p className="p-2 background-white">$orange-80</p>
          <div className="background-orange-50 pt-5" />
          <p className="p-2 background-white">$orange50</p>
          <div className="background-orange-30 pt-5" />
          <p className="p-2 background-white">$orange-30</p>
          <h5>Blue Gray</h5>
          <div className="background-bluegray-50 pt-5" />
          <p className="p-2 background-white">$bluegray-50</p>
          <div className="background-bluegray-20 pt-5" />
          <p className="p-2 background-white">$bluegray-20</p>
        </Col>
        <Col>
          <h5>Purple</h5>
          <div className="background-purple-100 pt-5" />
          <p className="p-2 background-white">$purple-100</p>
          <div className="background-purple-80 pt-5" />
          <p className="p-2 background-white">$purple-80</p>
          <div className="background-purple-50 pt-5" />
          <p className="p-2 background-white">$purple-50</p>
          <div className="background-purple-30 pt-5" />
          <p className="p-2 background-white">$purple-30</p>
        </Col>
        <Col>
          <h5>Olive</h5>
          <div className="background-olive-100 pt-5" />
          <p className="p-2 background-white">$olive-100</p>
          <div className="background-olive-80 pt-5" />
          <p className="p-2 background-white">$olive-80</p>
          <div className="background-olive-50 pt-5" />
          <p className="p-2 background-white">$olive-50</p>
          <div className="background-olive-30 pt-5" />
          <p className="p-2 background-white">$olive-30</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Accent Colors</h5>
        </Col>
      </Row>
      <Row xs="3" md="6">
        <Col>
          <div className="background-accent-red pt-5" />
          <p className="p-2 background-white">$accent-red</p>
        </Col>
        <Col>
          <div className="background-accent-orange pt-5" />
          <p className="p-2 background-white">$accent-orange</p>
        </Col>
        <Col>
          <div className="background-accent-yellow pt-5" />
          <p className="p-2 background-white">$accent-yellow</p>
        </Col>
        <Col>
          <div className="background-accent-olive pt-5" />
          <p className="p-2 background-white">$accent-olive</p>
        </Col>
        <Col>
          <div className="background-accent-green pt-5" />
          <p className="p-2 background-white">$accent-green</p>
        </Col>
        <Col>
          <div className="background-accent-teal pt-5" />
          <p className="p-2 background-white">$accent-teal</p>
        </Col>
        <Col>
          <div className="background-accent-blue pt-5" />
          <p className="p-2 background-white">$accent-blue</p>
        </Col>
        <Col>
          <div className="background-accent-violet pt-5" />
          <p className="p-2 background-white">$accent-violet</p>
        </Col>
        <Col>
          <div className="background-accent-purple pt-5" />
          <p className="p-2 background-white">$accent-purple</p>
        </Col>
        <Col>
          <div className="background-accent-pink pt-5" />
          <p className="p-2 background-white">$accent-pink</p>
        </Col>
        <Col>
          <div className="background-accent-brown pt-5" />
          <p className="p-2 background-white">$accent-brown</p>
        </Col>
        <Col>
          <div className="background-accent-gray pt-5" />
          <p className="p-2 background-white">$accent-gray</p>
        </Col>
        <Col>
          <div className="background-accent-black pt-5" />
          <p className="p-2 background-white">$accent-black</p>
        </Col>
      </Row>
      {/*

        TYPOGRAPHY

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Typography</h2>
          <h1 className="mt-3">h1. Callan Heading</h1>
          <h2 className="mt-4">h2. Callan Heading</h2>
          <h3 className="mt-4">h3. Callan Heading</h3>
          <h4 className="mt-4">h4. Callan Heading</h4>
          <h5 className="mt-4">h5. Callan Heading</h5>
          <h1 className="mb-0 mt-5 text-gray-100">Heading with Subheader</h1>
          <h3 className="subheader">Subheader</h3>
          <p className="mt-4">p. Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which lifts the horizon of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished out, and the car seemed to float in the middle of an immense dark sphere, whose upper half was strewn with silver. Looking down into the dark gulf below, I could see a ruddy light streaming through a rift in the clouds.</p>
          <p className="mt-4 intro">p.intro. Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which lifts the horizon of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished out, and the car seemed to float in the middle of an immense dark sphere, whose upper half was strewn with silver. Looking down into the dark gulf below, I could see a ruddy light streaming through a rift in the clouds.</p>
          <p className="mt-4 description">p.description. Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle. By the same illusion which lifts the horizon of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished out, and the car seemed to float in the middle of an immense dark sphere, whose upper half was strewn with silver. Looking down into the dark gulf below, I could see a ruddy light streaming through a rift in the clouds.</p>

          <h2 className="headline mt-5">h2. Headline</h2>

          <h2 className="headline underline mt-5">h2. Headline.underline</h2>
          <h3 className="headline underline mt-4">h3. Headline.underline</h3>
        </Col>
      </Row>
      {/*

        NAVIGATION TABS

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Navigation Tabs</h2>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="10" sm="8" md="6">
          <Breadcrumb>
            <BreadcrumbItem tag="a" key="test" href="/">
              Roark Capital Group
            </BreadcrumbItem>
          </Breadcrumb>
          <UncontrolledDropdown className="headline-dropdown">
            <DropdownToggle caret>
              <h2 className="headline">Company Name</h2>
            </DropdownToggle>
            <DropdownMenu>
              <h5>View another product by Roark Capital Group</h5>
              <Row>
                <Col sm="12" md="6">
                  <Form>
                    <FormGroup row className="relative m-0">
                      <Input type="text" placeholder="Find Product by name" />
                      <span className="o-88 absolute center-v right-1 pe-none">
                        <FontAwesomeIcon
                          icon={["fas", "search"]}
                          size="2x"
                          className="fontawesome-icon dark-icon-color text-gray-50"
                        />
                      </span>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <ListGroup className="headline-dropdown-links">
                <ListGroupItem tag="a" href="#">AB All China Equity Advisor</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB All Market Income Portfolio</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB Asia ex-Japan Equity Portfolio</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB Balanced Wealth Strategy</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB China Value A Shares</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB Concentrated International Growth</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB Consv Inv</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB Credit Long/Short Portfolio</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB Emerging Market Corporate Debt</ListGroupItem>
                <ListGroupItem tag="a" href="#">AB Emerging Market Debt Diversified</ListGroupItem>
                <ListGroupItem tag="a" href="#">Extra Link</ListGroupItem>
                <ListGroupItem tag="a" href="#">Extra Link</ListGroupItem>
                <ListGroupItem tag="a" href="#">Extra Link</ListGroupItem>
                <ListGroupItem tag="a" href="#">Extra Link</ListGroupItem>
                <ListGroupItem tag="a" href="#">Extra Link</ListGroupItem>
                <ListGroupItem tag="a" href="#">Extra Link</ListGroupItem>
              </ListGroup>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2 className="headline">Company Name</h2>
          <h5 className="mt-3">Nav tabs collapsed</h5>
          <Nav className="sub-nav sub-nav-primary collapsed" tabs role="group">
            <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
              <DropdownToggle caret>
                Link Two
              </DropdownToggle>
              <DropdownMenu>
                <NavItem>
                  <NavLink href="/" className="beta">
                    Link One
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="active" href="/">
                    Link Two
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/">
                    Link Three
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/">
                    Link Four
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/" className="beta">
                    Link Five
                  </NavLink>
                </NavItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

          <h5 className="mt-3">Nav tabs expanded</h5>
          <Nav className="sub-nav sub-nav-primary" tabs role="group">
            <UncontrolledDropdown className="nav-tabs-dropdown" direction="down">
              <DropdownToggle caret>
                Link Two
              </DropdownToggle>
              <DropdownMenu>
                <NavItem>
                  <NavLink href="/">
                    Link One
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="active" href="/">
                    Link Two
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/" className="beta">
                    Link Three
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/" className="beta">
                    Link Four
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/">
                    Link Five
                  </NavLink>
                </NavItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <Nav className="sub-nav sub-nav-secondary" tabs role="group">
            <NavItem>
              <NavLink className="active" href="/">
                Secondary Link One
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/">
                Secondary Link Two
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/">
                Secondary Link Three
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/">
                Secondary Link Four
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/">
                Secondary Link Five
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      {/*

        BUTTONS

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Buttons</h2>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h5>Default</h5>
          <Button color="primary" className="mr-1">Primary</Button>
          <Button color="secondary" className="mr-1">Secondary</Button>
          <Button color="light" className="mr-1">Light</Button>
          <Button disabled className="mr-1">Disabled</Button>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="mr-1">
            <DropdownToggle caret>
              Selector
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Header</DropdownItem>
              <DropdownItem>First Action</DropdownItem>
              <DropdownItem>Another Action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Another Action</DropdownItem>
              <DropdownItem disabled>Disabled</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <Button color="primary" className="mr-1">
            Labeled Icon
            <FontAwesomeIcon
              icon="arrow-right"
              size="xs"
              className="ml-2"
            />
          </Button>
          <Button color="secondary" className="mr-1">
            <FontAwesomeIcon
              icon="edit"
              size="xs"
              className="mr-2"
            />
            Labeled Icon
          </Button>
          <Button color="link" className="mr-1">
            Link with icon
            <FontAwesomeIcon
              icon="plus-circle"
              className="ml-2 text-blue-100"
            />
          </Button>
          <Button color="primary btn-thin" className="mr-1">
            <FontAwesomeIcon
              icon="cloud"
            />
          </Button>
          <Button color="secondary btn-thin" className="mr-1">
            <FontAwesomeIcon
              icon="cloud"
            />
          </Button>
          <Button color="light btn-thin" className="mr-1">
            <FontAwesomeIcon
              icon="cloud"
            />
          </Button>
        </Col>
      </Row>
      <Row  sm="1" md="3" className="mt-5">
        <Col>
          <h5>Small</h5>
          <Button color="primary" size="sm" className="mr-1">Primary</Button>
          <Button color="secondary" size="sm" className="mr-1">Secondary</Button>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-sm mr-1">
            <DropdownToggle caret>
              Selector
            </DropdownToggle>
          </ButtonDropdown>
        </Col>
        <Col>
          <h5>Medium</h5>
          <Button color="primary" size="md" className="mr-1">Primary</Button>
          <Button color="secondary" size="md" className="mr-1">Secondary</Button>
        </Col>
        <Col>
          <h5>Large</h5>
          <Button color="primary" size="lg" className="mr-1">Primary</Button>
          <Button color="secondary" size="lg" className="mr-1">Secondary</Button>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h5>Toggle</h5>
          <Button className={`btn-lockable mr-1 ${locked ? "locked" : ""}`} onClick={() => lock()}>
            Lockable button
          </Button>
        </Col>
        <Col>
          <h5>Calendar</h5>
          <Button className={`btn-lockable btn-cal mr-1 ${locked ? "locked" : ""}`} onClick={() => lock()}>
            <span>
              <FontAwesomeIcon
                icon="calendar"
                size="sm"
                className="mr-2"
              />
              Q4 2019
            </span>
          </Button>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h5>Groups</h5>
          <ButtonGroup className="mr-1">
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
          </ButtonGroup>
          <ButtonGroup className="mr-1">
            <Button className="btn-thin">
              <FontAwesomeIcon
                icon="align-left"
              />
            </Button>
            <Button className="btn-thin">
              <FontAwesomeIcon
                icon="align-center"
              />
            </Button>
            <Button className="btn-thin">
              <FontAwesomeIcon
                icon="align-right"
              />
            </Button>
            <Button className="btn-thin">
              <FontAwesomeIcon
                icon="align-justify"
              />
            </Button>
          </ButtonGroup>
          <ButtonGroup className="mr-1">
            <Button className="btn-thin">
              <FontAwesomeIcon
                icon="bold"
              />
            </Button>
            <Button className="btn-thin">
              <FontAwesomeIcon
                icon="italic"
              />
            </Button>
            <Button className="btn-thin">
              <FontAwesomeIcon
                icon="underline"
              />
            </Button>
            <Button className="btn-thin">
              <FontAwesomeIcon
                icon="strikethrough"
              />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h5>Other Bootstrap Buttons</h5>
          <Button color="success" className="mr-1">Success</Button>
          <Button color="info" className="mr-1">Info</Button>
          <Button color="warning" className="mr-1">Warning</Button>
          <Button color="danger" className="mr-1">Danger</Button>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h5>Accent Colors</h5>
          <Button color="red" className="mr-1">Red</Button>
          <Button color="orange" className="mr-1">Orange</Button>
          <Button color="yellow" className="mr-1">Yellow</Button>
          <Button color="olive" className="mr-1">Olive</Button>
          <Button color="green" className="mr-1">Green</Button>
          <Button color="teal" className="mr-1">Teal</Button>
          <Button color="blue" className="mr-1">Blue</Button>
          <Button color="violet" className="mr-1">Violet</Button>
          <Button color="purple" className="mr-1">Purple</Button>
          <Button color="pink" className="mr-1">Pink</Button>
          <Button color="white" className="mr-1">White</Button>
          <Button color="gray" className="mr-1">Gray</Button>
          <Button color="black" className="mr-1">Black</Button>
        </Col>
      </Row>
      <Row className="mt-3 background-accent-black py-3">
        <Col>
          <Button color="red" outline className="mr-1">Red</Button>
          <Button color="orange" outline className="mr-1">Orange</Button>
          <Button color="yellow" outline className="mr-1">Yellow</Button>
          <Button color="olive" outline className="mr-1">Olive</Button>
          <Button color="green" outline className="mr-1">Green</Button>
          <Button color="teal" outline className="mr-1">Teal</Button>
          <Button color="blue" outline className="mr-1">Blue</Button>
          <Button color="violet" outline className="mr-1">Violet</Button>
          <Button color="purple" outline className="mr-1">Purple</Button>
          <Button color="pink" outline className="mr-1">Pink</Button>
          <Button color="white" outline className="mr-1">White</Button>
          <Button color="gray" outline className="mr-1">Gray</Button>
          <Button color="black" outline className="mr-1">Black</Button>
        </Col>
      </Row>
      {/*

        ICONS

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Icons</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="12" lg="4">
          <FontAwesomeIcon icon="plus-circle" className="mr-4" />
          <FontAwesomeIcon icon="minus-circle" className="mr-4" />
          <FontAwesomeIcon icon="pen" className="mr-4" />
          <FontAwesomeIcon icon="upload" className="mr-4" />
          <FontAwesomeIcon icon="download" className="mr-4" />
          <FontAwesomeIcon icon="filter" className="mr-4" />
          <FontAwesomeIcon icon="trash" className="mr-4" />
          <FontAwesomeIcon icon="search" className="mr-4" />
          <FontAwesomeIcon icon="plus" className="mr-4" />
          <FontAwesomeIcon icon="file-pdf" className="mr-4" />
          <FontAwesomeIcon icon={['far', 'lock']} className="mr-4" />
          <FontAwesomeIcon icon={['far', 'unlock']} className="mr-4" />
          <Icon icon="quarter" className="mr-4" />
          <p>General</p>
        </Col>
        <Col sm="12" lg="2">
          <Icon icon="callan" className="mr-4" />
          <FontAwesomeIcon icon="ellipsis-v" className="mr-4" />
          <p>Nav</p>
        </Col>
        <Col sm="12" lg="3">
          <FontAwesomeIcon icon="chevron-circle-down" className="mr-4" />
          <FontAwesomeIcon icon="chevron-circle-left" className="mr-4" />
          <FontAwesomeIcon icon="chevron-circle-right" className="mr-4" />
          <FontAwesomeIcon icon="chevron-circle-up" className="mr-4" />
          <FontAwesomeIcon icon="chevron-down" className="mr-4" />
          <FontAwesomeIcon icon="chevron-left" className="mr-4" />
          <FontAwesomeIcon icon="chevron-right" className="mr-4" />
          <FontAwesomeIcon icon="chevron-up" className="mr-4" />
          <p>Chevrons</p>
        </Col>
        <Col sm="12" lg="3">
          <FontAwesomeIcon icon="angle-down" className="mr-4" />
          <FontAwesomeIcon icon="angle-left" className="mr-4" />
          <FontAwesomeIcon icon="angle-right" className="mr-4" />
          <FontAwesomeIcon icon="angle-up" className="mr-4" />
          <p>Angles</p>
        </Col>
      </Row>
      {/*

        PANES

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Panes</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="pane pane-toolbar">
            <Button className="btn-lockable btn-cal mr-1 locked" >
              <span>
                <FontAwesomeIcon
                  icon="calendar"
                  size="sm"
                  className="mr-2"
                />
                Q4 2019
              </span>
            </Button>
            <Button color="primary" className="ml-auto">
              Edit
              <FontAwesomeIcon
                icon="pen"
                size="xs"
                className="ml-2"
              />
            </Button>
          </div>
          <div className="pane pane-toolbar">
            <Button color="link" className="mr-auto">
              <FontAwesomeIcon
                icon="plus-circle"
                className="mr-2 text-blue-100"
              />
              Add Row
            </Button>
            <Button color="secondary" className="mr-1 ml-auto">Cancel</Button>
            <Button type="submit" color="primary" className="mr-1">Save</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="pane">
            <div className="pane-title">
              <h3>Pane Title</h3>
            </div>
            <p>Single column pane content</p>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <div className="pane">
            <div className="pane-title">
              <h3>Pane Title</h3>
            </div>
            <Row>
              <Col>
                <p>Column one pane content</p>
              </Col>
              <Col>
                <p>Column two pane content</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="9">
          <div className="pane">
            <p>Two panes on screen</p>
          </div>
        </Col>
        <Col>
          <div className="pane">
            <p>Two panes on screen</p>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <div className="pane">
            <div className="pane-title-alt">
              <h3>Pane Title</h3>
            </div>
            <p>Single column pane content</p>
          </div>
        </Col>
      </Row>
      {/*

        TABLES

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Tables</h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Standard Table</h5>
          <Table hover bordered>
            <thead>
              <tr className="uppercase">
                <th>Product name</th>
                <th>Product Id</th>
                <th>Asset class</th>
                <th>Strategy Type</th>
                <th>Active/Passive</th>
                <th>AUM($M)</th>
                <th>Notes Activity</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>AB All China Equity Advisor</td>
                <td>45160</td>
                <td>Emerging Markets</td>
                <td>Non-US Developed</td>
                <td></td>
                <td>$1,000</td>
                <td></td>
                <td>Active</td>
              </tr>
              <tr>
                <td>AB All Market Alternative Return Portfolio</td>
                <td>43485</td>
                <td>Alternative Inv</td>
                <td>Participant Loans</td>
                <td>Active</td>
                <td>$1,000</td>
                <td>Example note goes here...</td>
                <td>Active</td>
              </tr>
              <tr>
                <td>AB All Market Income Portfolio</td>
                <td>43484</td>
                <td>Domestic Balanced</td>
                <td>Domestic TAA</td>
                <td>Active</td>
                <td>$1,000</td>
                <td></td>
                <td>Active</td>
              </tr>
              <tr>
                <td>AB Alternative Risk Premia</td>
                <td>43922</td>
                <td>Divsfd Multi-Asset</td>
                <td>MAC Risk Premia</td>
                <td>Active</td>
                <td>$1,000</td>
                <td></td>
                <td>Active</td>
              </tr>
              <tr>
                <td>AB Asia ex-Japan Equity Portfolio</td>
                <td>43486</td>
                <td>Intl Equity</td>
                <td>Other Intl Equity</td>
                <td>Active</td>
                <td>$1,000</td>
                <td></td>
                <td>Active</td>
              </tr>
              <tr>
                <td>AB Balanced Real Asset</td>
                <td>36502</td>
                <td>Real Assets</td>
                <td>Farmland</td>
                <td>Active</td>
                <td>$1,000</td>
                <td></td>
                <td>Active</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Modular Table</h5>
          <div className="pane pane-table">
            <div className="pane-title">
              <h3>Table Name</h3>
            </div>
            <Table>
              <thead>
                <tr className="row-border-olive-100">
                  <th>Name</th>
                  <th className="text-right">Vintage Year</th>
                  <th>Strategy Type</th>
                  <th>Status</th>
                  <th className="text-right">Fund Size ($M)</th>
                  <th className="text-right">Projected Close</th>
                  <th className="text-right">Final Close</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Fund V</td>
                  <td className="text-right">2018</td>
                  <td>Buyout</td>
                  <td>Fundraising</td>
                  <td className="text-right">$5,000</td>
                  <td className="text-right">7/15/2019</td>
                  <td className="text-right">7/15/2019</td>
                </tr>
                <tr>
                  <td>Fund IV</td>
                  <td className="text-right">2016</td>
                  <td>Buyout</td>
                  <td>Closed</td>
                  <td className="text-right">$2,500</td>
                  <td className="text-right">6/2/2017</td>
                  <td className="text-right">6/2/2017</td>
                </tr>
                <tr>
                  <td>Fund III</td>
                  <td className="text-right">2012</td>
                  <td>Buyout</td>
                  <td>Closed</td>
                  <td className="text-right">$1,500</td>
                  <td className="text-right">8/13/2013</td>
                  <td className="text-right">8/13/2013</td>
                </tr>
                <tr>
                  <td>Fund II</td>
                  <td className="text-right">2008</td>
                  <td>Buyout</td>
                  <td>Closed</td>
                  <td className="text-right">$979</td>
                  <td className="text-right">4/24/2009</td>
                  <td className="text-right">4/24/2009</td>
                </tr>
                <tr>
                  <td>Fund I</td>
                  <td className="text-right">2005</td>
                  <td>Buyout</td>
                  <td>Closed</td>
                  <td className="text-right">$400</td>
                  <td className="text-right">11/7/2006</td>
                  <td className="text-right">11/7/2006</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Deletable Row Table</h5>
          <div className="pane pane-table">
            <div className="table-container">
              <Table hover>
                <thead>
                  <tr className="row-border-olive-100">
                    <th>Name</th>
                    <th className="text-right">Vintage Year</th>
                    <th>Strategy Type</th>
                    <th>Status</th>
                    <th className="text-right">Fund Size ($M)</th>
                    <th className="text-right">Projected Close</th>
                    <th className="text-right">Final Close</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr className="hover-actions editing">
                    <td>Fund V</td>
                    <td className="text-right">2018</td>
                    <td>Buyout</td>
                    <td>Fundraising</td>
                    <td className="text-right">$5,000</td>
                    <td className="text-right">7/15/2019</td>
                    <td className="text-right">7/15/2019</td>
                    <td className="actions">
                      <Button color="link" className="btn-thin">
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                    </td>
                  </tr>
                  <tr className="hover-actions editing">
                    <td>Fund IV</td>
                    <td className="text-right">2016</td>
                    <td>Buyout</td>
                    <td>Closed</td>
                    <td className="text-right">$2,500</td>
                    <td className="text-right">6/2/2017</td>
                    <td className="text-right">6/2/2017</td>
                    <td className="actions">
                      <Button color="link" className="btn-thin">
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                    </td>
                  </tr>
                  <tr className="hover-actions editing">
                    <td>Fund III</td>
                    <td className="text-right">2012</td>
                    <td>Buyout</td>
                    <td>Closed</td>
                    <td className="text-right">$1,500</td>
                    <td className="text-right">8/13/2013</td>
                    <td className="text-right">8/13/2013</td>
                    <td className="actions">
                      <Button color="link" className="btn-thin">
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                    </td>
                  </tr>
                  <tr className="hover-actions editing">
                    <td>Fund II</td>
                    <td className="text-right">2008</td>
                    <td>Buyout</td>
                    <td>Closed</td>
                    <td className="text-right">$979</td>
                    <td className="text-right">4/24/2009</td>
                    <td className="text-right">4/24/2009</td>
                    <td className="actions">
                      <Button color="link" className="btn-thin">
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                    </td>
                  </tr>
                  <tr className="hover-actions editing">
                    <td>Fund I</td>
                    <td className="text-right">2005</td>
                    <td>Buyout</td>
                    <td>Closed</td>
                    <td className="text-right">$400</td>
                    <td className="text-right">11/7/2006</td>
                    <td className="text-right">11/7/2006</td>
                    <td className="actions">
                      <Button color="link" className="btn-thin">
                        <FontAwesomeIcon
                          icon="trash"
                        />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <h5>Basic</h5>
          <h3 className="headline underline mt-4">Versions</h3>
          <Table hover>
            <thead>
              <tr className="uppercase unbordered text-gray-80">
                <th></th>
                <th>Person</th>
                <th>Updated</th>
                <th>Reason for Change</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className="unbordered">
                <td>5</td>
                <td>Dianne Tanihara</td>
                <td>4/30/2019</td>
                <td className="text-gray-70">Reason</td>
                <td><FontAwesomeIcon icon="download" className="mr-4" /></td>
                <td><FontAwesomeIcon icon="trash" className="mr-4" /></td>
              </tr>
              <tr>
                <td>4</td>
                <td>Dianne Tanihara</td>
                <td>4/30/2019</td>
                <td className="text-gray-70">Reason</td>
                <td><FontAwesomeIcon icon="download" className="mr-4" /></td>
                <td><FontAwesomeIcon icon="trash" className="mr-4" /></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Dianne Tanihara</td>
                <td>4/30/2019</td>
                <td className="text-gray-70">Reason</td>
                <td><FontAwesomeIcon icon="download" className="mr-4" /></td>
                <td><FontAwesomeIcon icon="trash" className="mr-4" /></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Dianne Tanihara</td>
                <td>4/30/2019</td>
                <td className="text-gray-70">Reason</td>
                <td><FontAwesomeIcon icon="download" className="mr-4" /></td>
                <td><FontAwesomeIcon icon="trash" className="mr-4" /></td>
              </tr>
              <tr>
                <td>1</td>
                <td>Dianne Tanihara</td>
                <td>4/30/2019</td>
                <td className="text-gray-70">Reason</td>
                <td><FontAwesomeIcon icon="download" className="mr-4" /></td>
                <td><FontAwesomeIcon icon="trash" className="mr-4" /></td>
              </tr>
            </tbody>
          </Table>
          <div className="d-flex">
          <Button color="link" className="ml-auto">
            Show more
            <FontAwesomeIcon
              icon="chevron-circle-down"
              className="ml-2 text-blue-100"
            />
          </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Dual Field Table</h5>
          <Table hover className="table-bordered-internal">
            <thead>
              <tr className="table-title row-border-olive-100">
                <th></th>
                <th colSpan={4} className="py-2">Assets ($M)</th>
                <th colSpan={4} className="py-2"># Accounts</th>
              </tr>
              <tr>
                <th>Client Type</th>
                <th>Total</th>
                <th>Taxable</th>
                <th>Tax-Exempt</th>
                <th>Institutional</th>
                <th>Total</th>
                <th>Taxable</th>
                <th>Tax-Exempt</th>
                <th>Institutional</th>
              </tr>
            </thead>
            <tbody className="text-right">
              <tr>
                <td className="border-right-0 text-left">Corporate</td>
                <td className="border-left-0">57,179.46</td>
                <td>10,982.36</td>
                <td>46,197.10</td>
                <td>56,852.41</td>
                <td>202</td>
                <td>32</td>
                <td>170</td>
                <td>200</td>
              </tr>
              <tr>
                <td className="border-right-0 text-left">Superannuation</td>
                <td className="border-left-0">-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td className="border-right-0 text-left">Public Fund (Gov)</td>
                <td className="border-left-0">63,616.26</td>
                <td>-</td>
                <td>63,616.26</td>
                <td>63,595.93</td>
                <td>103</td>
                <td>-</td>
                <td>103</td>
                <td>103</td>
              </tr>
              <tr>
                <td className="border-right-0 text-left">Union/Multi-Emp</td>
                <td className="border-left-0">3,755.66</td>
                <td>-</td>
                <td>3,755.66</td>
                <td>3,755.66</td>
                <td>37</td>
                <td>-</td>
                <td>37</td>
                <td>37</td>
              </tr>
              <tr>
                <td className="border-right-0 text-left">Found&amp;Endow</td>
                <td className="border-left-0">4,401.86</td>
                <td>45.16</td>
                <td>4,356.70</td>
                <td>4,401.86</td>
                <td>29</td>
                <td>-</td>
                <td>29</td>
                <td>29</td>
              </tr>
            </tbody>
            <tfoot className="text-right">
              <tr>
                <td className="border-right-0 text-left">Total</td>
                <td className="border-left-0">554,651.65</td>
                <td>251,555.96</td>
                <td>303,095.68</td>
                <td>256,569.58</td>
                <td>42,680</td>
                <td>27,905</td>
                <td>14,775</td>
                <td>656</td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Table with Errors</h5>
          <div className="pane pane-table">
            <Table>
              <thead>
                <tr className="row-border-olive-100">
                  <th className="text-left">Wide Column</th>
                  <th className="text-left">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={15} className="p-0">
                    <Alert color="error">
                      <p>Entries contain <strong>3</strong> errors. <a href="#">edit</a></p>
                    </Alert>
                  </td>
                </tr>
                <tr>
                  <td className="text-left wide">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-left">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td className="text-left wide">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-left">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td className="text-left wide">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-left">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control error" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control error" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td className="text-left wide">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-left">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td className="text-left wide">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-left">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="Title" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control error" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                  <td className="text-right">
                    <FormGroup className="form-group row">
                      <div className="col-sm-12">
                        <Input className="form-control" disabled value="0" />
                      </div>
                    </FormGroup>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Table with highlight</h5>
          <div className="pane pane-table">
            <Table>
              <thead>
                <tr className="row-border-olive-100">
                  <th className="text-left wide">Wide Column</th>
                  <th className="text-left">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                  <th className="text-right">Col</th>
                </tr>
              </thead>
              <tbody>
                <tr className="fadein">
                  <td className="text-left">Title</td>
                  <td className="text-left">Title</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                </tr>
                <tr className="fadein">
                  <td className="text-left">Title</td>
                  <td className="text-left">Title</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                </tr>
                <tr className="fadein">
                  <td className="text-left">Title</td>
                  <td className="text-left">Title</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                </tr>
                <tr className="fadein">
                  <td className="text-left">Title</td>
                  <td className="text-left">Title</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                </tr>
                <tr className="fadein">
                  <td className="text-left">Title</td>
                  <td className="text-left">Title</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                  <td className="text-right">10</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      {/*

        SEARCH RESULT

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Search Results</h2>
        </Col>
      </Row>
      <Row className="mt-3 mb-5">
        <Col>
          <ListGroup className="horizontal with-category">
            <ListGroupItem tag="a" href="#">
              <div className="category product">
                <div className="category-text">
                  <h6>Product</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>AB All China Equity Advisor</h3>
                    <h5 className="d-inline mr-1">Manager:</h5>
                    <p className="d-inline"><strong>Roark</strong> Capital Group.</p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <h5>ID</h5>
                        <p>45160</p>
                      </Col>
                      <Col md="2">
                        <h5>Asset Class</h5>
                        <p>Emerging Markets</p>
                      </Col>
                      <Col md="2">
                        <h5>Strategy Type</h5>
                        <p>Non-US Developed</p>
                      </Col>
                      <Col md="2">
                        <h5>Status</h5>
                        <p>Active</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category product">
                <div className="category-text">
                  <h6>Product</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>AB All European Equity Advisor</h3>
                    <h5 className="d-inline mr-1">Manager:</h5>
                    <p className="d-inline"><strong>Roark</strong> Capital Group.</p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <h5>ID</h5>
                        <p>41133</p>
                      </Col>
                      <Col md="2">
                        <h5>Asset Class</h5>
                        <p>Equity</p>
                      </Col>
                      <Col md="2">
                        <h5>Strategy Type</h5>
                        <p>Non-US Developed</p>
                      </Col>
                      <Col md="2">
                        <h5>Status</h5>
                        <p>Active</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category product">
                <div className="category-text">
                  <h6>Product</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>AB All Brazil Equity Advisor</h3>
                    <h5 className="d-inline mr-1">Manager:</h5>
                    <p className="d-inline"><strong>Roark</strong> Capital Group.</p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <h5>ID</h5>
                        <p>45160</p>
                      </Col>
                      <Col md="2">
                        <h5>Asset Class</h5>
                        <p>Emerging Markets</p>
                      </Col>
                      <Col md="2">
                        <h5>Strategy Type</h5>
                        <p>Non-US Developed</p>
                      </Col>
                      <Col md="2">
                        <h5>Status</h5>
                        <p>Active</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category vehicle">
                <div className="category-text">
                  <h6>Vehicle</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>EAFE Equity Index F (w/o Sec Lending)</h3>
                    <h5 className="d-inline mr-1">Manager:</h5>
                    <p className="d-inline"><strong>Roark</strong> Capital Group.</p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="2">
                        <h5>Vehicle ID</h5>
                        <p>LL07</p>
                      </Col>
                      <Col md="2">
                        <h5>Product Name</h5>
                        <p>EAFE Index</p>
                      </Col>
                      <Col md="2">
                        <h5>Ticker</h5>
                        <p>GSSP</p>
                      </Col>
                      <Col md="2">
                        <h5>Vehicle Type</h5>
                        <p>Separate Account</p>
                      </Col>
                      <Col md="2">
                        <h5>Strategy Type</h5>
                        <p>Fixed Income</p>
                      </Col>
                      <Col md="2">
                        <h5>Status</h5>
                        <p>Active</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category manager">
                <div className="category-text">
                  <h6>Manager</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>Roark Capital Group</h3>
                    <p>&nbsp;</p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <h5>Manager ID</h5>
                        <p>14100</p>
                      </Col>
                      <Col md="4">
                        <h5>Location</h5>
                        <p>San Francisco, CA</p>
                      </Col>
                      <Col md="2">
                        <h5>Status</h5>
                        <p>Active</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category document">
                <div className="category-text">
                  <h6>Document</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>AllianceBernstein Global Core Equity R...</h3>
                    <p>
                      <FontAwesomeIcon icon="file-pdf" className="mr-1" />Research
                    </p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="4">
                        <h5>Manager</h5>
                        <p><strong>Roark</strong> Capital Group.</p>
                      </Col>
                      <Col md="2">
                        <h5>Last Updated</h5>
                        <p>Aug 21, 2019</p>
                      </Col>
                      <Col md="4">
                        <h5>Author</h5>
                        <p>Dianne Taniharat</p>
                      </Col>
                      <Col md="2">
                        <Button color="light" size="sm">Download</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category person">
                <div className="category-text">
                  <h6>Person</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>Jones, Tim</h3>
                    <h5 className="d-inline mr-1">Manager:</h5>
                    <p className="d-inline"><strong>Roark</strong> Capital Group.</p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <h5>Title</h5>
                        <p>Founding &amp; Managing Partner</p>
                      </Col>
                      <Col md="4">
                        <h5>Location</h5>
                        <p>San Francisco, CA</p>
                      </Col>
                      <Col md="2">
                        <h5>Status</h5>
                        <p>Active</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category person">
                <div className="category-text">
                  <h6>Person</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>Hughes, Tanya</h3>
                    <h5 className="d-inline mr-1">Manager:</h5>
                    <p className="d-inline"><strong>Roark</strong> Capital Group.</p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="6">
                        <h5>Title</h5>
                        <p>Vice President</p>
                      </Col>
                      <Col md="4">
                        <h5>Location</h5>
                        <p>San Francisco, CA</p>
                      </Col>
                      <Col md="2">
                        <h5>Status</h5>
                        <p>Active</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category target">
                <div className="category-text">
                  <h6>Target DT</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>AB Multi-Manager Elect Ret 2035</h3>
                    <h5 className="d-inline mr-1">Manager:</h5>
                    <p className="d-inline"><strong>Roark</strong> Capital Group.</p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md={{ size:"4", offset: "6" }}>
                        <h5>Glide Path Philosophy</h5>
                        <p>[Glide Path Philosophy]</p>
                      </Col>
                      <Col md="2">
                        <h5>Status</h5>
                        <p>Passive Blend</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category document">
                <div className="category-text">
                  <h6>Document</h6>
                </div>
              </div>
              <div className="w-100">
                <Row>
                  <Col md="3">
                    <h3>AllianceBernstein Global Core Equity R...</h3>
                    <p>
                      <FontAwesomeIcon icon="file-pdf" className="mr-1" />Research
                    </p>
                  </Col>
                  <Col md="9">
                    <Row>
                      <Col md="4">
                        <h5>Attached to</h5>
                        <p><strong>Roark</strong> Capital Group and 2 others</p>
                      </Col>
                      <Col md="2">
                        <h5>Last Updated</h5>
                        <p>Aug 21, 2019</p>
                      </Col>
                      <Col md="4">
                        <h5>Author</h5>
                        <p>Dianne Taniharat</p>
                      </Col>
                      <Col md="2">
                        <Button color="light" size="sm">Download</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Placeholders</h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="pane">
            <PlaceHolder  />
          </div>
        </Col>
      </Row>

      {/*

        ASSOCIATIONS

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Associations</h2>
        </Col>
      </Row>
      <Row className="mt-3 mb-5">
        <Col>
          <ListGroup className="horizontal with-category">
            <ListGroupItem tag="a" href="#">
              <div className="category product">
                <div className="category-text">
                  <h6>Product</h6>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center">
                <h3 className="headline">AB All China Equity Advisor</h3>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category product">
                <div className="category-text">
                  <h6>Product</h6>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center">
                <h3 className="headline">AB All China Equity Advisor</h3>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category td-family">
                <div className="category-text">
                  <h6>TD Family</h6>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center">
                <h3 className="headline">AB All China Equity Advisor</h3>
              </div>
            </ListGroupItem>
            <ListGroupItem tag="a" href="#">
              <div className="category manager">
                <div className="category-text">
                  <h6>Manager</h6>
                </div>
              </div>
              <div className="w-100 d-flex align-items-center">
                <h3 className="headline">AB All China Equity Advisor</h3>
              </div>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
      {/*

        MESSAGES

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Messages</h2>
        </Col>
      </Row>
      <Row className="mt-3 mb-5">
        <Col>
          <h5>Dismissable Alerts</h5>
          <UncontrolledAlert color="primary">
            <h4>Primary Alert / Change Message</h4>
            <p>Message details are added if needed. Message can <a href="#">include links</a>, and an optional close icon.</p>
          </UncontrolledAlert>
          <UncontrolledAlert color="secondary">
            <h4>Default/Secondary Alert / Change Message</h4>
            <p>Message details are added if needed. Message can <a href="#">include links</a>, and an optional close icon.</p>
          </UncontrolledAlert>
          <UncontrolledAlert color="danger">
            <h4>Danger Alert / Change Message</h4>
            <p>Message details are added if needed. Message can <a href="#">include links</a>, and an optional close icon.</p>
          </UncontrolledAlert>
          <UncontrolledAlert color="warning">
            <div className="d-flex">
              <FontAwesomeIcon icon="exclamation-triangle" size="3x" className="large mr-3" />
              <div className="w-100">
                <h4>Caution Alert with Icon / Change Message</h4>
                <p>Message details are added if needed. Message can <a href="#">include links</a>, and an optional close icon.</p>
              </div>
            </div>
          </UncontrolledAlert>
          <h5 className="mt-5">Other Color Options</h5>
          <Alert color="success">
            <p>Success alert</p>
          </Alert>
          <Alert color="info">
            <p>Info alert</p>
          </Alert>

          <Alert color="error">
            <p>Error alert <a href="#">edit</a></p>
          </Alert>
        </Col>
      </Row>
      {/*

        MODALS

      */}
      <Row className="mt-5">
        <Col>
          <hr />
          <h2 className="text-uppercase text-gray-50">Modals</h2>
        </Col>
      </Row>
      <Row className="mt-3 mb-5">
        <Col>
          <Button onClick={toggleModal}>Launch Modal</Button>
          <Modal isOpen={modal} toggle={toggleModal} zIndex={1500}>
            <ModalHeader toggle={toggle}>Modal title</ModalHeader>
            <ModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal} className="mr-2">Secondary</Button>
              <Button color="primary" onClick={toggleModal}>Primary</Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default WithTopNav(StyleGuide)