import React from "react"
import Auth from "../../../Auth/Auth"
import { Col } from "reactstrap"
import {
  ComponentType,
  ConsultantDashboardComponentFragment,
  LayoutSectionType,
} from "../../../__generated__/graphql"
import { ErrorComponent } from "../../Report/Shared/ReportComponent"
import ConsultantDashboardComponent from "./ConsultantDashboardComponent"
import { first } from "lodash"

type sectionProps = {
  components: ConsultantDashboardComponentFragment[]
  auth: Auth
  draftView: boolean
}

type aggregatedSectionProps = {
  type: LayoutSectionType
  components: ConsultantDashboardComponentFragment[]
  auth: Auth
  draftView: boolean
}

const componentNameMapping = (type:ComponentType|undefined) => {
  let name = ""
  switch (type) {
    case "NotableInteractions":
      name = "Interactions"
      break
    case "RecentlyViewed":
      name = "Recently Viewed"
      break
    case "ClientList":
      name = "Client List"
      break
    case "PlanList":
      name = "Manage Plans"
      break
    default:
      name = "RecentlyViewed"
      break
  }
  return name
}


const SingleColumnSection: React.FC<sectionProps> = ({components, auth, draftView}) => {
  const firstComponent = first(components)
  if(firstComponent){
    return (
      <ConsultantDashboardComponent
        component={firstComponent}
        auth={auth}
        draftView={draftView}
      />
    )
  }
  return <></>
}

const ThreeColumnSection: React.FC<sectionProps> = ({
  components,
  auth,
  draftView,
}) => {
    return (
      <>
      {components.map((component, idx)=>(
        <Col md={4} key={idx}>
          <div className="pane dashboard-pane">
            <h3 className="headline underline green-underline">
            {componentNameMapping(component.type || undefined)}
            </h3>
            <ConsultantDashboardComponent
            component={component}
            auth={auth}
            draftView={draftView}
            />
           </div>
        </Col>
        ))}
      </>
    )
}

const SectionMapping: { [key in LayoutSectionType]?: React.FC<sectionProps> } =
  {
    [LayoutSectionType.SingleColumnSection]: SingleColumnSection,
    // [LayoutSectionType.TwoColumnSection]: TwoColumnSection,
    [LayoutSectionType.ThreeColumnSection]: ThreeColumnSection,
  }

const ConsultantDashboardMain: React.FC<aggregatedSectionProps> = ({
  type,
  components,
  auth,
  draftView,
}) => {
  const section = SectionMapping[type]
  if (section) {
    return React.createElement(section, { components, auth, draftView })
  }

  return <ErrorComponent name={type || ""} error="Section not Implemented" />
}

export default ConsultantDashboardMain
