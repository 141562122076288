import React, { useEffect, useState } from "react"

interface MiddleEllipsisProps {
  text?: string
}

const MiddleEllipsis:React.FC<MiddleEllipsisProps> = ({text}) => {
  let [cutPoint, setCutPoint] = useState(Math.ceil((text || '').length * 0.5))

  const calculateCutPoint = () => {
    if(!text || text.length === 0){
      return
    }
    let testCutPoint = cutPoint
    let testFirstHalf = text.substring(0, testCutPoint)
    let testSecondHalf = text.substring(testCutPoint)
    while(getTextWidth(testFirstHalf) < getTextWidth(testSecondHalf)){
      testCutPoint += 1
      testFirstHalf = text.substring(0, testCutPoint)
      testSecondHalf = text.substring(testCutPoint)
    }
    setCutPoint(testCutPoint)
  }

  useEffect(() => {
    calculateCutPoint()
  }, [text])

  if(!text || text.length === 0){
    return <></>
  }


  let firstHalf = text.substring(0, cutPoint)
  let secondHalf = text.substring(cutPoint)

  if(secondHalf[0] === " "){
    firstHalf = firstHalf.concat(" ")
  }
  return (
    <span
      className="middle-ellipsis"
      data-content-start={firstHalf}
      data-content-end={secondHalf}
    />
  )
}


function getTextWidth(text:string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if(!context) return 0

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
}

export default MiddleEllipsis