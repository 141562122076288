import { Maybe } from '../../../__generated__/graphql'
import React, { useState, useEffect } from 'react'
import { Input, InputProps, InputGroup, InputGroupAddon } from 'reactstrap'
import { checkIfSet, formatCurrency, formatPercent } from './helper'
import classNames from 'classnames'

type InputPropsOverride = Omit<InputProps, 'value'> & {
  value: Maybe<number> | undefined
}
export interface CurrencyInputProps extends InputPropsOverride {
  value: Maybe<number> | undefined
  disabled: boolean
  invalid?: boolean
  onChange?: (value: string) => void
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  disabled,
  invalid,
  onChange,
  ...inputProps
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return
    const { value } = e.target
    const removedFormatting = value.replace(/[$|,]/g, '')
    onChange(removedFormatting)
  }

  const formattedValue = value ? formatCurrency(value) : ''

  return (
    <Input
      bsSize="sm"
      value={formattedValue}
      disabled={disabled}
      invalid={invalid}
      onChange={handleChange}
      {...inputProps}
    />
  )
}

export interface PercentInputProps extends InputPropsOverride {
  value: Maybe<number> | undefined
  disabled: boolean
  invalid?: boolean
  onChange: (value: string) => void
}

export const PercentInput: React.FC<PercentInputProps> = ({
  value,
  disabled,
  invalid,
  onChange,
  ...inputProps
}) => {
  const [formattedValue, setFormattedValue] = useState('')

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target
    const removedFormatting = parseFloat(inputValue) / 100
    onChange(isNaN(removedFormatting) ? '' : removedFormatting.toString())
    setFormattedValue(
      checkIfSet(value)
        ? (Math.round((value as number) * 100000) / 1000).toString()
        : ''
    )
  }

  useEffect(() => {
    setFormattedValue(
      checkIfSet(value)
        ? (Math.round((value as number) * 100000) / 1000).toString()
        : ''
    )
  }, [value])

  return disabled ? (
    <Input
      bsSize="sm"
      value={formatPercent(checkIfSet(value) ? (value as number) : '')}
      disabled={disabled}
      invalid={invalid}
      {...inputProps}
    />
  ) : (
    <InputGroup className={classNames(invalid ? 'is-invalid' : '')}>
      <Input
        style={{ marginRight: 0 }}
        value={formattedValue}
        disabled={disabled}
        invalid={invalid}
        onChange={(e) => setFormattedValue(e.target.value)}
        onBlur={handleBlur}
        {...inputProps}
      />
      <InputGroupAddon addonType="append">%</InputGroupAddon>
    </InputGroup>
  )
}
