import moment from "moment"
import React, { useEffect, useState } from "react"
import { FormGroup, Input } from "reactstrap"
import { DATE_API_FORMAT } from "../../../../../helpers/constant"
import { FormInput } from '../../../../ui/Forms/FormInput'
import { PercentInput } from "../../FeeFormComponents"
import {
  OtherFeesGenericProps,
  NumericInputData,
  OtherInputData,
  FurtherDetailsInputData,
} from "../FeeRows"

interface AltFeeRowProps extends OtherFeesGenericProps {
  date: string
  feeInput: NumericInputData<string>
  detailsInput: FurtherDetailsInputData<string>
  idx?: number|string
}

export const AltFeeRow: React.FC<AltFeeRowProps> = ({
  label,
  editing,
  date,
  feeInput,
  detailsInput,
  idx,
}) => {
  return (
    <React.Fragment key={idx}>
      <tr key={0}>
        <td>{label}</td>
        <td></td>
        <td>
          <FormGroup>
            <div>
              <PercentInput
                disabled={!editing}
                value={feeInput.value ? +feeInput.value : null}
                onChange={feeInput.onChange}
              />
            </div>
          </FormGroup>
        </td>
        <td>{moment(date, DATE_API_FORMAT).format("MM/DD/YYYY")}</td>
      </tr>
      <tr key={1}>
        <td colSpan={4}>
          <FormInput
            key={`alt-fee-description`}
            property={`altfee.furtherDescription`}
            displayName={""}
            type={"textarea"}
            placeholder={`Further Details ${label}`}
            idx={`${idx}-alt-fee-description`}
            editMode={editing}
            propertyVal={detailsInput.value}
            updateValue={detailsInput.onChange}
            subClasses={{inputWrapperClasses: "col-sm-12 alt-fee-description"}}
            showZero={true}
            charactersLimit={200}
          />
        </td>
      </tr>
    </React.Fragment>
  )
}
