import { ConfigType } from '../../../helpers/constant'

const OpenEndedEquityProductManagementConfig: ConfigType = {
  managementType: true,
  portfolioConstructionMethod: true,
  investmentManagementStrategy: true,
  currencyHedging: true,
  valueAdded: true,
  performanceCompositeDispersion: true
}

const OpenEndedEquityComplianceConfig: {
  [property: string]: ConfigType
} = {
  compliance: {
    currentTeamStartDate: true,
    trackRecordComments: true,
    previousFirmTrackRecord: true,
    GIPSMethodology: true,
    GIPSAuditor: true,
    Auditor: true,
    LastAuditDate: true,
    verifiedPerformanceBegan: true,
    performanceExamConcluded: true
  }
}

export const OpenEndedEquityProductManagementOverrideConfig = {
  ...OpenEndedEquityProductManagementConfig,
  ...OpenEndedEquityComplianceConfig
}
