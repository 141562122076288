import React from "react"
import { Alert } from "reactstrap"

const PagePermissionCheckFail:React.FC<any> = (props) => {
  return (
    <Alert color="error">
      <p>Sorry, you do not have permission to view this page</p>
    </Alert>
  )
}

export default PagePermissionCheckFail