import { ApolloError } from "@apollo/client"
import classnames from 'classnames'
import { History } from 'history'
import { concat, flatMap, includes, isEmpty, sortBy, uniq, without } from 'lodash'
import { ChangeEvent, Component } from "react"
import {
  Col,
  FormGroup, Input, Label
} from "reactstrap"

import Auth from "../../Auth/Auth"
import { Maybe, SearchQuery, SearchResultAssetClassCounts, SearchResultLocationCounts, SearchResultManagerCounts, SearchResultVehicleCounts } from '../../__generated__/graphql'
import { SidebarCollapse } from "../ui/SidebarCollapse"
import { SearchTypeDisplays } from './SearchEnums'


interface SearchSidebarProps {
  data?: SearchQuery
  error?: ApolloError
  loading: boolean,
  types: SearchTypeDisplays[] | null,
  filters: SearchFilterState | null,
  updateFilters: (filters:SearchSidebarState) => null,
  history: History,
  currentUserFirms: number[]
  auth: Auth
}

export enum SearchFilterOptions {
  managers = 'managers',
  active = 'active'
}


export interface SearchFilterState {
  managers: string[]
  assetClasses: string[]
  vehicles: string[]
  locations: string[]
  active: boolean[]
}

export interface FullSearchState {
  types: SearchTypeDisplays[] | null
  filters: SearchFilterState
}

interface SearchSidebarState extends FullSearchState {
  allFilters: AllFiltersType
}

export



interface AllFiltersType {
  managers: Maybe<SearchResultManagerCounts>[]
  assetClasses: Maybe<SearchResultAssetClassCounts>[]
  vehicles: Maybe<SearchResultVehicleCounts>[]
  locations: Maybe<SearchResultLocationCounts>[]
}

const resetDisplayFilters:AllFiltersType = {
  managers: [],
  assetClasses: [],
  vehicles: [],
  locations: [],
}

const resetFilters = {
  managers: [],
  assetClasses: [],
  vehicles: [],
  locations: [],
  active: [true]
}

export class SearchSidebar extends Component<SearchSidebarProps, SearchSidebarState> {

  constructor (props:SearchSidebarProps) {
    super(props)

    let allFilters:AllFiltersType = this.generateAllFilters(props.data)

    this.state = {
      types: this.props.types,
      filters: resetFilters,
      allFilters: allFilters
    }

  }

  componentWillReceiveProps = (nextProps:SearchSidebarProps) => {
    let newState:{[key:string]: any} = {}
    newState.allFilters = this.generateAllFilters(nextProps.data)

    if (this.state.types !== nextProps.types) {
      newState.types = nextProps.types
    } else if (this.state.filters !== nextProps.filters && nextProps.filters) {
      newState.filters = nextProps.filters
    }

    if (!isEmpty(newState)) {
      this.setState({ ...this.state, ...newState})
    }
  }

  generateAllFilters = (data:SearchQuery | undefined) => {
    const searchData = data?.search
    const filtersAvailable = data?.filtersAvailable

    let allFilters:AllFiltersType =  resetDisplayFilters

    if (searchData && searchData.data) {
      const managerCounts = filtersAvailable?.metaData?.managerCounts || []
      const assetClassCounts = filtersAvailable?.metaData?.assetClassCounts || []
      const vehicleCounts = filtersAvailable?.metaData?.vehicleCounts || []
      const locationCounts = filtersAvailable?.metaData?.locationCounts || []

      allFilters = {
        managers: managerCounts || [],
        vehicles: vehicleCounts || [],
        assetClasses: assetClassCounts || [],
        locations: locationCounts || []
      }
    }


    return allFilters
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newState:SearchSidebarState = this.state
    const { target } = e

    switch(target.name) {
      case 'filter-type':
        if (this.state.types) {
          let newTypes:string[] = target.checked ?
            [...this.state.types, target.value] :
            without(this.state.types, target.value)

          newTypes = without(newTypes, "")

          newState.types = newTypes.length > 0 ? uniq(newTypes) as SearchTypeDisplays[] : null

        } else if (target.checked) {
          newState.types = [target.value] as SearchTypeDisplays[]
        }

        if (!newState.types?.includes(SearchTypeDisplays.Vehicles)) {
          newState.filters.vehicles = []
        }

        if (!newState.types?.includes(SearchTypeDisplays.Vehicles) && !newState.types?.includes(SearchTypeDisplays.Products)) {
          newState.filters.assetClasses = []
        }

        if (newState.types?.includes(SearchTypeDisplays.Manager)) {
          newState.filters.managers = []
        }
        break;
      case 'filter-manager':
        let newManagers:string[] = target.checked ?
          concat(this.state.filters.managers, target.value) :
          without(this.state.filters.managers, target.value)

        newState.filters.managers = uniq(newManagers)
        break;

      case 'filter-asset-class':
        let newAssetClasses:string[] = target.checked ?
        concat(this.state.filters.assetClasses, target.value) :
        without(this.state.filters.assetClasses, target.value)

        newState.filters.assetClasses = uniq(newAssetClasses)

        // also ensure type is product
        // if (target.checked) {
        //   newState.types = uniq(concat(newState.types, SearchTypeDisplays.Products)) as SearchTypeDisplays[]
        // }

        break;

      case 'filter-vehicle':
        let newVehicles:string[] = target.checked ?
        concat(this.state.filters.vehicles, target.value) :
        without(this.state.filters.vehicles, target.value)

        newState.filters.vehicles = uniq(newVehicles)

        // also ensure type is vehicle
        if (target.checked) {
          newState.types = uniq(concat(newState.types, SearchTypeDisplays.Vehicles)) as SearchTypeDisplays[]
        }
        break;

      case 'filter-location':
        let newLocation:string[] = target.checked ?
        concat(this.state.filters.locations, target.value) :
        without(this.state.filters.locations, target.value)

        newState.filters.locations = uniq(newLocation)
        break;

      case 'filter-active':
        const setValue = target.value === "true" ? true : false
        let newActive:boolean[] = target.checked ?
          concat(this.state.filters.active, setValue) :
          without(this.state.filters.active, setValue)

        newState.filters.active = uniq(newActive)
      }

    this.props.updateFilters(newState)
    this.setState(newState)
    return null
  }

  currentFilters = () => {

  }


  renderCategoryFilters = (openState:boolean) => {
    const typesChecked = this.state.types
    const resultCounts = this.props.data?.filtersAvailable?.metaData.resultCounts

    let typesReturned:{ type: SearchTypeDisplays, count: number}[] = []
    if (this.props.auth.checkPermissions(["view:all_clients"]))
      typesReturned.push({ type: SearchTypeDisplays.Client, count: resultCounts?.clients || 0})

    if (this.props.auth.checkPermissions(["view:all_managers"]))
      typesReturned.push({ type: SearchTypeDisplays.Manager, count: resultCounts?.managers || 0})

    if (this.props.auth.checkPermissions(["view:all_clients"])) {
      typesReturned.push({ type: SearchTypeDisplays.Custodian, count: resultCounts?.custodians || 0})
      typesReturned.push({ type: SearchTypeDisplays["Record Keeper"], count: resultCounts?.record_keepers || 0})
    }

    typesReturned.push({ type: SearchTypeDisplays.People, count: resultCounts?.people || 0 })
    typesReturned.push({ type: SearchTypeDisplays.Products, count: resultCounts?.products || 0 })
    typesReturned.push({ type: SearchTypeDisplays.Vehicles, count: resultCounts?.vehicles || 0 })
    typesReturned.push({ type: SearchTypeDisplays["Target Date"], count: resultCounts?.target_dates || 0 })

    if(this.props.auth.checkPermissions(["view:all_clients"])){
      typesReturned.push({ type: SearchTypeDisplays.Group, count: resultCounts?.groups || 0 })
      typesReturned.push({ type: SearchTypeDisplays.Index, count: resultCounts?.indices || 0})
    }

    if (this.props.auth.checkPermissions(["search:documents"]))
      typesReturned.push({ type: SearchTypeDisplays.Documents, count: resultCounts?.documents || 0})

    return (
      <SidebarCollapse title="Category" open={openState} className='auto-height'>
        {typesReturned.map((item) => {
          const { type, count } = item

          return (
            <FormGroup check key={`filter-type-${type}`}>
              <Label check className={classnames("category", (type as string).toLowerCase())}>
                <Input
                  type="checkbox"
                  name="filter-type"
                  value={type}
                  checked={includes(typesChecked,type)}
                  onChange={this.handleChange} />{' '}

                { type }
                <span className="description ml-1">({count})</span>

              </Label>
            </FormGroup>
          )
        })}
      </SidebarCollapse>
    )
  }

  renderManagerNames = (openState:boolean) => {
    const searchData = this.props.data?.search || null
    if ( !!searchData && searchData.data) {
      const managerCounts = this.state.allFilters.managers

      if (!managerCounts || managerCounts.length === 0) return <></>

      return (
        <SidebarCollapse title="Manager Name" open={openState}>
          {managerCounts.map((managerCount,num) => {
            if (!managerCount) return (<></>)

            return (
              <FormGroup check key={`filter-manager-${num}`}>
                <Label check>
                  <Input
                    type="checkbox"
                    name="filter-manager"
                    value={managerCount.manager}
                    checked={includes(this.state.filters.managers, managerCount.manager)}
                    onChange={this.handleChange}/>{' '}
                  { managerCount.manager }
                  <span className="description ml-1">({managerCount.count})</span>
                </Label>
              </FormGroup>
            )
          })}
        </SidebarCollapse>
      )


    }

    return <></>
  }

  renderVehicleNames = (openState:boolean) => {
    const searchData = this.props.data?.search || null
    if ( !!searchData && searchData.data) {
      const vehicleCounts = this.state.allFilters.vehicles

      if (!vehicleCounts || vehicleCounts.length === 0) return <></>

      if (vehicleCounts.length === 0 ) return <></>

      return (
        <SidebarCollapse title="Vehicle Name" open={openState}>
          {vehicleCounts.map((vehicleCount, num) => {
            if (!vehicleCount) return (<></>)

            return (
              <FormGroup check key={`filter-vehicle-${vehicleCount?.vehicle}`}>
                <Label check>
                  <Input
                    type="checkbox"
                    name="filter-vehicle"
                    value={vehicleCount.vehicle || ""}
                    checked={includes(this.state.filters.vehicles, vehicleCount.vehicle)}
                    onChange={this.handleChange}/>{' '}
                  { vehicleCount.vehicle }
                  <span className="description ml-1">({vehicleCount.count})</span>
                </Label>
              </FormGroup>
            )
          })}
        </SidebarCollapse>
      )


    }

    return <></>
  }

  renderAssetClass = (openState:boolean) => {
    const searchData = this.props.data?.search || null
    if ( !!searchData && searchData.data) {
      const assetClasses = this.state.allFilters.assetClasses

      if (!assetClasses || assetClasses.length === 0) return <></>
      return (
        <SidebarCollapse title="Asset Class" open={openState}>
          {assetClasses.map((assetClassCount,num) => {
            if (!assetClassCount) return (<></>)

            const name = assetClassCount.assetClass || ''
            return (
              <FormGroup check key={`filter-asset-class-${name}`}>
                <Label check>
                  <Input
                    type="checkbox"
                    name="filter-asset-class"
                    value={name}
                    checked={includes(this.state.filters.assetClasses, name)}
                    onChange={this.handleChange}/>{' '}
                  { name }
                  <span className="description ml-1">({assetClassCount.count})</span>
                </Label>
              </FormGroup>
            )
          })}
        </SidebarCollapse>
      )


    }

    return <></>
  }

  renderLocation = (openState:boolean) => {
    const searchData = this.props.data?.search || null
    if ( !!searchData && searchData.data) {

      const locations = this.state.allFilters.locations
      const flatCities = flatMap(locations, (location) => {return location?.cities.map((city) => { return {state: location.location, city: city?.city, count: city?.count}})})
      const sortedCities = sortBy(flatCities, ["count"]).reverse()

      if (!locations || locations.length === 0) return <></>
      return (
        <SidebarCollapse title="Location" open={openState}>
          {sortedCities.splice(0,10).map((locationCount,num) => {
            if (!locationCount) return (<></>)

            const name = `${locationCount.state}|${locationCount.city}`
            return (
              <FormGroup check key={`filter-location-${name}`}>
                <Label check>
                  <Input
                    type="checkbox"
                    name="filter-location"
                    value={name}
                    checked={includes(this.state.filters.locations, name)}
                    onChange={this.handleChange}/>{' '}
                  {locationCount.city}, {locationCount.state}
                  <span className="description ml-1">({locationCount.count})</span>
                </Label>
              </FormGroup>
            )
          })}
        </SidebarCollapse>
      )
    }

    return <></>
  }

  renderStatus = (openState:boolean) => {
    const searchData = this.props.data?.search || null
    if ( !!searchData && searchData.data) {

      return (
        <SidebarCollapse title="Active/Inactive" open={openState}>

          <FormGroup check key={`filter-status-active`}>
            <Label check>
              <Input
                type="checkbox"
                name="filter-active"
                value="true"
                checked={this.state.filters.active.includes(true)}
                onChange={this.handleChange}/>{' '}
              Active
              <span className="description ml-1"></span>
            </Label>
          </FormGroup>
          <FormGroup check key={`filter-status-inactive`}>
            <Label check>
              <Input
                type="checkbox"
                name="filter-active"
                value="false"
                checked={this.state.filters.active.includes(false)}
                onChange={this.handleChange}/>{' '}
              Inactive
              <span className="description ml-1"></span>
            </Label>
          </FormGroup>
        </SidebarCollapse>
      )


    }

    return <></>  }

    renderAllFilters = (open:boolean) => (
      <>

        { this.renderCategoryFilters(open)}

        { this.props.currentUserFirms && this.props.auth.checkPermissions(["view:all_managers"]) && this.renderManagerNames(open) }

        { this.renderVehicleNames(open) }

        { this.renderAssetClass(open) }

        { this.renderLocation(open) }

        { this.renderStatus(open) }

      </>
    )
  render () {
    const searchData = this.props.data?.search || null
    if ( !!searchData && searchData.data) {

      return (
        <>
        <Col className="d-block d-md-none">
          <SidebarCollapse title="Filter By" className="mobile-collapse">
            { this.renderAllFilters(false)}
          </SidebarCollapse>
        </Col>
        <Col md="2" className="d-none d-md-block">
          <h5 className="mini mb-4">Filter By</h5>
            { this.renderAllFilters(true)}
        </Col>
        </>
      )
    }

    return <Col md="2"></Col>
  }
}