// Download a file with filename
export const downloadWithFilename = (url: string, filename?: string) => {
  fetch(url)
    .then((res) => {
      return res.blob()
    })
    .then((blob) => {
      const href = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = href
      if(filename) link.download = filename
      link.click()
    })
    .catch(error => {
      console.error(error.message)
    })
}