
import classnames from 'classnames'
import { get, set, cloneDeep, unset, has, remove as _remove, find } from 'lodash'
import React, { Component, useState, useContext, RefObject, useRef } from 'react'
import { Table, Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import iassign from 'immutable-assign'
import moment from 'moment'
import omitDeep from 'omit-deep-lodash'
import { useHistory } from 'react-router-dom'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import { useManagerAssetsByVehicleQuery, ManagerAssetsInputFields, useUpdateManagerAssetsByVehicleMutation, ManagerAssetsByVehicleQuery, ManagerAssetsByVehicle, VehicleTypeCodeEv, AssetClassType, OriginCode, PrivateEquityAssetsByStrategyType, PrivateEquityAssetsByCapitalType, RealAssetsAssetsByStrategyType, RealAssetsAssetsByCapitalType, ManagerAssetsByVehicleInput, ManagerPrivateEquityAssetsByStrategyTypeInput, ManagerPrivateEquityAssetsByCapitalTypeInput, ManagerRealAssetsAssetsByStrategyTypeInput, ManagerRealAssetsAssetsByCapitalTypeInput, OtherAssetExplanation, ManagerOtherAssetExplanationInput } from '../../__generated__/graphql'
import { ManagerType, listQuarters, numbroFormatter } from "../../helpers/helpers"
import { CalendarContext, appDate } from '../../Context/CalendarContext'
import { DATE_API_FORMAT, FormInputField } from '../../helpers/constant'
import { CalendarPicker } from '../CalendarPicker'
import PlaceHolder from './../ui/PlaceHolder'
import { FormInput } from '../ui/Forms/FormInput'

import { HistoricalPrivateEquityAssetsByStrategyTypeTable, PrivateEquityAssetsByStrategyTypeTable} from './ManagerAssetsVehicles/PrivateEquityAssetsByStrategyType'
import { HistoricalPrivateEquityAssetsByCapitalTypeTable, PrivateEquityAssetsByCapitalTypeTable} from './ManagerAssetsVehicles/PrivateEquityAssetsByCapitalType'
import {HistoricalRealAssetsAssetsByStrategyTypeTable, RealAssetsAssetsByStrategyTypeTable} from './ManagerAssetsVehicles/RealAssetsByStrategyType'
import {HistoricalRealAssetsAssetsByCapitalTypeTable, RealAssetsAssetsByCapitalTypeTable} from './ManagerAssetsVehicles/RealAssetsByCapitalType'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import ErrorDisplay from '../Shared/ErrorDisplay'
import exportTables from '../../helpers/exportTable'
import _ from 'lodash'

interface ManagerAssetsVehiclesProps {
  id: number
  managerType?: ManagerType
  auth: Auth
}

export interface GroupedAssets {
  [key: string]: GroupedAsset
}

export interface GroupedAsset {
  [key: string]: TableTypes
}

export type TableInputTypes = ManagerAssetsByVehicleInput | ManagerPrivateEquityAssetsByStrategyTypeInput | ManagerPrivateEquityAssetsByCapitalTypeInput | ManagerRealAssetsAssetsByStrategyTypeInput | ManagerRealAssetsAssetsByCapitalTypeInput | ManagerOtherAssetExplanationInput
export type TableTypes = ManagerAssetsByVehicle | PrivateEquityAssetsByStrategyType | PrivateEquityAssetsByCapitalType | RealAssetsAssetsByStrategyType | RealAssetsAssetsByCapitalType
type Tables = "ManagerAssetsByVehicle" | "PrivateEquityAssetsByStrategyType" | "PrivateEquityAssetsByCapitalType" | "RealAssetsAssetsByStrategyType" | "RealAssetsAssetsByCapitalType" | "ManagerOtherAssetExplanation"

export type HistoricTables = HistoricalAssetsByAssetClassTable |HistoricalPrivateEquityAssetsByStrategyTypeTable | HistoricalPrivateEquityAssetsByCapitalTypeTable | HistoricalRealAssetsAssetsByStrategyTypeTable | HistoricalRealAssetsAssetsByCapitalTypeTable
export type HistoricalDisplayValueType = "assetsUnderManagement" | "numberOfAccounts"

const validManagerAssetsByVehicleInput = (v:any): v is ManagerAssetsByVehicleInput => {
  return (v as ManagerAssetsByVehicleInput).vehicleType !== undefined && (v as ManagerAssetsByVehicleInput).assetClassType !== undefined
}

const validPrivateEquityAssetsByStrategyTypeInput = (v:any): v is ManagerPrivateEquityAssetsByStrategyTypeInput => {
  return has(v,'strategy') && !has(v,'capitalType') && !has(v,'vehicleType')
}

const validPrivateEquityAssetsByCapitalTypeInput = (v:any): v is ManagerPrivateEquityAssetsByCapitalTypeInput => {
  return has(v,'strategy') && has(v,'capitalType') && !has(v,'vehicleType')
}

const validRealAssetsByStrategyTypeInput = (v:any): v is ManagerRealAssetsAssetsByStrategyTypeInput => {
  return has(v,'strategy') && !has(v,'capitalType') && has(v,'vehicleType')
}

const validRealAssetsByCapitalTypeInput = (v:any): v is ManagerRealAssetsAssetsByCapitalTypeInput => {
  return has(v,'strategy') && has(v,'capitalType') && !has(v,'vehicleType')
}

const validManagerOtherAssetExplanationInput = (v:any): v is ManagerOtherAssetExplanationInput => {
  return has(v,'vehicle')
}

export interface AssetsByAssetClassTableProps {
  setSearchDate: (searchDate:string) => void
  data: ManagerAssetsByVehicleQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  managerType?: ManagerType
  onChange: (updatedCell: TableInputTypes, table:Tables) => void
}

interface AssetsByAssetClassTableState {
  currentState: GroupedAssets
  initialState: GroupedAssets
}



const firstHistoricalDate = moment(appDate).subtract(5,"years")

const convertVehicleCellToInput = (updatedCell:ManagerAssetsByVehicle):ManagerAssetsByVehicleInput => {
  let newInputCell = omitDeep(updatedCell, '__typename')
  set(newInputCell, 'vehicleType', newInputCell.vehicleType.code)
  set(newInputCell, 'assetClassType', newInputCell.assetClass.code)
  unset(newInputCell, 'origin')
  unset(newInputCell, 'assetClass')
  return newInputCell
}

const defaultStateDiff = {
  assetsByVehicle: [],
  privateEquityAssetsByCapitalType: [],
  privateEquityAssetsByStrategyType: [],
  realAssetsAssetsByStrategyType: [],
  realAssetsAssetsByCapitalType: [],
  otherAssetExplanation: []
} as ManagerAssetsInputFields

const ManagerAssetsVehicles: React.FC<ManagerAssetsVehiclesProps> = ({ id, managerType, auth }: ManagerAssetsVehiclesProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [screen, setScreen] = useState(context.period === "historical" ? "historical" :"single")
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [updateManagerAssetsByVehicle] = useUpdateManagerAssetsByVehicleMutation()
  const [historicLoading, setHistoricLoading] = useState(false)
  const [historicLoadDate, setHistoricLoadDate] = useState(firstHistoricalDate)
  const history = useHistory()

  const otherAssetsExplanationComponentRef = useRef<OtherAssetsExplanationComponent>(null)
  const historicalAssetsByAssetClassTableRef = useRef<HistoricalAssetsByAssetClassTable>(null)
  const historicalPrivateEquityAssetsByStrategyTypeTableRef = useRef<HistoricalPrivateEquityAssetsByStrategyTypeTable>(null)
  const historicalPrivateEquityAssetsByCapitalTypeTableRef = useRef<HistoricalPrivateEquityAssetsByCapitalTypeTable>(null)
  const historicalRealAssetsAssetsByStrategyTypeTableRef = useRef<HistoricalRealAssetsAssetsByStrategyTypeTable>(null)
  const historicalRealAssetsAssetsByCapitalTypeTableRef = useRef<HistoricalRealAssetsAssetsByCapitalTypeTable>(null)

  let { data, loading, error, fetchMore } =
  useManagerAssetsByVehicleQuery({
    variables: {
      id,
      startDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT),
      endDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT)
    },
    // partialRefetch: true,
    // fetchPolicy: ""
  })
  const handleEdit = () => {
    otherAssetsExplanationComponentRef!.current?.resetForm()
    historicalAssetsByAssetClassTableRef!.current?.resetForm()
    historicalPrivateEquityAssetsByStrategyTypeTableRef.current?.resetForm()
    historicalPrivateEquityAssetsByCapitalTypeTableRef.current?.resetForm()
    historicalRealAssetsAssetsByStrategyTypeTableRef.current?.resetForm()
    historicalRealAssetsAssetsByCapitalTypeTableRef.current?.resetForm()
    setStateDiff(defaultStateDiff)
    setEditMode(!editMode)
  }

  const [stateDiff, setStateDiff]:[ManagerAssetsInputFields, React.Dispatch<React.SetStateAction<ManagerAssetsInputFields>>] = useState(defaultStateDiff)

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let submissionData = {
      id: id,
      patch: stateDiff
    }
    updateManagerAssetsByVehicle({ variables: {
      input: submissionData,
      startDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT),
      endDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT)
    }})
      .then(result => {
        setSaving(false)
        if (result && result.data) {
          setEditMode(false)
          resetHistory()
        }
      })
      .catch(err => {
        setSaving(false)
        console.error("Error testManagerSummary", err.message)
      })
  }

  const handleChange = (updatedCell: TableInputTypes, table:Tables) => {
    let newStateDiff = cloneDeep(stateDiff)

    if (table === 'ManagerAssetsByVehicle' && newStateDiff.assetsByVehicle && validManagerAssetsByVehicleInput(updatedCell)) {
      _remove(newStateDiff.assetsByVehicle, (cell:ManagerAssetsByVehicleInput) => {
        return cell.quarterEndDate === updatedCell.quarterEndDate &&
          cell.vehicleType === updatedCell.vehicleType &&
          cell.assetClassType === updatedCell.assetClassType
      })

      newStateDiff.assetsByVehicle?.push(updatedCell)
    }

    if (table === 'PrivateEquityAssetsByStrategyType' && validPrivateEquityAssetsByStrategyTypeInput(updatedCell) && newStateDiff.privateEquityAssetsByStrategyType) {
      _remove(newStateDiff.privateEquityAssetsByStrategyType, (cell:ManagerPrivateEquityAssetsByStrategyTypeInput) => {
        return cell.quarterEndDate === updatedCell.quarterEndDate &&
        cell.strategy === updatedCell.strategy
      })

      newStateDiff.privateEquityAssetsByStrategyType?.push(updatedCell)
    }

    if (table === 'PrivateEquityAssetsByCapitalType' && validPrivateEquityAssetsByCapitalTypeInput(updatedCell) && newStateDiff.privateEquityAssetsByCapitalType) {
      _remove(newStateDiff.privateEquityAssetsByCapitalType, (cell:ManagerPrivateEquityAssetsByCapitalTypeInput) => {
        return cell.quarterEndDate === updatedCell.quarterEndDate &&
        cell.strategy === updatedCell.strategy &&
        cell.capitalType === updatedCell.capitalType
      })

      newStateDiff.privateEquityAssetsByCapitalType?.push(updatedCell)
    }

    if (table === 'RealAssetsAssetsByStrategyType' && validRealAssetsByStrategyTypeInput(updatedCell) && newStateDiff.realAssetsAssetsByStrategyType) {
      _remove(newStateDiff.realAssetsAssetsByStrategyType, (cell:ManagerRealAssetsAssetsByStrategyTypeInput) => {
        return cell.quarterEndDate === updatedCell.quarterEndDate &&
        cell.strategy === updatedCell.strategy &&
        cell.vehicleType === updatedCell.vehicleType

      })

      newStateDiff.realAssetsAssetsByStrategyType?.push(updatedCell)
    }

    if (table === 'RealAssetsAssetsByCapitalType' && validRealAssetsByCapitalTypeInput(updatedCell) && newStateDiff.realAssetsAssetsByCapitalType) {
      _remove(newStateDiff.realAssetsAssetsByCapitalType, (cell:ManagerRealAssetsAssetsByCapitalTypeInput) => {
        return cell.quarterEndDate === updatedCell.quarterEndDate &&
        cell.strategy === updatedCell.strategy &&
        cell.capitalType === updatedCell.capitalType
      })

      newStateDiff.realAssetsAssetsByCapitalType?.push(updatedCell)
    }

    if (table === 'ManagerOtherAssetExplanation' && validManagerOtherAssetExplanationInput(updatedCell) && newStateDiff.otherAssetExplanation) {
      _remove(newStateDiff.otherAssetExplanation, (cell:ManagerOtherAssetExplanationInput) => {
        return cell.quarterEndDate === updatedCell.quarterEndDate
      })

      newStateDiff.otherAssetExplanation?.push(updatedCell)
    }

    setStateDiff(newStateDiff)
  }


  // const historicLoadMore = () => {
  //   historicalAssetsByAssetClassTableRef.current?.loadMore()
  //   historicalPrivateEquityAssetsByStrategyTypeTableRef.current?.loadMore()
  //   historicalPrivateEquityAssetsByCapitalTypeTableRef.current?.loadMore()
  //   historicalRealAssetsAssetsByStrategyTypeTableRef.current?.loadMore()
  //   historicalRealAssetsAssetsByCapitalTypeTableRef.current?.loadMore()
  // }

  const historicLoadMore = () =>{
    if(historicLoading){
      return
    }
    setHistoricLoading(true)
    fetchMore({
      variables: {
        id: id,
        startDate: historicLoadDate.format(DATE_API_FORMAT),
        endDate: moment(historicLoadDate).subtract(5, "years").format(DATE_API_FORMAT)
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if(!fetchMoreResult){
          return previousResult
        }
        historicalAssetsByAssetClassTableRef.current?.loadMore(fetchMoreResult)
        historicalPrivateEquityAssetsByStrategyTypeTableRef.current?.loadMore(fetchMoreResult)
        historicalPrivateEquityAssetsByCapitalTypeTableRef.current?.loadMore(fetchMoreResult)
        historicalRealAssetsAssetsByStrategyTypeTableRef.current?.loadMore(fetchMoreResult)
        historicalRealAssetsAssetsByCapitalTypeTableRef.current?.loadMore(fetchMoreResult)

        setHistoricLoadDate(moment(historicLoadDate).subtract(5, "years"))
        setHistoricLoading(false)
        return previousResult
      }
    })
  }

  const resetHistory = () => {
    setHistoricLoadDate(firstHistoricalDate)
    historicalAssetsByAssetClassTableRef.current?.resetHistory()
    historicalPrivateEquityAssetsByStrategyTypeTableRef.current?.resetHistory()
    historicalPrivateEquityAssetsByCapitalTypeTableRef.current?.resetHistory()
    historicalRealAssetsAssetsByStrategyTypeTableRef.current?.resetHistory()
    historicalRealAssetsAssetsByCapitalTypeTableRef.current?.resetHistory()
  }
  let explanation = _.get(data, 'assets.otherAssetExplanation[0].vehicle', "")
  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={true}
          updateType={(type:string) => setScreen(type)}
          editMode={editMode}
          setEditMode={(value:boolean) => setEditMode(value)}
        />
      <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables({extraRows: [{values:["Other Explanation",explanation]}]})}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
      </div>
        {screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>historicLoadMore()}>
            {historicLoading && "Loading"}
            {!historicLoading && "Load 5 More Years"}
          </Button>
        }
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} cancelEdit={handleEdit}/>
        }
      </div>
    </>
  )

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error) {
    return (
      <Container fluid>
        <ErrorDisplay error={error}/>
      </Container>
    );
  }
  if (data && data?.assets?.__typename === "Manager") {
    if(screen === "single"){
      return (
        <Container fluid>
            <Row>
              <Col>
                {heading}
                { data?.assets?.assetsByVehicle && (managerType?.LGSH) &&
                  (<div className="pane pane-table">
                    <div className="pane-title">
                      <h3 id="assetsByAssetClassTooltipContainer">
                        <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByAssetClassTooltip">
                          Assets by Asset Class
                          <FontAwesomeIcon
                            icon={"question-circle" as IconName}
                            className="ml-2 mt-0"
                            size="sm"
                          />
                        </div>
                      </h3>
                    </div>
                    <AssetsByAssetClassTable
                      key={searchDate}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      setSearchDate={setSearchDate}
                      data={data}
                      searchDate={searchDate}
                      managerType={managerType}
                      onChange={handleChange}
                    />
                  </div>)
                }
                {(managerType?.LGSH) &&
                  <div className="pane pane-table">
                    <div className="pane-title">
                      <h3>Other Explanation</h3>
                    </div>
                    <OtherAssetsExplanationComponent
                        ref={otherAssetsExplanationComponentRef}
                        key={searchDate}
                        editMode={editMode}
                        data={data}
                        searchDate={searchDate}
                        managerType={managerType}
                        onChange={handleChange}
                    />
                  </div>
                }
                { data?.assets?.privateEquityAssetsByStrategyType && (managerType?.PE || managerType?.PEFOF) &&
                  (<div className="pane pane-table">
                    <div className="pane-title">
                      <h3 id="privateEquityAssetsStrategyTooltipContainer">
                      <div className="d-inline-flex align-items-center tooltip-icon" id="privateEquityAssetsStrategyTooltip">
                        Private Equity Assets by Strategy Type
                        <FontAwesomeIcon
                          icon={"question-circle" as IconName}
                          className="ml-2 mt-0"
                          size="sm"
                        />
                      </div>
                    </h3>
                    </div>
                    <PrivateEquityAssetsByStrategyTypeTable
                      key={searchDate}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      setSearchDate={setSearchDate}
                      data={data}
                      searchDate={searchDate}
                      managerType={managerType}
                      onChange={handleChange}
                    />
                  </div>)
                }
                { data?.assets?.privateEquityAssetsByCapitalType && (managerType?.PE || managerType?.PEFOF) &&
                  (<div className="pane pane-table">
                    <div className="pane-title">
                      <h3 id="privateEquityAssetsCapitalTooltipContainer">
                        <div className="d-inline-flex align-items-center tooltip-icon" id="privateEquityAssetsCapitalTooltip">
                          Private Equity Assets by Uninvested & New Capital
                          <FontAwesomeIcon
                            icon={"question-circle" as IconName}
                            className="ml-2 mt-0"
                            size="sm"
                          />
                        </div>
                      </h3>
                    </div>
                    <PrivateEquityAssetsByCapitalTypeTable
                      key={searchDate}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      setSearchDate={setSearchDate}
                      data={data}
                      searchDate={searchDate}
                      managerType={managerType}
                      onChange={handleChange}
                    />
                  </div>)
                }
                { data?.assets?.realAssetsAssetsByStrategyType && managerType?.RA &&
                  (<div className="pane pane-table">
                    <div className="pane-title">
                      <h3 id="realAssetsByStrategyTypeTooltipContainer">
                        <div className="d-inline-flex align-items-center tooltip-icon" id="realAssetsByStrategyTypeTooltip">
                          Real Assets by Strategy Type
                          <FontAwesomeIcon
                            icon={"question-circle" as IconName}
                            className="ml-2 mt-0"
                            size="sm"
                          />
                        </div>
                      </h3>
                    </div>
                    <RealAssetsAssetsByStrategyTypeTable
                      key={searchDate}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      setSearchDate={setSearchDate}
                      data={data}
                      searchDate={searchDate}
                      managerType={managerType}
                      onChange={handleChange}
                    />
                  </div>)
                }
                { data?.assets?.realAssetsAssetsByCapitalType && managerType?.RA &&
                  (<div className="pane pane-table">
                    <div className="pane-title">
                      <h3 id="realAssetsByCapitalTypeTooltipContainer">
                        <div className="d-inline-flex align-items-center tooltip-icon" id="realAssetsByCapitalTypeTooltip">
                          Real Assets by Capital Type
                          <FontAwesomeIcon
                            icon={"question-circle" as IconName}
                            className="ml-2 mt-0"
                            size="sm"
                          />
                        </div>
                      </h3>
                    </div>
                    <RealAssetsAssetsByCapitalTypeTable
                      key={searchDate}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      setSearchDate={setSearchDate}
                      data={data}
                      searchDate={searchDate}
                      managerType={managerType}
                      onChange={handleChange}
                    />
                  </div>)
                }
              </Col>
            </Row>
          </Container>
      )
    } else if(screen === "historical") {
      return (
        <Container fluid>
          <Row>
            <Col>
              {heading}
              { data?.assets?.assetsByVehicle && (managerType?.LGSH) &&
                <div className="pane pane-table">
                  <div className="pane-title">
                    <h3 id="assetsByAssetClassTooltipContainer">
                      <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByAssetClassTooltip">
                        Assets by Asset Class
                        <FontAwesomeIcon
                          icon={"question-circle" as IconName}
                          className="ml-2 mt-0"
                          size="sm"
                        />
                      </div>
                    </h3>
                  </div>
                  <HistoricalAssetsByAssetClassTable
                    managerType={managerType}
                    ref={historicalAssetsByAssetClassTableRef}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    data={data}
                    fetchMore={fetchMore}
                    onChange={handleChange}
                  />
                </div>
              }
              { data?.assets?.privateEquityAssetsByStrategyType && (managerType?.PE || managerType?.PEFOF) &&
                (<div className="pane pane-table">
                  <div className="pane-title">
                    <h3 id="privateEquityAssetsStrategyTooltipContainer">
                      <div className="d-inline-flex align-items-center tooltip-icon" id="privateEquityAssetsStrategyTooltip">
                        Private Equity Assets by Strategy Type
                        <FontAwesomeIcon
                          icon={"question-circle" as IconName}
                          className="ml-2 mt-0"
                          size="sm"
                        />
                      </div>
                    </h3>
                  </div>
                  <HistoricalPrivateEquityAssetsByStrategyTypeTable
                    managerType={managerType}
                    ref={historicalPrivateEquityAssetsByStrategyTypeTableRef}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    data={data}
                    fetchMore={fetchMore}
                    onChange={handleChange}
                  />
                </div>)
              }
              { data?.assets?.privateEquityAssetsByCapitalType && (managerType?.PE || managerType?.PEFOF) &&
                (<div className="pane pane-table">
                  <div className="pane-title">
                    <h3 id="privateEquityAssetsCapitalTooltipContainer">
                      <div className="d-inline-flex align-items-center tooltip-icon" id="privateEquityAssetsCapitalTooltip">
                        Private Equity Assets by Uninvested & New Capital
                        <FontAwesomeIcon
                          icon={"question-circle" as IconName}
                          className="ml-2 mt-0"
                          size="sm"
                        />
                      </div>
                    </h3>
                  </div>
                  <HistoricalPrivateEquityAssetsByCapitalTypeTable
                    managerType={managerType}
                    ref={historicalPrivateEquityAssetsByCapitalTypeTableRef}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    data={data}
                    fetchMore={fetchMore}
                    onChange={handleChange}
                  />
                </div>)
              }
              { data?.assets?.realAssetsAssetsByStrategyType && managerType?.RA &&
                (<div className="pane pane-table">
                  <div className="pane-title">
                    <h3 id="realAssetsByStrategyTypeTooltipContainer">
                      <div className="d-inline-flex align-items-center tooltip-icon" id="realAssetsByStrategyTypeTooltip">
                        Real Assets by Strategy Type
                        <FontAwesomeIcon
                          icon={"question-circle" as IconName}
                          className="ml-2 mt-0"
                          size="sm"
                        />
                      </div>
                    </h3>
                  </div>
                  <HistoricalRealAssetsAssetsByStrategyTypeTable
                    managerType={managerType}
                    ref={historicalRealAssetsAssetsByStrategyTypeTableRef}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    data={data}
                    fetchMore={fetchMore}
                    onChange={handleChange}
                  />
                </div>)
              }
              { data?.assets?.realAssetsAssetsByCapitalType && managerType?.RA &&
                (<div className="pane pane-table">
                  <div className="pane-title">
                    <h3 id="realAssetsByCapitalTypeTooltipContainer">
                      <div className="d-inline-flex align-items-center tooltip-icon" id="realAssetsByCapitalTypeTooltip">
                        Real Assets by Capital Type
                        <FontAwesomeIcon
                          icon={"question-circle" as IconName}
                          className="ml-2 mt-0"
                          size="sm"
                        />
                      </div>
                    </h3>
                  </div>
                  <HistoricalRealAssetsAssetsByCapitalTypeTable
                    managerType={managerType}
                    ref={historicalRealAssetsAssetsByCapitalTypeTableRef}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    data={data}
                    fetchMore={fetchMore}
                    onChange={handleChange}
                  />
                </div>)
              }

            </Col>
          </Row>
        </Container>
      )
    }
  }
  return <div>data doesn't exist</div>

}


class AssetsByAssetClassTable extends Component<AssetsByAssetClassTableProps, AssetsByAssetClassTableState> {
  constructor(props: AssetsByAssetClassTableProps) {
    super(props)

    const assets:ManagerAssetsByVehicle[] = get(props.data, "assets.assetsByVehicle", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:ManagerAssetsByVehicle) => {
      let assetClass:AssetClassType = entry.assetClass.code
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code

      set(groupedData, [assetClass, vehicleType], entry)
    })

    this.state = {
      currentState: groupedData,
      initialState: groupedData
    }
  }

  static getDerivedStateFromProps(props: AssetsByAssetClassTableProps, state:AssetsByAssetClassTableState) {
    const assets:ManagerAssetsByVehicle[] = get(props.data, "assets.assetsByVehicle", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:ManagerAssetsByVehicle) => {
      let assetClass:AssetClassType = entry.assetClass.code
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code

      set(groupedData, [assetClass, vehicleType], entry)
    })
    return {
      currentState: props.editMode ? state.currentState : groupedData,
      initialState: groupedData,
    }
  }

  resetForm = () => {
    this.setState({currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    assetClass: AssetClassType,
    vehicleType: VehicleTypeCodeEv
  ) => {
    let updatedCell:ManagerAssetsByVehicle
    let oldState = cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      selectedRow => {
        let row:GroupedAssets = cloneDeep(selectedRow)
        let cell:ManagerAssetsByVehicle
        let selectedCell = get(selectedRow, [assetClass, vehicleType]) as ManagerAssetsByVehicle
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            vehicleType: {code: vehicleType as VehicleTypeCodeEv, __typename: "OrgVehicleTypeLookup"},
            assetClass: {code: assetClass as AssetClassType, __typename: "AssetClassTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: this.props.searchDate,
            __typename: "ManagerAssetsByVehicle",
            origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'}
          }
        }

        set(cell, property, value)
        set(row, [assetClass, vehicleType], cell)
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () =>
      this.props.onChange(convertVehicleCellToInput(updatedCell), 'ManagerAssetsByVehicle')
    )
  }

  render() {
    const { editMode, managerType} = this.props
    const rowOrder:{ code: AssetClassType, display: string}[] = [
      {code: AssetClassType.EQA, display: "Equities: Active"},
      {code: AssetClassType.EQP, display: "Equities: Passive"},
      {code: AssetClassType.FIA, display: "Fixed Income: Active"},
      {code: AssetClassType.FIP, display: "Fixed Income: Passive"},
      {code: AssetClassType.RE, display: "Real Estate"},
      {code: AssetClassType.HF, display: "Hedge Funds"},
      {code: AssetClassType.OV, display: "Overlay (TAA/Currency)"},
      {code: AssetClassType.PE, display: "Private Equity"},
      {code: AssetClassType.AOT, display: "Other Alternatives"},
      {code: AssetClassType.MA, display: "Multi-Asset/Balanced"},
      {code: AssetClassType.OTH, display: "Other"},
      {code: AssetClassType.TOT, display: "Total"}
    ]

    let calculatedTotal: any[] = []

    const handleCalculatedTotal = (path: string) => {
      const pathArray = calculatedTotal.map((ct) => get(ct, path, 0))
      pathArray.pop() //exclude total
      if (pathArray) {
        if(path.indexOf("numberOfAccounts") > -1 ) {
          return numbroFormatter(pathArray.reduce((accumulator, currentValue) => accumulator + currentValue))
        }else {
          return numbroFormatter(pathArray.reduce((accumulator, currentValue) => accumulator + currentValue), "$0,0.00")
        }
      }
    }

    return(
      <div className="table-container">
        <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.assets?.name}-Assets by asset class (Single)`}>
          <thead>
            <tr className="table-title row-border-olive-100">
              <th className="border-bottom-0"></th>
              <th colSpan={5} className="py-2">Assets ($M)</th>
              <th className="boundary-left"></th>
              <th className="boundary-right"></th>
              <th colSpan={5} className="py-2"># Accounts</th>
            </tr>
            <tr>
              <th>Asset Class</th>
              <th className="text-right fit-currency">Total</th>
              <th className="text-right fit-currency">Separate Account</th>
              <th className="text-right">Pooled/Commingled</th>
              <th className="text-right width-140" id="mutualFundInstitutionalTooltipContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="mutualFundInstitutionalTooltip">
                  Mutual Fund Institutional
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                  />
                </div>
              </th>
              <th className="text-right width-140" id="mutualFundRetailTooltipContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="mutualFundRetailooltip">
                  Mutual Fund Retail
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                  />
                </div>
              </th>
              <th className="boundary-left"></th>
              <th className="boundary-right"></th>
              <th className="text-right">Total</th>
              <th className="text-right fit-currency">Separate Account</th>
              <th className="text-right" id="pooledTooltipContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="pooledTooltip">
                  Pooled/Commingled
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                  />
                </div>
              </th>
              <th className="text-right width-140">Mutual Fund Institutional</th>
              <th className="text-right width-140">Mutual Fund Retail</th>
            </tr>
          </thead>
          <tbody>
            {rowOrder.map((arr, idx) => {

              if (VehicleTypeCodeEv.TOT) {
                const obj = this.state.currentState[arr.code]
                calculatedTotal.push(obj)
              }

              return(
                <AssetClassRow
                  data={this.state.currentState[arr.code]}
                  row={idx}
                  key={idx}
                  editMode={editMode}
                  clientType={arr}
                  updateValue={(value:any, property:string, vehicleType:VehicleTypeCodeEv) => this.handleInputChange(value, property, arr.code, vehicleType)}
                  managerType={managerType}
                />
              )
            })}
             <tr>
                <td className="text-left">Calculated Total</td>
                <td className={"text-right"}>{handleCalculatedTotal("TOT.assetsUnderManagement")}</td>
                <td className={"text-right"}>{handleCalculatedTotal("SA.assetsUnderManagement")}</td>
                <td className={"text-right"}>{handleCalculatedTotal("POOL.assetsUnderManagement")}</td>
                <td className={"text-right"}>{handleCalculatedTotal("MFI.assetsUnderManagement")}</td>
                <td className={"text-right"}>{handleCalculatedTotal("MFR.assetsUnderManagement")}</td>
                <td className="boundary-left"></td>
                <td className="boundary-right"></td>
                <td className={"text-right"}>{handleCalculatedTotal("TOT.numberOfAccounts")}</td>
                <td className={"text-right"}>{handleCalculatedTotal("SA.numberOfAccounts")}</td>
                <td className={"text-right"}>{handleCalculatedTotal("POOL.numberOfAccounts")}</td>
                <td className={"text-right"}>{handleCalculatedTotal("MFI.numberOfAccounts")}</td>
                <td className={"text-right"}>{handleCalculatedTotal("MFR.numberOfAccounts")}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }
}

interface AssetClassRowProps {
  data: GroupedAsset
  row: number
  editMode: boolean
  clientType: {code: string, display: string}
  updateValue: (value:any, property:string, vehicleType:VehicleTypeCodeEv) => void
  managerType?: ManagerType
  historicalList?: boolean
}

interface VehicleTypeInputField extends FormInputField{
  vehicleType?: VehicleTypeCodeEv
}

const VehicleTypeInputList:VehicleTypeInputField[] = [
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.TOT},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.SA},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.POOL},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.MFI},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.MFR},
  { property: "boundary", label: "", type: "boundary"},
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.TOT },
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.SA },
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.POOL },
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.MFI },
  { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.MFR },
]

const AssetClassRow = ({data, row, editMode, clientType, updateValue, managerType, historicalList}: AssetClassRowProps) => {
  return (
    <tr key={`AssetsByVehicle-${row}`}>
      <td className="text-nowrap text-left">
        { clientType.display }
      </td>
      {
        VehicleTypeInputList.map(({property, label, type, subtype, vehicleType}, idx) => {
          if(type === "boundary"){
            return (
              <React.Fragment key={idx}>
                <td className="boundary-left"></td>
                <td className="boundary-right"></td>
              </React.Fragment>
            )
          }

          let propertyVal, onChangeCallback
          propertyVal = get(data, [vehicleType as string, property])
          onChangeCallback = (value:any) => vehicleType ? updateValue(value, property, vehicleType) : null

          return (
            <td key={idx} className="text-right" style={{minWidth: 80}}>
              <FormInput
                property={property}
                displayName={label}
                type={type}
                showZero={true}
                subtype={subtype}
                idx={`AssetsByVehicle-${property}.${row}.${vehicleType}`}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
              />
            </td>
          )
        })
      }
    </tr>
  )
}

export interface HistoricalGroupedAssets {
  [key: string]: { [key: string]: GroupedAsset }
}

interface HistoricalAssetsByAssetClassTableState {
  currentState: HistoricalGroupedAssets
  initialState: HistoricalGroupedAssets
  vehicleType: VehicleTypeCodeEv
  displayValue: HistoricalDisplayValueType
  historicalDate: moment.Moment
}

export interface HistoricalAssetsByAssetClassTableProps {
  data: ManagerAssetsByVehicleQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<HistoricTables>
  fetchMore: any
  managerType?: ManagerType
  onChange: (updatedCell: TableInputTypes, table:Tables) => void
}


class HistoricalAssetsByAssetClassTable extends Component<HistoricalAssetsByAssetClassTableProps, HistoricalAssetsByAssetClassTableState> {

  constructor(props: any) {
    super(props)

    const assets:ManagerAssetsByVehicle[] = get(props.data, "assets.assetsByVehicle", [])
    let groupedData:HistoricalGroupedAssets = {}

    assets.map((entry:ManagerAssetsByVehicle) => {
      let assetClass:AssetClassType = entry.assetClass.code
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code
      let quarterEndDate = entry.quarterEndDate

      set(groupedData, [vehicleType, quarterEndDate, assetClass], entry)
    })
    this.state = {
      currentState: groupedData,
      initialState: groupedData,
      vehicleType: VehicleTypeCodeEv["TOT"],
      displayValue: "assetsUnderManagement",
      historicalDate: moment(firstHistoricalDate)
    }
  }

  static getDerivedStateFromProps(props: HistoricalAssetsByAssetClassTableProps, state:HistoricalAssetsByAssetClassTableState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    const assets:ManagerAssetsByVehicle[] = get(props.data, "assets.assetsByVehicle", [])
    let groupedData:HistoricalGroupedAssets = {}

    assets.map((entry:ManagerAssetsByVehicle) => {
      let assetClass:AssetClassType = entry.assetClass.code
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code
      let quarterEndDate = entry.quarterEndDate

      set(groupedData, [vehicleType, quarterEndDate, assetClass], entry)
    })
    return {
      currentState: resetDate && !props.editMode ? groupedData : state.currentState,
      initialState: resetDate ? groupedData : state.initialState,
      vehicleType: state.vehicleType,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
    }
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    assetClass: AssetClassType,
    vehicleType: VehicleTypeCodeEv,
    quarterEndDate: string
  ) => {
    let updatedCell:ManagerAssetsByVehicle
    let oldState = cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      selectedRow => {
        let row:HistoricalGroupedAssets = cloneDeep(selectedRow)
        let cell:ManagerAssetsByVehicle
        let selectedCell = get(selectedRow, [vehicleType, quarterEndDate, assetClass]) as ManagerAssetsByVehicle
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            vehicleType: {code: vehicleType as VehicleTypeCodeEv, __typename: "OrgVehicleTypeLookup"},
            assetClass: {code: assetClass as AssetClassType, __typename: "AssetClassTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: quarterEndDate,
            __typename: "ManagerAssetsByVehicle",
            origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'}
          }
        }

        set(cell, property, value)
        set(row, [vehicleType, quarterEndDate, assetClass], cell)
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () =>
      this.props.onChange(convertVehicleCellToInput(updatedCell), 'ManagerAssetsByVehicle')
    )
  }

  setVehicleType = (vehicleType:VehicleTypeCodeEv) => {
    this.setState({
      vehicleType: vehicleType
    })
  }

  setDisplayValue = (displayValue:HistoricalDisplayValueType) => {
    this.setState({
      displayValue: displayValue
    })
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  loadMore = (fetchMoreResult:ManagerAssetsByVehicleQuery) => {
    const newInitialState = this.state.initialState
    const newCurrentState = cloneDeep(this.state.currentState)

    const newAssets:ManagerAssetsByVehicle[] = get(fetchMoreResult, "assets.assetsByVehicle", [])

    newAssets.map((entry:ManagerAssetsByVehicle) => {
      let assetClass:AssetClassType = entry.assetClass.code
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code
      let quarterEndDate = entry.quarterEndDate

      if (!has(this.state.currentState, [vehicleType, quarterEndDate, assetClass])) {
        set(newInitialState, [vehicleType, quarterEndDate, assetClass], entry)
        set(newCurrentState, [vehicleType, quarterEndDate, assetClass], entry)
      }
    })

    this.setState({
      initialState: newInitialState,
      currentState: newCurrentState,
      historicalDate: moment(this.state.historicalDate).subtract(5, "years")
    })
  }

  render() {
    const vehicleType = this.state.vehicleType
    const displayValue = this.state.displayValue
    const headingOrder:{ code: AssetClassType, display: string}[] = [
      {code: AssetClassType.EQA, display: "Equities: Active"},
      {code: AssetClassType.EQP, display: "Equities: Passive"},
      {code: AssetClassType.FIA, display: "Fixed Income: Active"},
      {code: AssetClassType.FIP, display: "Fixed Income: Passive"},
      {code: AssetClassType.RE, display: "Real Estate"},
      {code: AssetClassType.HF, display: "Hedge Funds"},
      {code: AssetClassType.OV, display: "Overlay (TAA/Currency)"},
      {code: AssetClassType.PE, display: "Private Equity"},
      {code: AssetClassType.AOT, display: "Other Alternatives"},
      {code: AssetClassType.MA, display: "Multi-Asset/Balanced"},
      {code: AssetClassType.OTH, display: "Other"},
      {code: AssetClassType.TOT, display: "Total"}
    ]
    let exportNameSubtab = ""
    let exportNameSubtab_2 = ""
    switch(vehicleType) {
      case "SA":
        exportNameSubtab = "Separate Account"
        break;
      case "POOL":
        exportNameSubtab = "Pooled/Commingled"
        break;
      case "MFI":
        exportNameSubtab = "Mutual Fund Institutional"
        break;
      case "MFR":
        exportNameSubtab = "Mutual Fund Retail"
        break;
      default: 
        exportNameSubtab = "Total"
    }
    if(displayValue === "numberOfAccounts"){
      exportNameSubtab_2 = "Accounts"
    }
    else {
      exportNameSubtab_2 = "Assets"
    }
    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))
    const data = this.state.currentState[vehicleType]

    const heading = () =>{
      return(
      <div className="w-100 d-flex justify-content-between">
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-item",{ active: vehicleType === "TOT" })} onClick={() => this.setVehicleType(VehicleTypeCodeEv["TOT"])}>Total</li>
          <li className={classnames("horizontal-picker-item",{ active: vehicleType === "SA" })}  onClick={() => this.setVehicleType(VehicleTypeCodeEv["SA"])}>Separate Account</li>
          <li className={classnames("horizontal-picker-item",{ active: vehicleType === "POOL" })}  onClick={() => this.setVehicleType(VehicleTypeCodeEv["POOL"])}>Pooled/Commingled</li>
          <li className={classnames("horizontal-picker-item",{ active: vehicleType === "MFI" })}  onClick={() => this.setVehicleType(VehicleTypeCodeEv["MFI"])}>Mutual Fund Institutional</li>
          <li className={classnames("horizontal-picker-item",{ active: vehicleType === "MFR" })}  onClick={() => this.setVehicleType(VehicleTypeCodeEv["MFR"])}>Mutual Fund Retail</li>

        </ul>
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets ($M)</li>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
      </div>)
    }

    return (
      <>
        {heading()}
        <div className="table-container">
          <Table hover className="table-bordered-internal table-condensed exportable" data-export-name={`Historical ` + exportNameSubtab + ` ` + exportNameSubtab_2}>
            <thead>
              <tr>
                <th>Date</th>
                {headingOrder.map((heading) => {
                  return(<th key={heading.code}>{heading.display}</th>)
                })}
                <th>Calculated Total</th>
              </tr>
            </thead>
            <tbody>
              {allDates.map((date:string, row:number) => {
                let calculatedTotal: any[] = []
                return (
                  <tr key={`AssetsByVehicle-${displayValue}-${vehicleType}-${date}`} className="fadein">
                    <td className="nowrap">
                      {date}
                    </td>
                    {headingOrder.map((heading, idx) => {
                      let propertyVal = get(data, [date, heading.code, displayValue])
                      if (propertyVal && heading.code !== 'TOT' ) {
                        calculatedTotal.push(propertyVal)
                      }

                      return(
                        <td key={`AssetsByVehicle-${idx}`}>
                          <FormInput
                            property={displayValue}
                            displayName={""}
                            type={"number"}
                            showZero={true}
                            subtype={displayValue === "assetsUnderManagement" ? "currency" : ""}
                            placeholder={""}
                            idx={`AssetsByVehicle-${displayValue}-${vehicleType}-${date}-${heading.code}`}
                            editMode={this.props.editMode}
                            propertyVal={propertyVal}
                            updateValue={(value:any) => this.handleInputChange(value,this.state.displayValue,heading.code,vehicleType, date)}
                          />
                        </td>
                      )
                    })}
                    <td className="nowrap">
                      {numbroFormatter(calculatedTotal.reduce((acc, curr)=> acc + curr, null) , displayValue === 'numberOfAccounts' ? "0,0" : "$0,0.00")}
                    </td>
                  </tr>
                )
                })}
            </tbody>
          </Table>
        </div>
      </>
    )
  }
}

export interface OtherAssetsExplanationProps {
  data: ManagerAssetsByVehicleQuery
  editMode: boolean
  searchDate: string
  managerType?: ManagerType
  onChange: (updatedCell: TableInputTypes, table:Tables) => void
}

interface OtherAssetsExplanationState {
  currentState: OtherAssetExplanation
  initialState: OtherAssetExplanation
}

class OtherAssetsExplanationComponent extends Component<OtherAssetsExplanationProps, OtherAssetsExplanationState> {
  constructor(props: any) {
    super(props)

    const blankAssetExplanation = {
      __typename: "OtherAssetExplanation",
      quarterEndDate: this.props.searchDate,
      vehicle: ""
    } as OtherAssetExplanation

    const otherAssetExplanations: OtherAssetExplanation[] = get(props.data, "assets.otherAssetExplanation", [])
    const otherAssetExplanation = find(otherAssetExplanations, { quarterEndDate: props.searchDate }) || blankAssetExplanation

    this.state = {
      currentState: otherAssetExplanation,
      initialState: otherAssetExplanation
    }
  }

  static getDerivedStateFromProps(props: OtherAssetsExplanationProps, state:OtherAssetsExplanationState) {
    const otherAssetExplanations: OtherAssetExplanation[] = get(props.data, "assets.otherAssetExplanation", [])
    const blankAssetExplanation = {
      __typename: "OtherAssetExplanation",
      quarterEndDate: props.searchDate,
      vehicle: ""
    } as OtherAssetExplanation
    const otherAssetExplanation = find(otherAssetExplanations, { quarterEndDate: props.searchDate }) || blankAssetExplanation
    return {
      currentState: props.editMode ? state.currentState : otherAssetExplanation,
      initialState: otherAssetExplanation,
    }
  }

  handleInputChange = (
    value: any
  ) => {
    const newAssetExplanation = {
      __typename: "OtherAssetExplanation",
      quarterEndDate: this.props.searchDate,
      vehicle: value
    } as OtherAssetExplanation

    this.setState({ currentState: newAssetExplanation }, () =>
      this.props.onChange({quarterEndDate: this.props.searchDate, vehicle: value}, "ManagerOtherAssetExplanation")
    )
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  render() {
    const { editMode, managerType} = this.props

    return (
      <div className={"m-3"}>
        <FormInput
          property={"vehicle"}
          displayName={""}
          type={"textarea"}
          showZero={true}
          subtype={""}
          placeholder={""}
          idx={1}
          editMode={editMode}
          propertyVal={this.state.currentState.vehicle}
          updateValue={(value:any) => this.handleInputChange(value)}
        />
      </div>
    )
  }
}


export default ManagerAssetsVehicles
