import { ConfigTypeUnion } from '../../../helpers/constant'
import { defaultProductOverviewSummaryConfig, passiveProductSummaryConfig } from './default'
import { OpenEndedEquityProductOverviewSummaryOverrideConfig } from './OpenEndedEquity'

export const OpenEndedPassiveEquityProductOverviewSummaryOverrideConfig = {
  openEnded: {
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig.openEnded
  },
  product: {
    ...defaultProductOverviewSummaryConfig,
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig.product
  }
}
