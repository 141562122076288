import classnames from 'classnames'
import React, { useEffect } from "react"
import { Redirect, Switch, useHistory } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Container, DropdownToggle, Nav, NavItem, NavLink, Row } from 'reactstrap'
import { ListSubCategoryCode, usePlanDetailsQuery } from '../../__generated__/graphql'
import { useDocumentTitle } from "../../helpers/usehook"
import { appProps } from '../../queries/extended/appProps'
import { PlanRouteProps } from "../../queries/extended/types/Plan"
import { CrumbRoute } from '../../routes/crumb-route'
import { WithTopNav } from "../ui/LayoutWrapper"
import LoadingOverlay from '../ui/Loading'
import PlanPicker from '../ui/Pickers/PlanPicker'
import PlanActivitiesList from './PlanActivities'
import { PlanPerformanceMain } from "./PlanPerformance"
import PlanPortfoliosList from './PlanPortfolios'
import PlanSummary from "./PlanSummary"

const QueryPlanDetails: React.FC<appProps & PlanRouteProps> = (props) => {
  const { match, auth } = props
  const { planId } = match.params
  const history = useHistory()
  const { setTitle, resetTitle } = useDocumentTitle()

  const { loading, error, data } = usePlanDetailsQuery({
    variables: { id: parseInt(planId)}
  })

  useEffect(() => {
    return () => {
      resetTitle()
    }
  }, [])

  useEffect(() => {
    if (data) {
      setTitle(data.plan?.name || "")
    }
  }, [data])

  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <div>
        <p>{error.message}</p>
      </div>
    )
  }
  if (!data?.plan) {
    return <p>Plan Data not found</p>
  }

  if(!auth.checkPermissions(['view:all_clients']))
    return <p>You do not have permissions to view this page</p>
  const plan = data.plan
  const { type, subtype } = match.params
  const urlWithType = subtype
    ? match.url.slice(0, -subtype.length - 1)
    : match.url
  const urlWithoutType = type
    ? urlWithType.slice(0, -type.length - 1)
    : urlWithType

  const masterList = plan?.report?.find((report) => report?.draftList?.subCategory?.code === ListSubCategoryCode.MASTER)

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <Col xs="10" sm="8" md="6">
                <Breadcrumb>
                  <BreadcrumbItem
                    className="headline-breadcrumbs"
                    key="test"
                    onClick={() =>
                      history.push(
                        `/clients/${plan.client?.id}`
                      )
                    }
                  >
                    {plan?.client?.name}
                  </BreadcrumbItem>
                </Breadcrumb>
                {plan?.client && (
                  <PlanPicker
                    clientId={plan?.client.id}
                    title={`View another product by ${plan?.client.name || ""}`}
                    onClick={(planId) => {
                      window.location.href = `/plans/${planId}`
                    }}
                  >
                  <div className='dropdown'>
                    <DropdownToggle caret>
                      <h2 className="headline">
                        {plan?.name || ""}
                      </h2>
                    </DropdownToggle>
                    {data.plan?.accountType?.code == "FCUST" && (
                      <span className="inactive-tag">Inactive</span>
                    )}
                  </div>
                  </PlanPicker>
                )}
              </Col>
            </Row>
            <Nav
              className="sub-nav sub-nav-primary"
              tabs
              role="group"
              aria-label="Plan Subpage"
            >
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active:
                      match.url.indexOf(`${urlWithoutType}/overview`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/overview`)}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active:
                      match.url.indexOf(`${urlWithoutType}/portfolios`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/portfolios`)}
                >
                  Portfolios
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active:
                      match.url.indexOf(`${urlWithoutType}/performance`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/performance`)}
                >
                  Performance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("", {
                    active:
                      match.url.indexOf(`${urlWithoutType}/activities`) === 0,
                  })}
                  onClick={() => history.push(`${urlWithoutType}/activities`)}
                >
                  Activities
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        <CrumbRoute
          title="overview"
          path={`${urlWithoutType}/overview`}
          render={() => (
            <PlanSummary data={plan} {...props} planId={parseInt(planId)} />
          )}
        />
        <CrumbRoute
          title="portfolios"
          path={`${urlWithoutType}/portfolios`}
          render={() => (
            <PlanPortfoliosList data={data} {...props} planId={parseInt(planId)} clientId={data?.plan?.client?.id} />
          )}
        />
        <CrumbRoute
          title="performance"
          path={`${urlWithoutType}/performance/:portfolioId?/:subtype?`}
          render={() => (
            <PlanPerformanceMain {...props} planId={parseInt(planId)} listId={masterList?.draftList?.id} />
          )}
        />
        <CrumbRoute
          title="activities"
          path={`${urlWithoutType}/activities`}
          render={() => (
            <PlanActivitiesList {...props} planId={parseInt(planId)}/>
          )}
        />
        <Redirect to={`${urlWithoutType}/overview`} />
      </Switch>
    </>
  )
}
export default WithTopNav(QueryPlanDetails)