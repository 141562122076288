import React, { useCallback, useEffect, useState } from "react"
import { Button } from "reactstrap"
import SSRMTable from "../Shared/SSRM/SSRMTable"
import { ColDef, FilterChangedEvent, GridReadyEvent, ProcessCellForExportParams } from "@ag-grid-community/core"
import {
  UserSavedProductFilterFragment,
  Maybe,
  GetAllAssetClassesQuery,
  UserSavedProductFilterQuery,
  AssetClassFragment,
} from "../../__generated__/graphql"
import {
  ResearchProductStrategyTypeSearchType as SearchType,
} from "./ResearchProductConfig"
import { ApolloQueryResult } from "@apollo/client"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { AddNewViewModal, convertToFilterModel, convertToSortState, CurrentViewsModal, SortModelDirection, SortModelState } from "./FilterViewModal"
import { createServerSideDatasource } from "../Shared/SSRM/DataSource/ResearchProductsViewDataSource"
import { matchedFilters } from "./ResearchProductMain"
import numbro from "numbro"
import moment from "moment"

type ResearchProductViewProps = {
  colDef: (assetClasses: GetAllAssetClassesQuery["assetClasses"], viewOpinion?: boolean, viewClientExposure?: boolean) => {}[]
  type: SearchType
  filters?: Maybe<UserSavedProductFilterFragment[]>
  refetch: (variables?: any) => Promise<ApolloQueryResult<any>>
  filtersRefetch: (variables?: any) => Promise<ApolloQueryResult<UserSavedProductFilterQuery>>
  viewOpinion?: boolean
  viewClientExposure?: boolean
  filterId?: number
  loading?: boolean
  // {filters: {controls, limit}, options, viewOpinion, viewClientExposure}
  // variables default set in ResearchProductConfig.tsx, not settings in savedFilters.
  variables?: any
  assetClasses: GetAllAssetClassesQuery["assetClasses"]
}

export type FilterModelState = { [key: string]: any }

/**
 * filter view button
 * 1. click filter views
 * 2. show current views modal
 * 3. one of scenario below
 * 3.a click add => current views close, add new view modal open
 * 3.b choose one view => click show, current views modal close, view
 * 3.c choose one view => delete this view, saving, current views modal updated
 * 3.d cancel , current views close
 */

const getFilter = (savedFilters: UserSavedProductFilterFragment[], filterId: Maybe<number> | undefined) => {
  if (!!filterId) {
    return savedFilters?.find((filter) => filter.id === filterId) || null
  } else {
    return null
  }
}

const exportedHiddenColumns = [
  // fields
  "aumDate",
  // colIds, for duplicate fields.
  "yearlyDate",
  "quarterlyDate",
]

const customExport = (type: SearchType, columnDefs: ColDef[], assetClasses: GetAllAssetClassesQuery["assetClasses"]) => {
  let acc: ({ __typename: "AssetClass" } & AssetClassFragment)[] = []
  let assetClassesChildren = assetClasses.reduce((acc, el) => {
    let children = el?.children
    return [...acc, ...children]
  }, acc)
  let strategyTestOptions = assetClassesChildren.map((el) => {
    let {code, value} = el
    return {code, value}
      })
  let processCellCallback = (params:ProcessCellForExportParams) => {
    let colId = params.column.getColId()
    if(colId === "product.assetClass.parent.code") {
      return assetClasses.find(el => el.code == params.value)?.value
    }else if(colId === "product.assetClass.code") {
      return strategyTestOptions.find(el => el.code == params.value)?.value
    }
    if(colId.includes("Date")) {
      return params.value? moment(params.value, "YYYY-MM-DD").format("MM-DD-YYYY") : ""
    }
    if (type === SearchType._2) {
      // public fixed market, riskTarget format
      if(colId === "openEnded.riskStatisticsTargets.excessReturn" || colId === "openEnded.riskStatisticsTargets.trackingError") {
        if(!params.value) return ""
        let {low, high} = params.value
        if(_.isNull(low) && _.isNull(high)) return ""
        return `${low || 0}, ${high || 0}`
      }
    } else if( type === SearchType._3) {
      // private market fund size => million unit format
      if(colId === "closedEnded.fundRaising") {
        if(!_.isNumber(params.value)) return params.value
        return numbro((params?.value || 0)/1_000_000).format("0.00")
      }
    }
    return params.value
  }
  let columnKeys: string[] = columnDefs.filter(el => {
    if(!el?.field && !el?.colId) return false
    if(el?.field && exportedHiddenColumns.includes(el?.field) || el?.colId && exportedHiddenColumns.includes(el?.colId)) return true
    return el.hide !== true
  }).map(el => el?.colId? el?.colId: el?.field as string)

  let result = {processCellCallback, columnKeys}
  return result
}

const defaultSortModel: SortModelState = [
  {
    sort: SortModelDirection["asc"],
    colId: "product.manager.name",
    sortIndex: 1,
  },
]

const ResearchProductView = (props: ResearchProductViewProps) => {
  const { type, filters, colDef, refetch, viewOpinion, viewClientExposure } = props
  const { filterId, assetClasses } = props
  const{ variables, loading, filtersRefetch } = props
  const history = useHistory()

  const [savedFilters, setSavedFilters] = useState<UserSavedProductFilterFragment[]>(filters || [])
  const [selectedFilter, setSelectedFilter] = useState<Maybe<UserSavedProductFilterFragment>>(
    getFilter(savedFilters, filterId)
  )
  const [currentFilterModel, setCurrentFilterModel] = useState<Maybe<FilterModelState>>(null)

  // add new view modal
  const [isAddNewViewModalOpen, setAddNewViewModalOpen] = useState(false)
  const toggleAddNewViewModal = () => setAddNewViewModalOpen(!isAddNewViewModalOpen)

  // show current views modal
  const [isCurrentViewsModalOpen, setCurrentViewsModalOpen] = useState(false)
  const toggleCurrentViewsModal = () => setCurrentViewsModalOpen(!isCurrentViewsModalOpen)

  const [dataLoading, setDataLoading] = useState<boolean>(!!loading)
  const [forceUpdateSavedFilters, setForceUpdateSavedFilters] = useState<boolean>(false)
  // ag-grid
  const [columnDefs, setColumnDefs] = useState(colDef(assetClasses, !!viewOpinion, !!viewClientExposure))
  const [gridReadyEvent, setGridApi] = useState<Maybe<GridReadyEvent>>(null)
  
  const onReady = (params: GridReadyEvent) => {
    let {api, columnApi} = params
    setGridApi(params)
    // dataSource from default settings.
    const dataSource = createServerSideDatasource(refetch, variables, {}, params)
    api!.setServerSideDatasource(dataSource)
    let filter = selectedFilter || getFilter(savedFilters, filterId)
    if (api) {
      let activeOnlyFilterModel = {
        "product.inactive": {
          values: ["false", "Active"],
          filterType: "set"
        }
      }
      let filterModel = convertToFilterModel(filter) || activeOnlyFilterModel
      setCurrentFilterModel(filterModel)
      setFilterUpdates(filterUpdates + 1)
      api.setFilterModel(filterModel)
    }
    if(columnApi) {
      let sortModel = convertToSortState(filter) || defaultSortModel
      columnApi.applyColumnState({ state: sortModel })
    }
  }

  // used so that filter changes update the grid
  const [filterUpdates, setFilterUpdates] = useState(0)

  useEffect(() => {
    setColumnDefs(colDef(assetClasses, !!viewOpinion, !!viewClientExposure))
  }, [viewOpinion, viewClientExposure])

  useEffect(() => {
    setDataLoading(!!loading)
  }, [loading])

  useEffect(() => {
    if(forceUpdateSavedFilters) {
      filtersRefetch().then((result) => {
        let filters = result?.data?.savedProductFilters || []
        let matchedCategoryFilters = matchedFilters(filters, type)
        setSavedFilters(matchedCategoryFilters)
        setForceUpdateSavedFilters(false)
      })
    }
  }, [forceUpdateSavedFilters])

  useEffect(() => {
    let filters = props?.filters || []
    let selected = getFilter(filters, filterId)
    if (_.isNumber(filterId) && gridReadyEvent) {
      setSelectedFilter(selected)
      reapplyFilter()
      // }
    } else if (!filterId) {
      setSelectedFilter(null)
      clearFilters()
    }
  }, [filterId, gridReadyEvent])

  const reapplyFilter = useCallback(() => {
    let filters = props?.filters || []
    let selected = getFilter(filters, filterId)
    if (selected && gridReadyEvent) {
      let {api, columnApi} = gridReadyEvent
      if (api) {
        let filterModel = convertToFilterModel(selected)
        api.setFilterModel(filterModel)
      }
      if(columnApi) {
        columnApi.resetColumnState()
        let sortModel = convertToSortState(selected) || defaultSortModel
        columnApi.applyColumnState({ state: sortModel })
      }
    }
  }, [filterId, gridReadyEvent])

  useEffect(() => {
    if (selectedFilter && selectedFilter.id) {
      // setDataLoading(true)
      setCurrentViewsModalOpen(false)
      setAddNewViewModalOpen(false)
      console.log(433, { selectedFilter })
      if (filterId !== selectedFilter.id) {
        history.push(`/research/${type}?filterId=${selectedFilter.id}`)
      }
    }
  }, [selectedFilter])

  useEffect(() => {
    if (isAddNewViewModalOpen) {
      console.log("add open", { currentFilterModel })
    }
  }, [isAddNewViewModalOpen])

  // test
  const printCurrentFilterModel = () => {
    let sortModel = gridReadyEvent?.columnApi.getColumnState().filter(({sort, sortIndex}) => !!sort || sortIndex)
    console.log("print", {sortModel, currentFilterModel, selectedFilter })
  }

  const clearFilters = () => gridReadyEvent?.api?.setFilterModel(null)

  const heading = (
    <div className='pane pane-toolbar sticky-top'>
      <div className='px-1'>
        <Button
          color='link'
          className='mr-2 text-callan-blue border-blue-80 btn-thin'
          onClick={toggleCurrentViewsModal}
        >
          Filter Views...
        </Button>
        {/* <Button
          color='link'
          className='mr-2 text-callan-blue border-blue-80 btn-thin'
          onClick={printCurrentFilterModel}
        >
          Current Filters
        </Button>
        <Button color='link' className='mr-2 text-callan-blue border-blue-80 btn-thin' onClick={clearFilters}>
          Clear Active Filters
        </Button> */}
      </div>
      {!dataLoading && (
        <AddNewViewModal
          key='add-new'
          type={type}
          modalOpen={isAddNewViewModalOpen}
          setModalOpen={setAddNewViewModalOpen}
          callBackIfSuccess={refetch}
          filterModelState={currentFilterModel}
          gridReadyEvent={gridReadyEvent}
          setForceUpdateSavedFilters={setForceUpdateSavedFilters}
        />
      )}
      {!dataLoading && (
        <CurrentViewsModal
          key='current'
          type={type}
          modalOpen={isCurrentViewsModalOpen}
          setModalOpen={setCurrentViewsModalOpen}
          callBackIfSuccess={() => setDataLoading(false)}
          currentFilters={savedFilters}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          setAddNewModalOpen={setAddNewViewModalOpen}
          setForceUpdateSavedFilters={setForceUpdateSavedFilters}
          filterId={filterId}
        />
      )}
    </div>
  )
  return (
    <>
      {heading}
      <SSRMTable
        serverSide={true}
        loading={dataLoading}
        columnDefs={columnDefs}
        rowId={"product.id"}
        onReady={onReady}
        key={type}
        onFilterChanged={(event: FilterChangedEvent) => {
          let model = event?.api.getFilterModel()
          setCurrentFilterModel(model)
          setFilterUpdates(filterUpdates + 1)
        }}
        exportParams={customExport(type, columnDefs, assetClasses)}
      />
    </>
  )
}

export default ResearchProductView
