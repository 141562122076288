import React, { Component, useState, useRef, RefObject, useContext, useEffect } from "react"
import _, { first, get, remove } from "lodash"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  ListGroupItem,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useParams,
  RouteComponentProps,
  match,
} from "react-router-dom"
import moment, { Moment } from "moment"
import { History } from "history"

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import {
  convertLookupToString,
  excludePropertyArray,
} from "../../helpers/object"
import {
  Vehicle,
  useUpdateClientPortfolioMutation,
  UpdateClientPortfolioInput,
  ProductCharacteristicsQuery,
  ClientPortfolio,
  ProductStructureCode,
} from "../../__generated__/graphql"
import { CalendarPicker, CalendarPickerType } from '../CalendarPicker'
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import { DATE_API_FORMAT } from "../../helpers/constant"
import { Picker, OptionItem } from "../Shared/Picker"
import { CalendarContext,  appDate } from "../../Context/CalendarContext"
import { ProductCharacteristicsVehiclesDetail, QueryProductCharacteristicsClientDetails } from "./ProductCharacteristicsVehiclesDetail"
import { VehicleCharacteristicsDefinition, VehicleCharacteristicsTable, tableLengths } from "./ProductCharacteristicsVehiclesFields"
import exportTables from "../../helpers/exportTable"
import { DEFAULT_CHARACTERISTICS_FILTER, NonNullableCharacteristicsProperties } from "./ProductCharacteristicsVehicles"

const CharacteristicsTables = ["miscPortfolioInfo", "portfolioCharacteristics"]
enum CharacteristicsTablesEnum {
  characteristics = "characteristics",
  weightedExposures = "weightedExposures"
}
const WeightedExposuresWithActive = ["bondSectorExposures", "durationExposures", "qualityExposures"]

interface ProductCharacteristicsClientProps extends RouteComponentProps {
  productId: number
  auth: Auth
  productCharacteristics: ProductCharacteristicsQuery
}

const firstHistoricalDate = moment(appDate).subtract(3,"years")
const historicalStartDate = appDate.format(DATE_API_FORMAT)
const historicalEndDate = firstHistoricalDate.format(DATE_API_FORMAT)

const ProductCharacteristicsClient: React.FC<ProductCharacteristicsClientProps> = ({
  productId,
  auth,
  productCharacteristics,
}: ProductCharacteristicsClientProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [editMode, setEditMode] = useState(false)
  const [screen, setScreen] = useState<CalendarPickerType>(context.period == "historical" ? "historical" :"single")
  const [showEditMode, setShowEditMode] = useState(true)
  const [saving, setSaving] = useState(false)
  const [search, setSearch] = useState("")
  const resultRef = useRef<ProductCharacteristicsVehiclesDetail>(null)
  const queryRef = useRef<QueryProductCharacteristicsClientDetails>(null)
  const [updateClientPortfolio] = useUpdateClientPortfolioMutation()
  let match = useRouteMatch()
  const history = useHistory()
  const [loadingMore,] = useState(false)
  const [historicalDate, setHistoricalDate] = useState(firstHistoricalDate)
  const [tab, setTab] = useState<string>('')
  const data = productCharacteristics

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setEditMode(!editMode)
  }

  const updateStateFromResult = (result: any) => {
    setSaving(false)
    if (result && result.data) {
      setEditMode(false)
    }
    if(screen === "historical"){
      queryRef.current?.resetHistory()
    }
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:client_portfolios"])){
      return
    }
    let currentPortfolio = _.cloneDeep(resultRef!.current?.state?.currentState) as ClientPortfolio
    let initialPortfolio = resultRef!.current?.state?.initialState
    if (!currentPortfolio) {
      return
    }
    if (!initialPortfolio) {
      return
    }
    setSaving(true)
    let submissionData:any = {}
    const tables = Object.keys(tableLengths)
    // TODO should split between types.
    tables.forEach((tableName) => {
      let oldTable: any, newTable: any
      let type = (CharacteristicsTables.includes(tableName)? CharacteristicsTablesEnum.characteristics: CharacteristicsTablesEnum.weightedExposures).toString()
      if(type === "characteristics") {
        newTable = _.get(currentPortfolio, [type], []) || []
        oldTable = _.get(initialPortfolio, [type], []) || []
        if(!_.isEqual(newTable, oldTable)){
          if(!submissionData.hasOwnProperty(type)) {
            _.set(submissionData, [type], [])
          }
        }
        newTable.forEach((row:any, index:number) => {
          let oldValue = oldTable.find((oldRow:any) => oldRow.date === row.date)
          if(row && !_.isEqual(row, oldValue)) {
            let diff: any = {}
            let newResult = Object.keys(row).reduce((acc, property) => {
              if(!oldValue?.hasOwnProperty(property) || !_.isEqual(row[property], oldValue[property])){
                acc[property] = row[property]
              }
              return acc
            }, diff)
            // check nulls
            if(Object.keys(NonNullableCharacteristicsProperties).every((property) => !newResult.hasOwnProperty(property))){
              newResult = {...newResult, ...NonNullableCharacteristicsProperties}
            }
            let oldResult = submissionData[type] ? submissionData[type].find((oldRow:any) => oldRow.date === row.date) : null
            if(oldResult){
              submissionData[type] = submissionData[type].map((oldRow:any) => oldRow.date === row.date ? {...oldRow, ...newResult} : oldRow)
            }else {
              submissionData[type].push({...oldValue, ...newResult})
            }
          }
        })
      }else {
        // weightedExposures, match date & type
        newTable = _.get(currentPortfolio, [type, tableName], []) || []
        oldTable = _.get(initialPortfolio, [type, tableName], []) || []
        if(!submissionData.hasOwnProperty(type)) {
          _.set(submissionData, [type], {[tableName]: {add: [], remove: []}})
        }else if(!submissionData[type].hasOwnProperty(tableName)) {
          _.set(submissionData, [type, tableName], {add: [], remove: []})
        }
        // console.log(165, {tableName, isEqual: _.isEqual(newTable, oldTable), own: submissionData.hasOwnProperty(type), submissionData, newTable, oldTable, table: submissionData[type], type})
        newTable.forEach((row:any, index:number) => {
          // weightedExposures, match date & type
          // TODO: doesn't work yet.
          let oldValue = oldTable.find((oldRow:any) => oldRow.date === row.date && row?.type?.code && oldRow?.type?.code === row?.type?.code)
          let hasActiveValue = WeightedExposuresWithActive.includes(tableName)
          let valueProperty = hasActiveValue? "value.active": "value"
          if(row && !_.isEqual(row, oldValue)) {
            if(_.get(row, valueProperty) == null && _.get(row, "contributionToDuration") == null && _.get(row, "contributionToDurationTimeSpread") == null && _.get(row, "relativeContributionToDurationTimeSpread") == null){
            } else {
              console.log(175, {table: submissionData[type], row, type, tableName})
              let { type: rowType, value, ...rest} = row
              let formattedData = {...rest, type: rowType.code, value: hasActiveValue? value?.active: value}
              submissionData[type][tableName].add.push(formattedData)
            }
            if(oldValue){
              submissionData[type][tableName].remove.push({date: row.date, type: row.type})
            }
          }
          // console.log(182, {index, row, type, tableName, old: oldValue,a: submissionData[type], b: submissionData[type][tableName]})
        })

        if(submissionData[type][tableName].add.length === 0 && submissionData[type][tableName].remove.length === 0){
        _.unset(submissionData, [type, tableName])
        }else if(submissionData[type][tableName].add.length === 0){
          delete submissionData[type][tableName].add
        }else if(submissionData[type][tableName].remove.length === 0){
          delete submissionData[type][tableName].remove
        }
      }
    })
    if(submissionData?.weightedExposures){
      _.set(currentPortfolio, "weightedExposures",  submissionData?.weightedExposures)
    }
    if(submissionData?.characteristics){
      _.set(currentPortfolio, "characteristics",  submissionData?.characteristics)
    }

    let formattedData = convertLookupToString(currentPortfolio, false, [])
    const formattedRemoveNull = formattedData //_.pickBy(formattedDataRemove, _.identity);
    const data = excludePropertyArray(formattedRemoveNull, ["__typename", "name", "fundid", "id", "isRepresentative", "holdingStatus", "vehicleDataCollection", "identifiers", "inceptionDate", "status", "category", "active", "surveyed"])
    const updateData = {
      id: currentPortfolio.id,
      patch: excludePropertyArray(data, ["__typename", "name", "id", "clientSpecificDataCollectionFields", "isRepresentative", "holdingStatus", "vehicleDataCollection", "identifiers", "inceptionDate", "status", "category"]),
    } as UpdateClientPortfolioInput
    console.log(208, {formattedData, updateData})
    updateClientPortfolio({ variables: {
      input: updateData,
      startDate: screen === "single" ? searchDate : historicalEndDate,
      endDate: screen === "single" ? searchDate : historicalStartDate,
      filters: {
        startDate: screen === "single" ? searchDate : historicalEndDate,
        endDate: screen === "single" ? searchDate : historicalStartDate,
        ...DEFAULT_CHARACTERISTICS_FILTER,
      }
    } })
    .then(updateStateFromResult)
    .catch((err) => {
      setSaving(false)
      console.error("Error update Product Characteristics", err.message)
    })
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top above-picker">
      <Form className="mr-2 pr-3 border-right">
        <FormGroup row className="relative m-0 mr-1">
          <Input
            type="text"
            placeholder="Search Results"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
          {search === "" && (
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          )}
        </FormGroup>
      </Form>
      {showEditMode &&
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          editMode={editMode}
          setEditMode={(value:boolean) => setEditMode(value)}
          updateType={(type:CalendarPickerType) => setScreen(type)}
          hasHistorical={true}
          defaultType={screen}
        />
      }
      {showEditMode && screen === "historical" &&
        <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>resultRef.current?.loadMore()}>
          {loadingMore && "Loading"}
          {!loadingMore && "Load 3 More Years"}
        </Button>
      }
      {tab !== "holdings" &&
        <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables(screen !== "historical" ? {arbitraryCols: [{column: 0, heading: "As of Date", value: searchDate}]}: {})}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
      }
      {showEditMode && auth.checkPermissions(["edit:client_portfolios"]) &&
        <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} />
      }
    </div>
  )

  const showStrategyTab = (product: ProductCharacteristicsQuery["product"]) => {
    const parentId = product?.product?.assetClass?.parent?.id
    const assetClassId = product?.product?.assetClass?.id
    const structureCode = product?.product?.structure?.code
    if(parentId !== 57 && parentId !== 146){
      return false
    }
    if (structureCode === ProductStructureCode.FOF){
      return true
    }
    if(!assetClassId) return false
    if([157, 220, 221, 222, 223].includes(assetClassId)){
      return true
    }

  }

  if (data && data.product && data.product.product?.vehicles) {
    let vehicles = _.cloneDeep(data.product.product.vehicles) as Vehicle[]
    let portfolios = _.compact(_.flatMap(vehicles, (v) => v.vehicle?.relatedClientPortfolioManagerDataCollect))
    const filteredPortfolios = _.filter(portfolios, (portfolio:ClientPortfolio) => portfolio.clientSpecificDataCollectionFields?.questionnaireHoldingsRequired || portfolio.clientSpecificDataCollectionFields?.questionnaireCharacteristicsRequired) as ClientPortfolio[]
    let tableDef = VehicleCharacteristicsDefinition[data.product.product.assetClass?.id || "0"] || VehicleCharacteristicsDefinition[data.product.product.assetClass?.parent?.id || "0"]
    if (!showStrategyTab(data.product)){
      remove(tableDef, {"tableName": "strategies"})
    }
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <Result
              detailRef={resultRef}
              queryRef={queryRef}
              editMode={editMode}
              portfolios={filteredPortfolios}
              search={search}
              match={match}
              history={history}
              searchDate={searchDate}
              historicalDate={screen === "historical" ? historicalDate.format(DATE_API_FORMAT) : undefined}
              setShowEditMode={(value:boolean) => setShowEditMode(value)}
              tableDefinitions={tableDef}
              setHistoricalDate={(value:Moment) => setHistoricalDate(value)}
              tab={tab}
              setTab={setTab}
            />
          </Col>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist</div>
}

interface Props {
  portfolios: ClientPortfolio[]
  editMode: boolean
  search: string
  detailRef: RefObject<ProductCharacteristicsVehiclesDetail>
  queryRef: RefObject<QueryProductCharacteristicsClientDetails>
  match: any
  history: any
  searchDate: string
  historicalDate?: string
  setShowEditMode: (value:boolean) => void
  tableDefinitions: VehicleCharacteristicsTable[]
  setHistoricalDate: (value:Moment) => void
  tab: string
  setTab: (value: string) => void
}

class Result extends Component<Props> {
  render() {
    return (
      <Row>
        <Switch>
          <Route path={`${this.props.match.path}/:portfolioId?`}>
            <PortfolioHolder
              detailRef={this.props.detailRef}
              queryRef={this.props.queryRef}
              portfolios={this.props.portfolios}
              editMode={this.props.editMode}
              search={this.props.search}
              history={this.props.history}
              match={this.props.match}
              searchDate={this.props.searchDate}
              historicalDate={this.props.historicalDate}
              setShowEditMode={this.props.setShowEditMode}
              tableDefinitions={this.props.tableDefinitions}
              setHistoricalDate={this.props.setHistoricalDate}
              key={"test"}
              tab={this.props.tab}
              setTab={this.props.setTab}
            />
          </Route>
        </Switch>
      </Row>
    )
  }
}

function PortfolioHolder({
  detailRef,
  queryRef,
  portfolios,
  editMode,
  search,
  history,
  match,
  searchDate,
  setShowEditMode,
  tableDefinitions,
  historicalDate,
  setHistoricalDate,
  tab,
  setTab,
}: any) {
  let { portfolioId } = useParams() as {portfolioId?: string}
  if (portfolioId === "new") {
    portfolioId = "0"
  }
  const portfolio = _.find(portfolios, (o) => {
    return o.id == portfolioId
  })
  // reset after change not on click incase user cancels navigation
  useEffect(() => {
    setShowEditMode(true)
    setHistoricalDate(moment(historicalEndDate))
    setTab(get(first(tableDefinitions), 'tableName'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId])
  if (!portfolio) {
    return (
      <>
        <PortfolioPicker
          portfolios={portfolios}
          search={search}
          history={history}
          match={match}
        />
        <Col md="8" lg="9" className="pl-1">
          <div className="pane">
            <Row>
              <Col>
                <h3>No Portfolio Selected</h3>
              </Col>
            </Row>
          </div>
        </Col>
      </>
    )
  }
  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={(path) => history.push(path)}
      />
      <PortfolioPicker
        selectedPortfolio={portfolio}
        portfolios={portfolios}
        search={search}
        history={history}
        match={match}
      />
      <QueryProductCharacteristicsClientDetails
        ref={queryRef}
        detailRef={detailRef}
        portfolio={portfolio}
        editMode={editMode}
        key={portfolioId}
        tableDefinitions={tableDefinitions}
        searchDate={searchDate}
        historicalDate={historicalDate}
        setShowEditMode={setShowEditMode}
        setHistoricalDate={setHistoricalDate}
        tab={tab}
        setTab={setTab}
      />
    </>
  )
}

interface PortfolioPickerProps {
  selectedPortfolio?: ClientPortfolio
  portfolios: ClientPortfolio[]
  search: string
  history: History
  match: match
}

interface PortfolioPickerState {
  pickerFilters: OptionItem[]
  activeFilters: number[]
  pickerSortOptions: OptionItem[]
  activeSort: number
}

export class PortfolioPicker extends Component<PortfolioPickerProps> {
  state: PortfolioPickerState
  constructor(props: PortfolioPickerProps) {
    super(props)
    let pickerFilters: OptionItem[] = []
    let activeFilters: number[] = []
    pickerFilters = []
    // Add the possible options to the
    _.uniq(props.portfolios.map((portfolio:ClientPortfolio) => _.get(portfolio, "relatedVehicle.vehicle.category.value", ""))).sort().map((category:string) => { pickerFilters.push({id: pickerFilters.length, name: category})})
    activeFilters = []
    this.state = {
      pickerFilters,
      activeFilters: activeFilters,
      pickerSortOptions: [
        {
          id: 1,
          name: "Name ( A to Z )",
        },
        {
          id: 2,
          name: "Name ( Z to A )",
        },
      ],
      activeSort: 1,
    }
    if(!props.selectedPortfolio){
      const sortedObjects = this.sortObjects()
      if(sortedObjects.length > 0){
        props.history.push(`${props.match.url}/${_.get(_.first(sortedObjects),"id")}`)
      }
    }
  }

  onFilter = (filterId: number) => {
    let { activeFilters } = this.state
    const index = activeFilters.indexOf(filterId)
    if (index === -1) {
      _.remove(activeFilters, (id) => id !== 1)
      activeFilters.push(filterId)
    } else {
      activeFilters.splice(index, 1)
    }
    this.setState({ activeFilters })
  }

  onSort = (sortId: number) => {
    this.setState({ activeSort: sortId })
  }

  sortObjects = (): ClientPortfolio[] => {
    const { portfolios, search } = this.props
    const { activeFilters, activeSort } = this.state
    return portfolios
      .filter((portfolio: ClientPortfolio) => {
        if (search) {
          const name = (portfolio.name || "").toLocaleLowerCase().trim()
          if (!name.includes(this.props.search.toLowerCase().trim()))
            return false
        } else if (activeFilters.length > 0) {
          // Inverse as filters remove options
          return _.some(activeFilters, (id:number) => {
            const matchingFilter = _.find(this.state.pickerFilters, {id: id})
            return matchingFilter?.name === portfolio.relatedVehicle?.vehicle?.category?.value
          })

        }
        return true
      })
      .sort((portfolioA: ClientPortfolio, portfolioB: ClientPortfolio) => {
        const nameA = (portfolioA.name || "").toLocaleLowerCase().trim()
        const nameB = (portfolioB.name || "").toLocaleLowerCase().trim()
        switch (activeSort) {
          case 1:
            return nameA.localeCompare(nameB)
          case 2:
            return nameB.localeCompare(nameA)
          default:
            throw new Error(`unrecognized sort choice ${activeSort}`)
        }
      })
  }

  objectListItem = (portfolio: ClientPortfolio, idx: number) => {
    const category = _.get(portfolio, "relatedVehicle.vehicle.category.value")
    return (
      <ListGroupItem
        tag="a"
        key={idx}
        className={classnames({
          active: this.props.selectedPortfolio?.id === portfolio.id,
        })}
        onClick={() =>{
            this.props.history.push(`${this.props.match.url}/${portfolio.id}`)
          }
        }
      >
        <h5>
          {portfolio.clientSpecificDataCollectionFields?.nameOverride || portfolio.name}
        </h5>
        <dl>
          <dt>Vehicle Category:</dt>
          <dd>{category}</dd>
          <dt>Vehicle Name:</dt>
          <dd>{portfolio.relatedVehicle?.vehicle?.name}</dd>
        </dl>
      </ListGroupItem>
    )
  }

  emptyMessage() {
    return `There are no portfolios associated with this product.`
  }

  render() {
    const { search } = this.props
    const {
      pickerFilters,
      activeFilters,
      pickerSortOptions,
      activeSort,
    } = this.state
    const sortedObjects = this.sortObjects()
    return (
      <Picker
        filters={pickerFilters}
        activeFilters={activeFilters}
        sortOptions={pickerSortOptions}
        activeSort={activeSort}
        onFilter={this.onFilter}
        onSort={this.onSort}
        searching={search ? true : false}
      >
        {sortedObjects.length > 0 && sortedObjects.map((o, idx) => this.objectListItem(o, idx))}
        {sortedObjects.length === 0 &&
          <div className="p-3 text-gray-70">
            {this.emptyMessage()}
          </div>
        }
      </Picker>
    )
  }
}


export default ProductCharacteristicsClient
