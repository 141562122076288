import moment from "moment"
import React from "react"
import { FormGroup, Input } from "reactstrap"
import { DATE_API_FORMAT } from "../../../../../helpers/constant"
import {
  FeeCalculationCode,
  FeeReturnCode,
} from "../../../../../__generated__/graphql"
import { PercentInput } from "../../FeeFormComponents"
import { NumericInputData, OtherFeesGenericProps } from "../FeeRows"
import { HurdleCalculationOptions, HurdleReturnOptions } from "../getFeeData"

interface PrivateHurdleRowProps extends OtherFeesGenericProps {
  date: string
  returnTypeInput: {
    options: HurdleReturnOptions
    value: FeeReturnCode | ""
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
  calculationTypeInput: {
    options: HurdleCalculationOptions
    value: FeeCalculationCode | ""
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
  feeInput: NumericInputData<string>
}

export const PrivateHurdleRow: React.FC<PrivateHurdleRowProps> = ({
  label,
  editing,
  date,
  returnTypeInput,
  calculationTypeInput,
  feeInput,
}) => {
  const returnType = returnTypeInput.options.find(
    (item) => item.code === returnTypeInput.value
  )
  const returnTypeDisplay = returnType ? returnType.value : ""
  const calculationType = calculationTypeInput.options.find(
    (item) => item.code === calculationTypeInput.value
  )
  const calculationTypeDisplay = calculationType ? calculationType.value : ""
  return (
    <>
      <tr>
        <td>{label}</td>
        <td>
          {editing ? (
            <Input
              type="select"
              bsSize="sm"
              onChange={returnTypeInput.onChange}
              value={returnTypeInput.value}
            >
              <option value="">Select Return Type</option>
              {returnTypeInput.options.map((item) => (
                <option key={item.code} value={item.code}>
                  {item.value}
                </option>
              ))}
            </Input>
          ) : (
            <span>Return Type: {returnTypeDisplay}</span>
          )}
        </td>
        <td>
          <FormGroup>
            <div>
              <PercentInput
                disabled={!editing}
                value={+feeInput.value}
                onChange={feeInput.onChange}
              />
            </div>
          </FormGroup>
        </td>
        <td>{moment(date, DATE_API_FORMAT).format("MM/DD/YYYY")}</td>
      </tr>
      <tr>
        <td></td>
        <td>
          {editing ? (
            <Input
              type="select"
              bsSize="sm"
              onChange={calculationTypeInput.onChange}
              value={calculationTypeInput.value}
            >
              <option value="">Select Calculation Type</option>
              {calculationTypeInput.options.map((item) => (
                <option key={item.code} value={item.code}>
                  {item.value}
                </option>
              ))}
            </Input>
          ) : (
            <span>Calculation Type: {calculationTypeDisplay}</span>
          )}
        </td>
        <td></td>
        <td></td>
      </tr>
    </>
  )
}
