import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { filter, get } from "lodash"
import React, { useState } from "react"
import { Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { typeIdMapping } from "../../helpers/list"
import { ListDetailItemsFragment, useListQuickViewQuery } from "../../__generated__/graphql"
import { DebouncedSearchBar } from "../ui/Forms/SearchInput"
import PlaceHolder from "../ui/PlaceHolder"

interface ListQuickViewProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  listId: number
}

export const ListQuickView: React.FC<ListQuickViewProps> = ({
  modalOpen,
  setModalOpen,
  listId,
}) => {
  let [search, setSearch] = useState<string>("")
  const { loading, data, error } = useListQuickViewQuery({
    variables: {id: listId},
    skip: !modalOpen,
    errorPolicy: "all",
  })

  const closeModal = () => {
    setModalOpen(false)
  }

  if (error){
    console.error("Quick List Error: ", error)
    closeModal()
  }

  const getName = (container:ListDetailItemsFragment) => {
    const item = container.item
    if(!item){
      return ""
    }
    if ("orgName" in item){
      return item.orgName
    } else if ("planName" in item){
      return item.planName
    } else if ("product" in item){
      return item.product?.productName
    } else if ("glidePathName" in item){
      return item.glidePathName
    } else if ("vehicle" in item){
      return item.vehicle?.vehicleName
    } else if ("portfolioName" in item){
      return item.portfolioName
    }
    return ""
  }
  let filteredItems = filter(data?.list?.items || [], (item) => {
    if(!!search && search !== ""){
      return getName(item)?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    }
    return true
  }) as ListDetailItemsFragment[]

  return (
    <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        <div className="d-flex justify-content-between">
          <div>
            Items for {data?.list?.name || "List Name"}
          </div>
          <div onClick={() => closeModal()}>
            <FontAwesomeIcon
              icon="times"
              className="ml-auto"
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <DebouncedSearchBar
          setSearchTerm={(name) => setSearch(name)}
          idx={1}
          placeholder="Find item by name"
          shortProperty={"search"}
          property={"search"}
          editMode={true}
        />
        <div className="overflow-auto ml-1 mt-2 w-100">
          {!!loading && !data &&
            <PlaceHolder height={200} />
          }
          <ListGroup className="horizontal overflow-auto quick-view">
            {filteredItems.map((item, idx) => {
              let name = getName(item)
              let key = `${item.type}:${get(item, `item.${item.type && typeIdMapping[item.type]}`)}`
              if(name === ""){
                return (<React.Fragment key={key}></React.Fragment>)
              }

              return (
                <ListGroupItem className='p-2' key={key}>
                  {name}
                </ListGroupItem>
              )
            })}
          </ListGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setModalOpen(!modalOpen)}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  )
}