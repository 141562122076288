import classnames from 'classnames'
import { get, set, cloneDeep, unset, has} from 'lodash'
import React, { Component } from 'react'
import { Table, Row, Col} from 'reactstrap'
import iassign from 'immutable-assign'
import moment from 'moment'
import omitDeep from 'omit-deep-lodash'

import {  ManagerAssetsByVehicleQuery, PrivateEquityAssetsByCapitalType, PrivateEquityStrategyCode, ManagerPrivateEquityAssetsByCapitalTypeInput, CapitalTypeCode, ManagerType} from '../../../__generated__/graphql'
import { listQuarters } from "../../../helpers/helpers"
import { appDate } from '../../../Context/CalendarContext'
import { DATE_API_FORMAT, FormInputField } from '../../../helpers/constant'
import { FormInput } from '../../ui/Forms/FormInput'
import { GroupedAsset, GroupedAssets, AssetsByAssetClassTableProps, HistoricalDisplayValueType, HistoricalAssetsByAssetClassTableProps, HistoricalGroupedAssets } from '../ManagerAssetsVehicles'

const firstHistoricalDate = moment(appDate).subtract(5,"years")

const convertPEByCapitalCellToInput = (updatedCell:PrivateEquityAssetsByCapitalType):ManagerPrivateEquityAssetsByCapitalTypeInput => {
  let newInputCell = omitDeep(updatedCell, '__typename')
  set(newInputCell, 'strategy', newInputCell.strategy.code)
  set(newInputCell, 'capitalType', newInputCell.capitalType.code)
  unset(newInputCell, 'updatedDate')
  return newInputCell
}

export class PrivateEquityAssetsByCapitalTypeTable extends Component<AssetsByAssetClassTableProps, { currentState: GroupedAssets, initialState: GroupedAssets }> {
  constructor(props: any) {
    super(props)

    const assets:PrivateEquityAssetsByCapitalType[] = get(props.data, "assets.privateEquityAssetsByCapitalType", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:PrivateEquityAssetsByCapitalType) => {
      let strategyType = entry.strategy.code
      let capitalType = entry.capitalType.code

      if (strategyType && capitalType) {
        set(groupedData, [strategyType, capitalType], entry)
      }
    })

    this.state = {
      currentState: groupedData,
      initialState: groupedData
    }
  }

  static getDerivedStateFromProps(props: AssetsByAssetClassTableProps, state:{ currentState: GroupedAsset, initialState: GroupedAsset }) {
    const assets:PrivateEquityAssetsByCapitalType[] = get(props.data, "assets.privateEquityAssetsByCapitalType", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:PrivateEquityAssetsByCapitalType) => {
      let strategyType = entry.strategy.code
      let capitalType = entry.capitalType.code

      if (strategyType && capitalType) {
        set(groupedData, [strategyType, capitalType], entry)
      }
    })
    return {
      currentState: props.editMode ? state.currentState : groupedData,
      initialState: groupedData,
    }
  }

  handleInputChange = (
    value: any,
    property: string,
    strategyType: PrivateEquityStrategyCode,
    capitalType: CapitalTypeCode
  ) => {
    let updatedCell:PrivateEquityAssetsByCapitalType
    console.log(this.state.currentState, strategyType, get(this.state.currentState, [strategyType, capitalType]))
    let oldState = cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      selectedRow => {
        let row:GroupedAssets = cloneDeep(selectedRow)
        let cell:PrivateEquityAssetsByCapitalType
        let selectedCell = get(selectedRow, [strategyType, capitalType]) as PrivateEquityAssetsByCapitalType
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            strategy: {code: strategyType as PrivateEquityStrategyCode, __typename: "PrivateEquityStrategyLookup"},
            capitalType: {code: capitalType as CapitalTypeCode, __typename: "CapitalTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: this.props.searchDate,
            __typename: "PrivateEquityAssetsByCapitalType",
          }
        }

        set(cell, property, value)
        set(row, [strategyType, capitalType], cell)
        console.log({ cell })
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () => {
      this.props.onChange(convertPEByCapitalCellToInput(updatedCell),"PrivateEquityAssetsByCapitalType")
    })
  }




  render() {
    const { editMode, managerType} = this.props
    const rowOrder:{ code: PrivateEquityStrategyCode, display: string}[] = [
      { code: PrivateEquityStrategyCode.BUYOUT, display: 'Buyout'},
      { code: PrivateEquityStrategyCode.DISRES, display: 'Distressed/Restructuring' },
      { code: PrivateEquityStrategyCode.DIVER, display: 'Diversified' },
      { code: PrivateEquityStrategyCode.GROEQ, display: 'Growth Equity' },
      { code: PrivateEquityStrategyCode.SPSIT, display: 'Special Situations' },
      { code: PrivateEquityStrategyCode.VC, display: 'Venture Capital'},
      { code: PrivateEquityStrategyCode.PVENER, display: 'Private Energy and Mining'},
      { code: PrivateEquityStrategyCode.MEZZ, display: 'Mezzanine'},
      { code: PrivateEquityStrategyCode.DISTR, display: 'Distressed/Trading' },
      { code: PrivateEquityStrategyCode.COLEND, display: 'Senior Direct Corporate Lending' },
      { code: PrivateEquityStrategyCode.SPFIN, display: 'Specialty Finance/ABL' },
      { code: PrivateEquityStrategyCode.OPP, display: 'Opportunistic' }
    ]

    return(
      <Row>
        <Col md={8}>
          <div className="table-container">
          <Table hover className="table-bordered-internal">
            <thead>
              <tr className="table-title row-border-olive-100">
                <th className="border-bottom-0"></th>
                <th colSpan={2} className="py-2">Uninvested</th>
                <th className="boundary-left"></th>
                <th className="boundary-right"></th>
                <th colSpan={2} className="py-2">New Capital</th>
              </tr>
              <tr>
                <th>Asset Class</th>
                <th className="text-right"># Funds</th>
                <th className="text-right width-120">Net Assets $(M)</th>
                <th className="boundary-left"></th>
                <th className="boundary-right"></th>
                <th className="text-right"># Funds</th>
                <th className="text-right width-120">Net Assets $(M)</th>
              </tr>
            </thead>
            <tbody>
              {rowOrder.map((arr, idx) => {
                return(
                  <AssetClassRow
                    data={this.state.currentState[arr.code]}
                    row={idx}
                    key={idx}
                    editMode={editMode}
                    clientType={arr}
                    updateValue={(value:any, property:string, capitalType:CapitalTypeCode) => this.handleInputChange(value, property, arr.code, capitalType)}

                  />
                )
              })}
            </tbody>
          </Table>
        </div>
        </Col>
      </Row>

    )
  }
}

interface AssetClassRowProps {
  data: GroupedAsset
  row: number
  editMode: boolean
  clientType: {code: string, display: string}
  updateValue: (value:any, property:string, capitalType:CapitalTypeCode) => void
  managerType?: ManagerType
  historicalList?: boolean
}

interface CapitalTypeInputField extends FormInputField{
  capitalType?: CapitalTypeCode
}

const CapitalTypeInputList:CapitalTypeInputField[] = [
  { property: "numberOfAccounts", label: "", type: "number", capitalType: CapitalTypeCode.UNINVESTED },
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", capitalType: CapitalTypeCode.UNINVESTED},
  { property: "boundary", label: "", type: "boundary"},
  { property: "numberOfAccounts", label: "", type: "number", capitalType: CapitalTypeCode.NEWCAPITAL },
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", capitalType: CapitalTypeCode.NEWCAPITAL},
]

const AssetClassRow = ({data, row, editMode, clientType, updateValue, managerType, historicalList}: AssetClassRowProps) => {
  return (
    <tr key={`AssetsByVehicle-${row}`}>
      <td className="text-nowrap text-left">
        { clientType.display }
      </td>
      {
        CapitalTypeInputList.map(({property, label, type, subtype, capitalType}, idx) => {
          if(type == "boundary"){
            return (
              <React.Fragment key={idx}>
                <td className="boundary-left"></td>
                <td className="boundary-right"></td>
              </React.Fragment>
            )
          }

          let propertyVal, onChangeCallback
          propertyVal = get(data, [capitalType as string, property])
          onChangeCallback = (value:any) => capitalType ? updateValue(value, property, capitalType) : null

          return (
            <td key={idx} className="text-right">
              <FormInput
                property={property}
                displayName={label}
                type={type}
                subtype={subtype}
                idx={`AssetsByVehicle-${property}.${row}.${capitalType}`}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
              />
            </td>
          )
        })
      }
    </tr>
  )
}

interface HistoricalPrivateEquityAssetsByCapitalTypeTableState {
  currentState: HistoricalGroupedAssets
  initialState: HistoricalGroupedAssets
  displayValue: HistoricalDisplayValueType
  capitalType: CapitalTypeCode
  historicalDate: moment.Moment
}
export class HistoricalPrivateEquityAssetsByCapitalTypeTable extends Component<HistoricalAssetsByAssetClassTableProps, HistoricalPrivateEquityAssetsByCapitalTypeTableState> {

  constructor(props: any) {
    super(props)

    const assets:PrivateEquityAssetsByCapitalType[] = get(props.data, "assets.privateEquityAssetsByCapitalType", [])
    let groupedData:HistoricalGroupedAssets = {}

    assets.map((entry:PrivateEquityAssetsByCapitalType) => {
      let strategyType = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate
      let capitalType = entry.capitalType.code

      if (strategyType && capitalType) {
        set(groupedData, [capitalType, quarterEndDate, strategyType], entry)
      }
    })

    this.state = {
      currentState: groupedData,
      initialState: groupedData,
      displayValue: "assetsUnderManagement",
      capitalType: CapitalTypeCode.UNINVESTED,
      historicalDate: moment(firstHistoricalDate),
    }
  }

  static getDerivedStateFromProps(props: HistoricalAssetsByAssetClassTableProps, state:HistoricalPrivateEquityAssetsByCapitalTypeTableState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    const assets:PrivateEquityAssetsByCapitalType[] = get(props.data, "assets.privateEquityAssetsByCapitalType", [])
    let groupedData:HistoricalGroupedAssets = {}

    assets.map((entry:PrivateEquityAssetsByCapitalType) => {
      let strategyType = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate
      let capitalType = entry.capitalType.code

      if (strategyType && capitalType) {
        set(groupedData, [capitalType, quarterEndDate, strategyType], entry)
      }
    })
    return {
      currentState: resetDate && !props.editMode ? groupedData : state.currentState,
      initialState: resetDate ? groupedData : state.initialState,
      capitalType: state.capitalType,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
    }
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    quarterEndDate: string,
    capitalType: CapitalTypeCode,
    strategyType: PrivateEquityStrategyCode
  ) => {
    let updatedCell:PrivateEquityAssetsByCapitalType
    console.log(this.state.currentState, capitalType, get(this.state.currentState, [capitalType]))
    let oldState = cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      selectedRow => {
        let row:HistoricalGroupedAssets = cloneDeep(selectedRow)
        let cell:PrivateEquityAssetsByCapitalType
        let selectedCell = get(selectedRow, [capitalType, quarterEndDate, strategyType]) as PrivateEquityAssetsByCapitalType
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            strategy: {code: strategyType as PrivateEquityStrategyCode, __typename: "PrivateEquityStrategyLookup"},
            capitalType: {code: capitalType as CapitalTypeCode, __typename: "CapitalTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: quarterEndDate,
            __typename: "PrivateEquityAssetsByCapitalType",
          }
        }

        set(cell, property, value)
        set(row, [capitalType, quarterEndDate, strategyType], cell)
        console.log({ cell })
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () => {
      this.props.onChange(convertPEByCapitalCellToInput(updatedCell),"PrivateEquityAssetsByCapitalType")
    })
  }

  setCapitalType = (capitalType:CapitalTypeCode) => {
    this.setState({
      capitalType: capitalType
    })
  }

  setDisplayValue = (displayValue:HistoricalDisplayValueType) => {
    this.setState({
      displayValue: displayValue
    })
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  loadMore = (fetchMoreResult:ManagerAssetsByVehicleQuery) =>{
    const newInitialState = this.state.initialState
    const newCurrentState = cloneDeep(this.state.currentState)

    const newAssets:PrivateEquityAssetsByCapitalType[] = get(fetchMoreResult, "assets.privateEquityAssetsByCapitalType", [])

    newAssets.map((entry:PrivateEquityAssetsByCapitalType) => {
      let strategyType = entry.strategy.code
      let quarterEndDate = entry.quarterEndDate
      let capitalType = entry.capitalType.code

      if (strategyType && capitalType && !has(this.state.currentState, [capitalType, quarterEndDate, strategyType])) {
        set(newInitialState, [capitalType, quarterEndDate, strategyType], entry)
        set(newCurrentState, [capitalType, quarterEndDate, strategyType], entry)
      }
    })


    console.log({newCurrentState, newInitialState})
    this.setState({
      initialState: newInitialState,
      currentState: newCurrentState,
      historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
    })
  }

  render() {
    const { capitalType, displayValue} = this.state
    const headingOrder:{ code: PrivateEquityStrategyCode, display: string}[] = [
      { code: PrivateEquityStrategyCode.BUYOUT, display: 'Buyout'},
      { code: PrivateEquityStrategyCode.DISRES, display: 'Distressed/Restructuring' },
      { code: PrivateEquityStrategyCode.DIVER, display: 'Diversified' },
      { code: PrivateEquityStrategyCode.GROEQ, display: 'Growth Equity' },
      { code: PrivateEquityStrategyCode.SPSIT, display: 'Special Situations' },
      { code: PrivateEquityStrategyCode.VC, display: 'Venture Capital'},
      { code: PrivateEquityStrategyCode.PVENER, display: 'Private Energy and Mining'},
      { code: PrivateEquityStrategyCode.MEZZ, display: 'Mezzanine'},
      { code: PrivateEquityStrategyCode.DISTR, display: 'Distressed/Trading' },
      { code: PrivateEquityStrategyCode.COLEND, display: 'Senior Direct Corporate Lending' },
      { code: PrivateEquityStrategyCode.SPFIN, display: 'Specialty Finance/ABL' },
      { code: PrivateEquityStrategyCode.OPP, display: 'Opportunistic' }
    ]
    console.log({currentState: this.state.currentState, capitalType})
    const data = this.state.currentState[capitalType]
    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

    const heading = () =>{
      return(
      <div className="w-100 d-flex justify-content-between">
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-item",{ active: capitalType === "UNINVESTED" })} onClick={() => this.setCapitalType(CapitalTypeCode.UNINVESTED)}>Uninvested</li>
          <li className={classnames("horizontal-picker-item",{ active: capitalType === "NEWCAPITAL" })}  onClick={() => this.setCapitalType(CapitalTypeCode.NEWCAPITAL)}>New Capital</li>

        </ul>
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets ($M)</li>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
      </div>)
    }


    return (
      <>
        <div className="pane pane-table">
          <Table hover className="table-condensed">
            <thead>
              <tr>
                <td colSpan={1000}>{heading()}</td>
              </tr>
            </thead>
          </Table>
          <div className="table-container">
            <Table hover className="table-bordered-internal table-condensed">
              <thead>
                <tr>
                  <th>Date</th>
                  {headingOrder.map((heading) => {
                    return(<th key={heading.code}>{heading.display}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {allDates.map((date:string, row:number) => {
                  return (
                    <tr key={`PrivateEquityAssetsByCapitalType-${capitalType}-${displayValue}-${date}`} className="fadein">
                      <td className="nowrap">
                        {date}
                      </td>
                      {headingOrder.map((heading, idx) => {
                        let propertyVal = get(data, [date, heading.code,displayValue])
                        // console.log({data, row: get(data, [date, heading.code,displayValue])})
                        return(
                          <td key={`PrivateEquityAssetsByCapitalType-${capitalType}-${idx}`}>
                            <FormInput
                              property={displayValue}
                              displayName={""}
                              type={"number"}
                              subtype={displayValue == "assetsUnderManagement" ? "currency" : ""}
                              placeholder={""}
                              idx={`PrivateEquityAssetsByCapitalType-${capitalType}-${date}-${heading.code}`}
                              editMode={this.props.editMode}
                              propertyVal={propertyVal}
                              updateValue={(value:any) => this.handleInputChange(value, displayValue,date, capitalType, heading.code )}
                            />
                          </td>
                        )
                      })}
                    </tr>
                  )
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    )
  }
}

