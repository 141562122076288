import React from "react"

import {
  Row,
  Col,
  ListGroupItem,
  Button
} from "reactstrap"
import { compact, get } from "lodash"
import { FileIcon, defaultStyles } from "react-file-icon"
import { SearchFileResultFragment } from "../../../__generated__/graphql"

export const DocumentResult = (props:SearchFileResultFragment) => {
  const { fileid, fileName, managers, products, plans, vehicles, glidePaths, description, url, type, subType } = props
  let managerNames = managers ? compact(managers?.map(m => m?.name).filter(m => m)) : []
  let vehicleNames = vehicles ? compact(vehicles?.map(v => v?.vehicle?.name).filter(v => v)) : []
  let productNames = products ? compact(products?.map(p => p?.product?.name).filter(p => p)) : []
  let planNames = plans ? compact(plans?.map(p => p?.name).filter(p => p)) : []
  let glidePathNames = glidePaths ? compact(glidePaths?.map(gp => gp?.name).filter(gp => gp)) : []
  const nameList = managerNames.concat(productNames, vehicleNames, glidePathNames, planNames)
  const firstName = nameList.shift()

  let versions = props.versions ? props.versions.filter(v => v) : []
  versions = versions.sort( (a,b) => {
    let ax = a?.updated || 0
    let bx = b?.updated || 0

    return (new Date(ax).getTime()) - (new Date(bx).getTime())
  })
  const latestVersion = versions.slice(-1)[0]
  const firstVersion = versions[0]
  const authorName = `${firstVersion?.person?.firstName} ${firstVersion?.person?.lastName}`
  const extension = fileName?.split(".")?.pop() || ""
  const style = get(defaultStyles, extension)

  return (
    <ListGroupItem tag="a" href={`/documents/${fileid}`}>
      <div className="category document">
        <div className="category-text">
          <h6>Document</h6>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md="3">
            <h3>{ description || fileName }</h3>
            <p>{type?.value}/{subType?.value}</p>
          </Col>
          <Col md="9">
            <Row>
              <Col md="4">
                <h5>Associated to</h5>
                <p>{firstName}
                {nameList.length > 0 &&
                  <>
                    {" "}and {nameList.length} others
                  </>
                }
                </p>
              </Col>
              <Col md="2">
                <h5>Last Updated</h5>
                <p>{latestVersion?.updated}</p>
              </Col>
              <Col md="2">
                <h5>Author</h5>
                <p>{authorName}</p>
              </Col>
              <Col md="2">
                <div className="document-search-icon-container">
                  <FileIcon extension={extension} {...style}  />
                </div>
              </Col>
              <Col md="2">
                <Button color="light" size="sm" href={url} download={fileName}>Download</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroupItem>
  )
}