import { cloneDeep, get, isEmpty, isNull, isUndefined } from "lodash"
import React from "react"
import { convertLookupToString, excludePropertyArray, reShapeObject } from "../../../helpers/object"
import { ClientPortfolioDefinitionQuery, ClientPortfolioEmpowerRelationship, ClientPortfolioFidelityRelationship, ClientPortfolioUpdateFields, Maybe, UpdateClientPortfolioOverviewInput } from "../../../__generated__/graphql"

const reShapeOverviewInputActions = {
  "isComposite":{
    destinationPath: "isComposite",
    action: (a: string| boolean) =>!!(a === "true" || a === true)
  },
  "relatedVehicle.vehicle.id": {
    destinationPath: "relatedVehicle",
    // string to num
    action: (a: any) => {
      if (isEmpty(a) || isNull(a)) {
        return null
      } else {
        return a
      }
    },
  },
  "characteristicSource.code": {
    destinationPath: "characteristicsSource",
    // string to num
    action: (a: any) => {
      if (isEmpty(a) || isNull(a) || a === "") {
        return null
      } else {
        return a
      }
    },
  },
}

class ClientPortfolioInputParser {
  data?: ClientPortfolioDefinitionQuery
  private defaultOverviewInput = {
  }

  constructor(data?: ClientPortfolioDefinitionQuery) {
    this.data = (data || {}) as any
  }

  public getOverviewInput = () => {
    let newData = cloneDeep(this.data) as any
    // remove fields
    let propertyArrayToRemove = [
      // not editable
      "__typename",
      "id",
      "lists",
      "manager",
      "product",
      "plan",
      "bank",
      // Other Fields
      "memberOfComposites",
      "performanceTargetMap",
      "clientSpecificDataCollectionFields",
      "compositeMembers",
      "customCharacteristicsSource",
      "empowerRelationships",
      "fidelityRelationship",
      "dataCollection",
      "targetFootnotes",
      "footnote",
    ]

    let formattedData = reShapeObject(newData, reShapeOverviewInputActions) as any
    let {__typename, id, client, lists, manager, product, plan, bank, memberOfComposites, performanceTargetMap, clientSpecificDataCollectionFields, compositeMembers, customCharacteristicsSource, empowerRelationships, fidelityRelationship, dataCollection, targetFootnotes, footnote, customPerformance, assetClass, styleGroup, notes, feeSchedule, datedFees, ...overview} = formattedData
    let assetClassCode = assetClass.code
    return {assetClass: parseInt(assetClassCode || 1), styleGroup: styleGroup?.id, ...overview}
    // name: String
    // plan: Int
    // client: Int
    // manager: Int
    // assetClass: Int
    // currency: PortfolioCurrencyCode
    // relatedVehicle: String
    // inceptionDate: Date
    // serviceStartDate: Date
    // serviceEndDate: Date
    // endAssetDate: Date
    // isComposite: Boolean
    // isActive: Boolean
    // isTest: Boolean
    // discretionary: PortfolioDiscretionaryCode
    // type: PortfolioType
    // dataType: PortfolioDataType
    // mandateType: PortfolioMandateType
    // characteristicsSource: CharacteristicsSourceCode
    // performanceSource: PerformanceSourceCode
    // performanceType: ClientPerformanceTypeCode
    // styleGroup: String
    // groupPerformanceType: GroupPerformanceTypeCode
    // bank: Int
    // bankAccount: String
  }

  public getEmpowerRelationshipsInput = () => {
    let empowerRelationships = get(this.data, "empowerRelationships", null) as Maybe<ClientPortfolioEmpowerRelationship>
    if(isNull(empowerRelationships) || isUndefined(empowerRelationships)) {
      return null
    }else {
      let {__typename, ...rest} = empowerRelationships
      return rest
    }
  }

  public getFidelityRelationshipInput  = () => {
    let fidelityRelationship = get(this.data, "fidelityRelationship", {} as ClientPortfolioFidelityRelationship)
    if(isNull(fidelityRelationship)) {
      return null
    }else {
      let {__typename, ...rest} = fidelityRelationship
      let fidelityPlanCode = fidelityRelationship?.fidelityPlanCode
      let fidelitySkipTrialBalance = fidelityRelationship?.fidelitySkipTrialBalance
      // if fidelityPlanCode or fidelitySkipTrialBalance not provided, can't save anything about fidelityRelationship.
      if(isNull(fidelityPlanCode) || isUndefined(fidelityPlanCode) || isNull(fidelitySkipTrialBalance) || isUndefined(fidelitySkipTrialBalance)) {
        return null
      }
      return {...rest}
    }
  }

  public getNotesInput = () => {
    return (this.data as any )?.notes
  }

}
export const getClientPortfolioDefinitionInput = (data?: ClientPortfolioDefinitionQuery): ClientPortfolioUpdateFields => {
  let formattedData = new ClientPortfolioInputParser(data)
  let overview = formattedData.getOverviewInput()
  let fidelityRelationship = formattedData.getFidelityRelationshipInput()
  let empowerRelationships = formattedData.getEmpowerRelationshipsInput()
  let notes = formattedData.getNotesInput()
  return {
    overview,
    fidelityRelationship,
    empowerRelationships,
    notes,
   } as ClientPortfolioUpdateFields
}
