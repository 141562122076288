import { keys } from './object'

type NN<T> = NonNullable<T>

export class DeepPropertyAccess {
  public static get<T, P1 extends keyof NN<T>>(
    obj: T,
    prop1: P1
  ): NN<T>[P1] | undefined

  // tslint:disable:max-line-length
  public static get<T, P1 extends keyof NN<T>, P2 extends keyof NN<NN<T>[P1]>>(
    obj: T,
    prop1: P1,
    prop2: P2
  ): NN<NN<T>[P1]>[P2] | undefined

  public static get<
    T,
    P1 extends keyof NN<T>,
    P2 extends keyof NN<NN<T>[P1]>,
    P3 extends keyof NN<NN<NN<T>[P1]>[P2]>
  >(
    obj: T,
    prop1: P1,
    prop2: P2,
    prop3: P3
  ): NN<NN<NN<T>[P1]>[P2]>[P3] | undefined

  public static get<
    T,
    P1 extends keyof NN<T>,
    P2 extends keyof NN<NN<T>[P1]>,
    P3 extends keyof NN<NN<NN<T>[P1]>[P2]>,
    P4 extends keyof NN<NN<NN<NN<T>[P1]>[P2]>[P3]>
  >(
    obj: T,
    prop1: P1,
    prop2: P2,
    prop3: P3,
    prop4: P4
  ): NN<NN<NN<NN<T>[P1]>[P2]>[P3]>[P4] | undefined

  public static get<
    T,
    P1 extends keyof NN<T>,
    P2 extends keyof NN<NN<T>[P1]>,
    P3 extends keyof NN<NN<NN<T>[P1]>[P2]>,
    P4 extends keyof NN<NN<NN<NN<T>[P1]>[P2]>[P3]>,
    P5 extends keyof NN<NN<NN<NN<NN<T>[P1]>[P2]>[P3]>[P4]>
  >(
    obj: T,
    prop1: P1,
    prop2: P2,
    prop3: P3,
    prop4: P4,
    prop5: P5
  ): NN<NN<NN<NN<NN<T>[P1]>[P2]>[P3]>[P4]>[P5] | undefined

  // tslint:disable:max-line-length
  // public static get<
  //   T,
  //   P1 extends keyof NN<T>,
  //   P2 extends keyof NN<NN<T>[P1]>,
  //   P3 extends keyof NN<NN<NN<T>[P1]>[P2]>,
  //   P4 extends keyof NN<NN<NN<NN<T>[P1]>[P2]>[P3]>,
  //   P5 extends keyof NN<NN<NN<NN<NN<T>[P1]>[P2]>[P3]>[P4]>,
  //   P6 extends keyof NN<NN<NN<NN<NN<NN<T>[P1]>[P2]>[P3]>[P4]>[P5]>
  // >(
  //   obj: T,
  //   prop1: P1,
  //   prop2: P2,
  //   prop3: P3,
  //   prop4: P4,
  //   prop5: P5,
  //   prop6: P6
  // ): NN<NN<NN<NN<NN<NN<T>[P1]>[P2]>[P3]>[P4]>[P5]>[P6] | undefined
  /* tslint:enable:max-line-length */

  // the actual function to extract the property
  public static get(obj: any, ...props: string[]): any {
    return (
      obj &&
      props.reduce(
        (result, prop) => (result == null ? undefined : result[prop]),
        obj
      )
    )
  }
}

/* Hwo to use
import {DeepPropertyAccess}

const level_6 = {
  level_5: {
    level_4: {
      level_3: {
        level_2: {
          level_1: ['Hello']
        }
      }
    }
  }
};

const result = DeepPropertyAccess.get(level_6, 'level_5', 'level_4', 'level_3', 'level_2', 'level_1');
*/

/*
compare object difference in properties and values, show changedTypes crate/update/deleted, and changedValue(value in original/first object for updated and delete type, value in latter object for create property)
e.g. const wrapper = new deepDiffMapper()
 wrapper.map({a:1, b:2}, {a:[2], c:4})
 would return
 {
   a:{type: "update",data:1},
   b:{type: "delete",data:2},
   c:{type: "create",data:4}
 }
*/
export class deepDiffMapper {
  static VALUE_CREATED = "created"
  static VALUE_UPDATED = "updated"
  static VALUE_DELETED = "deleted"
  static VALUE_UNCHANGED = "unchanged"

  protected isFunction(obj: object | undefined) {
    if ("undefined" === typeof obj) {
      return false
    }
    return {}.toString.apply(obj) === "[object Function]"
  }

  protected isArray(obj: object) {
    return {}.toString.apply(obj) === "[object Array]"
  }

  protected isObject(obj: object) {
    return {}.toString.apply(obj) === "[object Object]"
  }

  protected isDate(obj: object) {
    return {}.toString.apply(obj) === "[object Date]"
  }

  protected isValue(obj: object | undefined) {
    if ("undefined" === typeof obj) {
      return false
    }
    return !this.isObject(obj) && !this.isArray(obj)
  }

  protected compareValues(value1: any, value2: any) {
    if (value1 === value2) {
      return deepDiffMapper.VALUE_UNCHANGED
    }
    if (
      this.isDate(value1) &&
      this.isDate(value2) &&
      value1.getTime() === value2.getTime()
    ) {
      return deepDiffMapper.VALUE_UNCHANGED
    }
    if ("undefined" == typeof value1) {
      return deepDiffMapper.VALUE_CREATED
    }
    if ("undefined" == typeof value2) {
      return deepDiffMapper.VALUE_DELETED
    }

    return deepDiffMapper.VALUE_UPDATED
  }

  public map(obj1: object | undefined, obj2: object | undefined) {
    if (this.isFunction(obj1) || this.isFunction(obj2)) {
      throw new Error("Invalid argument. Function given, object expected.")
    }
    if (this.isValue(obj1) || this.isValue(obj2)) {
      return {
        type: this.compareValues(obj1, obj2),
        data: obj1 === undefined ? obj2 : obj1
      }
    }

    var diff = {} as any
    if ("undefined" !== typeof obj1) {
      keys(obj1).forEach(key => {
        if (!this.isFunction(obj1[key])) {
          var value2 = undefined
          if ("undefined" != typeof obj2 && "undefined" != typeof obj2[key]) {
            value2 = obj2[key] as any
          }
          diff[key] = this.map(obj1[key], value2)
        }
      })
    }
    if ("undefined" !== typeof obj2) {
      keys(obj2).forEach(key => {
        if (this.isFunction(obj2[key]) || "undefined" != typeof diff[key]) {
        } else {
          diff[key] = this.map(undefined, obj2[key])
        }
      })
    }

    return diff
  }
}
