import { ConfigTypeUnion } from '../../../helpers/constant'
import { ClosedEndedCommonConfig } from './ClosedEndedPrivateEquity'
import { ClosedEndedProductSummaryConfig, defaultProductOverviewSummaryConfig } from './default'

const PrivateCreditConfig: ConfigTypeUnion = {
  controlRights: true,
  targetInvestmentStyle: true,
  targetGrossYield: true,
  targetOriginationFees: true
}

export const ClosedEndedPrivateCreditProductOverviewSummaryOverrideConfig = {
  closedEnded: {
    ...ClosedEndedProductSummaryConfig,
    ...ClosedEndedCommonConfig,
    fundFocus: true,
    targetTotalValuePaidIn: {
      gross: true,
      net: true
    }
  },
  privateCredit: PrivateCreditConfig,
  product: {
    ...defaultProductOverviewSummaryConfig,
    primaryGeographicRegion: true,
    hardCap: true
  }
}
