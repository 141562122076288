import React, { useState, useEffect } from "react"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DATE_API_FORMAT } from "../../../helpers/constant"
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Col,
  Row,
  Input,
  FormGroup,
  Label,
  Table,
} from "reactstrap"
import PlaceHolder from "../../ui/PlaceHolder"
import { formatCurrency, previousQuarterEnd } from "./helper"
import {
  VehicleFee as IVehicleFee,
  useVehicleFeesQuery,
} from "../../../__generated__/graphql"
import { FeeHistoryModal } from "./FeeHistoryModal"
import _ from "lodash"
import { CurrencyInput } from "./FeeFormComponents"
import { FormInput } from "../../ui/Forms/FormInput"

type VehicleFee = {
  date?: string
  minimumSize?: string
  minimumAnnualFee?: string
}

interface FeeMinimumProps {
  handleInputChange: (value: any, property: string) => void
  editing: boolean
  fundid: string
}

export const FeeMinimums: React.FC<FeeMinimumProps> = ({
  handleInputChange,
  editing,
  fundid,
}) => {
  const quarterEndDate = previousQuarterEnd
  const [searchDate, setSearchDate] = useState(quarterEndDate)
  const [fees, setFees] = useState<VehicleFee>({} as VehicleFee)
  const [showHistory, toggleHistoryModal] = useState(false)
  const { error, loading, data } = useVehicleFeesQuery({
    variables: {
      fundid: fundid,
    },
    fetchPolicy: "no-cache",
  })

  const toggle = () => toggleHistoryModal(!showHistory)

  useEffect(() => {
    const datedFees = data?.vehicle?.vehicle?.datedFees
    let fees: VehicleFee = {}
    if (datedFees) {
      fees = datedFees
        .sort((a, b) => {
          const dateA = a?.date
          const dateB = b?.date
          if (!dateA || !dateB) return 0
          if (dateA < dateB) return 1
          if (dateA === dateB) return 0
          return -1
        })
        .reduce(
          (prev, curr) => {
            if (!curr?.date) return prev
            if (
              (prev.minimumAnnualFee === null ||
                prev.minimumAnnualFee === undefined ||
                prev.minimumSize === "") &&
              curr?.minimumAnnualFee !== null &&
              curr?.minimumAnnualFee !== undefined
            ) {
              prev.minimumAnnualFee = curr.minimumAnnualFee?.toString()
              prev.date = curr.date
            }
            if (
              (prev.minimumSize === null ||
                prev.minimumSize === undefined ||
                prev.minimumSize === "") &&
              curr?.minimumSize !== null &&
              curr?.minimumSize !== undefined
            ) {
              prev.minimumSize = curr.minimumSize?.toString()
              prev.date = curr.date
            }
            return prev
          },
          {
            date: "",
            minimumSize: "",
            minimumAnnualFee: "",
          } as VehicleFee
        )
    }
    if (fees) {
      setFees(fees)
      if (fees.date) {
        setSearchDate(moment(fees.date).endOf("Q").format(DATE_API_FORMAT))
      } else {
        setSearchDate(quarterEndDate)
      }
    }
  }, [loading, data, quarterEndDate])

  const onChangeCallback = (value: VehicleFee) => {
    const date = previousQuarterEnd
    const minimumSize =
      value.minimumSize !== undefined
        ? parseFloat(value.minimumSize)
        : undefined
    const minimumAnnualFee =
      value.minimumAnnualFee !== undefined
        ? parseFloat(value.minimumAnnualFee)
        : undefined
    setSearchDate(previousQuarterEnd)
    handleInputChange(
      {
        date,
        minimumSize,
        minimumAnnualFee,
      },
      `vehicle.datedFees`
    )
  }

  const feeHistoryData = {
    headers: ["MINIMUMS", "FEE MINIMUM"],
    rows:
      data?.vehicle?.vehicle?.datedFees
        ?.filter((item) => {
          return (
            (item?.minimumSize !== null && item?.minimumSize !== undefined) ||
            (item?.minimumAnnualFee !== null &&
              item?.minimumAnnualFee !== undefined)
          )
        })
        .map((datedFee) => {
          const date = datedFee?.date
            ? moment(datedFee.date, DATE_API_FORMAT).format("MM/DD/YYYY")
            : null
          const formattedMinSize =
            datedFee?.minimumSize !== null &&
            datedFee?.minimumSize !== undefined
              ? formatCurrency(datedFee.minimumSize, editing)
              : null
          const formattedMinFee =
            datedFee?.minimumAnnualFee !== null &&
            datedFee?.minimumAnnualFee !== undefined
              ? formatCurrency(datedFee.minimumAnnualFee, editing)
              : null
          return {
            date,
            data: [
              ["Minimum size", formattedMinSize],
              ["Minimum Annual Fee", formattedMinFee],
            ],
          }
        }) || [],
  }

  return (
    <>
      <Card>
        {error ? (
          <div>{error.message}</div>
        ) : loading ? (
          <PlaceHolder />
        ) : data ? (
          <>
            <CardHeader className="with-input">
              <Row style={{ display: "table" }}>
                <Col className={"pl-1"} style={{ display: "table-cell" }}>
                  Minimums{" "}
                  {!editing && (
                    <FontAwesomeIcon
                      onClick={() => toggle()}
                      icon="history"
                      className="ml-2"
                    />
                  )}
                </Col>
                <Col className="form-inline">
                  <FormGroup>
                    <Label className={"text-nowrap"} for="datedFeesDate" sm={2}>
                      As of{" "}
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="date"
                        bsSize="sm"
                        disabled
                        name="datedFeesDate"
                        value={searchDate}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className={"fee-card"}>
              <div className="exportable-form" data-export-name={"Minimums"}>
                <Table className={"fee-table"}>
                  <tbody>
                    <tr>
                      <td>
                        <FormInput
                          property="minimum-size"
                          label="Minimum Size"
                          type="number"
                          subtype="currency"
                          idx={`1-minimum-size`}
                          editMode={editing}
                          propertyVal={fees.minimumSize}
                          subClasses={{
                            inputClasses: "text-left",
                            labelClasses: "col-sm-4 pl-3",
                            wrapperClasses: "pl-1"
                          }}
                          updateValue={(value) => {
                            _.set(fees, "minimumSize", value)
                            setFees(fees)
                            onChangeCallback(fees)
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormInput
                          property="minimum-annual-fee"
                          label="Minimum Annual Fee"
                          type="number"
                          subtype="currency"
                          idx={`1-minimum-annual-fee`}
                          editMode={editing}
                          propertyVal={fees.minimumAnnualFee}
                          subClasses={{
                            inputClasses: "text-left",
                            labelClasses: "col-sm-4 pl-3",
                            wrapperClasses: "pl-1"
                          }}
                          updateValue={(value) => {
                            _.set(fees, "minimumAnnualFee", value)
                            setFees(fees)
                            onChangeCallback(fees)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </>
        ) : (
          <div>data doesn't exist</div>
        )}
      </Card>
      <FeeHistoryModal
        isOpen={showHistory}
        title="Fee Minimums"
        toggle={toggle}
        data={feeHistoryData}
      ></FeeHistoryModal>
    </>
  )
}
