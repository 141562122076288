import { ConfigType } from '../../../helpers/constant'
import { defaultProductOverviewSummaryConfig } from './default'
import { OpenEndedEquityProductOverviewSummaryOverrideConfig } from './OpenEndedEquity'

export const OpenEndedMACProductOverviewSummaryOverrideConfig = {
  openEnded: {
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig.openEnded
  },
  product: {
    ...defaultProductOverviewSummaryConfig,
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig.product,
    targetBenchmarkAdd: true,
    blendedBenchmark: true
  }
}
