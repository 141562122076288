
interface IStringMap {
    [term: string]: string;
}

/**
 * Terms specific to Real Estate managers
 */
const realEstateTerms: IStringMap = {}

realEstateTerms["Strategy utilizes proprietary analytical tools for monitoring ESG risks and opportunities at the security level"] = 
    "Strategy utilizes proprietary analytical tools for monitoring ESG risks and opportunities at the asset-level";

realEstateTerms["Strategy evaluates and considers ESG metrics at the issuer level with subjective decision-making (i.e. individual judgment-based)"] =
    "Strategy evaluates and considers ESG metrics at the firm-level with subjective decision-making (i.e. individual judgment-based)";

realEstateTerms["Strategy evaluates and considers ESG metrics at the issuer level with objective decision-making (i.e. rules-based)"] =
    "Strategy evaluates and considers ESG metrics at the firm-level with objective decision-making (i.e. rules-based)";

realEstateTerms["Strategy monitors and reports on of achievement of ESG goals at the security level"] =
    "Strategy monitors and reports on of achievement of ESG goals at the asset-level";

realEstateTerms["Strategy engaged with an issuer about Environmental matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year"] =
    "Strategy engaged with a firm about Environmental matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year";

realEstateTerms["Strategy engaged with an issuer about Social matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year"] =
    "Strategy engaged with a firm about Social matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year";

realEstateTerms["Strategy engaged with an issuer about Governance matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year"] =
    "Strategy engaged with a firm about Governance matters (e.g., oral or written correspondence directly with issuer management personnel, investor relations, and/or board of directors) in the past year";


interface ITermDict {
    [assetId: number]: IStringMap;
}

/** 
 * Stores specific terminology used for some assets,
 * accessible by asset id.
 */
const TERMS_BY_ASSET: ITermDict = {
    9: {...realEstateTerms},
    125: {...realEstateTerms},
}

/**
 * Provided with an asset id, it returns a function which may be used to get
 * a given string formatted with the specific terminology used in the asset,
 * if the string belongs to the known terms in the map.
 */
export const assetTerminologyGetter = (assetId: number) => (term: string): string => {
    return (assetId && TERMS_BY_ASSET[assetId] && TERMS_BY_ASSET[assetId][term]) || term;
}
