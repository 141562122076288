import { ConfigType } from '../../../helpers/constant'
import { OpenEndedEquityProductOverviewSummaryOverrideConfig } from './OpenEndedEquity'

const OpenEndedHedgeFundProductOverviewSummaryConfig: ConfigType = {
  targetBenchmarkAdd: true,
  primaryGeographicRegion: true,
  totalCapacity: false
}

export const OpenEndedHedgeFundProductOverviewSummaryOverrideConfig = {
  product: {
    ...OpenEndedEquityProductOverviewSummaryOverrideConfig.product,
    ...OpenEndedHedgeFundProductOverviewSummaryConfig
  },
  hedgeFund: {
    primaryHedgeFundInvestmentStyle: true,
    primaryHedgeFundAssetType: true,
    directionalBias: true
  }
}
