export const fileSizeLimit = 25 // in Mb

export const checkFileSize = (file: File) => {
  let size = fileSizeLimit * 1024 * 1024
  return file.size <= size
}
/**
 * check file type, return true if matches mimeType
 * can be modified to filter down.
 * **/
export const checkMimeType = (file: File) => {
  console.log("filetype=", file.type)
  return file.type.match("text.*|image.*|application.*")
}

export const isFileEmpty = (file: File) => {
  return file.size === 0
}

export const fileToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let encoded =
        reader.result && reader.result.toString().replace(/^data:(.*,)?/, "")
      if (encoded && encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4))
      }
      resolve(encoded)
    }
    reader.onerror = error => reject(error)
  })
}
