import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import iassign from "immutable-assign"
import { cloneDeep, get, groupBy, set } from "lodash"
import React, { useEffect, useState } from "react"
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

import { FormInputField } from "../../helpers/constant"
import { ListDetailFragment, UpdateListFields, UpdateListInput, useUpdateListMutation, ListCategoryMapFragment } from "../../__generated__/graphql"
import EditButtons from "../ui/EditButtons"
import { FormInput } from "../ui/Forms/FormInput"
import { GetLookupDataToOptions } from "../ui/LookupOptions"


interface ListSettingsProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  categoryMap: ListCategoryMapFragment[]
  list: ListDetailFragment
  setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
}

const ListSettingsInputs: FormInputField[] = [
  {
    property: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    property: "id",
    label: "List ID",
    type: "text",
    readonly: true,
  },
  {
    property: "type",
    label: "List Type",
    type: "text",
    readonly: true,
  },
  {
    property: "active",
    label: "Status",
    type: "select",
  },
  {
    property: "category.code",
    label: "Category",
    type: "select",
    optionSource: "ListCategoryCode",
  },
  {
    property: "subCategory.code",
    label: "Subcategory",
    type: "select",
    optionSource: "ListSubCategoryCode",
  },
]

const ActiveBooleanOptions = GetLookupDataToOptions({
  data: [
    {
      code: "true",
      value: "Active",
    },
    {
      code: "false",
      value: "Inactive",
    },
  ],
  multiple: true,
})

export const ListSettingsModal: React.FC<ListSettingsProps> = ({
  modalOpen,
  setModalOpen,
  categoryMap,
  list,
  setEditedList,
}) => {

  const [saving, setSaving] = useState(false)
  const [modalList, setModalList] = useState(list)
  const [updateList] = useUpdateListMutation()

  const groupedCategories = groupBy(categoryMap, 'category')


  useEffect( () => {
    setModalList(list)
  }, [list])

  const closeModal = () => {
    setModalOpen(false)
    setModalList(list)
  }

  const handleChange = (property:string, value:any) => {
    setModalList((modalList) => {
      let newState = cloneDeep(modalList)
      if(property.endsWith(".code")){
        const objectFetch = property.substring(0, property.length - 5)
        if(get(newState, objectFetch) === null){
          set(newState, objectFetch, { code: null, value: null})
        }
      }
      let path = property.split(".")
      newState = iassign(
        newState,
        path,
        obj => {
          return value
        }
      )
      return newState
    })
  }

  const onSubmit = () => {
    setSaving(true)

    let patch = {
      name: modalList.name,
      active: modalList.active,
      category: modalList.category?.code,
      subCategory: modalList.subCategory?.code,
    } as UpdateListFields
    let input = {
      id: modalList.id,
      patch: patch
    } as UpdateListInput
    updateList({variables: {input: input}})
    .then(result => {
      setSaving(false)
      closeModal()
      setEditedList({
        ...list,
        name: modalList.name,
        active: modalList.active,
        category: modalList.category,
        subCategory: modalList.subCategory,
      })
    })
    .catch(err => {
      setSaving(false)
      console.error("Error updating list:", err.message)
    })

  }

  return (
    <>
      <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
        <ModalHeader className="fee-modal-header full-width-header">
          <div className="d-flex justify-content-between">
            <div>
              Settings
            </div>
            <div onClick={() => closeModal()}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
        <h5>Info</h5>
        <Row>
          <Col className="mx-3">
            {ListSettingsInputs.map(({property, label, type, subtype, placeholder, optionSource, readonly, required}, idx) => {
              let propertyVal, onChangeCallback, options, optionFilterRule
              propertyVal = get(modalList, property)
              onChangeCallback = (value:any) => {
                if(property === "active"){
                  handleChange(property, value === "true")
                } else if (property === "category.code") {
                  handleChange(property, value)
                  handleChange("subCategory.code", null)
                } else {
                  handleChange(property, value)
                }
              }
              if(property === "active"){
                options = ActiveBooleanOptions
                propertyVal = propertyVal.toString()
              } else if (property === "subCategory.code"){
                const optionFilterMapping = groupedCategories[modalList.category?.code || ""] || []
                optionFilterRule = optionFilterMapping.reduce((acc, { subCategory }) => {
                  return {...acc, [subCategory || ""]: true }
                }, {})
              }
              return(
                <FormInput
                  key={idx}
                  property={property}
                  displayName={label}
                  type={type}
                  subtype={subtype}
                  placeholder={placeholder}
                  idx={idx}
                  editMode={true}
                  propertyVal={propertyVal}
                  updateValue={onChangeCallback}
                  optionSource={optionSource}
                  readonly={readonly}
                  required={required}
                  options={options}
                  optionFilterRule={optionFilterRule}
                />
              )
            })}
          </Col>
        </Row>
        </ModalBody>
        <ModalFooter>
          <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => closeModal()} saving={saving} onSubmit={onSubmit} disableOnError={true}/>
        </ModalFooter>
      </Modal>
    </>
  )
}