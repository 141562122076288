import React, { useState, useEffect } from "react"
import {
  useVehicleCalculatedFeesQuery,
  MutualFund,
  ExchangeTradedFund,
  CollectiveInvestmentFund,
  CollectiveInvestmentFundComposite,
  ExchangeTradedFundFees,
  MutualFundFees,
  CollectiveInvestmentFee,
  CollectiveInvestmentCompositeFees,
  Maybe,
} from "../../../__generated__/graphql"
import moment from "moment"
import { DATE_API_FORMAT } from "../../../helpers/constant"
import {
  Card,
  CardHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardBody,
  Table,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FeeHistoryModal, FeeHistoryRow } from "./FeeHistoryModal"
import { formatPercent, previousQuarterEnd } from "./helper"

interface CalculatedFeeProps {
  fundid: string
}

interface CalculatedFees {
  cheapestFee?: number | null
  institutionalFee?: number | null
  retailFee?: number | null
  equalWeightedFee?: number | null
}

export const CalculatedFees: React.FC<CalculatedFeeProps> = ({ fundid }) => {
  const quarterEndDate = previousQuarterEnd
  const [feeDate, setFeeDate] = useState(quarterEndDate)
  const [showHistory, toggleHistoryModal] = useState(false)
  const [fees, setFees] = useState<CalculatedFees>({})

  const { error, loading, data } = useVehicleCalculatedFeesQuery({
    variables: {
      fundid,
    },
    fetchPolicy: "no-cache",
  })

  const getLatestFee = <
    T extends
      | Maybe<MutualFundFees>
      | Maybe<ExchangeTradedFundFees>
      | Maybe<CollectiveInvestmentFee>
      | Maybe<CollectiveInvestmentCompositeFees>
  >(
    datedFees: T[]
  ): T => {
    datedFees.sort((a: any, b: any) => {
      if (!a || !b) return 0
      const dateA = moment(a.date, DATE_API_FORMAT)
      const dateB = moment(b.date, DATE_API_FORMAT)
      if (dateA > dateB) return -1
      if (dateA < dateB) return 1
      else return 0
    })
    return datedFees[0]
  }

  const getFeeRowData = (
    fee:
      | Maybe<MutualFundFees>
      | Maybe<ExchangeTradedFundFees>
      | Maybe<CollectiveInvestmentFee>
      | Maybe<CollectiveInvestmentCompositeFees>
  ): FeeHistoryRow => {
    const data = []
    if ((fee as MutualFundFees)?.cheapestFee) {
      data.push([
        "Cheapest",
        formatPercent((fee as MutualFundFees).cheapestFee as number),
      ])
    }
    if ((fee as MutualFundFees)?.institutionalFee) {
      data.push([
        "Institutional",
        formatPercent((fee as MutualFundFees).institutionalFee as number),
      ])
    }
    if ((fee as MutualFundFees)?.retailFee) {
      data.push([
        "Retail",
        formatPercent((fee as MutualFundFees).retailFee as number),
      ])
    }
    if ((fee as MutualFundFees)?.equalWeightedFee) {
      data.push([
        "Equal Weighted",
        formatPercent((fee as MutualFundFees).equalWeightedFee as number),
      ])
    }
    return {
      date: fee?.date
        ? moment(fee.date, DATE_API_FORMAT).format("MM/DD/YYYY")
        : null,
      data,
    }
  }

  useEffect(() => {
    if (data?.vehicle) {
      const mutualFund = (data.vehicle as MutualFund).mutualFund
      const exchangeTradedFund = (data.vehicle as ExchangeTradedFund)
        .exchangeTradedFund
      const collectiveInvestmentFund = (data.vehicle as CollectiveInvestmentFund)
        .collectiveInvestmentFund
      const collectiveInvestmentFundComposite = (data.vehicle as CollectiveInvestmentFundComposite)
        .collectiveInvestmentFundComposite

      if (mutualFund) {
        const datedFees = mutualFund.datedFees
        if (datedFees) {
          const latestFees = getLatestFee<Maybe<MutualFundFees>>(datedFees)
          if (latestFees) {
            setFees(latestFees)
            setFeeDate(latestFees.date)
          }
        }
      } else if (exchangeTradedFund) {
        const datedFees = exchangeTradedFund.datedFees
        if (datedFees) {
          const latestFees = getLatestFee<Maybe<ExchangeTradedFundFees>>(
            datedFees
          )
          if (latestFees) {
            setFees(latestFees)
            setFeeDate(latestFees.date)
          }
        }
      } else if (collectiveInvestmentFund) {
        const datedFees = collectiveInvestmentFund.datedFees
        if (datedFees) {
          const latestFees = getLatestFee<Maybe<CollectiveInvestmentFee>>(
            datedFees
          )
          if (latestFees) {
            setFees(latestFees)
            setFeeDate(latestFees.date)
          }
        }
      } else if (collectiveInvestmentFundComposite) {
        const datedFees = collectiveInvestmentFundComposite.datedFees
        if (datedFees) {
          const latestFees = getLatestFee<
            Maybe<CollectiveInvestmentCompositeFees>
          >(datedFees)
          if (latestFees) {
            setFees(latestFees)
            setFeeDate(latestFees.date)
          }
        }
      }
    }
  }, [loading, data, quarterEndDate])

  const toggle = () => toggleHistoryModal(!showHistory)

  const getFeeHistoryRows = () => {
    if (!data?.vehicle) {
      return []
    }
    const mutualFund = (data.vehicle as MutualFund).mutualFund
    const exchangeTradedFund = (data.vehicle as ExchangeTradedFund)
      .exchangeTradedFund
    const collectiveInvestmentFund = (data.vehicle as CollectiveInvestmentFund)
      .collectiveInvestmentFund
    const collectiveInvestmentFundComposite = (data.vehicle as CollectiveInvestmentFundComposite)
      .collectiveInvestmentFundComposite

    if (mutualFund?.datedFees) {
      return mutualFund.datedFees.map(getFeeRowData)
    } else if (exchangeTradedFund?.datedFees) {
      return exchangeTradedFund.datedFees.map(getFeeRowData)
    } else if (collectiveInvestmentFund?.datedFees) {
      return collectiveInvestmentFund.datedFees.map(getFeeRowData)
    } else if (collectiveInvestmentFundComposite?.datedFees) {
      return collectiveInvestmentFundComposite.datedFees.map(getFeeRowData)
    } else return []
  }

  const feeHistoryData = {
    headers: ["FEE", "MANAGEMENT FEE"],
    rows: getFeeHistoryRows(),
  }

  const { cheapestFee, institutionalFee, retailFee, equalWeightedFee } = fees
  return (
    <>
      <Card>
        <CardHeader className="with-input">
          <Row style={{ display: "table" }}>
            <Col className={"pl-1"} style={{ display: "table-cell" }}>
              Calculated using fees on this page{" "}
              <FontAwesomeIcon
                onClick={toggle}
                icon="history"
                className="ml-2"
              />
            </Col>
            <Col>
              <Col className="form-inline">
                <FormGroup>
                  <Label className={"text-nowrap"} for="datedFeesDate" sm={2}>As of </Label>
                  <Col sm={10}>
                    <Input
                      type="date"
                      bsSize="sm"
                      disabled
                      name="datedFeesDate"
                      value={feeDate}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className={"fee-card"}>
          <Table className={"fee-table"}>
            <tbody>
              <tr>
                <td>Cheapest</td>
                <td>{cheapestFee && formatPercent(cheapestFee)}</td>
              </tr>
              <tr>
                <td>Institutional</td>
                <td>{institutionalFee && formatPercent(institutionalFee)}</td>
              </tr>
              <tr>
                <td>Retail</td>
                <td>{retailFee && formatPercent(retailFee)}</td>
              </tr>
              <tr>
                <td>Equal Weighted</td>
                <td>{equalWeightedFee && formatPercent(equalWeightedFee)}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <FeeHistoryModal
        isOpen={showHistory}
        title="Fee Minimums"
        toggle={toggle}
        data={feeHistoryData}
      />
    </>
  )
}
