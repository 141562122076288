import { ConfigTypeUnion } from '../../../helpers/constant'
import { ClosedEndedCommonConfig } from './ClosedEndedPrivateEquity'
import {
  ClosedEndedProductSummaryConfig,
  defaultProductOverviewSummaryConfig,
  RealAssetsProductSummaryConfig
} from './default'

const ClosedEndedRealAssetsConfig: ConfigTypeUnion = {
  controlRights: false,
  materialChanges: true,
  irrTarget: { gross: true, net: true },
  targetTotalValuePaidIn: { gross: true, net: true },
}

export const ClosedEndedRealAssetsProductOverviewSummaryOverrideConfig = {
  closedEnded: {
    ...ClosedEndedProductSummaryConfig,
    ...ClosedEndedCommonConfig,
    ...ClosedEndedRealAssetsConfig
  },
  realAssets: {
    ...RealAssetsProductSummaryConfig,
    debtMaturitySchedule: true
  },
  product: {
    ...defaultProductOverviewSummaryConfig,
    primaryGeographicRegion: true,
    hardCap: true,
    incomeReturnTarget: true
  }
}
