import moment from "moment"
import React from "react"
import { DATE_API_FORMAT, DATE_TIME_API_FORMAT } from "../../helpers/constant"
import {
  FieldControls,
  ResearchProductsPublicEquityDocument,
  ResearchProductsPublicFixedIncomeDocument,
  ResearchProductsPrivateMarketDocument,
  ResearchProductsHedgeFundDivsDocument,
  ProductFilters,
  ProductInputField,
} from "../../__generated__/graphql"
import {
  ResearchProductHedgeFundDivsColumnDef,
  ResearchProductPrivateMarketColumnDef,
  ResearchProductPublicEquityColumnDef,
  ResearchProductPublicFixedIncomeColumnDef,
} from "./ResearchProductsColumnDefs"

/**
 * 1.order matters
 * 2. all functions need to be edited if new option is added.
 **/
export enum ResearchProductStrategyTypeSearchType {
  /** Public Equity */
  _1 = "_1",
  /** Public Fixed Income */
  _2 = "_2",
  /** Private Markets */
  _3 = "_3",
  /** Hedge Funds & Diversifiers */
  _4 = "_4",
  /** placeholder shouldn't show anywhere */
  _0 = "_0",
}

export const ResearchProductStrategyTypeScriptOrder: ResearchProductStrategyTypeSearchType[] = [
  ResearchProductStrategyTypeSearchType._1,
  ResearchProductStrategyTypeSearchType._2,
  ResearchProductStrategyTypeSearchType._3,
  ResearchProductStrategyTypeSearchType._4,
]

export const ResearchProductStrategyTypeColorSetting: { [type in ResearchProductStrategyTypeSearchType]: string } = {
  _1: "research-product-type-1",
  _2: "research-product-type-2",
  _3: "research-product-type-3",
  _4: "research-product-type-4",
  _0: "research-product-type-default",
}

/* map to asset class parent id*/
export const ResearchProductStrategyTypeUrlMapping: {
  [type in ResearchProductStrategyTypeSearchType]: { url: string; criteria: string[] }
} = {
  _1: {
    url: "_1",
    // domestic broad equity, int'l broad equity, domestic balanced, global equity broad
    criteria: ["1", "11", "16", "24"],
  },
  _2: {
    url: "_2",
    // domestic broad fixed income, cash equivalents, int'l broad fixed inc, stable value.
    criteria: ["4", "8", "12", "73"],
  },
  _3: {
    url: "_3",
    // real assets, private equity, diversified real assets, private debt
    criteria: ["9", "57", "125", "146"],
  },
  _4: {
    url: "_4",
    // other alternatives, hedge funds, MAC
    criteria: ["15", "45", "140"],
  },
  _0: {
    url: "_0",
    criteria: [],
  },
}

export const ResearchProductStrategyTypeScript: {
  [type in ResearchProductStrategyTypeSearchType]: { title: string; description: string }
} = {
  _1: {
    title: "Public Equity",
    description:
      "Equity strategies that are invested in common stocks, which are traded on national financial exchanges and valued daily. Public Equity products include Domestic Equity, International Equity, Global Equity and Domestic Balanced funds. Evergreen access to strategy via separate account, commingled/pooled funds, and/or mutual funds. Use the column filters on the next screen to narrow down and refine the list of products.",
  },
  _2: {
    title: "Public Fixed Income",
    description:
      "Fixed income strategies that are invested in debt securities, which are mainly trade over the counter and not on financial exchanges. Public Fixed Income products include Domestic Fixed Income, International Fixed Income, Stable Value and Cash Equivalents funds. Evergreen access to strategy via separate account, commingled/pooled funds, and/or mutual funds. Use the column filters on the next screen to narrow down and refine the list of products.",
  },
  _3: {
    title: "Private Markets",
    description:
      "Equity and debt strategies invest in privately held companies that generally do not trade publicly and have fewer regulatory reporting requirements. Private Markets products include Real Estate, Private Equity, Diversified Real Assets and Private Debt funds. Access to strategy via locked-up fund structures and separate account. Use the column filters on the next screen to narrow down and refine the list of products.",
  },
  _4: {
    title: "Hedge Funds & Diversifiers",
    description:
      "Alternative investment solutions offering unique risk and reward opportunities for diversifying a portfolio. Hedge Funds & Diversifiers products include Hedge Funds, Diversified Multi Asset and Other Alternative funds. Use the column filters on the next screen to narrow down and refine the list of products.",
  },
  _0: {
    title: "Default",
    description: "Default",
  },
}

const DocumentType = [
  ResearchProductsPublicEquityDocument,
  ResearchProductsPublicFixedIncomeDocument,
  ResearchProductsPrivateMarketDocument,
  ResearchProductsHedgeFundDivsDocument,
] as const

type DocumentType = typeof DocumentType[number]

const PublicEquityDefaultFilters: ProductFilters = {
  controls: [
    {
      /**
       * @ASSET_CLASS_ID works on AssetClass.parent.id
       * https://callanllc.atlassian.net/browse/CAL-2507?focusedCommentId=73322
       */
      field: "ASSET_CLASS_ID" as ProductInputField,
      controls: {
        _in: ResearchProductStrategyTypeUrlMapping[ResearchProductStrategyTypeSearchType._1].criteria,
      },
    },
    // // for test only
    // {
    //   field: "ID" as ProductInputField,
    //   controls: {
    //     // gte: '165' // lastActivity, numberOfClientsInvested
    //     // gte: '1098' // opinion
    //     gte: "1098", // trailing yearly returns dateToDateEnd: "2022-06-30"
    //   },
    // },
  ],
  limit: 100,
}

const PublicFixedIncomeDefaultFilters: ProductFilters = {
  controls: [
    {
      /**
       * @ASSET_CLASS_ID works on AssetClass.parent.id
       * https://callanllc.atlassian.net/browse/CAL-2507?focusedCommentId=73322
       */
      field: "ASSET_CLASS_ID" as ProductInputField,
      controls: {
        _in: ResearchProductStrategyTypeUrlMapping[ResearchProductStrategyTypeSearchType._2].criteria,
      },
    },
    // // for test only
    // {
    //   field: "ID" as ProductInputField,
    //   controls: {
    //     gte: "2367", // excess return , tracking error.
    //   },
    // },
  ],
  limit: 100,
}

const PrivateMarketsDefaultFilters: ProductFilters = {
  controls: [
    {
      /**
       * @ASSET_CLASS_ID works on AssetClass.parent.id
       * https://callanllc.atlassian.net/browse/CAL-2507?focusedCommentId=73322
       */
      field: "ASSET_CLASS_ID" as ProductInputField,
      controls: {
        _in: ResearchProductStrategyTypeUrlMapping[ResearchProductStrategyTypeSearchType._3].criteria,
      },
    },
    // // for test only
    // {
    //   field: "ID" as ProductInputField,
    //   controls: {
    //     // gte: '42220', // fund size.
    //     gte: "44820", // committed capital.
    //     // gte: '44876' // product structure..
    //   },
    // },
  ],
  limit: 100,
}

const HedgeFundDivsDefaultFilters: ProductFilters = {
  controls: [
    {
      /**
       * @ASSET_CLASS_ID works on AssetClass.parent.id
       * https://callanllc.atlassian.net/browse/CAL-2507?focusedCommentId=73322
       */
      field: "ASSET_CLASS_ID" as ProductInputField,
      controls: {
        _in: ResearchProductStrategyTypeUrlMapping[ResearchProductStrategyTypeSearchType._4].criteria,
      },
    },
  ],
  limit: 100,
}

// open RealAsset 26890

export const ResearchProductStrategyTypeColumnDefsMapping: {
  [type in ResearchProductStrategyTypeSearchType]: {
    colDef: (assetClasses: any[], viewOpinion?: boolean, viewClientExposure?: boolean) => {}[]
    document: DocumentType
    filters: ProductFilters
  }
} = {
  _1: {
    colDef: (assetClasses: any[], viewOpinion?: boolean, viewClientExposure?: boolean) =>
      ResearchProductPublicEquityColumnDef(assetClasses, viewOpinion, viewClientExposure),
    document: ResearchProductsPublicEquityDocument,
    filters: PublicEquityDefaultFilters,
  },
  _2: {
    colDef: (assetClasses: any[], viewOpinion?: boolean, viewClientExposure?: boolean) =>
      ResearchProductPublicFixedIncomeColumnDef(assetClasses, viewOpinion, viewClientExposure),
    document: ResearchProductsPublicFixedIncomeDocument,
    filters: PublicFixedIncomeDefaultFilters,
  },
  _3: {
    colDef: (assetClasses: any[], viewOpinion?: boolean, viewClientExposure?: boolean) =>
      ResearchProductPrivateMarketColumnDef(assetClasses, viewOpinion, viewClientExposure),
    document: ResearchProductsPrivateMarketDocument,
    filters: PrivateMarketsDefaultFilters,
  },
  _4: {
    colDef: (assetClasses: any[], viewOpinion?: boolean, viewClientExposure?: boolean) =>
      ResearchProductHedgeFundDivsColumnDef(assetClasses, viewOpinion, viewClientExposure),
    document: ResearchProductsHedgeFundDivsDocument,
    filters: HedgeFundDivsDefaultFilters,
  },
  _0: {
    colDef: (assetClasses: any[], viewOpinion?: boolean, viewClientExposure?: boolean) =>
      ResearchProductPublicEquityColumnDef(assetClasses, viewOpinion, viewClientExposure),
    document: ResearchProductsPublicEquityDocument,
    filters: PublicEquityDefaultFilters,
  },
}

export type CurrentProductInputField =
  | ProductInputField.MANAGER
  | ProductInputField.NAME
  | ProductInputField.ID
  | ProductInputField.STRATEGY
  | ProductInputField.STRATEGY_ID
  | ProductInputField.ASSET_CLASS
  | ProductInputField.ASSET_CLASS_ID
  | ProductInputField.BENCHMARK
  | ProductInputField.AUM
  | ProductInputField.HAS_COMMINGLED
  | ProductInputField.HAS_MF
  | ProductInputField.QTR_RETURN
  | ProductInputField.YEARLY_RETURN
  | ProductInputField.ACTIVE_PASSIVE
  | ProductInputField.LAST_INTERACTION
  | ProductInputField.NUM_CLIENT_EXPOSURES
  | ProductInputField.OVERALL_STOPLIGHT_STATUS
  | ProductInputField.INACTIVE
  | ProductInputField.PRODUCT_STRUCTURE_CODE
  | ProductInputField.CAPITAL_STRUCTURE_CODE
  | ProductInputField.FUNDRAISING_STATUS
  | ProductInputField.VINTAGE_YEAR
  | ProductInputField.FUND_SIZE
  | ProductInputField.EXCESS_RETURN_MIN
  | ProductInputField.EXCESS_RETURN_MAX
  | ProductInputField.TRACKING_ERROR_MIN
  | ProductInputField.TRACKING_ERROR_MAX

export const FilterModelRiskTargetMapping: { [key: string]: ProductInputField[] } = {
  "openEnded.riskStatisticsTargets.excessReturn": [
    ProductInputField.EXCESS_RETURN_MIN,
    ProductInputField.EXCESS_RETURN_MAX,
  ],
  "openEnded.riskStatisticsTargets.trackingError": [
    ProductInputField.TRACKING_ERROR_MIN,
    ProductInputField.TRACKING_ERROR_MAX,
  ],
}

export type ProductInputRiskTargetFieldKeys = Extract<CurrentProductInputField, ProductInputField.EXCESS_RETURN_MIN | ProductInputField.EXCESS_RETURN_MAX | ProductInputField.TRACKING_ERROR_MIN | ProductInputField.TRACKING_ERROR_MAX>

export const FieldModelRiskTargetFilterIndexMapping: { [key in ProductInputRiskTargetFieldKeys]: number } = {
  [ProductInputField.EXCESS_RETURN_MIN]: 0,
  [ProductInputField.EXCESS_RETURN_MAX]: 1,
  [ProductInputField.TRACKING_ERROR_MIN]: 0,
  [ProductInputField.TRACKING_ERROR_MAX]: 1,
}

export const FilterModelFieldMapping: { [key: string]: ProductInputField } = {
  // basic info
  "product.manager.name": ProductInputField.MANAGER,
  "product.name": ProductInputField.NAME,
  "product.id": ProductInputField.ID,
  "product.assetClass.parent": ProductInputField.ASSET_CLASS,
  "product.assetClass.parent.value": ProductInputField.ASSET_CLASS,
  "product.assetClass.value": ProductInputField.STRATEGY,

  "product.assetClass.parent.code": ProductInputField.ASSET_CLASS_ID,
  "product.assetClass.code": ProductInputField.STRATEGY_ID,
  // other shared

  // PUBLIC EQUITY ONLY
  "product.primaryBenchmark.name": ProductInputField.BENCHMARK,
  "product.latestAum.aum": ProductInputField.AUM,
  "product.hasCommingled": ProductInputField.HAS_COMMINGLED,
  "product.hasMutualFund": ProductInputField.HAS_MF,
  "product.quarterly": ProductInputField.QTR_RETURN,
  "product.yearly": ProductInputField.YEARLY_RETURN,
  "product.activePassive": ProductInputField.ACTIVE_PASSIVE,
  "product.lastActivity": ProductInputField.LAST_INTERACTION,
  "product.numberOfClientsInvested": ProductInputField.NUM_CLIENT_EXPOSURES,
  "product.writeUps.opinions": ProductInputField.OVERALL_STOPLIGHT_STATUS,
  "product.inactive": ProductInputField.INACTIVE,
  // FIXED INCOME
  // "openEnded.riskStatisticsTargets.excessReturn", EXCESS_RETURN_MIN EXCESS_RETURN_MAX
  // "openEnded.riskStatisticsTargets.trackingError", TRACKING_ERROR_MIN, TRACKING_ERROR_MAX

  // PRIVATE MARKET
  "product.structure.code": ProductInputField.PRODUCT_STRUCTURE_CODE,
  "product.capitalStructure": ProductInputField.CAPITAL_STRUCTURE_CODE,
  "product.fundraisingStatus": ProductInputField.FUNDRAISING_STATUS,
  "closedEnded.vintageYearFirstCashflow": ProductInputField.VINTAGE_YEAR,
  "closedEnded.fundRaising": ProductInputField.FUND_SIZE,

  // HEDGE FUND
}

export type FieldControlType = "int" | "string" | "boolean" | "date" | "float" | "numeric_enum" | "enum"| "multi"

const convertNumericEnumValueToNumber = (value: string) => {
  return parseInt(value.replace("_", ""), 10)
}

// For ResearchProducts page only.
export const convertFieldControlType = (value: string, type: FieldControlType) => {
  switch (type) {
    // fix for ag-grid, 
    case "int":
    case "multi":
    case "float":
      return value
    case "boolean":
      return value === "true"
    case "date":
      // ag-grid needs date in this format
      return moment(value, DATE_API_FORMAT).format(DATE_TIME_API_FORMAT)
    case "string":
      return value
    case "numeric_enum":
      return convertNumericEnumValueToNumber(value)
    default:
      throw new Error(`Unknown field control type: ${type}`)
  }
}


// numberToControl
const numberFilterToFieldControlsMapping: { [key: string]: keyof FieldControls } = {
  notEqual: "neq",
  equals: "eq",
  lessThan: "lt",
  greaterThan: "gt",
  lessThanOrEqual: "lte",
  greaterThanOrEqual: "gte",
  // "inRange": filter: 1, filterTo: 3
}
