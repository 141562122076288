import classnames from 'classnames'
import _, { isEmpty } from 'lodash'
import React, { Component, useState, useContext, useRef, RefObject, useEffect } from 'react'
import { Table, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import iassign from 'immutable-assign'
import moment from 'moment'
import numbro from 'numbro'
import { useHistory } from 'react-router-dom'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import { CalendarContext, appDate } from '../../Context/CalendarContext'
import { useProductAssetsByRegionQuery, AssetsByRegion, ProductAssetsByRegionQuery, useGetLookupQuery, GetLookupQuery, useUpdateProductAssetsByRegionSingleMutation, useUpdateProductAssetsByRegionHistoricAssetsMutation, useUpdateProductAssetsByRegionHistoricAccountsMutation, RegionType, ProductAssetsByRegionHistoricalAccountRow, ProductAssetsByRegionHistoricalAssetRow, ProductAssetsByRegionSingleFields, ProductAssetsByRegionHistoricalAssetFields, ProductAssetsByRegionHistoricalAccountFields, UpdateProductAssetsByRegionHistoricalAssetInput, OriginCode } from '../../__generated__/graphql'
import { CalendarPicker } from '../CalendarPicker'
import { WorldMap } from '../Shared/WorldMap'
import PlaceHolder from '../ui/PlaceHolder'
import { FormInputField, DATE_API_FORMAT } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import { listQuarters } from "../../helpers/helpers"
import { handleCountryIndent } from '../Org/ManagerAssetsLocation'
import exportTables from '../../helpers/exportTable'

export interface GroupedAssets {
  [key: string]: GroupedAsset
}

export interface GroupedAsset {
  [key: string]: AssetsByRegion
}


interface ResultProps {
  setSearchDate: (searchDate:string) => void
  data: ProductAssetsByRegionQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  regionData: GetLookupQuery
  onChange: (newState:ProductAssetsByRegionQuery) => void
}


interface HistoricResultProps {
  setSearchDate: (searchDate:string) => void
  data: ProductAssetsByRegionQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  onChange: (updateType: HistoricUpdateRow, newState:GroupedAssets) => void
  fetchMore: any
  ref: RefObject<HistoricResult>
}

type HistoricUpdateRowMap = {
  numberOfAccounts: ProductAssetsByRegionHistoricalAccountRow,
  assetsUnderManagement: ProductAssetsByRegionHistoricalAssetRow
}
export type HistoricUpdateRow = keyof HistoricUpdateRowMap


type HistoricUpdateFieldsMap = {
  numberOfAccounts: ProductAssetsByRegionHistoricalAccountFields,
  assetsUnderManagement: ProductAssetsByRegionHistoricalAssetFields
}

export interface stateHistoricDiffType<T extends HistoricUpdateRow> {
  updateType: T
  rows: HistoricUpdateRowMap[T][]
}

interface idProps {
  productId: number
  auth: Auth
}

interface AssetClassInputField extends FormInputField{
  assetClass?: string
}

const locationOrder:{code:RegionType, value:string}[] = [
  {code: "USA" as RegionType, value: "United States"},
  {code: "CAN" as RegionType, value: "Canada"},
  {code: "UK" as RegionType, value: "United Kingdom"},
  {code: "JPN" as RegionType, value: "Japan"},
  {code: "AUL" as RegionType, value: "Australia"},
  {code: "HK" as RegionType, value: "Hong Kong"},
  {code: "SIN" as RegionType, value: "Singapore"},
  {code: "OAXJP" as RegionType, value: "Other Asia ex-Japan"},
  {code: "AME" as RegionType, value: "Africa/Middle East"},
  {code: "LA" as RegionType, value: "Latin America"},
  {code: "OTH" as RegionType, value: "Other"},
  {code: "EXUK" as RegionType, value: "Europe ex-UK"},
  {code: "DEN" as RegionType, value: "Denmark"},
  {code: "EEU" as RegionType, value: "Eastern Europe"},
  {code: "FIN" as RegionType, value: "Finland"},
  {code: "FRA" as RegionType, value: "France"},
  {code: "GER" as RegionType, value: "Germany"},
  {code: "ITA" as RegionType, value: "Italy"},
  {code: "NET" as RegionType, value: "Netherlands"},
  {code: "NOR" as RegionType, value: "Norway"},
  {code: "SPA" as RegionType, value: "Spain"},
  {code: "SWE" as RegionType, value: "Sweden"},
  {code: "SWI" as RegionType, value: "Switzerland"},
  {code: "OEU" as RegionType, value: "Other Europe"},
]

const tooltipMapping = {
  "OAXJP": "OtherAsia",
  "AME": "AfricaMiddleEast",
  "LA": "LatinAmerica",
  "EXUK": "EuropeExUk",
  "OEU": "OtherEurope",
}

const orangeDotMapping = {
  "EXUK": "EuropeExUk",
}

const firstHistoricalDate = moment(appDate).subtract(5,"years")

const defaultHistoricDiff: stateHistoricDiffType<HistoricUpdateRow> = {
  updateType: 'assetsUnderManagement' as HistoricUpdateRow,
  rows: [] as ProductAssetsByRegionHistoricalAssetRow[]
}

const ProductAssetsLocation: React.FC<idProps> = ({ productId, auth }: idProps) => {
  const context = useContext(CalendarContext)
  const history = useHistory()
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [screen, setScreen] = useState(context.period === "historical" ? "historical" :"single")
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const resultRef = useRef<Result>(null)
  const historicalResultRef = useRef<HistoricResult>(null)

  const [updateProductAssetsByRegion] = useUpdateProductAssetsByRegionSingleMutation()
  const [updateProductAssetsByRegionHistoricAssets] = useUpdateProductAssetsByRegionHistoricAssetsMutation()
  const [updateProductAssetsByRegionHistoricAccounts] = useUpdateProductAssetsByRegionHistoricAccountsMutation()

  let { data, loading, error, fetchMore } =
  useProductAssetsByRegionQuery({
    variables: {
      id: productId,
      startDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT),
      endDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT)
    },
    // partialRefetch: true,
    fetchPolicy: 'network-only'
  })

  const { loading: regionLoading, error: regionError, data: regionData } = useGetLookupQuery({
    variables: { name: "RegionType" }
  })

  const [stateDiff, setStateDiff]:[ProductAssetsByRegionSingleFields[], React.Dispatch<React.SetStateAction<ProductAssetsByRegionSingleFields[]>>] = useState([] as ProductAssetsByRegionSingleFields[])

  const [historicStateDiff, setHistoricStateDiff]:[stateHistoricDiffType<HistoricUpdateRow>, React.Dispatch<React.SetStateAction<stateHistoricDiffType<HistoricUpdateRow>>>] = useState(defaultHistoricDiff)

  const handleEdit = () => {
    resultRef!.current?.resetForm()
    setStateDiff([])
    setHistoricStateDiff(defaultHistoricDiff)
    setEditMode(!editMode)
  }

  const afterUpdate = (resultPromise:Promise<any>) => {
    resultPromise.then(result => {
      setSaving(false)
      if (result && result.data) {
        setEditMode(false)
        historicalResultRef.current?.resetHistory()
        setStateDiff([])
        setHistoricStateDiff({
          updateType: 'assetsUnderManagement' as HistoricUpdateRow,
          rows: [] as ProductAssetsByRegionHistoricalAssetRow[]
        })
      }
    })
    .catch(err => {
      setSaving(false)
      console.error("Error testManagerSummary", err.message)
    })
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let returnPromise:Promise<any>
    if (screen === "single") {
      let submissionData = {
        id: productId,
        date: searchDate,
        fields: stateDiff
      }

      returnPromise = updateProductAssetsByRegion({ variables: { input: submissionData, startDate: searchDate, endDate: searchDate } })

    } else {
      let submissionData:UpdateProductAssetsByRegionHistoricalAssetInput = {
        id: productId,
        rows: historicStateDiff.rows
      }

      if (historicStateDiff.updateType === 'assetsUnderManagement') {
        returnPromise = updateProductAssetsByRegionHistoricAssets({ variables: { input: submissionData, startDate: appDate.format(DATE_API_FORMAT), endDate: firstHistoricalDate.format(DATE_API_FORMAT) } })

      } else {
        returnPromise = updateProductAssetsByRegionHistoricAccounts({ variables: { input: submissionData, startDate: appDate.format(DATE_API_FORMAT), endDate: firstHistoricalDate.format(DATE_API_FORMAT) } })

      }
    }

    afterUpdate(returnPromise)
  }

  const handleSingleChange = (newState:ProductAssetsByRegionQuery) => {

    if ( newState?.product?.product?.assetsByRegion  ){
      const { assetsByRegion } = newState?.product?.product

      let submissionData:ProductAssetsByRegionSingleFields[] = []

      assetsByRegion.map((asset) => {
        if (asset) {
          let field:ProductAssetsByRegionSingleFields = {
            region: asset.region.code,
            assetsUnderManagement: asset.assetsUnderManagement,
            numberOfAccounts: asset.numberOfAccounts
          }

          submissionData.push(field)
        }
      })

      setStateDiff(submissionData)
    }
  }

  const handleHistoricChange = (updateType: keyof HistoricUpdateRowMap, diff:GroupedAssets) => {
    let rows:HistoricUpdateRowMap[typeof updateType][] = []
    _.map(diff, (row:GroupedAsset, date:string) => {
      let fields:HistoricUpdateFieldsMap[typeof updateType][] = []

      _.map(row, (asset:AssetsByRegion, region: RegionType) => {
        if (updateType === 'assetsUnderManagement') {
          fields.push({ region, assetsUnderManagement: asset.assetsUnderManagement })
        } else {
          fields.push({ region, numberOfAccounts: asset.numberOfAccounts })
        }
      })

      rows.push({ date, fields })
    })

    let submissionData:stateHistoricDiffType<typeof updateType> = {
      updateType,
      rows
    }

    setHistoricStateDiff(submissionData)
  }

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={true}
          updateType={(type:string) => setScreen(type)}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        {screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>historicalResultRef.current?.loadMore()}>
            {historicalResultRef.current?.state.loadingMore && "Loading"}
            {!historicalResultRef.current?.state.loadingMore && "Load 5 More Years"}
          </Button>
        }
        <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="text-callan-blue mt-1 ml-1" onClick={()=> exportTables()}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} />
        }
      </div>
    </>
  )

  if (loading || regionLoading) {
    return (
      <>
        {heading}
        <div className='pane pane-table'>
          <PlaceHolder />
        </div>
      </>
    );
  }
  if (error || regionError) {
    return (
      <>
        {heading}
        <div className='pane pane-table'>
          <p>{error?.message}</p>
          <p>{regionError?.message}</p>
        </div>
      </>
    );
  }
  if (data && data.product) {
    if(screen === "single" && regionData){
      return (
        <>
          {heading}
          <Result
            key={searchDate}
            ref={resultRef}
            editMode={editMode}
            setEditMode={setEditMode}
            setSearchDate={setSearchDate}
            data={data}
            searchDate={searchDate}
            regionData={regionData}
            onChange={handleSingleChange}
          />
        </>
      )
    } else if(screen === "historical") {
      return (
        <>
          {heading}
          <HistoricResult
            editMode={editMode}
            setEditMode={setEditMode}
            setSearchDate={setSearchDate}
            data={data}
            searchDate={searchDate}
            onChange={handleHistoricChange}
            fetchMore={fetchMore}
            ref={historicalResultRef}
          />
        </>
      )
    }
  }
  return <div>data doesn't exist</div>
}

const AssetRegionInputList:FormInputField[] = [
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency"},
  { property: "boundary", label: "", type: "boundary"},
  { property: "numberOfAccounts", label: "", type: "number"},
]

const AssetClassInputList:AssetClassInputField[] = [
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "EQ"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "FI"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "BAL"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "ALT"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", assetClass: "OTH"},
]

class Result extends Component<ResultProps> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data
  }

  static getDerivedStateFromProps(props: HistoricResultProps, state:HistoricResultState) {
    return {
      currentState: props.editMode ? state.currentState : props.data,
      initialState: props.data,
    }
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    region: RegionType,
  ) => {
    let newState = _.cloneDeep(this.state.currentState)

    if(!(newState?.product?.product as any)[table]){
      _.set(newState, ["product", "product", table], [])
    }
    newState = iassign(
      newState,
      ["product", "product", table],
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as any[])
        var selectedRow = _.find(rows, (o) => o?.region?.code === region )

        if(selectedRow){
          _.set(selectedRow, property, value);
        } else {
          const regionOb = {...locationOrder.find((loc) => loc.code === region), __typename: "RegionTypeLookup"}
          const newRow= {
            assetsUnderManagement: null,
            region: regionOb,
            numberOfAccounts: null,
            quarterEndDate: this.props.searchDate,
            origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'},
            __typename: "AssetsByRegion",
          }
          _.set(newRow, property, value);
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ currentState: newState }, () =>
      this.props.onChange(this.state.currentState)
    )
  }

  formatData = (sourceData:any, mappingList:any) => {
    let mappingListHolder = mappingList
    if(mappingList.__type?.enumValues) mappingListHolder= mappingList.__type?.enumValues
    return _.map(mappingListHolder, (type:any) => {
      const foundData = _.find(sourceData, (o:any) => {return o.region.code === type.code})
      if(foundData){
        return foundData
      } else {
        return {
          assetsUnderManagement: undefined,
          numberOfAccounts: undefined,
          quarterEndDate: this.props.searchDate,
          region: {...type, __typename: "RegionTypeLookup"}
        }
      }
    })
  }

  render() {
    const assetLocations = _.get(this.state.currentState, 'product.product.assetsByRegion', [])

    const formattedLocationsData = this.formatData(assetLocations, locationOrder)
    const totalLocations = formattedLocationsData.reduce((result:any,entry:any) => {
      if(entry.region.code !== "EXUK"){
        result.assetsUnderManagement += entry.assetsUnderManagement || 0
        result.numberOfAccounts += entry.numberOfAccounts || 0
      }
      return result;
    },{ assetsUnderManagement: 0, numberOfAccounts: 0 })
    const sortedLocations = formattedLocationsData

    return(
      <>
      <div className="pane pane-table">
        <div className="pane-title">
          <h3 id="assetsByLocationTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByLocationTooltip">
              Assets by Location
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                className="ml-2 mt-0"
                size="sm"
              />
            </div>
          </h3>
        </div>
        <Row>
          <Col md="5">
            <div className="table-container">
              <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.product?.product?.name}-client`}>
                <thead>
                  <tr className="table-title row-border-olive-100">
                    <th></th>
                    <th className="py-2">Assets ($M)</th>
                    <th className="boundary-left"></th>
                    <th className="boundary-right"></th>
                    <th className="py-2"># Accounts</th>
                  </tr>
                  <tr>
                    <th className="vw-16">Location</th>
                    <th className={"text-right"}>Total</th>
                    <th className="boundary-left"></th>
                    <th className="boundary-right"></th>
                    <th className={"text-right"}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedLocations.map((arr:AssetsByRegion, row:number) => {
                    if(!this.props.editMode && (!arr.assetsUnderManagement && arr.assetsUnderManagement !== 0) && (!arr.numberOfAccounts && arr.numberOfAccounts !== 0)){
                      return <></>
                    }
                    let tooltip = _.get(tooltipMapping, arr.region.code)
                    return (
                      <tr key={row}>
                        <td className="text-left text-nowrap">
                          <div className={classnames('position-relative', { 'tooltip-container' : tooltip })} id={tooltip ? `location${tooltip}TooltipContainer` : undefined}>
                            {arr.region.code === "EXUK" &&
                              <span className="align-items-center ml-n2 mr-1 orange-dot"></span>
                            }
                            {tooltip &&
                              <div className="d-inline-flex align-items-center tooltip-icon row-icon" id={`location${tooltip}Tooltip`}>
                                <FontAwesomeIcon
                                  icon={"question-circle" as IconName}
                                  className="mt-0"
                                  size="sm"
                                />
                              </div>
                            }
                            {handleCountryIndent(arr.region)}
                          </div>
                        </td>
                        {AssetRegionInputList.map(({property, label, type, subtype, placeholder, optionSource, readonly}, idx) => {
                          if(type === "boundary"){
                            return (
                              <React.Fragment key={idx}>
                                <td className="boundary-left"></td>
                                <td className="boundary-right"></td>
                              </React.Fragment>
                            )
                          }
                          let propertyVal, onChangeCallback
                          propertyVal = _.get(arr, property)
                          onChangeCallback = (value:any) => this.handleInputChange(value, property, "assetsByRegion", arr.region.code)

                          return(
                            <td key={idx}>
                              <div className="position-relative">
                                <FormInput
                                  property={property}
                                  displayName={label}
                                  type={type}
                                  subtype={subtype}
                                  placeholder={placeholder}
                                  idx={idx + (row*AssetRegionInputList.length)}
                                  editMode={this.props.editMode}
                                  showZero={true}
                                  propertyVal={propertyVal}
                                  updateValue={onChangeCallback}
                                  optionSource={optionSource}
                                  readonly={readonly}
                                />
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  <tr key="Calc">
                    <td className="text-right">Calculated Total</td>
                    <td className="text-right">{numbro(totalLocations.assetsUnderManagement || 0).format("$0,0.00")}</td>
                    <td className="boundary-left"></td>
                    <td className="boundary-right"></td>
                    <td className="text-right">{numbro(totalLocations.numberOfAccounts || 0).format("0,0")}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="row-indicator-legend">
              <div className="position-relative">
                <span className="row-indicator orange-dot" />
                Excluded from total
              </div>
            </div>
          </Col>
          <Col md="7" className="pt-4">
            <WorldMap data={assetLocations}/>
          </Col>
        </Row>
      </div>
    </>
    )
  }
}

interface HistoricResultState {
  currentState: GroupedAssets
  initialState: GroupedAssets
  loadingMore: boolean
  historicalDate: moment.Moment
  displayValue: HistoricUpdateRow
}
class HistoricResult extends Component<HistoricResultProps, HistoricResultState> {
  constructor(props: any) {
    super(props)

    this.state = {
      currentState: HistoricResult.groupData(props),
      initialState: HistoricResult.groupData(props),
      loadingMore: false,
      historicalDate: moment(firstHistoricalDate),
      displayValue: "assetsUnderManagement",
    }
  }

  static getDerivedStateFromProps(props: HistoricResultProps, state:HistoricResultState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    const groupData = HistoricResult.groupData(props)
    return {
      currentState: resetDate && !props.editMode ? groupData : state.currentState,
      initialState: resetDate ? groupData : state.initialState,
      loadingMore: state.loadingMore,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
    }
  }

  static groupData = (props:HistoricResultProps) => {
    const assets:AssetsByRegion[] = _.get(props.data, "product.product.assetsByRegion", [])

    let groupedData:GroupedAssets = {}
    assets.map((entry:AssetsByRegion) => {
      let date: string = entry.quarterEndDate
      let region:RegionType = entry.region.code
      _.set(groupedData, [date, region], entry)
    })

    return groupedData
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  getVisibleCountries = () => {
    const assets:AssetsByRegion[] = _.get(this.props.data, "product.product.assetsByRegion", [])
    let visibleCountries:RegionType[] = []
    assets.map((entry:AssetsByRegion) => {
      let region = entry.region.code
      visibleCountries = _.union(visibleCountries, [region])
    })
    return visibleCountries
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  setDisplayValue = (displayValue:HistoricUpdateRow) => {
    this.setState({
      displayValue: displayValue
    })
  }
  loadMore = () =>{
    if(this.state.loadingMore){
      return
    }
    this.setState({loadingMore: true})
    this.props.fetchMore({
      variables: {
        id: this.props.data.product?.product?.id,
        startDate: this.state.historicalDate.format(DATE_API_FORMAT),
        endDate: moment(this.state.historicalDate).subtract(5, "years").format(DATE_API_FORMAT)
      },
      updateQuery: (previousResult:ProductAssetsByRegionQuery, { fetchMoreResult }:{fetchMoreResult:ProductAssetsByRegionQuery}) => {
        if(!fetchMoreResult){
          return previousResult
        }
        const newInitialState = this.state.initialState
        const newCurrentState = _.cloneDeep(this.state.currentState)
        const newAssets:AssetsByRegion[] = _.get(fetchMoreResult, "product.product.assetsByRegion", [])

        newAssets.map((entry:AssetsByRegion) => {
          let date: string = entry.quarterEndDate
          let region:RegionType = entry.region.code

          if (!_.has(this.state.currentState, [date, region])) {
            _.set(newInitialState, [date, region], entry)
            _.set(newCurrentState, [date, region], entry)
          }
        })

        this.setState({
          initialState: newInitialState,
          currentState: newCurrentState,
          historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
          loadingMore: false
        })
      }
    })
  }

  onChangeCallback = (
    date: string,
    region: RegionType,
    value: any,
  ) => {
    const { displayValue } = this.state
    let newState = _.cloneDeep(this.state.currentState)
    if(!newState[date]){
      _.set(newState, date, {})
    }
    if(!newState[date][region]) {
      _.set(newState, [date, region], {})
    }

    newState = iassign(
      newState,
      [date, region],
      selectedCell => {
        let cell:any

        if (selectedCell) {
          cell = _.cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            region: {code: region as RegionType, __typename: "RegionTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: date,
            __typename: "AssetsByRegion",
            origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'}
          }
        }

        _.set(cell, displayValue, value)

        return cell
      }
    )

    let groupedDiff:GroupedAssets = {}

    _.map(newState, (row:GroupedAsset, date:string) => {
      _.map(row, (asset:AssetsByRegion, region: string) => {
        const oldAsset = _.get(this.state.initialState, [date, region])
        if (!_.isEqual(asset, oldAsset)) {
          _.set(groupedDiff, [date, region as RegionType], asset)
        }
      })
    })

    this.setState({ currentState: newState }, () => {
      this.props.onChange( displayValue, groupedDiff)
    })
  }

  render() {
    const { editMode } = this.props
    const { displayValue } = this.state
    const data = this.state.currentState
    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

    let visibleCountries:RegionType[] = this.getVisibleCountries()

    return(
      <>
      <div className="pane pane-table">
        <div className="pane-title-alt">
          <h3 id="assetsByLocationHistoricalTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByLocationHistoricalTooltip">
              Assets by Location
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                className="ml-2 mt-0"
                size="sm"
              />
            </div>
          </h3>
        </div>
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-item",{ active: displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets ($M)</li>
          <li className={classnames("horizontal-picker-item",{ active: displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
        <div className="table-container">
          <Table hover className="table-bordered-internal table-condensed exportable" data-export-name={`${this.props.data.product?.product?.name}-client`}>
            <thead>
              <tr>
                <th>Date</th>
                {locationOrder.map((country) => {
                  if(editMode || visibleCountries.includes(country.code)){
                    const tooltip = _.get(tooltipMapping, country.code)
                    const orangeDot = _.get(orangeDotMapping, country.code)
                    return(
                      <th key={country.code}>
                        <div className="position-relative" id={tooltip ? `location${tooltip}TooltipContainer` : undefined}>
                          {country.value}
                          {orangeDot && <span className="row-indicator orange-dot" /> }
                          {tooltip &&
                              <div className="d-inline-flex align-items-center tooltip-icon" id={`location${tooltip}Tooltip`}>
                                <FontAwesomeIcon
                                  icon={"question-circle" as IconName}
                                  size="sm"
                                />
                              </div>
                            }
                        </div>
                      </th>
                    )
                  }
                })}
                <th>Calculated Total</th>
              </tr>
            </thead>
            <tbody>
              {allDates.map((quarter:string, row:number) => {
                  return (
                    <AssetClassHistoricalRow
                      date={quarter}
                      key={row + displayValue}
                      data={data}
                      row={row}
                      editMode={editMode}
                      updateValue={(value, property, region, date) => this.onChangeCallback(date, region, value)}
                      regions={locationOrder}
                      displayValue={displayValue}
                      visibleRegions={visibleCountries}
                    />
                  )
                })}
            </tbody>
          </Table>
          </div>
          <div className="row-indicator-legend">
            <div className="position-relative">
              <span className="row-indicator orange-dot" />
              Excluded from total
            </div>
        </div>
      </div>
    </>
    )
  }
}

interface AssetClassHistoricalRowProps {
  data: any
  row: number
  editMode: boolean
  date: string
  regions: any[]
  displayValue: string
  visibleRegions: string[]
  updateValue: (value:any, property:string, region:RegionType, date:string) => void
}

const AssetClassHistoricalRow = ({data, row, editMode, regions, updateValue, displayValue, date, visibleRegions}: AssetClassHistoricalRowProps) => {
  let rowTotal = 0
  const totalFormat = displayValue === "assetsUnderManagement" ? '$0,0.00' : '0,0'
  return (
    <tr className="fadein fadein-yellow">
      <td className="nowrap">
        {date}
      </td>
      {regions.map((region, idx) => {
        if(!editMode && !visibleRegions.includes(region.code)){
          return <></>
        }
        let propertyVal, onChangeCallback
        propertyVal = _.get(data, date+'.'+region.code+'.'+displayValue)
        onChangeCallback = (value:any) => updateValue(value, displayValue, region.code, date)
        if(region.code != "EXUK"){
          rowTotal += propertyVal || 0
        }

        return(
          <td key={idx}>
            <FormInput
              property={displayValue}
              displayName={""}
              type={"number"}
              subtype={displayValue === "assetsUnderManagement" ? "currency" : ""}
              placeholder={""}
              idx={idx + (row*AssetClassInputList.length)}
              editMode={editMode}
              showZero={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
            />
          </td>
        )
      })}
      <td>
        { numbro(rowTotal).format(totalFormat)}
      </td>
    </tr>
  )
}

export default ProductAssetsLocation
