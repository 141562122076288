import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Select, { ActionMeta, components, Styles } from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { InputTooltip } from './TextFieldInput';

type MultiSelectInputProps = {
  idx: string | number;
  property: string;
  displayName: string;
  editMode: boolean;
  onChange: (event: any) => void;
  disabled?: boolean;
  wrapperClasses?: string;
  labelClasses?: string;
  inputWrapperClasses?: string;
  options?: [] | undefined;
  tooltip?: InputTooltip
  isLoading?: boolean | undefined;
  defaultOptions?: OptionsProps[];
  isMulti?: boolean | undefined,
  isSearchable?:boolean | undefined,
  isClearable?:boolean | undefined,
  autoFocus?:boolean | undefined
}

export type OptionsProps = {
  label: string;
  value: string;
}

export const MultiSelectInput: React.FC<MultiSelectInputProps> =({
  idx,
  property,
  displayName,
  editMode,
  disabled,
  wrapperClasses,
  labelClasses,
  inputWrapperClasses,
  options,
  tooltip,
  onChange,
  isLoading = false,
  defaultOptions = [],
  isMulti = true,
  isSearchable= true,
  isClearable= true,
  autoFocus= true

}) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionsProps[]>(defaultOptions);

  useEffect(() => {
  }, [selectedOptions, defaultOptions])

  let shortProperty = property.split('.').pop() || property;
  const displayLabel = displayName !== '';

  const handleChange = (value: any, actionMeta: ActionMeta<any>) => {

    if (['select-option', 'remove-value'].includes(actionMeta.action)) {
      setSelectedOptions(value)
      onChange(value.map((select: any) => { return { code: select.value, value: select.label } }))
    }

    if (actionMeta.action === 'clear') {
      setSelectedOptions([])
      onChange([])
    }
  }

  const customTheme = (theme:any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#F2F6F7', // Focused selected item
        primary: '#044F76' // Border
      }
    }
  }

  const DropdownIndicator = (
    props: any
  ) => {
    return (
      <>
        {editMode &&
          <components.DropdownIndicator {...props}>
            <FontAwesomeIcon icon="caret-down" size='sm'/>
          </components.DropdownIndicator>
        }
      </>
    );
  };

  const isOptionsEmpty = isEmpty(options)

  const customStyles: Partial<Styles<OptionsProps, true, never>> | undefined = {
    control: (base, {isDisabled}) => !editMode ? ({
      cursor: 'not-allowed',
      ...isDisabled && {pointerEvents: 'auto'},
    }) : {
      ...base,
      backgroundColor: '#F2F6F7',
      border: '1px solid #ced4da',
      cursor: isDisabled ? 'not-allowed' : 'default',
      opacity: isDisabled ? 0.6 : 1
    },
    dropdownIndicator: (base) => !editMode ? ({
      display: 'none'
    }) : {
      ...base,
    },
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#FFFFFF',
      border: '1px solid #ced4da',
      borderRadius: '4px',
    }),
    multiValueLabel: (base) => ({
      ...base,
      paddingRight: '6px'
    }),
    multiValueRemove: (base) => !editMode ? ({
      ...base,
      display: 'none',
    }) : { ...base },
    menu: (base) => ({
      ...base,
      zIndex: 2,
    }),
  }

  return (
    <FormGroup className={classnames('form-group row', wrapperClasses)} key={idx}>
      {displayLabel && (
        <Label
          className={classnames('col-form-label', labelClasses, { 'col-sm-4': !labelClasses })}
          for={`${idx}-${shortProperty}`}
          id={property}
        >
          <div
            className={classnames("d-flex w-100", {'tooltip-icon': tooltip, 'justify-content-start': tooltip?.onClick})}
            id={tooltip ? tooltip.id : ''}
          >
            {displayName}
            {tooltip &&
              <FontAwesomeIcon
                icon={tooltip.icon as IconName}
                size="sm"
                onClick={() => { tooltip.onClick && tooltip.onClick() }}
              />
            }
          </div>
        </Label>
      )}
      <div
        className={classnames("exportable-form-input", inputWrapperClasses || '', {
          'col-sm-8': displayLabel && !inputWrapperClasses,
          'col-sm-12': !(displayLabel || inputWrapperClasses),
          "input-group-col-fixer": true,
        })} data-export-value={defaultOptions?.map((option)  => option.label).join(', ')} id={`${idx}-${shortProperty}`}
      >
        <Select
          options={options}
          value={defaultOptions}
          onChange={handleChange}
          className={'multi-select-position-fixer'}
          components={{DropdownIndicator, IndicatorSeparator: ()=> null }}
          placeholder={disabled || !editMode ? '' : 'Please Select'}
          isDisabled={disabled || !editMode || isOptionsEmpty }
          theme={customTheme}
          noOptionsMessage={() => 'No options'}
          isMulti={isMulti}
          isSearchable={isSearchable}
          isClearable={isClearable}
          autoFocus={autoFocus}
          isLoading={isLoading || (editMode && isOptionsEmpty)}
          styles={customStyles}
          menuPortalTarget={document.body}
        />
      </div>
    </FormGroup>
  );
};
