import { Mutation, Query } from '@apollo/client/react/components'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { ApolloError, ApolloQueryResult } from '@apollo/client'
import classNames from 'classnames'
import { History, Location } from 'history'
import iassign from 'immutable-assign'
import _, { sortBy as _sortBy, cloneDeep, compact, concat, filter, find, first, flatten, get, last, orderBy, pull, remove, set, trim, union, differenceBy, unionBy } from 'lodash'
import moment from 'moment'
import React, { Component, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Col, Collapse, Container, Dropdown, DropdownMenu, DropdownToggle, Form, FormGroup, Input, ListGroup, ListGroupItem, Row, UncontrolledAlert } from 'reactstrap'

import { AddInteractionAssociationMutation, AddInteractionAssociationMutationFn, AddInteractionAssociationMutationVariables, DownloadInteractionNoteQuery, Exact, FileBasicInfoFragment, GetInteractionQuery, GlidePathInteractionNoteFields, InteractionFragment, InteractionImportance, InteractionNoteDetailFragment, InteractionSidebarFragment, Maybe, MeFragment, OrganizationInteractionNotesFragment, OrgInteractionNoteFields, ProductInteractionNoteFields, ProductInteractionNotesFragment, RemoveInteractionAssociationMutationFn, UpdateFileDataInput, UpdateFileMetadataInput, UpdateFileMetadataMutationFn, useAddInteractionAssociationMutation, useDeleteInteractionProductNoteMutation, useDownloadInteractionNoteLazyQuery, useManagerAllProductsQuery, useManagerDocumentsQuery, useManagerInteractionsQuery, useMeQuery, useRemoveInteractionAssociationMutation, useUpdateFileMetadataMutation, useUpsertInteractionOrgNoteMutation, useUpsertInteractionProductNoteMutation } from '../../__generated__/graphql'
import Auth from '../../Auth/Auth'
import { DATE_API_FORMAT, FormInputField } from '../../helpers/constant'
import { diffOrgNote, diffProductNote, hyphenNateType, IsSameProductInteractionNote, productInteractionNoteHasChanged, toOrgInteractionNoteFields, toProductInteractionNoteFields } from '../../helpers/interaction'
import { ADD_INTERACTION_ASSOCIATION, GET_INTERACTION } from '../../queries/Interaction'
import { Association, AssociationProduct, InteractionAssociationsList } from '../Associations/AssociationsList'
import { InteractionAssociationsModal } from '../Associations/AssociationsModal'
import DocumentCompactList from '../Documents/DocumentCompactList'
import { ForceValuesProps } from '../Shared/Document'
import { Filter, MultiplePicker, OptionItem } from '../Shared/Picker'
import RouteLeavingGuard, { GuardModal, ModalScript } from '../Shared/RouteLeavingGuard'
import EditButtons from '../ui/EditButtons'
import RichTextEditor from "../ui/Editor"
import EmployeePicker, { PersonSelection } from "../ui/Pickers/EmployeePicker"
import PlaceHolder from '../ui/PlaceHolder'
import { QueryLazyOptions } from '@apollo/client'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import ErrorDisplay from '../Shared/ErrorDisplay'

type idProps = {
  managerId: number,
  history: History
  location: Location
  auth: Auth
}

type NoteChangeTypes = "organizationNotes" | "allProductNotes"

interface StateChangeStoreType {
  organizationNotes: OrgInteractionNoteFields | null
  allProductNotes: OrgInteractionNoteFields | null
  productNotes: ProductInteractionNoteFields[]
  glidePathNote: GlidePathInteractionNoteFields[]
}

interface IIconOptions extends Omit<FontAwesomeIconProps, 'icon'>{}

const typeIcon = (interaction:InteractionSidebarFragment, opts?: IIconOptions) => {
  const props = opts || {}
  switch (interaction.type) {
    case "Conference Call":
      return (<FontAwesomeIcon icon={["fal", "phone"]} {...props}></FontAwesomeIcon>)

    case "On Site":
      return (<FontAwesomeIcon icon={["fal", "building"]} {...props}></FontAwesomeIcon>)

    case "Off Site":
      return (<FontAwesomeIcon icon={["fal", "map-marker-alt"]} {...props}></FontAwesomeIcon>)

    case "In House":
      return (<FontAwesomeIcon icon={["fal", "home-alt"]} {...props}></FontAwesomeIcon>)

    case "Email":
      return (<FontAwesomeIcon icon={["fal", "envelope"]} {...props}></FontAwesomeIcon>)

    case "Client":
      return (<FontAwesomeIcon icon={["fal", "user-friends"]} {...props}></FontAwesomeIcon>)

    case "Video Call":
      return (<FontAwesomeIcon icon={["fal", "video"]} {...props}></FontAwesomeIcon>)

    case "Webcast":
      return (<FontAwesomeIcon icon={["fal", "tv-alt"]} {...props}></FontAwesomeIcon>)

    default:
      return (<React.Fragment></React.Fragment>)
  }
}
/** https://callanllc.atlassian.net/browse/CAL-1420?focusedCommentId=16762]
 * sort alphabetically
*/
export const ASSET_CLASS_OPTIONS_DATA: {code: number, value: string}[] = [
  {code: 1 , value: "Direct Hedge Fund"},
  {code: 2 , value: "Domestic Equity"},
  {code: 3 , value: "Domestic Fixed Income"},
  {code: 4 , value: "Hedge Fund-of-Funds"},
  {code: 5 , value: "International/Global Equity"},
  {code: 6 , value: "International/Global Fixed Income"},
  {code: 7 , value: "Multi-Asset Class"},
  {code: 8 , value: "Private Debt"},
  {code: 9 , value: "Private Equity"},
  {code: 10, value: "Real Assets - private"},
  {code: 11, value: "Real Assets - public"},
  {code: 12, value: "Real Estate"},
  {code: 13, value: "Target Date Funds"},
]

const ManagerInteractions: React.FC<idProps> = ({ managerId, history, location, auth }) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [search, setSearch] = useState("")

  const params = useParams() as { id?: string }
  const getInitialInteractionId = (id:string) => {
    const interactionId = id || ""
    if (!interactionId) {
      return "0"
    }else {
      return interactionId
    }
  }

  const [selectedInteractionId, setSelectedInteractionId] = useState(()=>getInitialInteractionId(params.id || ""))
  const [currentInteraction, setCurrentInteraction] = useState<InteractionFragment|null>(null)
  // avoid unnecessary forceUpdate CAL-1653
  const [forceUpdateFlag, setForceUpdateFlag] = useState<boolean>(false)

  const initialStateChangeStore = {
    organizationNotes: null,
    allProductNotes: null,
    productNotes: [],
    glidePathNote: []
  }

  const [noteIdsToDelete, setNoteIdsToDelete] = useState(new Set<number>())
  const [stateChangeStore, setStateChangeStore] = useState<StateChangeStoreType>(initialStateChangeStore)
  const resetStateChangeStore = () => setStateChangeStore(initialStateChangeStore)

  const [upsertInteractionOrgNote] = useUpsertInteractionOrgNoteMutation()
  const [upsertInteractionProductNotes] = useUpsertInteractionProductNoteMutation()
  const [deleteInteractionProductNote] = useDeleteInteractionProductNoteMutation()
  const [addInteractionsMutation] = useAddInteractionAssociationMutation()
  const [removeInteractionsMutation] = useRemoveInteractionAssociationMutation()
  const [updateFileMutation] = useUpdateFileMetadataMutation()

  let showInternalOpinion = auth.checkPermissions(["view:interactions_private"])
  const { loading, error, data } = useManagerInteractionsQuery({
    fetchPolicy: "cache-first",
    variables: { id: managerId }
  })

  const [getInteractionUrl, {loading: urlLoading, data: urlData, error: urlError}] = useDownloadInteractionNoteLazyQuery({
    fetchPolicy: "network-only"
  });

  const { data: documentsData } = useManagerDocumentsQuery({
    notifyOnNetworkStatusChange: true,
    variables: { id: managerId },
    errorPolicy: "all",
  })

  const { loading:userLoading, error:userError, data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me

  useEffect(()=>{
    let newId = getInitialInteractionId(params.id ||"")
    if(newId !== selectedInteractionId) {
      setSelectedInteractionId(newId)
    }
  }, [params.id])

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:interactions"])){
      return
    }
    setSaving(true)
    setForceUpdateFlag(true)
    const submitProducts = stateChangeStore.organizationNotes || stateChangeStore.allProductNotes
    const initialProductNotes: ProductInteractionNotesFragment[] = compact(currentInteraction?.notes?.specificProductNotes || [])
    // currentProductNotes here stores only changed product specific notes, which means if no product specific notes ever change it will be empty.
    const currentProductNotes: ProductInteractionNoteFields[] = compact(stateChangeStore.productNotes)
    const submitAll = currentProductNotes.length > 0 || Array.from(noteIdsToDelete).length > 0
    const associations = InteractionDisplay.generateAllInteractions(currentInteraction).concat({__typename: "Manager", id: managerId, name: ""})

    // Find the list of associations attached to all documents
    currentInteraction?.documentAssociations.forEach((document) => {
      const managerAssociations:Association[] = compact(document.managers).map(m => { return { __typename: m?.__typename, id: m?.id, name: m?.name || '' } })
      const products = compact(document.products?.map(p => p?.product))
      const productAssociations: Association[] = products.map(p => { return { __typename: p?.__typename, id: p?.id, name: p?.name } })
      const glidePathAssociations: Association[] = compact(document.glidePaths).map(g => { return { __typename: g?.__typename, id: g?.id, name: g?.name || '' } })
      const addAssociations = differenceBy(associations, [...managerAssociations, ...productAssociations, ...glidePathAssociations] || [], (association:Association) => {
        return `${association.__typename}:${association.id}`
      })
      if(addAssociations.length > 0 && currentInteraction){
        let updateData = {
          managers: compact(union(document.managers?.map((ob:any) => ob.id), currentInteraction.otherManagerAssociations.map((ob:any) => ob.id), [currentInteraction.primaryManagerAssociation?.id])),
          products: compact(union(document.products?.map((ob:any) => ob.product.id), currentInteraction.productAssociations.map((ob:any) => ob.product.id))),
          glidePaths: compact(union(document.glidePaths?.map((ob:any) => ob.id), currentInteraction.glidePathAssociations.map((ob:any) => ob.id))),
        } as UpdateFileDataInput

        const input = { id: document.id, patch: updateData } as UpdateFileMetadataInput

        updateFileMutation({ variables: { input } })
          .catch(err => {
            console.error("Error Update File Metadata", err.message)
          })
      }
    })

    // update Organization Notes and All Associated Products Notes
    if (submitProducts) {
      const initialOrgNotes: OrganizationInteractionNotesFragment | null = currentInteraction?.notes?.organizationNotes || null
      const initialAllProductNotes: OrganizationInteractionNotesFragment | null = currentInteraction?.notes?.allProductNotes || null
      let submissionData = {
        interactionId: selectedInteractionId,
        organizationNotes: diffOrgNote(initialOrgNotes, stateChangeStore.organizationNotes), // empty value {} will be ignored by api.
        allProductNotes: diffOrgNote(initialAllProductNotes, stateChangeStore.allProductNotes)
      }

      upsertInteractionOrgNote({ variables: { input: submissionData } })
        .then(result => {
          if (result && result.data) {
            if(!submitAll){
              setEditMode(false)
              setSaving(false)
            }
          }
        })
        .catch(err => {
          console.error("Error upserting org note", err.message)
          setSaving(false)
        })
    }
    // update Product Specific Notes
    if (submitAll) {
      const notesToUpsert: ProductInteractionNoteFields[] = []
      initialProductNotes.forEach((initialNote: ProductInteractionNotesFragment) => {
        const updatedNote: ProductInteractionNoteFields | undefined = find(currentProductNotes, current => IsSameProductInteractionNote(initialNote, current))
        if (updatedNote && productInteractionNoteHasChanged(initialNote, updatedNote)){
          notesToUpsert.push(diffProductNote(initialNote, updatedNote))
        }
      })
      currentProductNotes.forEach((current: ProductInteractionNoteFields) => {
        const isNew = !find(initialProductNotes, initialNote => IsSameProductInteractionNote(initialNote, current))
        if (isNew) notesToUpsert.push(current)
      })

      let noteIdsArray = Array.from(noteIdsToDelete)
      //CAL-1587  do not send empty array to graphql api if there's nothing to update.
      if(notesToUpsert.length > 0){
        let productNotesSubmissionData = {
          interactionId: selectedInteractionId,
          productNotes: notesToUpsert,
        }
        console.log("submit ProductSpecific Notes.", productNotesSubmissionData, noteIdsToDelete, notesToUpsert, initialProductNotes, currentProductNotes)
        // delete/upsert modified note sections
        Promise.all([
          upsertInteractionProductNotes({ variables: { input: productNotesSubmissionData } }),
          ...noteIdsArray.map(noteID => deleteInteractionProductNote({ variables: {input: {id: noteID}}}))
        ])
        .then(results => {
          if(results[0] && results[0].data){
            setEditMode(false)
            setSaving(false)
          }
        })
        .catch(err => {
          console.error("Error updating product notes", err.message)
          setSaving(false)
        })
      }else if(noteIdsToDelete.size > 0){
        console.log("delete Product Notes.", noteIdsToDelete, notesToUpsert, initialProductNotes, currentProductNotes)
        Promise.all([...noteIdsArray.map(noteID => deleteInteractionProductNote({ variables: {input: {id: noteID}}}))
        ])
        .then(results => {
          if(results[0] && results[0].data){
            setEditMode(false)
            setSaving(false)
          }
        })
        .catch(err => {
          console.error("Error updating product notes", err.message)
          setSaving(false)
        })
      }else {
        // no upsert or delete, exit saving and editMode
        console.log("no productSpecific notes saving, exit")
        setForceUpdateFlag(false)
        setSaving(false)
        setEditMode(false)
      }
    }

    if (!submitProducts && !submitAll) {
      console.log("no notes saving.")
      setForceUpdateFlag(false)
      setSaving(false)
      setEditMode(false)
    }
  }

  const handleChange = (notes: OrganizationInteractionNotesFragment | null, noteType: NoteChangeTypes) => {
    // for OrgNotes and allProductNotes
    let stateStore = stateChangeStore
    const hasInternalOpinion = auth.checkPermissions(["edit:interactions_private"])
    stateStore[noteType] = toOrgInteractionNoteFields(notes, hasInternalOpinion)
    setStateChangeStore(stateStore)
  }

  const handleProductNoteChange = (prodNotes:ProductInteractionNotesFragment[]) => {
    // for SpecificProductNotes
    let stateStore = stateChangeStore
    const hasInternalOpinion = auth.checkPermissions(["edit:interactions_private"])
    const newProdNotes: ProductInteractionNoteFields[] = compact(prodNotes.map(note => {
      return toProductInteractionNoteFields(note, hasInternalOpinion)
    }))
    set(stateStore, 'productNotes', newProdNotes)
    setStateChangeStore(stateStore)
  }

  const handleProductNoteDelete = (noteIds: number[]) => {
    let newSet = _.clone(noteIdsToDelete)
    noteIds.forEach(id => newSet.add(id))
    setNoteIdsToDelete(newSet)
  }

  const selectInteraction = (id:string) => {
    // setSelectedInteractionId(id)
    if(selectedInteractionId === "" || selectedInteractionId === "0"){
      history.replace(`/managers/${managerId}/interactions/${id}`)
    } else {
      history.push(`/managers/${managerId}/interactions/${id}`)
    }
  }

  const cancelEditMode = () => {
    resetStateChangeStore()
  }

  const downloadInteractionNote = () => {
    getInteractionUrl({variables:{id: selectedInteractionId}})
  }

  let downloadPermission = auth.checkPermissions(["download:meeting_notes"])
  const showExportButton = (!editMode && currentInteraction?.notes?.hasNotes && downloadPermission)


  const LoadingButton = (
    <Button className="btn-no-style text-blue-100 p-0 mx-2">
      Creating
      <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />
    </Button>
  )
  const ExportButton = (
    <div className="d-flex align-items-center justify-content-between" id="exportInteractionTooltipContainer">
      <Button  className="mx-2 text-callan-blue border-blue-80 btn-thin"
          onClick={downloadInteractionNote}>
            Download PDF
            <img src='/assets/PDF.svg' className="ml-2"/>
      </Button>
      <div className="d-inline-flex align-items-center tooltip-icon" id="exportInteractionTooltip">
        <FontAwesomeIcon
          icon={"question-circle" as IconName}
          className="mt-1"
          size="sm"
        />
      </div>
    </div>
  )

  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="mr-2 pr-3 border-right" onSubmit={e=>e.preventDefault()}>
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
    {urlLoading? LoadingButton: showExportButton &&
              ExportButton}
        {auth.checkPermissions(["edit:interactions"]) &&
          <EditButtons
            editMode={editMode}
            setEditMode={setEditMode}
            saving={saving}
            onSubmit={handleSubmit}
            cancelEdit={cancelEditMode}
          />
        }
      </div>
    </>
  )

  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error) {
    setCurrentInteraction(null)
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data && data.org?.__typename === 'Manager') {
    return (
      <Container fluid>
        {heading}
        <Row>
            <RouteLeavingGuard
              when={editMode}
              navigate={path => history.push(path)}
            />
            <InteractionsSidebar
              auth={auth}
              interactions={data.org.interactions}
              assetClasses={ASSET_CLASS_OPTIONS_DATA}
              selectInteraction={(id:string) => selectInteraction(id)}
              selectedInteraction={selectedInteractionId}
              search={search}
              source={"manager"}
              user={user}
            />
            <Query<GetInteractionQuery> query={GET_INTERACTION} variables={{ id: selectedInteractionId === "0" ? "" : selectedInteractionId, showInternal: showInternalOpinion}} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
              {
                (results) => {
                  if (results.loading) {
                    return (
                      <Col md="8" lg="9" className="pl-md-1">
                        <div className="pane">
                          <PlaceHolder />
                        </div>
                      </Col>
                    )
                  }

                  if (results.error) {
                    var error=results.error
                    return (
                      <Col md="8" lg="9" className="pl-md-1">
                        <div className="pane">
                          <UncontrolledAlert color="danger">
                            <h4>Error Fetching Interaction</h4>
                            <ErrorDisplay error={error}/>
                          </UncontrolledAlert>
                        </div>
                      </Col>
                    )
                  }

                  const interaction = results.data?.interaction
                  // fix update inside render warning
                  if (!interaction) {
                    return (
                      <Col md="8" lg="9" className="pl-md-1">
                        <div className="pane">
                          <Row>
                            <Col>
                              <h3>No Interactions Listed</h3>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )
                  }
                  return(<InteractionDisplay
                    id={selectedInteractionId}
                    editMode={editMode}
                    interaction={interaction}
                    notEditable={!auth.checkPermissions(["edit:interactions"])}
                    setCurrentInteraction={setCurrentInteraction}
                    onChange={handleChange}
                    onProductNoteChange={handleProductNoteChange}
                    onProductNoteDelete={handleProductNoteDelete}
                    user={user}
                    setEditMode={setEditMode}
                    refetchQuery={(variables?: Record<string, any> | undefined) => {
                      resetStateChangeStore()
                      return results.refetch(variables)
                    }}
                    forceUpdate={forceUpdateFlag}
                    auth={auth}
                    addInteractionsMutation={addInteractionsMutation}
                    removeInteractionsMutation={removeInteractionsMutation}
                    updateFileMutation={updateFileMutation}
                    associationType="Manager"
                    associationId={data.org?.id}
                    downloadUrl={getInteractionUrl}
                    urlLoading={urlLoading}
                    urlError={urlError}
                    urlData={urlData}
                    searchableDocuments={documentsData?.org?.__typename === "Manager" ? documentsData.org.documents as FileBasicInfoFragment[] || undefined : undefined}
                  />)
                }
              }
            </Query>
        </Row>
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}

interface InteractionsSidebarProps {
  auth: Auth
  interactions: InteractionSidebarFragment[]
  selectedInteraction: string
  selectInteraction: (id:string) => void
  search?: string
  source: string // for no interaction message
  assetClasses?: {code: number, value: string}[]
  user?: MeFragment // filter my interaction
  showAssociation?: boolean
  clientInteractions?: boolean
  componentPiece?: boolean // part of a component so display differently
  settingsToDefaultFilter?: (filters:Filter[]) => MultiFilter[]
  afterFilter?: (filters: Filter[], activeFilters:MultiFilter[]) => void
  stayOpen?: boolean
}

export type MultiFilter = {
  filterId: number,
  activeOptions:Array<number| string>
}

export const InteractionsSidebar:React.FC<InteractionsSidebarProps> = (props:InteractionsSidebarProps) => {
  let { interactions, source, assetClasses, user, showAssociation, clientInteractions, componentPiece, settingsToDefaultFilter, afterFilter, stayOpen, auth} = props
  let userId = user?.person?.id
  // CAL-1420
  const EditedFilters: Filter[] = compact([
    auth.checkPermissions(["view:all_clients"]) ?
    {
      filterId: 1,
      filterName: "Author",
      classNames: "left",
      property: "callanPeople",
      options: [
        {
          id: 1,
          property: "id",
          value: userId || -999999,
          name: "My Interactions"
        }
      ]
    } : null,
    {
      filterId: 5,
      filterName: "Details",
      classNames: "left",
      property: "notes",
      matchFunction: (option, value) =>{
        if(typeof option.value === "number")return false
        if(option.property === "hasNotes" && option.value === "true") return value.hasNotes
        return false
      },
      options: [
        {
          id: 1,
          property: "hasNotes",
          value: "true",
          name: "Has Notes"
        }
      ]
    },
    {
      filterId: 2,
      filterName: "Importance",
      classNames: "left",
      property: "importance",
      options: [
        {
          id: 1,
          value: "Normal",
          name: "Normal"
        },
        {
          id: 2,
          value: "Notable",
          name: "Notable"
        },
        {
          id: 3,
          value: "Significant",
          name: "Significant"
        }
      ]
    },
    {
      filterId: 4,
      filterName: "Time Period",
      classNames: "left",
      property: "date",
      matchFunction: (option, value) =>{
        if(typeof option.value === "number")return false
        const [count, duration] = option.value.split(" ")
        const comparisonDate = moment().subtract(parseInt(count), duration as any)
        const interactionDate = moment(value, DATE_API_FORMAT)
        if(comparisonDate && interactionDate) return interactionDate.isAfter(comparisonDate)
        return false
      },
      options: [
        {
          id: 1,
          value: "1 M",
          name: "1 Month"
        },
        {
          id: 2,
          value: "1 Q",
          name: "1 Quarter"
        },
        {
          id: 3,
          value: "1 y",
          name: "1 Year"
        },
      ]
    },
    {
      filterId: 3,
      filterName: "Asset Class",
      classNames: "right",
      property: "assetClass",
      options: assetClasses?.map(({ code, value })=>({
        id: code,
        name: value || "",
        value: value || ""
      })) || []
    }
  ])

  const sortOptions:OptionItem[] = [{
    id: 0,
    name: "Most recent",
  },{
    id: 1,
    name: "Subject",
  },{
    id: 2,
    name: "Type",
  },{
    id: 3,
    name: "Importance",
  }]

  const [sortBy, setSortBy] = useState(0)
  const [filterByMultiple, setFilterByMultiple] = useState<MultiFilter[]>(settingsToDefaultFilter ? settingsToDefaultFilter(EditedFilters): clientInteractions ? [{filterId: 4, activeOptions: [3]}] : [])

  const sortByImportance = (interaction: InteractionSidebarFragment) => {
    let result = 3
    switch (interaction.importance) {
      case "Normal":
        result = 3
        break
      case "Notable":
        result = 2
        break
      case "Significant":
        result = 1
        break
      default:
        result = 3
        break
    }
    return result
  }

  const sortInteractionsByMultiple = (props:InteractionsSidebarProps) =>{
    let {search, interactions} = props
    let sortedResult:InteractionSidebarFragment[] = interactions.filter((i:InteractionSidebarFragment) => {
      if (search) {
        let searchString = (i.subject || "").toLocaleLowerCase().trim()
        if(showAssociation){
          searchString += " " +  (i.primaryManagerAssociation?.name || "").toLocaleLowerCase().trim() + " " + (i.productAssociations.map((p) => p.product?.name.toLocaleLowerCase().trim()).join(" "))
        }
        if (!searchString.includes(search.toLowerCase().trim())){
          return false
        }
      }

      if(filterByMultiple.length > 0) {
        let f = filterByMultiple.every(filterType => {
          let {filterId, activeOptions} = filterType
          if(activeOptions.length > 0) {
            let filter = EditedFilters.find(element=>element.filterId === filterId)
            if(filter){
              let {property, options} = filter
              let propertyVal = _.get(i, property)
              let propertyValMatchOption = false
              if(filter.matchFunction){
                propertyValMatchOption = options.some(option=>activeOptions.includes(option.id) && filter?.matchFunction &&filter?.matchFunction(option, propertyVal))
              }else if(Array.isArray(propertyVal)) {
                propertyValMatchOption = options.some(option=>activeOptions.includes(option.id) && (propertyVal.includes(option.value) || propertyVal.some((obj:any)=> option.property && _.get(obj, option.property) === option.value)))
              }else {
                propertyValMatchOption = options.some(option=>activeOptions.includes(option.id) && option.value === propertyVal)
              }
              return propertyValMatchOption
            }
          }
          return true
        })
        return f
      }else {
        return true
      }
    })

    switch (sortBy) {
      case 1:
        sortedResult = _sortBy(sortedResult, ["subject"])
        break
      case 2:
        sortedResult = _sortBy(sortedResult, ["type"])
        break
      case 3:
        sortedResult = orderBy(sortedResult, [sortByImportance])
        break
      default:
        sortedResult = orderBy(sortedResult, ["date"], ["desc"])
        break
    }
    return sortedResult
  }

  const [sortedInteractions, setSortedInteractions] = useState(()=>sortInteractionsByMultiple(props))

  const toggleMultipleFilters = (filter: {filterId:number, optionId: number|string}) => {
    let elementFound = false
    let newFilters = filterByMultiple.map(element=> {
      if(element.filterId === filter.filterId) {
        elementFound = true
        if(element.activeOptions.findIndex(el=> el === filter.optionId) > -1){
          let newOptionIds = element.activeOptions
          _.remove(newOptionIds, (item:any )  =>item as any === filter.optionId as any)
          return {filterId: element.filterId, activeOptions: newOptionIds}
        }else {
          return {filterId: element.filterId, activeOptions: [...element.activeOptions, filter.optionId]}
        }
      }
      return element
    })
    if(!elementFound) {
      newFilters = [...newFilters, {filterId: filter.filterId, activeOptions: [filter.optionId]}]
    }
    setFilterByMultiple(newFilters.filter(filter=>filter.activeOptions.length > 0))
    if(afterFilter){
      afterFilter(EditedFilters, newFilters)
    }
  }

  useEffect(() => {
    if(props.selectedInteraction === "" || props.selectedInteraction === "0"){
      if(interactions.length > 0 && !componentPiece){
        props.selectInteraction(first(interactions)?.id || "")
      }
    }
  }, [props.selectedInteraction])

  useEffect(() => {
    setSortedInteractions(()=>sortInteractionsByMultiple(props))
  }, [sortBy, filterByMultiple, props])

  let content = (<>
    {sortedInteractions.length > 0 && sortedInteractions.map((interaction: InteractionSidebarFragment, idx) => {
        let importanceClass = 'normal'
        switch(interaction.importance) {
          case InteractionImportance.Significant:
            importanceClass = 'significant'
            break;
          case InteractionImportance.Notable:
            importanceClass = 'notable'
            break;
        }

        return (
          <ListGroupItem tag="a" key={interaction.id} className={classNames("interaction-item", { active: interaction.id === props.selectedInteraction})} onClick={() => { props.selectInteraction(interaction.id) }}>
            <div className="interaction-icon">{ typeIcon(interaction, {size: "lg", className: importanceClass}) }</div>
            <div className="interaction-list-details">
              {showAssociation &&
                <div className="interaction-association">
                  <div className="interaction-association-manager">{interaction.primaryManagerAssociation?.name}</div>
                  {interaction.productAssociations.length > 0 &&
                    <div className="interaction-association-product">
                      {`> ${interaction.productAssociations[0].product?.name}`}
                      {interaction.productAssociations.length > 1 && " +"}
                    </div>
                  }
                </div>
              }
              <p className={classNames(importanceClass, "interaction-subject")}>{ interaction.subject }</p>
              <p className="interaction-date">
                {interaction.date}
                { interaction.importance && (
                  <span
                    title={interaction.importance}
                    className={classNames("interaction-badge", importanceClass)}
                  />
                )}
              </p>
            </div>
            {interaction.notes?.hasNotes && (
              <FontAwesomeIcon icon={["fal", "sticky-note"]} className="note-icon"></FontAwesomeIcon>
            )}
          </ListGroupItem>
        )
      })
    }
    {!componentPiece && sortedInteractions.length == 0 &&
      <div className="p-3 text-gray-70">
        There are no interactions associated with this {source} with the current filters.
      </div>
    }
  </>)

  // if(componentPiece) {
  //   return content
  // }

  let hideFilters = []
  if(clientInteractions) hideFilters.push(1)
  if(!componentPiece) hideFilters.push(5)

  return (
    <MultiplePicker
      // filters={filters}
      // activeFilters={filterByType}
      filters={EditedFilters}
      activeFilters={filterByMultiple}
      sortOptions={sortOptions}
      activeSort={sortBy}
      // onFilter={id => toggleFilters(id)}
      onFilter={({filterId, optionId}) => toggleMultipleFilters({filterId, optionId})}
      columnNumber={2}
      dropDownMenuClass="filter-dropdown"
      onSort={id => setSortBy(id)}
      searching={false}
      listClass="interaction-list"
      toolbarClass="interaction-toolbar"
      hideFilters={clientInteractions ? [1] : []}
      componentPiece={componentPiece}
      stayOpen={stayOpen}
    >
      {content}
    </MultiplePicker>
  )
}


interface InteractionDisplayProps {
  id: string
  editMode: boolean
  interaction: InteractionFragment
  setCurrentInteraction:(interaction:InteractionFragment) => void
  onChange: (notes: OrganizationInteractionNotesFragment | null, noteType: NoteChangeTypes) => void
  onProductNoteChange: (notes: ProductInteractionNotesFragment[]) => void
  onProductNoteDelete:(noteIds: number[]) => void
  user?: MeFragment
  setEditMode: (mode:boolean) => void
  refetchQuery: (variables?: Record<string, any> | undefined) => Promise<ApolloQueryResult<GetInteractionQuery>>
  auth: Auth
  addInteractionsMutation: AddInteractionAssociationMutationFn
  removeInteractionsMutation: RemoveInteractionAssociationMutationFn
  updateFileMutation: UpdateFileMetadataMutationFn
  // used for docs association & productInteraction notes setting
  associationType: "Manager" | "Product" | "GlidePath"
  associationId?: number
  downloadUrl?: (options?: QueryLazyOptions<Exact<{ id: string; }>> | undefined) => void
  urlLoading?: boolean
  urlData?: DownloadInteractionNoteQuery | undefined
  urlError?: ApolloError | undefined
  forceUpdate?: boolean
  notEditable?: boolean
  showAssociation?: boolean
  searchableDocuments?: FileBasicInfoFragment[]
}

type SpecificProductNotesListType =  Maybe<{
  __typename: "ProductInteractionNotes";
} & ProductInteractionNotesFragment>[]

interface InteractionDisplayState {
  currentState: InteractionFragment
  addAssociationInEditModalOpen: boolean
  associationsModalOpen: boolean
  openNotes: string[]
  associations: Association[]
  productAddLoading: boolean
  editMode: boolean
  documents?: InteractionFragment["documentAssociations"]
  downloadUrl?: (options?: QueryLazyOptions<Exact<{ id: string; }>> | undefined) => void
  urlLoading?: boolean
  urlErrorMessage?: string
  urlErrorModalOpen?: boolean
}

interface NotesFields extends FormInputField {
  collapseClass?: string
}

const OrganizationNotesList:NotesFields[] = [
  { property: "notes.organizationNotes.summary", label: "Summary", type: "textarea" },
  { property: "notes.organizationNotes.observation", label: "Observation", type: "textarea" },
  { property: "notes.organizationNotes.internalOpinion", label: "Private Notes", type: "textarea", collapseClass: 'private-note' },
]

const AllProductNotesList:NotesFields[] = [
  { property: "notes.allProductNotes.summary", label: "Summary", type: "textarea" },
  { property: "notes.allProductNotes.observation", label: "Observation", type: "textarea" },
  { property: "notes.allProductNotes.internalOpinion", label: "Private Notes", type: "textarea", collapseClass: 'private-note' },
]

const SpecificProductNotesList:NotesFields[] = [
  { property: "summary", label: "Summary", type: "textarea" },
  { property: "observation", label: "Observation", type: "textarea" },
  { property: "internalOpinion", label: "Private Notes", type: "textarea", collapseClass: 'private-note' },
]

// script for UrlErrorMessageModal CAL-1735
 const urlErrorMessageScript: ModalScript = {
  header: "Create PDF Error",
  body: "An error occurred, and PDF could not be generated. Please click on the help icon next to Create PDF for instructions on how to use this feature.",
  leaveButtonContent: "",
  stayButtonContent: "Confirm"
}

export class InteractionDisplay extends Component<InteractionDisplayProps,InteractionDisplayState> {
  constructor(props: InteractionDisplayProps) {
    super(props)
    const { interaction, editMode, downloadUrl, urlLoading, user } = props
    this.state = {
      currentState: interaction,
      openNotes: [],
      associationsModalOpen: false,
      associations: InteractionDisplay.generateAllInteractions(props.interaction),
      productAddLoading: false,
      editMode: editMode,
      addAssociationInEditModalOpen: false,
      documents: interaction.documentAssociations,
      downloadUrl: downloadUrl,
      urlLoading: !!urlLoading,
      urlErrorMessage: "",
      urlErrorModalOpen: false
    }
  }

  static getDerivedStateFromProps(props: InteractionDisplayProps, state:InteractionDisplayState) {
    return {
      currentState: !props.editMode ? props.interaction : state.currentState,
      openNotes: state.openNotes,
      associationsModalOpen: state.associationsModalOpen,
      associations: InteractionDisplay.generateAllInteractions(props.interaction),
      productAddLoading: state.productAddLoading
    }
  }

  static generateAllInteractions = (interaction:InteractionFragment | null) => {
    if(!interaction){
      return []
    }
    const otherManagerAssociations:Association[] = interaction.otherManagerAssociations.map(m => { return { __typename: m.__typename, id: m.id, name: m.name || '' } })
    const products = compact(interaction.productAssociations.map(p => p.product))
    const productAssociations: Association[] = compact(products.map(p => { return { __typename: p.__typename, id: p.id, name: p.name } }))
    const glidePathAssociations: Association[] = compact(interaction.glidePathAssociations.map(g => { return { __typename: g.__typename, id: g.id, name: g.name || '' } }))
    const allAssociations : Association[]= [...otherManagerAssociations, ...productAssociations, ...glidePathAssociations]
    return allAssociations
  }

  componentDidMount = () => {
    const {setCurrentInteraction, interaction} = this.props
    setCurrentInteraction(interaction)
  }

  componentDidUpdate =(prevProps: InteractionDisplayProps) => {
    let {interaction, editMode, forceUpdate, urlData, urlLoading, urlError} = this.props
    if (!_.isEqual(interaction.documentAssociations, prevProps.interaction.documentAssociations)) {
      this.setState({documents: interaction.documentAssociations})
    }
    if(prevProps.editMode && !editMode && forceUpdate) {
      // add this to forceUpdate after exit saving. CAL-1653 1660
      this.props.refetchQuery()
      return
    }
    if(!prevProps.urlData && urlData) {
      let url = urlData?.download?.url
      if(!!url) {
        console.log('Download started')
        const downloadLink = document.createElement("a")
        downloadLink.href = url
        downloadLink.download = "download"
        downloadLink.click()
        setTimeout(()=> {this.setState({ urlLoading })}, 2000);
      } else {
        console.error('no url')
        this.setState({urlErrorMessage: "", urlLoading}, () => this.setUrlErrorModalVisible(true))
      }
    }else if(!prevProps.urlError && urlError) {
      this.setState({urlErrorMessage: `${urlError.message}.`, urlLoading}, () => this.setUrlErrorModalVisible(true))
    }else if(prevProps.urlLoading !==  urlLoading) {
      this.setState({urlLoading})
    }else {
      console.log("exclusion")
    }
  }

  onChange = (property:string, value:string | PersonSelection) => {
    if (!get(this.state.currentState, property) && typeof value == 'string' && trim(value) === "<p></p>" ) {
      return
    }

    let newState = iassign(
      this.state.currentState,
      interaction => {
        let newInteraction = cloneDeep(interaction)
        let core_prop = property.replace(/\.body/,"").split('.')

        if (last(property.split('.')) === 'body') {
          set(newInteraction, flatten([core_prop, 'author']), this.props.user?.person)
        }

        set(newInteraction, flatten([core_prop, 'date']), moment().format('YYYY-MM-DD HH:mm:ss'))
        set(newInteraction, property, value)
        set(newInteraction, 'notes.hasNotes', true)
        return newInteraction
      }
    )

    this.setState({ currentState: newState }, () => {
      this.props.onChange(newState.notes?.organizationNotes || null, "organizationNotes")
      this.props.onChange(newState.notes?.allProductNotes || null, "allProductNotes")
    })
  }

  onProductSpecificChange = (product:AssociationProduct, property: string, value: string | PersonSelection) => {
    let newState = iassign(
      this.state.currentState,
      interaction => {
        let newInteraction = cloneDeep(interaction)
        let productNotes = newInteraction?.notes?.specificProductNotes
        let newProductNotes = cloneDeep(compact(productNotes)) || []
        let alteredProduct = find(newProductNotes, (note:ProductInteractionNotesFragment | null) => note?.product?.product?.id === product?.id)

        if (alteredProduct) {
          let core_prop = property.replace(/\.body/,"").split('.')

          if (last(property.split('.')) === 'body') {
            set(alteredProduct, flatten([core_prop, 'author']), this.props.user?.person)
          }

          set(alteredProduct, flatten([core_prop, 'date']), moment().format('YYYY-MM-DD HH:mm:ss'))
          set(alteredProduct, property, value)
        }
        set(newInteraction, 'notes.specificProductNotes', newProductNotes)
        return newInteraction
      }
    )
    set(newState, 'notes.hasNotes', true)
    this.setState({ currentState: newState }, () => {
      this.props.onProductNoteChange(flatten(compact(newState.notes?.specificProductNotes) || []))
    })
  }

  setAssociationsModalOpen = (open:boolean) => {
    this.setState({ associationsModalOpen: open})
  }

  onAssociationModalSave = () => {
    this.setAssociationsModalOpen(false)
    this.props.setEditMode(false)
    return this.props.refetchQuery()
  }

  addDocument = (documents:FileBasicInfoFragment[]) => {
    let interactionsAdded:{ id: string, orgs: number[], products:number[], glidePaths:number[], documents:string[]} = {
      id: this.props.id,
      orgs: [],
      products: [],
      glidePaths: [],
      documents: documents.map((document) => document.id)
    }

    this.props.addInteractionsMutation({ variables: { input: interactionsAdded } })
      .then(result => {
        if (result && result.data) {
          let newState = iassign(
            this.state.currentState,
            currentState => {
              let newCurrentState = cloneDeep(currentState)
              let docs = newCurrentState.documentAssociations
              set(newCurrentState, 'documentAssociations', unionBy(docs, documents, 'id'))
              return newCurrentState
            }
          )
          this.setState({ currentState: newState })
        }
      })
      .catch(err => {
        console.error("Error adding associations", err.message)
      })
  }

  removeDocument = (document:FileBasicInfoFragment) => {
    let interactionsRemoved:{ id: string, orgs: number[], products:number[], glidePaths:number[], documents:string[]} = {
      id: this.props.id,
      orgs: [],
      products: [],
      glidePaths: [],
      documents: [document.id],
    }

    this.props.removeInteractionsMutation({ variables: { input: interactionsRemoved } })
    .then(result => {
      if (result && result.data) {
        this.setState((state) => {
          let oldState = _.cloneDeep(state.currentState)
          let newState = iassign(
            oldState,
            currentState => {
              let newState = _.cloneDeep(currentState)
              let docs = newState.documentAssociations
              const idx = _.findIndex(docs, {'id': document.id})
              docs?.splice(idx, 1)
              set(newState, 'documentAssociations', docs)
              return newState
            }
          )

          return {...state, currentState: newState}
        })
      }
    })
    .catch(err => {
      console.error("Error adding associations", err.message)
    })
  }

  toggleNote = (noteId:string) => {
    let {openNotes} = this.state
    if (openNotes.indexOf(noteId) >= 0) {
      pull(openNotes, noteId)
    } else {
      openNotes.push(noteId)
    }

    this.setState({ openNotes })
  }

  noteOpen = (noteId:string, sectionCollapsedByDefault?:boolean):boolean => {
    // expand notes if editMode, except for private-notes and org notes in productInteractions
    if (this.state.openNotes.indexOf(noteId) > -1) {
      return true
    }else if (this.props.editMode && !sectionCollapsedByDefault){
      return true
    }
    return false
  }

  addProductAssociation = (product:ProductSelection, addInteractionMutation: AddInteractionAssociationMutationFn) => {
    if (!product?.product?.id) {
      return
    }

    if (!this.state.associations.filter(p => p.__typename === "ProductFields").map(p => p.id).includes(product.product.id)) {
      this.setState({ productAddLoading: true })
      let mutationData = { input: {
        id: this.state.currentState.id,
        products: [product?.product?.id],
        orgs: [],
        documents: [],
        glidePaths: []
      }}

      addInteractionMutation({ variables: mutationData })
        .then(result => {
          if (result && result.data) {
            //let unformattedNewData = { assets: result.data.assets?.org }
            this.addProductNote(product)
          }
        })
        .catch(err => {
          console.error("Error adding product to associations", err.message)
          this.setState({ productAddLoading: false })

        })
    } else {
      this.addProductNote(product)
    }
  }

  addProductNote = (product:ProductSelection) => {
    this.updateDocumentAssociations()

    if (!this.props.editMode) {
      this.setState({productAddLoading: false})
      return
    }

    let newInteractionNote: InteractionNoteDetailFragment = {
      __typename: 'InteractionNote',
      id: -1,
      body: '',
      author: this.props.user?.person,
      date: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    let newProductNote:ProductInteractionNotesFragment = {
      __typename: 'ProductInteractionNotes',
      product: product,
      summary: cloneDeep(newInteractionNote),
      observation: cloneDeep(newInteractionNote),
      internalOpinion: cloneDeep(newInteractionNote)
    }

    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["notes", "specificProductNotes"]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      productNotes => {
        return concat([newProductNote], cloneDeep(productNotes) || [])
      }
    )

    this.setState({ currentState: newState, productAddLoading: false }, () => {
      this.props.onProductNoteChange(flatten(compact(newState.notes?.specificProductNotes) || []))
    })
  }

  updateDocumentAssociations = () => {
    const associations = this.state.associations.concat({__typename: "Manager", id: this.state.currentState.primaryManagerAssociation?.id || "", name: ""})
    // Find the list of associations attached to all documents
    this.state.documents?.forEach((document) => {
      const managerAssociations:Association[] = compact(document.managers).map(m => { return { __typename: m?.__typename, id: m?.id, name: m?.name || '' } })
      const products = compact(document.products?.map(p => p?.product))
      const productAssociations: Association[] = products.map(p => { return { __typename: p?.__typename, id: p?.id, name: p?.name } })
      const glidePathAssociations: Association[] = compact(document.glidePaths).map(g => { return { __typename: g?.__typename, id: g?.id, name: g?.name || '' } })
      const addAssociations = differenceBy(associations, [...managerAssociations, ...productAssociations, ...glidePathAssociations] || [], (association:Association) => {
        return `${association.__typename}:${association.id}`
      })
      if(addAssociations.length > 0){
        let updateData = {
          managers: compact(union(document.managers?.map((ob:any) => ob.id), associations.flatMap((ob:any) => ob.__typename === "Manager" ? ob.id : []))),
          products: compact(union(document.products?.map((ob:any) => ob.product.id), associations.flatMap((ob:any) => ob.__typename === "ProductFields" ? ob.id : []))),
          glidePaths: compact(union(document.glidePaths?.map((ob:any) => ob.id), associations.flatMap((ob:any) => ob.__typename === "GlidePath" ? ob.id : []))),
        } as UpdateFileDataInput

        const input = { id: document.id, patch: updateData } as UpdateFileMetadataInput

        this.props.updateFileMutation({ variables: { input } })
          .catch(err => {
            console.error("Error Update File Metadata", err.message)
          })
      }
    })
  }

  removeProductNote = (productId:number) => {

    let specificProductNotes = this.state.currentState.notes?.specificProductNotes || []

    let allNoteIds = _.filter(specificProductNotes, note=>(note?.product?.product?.id === productId))

    let noteIdsRelated = allNoteIds.reduce((acc, note)=>{
      let newIds:number[] = []
      if(note?.summary?.id) newIds.push(note?.summary?.id)
      if(note?.observation?.id) newIds.push(note?.observation?.id)
      if(note?.internalOpinion?.id) newIds.push(note?.internalOpinion?.id)
      acc = [...acc, ...newIds]
      return acc
    }, [] as number[])

    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["notes", "specificProductNotes"]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      productNotes => {
        let newProductNotes = cloneDeep(productNotes as any[]) || []
        remove(newProductNotes, (note:ProductInteractionNotesFragment | null) => note?.product?.product?.id === productId)
        return newProductNotes
      }
    )

    this.setState({ currentState: newState }, () => {
      this.props.onProductNoteDelete((noteIdsRelated))
      this.props.onProductNoteChange(flatten(compact(newState.notes?.specificProductNotes) || []))
    })
  }

  interactionImportanceIcon = () => {
    const interaction = this.state.currentState
    let importanceClass = 'normal'

    switch(interaction.importance) {
      case InteractionImportance.Significant:
        importanceClass = 'significant'
        break;
      case InteractionImportance.Notable:
        importanceClass = 'notable'
        break;
    }

    return (<div className={classNames('interaction-importance-icon', importanceClass)}>{interaction.importance}</div>)
  }

  authorDetails = (note:NotesFields) => {
    const noteData = get(this.state.currentState, note.property)

    if (!get(noteData, 'author')) {
      let authorName = compact([this.props.user?.person?.firstName, this.props.user?.person?.lastName]).join(' ')
      if (!authorName) {
        authorName = "Select Author"
      }

      if (!this.props.editMode) {
        return (<span></span>)
      }

      return (
        <EmployeePicker
          managerId={244}
          onClick={(person) => { this.onChange(`${note.property}.author`, person)}}
          dropdownClass={"justify-content-end"}
        >
          <span>
            <DropdownToggle className="author-picker btn-no-style">
              { authorName }
              &nbsp;
              <FontAwesomeIcon icon="caret-down" />
            </DropdownToggle>

          </span>
        </EmployeePicker>

      )
    }

    const noteAuthorChange = moment(get(noteData, 'date'))
    const noteAuthor = get(noteData, 'author')
    let authorName = "Author"
    if (noteAuthor?.firstName || noteAuthor?.lastName) {
      authorName = compact([noteAuthor?.firstName, noteAuthor?.lastName]).join(' ')
    }

    if (!this.props.editMode) {
      return (
        <span>
          Last updated by { authorName } on {noteAuthorChange.format('MMM D, YYYY')}
        </span>
      )
    }

    return (
      <EmployeePicker
        managerId={244}
        onClick={(id) => { this.onChange(`${note.property}.author`, id)}}
        dropdownClass={"justify-content-end"}
      >
        <span>
        Last updated by
        <DropdownToggle className="author-picker btn-no-style">
          { authorName }
          &nbsp;
          <FontAwesomeIcon icon="caret-down" />
        </DropdownToggle>
        on {noteAuthorChange.format('MMM D, YYYY')}
      </span>
      </EmployeePicker>
    )
  }

  productAuthorDetails = (product: AssociationProduct, note:NotesFields) => {
    const productNoteData = find(this.state.currentState.notes?.specificProductNotes, (n:ProductInteractionNotesFragment) => n.product?.product?.id === product.id)

    const noteData = get(productNoteData, note.property)
    if (!get(noteData, 'author')) {
      let authorName = compact([this.props.user?.person?.firstName, this.props.user?.person?.lastName]).join(' ')
      if (!authorName) {
        authorName = "Select Author"
      }

      if (!this.props.editMode) {
        return (<span></span>)
      }

      return (
        <EmployeePicker
          managerId={244}
          onClick={(person) => { this.onProductSpecificChange(product,`${note.property}.author`, person)}}
          dropdownClass={"justify-content-end"}
        >
          <span>
            <DropdownToggle className="author-picker btn-no-style">
              { authorName }
              &nbsp;
              <FontAwesomeIcon icon="caret-down" />
            </DropdownToggle>

          </span>
        </EmployeePicker>

      )
    }

    const noteAuthorChange = moment(get(noteData, 'date'))
    const noteAuthor = get(noteData, 'author')
    let authorName = "Author"
    if (noteAuthor?.firstName || noteAuthor?.lastName) {
      authorName = compact([noteAuthor?.firstName, noteAuthor?.lastName]).join(' ')
    }

    if (!this.props.editMode) {
      return (
        <span>
          Last updated by { authorName } on {noteAuthorChange.format('MMM D, YYYY')}
        </span>
      )
    }

    return (
      <EmployeePicker
        managerId={244}
        onClick={(id) => { this.onProductSpecificChange(product,`${note.property}.author`, id)}}
        dropdownClass={"justify-content-end"}
      >
        <span>
        Last updated by
        <DropdownToggle className="author-picker btn-no-style">
          { authorName }
          &nbsp;
          <FontAwesomeIcon icon="caret-down" />
        </DropdownToggle>
        on {noteAuthorChange.format('MMM D, YYYY')}
      </span>
      </EmployeePicker>
    )
  }

  organizationNotes = () => {
    return OrganizationNotesList.map((note:NotesFields, idx) => {
    if (note.collapseClass === 'private-note' && !this.props.auth.checkPermissions(["view:interactions_private"])){
      return (<React.Fragment key={`note-wrapper-org-${note.property}`}></React.Fragment>)
    }
    const note_property = `${note.property}.body`
    const noteData = get(this.state.currentState, note.property)
    const propertyVal = get(noteData, 'body')

    const editor = (
      <RichTextEditor
        value={propertyVal || ""}
        editMode={this.props.editMode}
        updateValue={(value) => {this.onChange(note_property, value)}}
        stopAutoFocus={true}
        placeholder="Include information that applies to the same firm"
      />)
    const isEmpty = !propertyVal
    // cal-1544 collapse if empty or private-note or OrgNotes in ProductInteraction
    const sectionCollapsedByDefault = note.collapseClass === 'private-note' || this.props.associationType === "Product"

    const noteComponent = sectionCollapsedByDefault ?
    ( <Collapse isOpen={this.noteOpen(note.property, sectionCollapsedByDefault)} className={classNames("mb-2 expandable-container",this.noteOpen(note.property, sectionCollapsedByDefault) ? 'expanded' : '', {empty:isEmpty})
  }>{editor}</Collapse>)
    : (<div onClick={(event)=>{event.preventDefault()}} className={classNames("mb-2 expandable-container",this.noteOpen(note.property, sectionCollapsedByDefault) ? 'expanded' : '', {empty:isEmpty})}>{editor}</div>
    )
    // expandable link in text expandable feature
    const textExpandButton =
      (<div className="expand-link">
        <Button color="link" onClick={(event) => {event.stopPropagation(); this.toggleNote(note.property)}}>
          <FontAwesomeIcon
            icon={this.noteOpen(note.property, sectionCollapsedByDefault)  ? "chevron-up" : "chevron-down"}
            size="sm"
          />
          <span className="pl-2 expand-text">{this.noteOpen(note.property, sectionCollapsedByDefault)  ? "Collapse" : "Expand"}</span>
        </Button>
      </div>)
    return (
      //CAL-1569 update lag due to not-specific key
      <div key={`note-wrapper-org-${note.property}-${this.state.currentState.id}`} className={classNames("interaction-note", note?.collapseClass, "expandable-textarea-container")}>
        <ListGroupItem tag="div" onClick={(event) => {event.stopPropagation(); this.toggleNote(note.property)}}>
            <FontAwesomeIcon icon={this.noteOpen(note.property, sectionCollapsedByDefault) ? "caret-down" : "caret-right"} />
            <div className="note-title">
              { note.label }
            </div>
          <div className="text-center internal-use"> { note.collapseClass === 'private-note' && "INTERNAL USE ONLY" }</div>
          <div className="author-detail text-right" onClick={(event)=>{
            if(this.props.editMode){event.stopPropagation()}}}
            style={{zIndex:1023-idx}}
            >
            { this.authorDetails(note) }
          </div>
        </ListGroupItem>
        {noteComponent}
        {!sectionCollapsedByDefault && textExpandButton}
      </div>
      )
    })
  }

  allProductsNotes = () =>{
    return AllProductNotesList.map((note:NotesFields, idx) => {
      if (note.collapseClass === 'private-note' && !this.props.auth.checkPermissions(["view:interactions_private"])){
        return (<React.Fragment key={`note-wrapper-allProd-${note.property}`}></React.Fragment>)
      }
      const note_property = `${note.property}.body`
      const noteData = get(this.state.currentState, note.property)
      const propertyVal = get(noteData, 'body') || ""


      const editor = (
        <RichTextEditor
          value={propertyVal || ""}
          editMode={this.props.editMode}
          updateValue={(value) => {this.onChange(note_property, value)}}
          stopAutoFocus={true}
          placeholder="Include information that applies to the same firm"
        />)
      const isEmpty = !propertyVal
      // cal-1544 collapse if empty or private-note
      const sectionCollapsedByDefault = note.collapseClass === 'private-note'

      let noteComponent = sectionCollapsedByDefault ?
      ( <Collapse isOpen={this.noteOpen(note.property, sectionCollapsedByDefault)} className={classNames("mb-2 expandable-container",this.noteOpen(note.property, sectionCollapsedByDefault) ? 'expanded' : '', {empty:isEmpty})
      }>{editor}</Collapse>)
      : (<div onClick={(event)=>{event.stopPropagation()}} className={classNames("mb-2 expandable-container",this.noteOpen(note.property, sectionCollapsedByDefault) ? 'expanded' : '', {empty:isEmpty})}>{editor}</div>
      )
      // expandable link in text expandable feature
      const textExpandButton =
        (<div className="expand-link">
          <Button color="link" onClick={(event) => {event.stopPropagation(); this.toggleNote(note.property)}}>
            <FontAwesomeIcon
              icon={this.noteOpen(note.property, sectionCollapsedByDefault)  ? "chevron-up" : "chevron-down"}
              size="sm"
            />
            <span className="pl-2 expand-text">{this.noteOpen(note.property, sectionCollapsedByDefault)  ? "Collapse" : "Expand"}</span>
          </Button>
        </div>)

      return (
        //CAL-1569 update lag due to not-specific key
        <div key={`note-wrapper-allProd-${note.property}-${this.state.currentState.id}`} className={classNames("interaction-note", note?.collapseClass, "expandable-textarea-container")}>
          <ListGroupItem tag="div" onClick={(event) => {event.stopPropagation(); this.toggleNote(note.property)}}>
              <FontAwesomeIcon icon={this.noteOpen(note.property, sectionCollapsedByDefault) ? "caret-down" : "caret-right"} />
              <div className="note-title">
                { note.label }
              </div>
              <div className="text-center internal-use"> { note.collapseClass === 'private-note' && "INTERNAL USE ONLY" }</div>
            <div className="author-detail text-right" onClick={(event)=>{
              if(this.props.editMode){event.stopPropagation()}}}
              style={{zIndex:1010-idx}}
              >
              { this.authorDetails(note) }
            </div>
          </ListGroupItem>
          {noteComponent}
          {!sectionCollapsedByDefault && textExpandButton}
        </div>
        )
      })
    }

  specificProductNotes = (product:ProductInteractionNotesFragment["product"]) => {
    const productFields:AssociationProduct|null|undefined = product?.product
    if(!product || !productFields){
      return <></>
    }

    return SpecificProductNotesList.map((note:NotesFields, idx:number) => {
      const wrapperKey = `note-wrapper-product-specific-${product.product?.id}-${note.property}`
      if (note.collapseClass === 'private-note' && !this.props.auth.checkPermissions(["view:interactions_private"])){
        return (<React.Fragment key={wrapperKey}></React.Fragment>)
      }
      const note_property = `${note.property}.body`
      const productNoteData = find(this.state.currentState.notes?.specificProductNotes, (n:ProductInteractionNotesFragment) => n.product?.product?.id === productFields.id)
      const noteData = get(productNoteData, note.property)
      const propertyVal = get(noteData, 'body')
      const editorKey = `note-editor-product-specific-${product.product?.id}-${note.property}`
      const editor = (
        <RichTextEditor
          value={propertyVal || ""}
          editMode={this.props.editMode}
          updateValue={(value) => {this.onProductSpecificChange(productFields, note_property, value)}}
          stopAutoFocus={true}
          placeholder="Include information that applies to the same firm"
        />)
      const isEmpty = !propertyVal
      // cal-1544 collapse if empty or OrgNotes in ProductInteraction
      const sectionCollapsedByDefault = note.collapseClass === 'private-note'

      let noteComponent = sectionCollapsedByDefault ?
      ( <Collapse isOpen={this.noteOpen(note.property, sectionCollapsedByDefault)} className={classNames("mb-2 expandable-container",this.noteOpen(note.property, sectionCollapsedByDefault) ? 'expanded' : '', {empty:isEmpty})
      }>{editor}</Collapse>)
      : (<div onClick={(event)=>{event.stopPropagation()}} className={classNames("mb-2 expandable-container",this.noteOpen(note.property, sectionCollapsedByDefault) ? 'expanded' : '', {empty:isEmpty})}>{editor}</div>
      )
      // expandable link in text expandable feature
      const textExpandButton =
        (<div className="expand-link">
          <Button color="link" onClick={(event) => {event.stopPropagation(); this.toggleNote(note.property)}}>
            <FontAwesomeIcon
              icon={this.noteOpen(note.property, sectionCollapsedByDefault)  ? "chevron-up" : "chevron-down"}
              size="sm"
            />
            <span className="pl-2 expand-text">{this.noteOpen(note.property, sectionCollapsedByDefault)  ? "Collapse" : "Expand"}</span>
          </Button>
        </div>)

      return (
        <div key={wrapperKey} className={classNames("interaction-note", note?.collapseClass, "expandable-textarea-container")}>
          <ListGroupItem tag="div" onClick={(event) => {event.stopPropagation(); this.toggleNote(note.property)}}>
            <FontAwesomeIcon icon={this.noteOpen(note.property, sectionCollapsedByDefault) ? "caret-down" : "caret-right"} />
            <div className="note-title">
              { note.label }
            </div>
            <div className="text-center internal-use"> { note.collapseClass === 'private-note' && "INTERNAL USE ONLY" }</div>
            <div className="author-detail text-right" onClick={(event)=>{
              if(this.props.editMode){event.stopPropagation()}}}
              // CAL-2097
              style={{zIndex:1180-idx}}
              >
              {this.productAuthorDetails(productFields, note)}
            </div>
          </ListGroupItem>
          {noteComponent}
          {!sectionCollapsedByDefault && textExpandButton}
        </div>
      )
    })
  }

  getSpecificProductNotesList = (specificProductNotes:SpecificProductNotesListType) => {
    return specificProductNotes.map((productNotes:ProductInteractionNotesFragment | null, idx:number) => {
      const product = productNotes?.product
      const productFields:AssociationProduct | undefined | null = productNotes?.product?.product

      if (!product || !productFields) { return <React.Fragment key={idx}></React.Fragment>}

      return (
        <React.Fragment key={`specific-prod-notes-${idx}-${productFields.id}`}>
          <h3 className="headline text-callan-blue mt-4">
            {productFields.name}
            { this.props.editMode && (<FontAwesomeIcon className="ml-2" icon="trash" onClick={() => { this.removeProductNote(productFields.id) }} />)}
          </h3>
          <ListGroup>
            {this.specificProductNotes(product)}
          </ListGroup>
        </React.Fragment>
      )
    })
  }

  setAddAssociationInEditModalOpen =(open:boolean) => {
    this.setState({addAssociationInEditModalOpen: open})
  }

  handleAddAssociationClick = () => {
    if (this.props.editMode) {
      this.setAddAssociationInEditModalOpen(true)
    }else {
      this.setAssociationsModalOpen(true)
    }
  }

  closeModal = (callback?: (() => void) | undefined) => this.setState({addAssociationInEditModalOpen: false}, callback)

  handleConfirmNavigationClick = () => this.closeModal(() => {
    this.setAssociationsModalOpen(true)
    this.props.setEditMode(false)
  })

  downloadInteractionNote = (id: string) => {
    const {downloadUrl} = this.props
    if(downloadUrl) {
      this.setState({urlErrorMessage:""}, ()=> downloadUrl({variables:{id}}))
    }
  }

  showExportButton = (interaction: InteractionFragment) => {
    return !this.props.editMode && interaction.notes?.hasNotes
  }

  setUrlErrorModalVisible = (state: boolean) => {
    this.setState({urlErrorModalOpen: state})
  }

  render() {
    if (this.props.id === '') {
      return <></>
    }
    let downloadPermission = this.props.auth.checkPermissions(["download:meeting_notes"])
    const notEditable = this.props.notEditable
    const interaction = this.state.currentState
    const allAssociations = this.state.associations
    const {urlErrorMessage, urlErrorModalOpen } = this.state
    let errorScript = urlErrorMessageScript
    const showAssociation = this.props.showAssociation
    if(urlErrorMessage) {
      errorScript = {...errorScript, body: urlErrorMessage}
    }

    const heading = (
      <Row>
        <Col>
          {showAssociation &&
            <div className="interaction-association">
              <div className="interaction-association-manager">{interaction.primaryManagerAssociation?.name}</div>
              {interaction.productAssociations.length > 0 &&
                <div className="interaction-association-product">
                  {`> ${interaction.productAssociations[0].product?.name}`}
                  {interaction.productAssociations.length > 1 && " +"}
                </div>
              }
            </div>
          }
          <h2 className="headline underline d-flex justify-content-between">
            { interaction.subject }
            <GuardModal
              key={`${interaction.id}-url-error-modal`}
              open={!!urlErrorModalOpen}
              stay={() => this.setUrlErrorModalVisible(false)}
              leave={() => this.setUrlErrorModalVisible(false)}
              script={errorScript}
              hide={{leaveButton: true}}
            />
          </h2>
        </Col>
      </Row>
    )

    // CAL-1541 modals for add association, no documents in view mode
    const forceValues:ForceValuesProps = {
      manager_ids: compact(union(interaction.otherManagerAssociations.map((ob:any) => ob.id), [interaction.primaryManagerAssociation?.id])),
      product_ids: compact(interaction.productAssociations.map((ob:any) => ob.product.id)),
      glidePath_ids: compact(interaction.glidePathAssociations.map((ob:any) => ob.id)),
    }
    let hasDocuments = this.state.documents && this.state.documents?.length > 0
    let isCallan = this.props.auth.checkPermissions(["edit:documents_restricted"])
    let isClient = this.props.auth.checkPermissions(["view:client_documents"])
    let permittedDocuments = this.state.documents?.filter(doc => isCallan || doc?.access?.code === "_1" || ( isClient && doc?.access?.code === "_3") )  as FileBasicInfoFragment[]

    let documents = (
      <DocumentCompactList
        associationId={interaction.primaryManagerAssociation?.id}
        associationType={"Manager"}
        documents={permittedDocuments}
        addDocument={(document:FileBasicInfoFragment[]) => this.addDocument(document)}
        removeDocument = {(document:FileBasicInfoFragment) => this.removeDocument(document)}
        user={this.props.user||undefined}
        auth={this.props.auth}
        forceValues={forceValues}
        notEditable={notEditable}
        includeSearch={true}
        searchableDocuments={this.props.searchableDocuments}
      />
    )

    let modals = (
      <>
        <InteractionAssociationsModal
          key={`${interaction.id}-interaction-associations-modal`}
          modalOpen={this.state.associationsModalOpen}
          setModalOpen={this.setAssociationsModalOpen}
          interaction={interaction}
          onSaveSuccess={this.props.refetchQuery}
          allAssociations={this.state.associations}
          documents={this.state.documents || []}
          primaryManagerId={interaction.primaryManagerAssociation?.id || 0}
        />
        <GuardModal
          key={`${interaction.id}-add-associations-inedit-modal`}
          open={this.state.addAssociationInEditModalOpen}
          stay={() => this.closeModal()}
          leave={this.handleConfirmNavigationClick}
        />
      </>
    )
    let rightColumn = (
      <Col>
        <h3 className="headline underline mt-4 d-flex justify-content-between">
          <span>Salesforce Information</span>
          { this.props.auth.checkPermissions(["view:all_clients"]) &&
            <button className="btn-no-style" onClick={() => { window.open(`https://callan.lightning.force.com/${interaction.id}`,'_blank')}}>
              <FontAwesomeIcon icon="external-link" />
            </button>
          }
        </h3>
        <dl className="interaction-data">
          <dt>Subject</dt><dd>{interaction.subject}</dd>
          <dt>Date</dt><dd>{interaction.date}</dd>
          <dt>Type</dt>
          <dd>
            {typeIcon(interaction)}  {hyphenNateType(interaction.type)}
          </dd>
          <dt>Importance</dt><dd>
            {this.interactionImportanceIcon()}
          </dd>
          <dt>Contact</dt><dd>{interaction.contacts?.map((c, idx) =>(<span key={idx}>{c ? `${c?.firstName} ${c?.lastName}`: ""}<br/></span>))}</dd>
          <dt>Callan</dt><dd>{interaction.callanPeople?.map((c, idx) =>(<span key={idx}>{c ? `${c?.firstName} ${c?.lastName}`: ""}<br/></span>))}</dd>
        </dl>
        <h3 className="headline underline mt-4 d-flex justify-content-between">
          <span className="my-auto">Associations</span>
          {/* CAL-1541 currently not working in view mode*/}
          {!notEditable && this.props.auth.checkPermissions(["edit:interactions"]) &&
            <Button className="btn-no-style" size="sm" onClick={this.handleAddAssociationClick}>
              <span>Add</span>
              <FontAwesomeIcon icon="plus-circle" className="ml-1 text-blue-100"/>
            </Button>
          }
        </h3>
        {interaction?.primaryManagerAssociation && <InteractionAssociationsList interaction={interaction} associations={[interaction.primaryManagerAssociation]} flat removable={false}/>}
        <InteractionAssociationsList interaction={interaction} associations={allAssociations} flat removable={!this.props.notEditable}/>

      </Col>)
    if (!interaction.notes?.hasNotes && !this.props.editMode) {
      return (
        <Col md="8" lg="9" className="pl-md-1">
          <div className="pane">
            {heading}
            <Row>
              <Col>
                <Row>
                  <Col lg="9" md="8">
                    <div className='d-flex flex-column w-100 align-items-center justify-content-center background-gray-10' style={{ height: 700 }}>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        {!notEditable && this.props.auth.checkPermissions(["edit:interactions"]) &&
                          <Button onClick={() => this.props.setEditMode(true)} color="primary" className="d-flex">
                            <FontAwesomeIcon
                              icon="plus-circle"
                              size="xs"
                              className=""
                            />
                            &nbsp; Add Notes
                          </Button>
                        }
                        <p className="text-center d-flex mt-2 mb-0">
                          No notes have been added to this interaction.
                        </p>
                        <p>
                        {!notEditable && this.props.auth.checkPermissions(["edit:interactions"]) &&
                          <>
                            <span className="fake-link"  onClick={() => this.props.setEditMode(true)}>Click Add Notes</span> to add some.
                          </>
                        }
                        </p>
                      </div>
                    </div>
                    {hasDocuments && documents}
                    {modals}
                  </Col>
                  {rightColumn}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      )
    }

    return (
      <Col md="8" lg="9" className="pl-md-1">
        <div className="pane">
          {heading}
          <Row>
            <Col>
            {/* Add key to silent can't perform a react state update on an unmounted component warning.
              Forget it , doesn't work, wait for react18*/}
              <Mutation<AddInteractionAssociationMutation,AddInteractionAssociationMutationVariables> mutation={ADD_INTERACTION_ASSOCIATION} key={`interaction-mutation-${interaction.id}`}>
                {(addInteractionAssociation) => (
                  <Row key={`interaction-mutation-${interaction.id}--row`}>
                    <Col lg="9" md="8">
                      <h3 className="headline underline mt-4">Organization Notes</h3>
                      <ListGroup onClick={(e)=>e.stopPropagation()} key={`interaction-${interaction.id}-org-notes`}>
                        {this.organizationNotes()}
                      </ListGroup>
                      <h3 className="headline underline mt-4">All Associated Products</h3>
                      <ListGroup key={`interaction-${interaction.id}-all-prod-notes`}>
                        { this.allProductsNotes()}
                      </ListGroup>
                      <h3 className="headline underline mt-4">Product Specific </h3>
                        <div className="ml-auto flex-grow-1">
                          {interaction.primaryManagerAssociation && (
                            <InteractionProductPicker
                              managerId={interaction?.primaryManagerAssociation?.id || 0}
                              title="Select a product"
                              dropdownClass="w-100"
                              currentProducts={compact(interaction?.notes?.specificProductNotes?.map(p => p?.product?.product) || []) as AssociationProduct[]}
                              onClick={(product) => { this.addProductAssociation(product, addInteractionAssociation) }}
                            >
                              <>
                                { this.state.productAddLoading && (<small className="ml-auto">Adding Product <FontAwesomeIcon icon="cog" spin /> </small>)}
                                { this.props.editMode && !this.state.productAddLoading && (
                                  <DropdownToggle className="btn-no-style ml-auto">
                                    Add Product
                                    <FontAwesomeIcon icon="plus-circle" className="ml-2"/>
                                  </DropdownToggle>
                                )}
                              </>
                            </InteractionProductPicker>
                          )}
                        </div>
                      { interaction.notes?.specificProductNotes && this.getSpecificProductNotesList(interaction.notes.specificProductNotes)}
                      {documents}
                      {modals}
                    </Col>
                    {rightColumn}
                  </Row>
                )}
              </Mutation>
            </Col>
          </Row>
        </div>
      </Col>
    )
  }
}

interface InteractionProductPickerProps {
  managerId: number
  children: JSX.Element
  dropdownClass?: string
  title: string
  onClick: (product:ProductSelection) => void
  currentProducts: AssociationProduct[]
}

interface ProductSelection {
  __typename: "OpenEndedEquity" | "OpenEndedPassiveEquity" | "OpenEndedFixedIncome" | "OpenEndedPassiveFixedIncome" | "OpenEndedHedgeFund" | "ClosedEndedHedgeFund" | "OpenEndedMAC" | "OpenEndedPassiveMAC" | "OpenEndedTargetDate" | "ClosedEndedPrivateCredit" | "ClosedEndedPrivateEquity" | "ClosedEndedRealAssets" | "OpenEndedRealAssets" | "OtherProduct"
  product: {
    __typename: 'ProductFields'
    id: number
    name: string
  }
}

const InteractionProductPicker: React.FC<InteractionProductPickerProps> = ({ managerId, title, children, dropdownClass, onClick, currentProducts }: InteractionProductPickerProps) => {
  const { loading, error, data } = useManagerAllProductsQuery({
    variables: { id: managerId }
  })

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  if (loading || error || data?.assets?.__typename !== 'Manager' || !data?.assets?.products || data?.assets?.products == null ) {
    return (children)
  }
  const currentProductIds = compact(currentProducts.map(p => p?.id))

  let products:ProductSelection[] = data.assets.products.filter((product) => product?.product?.id && !currentProductIds.includes(product.product.id)) as ProductSelection[]
  // fix CAL-1416 sort alphabetically case-insensitive
  products = _.sortBy(products, [(obj)=>(obj.product.name).toLowerCase()])

  return (
    <Dropdown className={classNames("headline-dropdown product-picker-dropdown pr-0", dropdownClass)} isOpen={dropdownOpen} toggle={toggle} style={{zIndex:1000}}>
      { children }
      <DropdownMenu>
        <h5>{title}</h5>
        <InteractionsProductsSearch data={products} onClick={(product) => { onClick(product); toggle() }} />
      </DropdownMenu>
    </Dropdown>
  )

}

interface InteractionsProductsSearchProps {
  data:ProductSelection[]
  onClick: (product:ProductSelection) => void

}
interface InteractionsProductsSearchState {
  products:ProductSelection[]
  search: string
}

export class InteractionsProductsSearch extends Component<InteractionsProductsSearchProps, InteractionsProductsSearchState> {
  state = {
    products: this.props.data,
    search: ""
  }

  componentWillReceiveProps(nextProps:InteractionsProductsSearchProps) {
    this.setState({ products: nextProps.data });
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredProducts = filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p?.product?.name.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      products: filteredProducts
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="6">
            <Form>
              <FormGroup row className="relative m-0">
                <Input
                  type="text"
                  placeholder="Find Product by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          { this.state.products?.map((product) => {
            if (!product || !product?.product?.id) { return <React.Fragment key={"no-product"}></React.Fragment> }

            return (<ListGroupItem tag="a" key={`pp-product-${product.product.id}`} onClick={() => this.props.onClick(product)}>{product.product.name}</ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

export default ManagerInteractions
