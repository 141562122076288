import classnames from 'classnames'
import React, { Component } from 'react'
import { Redirect, Switch } from 'react-router'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { Container, Row, Col, Nav, NavItem, NavLink, Breadcrumb, BreadcrumbItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, Form, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap'
import _ from "lodash"

import Auth from '../../Auth/Auth'
import { appProps } from '../../queries/extended/appProps'
import { GlidePathRouteProps } from '../../queries/extended/types/GlidePath'
import { useGlidePathBasicQuery } from '../../__generated__/graphql'
import { CrumbRoute } from '../../routes/crumb-route'
import { WithTopNav } from '../ui/LayoutWrapper'
import LoadingOverlay from '../ui/Loading'
import GlidePathOverview from './GlidePathOverview'
import GlidePathSummary from './GlidePathSummary'
import GlidePathSeries from './GlidePathSeries'
import GlidePathInteractions from './GlidePathInteractions'
import GlidePathSeriesPicker from '../ui/Pickers/GlidePathSeriesPicker'
import GlidePathDocuments from './GlidePathDocuments'
import ErrorDisplay from '../Shared/ErrorDisplay'

interface authProps {
  auth: Auth
}

const QueryGlidePathDetails: React.FC<appProps & GlidePathRouteProps> = props => {
  const { match, ...rest } = props
  const { glidepathId } = match.params as {glidepathId: string}
  const history = useHistory()
  const auth = props.auth

  const { loading, error, data } = useGlidePathBasicQuery({
    variables: { id: parseInt(glidepathId) }
  })

  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <div>
        <ErrorDisplay error={error}/>
      </div>
    )
  }
  if (!data || data.glidePath === null) {
    return <p>Target Date Family Data not found</p>
  }
  const { type, subtype } = match.params as {type: string, subtype?: string| undefined}
  const urlWithType = subtype
    ? match.url.slice(0, -subtype.length - 1)
    : match.url
  const urlWithoutType = type
    ? urlWithType.slice(0, -type.length - 1)
    : urlWithType
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <Col xs="10" sm="8" md="6">
                <Breadcrumb>
                  <BreadcrumbItem className="headline-breadcrumbs" key="test" onClick={() => history.push(`/managers/${data.glidePath?.manager?.id}`)}>
                    {data.glidePath?.manager?.name}
                  </BreadcrumbItem>
                </Breadcrumb>
                { data.glidePath?.manager &&
                  <GlidePathSeriesPicker
                    managerId={data.glidePath.manager.id}
                    title={`View another target date series by ${data.glidePath.manager.name || ''}`}
                    onClick={(seriesId) => { window.location.href = `/glidepaths/${seriesId}`}}
                >
                  <div className='dropdown'>
                    <DropdownToggle caret>
                      <h2 className="headline">{data.glidePath.name || ""}</h2>
                    </DropdownToggle>
                    {!data.glidePath.dataCollect &&
                      <span className="inactive-tag m-2">Inactive</span>
                    }
                  </div>
                  </GlidePathSeriesPicker>
                }
              </Col>
            </Row>
            <Nav className="sub-nav sub-nav-primary" tabs role="group" aria-label="Manager Subpage">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutType}/overview`) === 0
                  })}
                  onClick={() => history.push(`${urlWithoutType}/overview`)}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutType}/glidepath`) === 0
                  })}
                  onClick={() => history.push(`${urlWithoutType}/glidepath`)}
                >
                  Glide Path
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: match.url.indexOf(`${urlWithoutType}/series`) === 0
                  })}
                  onClick={() => history.push(`${urlWithoutType}/series`)}
                >
                  Series
                </NavLink>
              </NavItem>
              {auth.checkPermissions(["view:interactions"]) &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutType}/interactions`) === 0
                    })}
                    onClick={() => history.push(`${urlWithoutType}/interactions`)}
                  >
                    Interactions
                  </NavLink>
                </NavItem>
              }
              {(auth.checkPermissions(["view:documents"]) || auth.checkPermissions(["view:my_documents"]) || auth.checkPermissions(["view:manager_documents"]) || auth.checkPermissions(["view:all_documents"])) &&
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: match.url.indexOf(`${urlWithoutType}/documents`) === 0
                    })}
                    onClick={() => history.push(`${urlWithoutType}/documents`)}
                  >
                    Documents
                  </NavLink>
                </NavItem>
              }
            </Nav>
          </Col>
        </Row>
      </Container>
      <Switch>
        <CrumbRoute
          exact
          title="overview"
          path={`${urlWithoutType}/overview`}
          render={() => (
            <GlidePathOverview id={parseInt(glidepathId)} {...props}/>
          )}
        />
        <CrumbRoute
          title="glidepath"
          path={`${urlWithoutType}/glidepath/:subtype(allocation|returns)?`}
          render={() => (
            <GlidePathSummary glidepathId={parseInt(glidepathId)} {...props} />
          )}
        />
        <CrumbRoute
          title="series"
          path={`${urlWithoutType}/series`}
          render={() => (
            <GlidePathSeries glidepathId={parseInt(glidepathId)} {...props} />
          )}
        />
        {auth.checkPermissions(["view:interactions"]) &&
          <CrumbRoute
            title="interactions"
            path={`${urlWithoutType}/interactions/:id([A-Za-z0-9]+)?`}
            render={() =>
              <GlidePathInteractions
                managerId={data.glidePath?.manager?.id || 0}
                glidePathId={parseInt(glidepathId)}
                history={history}
                location={props.location}
                auth={auth}
              />
            }
          />
        }
        {(auth.checkPermissions(["view:documents"]) || auth.checkPermissions(["view:my_documents"]) || auth.checkPermissions(["view:manager_documents"]) || auth.checkPermissions(["view:all_documents"])) &&
          <CrumbRoute
            title="documents"
            path={`${urlWithoutType}/documents`}
            render={() =>
              <GlidePathDocuments
              managerId={data.glidePath?.manager?.id || 0}
              glidePathId={parseInt(glidepathId)}
              history={history}
              location={props.location}
              auth={auth}
            />}
          />
        }
        <Redirect to={`${urlWithoutType}/overview`} />
      </Switch>
    </div>
  )
}
export default WithTopNav(QueryGlidePathDetails)
