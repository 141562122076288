import { ConfigType } from '../../../helpers/constant'

const productBasicConfig: ConfigType = {
  id: true,
  manager: true,
  name: true,
  assetClass: true
}

const productManagementBasicConfig: ConfigType = {
  managementType: false, // securityManagementStyle
  portfolioConstructionMethod: false,
  investmentManagementStrategy: false,
  currencyHedging: false
}

const valueAddedConfig: ConfigType = {
  valueAdded: false
}

const attributedTrackingErrorConfig: ConfigType = {
  securitiesLending: false,
  constructionMethodology: false,
  other: false
}

const performanceCompositeDispersionConfig: ConfigType = {
  quarterEndDate: false,
  compositeAccounts: false,
  accountsExplanation: false,
  minReturn: false,
  maxReturn: false,
  weightedStandardDeviation: false
}

export const passiveProductManagementConfig: {
  [property: string]: ConfigType | boolean
} = {
  valueAdded: false,
  passivePortfolioConstruction: true,
  attributedTrackingError: {
    securitiesLending: true,
    constructionMethodology: true,
    other: true
  }
}

export const productManagementComplianceConfig: ConfigType = {
  currentTeamStartDate: false,
  trackRecordComments: false,
  previousFirmTrackRecord: false,
  GIPSMethodology: false,
  GIPSAuditor: false,
  Auditor: false,
  LastAuditDate: false,
  performanceExamConcluded: false,
  verifiedPerformanceBegan: false,
  administrator: false,
  custodian: false
}

const s: ConfigType = {
  ...productBasicConfig,
  ...productManagementBasicConfig,
  ...valueAddedConfig,
  ...performanceCompositeDispersionConfig
}

export const defaultProductManagementConfig = {
  ...s,
  compliance: productManagementComplianceConfig,
  attributedTrackingError: attributedTrackingErrorConfig
}
