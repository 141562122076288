import { ConfigType } from '../../../helpers/constant'

const ClosedEndedProductManagementConfig: {
  [property: string]: ConfigType | boolean
} = {
  managementType: true,
  currencyHedging: true,
  compliance: {
    currentTeamStartDate: true,
    trackRecordComments: true,
    GIPSMethodology: true,
    GIPSAuditor: true,
    Auditor: true,
    LastAuditDate: true
  }
}

export const ClosedEndedPrivateEquityProductManagementConfig = ClosedEndedProductManagementConfig
