import React, { Component } from "react"
import { Button, Form, FormGroup, Input } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Auth from "../../../Auth/Auth"
import exportTables from "../../../helpers/exportTable"

const auth = new Auth()

export interface HeaderProps {
  type: string
  search: string
  setSearch: (term: string) => void
  setEditing: (editing: boolean) => void
  editing: boolean
  create?: (name: string) => void
  save: () => void
  remove?: () => void
}

export interface HeaderState {
  modal: boolean
  popover: boolean
}

export class HeaderControls extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props)
  }

  search = (term: string) => {
    this.props.setSearch(term)
  }

  handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  render() {
    const { save, setEditing, editing, search, setSearch } = this.props
    return (
      <div className="pane pane-toolbar sticky-top">
        <Form className="mr-1 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onKeyDown={this.handleEnterKeyDown}
            />
            {search == "" && (
              <span className="o-88 absolute center-v right-1 pe-none">
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="2x"
                  className="fontawesome-icon dark-icon-color text-gray-50"
                />
              </span>
            )}
          </FormGroup>
        </Form>
        <Button color="secondary btn-thin" className="text-callan-blue ml-1" onClick={()=> exportTables()}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>
        {editing && (
          <>
            <Button
              onClick={() => {
                window.location.reload()
              }}
              color="secondary"
              className="mr-1 ml-auto"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setEditing(false)
                save()
              }}
              color="primary"
              className="mr-1"
            >
              Save
            </Button>
          </>
        )}
        {auth.checkPermissions(["edit:manager"]) && !editing && (
          <Button
            onClick={() => {
              setEditing(true)
            }}
            color="primary"
            className="mr-1 ml-auto"
          >
            Edit
            <FontAwesomeIcon icon="pen" size="xs" className="ml-2" />
          </Button>
        )}
      </div>
    )
  }
}
