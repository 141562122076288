import { ConfigType } from '../../../helpers/constant'
import { ClosedEndedHedgeFundProductManagementConfig } from './ClosedEndedHedgeFund'
import { ClosedEndedPrivateCreditProductManagementConfig } from './ClosedEndedPrivateCredit'
import { ClosedEndedPrivateEquityProductManagementConfig } from './ClosedEndedPrivateEquity'
import { ClosedEndedRealAssetsProductManagementConfig } from './ClosedEndedRealAssets'
import { defaultProductManagementConfig } from './default'
import { OpenEndedEquityProductManagementOverrideConfig } from './OpenEndedEquity'
import { OpenEndedTargetDateProductManagementOverrideConfig } from './OpenEndedTargetDate'
import { OpenEndedFixedIncomeProductManagementOverrideConfig } from './OpenEndedFixedIncome'
import { OpenEndedHedgeFundProductManagementOverrideConfig } from './OpenEndedHedgeFund'
import { OpenEndedMACProductManagementOverrideConfig } from './OpenEndedMAC'
import { OpenEndedPassiveEquityProductManagementOverrideConfig } from './OpenEndedPassiveEquity'
import { OpenEndedPassiveFixedIncomeProductManagementOverrideConfig } from './OpenEndedPassiveFixedIncome'
import { OpenEndedPassiveMACProductManagementOverrideConfig } from './OpenEndedPassiveMAC'
import { OpenEndedRealAssetsProductManagementOverrideConfig } from './OpenEndedRealAssets'
import { OpenEndedPrivateCreditProductManagementOverrideConfig } from './OpenEndedPrivateCredit'
import { OpenEndedPrivateEquityProductManagementOverrideConfig } from './OpenEndedPrivateEquity'

const ProductManagementConfig: {
  [productType: string]: { [property: string]: ConfigType | boolean }
} = {
  Product: { ...defaultProductManagementConfig },
  OpenEndedEquity: {
    ...OpenEndedEquityProductManagementOverrideConfig
  },
  OpenEndedTargetDate: {
    ...OpenEndedTargetDateProductManagementOverrideConfig
  },
  OpenEndedPassiveEquity: {
    ...OpenEndedPassiveEquityProductManagementOverrideConfig
  },
  OpenEndedFixedIncome: {
    ...OpenEndedFixedIncomeProductManagementOverrideConfig
  },
  OpenEndedPassiveFixedIncome: {
    ...OpenEndedPassiveFixedIncomeProductManagementOverrideConfig
  },
  OpenEndedHedgeFund: {
    ...OpenEndedHedgeFundProductManagementOverrideConfig
  },
  OpenEndedMAC: {
    ...OpenEndedMACProductManagementOverrideConfig
  },
  OpenEndedPassiveMAC: {
    ...OpenEndedPassiveMACProductManagementOverrideConfig
  },
  OpenEndedRealAssets: {
    ...OpenEndedRealAssetsProductManagementOverrideConfig
  },
  OpenEndedPrivateCredit: {
    ...OpenEndedPrivateCreditProductManagementOverrideConfig
  },
  OpenEndedPrivateEquity: {
    ...OpenEndedPrivateEquityProductManagementOverrideConfig
  },
  ClosedEndedRealAssets: {
    ...ClosedEndedRealAssetsProductManagementConfig
  },
  ClosedEndedPrivateEquity: {
    ...ClosedEndedPrivateEquityProductManagementConfig
  },
  ClosedEndedPrivateCredit: {
    ...ClosedEndedPrivateCreditProductManagementConfig
  },
  ClosedEndedHedgeFund: {
    ...ClosedEndedHedgeFundProductManagementConfig
  }
}

export default ProductManagementConfig
