import iassign from "immutable-assign"
import _, { cloneDeep } from "lodash"
import omitDeep from "omit-deep-lodash"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"

import Auth from "../../Auth/Auth"
import EditButtons from '../ui/EditButtons'
import {
  ClosedEnded,
  ClosedEndedFields,
  DeleteGeneralPartnerCommitmentsListInput,
  DeleteInvestorRostersListInput,
  GeneralPartnerCommitmentUpdateInput,
  GeneralPartnerCommitmentCreateFields,
  GeneralPartnerCommitmentUpdateFields,
  GeneralPartnersCommitment,
  InvestorRoster,
  InvestorTypeCode,
  CountryCode,
  Maybe,
  ParticipantTypeCode,
  ProductGpCommitmentsQuery,
  SponsorCommitTypeCode,
  UpdateGeneralPartnerCommitmentInput,
  CreateGeneralPartnerCommitmentInput,
  useDeleteProductGpInvestorListsMutation,
  useDeleteProductGpListsMutation,
  useProductGpCommitmentsQuery,
  useCreateProductGpCommitmentsMutation,
  useUpdateProductGpCommitmentsMutation
} from "../../__generated__/graphql"
import {
  GeneralPartnersCommitmentUnion,
  InvestorRosterUnion
} from "../../helpers/constant"
import { reShapeObject } from "../../helpers/object"
import { isClosedEnded } from "../../helpers/productFunction"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import PlaceHolder from "../ui/PlaceHolder"
import GPTableDisplay from "./ProductOverviewGPCommitmentsGPTable"
import InvestorRosterTableDisplay from "./ProductOverviewGPCommitmentsInvestRoster"
import exportTables from "../../helpers/exportTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ProductOverviewGPCommitments: React.FC<{ productId: number, auth: Auth }> = props => {
  const { productId, auth } = props
  const { loading, error, data } = useProductGpCommitmentsQuery({
    variables: { id: productId }
  })

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (error) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <p>{error?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
  if (!data || !data.product) {
    return <p>Product Data not found</p>
  }
  console.log(data.product)
  if (isClosedEnded(data.product as any)) {
    return <Display data={data} productId={productId} auth={auth} />
  } else {
    return <div>Not a closedEnded Product</div>
  }
}

const getInitialState = (data: ProductGpCommitmentsQuery) => {
  let closedEnded = (data.product as ClosedEnded)?.closedEnded
  if (!closedEnded) {
    closedEnded = {
      __typename: "ClosedEndedFields",
      generalPartnersCommitments: null,
      investorRosters: null
    }
  }
  const {
    generalPartnersCommitments,
    investorRosters
  } = closedEnded as ClosedEndedFields
  return {
    ...closedEnded,
    generalPartnersCommitments: generalPartnersCommitments || [],
    investorRosters: investorRosters || []
  } as {
    generalPartnersCommitments: Maybe<GeneralPartnersCommitmentUnion>[]
    investorRosters: Maybe<InvestorRosterUnion>[]
  }
}

const reShapeGPCommitmentsActions = {
  "participantType.code": {
    destinationPath: "participantType",
    action: (a: any) => (a as ParticipantTypeCode) || null
  },
  "sponsorCommitType.code": {
    destinationPath: "sponsorCommitType",
    action: (a: any) => (a as SponsorCommitTypeCode) || null
  }
}

const reShapeInvestorRosterActions = {
  "investorType.code": {
    destinationPath: "investorType",
    action: (a: any) => (a as InvestorTypeCode) || null
  },
  "investorDomicile.code": {
    destinationPath: "investorDomicile",
    action: (a: any) => (a as CountryCode) || null
  }
}

const Display: React.FC<{
  data: ProductGpCommitmentsQuery
  productId: number
  auth: Auth
}> = ({ data, productId, auth }) => {
  const history = useHistory()
  const [currentState, setState] = useState(getInitialState(data))
  const [initialState, setInitial] = useState(currentState)
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [updateGPCommitments] = useUpdateProductGpCommitmentsMutation()
  const [createGPCommitments] = useCreateProductGpCommitmentsMutation()

  const [deleteGpIds] = useDeleteProductGpListsMutation()
  const [deleteInvestorIds] = useDeleteProductGpInvestorListsMutation()
  const handleChangeTableList = (newTable: any[], property: string) => {
    let propertyArray = property.split(".")
    let singleProperty = propertyArray.length === 1
    let lastProperty = singleProperty
      ? property
      : propertyArray[propertyArray.length - 1]
    let frontProperties = singleProperty
      ? ""
      : property.slice(0, -lastProperty.length - 1)
    let newState
    if (!frontProperties) {
      newState = { ...currentState, [property]: newTable } as {
        generalPartnersCommitments: Maybe<GeneralPartnersCommitmentUnion>[]
        investorRosters: Maybe<InvestorRosterUnion>[]
      }
    } else {
      let path = frontProperties.split(".")
      let oldState = cloneDeep(currentState)
      if(!_.get(oldState, path)){
        _.set(oldState, path, {})
      }
      newState = iassign(
        oldState,
        path,
        state => ({
          ...(state || {}),
          [lastProperty]: newTable
        })
      )
    }
    setState(newState)
  }

  const toggleEditMode = () => {
    setState(initialState)
    setEditMode(!editMode)
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let generalPartnersCommitments = currentState?.generalPartnersCommitments.map(
      el => {
        return reShapeObject(
          el as GeneralPartnersCommitment,
          reShapeGPCommitmentsActions
        ) as GeneralPartnerCommitmentUpdateInput
      }
    )
    let investorRosters = currentState?.investorRosters.map(el => {
      return reShapeObject(el as InvestorRoster, reShapeInvestorRosterActions)
    }) as any
    // TODO naming inconsistency

    //delete rows
    const currentGPIds = currentState?.generalPartnersCommitments?.map(
      el => el?.entityId
    )
    const initialGPIds = initialState?.generalPartnersCommitments?.map(
      el => el?.entityId
    )
    const removedGPIds = initialGPIds.filter(
      entityId => !currentGPIds.includes(entityId)
    )

    const currentInvestorIds = currentState?.investorRosters?.map(
      el => el?.investorId
    )
    const initialInvestorIds = initialState?.investorRosters?.map(
      el => el?.investorId
    )

    const removedInvestorIds = initialInvestorIds.filter(
      investorId => !currentInvestorIds.includes(investorId)
    )

    if (removedGPIds.length > 0) {
      const deleteGpIdsInput: DeleteGeneralPartnerCommitmentsListInput = {
        id: productId,
        entityId: removedGPIds as number[]
      }
      deleteGpIds({ variables: { input: deleteGpIdsInput } })
        .then(result => {
          console.log(result.data?.deleteGeneralPartnerCommitmentsList?.status)
        })
        .catch(err => {
          console.log(err.message)
        })
    }
    if (removedInvestorIds.length > 0) {
      const deleteInvestorIdsInput: DeleteInvestorRostersListInput = {
        id: productId,
        investorId: removedInvestorIds as number[]
      }
      deleteInvestorIds({ variables: { input: deleteInvestorIdsInput } })
        .then(result => {
          console.log(result.data?.deleteInvestorRostersList?.status)
        })
        .catch(err => {
          console.log(err.message)
        })
    }

    const [ existingGeneralPartnersCommitments, newGeneralPartnersCommitments ] = _.partition(generalPartnersCommitments, "entityId")
    const [ existingInvestorRosters, newInvestorRosters ] = _.partition(investorRosters, "investorId")

    const inputState = {
      generalPartnerCommitments: existingGeneralPartnersCommitments,
      investorRosters: existingInvestorRosters
    }

    const createInputState = {
      generalPartnerCommitments: newGeneralPartnersCommitments,
      investorRosters: newInvestorRosters
    }

    let patch: GeneralPartnerCommitmentUpdateFields = omitDeep(
      inputState,
      "__typename"
    )
    const input: UpdateGeneralPartnerCommitmentInput = {
      id: productId,
      patch
    }

    let createPatch: GeneralPartnerCommitmentCreateFields = omitDeep(
      createInputState,
      "__typename"
    )
    const createInput: CreateGeneralPartnerCommitmentInput = {
      id: productId,
      patch: createPatch
    }

    createGPCommitments({ variables: { input: createInput } })
      .catch(err => {
        console.log("Error update GP Commitments", err.message)
      })

    updateGPCommitments({ variables: { input } })
      .then(result => {
        setSaving(false)
        if (
          result &&
          result.data &&
          result.data.updateGeneralPartnerCommitments
        ) {
          const formattedData = getInitialState(
            (result.data
              .updateGeneralPartnerCommitments as unknown) as ProductGpCommitmentsQuery
          )
          setState(formattedData)
          setInitial(formattedData)
          setEditMode(false)
        }
      })
      .catch(err => {
        setSaving(false)
        console.log("Error update GP Commitments", err.message)
      })
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> exportTables()}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
      </div>
      {auth.checkPermissions(["edit:manager"]) &&
        <EditButtons editMode={editMode} setEditMode={toggleEditMode} saving={saving} onSubmit={handleSubmit} />
      }
    </div>
  )
  return (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className="pane pane-table">
              <GPTableDisplay
                data={currentState?.generalPartnersCommitments || []}
                handleChange={(newTableState: any[]) =>
                  handleChangeTableList(
                    newTableState,
                    "generalPartnersCommitments"
                  )
                }
                editMode={editMode}
              />
            </div>
            <div className="pane pane-table">
              <InvestorRosterTableDisplay
                data={currentState?.investorRosters || []}
                handleChange={(newTableState: any[]) =>
                  handleChangeTableList(newTableState, "investorRosters")
                }
                editMode={editMode}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductOverviewGPCommitments
