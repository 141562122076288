import React, { useState } from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { CustomInput } from 'reactstrap';
import { getNewStateObject } from '../../../helpers/helpers';
import { Volatility } from '../../../__generated__/graphql';

import { FormInput } from '../../ui/Forms/FormInput';

type VolatilityComponentProps = {
  data: Volatility;
  handleChange: (state: any, property: string) => void;
  editMode: boolean;
  hidden?: boolean;
};

export const VolatilityComponent: React.FC<VolatilityComponentProps> = ({ data, editMode, handleChange, hidden }) => {
  const handleDefaultVolatilityToggle = () => (data.annualized ? true : data.benchmarkSpread?.benchmark ? false : true);
  const [isVolatilityToggle, setIsVolatilityToggle] = useState(handleDefaultVolatilityToggle);
  const toggleAnnualized = () => {
    if (isVolatilityToggle) {
      handleChange(null, `annualized`);
    } else {
      handleChange({ spread: null, benchmark: null }, `benchmarkSpread`);
    }
    setIsVolatilityToggle(!isVolatilityToggle);
  };

  const updateValue = (value: any, type: string, property: string) => {
    let newData =
      getNewStateObject({
        state: data,
        newValue: value,
        property: `${property}`,
        type,
      }) || {};

    handleChange(get(newData, property), property);
  };
  return (
    <>
      <span>{'Volatility'}</span>

      {/* Annualized standard deviation*/}
      <div className={classnames('d-flex align-items-center')}>
        <CustomInput
          key={'annualized-standard-deviation-radio-button'}
          id={'annualized'}
          type='radio'
          label={'Annualized Standard Deviation %'}
          className='boolean-radio custom-radio custom-radio-sm custom-control'
          name={'annualized'}
          bsSize='sm'
          value='false'
          disabled={!editMode}
          checked={isVolatilityToggle}
          onChange={toggleAnnualized}
        />
        {isVolatilityToggle && (
          <FormInput
            key={'annualized-standard-deviation-form-input'}
            property={'annualized'}
            type='float'
            subtype='percent'
            idx='annualized-standard-deviation-form-input'
            editMode={editMode}
            propertyVal={get(data, 'annualized')}
            placeholder='Annualized Standard Deviation %'
            updateValue={(value) => updateValue(value, 'float', 'annualized')}
          />
        )}
      </div>

      {/* Benchmark + Spread */}
      <div className={classnames('d-flex align-items-center')}>
        <CustomInput
          key={'benchmark-spread-radio-button'}
          id={'benchmark-spread'}
          type='radio'
          label={'Benchmark + Spread %'}
          className='boolean-radio custom-radio custom-radio-sm custom-control'
          name={'benchmark-spread'}
          bsSize='sm'
          value='false'
          disabled={!editMode}
          checked={!isVolatilityToggle}
          onChange={toggleAnnualized}
        />
        {!isVolatilityToggle && (
          <>
            <FormInput
              key={'benchmark-form-input'}
              property={'benchmark'}
              type='float'
              subtype='percent'
              idx='benchmark-form-input'
              editMode={editMode}
              propertyVal={get(data, 'benchmarkSpread.spread')}
              placeholder='Benchmark Spread %'
              updateValue={(value) => updateValue(value, 'float', 'benchmarkSpread.spread')}
            />
            <span className='px-3'>Above</span>
            <FormInput
              key={'spread-form-input'}
              property={'spread'}
              type='text'
              idx='spread-form-input'
              editMode={editMode}
              propertyVal={get(data, 'benchmarkSpread.benchmark')}
              placeholder='Benchmark Name'
              updateValue={(value) => updateValue(value, 'text', 'benchmarkSpread.benchmark')}
            />
          </>
        )}
      </div>
    </>
  );
};
