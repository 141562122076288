import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FetchResult, gql } from '@apollo/client'
import { default as classNames, default as classnames } from 'classnames'
import _, { cloneDeep, compact, difference, differenceWith, first, get, intersection, isEqual, remove, set, union, uniqBy } from 'lodash'
import moment from 'moment'
import React, { FormEvent, useEffect, useState } from "react"
import { MutationFunctionOptions } from '@apollo/client'
import { Button, Col, CustomInput, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, FormInputField } from '../../../../helpers/constant'
import { ClientPortfolioPlanFragment, CopyFootnoteHistoryInput, CopyFootnoteHistoryMutation, CopyFootnoteHistoryMutationVariables, CreateFootnoteInput, CreateFootnoteMutation, CreateFootnoteMutationVariables, DeleteFootnoteMutation, DeleteFootnoteMutationVariables, DeleteInput, Footnote, FootnoteDetailFragment, FootnoteHistoryFragment, UpdateFootnoteInput, UpdateFootnoteMutation, UpdateFootnoteMutationVariables } from "../../../../__generated__/graphql"
import { FormInput } from '../../../ui/Forms/FormInput'
import { ClientPortfolioHistoryData, TraceableTableKey } from '../helper'

export interface TargetFootNoteTableProps {
  data: FootnoteDetailFragment | FootnoteHistoryFragment
  tableKey: TraceableTableKey
  handleTableStateChange: (footnote: FootnoteDetailFragment, change?: FootnoteModificationsChange) => void
  targetId?: number // associated target
  editMode: boolean
  showStatus: boolean
  endCurrent: () => void
  revertEndCurrent: () => void
  showSuffixEndDate?: boolean
  currentTableBatchEndDate?: string
  page: FootnotePageTypes
  previousFootnote?: FootnoteDetailFragment
  toggleLibraryModal: () => void
  isLinked?: boolean
  plan: ClientPortfolioPlanFragment
  footnoteId: number
  hasFullHistoryButton?: boolean
}

type FootnotePageTypes =  "plan" | "portfolio" | "benchmark"

interface TargetFootNoteNewTableProps extends TargetFootNoteTableProps {
  tableKey: 'new'
  currentTableBatchEndDate: string
}
interface TargetFootNoteCurrentTableProps extends TargetFootNoteTableProps {
  tableKey: 'current'
  setCurrentTableBatchEndDate?: React.Dispatch<React.SetStateAction<string | null>>
  currentTableBatchEndDate: string
  greyOut: boolean
  toggleDeleteModal: () => void
}

interface TargetFootNotePreviousTableProps extends TargetFootNoteTableProps {
  tableKey: 'previous'
  toggleFullHistoryModal?: () => void
}

const TargetFootNoteInput: TargetFootNoteInputType[] = [
  {
    property: "text",
    label: "FootNote",
    type: "textarea",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pb-2"
    }
  },
  {
    property: "editedValidUntilDate",
    label: "As of",
    type: "date",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pb-2"
    }
  },
]

const BatchEndDateInput: FormInputField[] = [
  {
    property: "startDate",
    label: "Start",
    type: "date",
    readonly: true,
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
  {
    property: "editedEndDate",
    label: "End",
    type: "date",
    subtype: "month",
    subClasses: {
      inputClasses: "text-left bg-white",
    },
  },
]

interface TargetFootNoteInputType extends FormInputField {
  widthClass?: string
}

interface TargetFootNoteRowProps {
  data: FootnoteDetailFragment | FootnoteHistoryFragment
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  tableKey: string
}

const DEFAULT_NEW_START_DATE = "1900-01-01"

export const TargetFootNoteRow: React.FC<TargetFootNoteRowProps> = ({data, editMode, updateValue, tableKey}) => {
  let field = "text"
  let propertyVal = _.get(data, field)
  return (<FormInput
            key={`${tableKey}-target-footnote`}
            property={`text`}
            displayName={""}
            type={"textarea"}
            idx={`${tableKey}-composite-member-item`}
            editMode={editMode}
            propertyVal={propertyVal}
            updateValue={(value: any) => {
              updateValue(value, tableKey, "text")
            }}
          />)
}

export const DEFAULT_EMPTY_FOOTNOTE = {
  text: "",
  date: "",
  id: -1,
  history: [],
  clientPortfolioTargets: [],
  clientPortfolios: [],
  plans: [],
  __typename: "Footnote" as "Footnote",
}

export const TargetFootNoteTable = (props: TargetFootNoteTableProps | TargetFootNoteCurrentTableProps | TargetFootNotePreviousTableProps) => {
  const componentName = "FootNote"
  let { data, tableKey, editMode, endCurrent, revertEndCurrent, showSuffixEndDate, showStatus, handleTableStateChange: handleChange, page, previousFootnote, toggleLibraryModal, isLinked, plan, targetId, hasFullHistoryButton} = props

  let { toggleFullHistoryModal, footnoteId } = props as TargetFootNotePreviousTableProps
  let { greyOut, toggleDeleteModal, setCurrentTableBatchEndDate, currentTableBatchEndDate } = props as TargetFootNoteCurrentTableProps

  const showDeleteButton = !!showSuffixEndDate && tableKey !== "previous"
  const showFullHistoryButton = tableKey ==="previous"

  const endCurrentComponent = () => (
    <React.Fragment key={`${tableKey}-end-current`}>
      {editMode && tableKey === "current" && !isLinked && !showDeleteButton && (
        <>
          <Button
            color="link"
            className="text-callan-blue btn-thin pt-0"
            onClick={() => endCurrent()}
          >
            {"End Current & Start New"}
            <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
          </Button>
          <span className='text-callan-blue font-weight-bold vertical-align-text-bottom'>|</span>
        </>
      )}
      {editMode && tableKey === "new" && (
        <Button
          color="link"
          className="text-callan-blue btn-thin pt-0"
          onClick={() => revertEndCurrent()}
        >
          {"Cancel New"}
        </Button>
      )}
      {editMode && tableKey === "current" && page === "plan" && (
        <Button
          color="link"
          className="text-danger btn-thin pt-0"
          onClick={() => toggleDeleteModal()}
        >
          {"Delete"}
          <FontAwesomeIcon icon="trash" className="ml-2 text-danger" />
        </Button>
      )}
      {editMode && tableKey === "current" && page !== "plan" && !isLinked && data.__typename === "Footnote" && (data?.id || -1) >= 0 && (
        <Button
          color="link"
          className="text-danger btn-thin pt-0"
          onClick={() => data.__typename === "Footnote" && handleChange({...data, text: null, date: null}, 'delete')}
        >
          {"Delete"}
          <FontAwesomeIcon icon="trash" className="ml-2 text-danger" />
        </Button>
      )}
      {editMode && tableKey === "current" && page !== "plan" && (
        <Button
          color="link"
          className="text-callan-blue btn-thin pt-0"
          onClick={() => toggleLibraryModal()}
        >
          {isLinked ? "Swap Footnote" : "Library"}
        </Button>
      )}
      {editMode && tableKey === "current" && page !== "plan" && isLinked && (
        <>
          <span className='text-callan-blue font-weight-bold vertical-align-text-bottom'>|</span>
          <Button
            color="link"
            className="text-callan-blue btn-thin pt-0"
            onClick={() => data.__typename === "Footnote" && handleChange(data, "unlink")}
          >
            {"Unlink"}
          </Button>
        </>
      )}
      {tableKey === "current" && hasFullHistoryButton &&(
        <Button
          color="link"
          className="text-callan-blue btn-thin pt-0"
          onClick={toggleFullHistoryModal}
        >
          {"View Full History"}
          <FontAwesomeIcon icon="history" className="ml-2 text-callan-blue" />
        </Button>
      )}
      {tableKey === "current" && data?.date && (
        <div className="d-inline-block mb-1">
          Ending {moment(data?.date).format(DATE_DISPLAY_FORMAT)}
        </div>
      )}
    </React.Fragment>
  )

  const updateValue = (value: any, type: string, property: string) => {
    let state = cloneDeep(data)
    let change: FootnoteModificationsChange | undefined = undefined
    set(state, property, value)
    if(property === "text"){
      if(!previousFootnote) {
        change =  'create'
      } else if (type === "new"){
        change =  'update new'
      } else if (type === "current") {
        change =  'update current'
      }
    }
    if(state.__typename === "Footnote") handleChange(state, change)
  }

  const batchEndDateComponent = () => {
    // let setCurrentTableBatchEndDate = currentTableBatchEndDate

    let labelClass = ""
    if(tableKey === "current") {
      labelClass = "background-private-background text-accent-red"
    }

    return (
      <div key={"batch-end-date"} className={classnames("d-flex w-100 justify-content mb-3", labelClass)}>
        {BatchEndDateInput.map((headerDef, idx) => {
          let property = headerDef.property
          let propertyVal:string|undefined = ""
          if(tableKey === "current" && property === "editedEndDate") {
            propertyVal = currentTableBatchEndDate
          }else if (property === "startDate") {
            propertyVal = moment((data.__typename === "Footnote" && first(data.history)?.date) || "1900-01-01").add(1, 'day').format(DATE_API_FORMAT)
          }

          return(
            <div key={idx} className={classnames('d-flex ml-3')}>
              <div className="client-portfolio-date-label">
                {headerDef.label}
              </div>
              <FormInput
                key={headerDef.property}
                property={property}
                displayName={""}
                type={headerDef.type}
                subtype={headerDef.subtype}
                idx={`${tableKey}-footnote-${targetId}-item-batch-end-date`}
                editMode={true}
                propertyVal={propertyVal}
                readonly={!!headerDef.readonly || tableKey ==="previous"}
                updateValue={(value: any) => {
                  // updateValue(value, headerDef.type, headerDef.field)
                  // handleBatchCurrentEndDateChange(value, data, key)
                  if(setCurrentTableBatchEndDate) setCurrentTableBatchEndDate(value)
                }}
                inputProps={{
                  min: (data.__typename === "Footnote" && first(data.history)?.date) || "1900-01-01",
                }}
                // required={headerDef.required}
                // optionSource={headerDef.optionSource}
                subClasses={headerDef.subClasses || {}}
              />
            </div>
          )
        })}
      </div>
    )
  }

  if(!showStatus) {
    return <div className='w-100' key={`no-${tableKey}`}></div>
  }else {
    let greyClass = greyOut ? "bg-gray-full": ""
    return (
      <div
        className='w-100'
        key={`${componentName}-${tableKey}`}
      >
        <div className={"d-flex justify-content-between w-100 align-items-end"}>
          <div className={"d-flex justify-content-start"}>
            <h5 className={classNames("headline mt-3 text-nowrap", {"text-uppercase": page !== "benchmark"})}>
              {isLinked &&
                <FontAwesomeIcon
                  className='mr-2'
                  icon="link"
                />
              }
              {page !== "plan" && `Footnote `}
              {page === "plan" && "ID "}
              {isLinked && `ID - `}
              {(isLinked || page === "plan") && <span className={classNames({"fake-link": page !== "plan"})} onClick={() => window.open(`/plans/${plan.id}/overview`, "_blank")}>{footnoteId}</span>}
            </h5>
            <h5 className="headline text-uppercase text-gray-70 w-100 pl-1 mt-3">{(greyOut || tableKey !== "current") && ` (${tableKey})`}</h5>
          </div>
          <div className=''>
            {endCurrentComponent()}
          </div>
        </div>
        {/* {`greyState ${!!(props as any)?.greyOut}`} */}
        <h5 className={classnames("d-flex justify-content-between w-100 align-items-start pt-1 gray-underline underline pb-0", greyClass)}>
          <div className="d-flex flex-column">
          </div>
        </h5>
        <TargetFootNoteRow
          data={data}
          editMode={editMode}
          updateValue={updateValue}
          tableKey={tableKey}
        />
        {!!(data) && showSuffixEndDate && (
          batchEndDateComponent()
        )}
      </div>)
  }
}

interface FootnoteComponentProps {
  footnoteModification: FootnoteModifications
  editMode: boolean
  handleChange: (value: FootnoteModifications) => void
  page: FootnotePageTypes
  plan?: ClientPortfolioPlanFragment
}

const getInitialData = (footnote?: FootnoteDetailFragment) => {
  if(footnote){
    let sortedHistory = _.orderBy(footnote.history, ["date"], ['desc'])
    return {...footnote, history: sortedHistory}
  }
  return DEFAULT_EMPTY_FOOTNOTE
}

const getCurrentTableData = (data: FootnoteDetailFragment) => {
  return data
  // return data.find(el => el && (el?.editedValidUntilDate === "9999-12-31") && (!el?.source || el?.source ==="current")) || undefined
}

const getNewTableData = (data: FootnoteDetailFragment) => {
  return data
  // return data.find(el => el && (el?.source ==="new")) || undefined
}

const getPreviousTableData = (data: FootnoteDetailFragment) => {
  return compact(data.history) || []
  // return data.filter(el => (el?.editedValidUntilDate !== "9999-12-31") && (!el?.source || el?.source ==="previous"))
}

const getFirstPreviousTableData = (data: FootnoteHistoryFragment[]) => {
  return data[0] || undefined
}

interface TargetFootNotesHistoryData extends ClientPortfolioHistoryData {
}

interface TargetFootNotesHistoryProps {
  isOpen: boolean
  title: string
  toggle: () => void
  data: TargetFootNotesHistoryData
}


const getHistoryTableData = (initialData: FootnoteDetailFragment) => {
  let currentData = getCurrentTableData(initialData)
  let previousData = getPreviousTableData(initialData)
  let data = _.compact([currentData, ...previousData])
  let headers = TargetFootNoteInput.map(row => row.label)
  let result = data.reduce((acc, row) => {
    let {date} = row
    if(!acc[date]) {
      acc[date] = {
        date: date,
        data: []
      }
    }
    acc[date].data.push(row)
    return acc
  }, {} as any)
  let rows =  Object.values(result) as {date: string, data: any[]}[]
  return ({headers, rows}) as TargetFootNotesHistoryData
}

const TargetFootNoteHistoryModal: React.FC<TargetFootNotesHistoryProps> = ({
  isOpen,
  title,
  toggle,
  data,
}) => {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header">History | {title}</ModalHeader>
      <ModalBody>
        {data.rows?.map((row, idx) => {
          return (
            <div className="mb-1 p-2" key={idx}>
              <h5 className="composite-member-modal-as-of-date underline">{idx === 0 ? "Current" : `Valid Until ${moment(row.date).format(DATE_DISPLAY_FORMAT)}`}</h5>
                {row.data.map((tr, idx) => {
                  return (<p key={`targetNote-history-${idx}`}>{tr.text}</p>)
                })}
            </div>
          )
        })}
      </ModalBody>
    </Modal>
  )
}

interface FootnoteDeleteModalProps {
  isOpen: boolean
  toggle: () => void
  data: FootnoteDetailFragment
  handleTableStateChange: (footnote: FootnoteDetailFragment, change?: FootnoteModificationsChange) => void
}

const FootnoteDeleteModal: React.FC<FootnoteDeleteModalProps> = (props) => {
  const {toggle, isOpen, data, handleTableStateChange} = props

  const deleteAction = () => {
    handleTableStateChange(data, "delete")
    toggle()
  }
  return (
    <Modal size="md" className="mt-5" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        <div className="d-flex justify-content-between w-100 text-danger">
          <div>
            Permanently Delete Footnote
          </div>
          <div onClick={toggle}>
            <FontAwesomeIcon
              icon="times"
              className="ml-auto"
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={3}>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon icon="exclamation-triangle" size={"4x"} className="text-danger unlock-icon-height"/>
            </div>
          </Col>
          <Col xs={9}>
            Are you sure you want to permanently delete this footnote from the library? All instances of this footnote will be removed.
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color="secondary" className="mr-1 ml-auto px-2">Cancel</Button>
        <Button onClick={deleteAction} color="danger" className="mr-1 px-2">Permanently Delete Footnote</Button>
      </ModalFooter>
    </Modal>
  )
}

interface FootnoteLibraryModalProps {
  isOpen: boolean
  toggle: () => void
  handleModificationChange: (modification: FootnoteModifications) => void
  plan: ClientPortfolioPlanFragment
  footnoteModification: FootnoteModifications
  skipAlert: boolean
}

const FootnoteLibraryModal: React.FC<FootnoteLibraryModalProps> = (props) => {
  const {toggle, isOpen, handleModificationChange, plan, footnoteModification, skipAlert} = props
  const planHasFootnotes = (plan.footnotes || []).length > 0
  const [footnoteChange, setFootnoteChange] = useState<FootnoteModificationsChange>(planHasFootnotes ? "link" : "link new")
  const [newFootnoteModification, setNewFootnoteModification] = useState({
    footnote: DEFAULT_EMPTY_FOOTNOTE,
    // footnote: {...DEFAULT_EMPTY_FOOTNOTE, plans: [plan]},
    previousFootnote: footnoteModification?.previousFootnote,
    change: "link new",
    relation: {
      ...footnoteModification?.relation,
      plan: [...(footnoteModification?.relation.plan || []), plan.id],
    }
  } as FootnoteModifications)
  const [status, setStatus] = useState(skipAlert ? "Library" : "Alert")
  const [selectedFootnote, setSelectedFootnote] = useState<FootnoteDetailFragment | undefined>(undefined)
  const disableSubmit = footnoteChange === "link new" ? !newFootnoteModification?.footnote?.text : !selectedFootnote
  const resetModal = () => {
    toggle()
  }
  const addFootnoteAction = () => {
    if(footnoteChange === "link new"){
      handleModificationChange(newFootnoteModification)
      resetModal()
    } else if (footnoteChange === "link" && selectedFootnote){
      handleModificationChange({
        ...footnoteModification,
        footnote: selectedFootnote,
        change: "link",
      })
      resetModal()
    }
  }

  if(status === "Alert"){
    return (
      <Modal size="md" className="mt-5" isOpen={isOpen} toggle={resetModal} zIndex={1500}>
        <ModalHeader className="fee-modal-header full-width-header border-gray-30">
          <div className="d-flex justify-content-between w-100">
            <div>
              Alert
            </div>
            <div onClick={toggle}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          The current footnote will be deleted and replaced with the library footnote.
          <br />
          Do you want to continue?
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="secondary" className="mr-1 ml-auto px-2">Keep Current Footnote</Button>
          <Button onClick={() => setStatus("Library")} color="danger" className="mr-1 px-2">Use Library Footnote</Button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Modal size="md" className="mt-5" isOpen={isOpen} toggle={resetModal} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header border-gray-30">
        <div className="d-flex justify-content-between w-100">
          <div>
            Footnote Library
          </div>
          <div onClick={resetModal}>
            <FontAwesomeIcon
              icon="times"
              className="ml-auto"
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {planHasFootnotes &&
          <>
            <CustomInput
              id={"1-link"}
              className="boolean-radio mt-2 full-width"
              bsSize="sm"
              type="radio"
              value="true"
              checked={footnoteChange === "link"}
              onChange={(value:any) => value.target.value === "true" && setFootnoteChange("link")}
              label="Choose a shared footnote from the plan's library"
            />
            <div className='ml-2 mt-2 border-bottom border-gray-30'>
              <div className='w-100 pl-2 background-gray-10 border-gray-30 border-top border-bottom text-uppercase'>
                {plan.name}
              </div>
              {plan.footnotes?.map((footnote, idx) => {
                return(
                  <CustomInput
                    key={footnote?.id}
                    id={`footnote-${footnote?.id}-selected`}
                    className={classnames("full-width border-gray-20 py-1 square-checkbox", {"border-top": idx !== 0})}
                    bsSize="sm"
                    type="checkbox"
                    value="true"
                    disabled={footnoteChange !== "link"}
                    checked={selectedFootnote?.id === footnote?.id}
                    onChange={(value:any) => value.target.value === "true" && footnote && setSelectedFootnote(footnote)}
                    label={footnote?.text}
                />)
              })}
            </div>
          </>
        }
        {!planHasFootnotes && <div>The plan does not have any shared footnotes. Create and use the first shared footnote.</div>}
        <CustomInput
          id={"2-new"}
          className="boolean-radio mt-3 full-width"
          bsSize="sm"
          type="radio"
          value="true"
          checked={footnoteChange === "link new"}
          onChange={(value:any) => {if(value.target.value === "true") {setFootnoteChange("link new"); setSelectedFootnote(undefined)}}}
          label="Create a new footnote in library"
        />
        <FormInput
          key={`new-footnote`}
          property={`text`}
          displayName={""}
          type={"textarea"}
          idx={`new-composite-member-item`}
          editMode={footnoteChange === "link new"}
          propertyVal={newFootnoteModification?.footnote.text}
          subClasses={{inputClasses: footnoteChange === "link new" ? "background-blue-10" : ""}}
          updateValue={(value: any) => {
            setNewFootnoteModification({...newFootnoteModification, footnote: {...newFootnoteModification?.footnote, text: value}})
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color="secondary" className="mr-1 ml-auto px-2">Cancel</Button>
        <Button onClick={addFootnoteAction} disabled={disableSubmit} color="primary" className="mr-1 px-2">Add Footnote</Button>
      </ModalFooter>
    </Modal>
  )
}

export const FootnoteComponent = (props: FootnoteComponentProps) => {
  let { footnoteModification, editMode, handleChange, page, plan } = props
  // edited data
  const footnote = footnoteModification?.footnote
  const previousFootnote = footnoteModification?.previousFootnote
  const [currentData, setData] = useState(() => getInitialData(footnote))
  // const isLinked = footnoteModification.relation?.plan && footnoteModification.relation?.plan?.length > 0 && page === "portfolio"
  const isLinked = (footnote?.plans && footnote.plans.length > 0 && page !== "plan") || footnoteModification.change === "link new"
  const currentTableData = getCurrentTableData(footnote)
  const initialTableData = getNewTableData(currentData)
  const previousTableData = getPreviousTableData(currentData)
  const firstPreviousTable = getFirstPreviousTableData(previousTableData)

  const showCurrentTable = true

  const [currentTableBatchEndDate, setCurrentTableBatchEndDate] = useState<string | null>(null)
  const [showCurrentTableEndDate, setShowCurrentTableEndDate] = useState<boolean>(false)

  const [showNewTable, setShowNewTable] = useState<boolean>(false)

  const [showPreviousTable, setShowPreviousTable] = useState<boolean>(false)

  const [historyData, setHistoryData] = useState<TargetFootNotesHistoryData>({headers: [], rows: [{date: "", data: []}]})
  const [showFullHistoryModal, setShowFullHistoryModal] = useState<boolean>(false)
  const toggleFullHistoryModal = () => setShowFullHistoryModal(!showFullHistoryModal)

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

  const [showLibraryModal, setShowLibraryModal] = useState<boolean>(false)
  const toggleLibraryModal = () => setShowLibraryModal(!showLibraryModal)

  // useEffect(() => {
  //   let footNotes = props?.target?.footNotes
  // }, [props])

  useEffect(() => {
    let newState = getInitialData(footnote)
    setHistoryData(getHistoryTableData(newState))
  }, [footnote])

  useEffect(() => {
    if(!editMode) {
      // setShowDeleteButton(false)
      // if(!newTableData) {
      //   // reset after save/cancel
      //   setNewTableData(undefined)
      // }
      setShowNewTable(false)
      let newState = getInitialData(footnote)
      setData(newState)
      setShowPreviousTable(false)
      setShowCurrentTableEndDate(false)
      setCurrentTableBatchEndDate(null)
    }
  }, [editMode])

  // useEffect(() => {
  //   if(showNewTable && editMode && newTableData?.length === 0) {
  //     // handleTableStateChange(newTableData, "new")
  //   }
  // }, [showNewTable])

  const endCurrent = ()=> {
    // setShowDeleteButton(true)
    setShowCurrentTableEndDate(true)
    setShowNewTable(true)
    let newChange = 'update new'
    if(footnoteModification?.change === "unlink") newChange = 'unlink'
    let newState = {
      ...footnoteModification,
      change: newChange as FootnoteModificationsChange,
    }
    handleChange(newState)
  }
  const revertEndCurrent = ()=> {
    // setShowDeleteButton(false)
    setShowCurrentTableEndDate(false)
    setCurrentTableBatchEndDate(null)
    setShowNewTable(false)
    let newChange = 'update changed'
    if(footnoteModification?.change === "unlink"){newChange = 'unlink'}
    else if (!previousFootnote){newChange = 'create'}
    let newState = {
      ...footnoteModification,
      change: newChange as FootnoteModificationsChange,
    }
    handleChange(newState)
  }

  const showHistoryButtonComponent = () => {
    return (<div className="expand-link">
      <Button color="link" onClick={(event) => {event.stopPropagation(); setShowPreviousTable(!showPreviousTable)}} className={"wider-link-on-hover"}>
        <FontAwesomeIcon
          icon={showPreviousTable  ? "chevron-up" : "chevron-down"}
          size="sm"
        />
        <span className="pl-2 expand-text">{showPreviousTable  ? "Hide History" : "Show History"}</span>
      </Button>
    </div>)
  }

  const handleTableStateChange = (footnote: FootnoteDetailFragment, change?: FootnoteModificationsChange) => {
    let updatedFootnote = cloneDeep(footnote)
    let newChange = change || footnoteModification?.change
    if(footnoteModification?.change === "unlink" && newChange !== "link") newChange = "unlink"
    if(change === "unlink") {
      updatedFootnote = {
        ...updatedFootnote,
        plans: footnoteModification?.relation.plan?.map((plan) => ({id: plan, __typename: "Plan"})),
        clientPortfolios: footnoteModification?.relation.clientPortfolio?.map((cp) => ({id: cp, __typename: "ClientPortfolio"})),
        clientPortfolioTargets: footnoteModification?.relation.target?.map((cpt) => {
          return {
            target: {targetId: cpt.target, __typename: "Target"},
            clientPortfolio: {id: cpt.clientPortfolio, __typename: "ClientPortfolio"},
            __typename: "ClientPortfolioTarget"
          }
        }),
      }
    }
    let newState = {
      ...footnoteModification,
      footnote: updatedFootnote,
      change: newChange,
    }
    handleChange(newState)
  }

  const handleBatchEndDateChange = (date: string | null) => {
    setCurrentTableBatchEndDate(date)
    let updatedFootnote = cloneDeep(currentTableData)
    updatedFootnote = {
      ...updatedFootnote,
      date: date,
    }
    let newState = {
      ...footnoteModification,
      footnote: updatedFootnote,
    }
    handleChange(newState)
  }

  const handleModificationChange = (footnoteModification: FootnoteModifications) => {
    handleChange(footnoteModification)
  }

  let componentProps = {editMode, endCurrent, revertEndCurrent, handleTableStateChange, targetId: footnote?.id, page, previousFootnote, toggleLibraryModal, isLinked, plan, footnoteId: currentTableData?.id}

  let newTableProps = {...componentProps, showStatus: !isLinked && showNewTable, tableKey: "new" as TraceableTableKey, data: currentTableData, currentTableBatchEndDate, showSuffixEndDate: false,} as TargetFootNoteNewTableProps

  let previousTableProps = {...componentProps, showStatus: showPreviousTable, tableKey: "previous" as TraceableTableKey, data: firstPreviousTable, showSuffixEndDate: false, editMode: false, toggleFullHistoryModal} as TargetFootNotePreviousTableProps

  let currentTableProps = {...componentProps, showStatus: showCurrentTable, tableKey: "current" as TraceableTableKey, data: showNewTable ? initialTableData : currentTableData, showSuffixEndDate: showCurrentTableEndDate, setCurrentTableBatchEndDate: handleBatchEndDateChange, currentTableBatchEndDate, editMode: editMode && !showNewTable, greyOut: showNewTable, isLinked, toggleDeleteModal, toggleFullHistoryModal, hasFullHistoryButton: previousTableData.length > 0} as TargetFootNoteCurrentTableProps

  return (
    <Row className={classnames("px-0", {"d-none": footnoteModification?.change === "delete" && page === "plan"})} key={footnote?.id}>
      {TargetFootNoteTable(newTableProps)}
      {TargetFootNoteTable(currentTableProps)}
      {/* {TargetFootNoteTable(previousTableProps)}
      {previousTableData.length > 0 && showHistoryButtonComponent()} */}
      <TargetFootNoteHistoryModal
        isOpen={showFullHistoryModal}
        title={(isLinked || page === "plan") ? 'Library Footnote' : 'FootNote'}
        toggle={toggleFullHistoryModal}
        data={historyData}
      />
      <FootnoteDeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        data={footnote}
        handleTableStateChange={handleTableStateChange}
      />
      {plan &&
        <FootnoteLibraryModal
          key={previousFootnote?.id}
          isOpen={showLibraryModal}
          toggle={toggleLibraryModal}
          footnoteModification={footnoteModification}
          handleModificationChange={handleModificationChange}
          plan={plan}
          skipAlert={!!isLinked || currentTableData?.id === -1}
        />
      }
    </Row>
  )
}

export type FootnoteModifications = {
  footnote: FootnoteDetailFragment
  previousFootnote?: FootnoteDetailFragment
  change: FootnoteModificationsChange
  relation: {
    clientPortfolio?: number[]
    plan?: number[]
    target?: {clientPortfolio: number, target: number}[]
  }
}

export type FootnoteModificationsChange = "create" | "update current" | "update new" | "link" | "link new" | "unlink" | "delete" | "none"

interface SubmitFootnoteChangesProps {
  modifications: FootnoteModifications[]
  createFootnote?: (options?: MutationFunctionOptions<CreateFootnoteMutation, CreateFootnoteMutationVariables> | undefined) => Promise<FetchResult<CreateFootnoteMutation>>
  updateFootnote?: (options?: MutationFunctionOptions<UpdateFootnoteMutation, UpdateFootnoteMutationVariables> | undefined) => Promise<FetchResult<UpdateFootnoteMutation>>
  deleteFootnote?: (options?: MutationFunctionOptions<DeleteFootnoteMutation, DeleteFootnoteMutationVariables> | undefined) => Promise<FetchResult<DeleteFootnoteMutation>>
  copyFootnoteHistory?: (options?: MutationFunctionOptions<CopyFootnoteHistoryMutation, CopyFootnoteHistoryMutationVariables> | undefined) => Promise<FetchResult<CopyFootnoteHistoryMutation>>
  setFootnoteSavingCallCount?:  React.Dispatch<React.SetStateAction<number>>
}

export const SubmitFootnoteChanges = (props: SubmitFootnoteChangesProps) => {
  const {modifications, createFootnote, updateFootnote, deleteFootnote, copyFootnoteHistory, setFootnoteSavingCallCount} = props
  // let promiseTracker = modifications
  const decrementPromise = () => {
    if(setFootnoteSavingCallCount)
      setFootnoteSavingCallCount((prev) => prev - 1)
  }
  let promiseCount = 0
  modifications.forEach((modification) => {
    const footnote = modification.footnote
    const previousFootnote = modification.previousFootnote
    const relation = modification.relation
    const previousClientPortfolios = compact(previousFootnote?.clientPortfolios?.map((cp) => cp?.id)) || []
    const previousPlans = compact(previousFootnote?.plans?.map((cp) => cp?.id)) || []
    const previousTargets = previousFootnote?.clientPortfolioTargets?.map((cpt) => ({clientPortfolio: cpt?.clientPortfolio?.id || 0, target: cpt?.target?.targetId || 0})) || []
    const lastAssociation = union<number | {clientPortfolio: number, target: number}>(difference(previousClientPortfolios, relation.clientPortfolio || []), difference(previousPlans, relation.plan || []), differenceWith(previousTargets, relation.target || [], isEqual)).length === 0
    const updateCache = (cache: any, footnote:FootnoteDetailFragment | undefined) => {
      if(!footnote) return
      footnote.plans?.forEach((plan) => {
        const planFragment = cache.readFragment({
          id: `Plan:${plan?.id}`, // The value of the to-do item's cache ID
          fragment: gql`
            fragment PlanFootnotes on Plan {
              id
              footnotes {
                id
              }
            }
          `,
        });
        let footnotes = cloneDeep(get(planFragment, "footnotes"))
        footnotes = uniqBy(union(footnotes, [footnote]), 'id')
        cache.writeFragment({
          id: `Plan:${plan?.id}`,
          fragment: gql`
            fragment PlanFootnotes on Plan {
              footnotes {
                id
              }
            }
          `,
          data: {
            footnotes: footnotes,
            __typename: "Plan",
          },
        });
      })

      footnote.clientPortfolios?.forEach((cp) => {
        cache.writeFragment({
          id: `ClientPortfolio:${cp?.id}`,
          fragment: gql`
            fragment ClientPortfolioFootnotes on ClientPortfolio {
              footnote {
                id
              }
            }
          `,
          data: {
            footnote: footnote,
            __typename: "ClientPortfolio",
          },
        });
      })

      footnote.clientPortfolioTargets?.forEach((cpt) => {
        if(!(cpt?.clientPortfolio?.id && (relation.target?.filter((target) => target?.clientPortfolio === cpt?.clientPortfolio?.id)?.length || 0) > 0)) return
        const ClientPortfolioFragment = cache.readFragment({
          id: `ClientPortfolio:${cpt?.clientPortfolio?.id}`, // The value of the to-do item's cache ID
          fragment: gql`
            fragment ClientPortfolioFootnotes on ClientPortfolio {
              id
              targetFootnotes {
                id
                clientPortfolioTargets {
                  target {
                    targetId: id
                  }
                }
              }
            }
          `,
        });
        let footnotes = cloneDeep(get(ClientPortfolioFragment, "targetFootnotes")) as any[]
        const targetsList = footnote.clientPortfolioTargets?.map((cpt) => cpt?.target?.targetId) || []
        remove(footnotes, (f) => intersection(targetsList, f?.clientPortfolioTargets?.map((t:any) => t?.target?.targetId)).length > 0)
        footnotes = union(footnotes, [footnote])
        cache.writeFragment({
          id: `ClientPortfolio:${cpt?.clientPortfolio?.id}`,
          fragment: gql`
            fragment ClientPortfolioFootnotes on ClientPortfolio {
              targetFootnotes {
                id
                clientPortfolioTargets {
                  target {
                    targetId: id
                  }
                }
              }
            }
          `,
          data: {
            targetFootnotes: footnotes,
            __typename: "ClientPortfolio",
          },
        });
      })
    }

    if(modification.change === "create") {
      // CREATE

      let input = {
        text: footnote.text,
        clientPortfolios: relation.clientPortfolio,
        plans: relation.plan,
        targets: relation.target,
      } as CreateFootnoteInput

      if(createFootnote){
        promiseCount++
        createFootnote({variables: {input}, update: (cache, data) => updateCache(cache, data.data?.createFootnote?.footnote || undefined)})
          .then((result) => {
          })
          .catch((err) => {
            console.error(err.message)
          })
          .finally(() => {
            decrementPromise()
          })
      }
    } else if (modification.change === "update current" || modification.change === "update new") {
      // UPDATE CURRENT / UPDATE NEW

      let input = {
        id: footnote.id,
        date: modification.change === "update current" ? previousFootnote?.date : null,
        patch:{
          text: footnote.text,
          date: footnote.date,
        }
      } as UpdateFootnoteInput

      if(updateFootnote){
        promiseCount++
        updateFootnote({variables: {input}, update: (cache, data) => updateCache(cache, data.data?.updateFootnote?.footnote || undefined)})
          .then((result) => {
          })
          .catch((err) => {
            console.error(err.message)
          })
          .finally(() => {
            decrementPromise()
          })
      }
    } else if (modification.change === "unlink") {
      // UNLINK

      const createAndLink = () => {
        let input = {
          text: footnote.text,
          clientPortfolios: relation.clientPortfolio,
          plans: relation.plan,
          targets: relation.target,
        } as CreateFootnoteInput

        if(createFootnote){
          createFootnote({variables: {input}, update: (cache, data) => updateCache(cache, data.data?.createFootnote?.footnote || undefined)})
            .then((result) => {
              // TODO: UPDATE CACHE TO USE NEW FOOTNOTE
              if(result) {
                let input = {
                  copyFromId: previousFootnote?.id,
                  copyToId: result.data?.createFootnote?.footnote?.id
                } as CopyFootnoteHistoryInput

                if(copyFootnoteHistory){
                  copyFootnoteHistory({variables: {input}})
                    .then((result) => {
                    })
                    .catch((err) => {
                      console.error(err.message)
                    })
                    .finally(() => {
                      decrementPromise()
                    })
                }
              }
            })
            .catch((err) => {
              decrementPromise()
              console.error(err.message)
            })
        }
      }
      if (lastAssociation) {
        // When last remaining association delete footnote
        let input = {
          id: previousFootnote?.id
        } as DeleteInput

        if (deleteFootnote) {
          promiseCount++
          deleteFootnote({variables: {input}})
            .then((result) => {
              createAndLink()
            })
            .catch((err) => {
              decrementPromise()
              console.error(err.message)
            })
        }
      } else {
        // If there are other associations only remove the old type
        let input = {
          id: previousFootnote?.id,
          date: previousFootnote?.date,
          patch:{
            clientPortfolios: { remove: relation.clientPortfolio },
            plans: { remove: relation.plan },
            targets: { remove: relation.target },
          }
        } as UpdateFootnoteInput

        if(updateFootnote){
          promiseCount++
          updateFootnote({variables: {input}})
            .then((result) => {
              createAndLink()
            })
            .catch((err) => {
              decrementPromise()
              console.error(err.message)
            })
        }
      }

    } else if (modification.change === "link" || modification.change === "link new") {
      // LINK / LINK NEW

      const afterLink = () => {
        if (modification.change === "link") {
          let input = {
            id: footnote.id,
            date: footnote.date,
            patch:{
              text: footnote.text,
              date: footnote.date,
              clientPortfolios: { add: relation.clientPortfolio },
              plans: { add: relation.plan },
              targets: { add: relation.target },
            }
          } as UpdateFootnoteInput

          if (updateFootnote) {
            updateFootnote({variables: {input}, update: (cache, data) => updateCache(cache, data.data?.updateFootnote?.footnote || undefined)})
              .then((result) => {
              })
              .catch((err) => {
                console.error(err.message)
              })
              .finally(() => {
                decrementPromise()
              })
          } else {
            decrementPromise()
          }
        } else if (modification.change === "link new") {
          let input = {
            text: footnote.text,
            clientPortfolios: relation.clientPortfolio,
            plans: relation.plan,
            targets: relation.target,
          } as CreateFootnoteInput

          if(createFootnote){
            createFootnote({variables: {input}, update: (cache, data) => updateCache(cache, data.data?.createFootnote?.footnote || undefined)})
              .then((result) => {
              })
              .catch((err) => {
                console.error(err.message)
              })
              .finally(() => {
                decrementPromise()
              })
          } else {
            decrementPromise()
          }
        }
      }
      if (!previousFootnote) {
        // There is no previous so just add to new
        promiseCount++
        afterLink()
      } else if (lastAssociation) {
        // When last remaining association delete footnote
        let input = {
          id: previousFootnote?.id
        } as DeleteInput

        if (deleteFootnote) {
          promiseCount++
          deleteFootnote({variables: {input}})
            .then((result) => {
              afterLink()
            })
            .catch((err) => {
              decrementPromise()
              console.error(err.message)
            })
        }
      } else {
        // If there are other associations only remove the old type
        let input = {
          id: previousFootnote?.id,
          date: previousFootnote?.date,
          patch:{
            clientPortfolios: { remove: relation.clientPortfolio },
            plans: { remove: modification.change === "link new" ? [] : relation.plan },
            targets: { remove: relation.target },
          }
        } as UpdateFootnoteInput

        if(updateFootnote){
          promiseCount++
          updateFootnote({variables: {input}})
            .then((result) => {
              afterLink()
            })
            .catch((err) => {
              decrementPromise()
              console.error(err.message)
            })
        }
      }
    } else if (modification.change === "delete") {
      // DELETE

      let input = {
        id: footnote.id
      } as DeleteInput
      // If does not have positive integer id then it is not in graphql yet
      if (deleteFootnote && footnote.id && footnote.id >= 0) {
        promiseCount++
        deleteFootnote({variables: {input},
          update: (cache) => {
            footnote.plans?.forEach((plan) => {
              const planFragment = cache.readFragment({
                id: `Plan:${plan?.id}`, // The value of the to-do item's cache ID
                fragment: gql`
                  fragment PlanFootnotes on Plan {
                    id
                    footnotes {
                      id
                    }
                  }
                `,
              });
              let footnotes = cloneDeep(get(planFragment, "footnotes", [] as Footnote[]))
              remove(footnotes, (v:Footnote) => v.id === footnote.id)
              cache.writeFragment({
                id: `Plan:${plan?.id}`,
                fragment: gql`
                  fragment PlanFootnotes on Plan {
                    footnotes {
                      id
                    }
                  }
                `,
                data: {
                  footnotes: footnotes,
                  __typename: "Plan",
                },
              });
            })

            footnote.clientPortfolios?.forEach((cp) => {
              cache.writeFragment({
                id: `ClientPortfolio:${cp?.id}`,
                fragment: gql`
                  fragment ClientPortfolioFootnotes on ClientPortfolio {
                    footnote {
                      id
                    }
                  }
                `,
                data: {
                  footnote: null,
                  __typename: "ClientPortfolio",
                },
              });
            })

            footnote.clientPortfolioTargets?.forEach((cpt) => {
              if(!(cpt?.clientPortfolio?.id && (relation.target?.filter((target) => target?.clientPortfolio === cpt?.clientPortfolio?.id)?.length || 0) > 0)) return
              cache.modify({
                id: `ClientPortfolio:${cpt?.clientPortfolio?.id}`, // replace `target` with the object that contains `targetFootnotes`
                fields: {
                  targetFootnotes(existingFootnotes = []) {
                    console.log({existingFootnotes, footnote})
                    return existingFootnotes.filter((footn:any) => footn.__ref !== `Footnote:${footnote.id}`);
                  }
                }
              });
            })
          }})
          .then((result) => {
          })
          .catch((err) => {
            console.error(err.message)
          })
          .finally(() => {
            decrementPromise()
          })
      }
    }
  })
  if(setFootnoteSavingCallCount) setFootnoteSavingCallCount(promiseCount)
  return
}