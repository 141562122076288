import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

export interface FeeHistoryRow {
  date: string | null
  data: (string | null)[][]
}

export interface FeeHistoryData {
  headers: string[]
  rows: FeeHistoryRow[]
}

interface FeeHistoryModalProps {
  isOpen: boolean
  title: string
  toggle: () => void
  data: FeeHistoryData
  isValidUntilDate?: boolean
}

export const FeeHistoryModal: React.FC<FeeHistoryModalProps> = ({
  isOpen,
  title,
  toggle,
  data,
  isValidUntilDate,
}) => {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header">Historical Data</ModalHeader>
      <ModalBody>
        <h4 className="fee-modal-title">{title}</h4>
        <div className="pl-3 pr-3 pt-2 pb-2">
          <table className="fee-modal-table">
            <thead>
              <tr>
                {data.headers.map((header, idx) => (
                  <th key={idx}>{header}</th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
        {data.rows.map((row, idx) => {
          return (
            <div className="fee-modal-history-item" key={idx}>
              <div className="fee-modal-as-of-date">{isValidUntilDate? "Valid Until ": "As of "}{row.date}</div>
              <table className="fee-modal-table">
                <tbody>
                  {row.data.map((tr, idx) => (
                    <tr key={idx}>
                      {tr.map((td, idx) => (
                        <td key={idx}>{td}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        })}
      </ModalBody>
    </Modal>
  )
}
