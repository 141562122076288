import { ApolloQueryResult } from '@apollo/client'
import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { EditButtonContext } from '../../Context/EditButtonContext'
import { convertLookupToString, excludePropertyArray } from '../../helpers/object'
import { appProps } from '../../queries/extended/appProps'
import { DocumentRouteProps } from '../../queries/extended/types/Documents'
import { File as Document, FileDetailsQuery, FileDetailsQueryVariables, FileTypeSubTypeMap, UpdateFileMetadataInput, useFileDetailsQuery, useMeQuery, useUpdateFileMetadataMutation } from '../../__generated__/graphql'
import { DisplayDocs, getDocumentPermission } from '../Shared/Document'
import ErrorDisplay from '../Shared/ErrorDisplay'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import EditButtons from '../ui/EditButtons'
import { WithTopNav } from '../ui/LayoutWrapper'
import LoadingOverlay from '../ui/Loading'


const QueryDocumentDetails: React.FC<appProps & DocumentRouteProps> = props => {
  const { match } = props
  const { documentId } = match.params
  const auth = props.auth

  const { loading, error, data, refetch } = useFileDetailsQuery({
    variables: { id: documentId }
  })

  if (loading) {
    return <LoadingOverlay loadingMessage={"Loading..."} />
  }
  if (error) {
    return (
      <div>
        <ErrorDisplay error={error}/>
      </div>
    )
  }
  if (!data || !data.file) {
    return <p>Document Data not found</p>
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <h2 className="headline">{data.file?.description || data.file?.name}</h2>
            {/* <Nav className="sub-nav sub-nav-primary" tabs role="group" aria-label="Document Subpage">

            </Nav> */}
          </Col>
        </Row>
      </Container>
      <DocumentShow
        auth={auth}
        document={data.file as Document}
        typeMapping={data.fileTypeMap as FileTypeSubTypeMap[]}
        refetchQuery={refetch}
      />
    </div>
  )
}

interface DocumentShowProps {
  auth: Auth
  document: Document
  typeMapping: FileTypeSubTypeMap[]
  refetchQuery: (variables?: FileDetailsQueryVariables | undefined) => Promise<ApolloQueryResult<FileDetailsQuery>>
}

export const DocumentShow: React.FC<DocumentShowProps> = ({ auth, document, typeMapping, refetchQuery }) => {
  const [editMode, setEditMode] = useState(false)
  // const [saving, setSaving] = useState(false)

  const resultRef = useRef<DisplayDocs>(null)
  const [hasError, setHasError] = useState(false)

  // const [updateFileMutation] = useUpdateFileMetadataMutation()
  const context = useContext(EditButtonContext)
  const { setOptions, showButtons } = context
  const documentPermissions = getDocumentPermission({document: document as Document, page: "Manager", auth})

  const { data:userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined
  // const history = useHistory()
  if(showButtons !== documentPermissions.edit)
    setOptions({showButtons: documentPermissions.edit})

  // const handleSubmit = () => {
  //   const currentDocument = resultRef!.current?.state.currentState
  //   if(!documentPermissions.edit){
  //     return
  //   }

  //   if(!currentDocument){
  //     return
  //   }
  //   if(hasError){
  //     return
  //   }
  //   setSaving(true)
  //   const { id, ...rest } = currentDocument
  //   const patch = { ...rest }
  //   let formattedPatch = convertLookupToString(
  //     patch,
  //     false,
  //   )
  //   let updateData = excludePropertyArray(formattedPatch, ["__typename", "name", "versions", "body", "mimeType", "versionId", "url"])
  //   updateData.managers = updateData.managers.map((ob:any) => ob.id)
  //   updateData.plans = updateData.plans.map((ob:any) => ob.id)
  //   updateData.products = updateData.products.map((ob:any) => ob.product.id)
  //   updateData.vehicles = updateData.vehicles.map((ob:any) => ob.vehicle.fundid)
  //   updateData.glidePaths = updateData.glidePaths.map((ob:any) => ob.id)
  //   updateData.clients = updateData.clients.map((ob:any) => ob.id)

  //   const input = { id, patch: updateData } as UpdateFileMetadataInput

  //   updateFileMutation({ variables: { input } })
  //     .then(result => {
  //       if (result && result.data) {
  //         //let unformattedNewData = { assets: result.data.assets?.org }
  //         setEditMode(false)
  //         setSaving(false)
  //       }
  //     })
  //     .catch(err => {
  //       setSaving(false)
  //       console.log("Error testManagerSummary", err.message)
  //       // throw new Error(`${err.message}`)
  //     })
  // }

  // const heading = (
  //   <>
  //     <RouteLeavingGuard
  //       when={editMode}
  //       navigate={path => history.push(path)}
  //     />
  //     <div className="pane pane-toolbar sticky-top above-picker">
  //       <div className="mr-2 pr-3">
  //       </div>
  //       <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} disableOnError={true} hideOnContext={true}/>
  //     </div>
  //   </>
  // )

  return (
    <Container fluid>
      {/* {heading} */}
      <Row>
        <DisplayDocs
          editMode={editMode}
          setEditMode={setEditMode}
          document={document as Document}
          typeMapping={typeMapping as FileTypeSubTypeMap[]}
          ref={resultRef}
          user={user}
          auth={auth}
          refetchQuery={refetchQuery}
          hasError={(value:boolean) => setHasError(value)}
          fullWidth={true}
        />
      </Row>
    </Container>
  )
}


export default WithTopNav(QueryDocumentDetails)