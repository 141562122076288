import { GridApi } from "@ag-grid-community/core";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { cloneDeep, isEmpty } from "lodash";
import React from "react"


export interface FuncProps {
  setGrid: (agGrid: GridApi) => void
  
}
export interface ContextProps extends FuncProps {
  agGrid?: GridApi
}


const initialState:ContextProps = {
    agGrid: undefined,
    setGrid: (agGrid: GridApi) => {},
}

const IState:ContextProps = {
    agGrid: initialState.agGrid,
    setGrid: initialState.setGrid,
}

export const AgGridContext = React.createContext<ContextProps>(IState)

// Provider for sidebar
export class AgGridProvider extends React.Component {
  constructor(props:any) {
    super(props);
    this.state = {
      agGrid: initialState.agGrid,
      setGrid: this.setGrid,
    }
  }

  state:ContextProps

  

  setGrid = (agGrid: GridApi) => {
    this.setState({agGrid})
  }

  render() {
    return (
      <AgGridContext.Provider
        value={this.state}
      >
        {this.props.children}
      </AgGridContext.Provider>
    )
  }
}
