import React, { useEffect, useState } from 'react'
import { Button, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import { FormInputSubClasses } from '../../helpers/constant'
import { FundRaising } from '../../__generated__/graphql'
import { FormInput } from '../ui/Forms/FormInput'

type columnDefItem = {
  field: string
  type: string
  title: string
  className?: string
  subtype?: string
  optionSource?: string
  subClasses?: { [name in FormInputSubClasses]?: string}
  noFormat?: true
}

type FundRaisingTableProps = {
  data: FundRaising[]
  handleChange: (state: any) => void
  editMode: boolean
  hidden: boolean
}

const FundRaisingColumnDef: columnDefItem[] = [
  {
    field: "date",
    type: "date",
    title: "Date",
    className: "",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "col-sm-12 pl-1",
      inputClasses: "text-right",
      wrapperClasses: "no-export-form" 
    }
  },
  {
    field: "committedCapital",
    type: "float",
    subtype: "currency",
    title: "Committed Capital",
    className: "text-center",
    subClasses: {
      labelClasses: "hidden",
      inputWrapperClasses: "col-sm-12 pl-1",
      wrapperClasses: "no-export-form" 
    }
  }
]

interface FundRaisingTableRowProps {
  data: any
  columnDef: columnDefItem[]
  highlightRow?: boolean
  idx: number
  editMode: boolean
  updateValue: (value: any, type: string, property: string) => void
  action: {
    add: (input: any) => void
    remove: (input: any) => void
  }
}

const FundRaisingTableRow = ({
  data,
  columnDef,
  idx,
  editMode,
  action,
  updateValue
}: FundRaisingTableRowProps) => {
  return (
    <tr className={ editMode ? "hover-actions editing" : "hover-actions" }>
      {columnDef.map(header => {
        return (
          <td key={header.field}>
            <FormInput
              key={header.field}
              property={header.field}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={idx}
              editMode={editMode}
              propertyVal={data[header.field]}
              placeholder={"-"}
              noFormat={header.noFormat}
              updateValue={value =>
                updateValue(value, header.type, header.field)
              }
              required={true}
              subClasses={header.subClasses}
            />
          </td>
        )
      })}
      {editMode && (
        <td className={"actions"}>
          <Button
            color="link"
            className="ml-auto"
            onClick={() => action.remove(idx)}
          >
            <FontAwesomeIcon icon="trash" className="ml-2 btn-thin text-blue-100" />
          </Button>
        </td>
      )}
    </tr>
  )
}

const FundRaisingTableDisplay = (seed: FundRaisingTableProps) => {
  const tableName = "Fund-Raising"
  const { data, editMode, handleChange, hidden } = seed
  const [tableDataState, setTableData] = useState(data)

  useEffect(() => {
    setTableData(data)
  }, [data])

  const onAddFundRaisingRow = (row: any) => {
    const newData = [...tableDataState, row]
    setTableData(newData)
    handleChange(newData)
  }

  const onRemoveFundRaisingRow = (colIndex: number) => {
    let newData = [
      ...tableDataState.slice(0, colIndex),
      ...tableDataState.slice(colIndex + 1)
    ]
    setTableData(newData)
    handleChange(newData)
  }
  const actionMap = {
    add: onAddFundRaisingRow,
    remove: onRemoveFundRaisingRow
  }

  const onHandleChangeFundRaisingRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData = tableDataState.map((el, index) => {
      if (idx === index) {
        return { ...tableDataState[index], [property]: value }
      } else {
        return el
      }
    })
    setTableData(newData)
    handleChange(newData)
  }

  return (
    <>
      <div className={"row pt-1"}>
        {"Please enter the date and the Committed Amount."}
      </div>
      <div
        className={classNames(`${tableName}-table row pl-0`, {
          "col-sm-9": editMode,
          "col-sm-7": !editMode
        })}
      >
        <Table
          hover
          size="sm"
          key={`FundRaising-table`}
          className={"equal-width exportable"}
          data-export-name="FundRaising"
        >
          <thead className="">
            <tr className="table-header">
              {FundRaisingColumnDef.map((header, index) => (
                <th
                  key={`{tableName}-header-${index}`}
                  className={header.className}
                >
                  {header.title}
                </th>
              ))}
              {editMode && (
                <th key={`Performance-header-2`}>
                  <Button
                    color="link"
                    className="ml-auto"
                    onClick={() =>
                      actionMap.add({ date: null, committedCapital: null })
                    }
                  >
                    Add Row
                    <FontAwesomeIcon
                      icon="plus-circle"
                      className="ml-2 text-blue-100"
                    />
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody key={0}>
            {tableDataState.map((row: any, idx: number) => {       
              return (
                <FundRaisingTableRow
                  data={row}
                  columnDef={FundRaisingColumnDef}
                  key={idx}
                  idx={idx}
                  editMode={editMode}
                  action={actionMap}
                  updateValue={(value, type, property) =>
                    onHandleChangeFundRaisingRow(idx, value, type, property)
                  }
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default FundRaisingTableDisplay
