import classnames from 'classnames'
import { find, get, set, cloneDeep, isEmpty, has, remove, findIndex } from 'lodash'
import React, { Component, useState, useContext, useRef, RefObject } from 'react'
import { Table, Row, Col, Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import iassign from 'immutable-assign'
import moment from 'moment'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numbro from 'numbro'
import { History } from 'history'
import { useHistory } from 'react-router-dom'

import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import { CalendarContext, appDate } from '../../Context/CalendarContext'
import { useProductAssetsByVehicleQuery, ProductAssetsByVehicleQuery, ProductAssetsByVehicle, VehicleTypeCodeEv, OriginCode, ProductAssetsByVehicleSingleFields, useUpdateProductAssetsByVehicleSingleMutation, useUpdateProductAssetsByVehicleHistoricalMutation, ProductAssetsByVehicleHistoricalRow, ProductAssetsByVehicleHistoricalFields } from '../../__generated__/graphql'
import { CalendarPicker } from '../CalendarPicker'
import PlaceHolder from '../ui/PlaceHolder'
import { FormInputField, DATE_API_FORMAT } from '../../helpers/constant'
import { FormInput } from '../ui/Forms/FormInput'
import { HistoricalDisplayValueType } from '../Org/ManagerAssetsVehicles'
import { listQuarters } from "../../helpers/helpers"
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import exportTables from '../../helpers/exportTable'
import ErrorDisplay from '../Shared/ErrorDisplay'

interface GroupedAssets {
  [key: string]: GroupedAsset
}

interface GroupedAsset {
  [key: string]: ProductAssetsByVehicle
}

interface idProps {
  productId: number
  auth: Auth
}

// interface stateSingleDiffType:ProductAssetsByVehicleSingleFields {
//   fields: [],
// }
// export interface stateHistoricDiffType<T extends HistoricUpdateRow> {
//   categoryType: CategoryType
//   updateType: T
//   rows: HistoricUpdateRowMap[T][]
// }

const firstHistoricalDate = moment(appDate).subtract(5,"years")

type updateType = "single" | "historic"

const ProductAssetsVehicle: React.FC<idProps> = ({ productId, auth }: idProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [screen, setScreen] = useState(context.period === "historical" ? "historical" :"single")
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [historicLoading, setHistoricLoading] = useState(false)
  const [historicLoadDate, setHistoricLoadDate] = useState(firstHistoricalDate)
  const historicalResultRef = useRef<HistoricResult>(null)
  const history = useHistory()
  const [stateDiff, setStateDiff] = useState([] as ProductAssetsByVehicleSingleFields[])
  const [historicStateDiff, setHistoricStateDiff] = useState([] as ProductAssetsByVehicleHistoricalRow[])

  const [updateProductAssetsByVehicle] = useUpdateProductAssetsByVehicleSingleMutation()
  const [updateProductAssetsByVehicleHistoric] = useUpdateProductAssetsByVehicleHistoricalMutation()
  // const [updateProductAssetsByVehicleHistoricAccounts] = useUpdateProductAssetsByVehicleHistoricalAccountsMutation()

  let { data, loading, error, fetchMore } =
  useProductAssetsByVehicleQuery({
    variables: {
      id: productId,
      startDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT),
      endDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT),
    },
    // partialRefetch: true,
    fetchPolicy: 'network-only'
  })


  const handleEdit = () => {
    // singleResultRef!.current?.resetForm()
    historicalResultRef!.current?.resetForm()
    setStateDiff([])
    setHistoricStateDiff([])
    setEditMode(!editMode)
  }

  const historicLoadMore = () =>{
    if(historicLoading){
      return
    }
    setHistoricLoading(true)
    fetchMore({
      variables: {
        id: productId,
        startDate: moment(historicLoadDate).subtract(5, "years").format(DATE_API_FORMAT),
        endDate: historicLoadDate.format(DATE_API_FORMAT),
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if(!fetchMoreResult){
          return previousResult
        }
        historicalResultRef.current?.loadMore(fetchMoreResult)

        setHistoricLoadDate(moment(historicLoadDate).subtract(5, "years"))
        setHistoricLoading(false)
        return previousResult
      }
    })
  }

  const afterUpdate = (resultPromise:Promise<any>) => {
    resultPromise.then(result => {
      setSaving(false)
      if (result && result.data) {
        let unformattedNewData = { assets: result.data.product?.product }
        setEditMode(false)
        if (screen === "historical"){
          setHistoricLoadDate(moment(firstHistoricalDate))
          historicalResultRef.current?.resetHistory()
        }
        setStateDiff([])
        setHistoricStateDiff([])
      }
    })
    .catch(err => {
      setSaving(false)
      console.error("Error testManagerSummary", err.message)
    })
  }

  const handleChange = (updatedCell:ProductAssetsByVehicle) => {
    if (screen === "single") {

      remove(stateDiff, (field:ProductAssetsByVehicleSingleFields) => {
        return field.vehicle === updatedCell.vehicleType.code
      })

      stateDiff.push({
        vehicle: updatedCell.vehicleType.code,
        assetsUnderManagement: updatedCell.assetsUnderManagement,
        numberOfAccounts: updatedCell.numberOfAccounts
      })

      setStateDiff(stateDiff)
    } else {
      let historicState = cloneDeep(historicStateDiff)
      let rowIndex = findIndex(historicState, (row) => row.date === updatedCell.quarterEndDate)
      let row:ProductAssetsByVehicleHistoricalRow

      if (rowIndex >= 0) {
        row = historicState[rowIndex]
        remove(row.fields, (field:ProductAssetsByVehicleHistoricalFields) => field.vehicle === updatedCell.vehicleType.code)
      } else {
        row = { date: updatedCell.quarterEndDate, fields: []} as ProductAssetsByVehicleHistoricalRow
      }

      row.fields.push({
        vehicle: updatedCell.vehicleType.code,
        assetsUnderManagement: updatedCell.assetsUnderManagement,
        numberOfAccounts: updatedCell.numberOfAccounts
      })

      if (rowIndex >= 0) {
        historicState[rowIndex] = row
      } else {
        historicState.push(row)
      }

      setHistoricStateDiff(historicState)
    }
  }

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let returnPromise:Promise<any>
    if (screen === "single") {
      let submissionData = {
        id: productId,
        date: searchDate,
        fields: stateDiff
      }

      returnPromise = updateProductAssetsByVehicle({ variables: { input: submissionData, startDate: searchDate, endDate: searchDate } })
    } else {
      let submissionData = {
        id: productId,
        rows: historicStateDiff
      }

      returnPromise = updateProductAssetsByVehicleHistoric({
        variables: {
          input: submissionData,
          startDate: firstHistoricalDate.format(DATE_API_FORMAT),
          endDate: appDate.format(DATE_API_FORMAT),
        }
      })
    }

    afterUpdate(returnPromise)

  }


  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={true}
          updateType={(type:string) => setScreen(type)}
          editMode={editMode}
          setEditMode={(value:boolean) => setEditMode(value)}
        />
        {screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={()=> historicLoadMore() }>
            {historicLoading && "Loading"}
            {!historicLoading && "Load 5 More Years"}
          </Button>
        }
        <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables()}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={handleEdit} saving={saving} onSubmit={handleSubmit} />
        }
      </div>
    </>
  )

  if (loading) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
            </Col>
        </Row>
      </Container>
    );
  }
  if (error) {
    return (
      <Container fluid className="px-0">
        <Row>
          <Col>
            {heading}
            <ErrorDisplay error={error}/>
          </Col>
        </Row>
      </Container>
    );
  }
  if (data && data.product ) {
    if(screen === "single"){
      return (
        <Container fluid className="px-0">
          <Row>
            <Col>
              {heading}
              <div className="pane pane-table">
                <div className="pane-title-alt">
                  <h3>Assets by Vehicle</h3>
                </div>
                <ProductAssetsChart data={data} />
                <br/>
                <ProductAssetsTable
                  editMode={editMode}
                  setEditMode={setEditMode}
                  setSearchDate={setSearchDate}
                  data={data}
                  searchDate={searchDate}
                  onChange={handleChange}
                  history={history}
                />
              </div>
            </Col>
          </Row>
        </Container>
      )
    } else if(screen === "historical") {
      return (
        <Container fluid className="px-0">
          <Row>
            <Col>
              {heading}
              <HistoricResult
                editMode={editMode}
                setEditMode={setEditMode}
                setSearchDate={setSearchDate}
                data={data}
                searchDate={searchDate}
                onChange={handleChange}
                fetchMore={fetchMore}
                ref={historicalResultRef}
                history={history}
              />
            </Col>
          </Row>
        </Container>
      )
    }
  }
  return <div>data doesn't exist</div>
}

interface ProductAssetsTableProps {
  setSearchDate: (searchDate:string) => void
  data: ProductAssetsByVehicleQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  onChange: (updatedCell:ProductAssetsByVehicle) => void,
  history: History
}

interface ProductAssetsTableState {
  currentState: GroupedAsset
  initialState: GroupedAsset
}

interface VehicleTypeInputField extends FormInputField{
  vehicleType?: VehicleTypeCodeEv
}

class ProductAssetsTable extends Component<ProductAssetsTableProps,ProductAssetsTableState> {
  constructor(props: any) {
    super(props)

    const assets:ProductAssetsByVehicle[] = get(props.data, "product.product.assetsByVehicle", [])
    let groupedData:GroupedAsset = {}
    assets.map((entry:ProductAssetsByVehicle) => {
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code

      set(groupedData, [vehicleType], entry)
    })

    this.state = {
      currentState: groupedData,
      initialState: groupedData
    }
  }

  static getDerivedStateFromProps(props: ProductAssetsTableProps, state:ProductAssetsTableState) {
    const assets:ProductAssetsByVehicle[] = get(props.data, "product.product.assetsByVehicle", [])
    let groupedData:GroupedAsset = {}
    assets.map((entry:ProductAssetsByVehicle) => {
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code

      set(groupedData, [vehicleType], entry)
    })

    return {
      currentState: props.editMode ? state.currentState : groupedData,
      initialState: groupedData,
    }
  }

  handleInputChange = (
    value: any,
    property: HistoricalDisplayValueType,
    vehicleType: VehicleTypeCodeEv
  ) => {
    let updatedCell:ProductAssetsByVehicle
    let newState = cloneDeep(this.state.currentState)
    newState = iassign(
      newState,
      selectedRow => {
        let row:GroupedAsset = cloneDeep(selectedRow)
        let cell:ProductAssetsByVehicle
        let selectedCell = get(selectedRow, [vehicleType]) as ProductAssetsByVehicle
        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: null,
            vehicleType: {code: vehicleType as VehicleTypeCodeEv, __typename: "OrgVehicleTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: this.props.searchDate,
            __typename: "ProductAssetsByVehicle",
            origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'}
          }
        }

        set(cell, property, value)
        set(row, [vehicleType], cell)
        updatedCell = cell
        return row
      }
    )
    this.setState({ currentState: newState }, () =>
      this.props.onChange(updatedCell)
    )
  }



  render() {
    const { editMode } = this.props
    const { currentState:data} = this.state

    const assetsVehicleTypeInputList:VehicleTypeInputField[] = [
      { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.TOT},
      { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.SA},
      { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.POOL},
      { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.MFI},
      { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", vehicleType: VehicleTypeCodeEv.MFR},
    ]
    const accountsVehicleTypeInputList:VehicleTypeInputField[] = [
      { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.TOT },
      { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.SA },
      { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.POOL },
      { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.MFI },
      { property: "numberOfAccounts", label: "", type: "number", vehicleType: VehicleTypeCodeEv.MFR },
    ]

    let assetsCalculatedTotal = 0
    let accountsCalculatedTotal = 0
    return(
      <>
        <RouteLeavingGuard
          when={editMode}
          navigate={path => this.props.history.push(path)}
        />
        <div className="table-container">
          <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.product?.product?.name}-client`}>
            <thead>
              <tr className="table-title row-border-olive-100">
                <th colSpan={7} className="py-2">Assets ($M)</th>
                <th className="boundary-left"></th>
                <th className="boundary-right"></th>
                <th colSpan={6} className="py-2"># Accounts</th>
              </tr>
              <tr>
                <th></th>
                <th className="text-right width-100">Total</th>
                <th className="text-right">Separate Account</th>
                <th className="text-right">Pooled/Commingled</th>
                <th className="text-right width-140" id="mutualFundInstitutionalTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="mutualFundInstitutionalTooltip">
                    Mutual Fund Institutional
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                <th className="text-right width-140" id="mutualFundRetailTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="mutualFundRetailTooltip">
                    Mutual Fund Retail
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                <th className="text-right">Calculated Total</th>
                <th className="boundary-left"></th>
                <th className="boundary-right"></th>
                <th className="text-right width-100">Total</th>
                <th className="text-right">Separate Account</th>
                <th className="text-right" id="pooledCommingledTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="pooledCommingledTooltip">
                    Pooled/Commingled
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                <th className="text-right width-140">Mutual Fund Institutional</th>
                <th className="text-right width-140">Mutual Fund Retail</th>
                <th className="text-right">Calculated Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total</td>
                {
                  assetsVehicleTypeInputList.map(({property, label, type, subtype, vehicleType}, idx) => {
                    let propertyVal, onChangeCallback
                    propertyVal = get(data, [vehicleType as string, property])
                    onChangeCallback = (value:any) => vehicleType ? this.handleInputChange(value, property as HistoricalDisplayValueType, vehicleType) : null
                    if (vehicleType !== VehicleTypeCodeEv.TOT) {
                      assetsCalculatedTotal += parseFloat(propertyVal || 0)
                    }

                    return (
                      <td key={`assets-${idx}`} className="text-right">
                        <FormInput
                          property={property}
                          displayName={label}
                          type={type}
                          subtype={subtype}
                          idx={`AssetsByVehicle-${property}.${vehicleType}`}
                          editMode={editMode}
                          showZero={true}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                        />
                      </td>
                    )
                  })
                }
                <td key={`AssetsByVehicle-assets-calc-total`} className="text-right">{numbro(assetsCalculatedTotal || 0).format('$0,0.00')}</td>
                <td className="boundary-left"></td>
                <td className="boundary-right"></td>
                {
                  accountsVehicleTypeInputList.map(({property, label, type, subtype, vehicleType}, idx) => {
                    let propertyVal, onChangeCallback
                    propertyVal = get(data, [vehicleType as string, property])
                    onChangeCallback = (value:any) => vehicleType ? this.handleInputChange(value, property as HistoricalDisplayValueType, vehicleType) : null

                    if (vehicleType !== VehicleTypeCodeEv.TOT) {
                      accountsCalculatedTotal += parseFloat(propertyVal || 0)
                    }

                    return (
                      <td key={idx} className="text-right">
                        <FormInput
                          property={property}
                          displayName={label}
                          type={type}
                          subtype={subtype}
                          idx={`AssetsByVehicle-${property}.${vehicleType}`}
                          editMode={editMode}
                          showZero={true}
                          propertyVal={propertyVal}
                          updateValue={onChangeCallback}
                        />
                      </td>
                    )
                  })
                }
                <td key={`AssetsByVehicle-accounts-calc-total`} className="text-right">{accountsCalculatedTotal}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    )
  }

}

interface ProductAssetsChartProps {
  data: ProductAssetsByVehicleQuery
}

class ProductAssetsChart extends Component<ProductAssetsChartProps> {
  state = {
    displayValue: "assetsUnderManagement",
  }

  setDisplayValue = (displayValue:HistoricalDisplayValueType) => {
    this.setState({
      displayValue: displayValue
    })
  }

  render() {
    if (isEmpty(this.props.data.product?.product?.assetsByVehicle)) { return <></> }

    const displayValue = this.state.displayValue

    const columnOrder = [
      VehicleTypeCodeEv.TOT, VehicleTypeCodeEv.SA, VehicleTypeCodeEv.POOL, VehicleTypeCodeEv.MFI, VehicleTypeCodeEv.MFR
    ]
    const seriesData:number[] = columnOrder.map((vehicleType) => {
      let entry = find(this.props.data.product?.product?.assetsByVehicle, d => d?.vehicleType?.code === vehicleType )
      return get(entry, displayValue) || null
    })

    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        style: {
          fontFamily: '"Lato", "Segoe UI", arial, sans-serif'
        },
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      yAxis: {
        min: 0,
        title: { text: displayValue === 'assetsUnderManagement' ? 'Assets ($M)' : '# Accounts' },
        allowDecimals: false
      },
      xAxis: {
        categories: ['Total', 'Separate Account', 'Pooled/Commingled', 'Mutual Fund Inst', 'Mutual Fund Retail']
      },
      series: [{
        name: displayValue === 'numberOfAccounts' ? '# Accounts ' : 'AUM ($M) ',
        showInLegend: false,
        type: 'column',
        data: seriesData,
        minPointLength: 2,
        color: '#6B90AD'
      }],
      plotOptions: {
        series: {
          dataLabels: { enabled: false }
        }
      }
    }

    const heading = () =>{
      return(
      <div className="w-100 d-flex justify-content-around">
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets ($M)</li>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
      </div>)
    }

    return (
      <>
        { heading() }
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </>
    )
  }
}

interface HistoricResultProps {
  setSearchDate: (searchDate:string) => void
  data: ProductAssetsByVehicleQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  onChange: (updatedCell:ProductAssetsByVehicle) => void,
  fetchMore: any
  ref: RefObject<HistoricResult>
  history: History
}

interface HistoricResultState {
  currentState: GroupedAssets
  initialState: GroupedAssets
  loadingMore: boolean
  historicalDate: moment.Moment
  displayValue: HistoricalDisplayValueType
}
class HistoricResult extends Component<HistoricResultProps, HistoricResultState> {
  constructor(props: any) {
    super(props)

    this.state = {
      currentState: HistoricResult.groupData(props),
      initialState: HistoricResult.groupData(props),
      loadingMore: false,
      historicalDate: moment(firstHistoricalDate),
      displayValue: "assetsUnderManagement",
    }

  }

  static getDerivedStateFromProps(props: HistoricResultProps, state:HistoricResultState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    const groupData = HistoricResult.groupData(props)
    return {
      currentState: resetDate && !props.editMode ? groupData : state.currentState,
      initialState: resetDate ? groupData : state.initialState,
      loadingMore: state.loadingMore,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
    }
  }

  static groupData = (props:HistoricResultProps) => {
    const assets:ProductAssetsByVehicle[] = get(props.data, "product.product.assetsByVehicle", [])
    let groupedData:GroupedAssets = {}
    assets.map((entry:ProductAssetsByVehicle) => {
      let date: string = entry.quarterEndDate
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code
      set(groupedData, [date, vehicleType], entry)
    })

    return groupedData
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  setDisplayValue = (displayValue:HistoricalDisplayValueType) => {
    this.setState({
      displayValue: displayValue
    })
  }

  loadMore = (fetchMoreResult:ProductAssetsByVehicleQuery) =>{
    const newInitialState = this.state.initialState
    const newCurrentState = cloneDeep(this.state.currentState)
    const newAssets:ProductAssetsByVehicle[] = get(fetchMoreResult, "product.product.assetsByVehicle", [])

    newAssets.map((entry:ProductAssetsByVehicle) => {
      let date: string = entry.quarterEndDate
      let vehicleType:VehicleTypeCodeEv = entry.vehicleType.code

      if (!has(this.state.currentState, [date, vehicleType])) {
        set(newInitialState, [date, vehicleType], entry)
        set(newCurrentState, [date, vehicleType], entry)
      }
    })
    this.setState({
      initialState: newInitialState,
      currentState: newCurrentState,
      historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
      loadingMore: false
    })

  }

  onChangeCallback = (
    date: string,
    vehicleType: VehicleTypeCodeEv,
    value: any,
  ) => {
    const { displayValue } = this.state
    let newState = cloneDeep(this.state.currentState)
    if(!newState[date]){
      set(newState, [date], {})
    }
    if(!newState[date][vehicleType]){
      set(newState, [date, vehicleType], null)
    }

    newState = iassign(
      newState,
      (currentState) => currentState[date][vehicleType],
      selectedCell => {
        let cell:ProductAssetsByVehicle

        if (selectedCell) {
          cell = cloneDeep(selectedCell)
        } else {
          cell = {
            assetsUnderManagement: 0.0,
            vehicleType: {code: vehicleType as VehicleTypeCodeEv, __typename: "OrgVehicleTypeLookup"},
            numberOfAccounts: 0,
            quarterEndDate: date,
            __typename: "ProductAssetsByVehicle",
            origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'}
          }
        }

        set(cell, displayValue, value)
        return cell
      }
    )

    let updatedCell:ProductAssetsByVehicle = get(newState, [date, vehicleType])

    this.setState({ currentState: newState }, () => {
      this.props.onChange(updatedCell)
    })
  }

  render() {
    const { editMode } = this.props
    const { displayValue } = this.state
    const data = this.state.currentState

    let visibleDates:string[] = Object.keys(data)
    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))



    return(
      <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => this.props.history.push(path)}
      />
      <div className="pane pane-table">
        <div className="pane-title-alt">
          <h3>Assets by Vehicle</h3>
        </div>
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-item",{ active: displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets ($M)</li>
          <li className={classnames("horizontal-picker-item",{ active: displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
        <div className="table-container">
          <Table hover className="table-bordered-internal table-condensed exportable" data-export-name={`${this.props.data.product?.product?.name}-client`}>
            <thead>
              <tr>
                <th>Date</th>
                <th className="text-right">Total</th>
                <th className="text-right">Separate Account</th>
                { displayValue === "assetsUnderManagement" ?
                  <th className="text-right">Pooled/Commingled</th>
                  :
                  <th className="text-right" id="pooledCommingledHistoricalTooltipContainer">
                    <div className="d-inline-flex align-items-center tooltip-icon" id="pooledCommingledHistoricalTooltip">
                      Pooled/Commingled
                      <FontAwesomeIcon
                        icon={"question-circle" as IconName}
                        size="sm"
                      />
                    </div>
                  </th>
                }
                <th className="text-right" id="mutualFundInstitutionalHistoricalTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="mutualFundInstitutionalHistoricalTooltip">
                    Mutual Fund Institutional
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                <th className="text-right" id="mutualFundRetailHistoricalTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="mutualFundRetailHistoricalTooltip">
                    Mutual Fund Retail
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                <th className="text-right">Calculated Total</th>
              </tr>
            </thead>
            <tbody>
              {allDates.map((quarter:string, row:number) => {
                return (
                  <AssetClassHistoricalRow
                    date={quarter}
                    key={row + displayValue}
                    data={data}
                    row={row}
                    editMode={editMode}
                    updateValue={(value, property, vehicleType, date) => this.onChangeCallback(date, vehicleType, value)}
                    displayValue={displayValue}
                    visibleDates={visibleDates}
                  />
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

    </>
    )
  }
}

interface AssetClassHistoricalRowProps {
  data: any
  row: number
  editMode: boolean
  date: string
  displayValue: string
  visibleDates: string[]
  updateValue: (value:any, property:string, vehicleType:VehicleTypeCodeEv, date:string) => void
}

const VehicleTypes = [
  { code: VehicleTypeCodeEv.TOT, label: "Total" },
  { code: VehicleTypeCodeEv.SA, label: "Separate Account" },
  { code: VehicleTypeCodeEv.POOL, label: "Pooled/Commingled" },
  { code: VehicleTypeCodeEv.MFI, label: "Mutual Fund Institutional" },
  { code: VehicleTypeCodeEv.MFR, label: "Mutual Fund Retail" }
]
const AssetClassHistoricalRow = ({data, row, editMode, updateValue, displayValue, date, visibleDates}: AssetClassHistoricalRowProps) => {
  let calculatedTotal = 0
  const format = displayValue === "assetsUnderManagement" ? '$0,0.00' : '0,0'

  return (
    <tr className="fadein fadein-yellow">
      <td className="nowrap">
        {date}
      </td>
      {VehicleTypes.map((vehicleType, idx) => {

        let propertyVal, onChangeCallback
        propertyVal = get(data, date+'.'+vehicleType.code+'.'+displayValue)
        onChangeCallback = (value:any) => updateValue(value, displayValue, vehicleType.code, date)

        // Do include Totals column in Calculated Total
        if (vehicleType.code !== "TOT") {
          calculatedTotal += parseFloat(propertyVal || 0)
        }

        return(
          <td key={idx}>
            <FormInput
              property={displayValue}
              displayName={""}
              type={"number"}
              subtype={displayValue === "assetsUnderManagement" ? "currency" : ""}
              placeholder={""}
              idx={`${date}-${vehicleType.code}-${displayValue}`}
              editMode={editMode}
              showZero={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
            />
          </td>
        )
      })}
      <td className="text-right">{numbro(calculatedTotal).format(format)}</td>
    </tr>
  )
}



export default ProductAssetsVehicle
