import _ from 'lodash'

import { FormInputField } from './constant'
import { DeepPropertyAccess } from './helperClass'

/**
 * use: keys(object), no typescript complaint
 * see https://github.com/microsoft/TypeScript/issues/24574
 **/
/*
Workaround in es2017
Object.entries(data).forEach(
 ([key, value]) => console.log(key, value)
);
*/
export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[]

export const setProperty = <T, K1 extends keyof T, K2 extends T[K1]>(
  obj: T,
  key: K1,
  value: K2
) => {
  obj[key] = value
}

/* only the deepest nested property and value is returned as flatted object
  Be aware of same name nested property like __typename would be manipulated as
  the deepest nested value, not currently used*/
export const retrieveDeepestNestedPropertyFromObject = (obj: object) => {
  const flattened = {}
  keys(obj).forEach(key => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(
        flattened,
        retrieveDeepestNestedPropertyFromObject(obj[key])
      )
    } else {
      flattened[key] = obj[key]
    }
  })
  return flattened
}
/*
  TODO currying function to make sortBy array for sorting order??
  const sortBy = [{ prop: "" }]
*/

export const sortArrayByColumn = <T extends { [columnName: string]: any }>(
  arr: T[],
  columnName: string,
  ascending = true
) =>
  arr.sort((a, b) =>
    ascending ? a[columnName] - b[columnName] : b[columnName] - a[columnName]
  )

/*work similar as Array.prototype.sort, modify original array*/
export const sortByPropertyValue = <T extends { [propertyName: string]: any }>(
  props: string,
  arr: T[]
) => {
  const prop = props.split(".")
  const len = prop.length
  arr.sort(function(a, b) {
    let refA = a
    let refB = b
    for (let i = 0; i < len; i++) {
      refA = refA[prop[i]]
      refB = refB[prop[i]]
    }
    if (refA === null) {
    }
    if (refA < refB) {
      return -1
    } else if (refA > refB) {
      return 1
    } else {
      return 0
    }
  })
  return arr
}

/*in-place sort, Consider edge cases like "Other", "Total", "Null value" */
export const sortByTypeValueWithAggregation = <
  T extends { [propertyName: string]: any }
>(
  props: string,
  arr: T[]
) => {
  const prop = props.split(".")
  const len = prop.length
  arr.sort(function(a, b) {
    let refA = a
    let refB = b
    for (let i = 0; i < len; i++) {
      refA = refA[prop[i]]
      refB = refB[prop[i]]
    }
    // refA and refB should be {code:string, value: string}
    // put other, total to the last one
    if ((refA.code === "TOTAL") !== (refB.code === "TOTAL")) {
      return refA.code === "TOTAL" ? 1 : -1
    }
    if ((refA.code === "OTHER") !== (refB.code === "OTHER")) {
      return refA.code === "OTHER" ? 1 : -1
    }
    if (!refA.value) {
      return refA.code > refB.value ? 1 : -1
    }
    if (!refB.value) {
      return refA.value > refB.type ? 1 : -1
    }
    if (refA.value < refB.value) {
      return -1
    } else if (refA.value > refB.value) {
      return 1
    } else {
      return 0
    }
  })
  return arr
}

/**filter object with key(might have more than keys) array, return only matched keys and values
 *
 * @param {object} source obj
 * @param {Partial<Extract<keyof T, string>[]>} keys string array contains property you need. works for only one layer
 *
 * const a = { x: 1, y: 2, z: [123], o: false }
 * const b = <Extract<keyof typeof a, string>[]>["x", "y", "z"]
 * const c = <Extract<keyof typeof a, string>[]>["x","t"]
 * console.log(filterProperties(a, b)) => { x: 1, y: 2, z: [123]}
 * console.log(filterProperties(a, c)) => { x: 1}
 **/
export const filterProperties = <
  T extends object,
  K extends Partial<Extract<keyof T, string>[]>
>(
  obj: T,
  keys: K
) =>
  keys.reduce(
    (o: object, key) =>
      key && obj.hasOwnProperty(key) ? { ...o, [key]: obj[key] } : o,
    {}
  )

/*not in use right now*/
export const objectDiff = (object: object, base: object) => {
  function changes(object: object, base: object) {
    const accumulator = {} as any
    keys(base).forEach(key => {
      if (object[key] === undefined) {
        accumulator[`-${key}`] = base[key]
      }
    })
    return _.map(object, (value, key) => {
      let baseVal = DeepPropertyAccess.get(base, key as never)
      if (baseVal === undefined) {
        accumulator[`+${key}`] = value
      } else if (!_.isEqual(value, baseVal)) {
        accumulator[key] =
          _.isObject(value) && _.isObject(baseVal)
            ? changes(value, baseVal)
            : value
      }
    })
  }
  return changes(object, base)
}

export const shouldUseYearInput = (propertyName: string | undefined) => {
  return propertyName && propertyName.toLowerCase().startsWith("year")
}

/*
  Return a copy of data object without property in property array, working for deep nested properties.
*/
export const excludePropertyArray = (
  data: { [name: string]: any },
  propertyArray: string[]
) => {
  if (data === null) {
    return data
  }
  const result: { [name: string]: any } = {}
  keys(data).forEach(property => {
    if(typeof data === "string"|| typeof data === "number"){
      return data
    }
    const currentValType = typeof data[property]
    if (!propertyArray.includes(property)) {
      if ("object" === currentValType) {
        if (Array.isArray(data[property])) {
          _.set(
            result,
            property,
            data[property].map((el: any) => {
              if (typeof el === "string" || typeof el === "number") {
                return el
              } else {
                return excludePropertyArray(el, propertyArray)
              }
            })
          )
        } else {
          _.set(
            result,
            property,
            excludePropertyArray(data[property], propertyArray)
          )
        }
      } else {
        _.set(result, property, data[property])
      }
    }
  })
  return result
}

type ActionCollection = {
  [sourcePath: string]: {
    destinationPath: string
    action: (
      sourceVal: any,
      valInCurrentDestination?: any
    ) => object | string | number | null | undefined | boolean
  }
}
/**
 * helper function for reshaping object, works for one to one propertyName/Shape Change
 * e.g. data = {type: {code:"code", value:"value"}}
 * actions = {"type.code":{destinationPath: "type", action:(input:string)=>"new " + input }}
 * return {type:"new code"}, if destinationPath is empty string, the sourcePath and value is filtered out.
 * not working for copy value, only work for move one value from one property to another property (action function works.)
 * for copy value purpose, use _.set instead
 * this action would merge values on dest with values on source
      action: (source, dest) => ({ ...dest, ...source }), action should be pure function
 * @param {object} data The original object needs to be modified.
 * @param {object}  actions optional MapObject , Format {[sourcePath:string]: {destinationPath: string  action: (props:any) => any}
 * @return {object} data a new copy of modified data, return type is any
} */
export const reShapeObject = (
  data: { [name: string]: any },
  actions: ActionCollection = {}
): object => {
  if (!data) {
    return data
  }
  const result = _.cloneDeep(data)
  if (!actions) {
    return result
  }
  keys(actions).forEach(sourcePath => {
    const sourceVal = _.get(data, sourcePath)
    const { destinationPath, action } = _.get(actions, sourcePath)
    if (destinationPath === "") {
      _.unset(result, destinationPath)
    } else {
      let valInCurrentDestination = _.get(data, destinationPath)
      if (valInCurrentDestination) {
        _.set(
          result,
          destinationPath,
          action(sourceVal, valInCurrentDestination)
        )
      } else {
        _.set(result, destinationPath, action(sourceVal))
      }
      if (destinationPath !== sourcePath) {
        _.unset(result, sourcePath)
      }
    }
  })
  return result
}

// helper functions for array checking
export const isEmptyArray = (data: any) => {
  return Array.isArray(data) && data.length === 0
}

export const isArray = (data: any) => {
  return !!data && Array.isArray(data)
}

export const isObjectNotNull = (data: any) => {
  if (data === null) {
    return false
  }
  return typeof data === "object"
}

/**
 * note null is object while undefined is not.
 * Check if @param {any} object is an object and not an array(while array is an object).
 * **/
export const isNotArrayButObject = (data: any) => {
  return !isArray(data) && typeof data === "object"
}
/**
 * helper method for convertLookupToString
 **/
const isLookUpObject = (
  obj: { [property: string]: any },
  specialTypenameArray = [] as string[]
) => {
  const type = _.get(obj, "__typename")
  if (!!type) {
    const stringType = type as string
    if (stringType.endsWith("Lookup")) {
      return true
    } else if (
      specialTypenameArray &&
      specialTypenameArray.includes(stringType)
    ) {
      return true
    }
  }
  return false
}

/**
 * helper method for convertLookupToString
 **/
const isAssetClass = (obj: { [property: string]: any }, specialClassArray: string[] = []) => {
  const type = _.get(obj, "__typename")
  const id = _.get(obj, "id")
  if (!!type) {
    const stringType = type as string
    if ((stringType.endsWith("AssetClass") || specialClassArray.includes(type)) && id) {
      return true
    }
  }
  return false
}
/**
 * Convert output type(from query) to input type(for mutation). All nested values whose __typename property ends with Lookup， or match values in stated in specialTypenameArray
 * would be convert to values of code so {propertyName:{code:'codeV', value:"valueV", __typename: "**Lookup"}} would be converted to
 * {propertyName: ‘codeV'}
 * @param {object} data for conversion.
 * @param {boolean} addTypeToName To add Type to the end of converted fields
 * @param {array} specialTypenameArray for customization(default as empty array)
 *
 */

export const convertLookupToString = (
  data: { [property: string]: any },
  addTypeToName: boolean,
  specialTypenameArray: string[] = [],
  specialClassArray: string[] = [],
) => {
  let result = _.cloneDeep(data)
  keys(result).forEach(property => {
    const value = result[property]
    if (!value) {
    } else if (isLookUpObject(value, specialTypenameArray)) {
      if(addTypeToName){
        result[property + "Type"] = _.get(value, "code")
        delete result[property]
      } else {
        result[property] = _.get(value, "code")
      }
    } else if (specialTypenameArray.includes(property)) {
      result[property] = _.get(value, "code")
    } else if (isAssetClass(value, specialClassArray)) {
      result[property] = _.get(value, "id")
    } else if (isNotArrayButObject(value)) {
      result[property] = convertLookupToString(value, addTypeToName, specialTypenameArray, specialClassArray)
    } else if (Array.isArray(value)){
      result[property] = value.map((o) => {
        if(isAssetClass(o, specialClassArray)){
          return _.get(o, "id")
        } else {
          return convertLookupToString(o, addTypeToName, specialTypenameArray, specialClassArray)
        }
      })
    }
  })
  return result
}

/**
 * Convert intersectional type object into separate type object.
 * @param {object} object for conversion.
 * @param {array} filterLists a nested array, element is an array of string(property for one type)
 * @returns {array}, array of objects, each object of index i is a filtered result by filterLists[i] filter.
 *
 */
export const filterByPropertiesList = (
  object: any,
  filterLists: string[][] = [[]]
): Array<object> => {
  return filterLists.map(filterList => {
    let result = {} as any
    filterList.forEach(filter => {
      if (keys(object).includes(filter)) {
        result[filter] = object[filter]
      }
    })
    return result as object
  })
}

export const splitAndJoinBy = (
  str: string,
  splitBy: string = " ",
  joinBy: string = " "
) => {
  return str.split(splitBy).join(joinBy)
}
export const getCheckedValuesFromState = (
  state: any,
  property: string,
  fieldName: string = "code"
) => {
  const checkedItems = _.get(state, property)
  if (!checkedItems) {
    return checkedItems
  }
  return checkedItems.map((lookup: any) => {
    return lookup[fieldName]
  })
}

/**
 * Fill in state with properties if state is null
 **/
export const InitialStateFormat = (
  DisplayInput: FormInputField[],
  state: object
) => {
  DisplayInput.forEach(el => {
    if (el.property !== "") {
      if (_.get(state, el.property) === undefined) {
        _.set(state, el.property, null)
      }
    }
  })
  return state
}

export const getNumValue = (textValue: string | null | undefined) =>{
 return isNaN(Number(textValue)) ? null : Number(textValue)
}

export const parseTextToNum = (
  state: any[] | { [name: string]: any },
  arr: any[]
) => {
  console.log(state, arr)
  if (!state) {
    return state
  }

  if (Array.isArray(state)) {
    return state.map((item: any) => {
      let result = { ...item }
      arr.map((el) => {
        const textValue = _.get(item, el)
        console.log('text', item, el, textValue)
        const value = getNumValue(textValue)
        _.set(result, el, value)
      })
      return result
    })
  } else {
    let newState:{ [name: string]: any } = {...state}
    arr.map(el => {
      let textValue = { ...state[el] }
      console.log('text', state, el, textValue)
      const value = getNumValue(textValue)
      _.set(newState, el, value)
    })
    return newState
  }
}
