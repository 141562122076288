import React, { Component } from "react"
import Icon from "./Icon"
interface LoadingMessageProps {
  loadingMessage?: string
}
export default class LoadingOverlay extends Component<LoadingMessageProps> {
  render() {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center h-100'>
        <svg xmlns="http://www.w3.org/2000/svg" width={46} height={46} viewBox="0 0 23 23" className="svg-icon loading">
          <rect className="sqr" fill="currentColor" width="5" height="5"/>
          <rect className="sqr"  fill="currentColor"  width="5" height="5" transform="translate(9)"/>
          <rect className="sqr"  fill="currentColor"  width="5" height="5" transform="translate(18)"/>
          <rect className="sqr"  fill="currentColor"  width="5" height="5" transform="translate(0 9)"/>
          <rect className="sqr"  fill="currentColor"  width="5" height="5" transform="translate(9 9)"/>
          <rect className="sqr"  fill="currentColor"  width="5" height="5" transform="translate(18 9)"/>
          <rect className="sqr"  fill="currentColor"  width="5" height="5" transform="translate(0 18)"/>
          <rect className="sqr"  fill="currentColor"  width="5" height="5" transform="translate(9 18)"/>
          <rect className="sqr"  fill="currentColor"  width="5" height="5" transform="translate(18 18)"/>
        </svg>
      </div>
    )
  }
}