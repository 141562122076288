import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import iassign from "immutable-assign"
import _ from "lodash"
import moment from 'moment'
import React, { Component, FormEvent, useEffect, useState } from "react"
import { Button, ButtonDropdown, Col, DropdownMenu, DropdownToggle, Form, FormGroup, Input, ListGroup, ListGroupItem, Row, Table } from "reactstrap"
import { DATE_API_FORMAT, FormInputSubClasses } from "../../../helpers/constant"
import { ClientPortfolioIsCompositeFragment, Maybe, MemberOfComposite, PlanClientPortfolioFragment, SearchTypes } from "../../../__generated__/graphql"
import { SearchTypeDisplays } from '../../Search/SearchEnums'
import { FormInput } from "../../ui/Forms/FormInput"
import { ClientPortfolioAddStatic, UpdatePortfolioListProps } from './Search'

type MemberOfComponentProps = {
  data: Maybe<MemberOfComposite[]> | any[]
  handleChange: (value: any) => void
  editMode: boolean
  hidden?: boolean
  options: JSX.Element
  planIds: {id: number, name: string, __typename: string}[]
  setEditedMemberOfComposite: React.Dispatch<React.SetStateAction<MemberOfComposite[] | undefined>>
  setUpdatedMemberOfCompositeInput: React.Dispatch<React.SetStateAction<UpdatePortfolioListProps<MemberOfComposite[] | undefined>>>
}

type DisplayDataType = {
  property: string
  checkFunction?: (input: any) => any
  value: any
}
interface columnDefItem {
  field: string
  type: string
  title: string
  className?: string
  readonly?: boolean
  subtype?: string
  optionSource?: string
  required?: boolean
  subClasses?: { [name in FormInputSubClasses]?: string }
  widthClass?: string
  // used for textLink helper function
  textLinkParams?: {
    property?: string, // to get property value from state and then passed to input as text params
    url: string,
  },
  searchTypes?: SearchTypes[]
  displayData?: DisplayDataType
}

interface SingleMemberOfCompositesInput extends columnDefItem {}

interface MemberOfCompositeExtendedInput extends MemberOfComposite {
  newAdded?: boolean
}

interface SingleMemberOfCompositesProps {
  data: any
  row: number
  columnDef: SingleMemberOfCompositesInput[]
  editMode: boolean
  removeRow: (input: any) => void
  addRow?: (input: any) => void
  updateValue: (value: any, type: string, property: string) => void
}

const MemberOfCompositesInput: SingleMemberOfCompositesInput[] = [
  {
    field: "composite.id",
    title: "composite id",
    type: "text",
    readonly: true,
    // required: true,
    subClasses: {
      // inputWrapperClasses: "row px-3"
    },
    widthClass: "col-3",
  },
  {
    field: "composite.name",
    title: "composite name",
    type: "text",
    subtype: "textLink",
    readonly: true,
    subClasses: {
      // inputWrapperClasses: "row px-3"
    },
    textLinkParams: {
      property: "composite.id",
      url: "/clientportfolios/",
    },
    widthClass: "col-5",
  },
  // {
  //   field: "startDate",
  //   title: "start date",
  //   type: "date",
  //   // required: true,
  //   subClasses: {
  //     // inputWrapperClasses: "row px-3"
  //     inputClasses: "text-left",
  //   },
  //   // displayData: {
  //   //   property: "composite.newAdded",
  //   //   checkFunction: (input) => !!input,
  //   //   value: true,
  //   // },
  //   widthClass: "col-2",
  // },
  // {
  //   field: "endDate",
  //   title: "end date",
  //   type: "date",
  //   // required: true,
  //   // displayData: {
  //   //   property: "composite.newAdded",
  //   //   checkFunction: (input) => !!input,
  //   //   value: false,
  //   // },
  //   subClasses: {
  //     inputClasses: "text-left",
  //     // inputWrapperClasses: "row px-3"
  //   },
  //   widthClass: "col-2",
  // },
]

export interface PlanSelection {
  id: number
  name?: string
  __typename: string
}
interface PlanSearchProps {
  data: PlanSelection[]
  onClick: (plan: PlanSelection) => void
}

interface PlanSearchState {
  plans: PlanSelection[]
  search: string
}

export class PlanSearch extends Component<PlanSearchProps, PlanSearchState> {
  state = {
    plans: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredPlans = _.filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p?.name?.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      plans: filteredPlans
    })
  }

  handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // prevent enter from submitting form
    e.key === 'Enter' && e.preventDefault()
  }

  render() {
    return (
      <>
        <Row onClick={(e)=>e.stopPropagation()}>
          <Col sm="12">
            <FormGroup row className="relative m-0 pt-1 pb-1">
              <Input
                type="text"
                placeholder="Find plan by name"
                onChange={this.onChange}
                value={this.state.search}
                onKeyDown={this.handleEnterKeyDown}
              />
              <span className="o-88 absolute center-v right-1 pe-none">
                <FontAwesomeIcon
                  icon={["fas", "search"]}
                  size="2x"
                  className="fontawesome-icon dark-icon-color text-gray-50"
                />
              </span>
            </FormGroup>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links">
          { this.state.plans?.map((plan) => {
            if (!plan) { return <></> }
            return (<ListGroupItem
              tag="a"
              key={`pp-plan-${plan?.id}`}
              onClick={(event:React.MouseEvent<any, globalThis.MouseEvent>) => {
                event.stopPropagation()
                this.props.onClick(plan)
              }}
              className={classnames({"divider": plan?.id === 18737})}
            >
              {plan?.name}
            </ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

const getInitialData = (data: MemberOfComponentProps["data"]) => {
  return data || ([] as Maybe<MemberOfComposite>[])
}

const dataShouldShow = (currentState: any, displayData: DisplayDataType) => {
  let { property: disPlayProperty, checkFunction, value: disPlayValue } = displayData

  let calculatedPropertyValInput = _.get(currentState, disPlayProperty)

  if (checkFunction) {
    let valueFromCheckFunction = checkFunction(calculatedPropertyValInput)
    return valueFromCheckFunction === disPlayValue
  } else {
    return calculatedPropertyValInput === disPlayValue
  }
}

const MemberOfCompositesRow: React.FC<SingleMemberOfCompositesProps> = (props) => {
  let { data, row, columnDef, editMode, removeRow, updateValue } = props
  let endDate = _.get(data, "endDate")
  // do not show if is not current member.
  let today = moment()
  // if(today.isAfter(endDate) || (today.isSame(endDate, 'month') &&
  // today.isSame(endDate, 'year'))) {
  //   return <tr></tr>
  // }
  return (
    <tr className={editMode ? "hover-actions editing" : "hover-actions"}>
      {columnDef.map((header, idx) => {
        let { field, displayData, textLinkParams, type, subtype } = header
        let propertyVal = _.get(data, field)
        let editable = !!editMode
        let textLinkResolved: Maybe<{ url: string }> = null
        if(type === "text" && subtype == "textLink" && !editMode && textLinkParams) {
          let {property: linkProperty, url} = textLinkParams
          let urlResolved: string = url
          if(linkProperty) {
            urlResolved +=  _.get(data, linkProperty)
          }
          textLinkResolved = {url: urlResolved}
        }
        return (
          <td key={`${idx}`} className={`${header?.widthClass}`}>
            <FormInput
              key={header.field}
              property={`${row}-${idx}-${header.field}`}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={`memberof-composite-item-${row}-${idx}`}
              editMode={editable}
              propertyVal={propertyVal}
              readonly={!!header.readonly}
              updateValue={(value: any) => {
                updateValue(value, header.type, header.field)
              }}
              // required={header.required}
              // optionSource={header.optionSource}
              subClasses={header.subClasses || {}}
              textLinkParams={textLinkResolved}
            />
          </td>
        )
      })}
      <td className="actions">
        {editMode && data?.composite?.newAdded && (
          <Button color="link" className="btn-thin" disabled={!editMode} onClick={() => removeRow(row)}>
            <FontAwesomeIcon icon="trash" className="text-blue-100" />
          </Button>
        )}
      </td>
    </tr>
  )
}

/**
 * test clientPortfolioId: 21332(one), 21331(more), 21333, 24553, 50484(zero)
 */
export const ClientPortfolioMemberOfComposites: React.FC<MemberOfComponentProps> = (props) => {
  const componentName = "MemberOfComposites"
  const { data, handleChange, planIds, setEditedMemberOfComposite, setUpdatedMemberOfCompositeInput } = props
  const editMode = false
  // const [initialData, resetData] = useState(getInitialData(data))
  const [currentData, setData] = useState(() => getInitialData(data))
  const [currentId, setId] = useState(-1)
  let [search, setSearch] = useState<string>("")
  const [planSearchDropdownOpen, setPlanSearchDropdownOpen] = useState(false)
  const [selectedPlan, setSelectedPlan]= useState<PlanSelection | null>(null)

  const convertAddedItem = (addItem: ClientPortfolioIsCompositeFragment) => {
    return ({
      startDate: moment().format(DATE_API_FORMAT),
      endDate: null,
      composite: addItem,
      __typename: "MemberOfComposite"
    })
  }


  useEffect(() => {
    setData(getInitialData(data))
  }, [data])

  useEffect(() => {
    if(!editMode) {
      setSelectedPlan(null)
    }
  }, [editMode])

  const generateId = () => {
    let nextId = currentId - 1
    console.log(nextId)
    setId(nextId)
    return nextId
  }

  const handleValueChange = (idx: number, value: any, type: string, property: string) => {
    let nextData: Maybe<MemberOfComposite>[] =
      currentData?.map((el, index) => {
        if (idx === index) {
          let newValue = _.cloneDeep(currentData[index])
          if (newValue) {
            _.set(newValue, property, value)
          }
          return newValue
        } else {
          return el
        }
      }) || []
    setData(nextData)
    handleChange(nextData)
  }

  const togglePlan = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    event?.stopPropagation()
    setPlanSearchDropdownOpen(!planSearchDropdownOpen)
  }

  const selectPlan = (plan: PlanSelection) => {
    togglePlan()
    setSelectedPlan(plan)
  }

  const planPicker = () => {
    if(editMode) {
      return (
        <ButtonDropdown
          isOpen={planSearchDropdownOpen}
          toggle={togglePlan}
          className={classnames("headline-dropdown plan-picker-dropdown-btn")}
        >
          <DropdownToggle caret={editMode}>
            { selectedPlan?.name || "Find plan by name" }
          </DropdownToggle>
          <DropdownMenu onClick={(event)=>event.stopPropagation()}>
            {!!planIds && <PlanSearch data={planIds} onClick={selectPlan} /> }
          </DropdownMenu>
        </ButtonDropdown>
      )
    } else {
      return <React.Fragment key={"plan-picker"}></React.Fragment>
    }
  }

  const isEmptyTable = () => !(currentData?.length > 0)

  const removeRow = (idx: number) => {
    let newData = [
      ...currentData.slice(0, idx),
      ...currentData.slice(idx + 1)
    ]
    setData(newData)
    handleChange(newData)
  }

  return (
    <div className={classnames("row pl-0 mb-2")} key={`${componentName}`} onSubmit={(event:FormEvent<any>) => {
      event.stopPropagation()
      event.preventDefault()
  }}>
      <div className="d-flex flex-column">
        {/* {editMode && planPicker()}
        {editMode && (!!selectedPlan?.id) &&
          <StaticClientPortfolioSearch
            key={"memberOfComposite"}
            list={{} as any}
            portfolioList={(data || []) as any[]}
            selectedPlan={{id: selectedPlan?.id, planName: selectedPlan?.name, __typename: "PlanAutocomplete"}}
            setEditedList={(value: any) => {
                setEditedMemberOfComposite(value)}}
            setMainListInput={(value: any) => {
              console.log("setMain", value)
              return value
            }}
            convertAddedItem={convertAddedItem}
            convertToClientPortfolioFragment={(item: any) => _.get(item, `composite.id`)}
            disabled={!editMode}
            filterRule={(item) => !!item?.isComposite}
          />
        } */}
        {<ClientPortfolioAddStatic
          list={{items: data} as any}
          setEditedList={setEditedMemberOfComposite}
          editMode={editMode}
          convertAddedItem={convertAddedItem}
          convertToClientPortfolioFragment={(item: any) => _.get(item, `composite.id`)}
          auth={{} as any}
          planIds={planIds}
          searchTypesOverride={[SearchTypeDisplays['Portfolio']]}
          key={'memberOfComposite'}
        />}
      </div>
      <Table hover size="sm" className={""}>
        <thead className="table-border-bottom">
          <tr className="table-header">
            {MemberOfCompositesInput.map((headerDef, idx) => {
              return (
                <th key={`memberof-composites-header-${idx}`} className={classnames(headerDef.widthClass, "text-uppercase font-weight-normal text-gray-80 font-small text-left")}>
                  {headerDef.title}
                </th>
              )
            })}
            {<th key={"actions"}></th>}
          </tr>
        </thead>
        <tbody>
          {currentData?.map((data: any, idx: number) => {
              return (
                <MemberOfCompositesRow
                  data={data}
                  key={`memberof-composite-row-${idx}`}
                  row={idx}
                  editMode={editMode}
                  columnDef={MemberOfCompositesInput}
                  removeRow={removeRow}
                  updateValue={(value, type, property) => handleValueChange(idx, value, type, property)}
                />
              )
            })}
          {editMode && isEmptyTable() && (
            <tr key={"empty"} className={"text-center border-bottom-line-break"}>
              <td align="center" colSpan={5}>
              Add to a composite portfolio.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {/* {editMode && (
        <div key={"add-member border-bottom-line-break"} className="d-flex">
          <Button color="link" className="text-callan-blue font-weight-500" onClick={addRow}>
            Add to Composite
            <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
          </Button>
        </div>
      )} */}
    </div>
  )
}