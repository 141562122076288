import React, { Component, useState } from 'react';
import { filter, compact} from 'lodash'
import { Dropdown, DropdownMenu, Row, Col, Form, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useManagerSimpleEmployeesQuery, Person } from '../../../__generated__/graphql'
import classNames from 'classnames'
import _ from 'lodash'

interface EmployeePickerProps {
  children: JSX.Element
  dropdownClass?: string
  onClick: (product:PersonSelection) => void
  managerId: number
  dropup?: boolean
  disabled?: boolean
  showName?: boolean
}

export interface PersonSelection {
  id: number
  firstName?: string | null
  lastName?: string | null
}

const EmployeePicker: React.FC<EmployeePickerProps> = ({ managerId, children, dropdownClass, onClick, dropup, disabled, showName}: EmployeePickerProps) => {
  let heading = <></>
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { loading, error, data } = useManagerSimpleEmployeesQuery({
    variables: { id: managerId } // Fetch employees from Callan Org
  })

  const toggle = (event?:React.MouseEvent<any, MouseEvent> | React.KeyboardEvent<any>) => {
    event?.stopPropagation()
    if(disabled) return
    setDropdownOpen(!dropdownOpen)
  }

  const selectPerson = (product:PersonSelection) => {
    toggle()
    onClick(product)
  }
  if(showName){
    if(loading){
      heading = <div className='pl-2'>Loading...</div>
    } else {
      heading = <div className='pl-2'>{data?.org?.name}</div>
    }
  }
  // CAL-2561
  if (loading || error || !data?.org?.employees || data?.org?.employees == null ) {
    return <>
      {heading}
      <Dropdown direction={dropup ? 'up' : 'down'} disabled={true} isOpen={false} className={classNames("headline-dropdown person-picker-dropdown", dropdownClass)}>
        { children }
      </Dropdown>
    </>
  }
  // CAL-1498 Order by firstName, then lastName, if firstName null, sortBy lastName
  let people = _.sortBy(compact(data.org.employees.filter((person: any) =>  person.active)), [(obj)=>obj.firstName||obj.lastName, "lastName"])
  return (
    <>
      {heading}
      <Dropdown direction={dropup ? 'up' : 'down'} disabled={disabled} isOpen={dropdownOpen} toggle={toggle} className={classNames("headline-dropdown person-picker-dropdown", dropdownClass)}>
        { children }
        <DropdownMenu>
          <PersonSearch data={people} onClick={selectPerson} />
        </DropdownMenu>
      </Dropdown>
    </>
  )

}

interface PersonSearchProps {
  data: PersonSelection[]
  onClick: (product:PersonSelection) => void

}
interface PersonSearchState {
  people: PersonSelection[]
  search: string
}

export class PersonSearch extends Component<PersonSearchProps> {
  state = {
    people: this.props.data,
    search: ""
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    let filteredPeople = filter(this.props.data, (p) => {
      const terms = value.split(' ')
      const regexes = terms.map(t => new RegExp('\\b'+t,'ig'))

      let results = regexes.map(r => !!p?.firstName?.match(r) || !!p?.lastName?.match(r))
      return results.reduce((result, r) => result && r, true)
    })

    this.setState({
      search: value,
      people: filteredPeople
    })
  }

  render() {
    return (
      <>
      {/* avoid click propagation out of component.*/}
        <Row onClick={(event)=>event.stopPropagation()} className="dropdown-search">
          <Col sm="12">
            <Form>
              <FormGroup row className="relative m-0 pt-1">
                <Input
                  type="text"
                  placeholder="Find Person by name"
                  onChange={this.onChange}
                  value={this.state.search}
                />
                <span className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </span>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ListGroup className="headline-dropdown-links" onClick={(event)=>event.stopPropagation()}>
          { this.state.people?.map((person) => {
            if (!person) { return <></> }

            return (<ListGroupItem tag="a" key={`pp-person-${person?.id}`} onClick={() => this.props.onClick(person)}>{person?.firstName} {person?.lastName}</ListGroupItem>)
          })}
        </ListGroup>
      </>
    )
  }
}

export default EmployeePicker