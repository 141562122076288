import { ConfigType, ConfigTypeUnion } from '../../../helpers/constant'

const productBasicConfig: ConfigTypeUnion = {
  id: true,
  manager: true,
  name: true,
  inactive: true,
  assetClass: { fullName: true, parent: { fullName: true } },
  primaryBenchmark: true,
  activePassive: true,
  structure: true,
  capitalStructure: true,
  materialChanges: true
}

export const passiveProductSummaryConfig: ConfigType = {}

export const RealAssetsProductSummaryConfig: ConfigType = {
  primaryRealAssetSectorsTarget: true,
  primaryUSRegions: true,
  realReturnTarget: true
}

export const ClosedEndedProductSummaryConfig: ConfigTypeUnion = {
  vintageYearFirstCashflow: true,
  vintageYearLegalInception: true,
  controlRights: true,
  targetRaise: true,
  minimumCommitment: true,
  sponsorCommitment: true,
  investmentPeriod: true,
  term: true,
  extensions: true,
  finalCommitSize: true,
  fundRaising: {
    date: true,
    committedCapital: true
  },
  projectedCloseDates: true
}

export const defaultProductOverviewSummaryConfig = {
  ...productBasicConfig
}
