import classnames from "classnames"
import _, { cloneDeep, compact, set } from "lodash"
import React, { Fragment, useEffect, useState } from "react"
import { Button, DropdownToggle, Label, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap"

import { FormInputField, FormInputSubClasses } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { FormInput } from "../../ui/Forms/FormInput"
import { ClientPortfolioDataCollectionFragment, Maybe, PersonBasicFragment, SearchTypes, useManagerSimpleEmployeesQuery, usePersonBasicLazyQuery } from "../../../__generated__/graphql"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal } from "reactstrap/lib"
import EditButtons from "../../ui/EditButtons"
import EmployeePicker from "../../ui/Pickers/EmployeePicker"

type BenchmarkComponentProps = {
  data: ClientPortfolioDataCollectionFragment[]
  handleChange: (value: any) => void
  editMode: boolean
  hidden?: boolean
  options?: JSX.Element
  hasTargetAdded?: boolean
  managerId?: number
}

interface DefItem extends FormInputField {
  widthClass?: string
  valueGetter?: (params: any, field: string) => any

}

const ContactsColInput: DefItem[] = [
  // with readonly columns
  {
    property: "contact",
    type: "text",
    // select ? // options?
    readonly: true,
    label: "contact",
    subClasses: {
      // labelClasses: "hidden",
      // inputWrapperClasses: "pl-1",
    },
    // for fixed readonly
    valueGetter: (params: any, field: string) => {
      let consultant = _.get(params, field)
      return compact([consultant?.firstName,consultant?.lastName]).join(" ")
    },
  },
  {
    property: "contact.email",
    type: "text",
    // select ? // options?
    readonly: true,
    label: "email",
    subClasses: {
      // labelClasses: "hidden",
      // inputWrapperClasses: "pl-1",
    },
  },
  {
    property: "contact.employer.name",
    type: "text",
    subtype: "textLink",
    textLinkParams: {
      property: "contact.employer.id",
      url: "/managers/",
    },
    // select ? // options?
    readonly: true,
    label: "manager",
    subClasses: {
      // labelClasses: "hidden",
      // inputWrapperClasses: "pl-1",
    },
  },
  // {
  //   property: "contact.employer.shortName",
  //   type: "text",
  //   // select ? // options?
  //   readonly: true,
  //   label: "manager display name",
  //   subClasses: {
  //     // labelClasses: "hidden",
  //     // inputWrapperClasses: "pl-1",
  //   },
  // },
  {
    property: "contactType.code",
    type: "select",
    optionSource: "ContactTypeCode",
    label: "contact type",
    subClasses: {
      // labelClasses: "hidden",
      inputWrapperClasses: "pl-1",
    },
  },
  // {
  //   property: "dataRequestTypes",
  //   type: "select",
  //   optionSource: "DataRequestTypeCode",
  //   label: "data request",
  //   readonly: true,
  //   subClasses: {
  //     // labelClasses: "hidden",
  //     // inputWrapperClasses: "pl-1",
  //   },
  // },
]

export const ClientPortfolioContacts = (props: BenchmarkComponentProps) => {
  const componentName = "DataCollectionContacts"
  const { data, editMode, handleChange, options, managerId } = props
  const [currentData, setData] = useState(data)
  const [showSearchBar, setShowSearchBar] = useState(false)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const updateValue = (value: any, property: string, idx:number) => {
    let newData = cloneDeep(currentData)
    let modifiedRow = newData[idx]
    set(modifiedRow, property, value)
    newData[idx] = modifiedRow
    setData(newData)
    handleChange(newData)
  }

  const addRow = (person:PersonBasicFragment) => {
    let newData = cloneDeep(currentData)
    newData.push({contact: person, contactType: null, dataRequestTypes: [], __typename: "ClientPortfolioDataCollection"})
    setData(newData)
    handleChange(newData)
  }

  const removeRow = (idx: number) => {
    let newData = cloneDeep(currentData)
    newData.splice(idx, 1)
    setData(newData)
    handleChange(newData)
  }
  return (
    <>
      <h5 className="row headline gray-underline underline text-uppercase mt-3">{`data collection contacts`}</h5>
      <div className={"row form-inline"} key={`${componentName}`}>
        <Table hover responsive size="sm" key={`${componentName}-table`} className={""}>
          <thead className="table-border-bottom">
            <tr className="table-header">
              {ContactsColInput.map((headerDef, idx) => {
                let widthClass = headerDef?.widthClass || "col-2"
                // if (!editMode) {
                //   widthClass = idx === 0 ? "col-3" : "col-9"
                // }
                return (
                  <th key={`${componentName}-header-${idx}`} className={classnames(headerDef.widthClass, "text-uppercase font-weight-normal text-gray-80 font-small text-left")}>
                    {headerDef.label}
                  </th>
                )
              })}
              {<th key={"actions"}></th>}
            </tr>
          </thead>
          <tbody key={0}>
            {currentData.map((row: any, idx: number) => {
              return (
                <DataCollectionRow
                  data={row}
                  key={row.uniqueId}
                  row={idx}
                  editMode={editMode}
                  removeRow={(idx: number) => removeRow(idx)}
                  updateValue={(value, property) =>
                    updateValue(value, property, idx)
                  }
                />
              )
            })}
            {editMode && (
              <tr>
                <td colSpan={6}>
                  <Row>
                    <Button color="link" className="text-callan-blue btn-thin py-0" onClick={() => setShowSearchBar(true)}>
                      {"Add Contact"}
                      <FontAwesomeIcon icon="plus-circle" className="ml-2 text-callan-blue" />
                    </Button>

                  </Row>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <AddContactModal
        modalOpen={showSearchBar}
        managerId={managerId}
        setModalOpen={setShowSearchBar}
        onSubmitted={(person) => addRow(person)}
      />
    </>
  )
}

interface DataCollectionRowProps {
  data: ClientPortfolioDataCollectionFragment
  row: number
  editMode: boolean
  removeRow: (input: any) => void
  updateValue: (value: any, property: string) => void
}

const DataCollectionRow: React.FC<DataCollectionRowProps> = (props) => {
  let { data, row, editMode, removeRow, updateValue } = props
  // do not show if is not current member.
  // if(today.isAfter(endDate) || (today.isSame(endDate, 'month') &&
  // today.isSame(endDate, 'year'))) {
  //   return <tr></tr>
  // }
  return (
    <tr className={editMode ? "hover-actions editing" : "hover-actions"}>
      {ContactsColInput.map((header, idx) => {
        let { property, textLinkParams, type, subtype, valueGetter } = header
        let propertyVal = _.get(data, property)
        let editable = !!editMode
        let textLinkResolved: Maybe<{ url: string }> = null
        if(type === "text" && subtype === "textLink" && textLinkParams) {
          let {property: linkProperty, url} = textLinkParams
          let urlResolved: string = url
          if(linkProperty) {
            urlResolved +=  _.get(data, linkProperty)
          }
          textLinkResolved = {url: urlResolved}
        }
        if(valueGetter){
          propertyVal = valueGetter(data, property)
        }
        return (
          <td key={`${idx}`}>
            <FormInput
              key={header.property}
              property={`${row}-${idx}-${header.property}`}
              displayName={""}
              type={header.type}
              subtype={header.subtype}
              idx={`memberof-composite-item-${row}-${idx}`}
              editMode={editable}
              propertyVal={propertyVal}
              readonly={!!header.readonly}
              updateValue={(value: any) => {
                updateValue(value, header.property)
              }}
              // required={header.required}
              optionSource={header.optionSource}
              subClasses={header.subClasses || {}}
              textLinkParams={textLinkResolved}
            />
          </td>
        )
      })}
      <td className="actions">
        {editMode && (
          <Button color="link" className="btn-thin" disabled={!editMode} onClick={() => removeRow(row)}>
            <FontAwesomeIcon icon="trash" className="text-blue-100" />
          </Button>
        )}
      </td>
    </tr>
  )
}

interface AddContactModalProps {
  modalOpen: boolean
  managerId?: number
  setModalOpen: (value: boolean) => void
  onSubmitted: (value: PersonBasicFragment) => void
}

export const AddContactModal = (props: AddContactModalProps) => {
  const { modalOpen, managerId, setModalOpen, onSubmitted } = props

  const [contact, setContact] = useState<any>(null)
  // const { loading: managerLoading, error: managerError, data: managerData } = useManagerSimpleEmployeesQuery({
  //   variables: { id: managerId } // Fetch employees from Callan Org
  // })
  const [personBasicQuery, {loading, data, error}] = usePersonBasicLazyQuery()


  const closeModal = (refresh: boolean = false) => {
    setContact(null)
    setModalOpen(false)
  }

  const onSubmit = () => {
    if(data?.person){
      onSubmitted(data.person)
      closeModal()
    }
  }

  useEffect(() => {
    if (contact) {
      personBasicQuery({variables: {id: contact.id}})
    }
  }, [contact])

  return (
    <Modal
      size='md'
      className='mt-5 filter-product-views'
      isOpen={modalOpen}
      toggle={() => {
        setContact(null)
        setModalOpen(!modalOpen)
      }}
      zIndex={1500}
    >
      <ModalHeader className='fee-modal-header full-width-header'>
        <div className='d-flex justify-content-between'>
          <div>Add Contact</div>
          <div onClick={() => closeModal()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {/* <FormInput
          property={"roles"}
          displayName={"Contact"}
          type={"search"}
          subtype="single"
          searchTypes={[SearchTypes.People]}
          idx={"1-person-search"}
          placeholder="Search for a person by name"
          editMode={true}
          propertyVal={contact}
          updateValue={(value) => setContact(value)}
          required={true}
          subClasses={{ labelClasses: "col-sm-1 pl-3 mr-2", inputWrapperClasses: "col-sm-8" }}
        /> */}
        {managerId &&
          <EmployeePicker
            managerId={managerId || -1}
            onClick={(value) => setContact(value)}
            showName
          >
            <span>
              <DropdownToggle className="author-picker btn-no-style">
                { contact?.id ? `${contact.firstName} ${contact.lastName}` : "Select Contact"}
                &nbsp;
                <FontAwesomeIcon icon="caret-down" />
              </DropdownToggle>
            </span>
          </EmployeePicker>
        }
        {!managerId && <>No Manager directly connected to this portfolio</>}
      </ModalBody>
      <ModalFooter>
        <EditButtons
          className={"disable-on-white"}
          editMode={true}
          setEditMode={() => true}
          cancelEdit={() => closeModal()}
          onSubmit={onSubmit}
          saving={loading || (data?.person?.id !== contact?.id)}
          disableOnError={true}
          disabled={!contact}
          saveText={"Add Contact"}
          savingText="Fetching"
        />
      </ModalFooter>
    </Modal>
  )
}
