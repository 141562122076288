import React from "react"
import {
  Row,
  Col,
  ListGroupItem
} from "reactstrap"
import { SearchVehicleResultFragment } from "../../../__generated__/graphql"


export const VehicleResult = (props:SearchVehicleResultFragment) => {
  const { vehicleName, fundid, status, category, product, identifiers } = props

  return (
    <ListGroupItem tag="a" href={`/products/${product?.product?.id}/vehicles/${fundid}`}>
      <div className="category vehicle">
        <div className="category-text">
          <h6>Vehicle</h6>
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col md="3">
            <h3>{vehicleName}</h3>
            <h5 className="d-inline mr-1">Manager:</h5>
            <p className="d-inline">{ product?.product?.manager?.name}</p>
          </Col>
          <Col md="9">
            <Row>
              <Col md="2">
                <h5>Vehicle ID</h5>
                <p>{fundid}</p>
              </Col>
              <Col md="2">
                <h5>Product Name</h5>
                <p>{ product?.product?.name }</p>
              </Col>
              <Col md="2">
                <h5>Ticker</h5>
                <p>{ !!identifiers?.ticker ? identifiers?.ticker : "-" }</p>
              </Col>
              <Col md="2">
                <h5>Vehicle Type</h5>
                <p>{ category?.value }</p>
              </Col>
              <Col md="2">
                <h5>Strategy Type</h5>
                <p>{ product?.product?.assetClass?.shortName}</p>
              </Col>
              <Col md="2">
                <h5>Status</h5>
                <p>{ status?.value }</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </ListGroupItem>
  )
}