import { disableFragmentWarnings, gql } from "@apollo/client"
import {
  CLIENT_PORTFOLIO_DETAIL_COMPONENT_FRAGMENT,
  CLIENT_PORTFOLIO_DETAIL_LAYOUT_FRAGMENT,
  REPORTS_FRAGMENT
} from "./ClientFragments"
import { CONSULTANT_DASHBOARD_COMPONENT_FRAGMENT } from "./ConsultantFragments"
import { REPORT_LIST_HEADER_FRAGMENT } from "./ListFragments"
import {
  REPORT_MANAGER_PERFORMANCE_DATA_FRAGMENT
} from "./ManagerPerformanceFragments"
import {
  REPORT_PERFORMANCE_COMPARISON_DC_PORTFOLIO_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_PORTFOLIO_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_TRAILING_CLIENT_PORTFOLIO_DEFAULT_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_TRAILING_CLIENT_PORTFOLIO_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_TRAILING_GROUP_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_TRAILING_INDEX_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_TRAILING_VEHICLE_DEFAULT_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_TRAILING_VEHICLE_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_YEARS_CLIENT_PORTFOLIO_DEFAULT_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_YEARS_CLIENT_PORTFOLIO_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_YEARS_GROUP_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_YEARS_INDEX_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_YEARS_VEHICLE_DEFAULT_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_YEARS_VEHICLE_FRAGMENT,
} from "./PerformanceComparisonFragments"
import {
  CLIENT_PORTFOLIO_DETAIL_COMPONENT_SETTINGS_FRAGMENT,
  DEFAULT_COMPONENT_DEFINITION_FRAGMENT,
  LISTS_FOR_ORG_FRAGMENT,
  PORTFOLIO_CASH_FLOW_FRAGMENT,
  REPORTS_LIST_CLIENT_PORTFOLIO_FRAGMENT,
  REPORT_ASSET_ALLOCATION_DATA_FRAGMENT,
  REPORT_ASSET_DISTRIBUTION_DATA_FRAGMENT,
  REPORT_ASSET_DISTRIBUTION_DETAIL_FRAGMENT,
  REPORT_PERFORMANCE_COMPARISON_DATA_FRAGMENT,
  REPORT_SIMPLE_FRAGMENT,
  REPORT_SIMPLE_LIST_FRAGMENT,
  SUM_SHEET_FRAGMENT,
  SUM_SHEET_HOVER_FRAGMENT,
  TRANSACTION_TYPE_FRAGMENT,
} from "./ReportFragments"

disableFragmentWarnings()

export const REPORT_LIST_QUERY = gql`
  query ReportList($filters: ReportFilters!) {
    reports(filters: $filters) {
      ...ReportSimpleFragment
    }
  }
  ${REPORT_SIMPLE_FRAGMENT}
`

export const REPORT_DETAIL_QUERY = gql`
  query ReportDetail($id: Int!, $liveView: Boolean!, $draftView: Boolean!) {
    report(id: $id) {
      ...ReportsFragment
    }
  }
  ${REPORTS_FRAGMENT}
`

export const UPDATE_REPORT = gql`
  mutation updateReport(
    $input: UpdateReportInput!
    $liveView: Boolean!
    $draftView: Boolean!
  ) {
    updateReport(input: $input) {
      report {
        ...ReportsFragment
      }
    }
  }
  ${REPORTS_FRAGMENT}
`

export const REPORT_ASSET_ALLOCATION_DATA_QUERY = gql`
  query ReportAssetAllocationData(
    $ids: [Int!]!
    $date: Date
    $liveView: Boolean!
    $draftView: Boolean!
    $preview: Boolean!
    $settings: ComponentSettingsInput!
  ) {
    components(ids: $ids) {
      id
      draftData(input: { endDate: $date}) @include(if: $draftView) {
        ...ReportAssetAllocationDataFragment
      }
      liveData(input: { endDate: $date }) @include(if: $liveView) {
        ...ReportAssetAllocationDataFragment
      }
      previewData(settings: $settings) @include(if: $preview) {
        ...ReportAssetAllocationDataFragment
      }
    }
  }
  ${REPORT_ASSET_ALLOCATION_DATA_FRAGMENT}
`

export const NEW_ASSET_ALLOCATION_DATA_QUERY = gql`
  query NewAssetAllocationData($settings: ComponentSettingsInput!) {
    componentPreviewData(settings: $settings) {
      ...ReportAssetAllocationDataFragment
    }
  }
  ${REPORT_ASSET_ALLOCATION_DATA_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_DATA_QUERY = gql`
  query ReportAssetDistributionData(
    $ids: [Int!]!
    $dates: ComponentDataSettingsInput
    $liveView: Boolean!
    $draftView: Boolean!
    $preview: Boolean!
    $settings: ComponentSettingsInput!
  ) {
    components(ids: $ids) {
      draftData(input: $dates) @include(if: $draftView) {
        ...ReportAssetDistributionDataFragment
      }
      liveData(input: $dates) @include(if: $liveView) {
        ...ReportAssetDistributionDataFragment
      }
      previewData(settings: $settings) @include(if: $preview) {
        ...ReportAssetDistributionDataFragment
      }
    }
  }
  ${REPORT_ASSET_DISTRIBUTION_DATA_FRAGMENT}
`

export const NEW_ASSET_DISTRIBUTION_DATA_QUERY = gql`
  query NewAssetDistributionData($settings: ComponentSettingsInput!) {
    componentPreviewData(settings: $settings) {
      ...ReportAssetDistributionDataFragment
    }
  }
  ${REPORT_ASSET_DISTRIBUTION_DATA_FRAGMENT}
`

export const REPORT_ASSET_DISTRIBUTION_DETAIL_QUERY = gql`
  query ReportAssetDistributionDetail(
    $id: Int!
    $date: Date!
    $beginningDate: Date!
    $showNNI: Boolean!
  ) {
    list(id: $id) {
      ...ReportAssetDistributionDetailFragment
    }
  }
  ${REPORT_ASSET_DISTRIBUTION_DETAIL_FRAGMENT}
`

export const PERFORMANCE_ORDER_ID_QUERY = gql`
  query PerformanceOrderId($request: String!) {
    performanceOrderID(request: $request)
  }
`

export const REPORT_MANAGER_PERFORMANCE_COMPONENT_QUERY = gql`
  query ReportManagerPerformanceComponent(
    $id: Int!
    $date: Date
    $liveView: Boolean!
    $draftView: Boolean!
    $preview: Boolean!
    $settings: ComponentSettingsInput!
  ) {
    components(ids: [$id]) {
      id
      draftData(input: { endDate: $date }) @include(if: $draftView) {
        ...ReportManagerPerformanceDataFragment
      }
      liveData(input: { endDate: $date }) @include(if: $liveView) {
        ...ReportManagerPerformanceDataFragment
      }
      previewData(settings: $settings)@include(if: $preview) {
        ...ReportManagerPerformanceDataFragment
      }
    }
  }
  ${REPORT_MANAGER_PERFORMANCE_DATA_FRAGMENT}
`

export const NEW_MANAGER_PERFORMANCE_DATA_QUERY = gql`
  query NewManagerPerformanceData($settings: ComponentSettingsInput!) {
    componentPreviewData(settings: $settings) {
      ...ReportManagerPerformanceDataFragment
    }
  }
  ${REPORT_MANAGER_PERFORMANCE_DATA_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_PORTFOLIO_QUERY = gql`
  query ReportPerformanceComparisonTrailingPortfolio(
    $id: Int!
    $monthDate: Date!
    $priorDate: Date!
    $fiscalQuarters: Int!
    $groupId: String!
    $lastQuarterGross: Boolean!
    $yearToDateGross: Boolean!
    $fiscalYearGross: Boolean!
    $last1YearGross: Boolean!
    $last2YearsGross: Boolean!
    $last3YearsGross: Boolean!
    $last5YearsGross: Boolean!
    $last7YearsGross: Boolean!
    $last10YearsGross: Boolean!
    $last15YearsGross: Boolean!
    $last20YearsGross: Boolean!
    $sinceInceptionGross: Boolean!
    $lastQuarterNet: Boolean!
    $yearToDateNet: Boolean!
    $fiscalYearNet: Boolean!
    $last1YearNet: Boolean!
    $last2YearsNet: Boolean!
    $last3YearsNet: Boolean!
    $last5YearsNet: Boolean!
    $last7YearsNet: Boolean!
    $last10YearsNet: Boolean!
    $last15YearsNet: Boolean!
    $last20YearsNet: Boolean!
    $sinceInceptionNet: Boolean!
    $lastQuarter: Boolean!
    $yearToDate: Boolean!
    $fiscalYear: Boolean!
    $last1Year: Boolean!
    $last2Years: Boolean!
    $last3Years: Boolean!
    $last5Years: Boolean!
    $last7Years: Boolean!
    $last10Years: Boolean!
    $last15Years: Boolean!
    $last20Years: Boolean!
    $sinceInception: Boolean!
    $actualPerformance: Boolean
    $withGroup: Boolean!
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    clientPortfolio(id: $id) {
      ...ReportPerformanceComparisonTrailingClientPortfolioFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_CLIENT_PORTFOLIO_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_PORTFOLIO_DEFAULT_QUERY = gql`
  query ReportPerformanceComparisonTrailingPortfolioDefault(
    $id: Int!
    $monthDate: Date!
    $priorDate: Date!
    $fiscalQuarters: Int!
    $groupId: String!
    $lastQuarterGross: Boolean!
    $yearToDateGross: Boolean!
    $fiscalYearGross: Boolean!
    $last1YearGross: Boolean!
    $last2YearsGross: Boolean!
    $last3YearsGross: Boolean!
    $last5YearsGross: Boolean!
    $last7YearsGross: Boolean!
    $last10YearsGross: Boolean!
    $last15YearsGross: Boolean!
    $last20YearsGross: Boolean!
    $sinceInceptionGross: Boolean!
    $lastQuarterNet: Boolean!
    $yearToDateNet: Boolean!
    $fiscalYearNet: Boolean!
    $last1YearNet: Boolean!
    $last2YearsNet: Boolean!
    $last3YearsNet: Boolean!
    $last5YearsNet: Boolean!
    $last7YearsNet: Boolean!
    $last10YearsNet: Boolean!
    $last15YearsNet: Boolean!
    $last20YearsNet: Boolean!
    $sinceInceptionNet: Boolean!
    $lastQuarter: Boolean!
    $yearToDate: Boolean!
    $fiscalYear: Boolean!
    $last1Year: Boolean!
    $last2Years: Boolean!
    $last3Years: Boolean!
    $last5Years: Boolean!
    $last7Years: Boolean!
    $last10Years: Boolean!
    $last15Years: Boolean!
    $last20Years: Boolean!
    $sinceInception: Boolean!
    $actualPerformance: Boolean
    $withGroup: Boolean!
    $groupPerformanceType: GroupPerformanceTypeCode!
    $sinceInceptionQuarters: Int!
  ) {
    clientPortfolio(id: $id) {
      ...ReportPerformanceComparisonTrailingClientPortfolioDefaultFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_CLIENT_PORTFOLIO_DEFAULT_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_YEARS_PORTFOLIO_QUERY = gql`
  query ReportPerformanceComparisonYearsPortfolio(
    $id: Int!
    $monthDate: Date!
    $priorDate: Date!
    $groupId: String!
    $yearToDateGross: Boolean!
    $fiscalGross: Boolean!
    $yearTwoGross: Boolean!
    $yearThreeGross: Boolean!
    $yearFourGross: Boolean!
    $yearFiveGross: Boolean!
    $yearSixGross: Boolean!
    $yearSevenGross: Boolean!
    $yearEightGross: Boolean!
    $yearNineGross: Boolean!
    $yearTenGross: Boolean!
    $yearToDateNet: Boolean!
    $fiscalNet: Boolean!
    $yearTwoNet: Boolean!
    $yearThreeNet: Boolean!
    $yearFourNet: Boolean!
    $yearFiveNet: Boolean!
    $yearSixNet: Boolean!
    $yearSevenNet: Boolean!
    $yearEightNet: Boolean!
    $yearNineNet: Boolean!
    $yearTenNet: Boolean!
    $yearToDate: Boolean!
    $fiscal: Boolean!
    $yearTwo: Boolean!
    $yearThree: Boolean!
    $yearFour: Boolean!
    $yearFive: Boolean!
    $yearSix: Boolean!
    $yearSeven: Boolean!
    $yearEight: Boolean!
    $yearNine: Boolean!
    $yearTen: Boolean!
    $fiscalQuarters: Int!
    $yearTwoDate: Date!
    $yearThreeDate: Date!
    $yearFourDate: Date!
    $yearFiveDate: Date!
    $yearSixDate: Date!
    $yearSevenDate: Date!
    $yearEightDate: Date!
    $yearNineDate: Date!
    $yearTenDate: Date!
    $actualPerformance: Boolean
    $withGroup: Boolean!
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    clientPortfolio(id: $id) {
      ...ReportPerformanceComparisonYearsClientPortfolioFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_CLIENT_PORTFOLIO_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_YEARS_PORTFOLIO_DEFAULT_QUERY = gql`
  query ReportPerformanceComparisonYearsPortfolioDefault(
    $id: Int!
    $monthDate: Date!
    $priorDate: Date!
    $groupId: String!
    $yearToDateGross: Boolean!
    $fiscalGross: Boolean!
    $yearTwoGross: Boolean!
    $yearThreeGross: Boolean!
    $yearFourGross: Boolean!
    $yearFiveGross: Boolean!
    $yearSixGross: Boolean!
    $yearSevenGross: Boolean!
    $yearEightGross: Boolean!
    $yearNineGross: Boolean!
    $yearTenGross: Boolean!
    $yearToDateNet: Boolean!
    $fiscalNet: Boolean!
    $yearTwoNet: Boolean!
    $yearThreeNet: Boolean!
    $yearFourNet: Boolean!
    $yearFiveNet: Boolean!
    $yearSixNet: Boolean!
    $yearSevenNet: Boolean!
    $yearEightNet: Boolean!
    $yearNineNet: Boolean!
    $yearTenNet: Boolean!
    $yearToDate: Boolean!
    $fiscal: Boolean!
    $yearTwo: Boolean!
    $yearThree: Boolean!
    $yearFour: Boolean!
    $yearFive: Boolean!
    $yearSix: Boolean!
    $yearSeven: Boolean!
    $yearEight: Boolean!
    $yearNine: Boolean!
    $yearTen: Boolean!
    $fiscalQuarters: Int!
    $yearTwoDate: Date!
    $yearThreeDate: Date!
    $yearFourDate: Date!
    $yearFiveDate: Date!
    $yearSixDate: Date!
    $yearSevenDate: Date!
    $yearEightDate: Date!
    $yearNineDate: Date!
    $yearTenDate: Date!
    $actualPerformance: Boolean
    $withGroup: Boolean!
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    clientPortfolio(id: $id) {
      ...ReportPerformanceComparisonYearsClientPortfolioDefaultFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_CLIENT_PORTFOLIO_DEFAULT_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_VEHICLE_QUERY = gql`
  query ReportPerformanceComparisonTrailingVehicle(
    $id: String!
    $monthDate: Date!
    $fiscalQuarters: Int!
    $groupId: String!
    $lastQuarter: Boolean!
    $yearToDate: Boolean!
    $fiscalYear: Boolean!
    $last1Year: Boolean!
    $last2Years: Boolean!
    $last3Years: Boolean!
    $last5Years: Boolean!
    $last7Years: Boolean!
    $last10Years: Boolean!
    $last15Years: Boolean!
    $last20Years: Boolean!
    $sinceInception: Boolean!
    $actualPerformance: Boolean
    $groupPerformanceType: GroupPerformanceTypeCode!
    $vehiclePerformanceType: VehiclePerformanceTypeCode!
  ) {
    vehicle(id: $id) {
      vehicle {
        ...ReportPerformanceComparisonTrailingVehicleFragment
      }
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_VEHICLE_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_VEHICLE_DEFAULT_QUERY = gql`
  query ReportPerformanceComparisonTrailingVehicleDefault(
    $id: String!
    $monthDate: Date!
    $fiscalQuarters: Int!
    $groupId: String!
    $lastQuarter: Boolean!
    $yearToDate: Boolean!
    $fiscalYear: Boolean!
    $last1Year: Boolean!
    $last2Years: Boolean!
    $last3Years: Boolean!
    $last5Years: Boolean!
    $last7Years: Boolean!
    $last10Years: Boolean!
    $last15Years: Boolean!
    $last20Years: Boolean!
    $sinceInception: Boolean!
    $actualPerformance: Boolean
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    vehicle(id: $id) {
      vehicle{
        ...ReportPerformanceComparisonTrailingVehicleDefaultFragment
      }
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_VEHICLE_DEFAULT_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_YEARS_VEHICLE_QUERY = gql`
  query ReportPerformanceComparisonYearsVehicle(
    $id: String!
    $monthDate: Date!
    $groupId: String!
    $yearToDate: Boolean!
    $fiscal: Boolean!
    $yearTwo: Boolean!
    $yearThree: Boolean!
    $yearFour: Boolean!
    $yearFive: Boolean!
    $yearSix: Boolean!
    $yearSeven: Boolean!
    $yearEight: Boolean!
    $yearNine: Boolean!
    $yearTen: Boolean!
    $fiscalQuarters: Int!
    $yearTwoDate: Date!
    $yearThreeDate: Date!
    $yearFourDate: Date!
    $yearFiveDate: Date!
    $yearSixDate: Date!
    $yearSevenDate: Date!
    $yearEightDate: Date!
    $yearNineDate: Date!
    $yearTenDate: Date!
    $actualPerformance: Boolean
    $groupPerformanceType: GroupPerformanceTypeCode!
    $vehiclePerformanceType: VehiclePerformanceTypeCode!
  ) {
    vehicle(id: $id) {
      vehicle {
        ...ReportPerformanceComparisonYearsVehicleFragment
      }
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_VEHICLE_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_YEARS_VEHICLE_DEFAULT_QUERY = gql`
  query ReportPerformanceComparisonYearsVehicleDefault(
    $id: String!
    $monthDate: Date!
    $groupId: String!
    $yearToDate: Boolean!
    $fiscal: Boolean!
    $yearTwo: Boolean!
    $yearThree: Boolean!
    $yearFour: Boolean!
    $yearFive: Boolean!
    $yearSix: Boolean!
    $yearSeven: Boolean!
    $yearEight: Boolean!
    $yearNine: Boolean!
    $yearTen: Boolean!
    $fiscalQuarters: Int!
    $yearTwoDate: Date!
    $yearThreeDate: Date!
    $yearFourDate: Date!
    $yearFiveDate: Date!
    $yearSixDate: Date!
    $yearSevenDate: Date!
    $yearEightDate: Date!
    $yearNineDate: Date!
    $yearTenDate: Date!
    $actualPerformance: Boolean
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    vehicle(id: $id) {
      vehicle{
        ...ReportPerformanceComparisonYearsVehicleDefaultFragment
      }
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_VEHICLE_DEFAULT_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_INDEX_QUERY = gql`
  query ReportPerformanceComparisonTrailingIndex(
    $id: ID!
    $monthDate: Date!
    $fiscalQuarters: Int!
    $groupId: String!
    $lastQuarter: Boolean!
    $yearToDate: Boolean!
    $fiscalYear: Boolean!
    $last1Year: Boolean!
    $last2Years: Boolean!
    $last3Years: Boolean!
    $last5Years: Boolean!
    $last7Years: Boolean!
    $last10Years: Boolean!
    $last15Years: Boolean!
    $last20Years: Boolean!
    $sinceInception: Boolean!
    $actualPerformance: Boolean
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    index(id: $id) {
      ...ReportPerformanceComparisonTrailingIndexFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_INDEX_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_YEARS_INDEX_QUERY = gql`
  query ReportPerformanceComparisonYearsIndex(
    $id: ID!
    $monthDate: Date!
    $groupId: String!
    $yearToDate: Boolean!
    $fiscal: Boolean!
    $yearTwo: Boolean!
    $yearThree: Boolean!
    $yearFour: Boolean!
    $yearFive: Boolean!
    $yearSix: Boolean!
    $yearSeven: Boolean!
    $yearEight: Boolean!
    $yearNine: Boolean!
    $yearTen: Boolean!
    $fiscalQuarters: Int!
    $yearTwoDate: Date!
    $yearThreeDate: Date!
    $yearFourDate: Date!
    $yearFiveDate: Date!
    $yearSixDate: Date!
    $yearSevenDate: Date!
    $yearEightDate: Date!
    $yearNineDate: Date!
    $yearTenDate: Date!
    $actualPerformance: Boolean
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    index(id: $id) {
      ...ReportPerformanceComparisonYearsIndexFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_INDEX_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_TRAILING_GROUP_QUERY = gql`
  query ReportPerformanceComparisonTrailingGroup(
    $id: String!
    $monthDate: Date!
    $fiscalQuarters: Int!
    $groupId: String!
    $lastQuarter: Boolean!
    $yearToDate: Boolean!
    $fiscalYear: Boolean!
    $last1Year: Boolean!
    $last2Years: Boolean!
    $last3Years: Boolean!
    $last5Years: Boolean!
    $last7Years: Boolean!
    $last10Years: Boolean!
    $last15Years: Boolean!
    $last20Years: Boolean!
    $sinceInception: Boolean!
    $actualPerformance: Boolean
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    group(id: $id) {
      ...ReportPerformanceComparisonTrailingGroupFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_TRAILING_GROUP_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_YEARS_GROUP_QUERY = gql`
  query ReportPerformanceComparisonYearsGroup(
    $id: String!
    $monthDate: Date!
    $groupId: String!
    $yearToDate: Boolean!
    $fiscal: Boolean!
    $yearTwo: Boolean!
    $yearThree: Boolean!
    $yearFour: Boolean!
    $yearFive: Boolean!
    $yearSix: Boolean!
    $yearSeven: Boolean!
    $yearEight: Boolean!
    $yearNine: Boolean!
    $yearTen: Boolean!
    $fiscalQuarters: Int!
    $yearTwoDate: Date!
    $yearThreeDate: Date!
    $yearFourDate: Date!
    $yearFiveDate: Date!
    $yearSixDate: Date!
    $yearSevenDate: Date!
    $yearEightDate: Date!
    $yearNineDate: Date!
    $yearTenDate: Date!
    $actualPerformance: Boolean
    $groupPerformanceType: GroupPerformanceTypeCode!
  ) {
    group(id: $id) {
      ...ReportPerformanceComparisonYearsGroupFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_YEARS_GROUP_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_DC_PORTFOLIO_QUERY = gql`
  query ReportPerformanceComparisonDcPortfolio(
    $id: Int!
    $monthDate: Date!
    $aum: Int!
  ) {
    clientPortfolio(id: $id) {
      ...ReportPerformanceComparisonDcPortfolioFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_DC_PORTFOLIO_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_DATA_QUERY = gql`
  query ReportPerformanceComparisonData(
    $ids: [Int!]!
    $date: Date
    $settings: ComponentSettingsInput!
    $draft: Boolean!
    $live: Boolean!
    $preview: Boolean!
  ) {
    components(ids: $ids) {
      id
      draftData(input: { endDate: $date }) @include(if: $draft) {
        ...ReportPerformanceComparisonDataFragment
      }
      liveData(input: { endDate: $date }) @include(if: $live) {
        ...ReportPerformanceComparisonDataFragment
      }
      previewData(settings: $settings) @include(if: $preview) {
        ...ReportPerformanceComparisonDataFragment
      }
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_DATA_FRAGMENT}
`

export const NEW_PERFORMANCE_COMPARISON_DATA_QUERY = gql`
  query NewPerformanceComparisonData($settings: ComponentSettingsInput!) {
    componentPreviewData(settings: $settings) {
      ...ReportPerformanceComparisonDataFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_DATA_FRAGMENT}
`

export const DELETE_PERFORMANCE_ORDER = gql`
  mutation DeletePerformanceOrder($input: deletePerformanceOrderIDInput!) {
    deletePerformanceOrderID(input: $input) {
      status
      message
    }
  }
`

export const PUBLISH_REPORT = gql`
  mutation PublishReport(
    $reportId: Int!
    $layouts: [PublishLayoutArgs!]!
    $componentIds: [Int!]!
    $liveView: Boolean!
    $draftView: Boolean!
  ) {
    publishReport(reportId: $reportId) {
      report {
        ...ReportsFragment
      }
    }
    publishLayouts(input: $layouts) {
      layouts {
        list {
          id
          name
        }
        member {
          ... on ListGroup {
            id
          }
          ... on ListHeader {
            ...ReportListHeaderFragment
          }
          ... on ClientPortfolio {
            ...ReportsListClientPortfolioFragment
          }
        }
        layout {
          ...ClientPortfolioDetailLayoutFragment
        }
      }
    }
    publishComponents(ids: $componentIds) {
      components {
        id
        name
        type
        template
        reportsUsedIn {
          list {
            id
          }
          report {
            id
            name
          }
        }
        draftSettings {
          ...ClientPortfolioDetailComponentSettingsFragment
        }
        liveSettings {
          ...ClientPortfolioDetailComponentSettingsFragment
        }
      }
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_COMPONENT_SETTINGS_FRAGMENT}
  ${REPORTS_FRAGMENT}
  ${CLIENT_PORTFOLIO_DETAIL_LAYOUT_FRAGMENT}
  ${REPORT_LIST_HEADER_FRAGMENT}
  ${REPORTS_LIST_CLIENT_PORTFOLIO_FRAGMENT}
`

export const BULK_UPDATE_COMPONENT_APPROVALS = gql`
  mutation bulkUpdateComponentApprovals(
    $input: BulkUpdateComponentApprovalsInput!
    $liveView: Boolean!
    $draftView: Boolean!
  ) {
    bulkUpdateComponentApprovals(input: $input) {
      components {
        ...ClientPortfolioDetailComponentFragment
      }
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_COMPONENT_FRAGMENT}
`

// Move to List file once created
export const LIST_SIMPLE_QUERY = gql`
  query ListSimple($id: Int!) {
    list(id: $id) {
      ...ReportSimpleListFragment
    }
  }
  ${REPORT_SIMPLE_LIST_FRAGMENT}
`

export const LISTS_FOR_ORG_QUERY = gql`
  query ListsForOrg($id: Int!) {
    lists(filters: { orgs: [$id], limit: 50 }) {
      ...ListsForOrgFragment
    }
  }
  ${LISTS_FOR_ORG_FRAGMENT}
`

export const UPSERT_LAYOUT = gql`
  mutation UpsertLayout(
    $input: UpsertLayoutArgs!
    $liveView: Boolean!
    $draftView: Boolean!
  ) {
    upsertLayout(input: $input) {
      layout {
        layout {
          ...ClientPortfolioDetailLayoutFragment
        }
      }
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_LAYOUT_FRAGMENT}
`

export const UPDATE_COMPONENT = gql`
  mutation UpdateComponent(
    $input: UpdateComponentInput!
    $liveView: Boolean!
    $draftView: Boolean!
  ) {
    updateComponent(input: $input) {
      component {
        ...ClientPortfolioDetailComponentFragment
        ...ConsultantDashboardComponentFragment
      }
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_COMPONENT_FRAGMENT}
  ${CONSULTANT_DASHBOARD_COMPONENT_FRAGMENT}
`

export const UPDATE_COMPONENT_APPROVAL = gql`
  mutation UpdateComponentApproval(
    $input: UpdateComponentInput!
  ) {
    updateComponent(input: $input) {
      component{
        id
        approval {
          code
          value
        }
      }
    }
  }
`

export const CREATE_COMPONENT = gql`
  mutation CreateComponent(
    $input: CreateComponentInput!
    $liveView: Boolean!
    $draftView: Boolean!
  ) {
    createComponent(input: $input) {
      component {
        ...ClientPortfolioDetailComponentFragment
      }
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_COMPONENT_FRAGMENT}
`

export const DELETE_COMPONENT = gql`
  mutation DeleteComponent($input: DeleteInput!) {
    deleteComponent(input: $input) {
      status
      message
    }
  }
`

export const EXPORT_REPORT = gql`
  query ExportReport($input: ExportReportInput!) {
    exportReport(input: $input) {
      url
    }
  }
`

export const EXPORT_EXCEL_REPORT = gql`
  query ExportExcelReport($input: ExportExcelReportInput!) {
    exportExcelReport(input: $input) {
      url
    }
  }
`

export const PUSH_ALL_COMPONENT_DATES = gql`
  mutation PushAllComponentDates($input: UpdateComponentDatesInput!, $liveView: Boolean!, $draftView: Boolean!) {
    pushAllComponentDates(input: $input) {
      report {
        ...ReportsFragment
      }
    }
  }
  ${REPORTS_FRAGMENT}
`

export const SUM_SHEET_QUERY = gql`
  query SumSheet(
    $id: Int!
    $orderId: Float!
    $endDate: Date!
    $startDate: Date!
    $showMonth: Boolean!
    $showQuarter: Boolean!
  ) {
    list(id: $id) {
      ...SumSheetFragment
      excludeList {
        ...SumSheetFragment
      }
    }
  }
  ${SUM_SHEET_FRAGMENT}
`

export const SUM_SHEET_CASH_FLOW_QUERY = gql`
  query SumSheetCashFlow(
    $id: Int!
    $date: Date!
    $showMonth: Boolean!
    $showQuarter: Boolean!
  ) {
    clientPortfolio(id: $id) {
      ...PortfolioCashFlowFragment
    }
    transactionTypeMap {
      ...TransactionTypeFragment
    }
  }
  ${PORTFOLIO_CASH_FLOW_FRAGMENT}
  ${TRANSACTION_TYPE_FRAGMENT}
`

export const UPDATE_SUM_SHEET_CASH_FLOW = gql`
  mutation UpdateSumSheetCashFlow(
    $input: UpdateClientPortfolioInput!
    $date: Date!
    $showMonth: Boolean!
    $showQuarter: Boolean!
  ) {
    updateClientPortfolio(input: $input) {
      portfolio {
        ...PortfolioCashFlowFragment
      }
    }
  }
  ${PORTFOLIO_CASH_FLOW_FRAGMENT}
`

export const SUM_SHEET_HOVER_QUERY = gql`
  query SumSheetHoverFlow(
    $id: Int!
    $endDate: Date!
    $showMonth: Boolean!
    $showQuarter: Boolean!
  ) {
    clientPortfolio(id: $id) {
      ...SumSheetHoverFragment
    }
  }
  ${SUM_SHEET_HOVER_FRAGMENT}
`

export const DELETE_SUM_SHEET_CASH_FLOW = gql`
  mutation DeleteSumSheetCashFlow($input: DeleteClientPortfolioCashFlowTransactionInput!) {
    deleteClientPortfolioCashFlowTransactions(input: $input) {
      status
      message
    }
  }
`
export const DELETE_CLIENT_PORTFOLIO_CASH_FLOW_MARKET_VALUE = gql`
  mutation DeleteClientPortfolioCashFlowMarketValue($input: DeleteClientPortfolioCashFlowMarketValueInput!) {
    deleteClientPortfolioCashFlowMarketValues(input: $input) {
      status
      message
    }
  }
`

export const UPDATE_REPORT_ORDER = gql`
  mutation UpdateReportOrder($input: UpdateReportOrderInput!) {
    updateReportOrder(input: $input) {
      reports {
        id
        order
      }
    }
  }
  ${REPORTS_FRAGMENT}
`

export const DEFAULT_COMPONENT_SETTINGS_QUERY = gql`
  query DefaultComponentSettings {
    componentDefinitions {
      ...DefaultComponentDefinitionFragment
    }
  }
  ${DEFAULT_COMPONENT_DEFINITION_FRAGMENT}
`

export const REPORT_PERFORMANCE_COMPARISON_DEFAULT_PORTFOLIO = gql`
  query ReportPerformanceComparisonDefaultPortfolio(
    $id: Int!
  ) {
    clientPortfolio(id: $id) {
      ...ReportPerformanceComparisonPortfolioFragment
    }
  }
  ${REPORT_PERFORMANCE_COMPARISON_PORTFOLIO_FRAGMENT}
`