import { ConfigTypeUnion } from '../../../helpers/constant'
import { ClosedEndedProductSummaryConfig, defaultProductOverviewSummaryConfig } from './default'

const PrivateEquityConfig: ConfigTypeUnion = {
  controlRights: true,
  targetPrimaryGICSSectors: true,
  targetInvestmentStyle: true
}
/* shared by ClosedEndedPrivateEquity, ClosedEndedPrivateCredit, ClosedEndedRealAssets*/
export const ClosedEndedCommonConfig: ConfigTypeUnion = {
  targetRaise: true,
  minimumCommitment: true,
  sponsorCommitment: true,
  investmentPeriod: true,
  term: true,
  extensions: {
    numberAllowed: true,
    lengthAllowed: true
  },
  irrTarget: { gross: true, net: true }
}

export const ClosedEndedPrivateEquityProductOverviewSummaryOverrideConfig = {
  closedEnded: {
    ...ClosedEndedProductSummaryConfig,
    fundFocus: true,
    ...ClosedEndedCommonConfig,
    targetTotalValuePaidIn: {
      gross: true,
      net: true
    }
  },
  privateEquity: PrivateEquityConfig,
  product: {
    ...defaultProductOverviewSummaryConfig,
    primaryGeographicRegion: true,
    hardCap: true
  }
}
