import classnames from "classnames"
import { History } from "history"
import { compact, sortBy } from "lodash"
import React from "react"
import { useHistory } from "react-router-dom"
import { Col, ListGroup, ListGroupItem } from "reactstrap"
import {
  ConsultantDashboardPlanListFragment,
  ConsultantPlanListFetchFragment, Maybe,
  Plan
} from "../../../__generated__/graphql"
import {
  ConsultantDashboardAggregatedComponentProps,
  ConsultantDashboardBaseComponent,
  ConsultantDashboardIndividualComponentProps
} from "./ConsultantDashboardComponent"

const PlanList: React.FC<ConsultantDashboardAggregatedComponentProps> = ({
  component,
  auth,
  draftView,
}) => {
  return (
    <ConsultantDashboardBaseComponent<ConsultantPlanListFetchFragment>
      component={component}
      auth={auth}
      draftView={draftView}
      expectedTypename={"PlanList"}
      reactComponent={PlanListDisplay}
    />
  )
}

export type PlanListExpanded = {
  name?: string
  id: number
  plan?: Maybe<Plan>
}

/**
 * ignore group & order
 */
const expandPlanListWithNoGroup = (
  list: ConsultantPlanListFetchFragment["list"]
) => {
  let filteredListItems = compact(list?.items?.map((groupMember) => {
    if (groupMember.item?.__typename === "Plan") {
      return {
        name: groupMember.item.name || "",
        id: groupMember.item.id,
        plan: groupMember.item as ConsultantDashboardPlanListFragment,
      }
    }
    return undefined
  }))
  let orderedList =
    sortBy(compact(filteredListItems), (el) => el?.name?.toLocaleLowerCase()) || []
  return orderedList
}

const PlanListDisplay: React.FC<
  ConsultantDashboardIndividualComponentProps<ConsultantPlanListFetchFragment>
> = (props) => {
  let { settings: PlanList } = props
  let stackable = true
  let list = PlanList.list
  let showList: PlanListExpanded[] | undefined = undefined
  if (list) {
    showList = expandPlanListWithNoGroup(list)
  }
  let history = useHistory<History>()
  return (
    <Col md={12}>
      <div className={classnames("pane dashboard-pane", { stackable })}>
        {showList?.length === 0 && (
          <>
            <span className="muted-text">
              Your Client List will appear here.
            </span>

            <ListGroup className="recent">
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "85%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "55%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
              <ListGroupItem className="dummy-recent-item">
                <div
                  className="dummy-recent-item-entry"
                  style={{ width: "75%" }}
                ></div>
              </ListGroupItem>
            </ListGroup>
          </>
        )}
        <div className="client-list">
          {!!showList &&
            showList.map((listItem: PlanListExpanded, idx) => {
              let planId = listItem?.id
              if (planId) {
                return (
                  <PlanListRow
                    key={idx}
                    planId={planId}
                    history={history}
                    list={listItem}
                    currentHierarchy={[]}
                  />
                )
              }
              return <React.Fragment key={idx}></React.Fragment>
            })}
        </div>
      </div>
    </Col>
  )
}

interface PlanListRowProps {
  currentHierarchy: number[]
  planId: number
  history: History
  list: PlanListExpanded
}

const PlanListRow: React.FC<PlanListRowProps> = ({
  planId,
  list,
  currentHierarchy,
  history,
}) => {
  const depth = currentHierarchy.length

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    history.push(`/plans/${planId}/portfolios`)
  }

  return (
    <>
      <ListGroup
        className={classnames("report-sidebar-item recent flex-row")}
      >
        <div style={{ marginLeft: depth * 10 }} className="d-flex">
        </div>
        <ListGroupItem tag="a" className="report-sidebar-title">
          <h3 onClick={handleClick}>
            {list.name}
            <span className="chevron">&rsaquo;</span>
          </h3>
        </ListGroupItem>
      </ListGroup>
    </>
  )
}

export default PlanList
