import React, { Component, ChangeEvent } from "react"
import { Redirect } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { debounce } from 'lodash'
import { LocationDescriptor } from 'history'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  InputGroup,
  InputGroupButtonDropdown,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
 } from 'reactstrap';
import { LocationProps } from '../../queries/extended/appProps'

import {SearchAutocomplete } from './SearchAutocomplete'
import { SearchTypeDisplays, SearchTypeDisplaysOrder } from './SearchEnums'
import { Query } from '@apollo/client/react/components'
import { MeQuery } from "../../__generated__/graphql"
import { ME_QUERY } from "../../queries/Person"
import Auth from "../../Auth/Auth"

interface SearchBarProps {
  query: string | null
  type: SearchTypeDisplays | null
  location: LocationProps
  auth: Auth
}

interface SearchBarState {
  query: string,
  type: SearchTypeDisplays | null
  search: string
  autocomplete: string
  updatedSearch: boolean
}
export default class SearchBar extends Component<SearchBarProps> {
  state:SearchBarState = {
    query: "",
    type: null,
    search: "",
    autocomplete: "",
    updatedSearch: false
  }

  constructor(props:SearchBarProps) {
    super(props)
    this.updateAutocomplete = debounce(this.updateAutocomplete, 200)
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  updateAutocomplete = (value:string) => {
    this.setState({ autocomplete: value })
  }

  componentDidMount = () => {
    this.setState({
      query: this.props.query || '',
      type: this.props.type,
      search: this.props.location.search,
      updatedSearch: false
    })

    document.addEventListener('mousedown', this.handleClickOutside, true)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside, true)
  }

  componentWillUpdate = (nextProps:SearchBarProps) => {
    if (this.state.search !== nextProps.location.search && nextProps.location.pathname === "/search") {
      this.setState({ search: nextProps.location.search, updatedSearch: true, type: nextProps.type, autocomplete: ''})
    }
  }

  handleClickOutside = (e:MouseEvent) => {
    const domNode = ReactDOM.findDOMNode(this)

    if (!domNode || !domNode.contains(e.target as Node)) {
      this.setState({ autocomplete: '' })
    }
  }

  handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ query: e.target.value })
    this.updateAutocomplete(e.target.value)
  }

  handleTypeChange = (type: string ) => {
    let newType:SearchTypeDisplays | null = type !== "" ? type as SearchTypeDisplays : null
    this.setState({ type: newType }, () => {
      if (this.state.query !== "") {
        this.handleSubmit(null)
      }
    })

  }

  handleSubmit = (event:React.FormEvent<HTMLFormElement> | null) => {
    const { query, type } = this.state
    let searchVars = []

    console.log({ query, type})
    if (type) {
      searchVars.push("types=" + encodeURIComponent(type as string))
    }

    searchVars.push("q=" + encodeURIComponent(query))

    if (type || query !== '') {
      this.setState({ search: "?" + searchVars.join('&'), autocomplete: '', updatedSearch: true })
    }

    if (event) {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }

  render() {
    const {search, autocomplete, type} = this.state
    if (this.state.updatedSearch && search !== "" ) {
      this.setState({ updatedSearch: false})

      const redirectTo:LocationDescriptor = {
        pathname: "/search",
        search: search
      }
      console.log('redirectTo', search)
      return (
        <Redirect
          to={redirectTo}
          push
        />
      )
    }

    let autocompleter = <></>
    if (autocomplete !== "") {
      autocompleter = <SearchAutocomplete query={autocomplete} type={this.state.type} updateAutocomplete={this.updateAutocomplete}/>
    }

    let typeString = ""
    if (type) typeString = decodeURI(type as string)

    return (
      <Query<MeQuery> query={ME_QUERY} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true} >
        { meResults => {
          if (typeString === "Manager" && !this.props.auth.checkPermissions(["view:all_managers"])) typeString = "ALL"
          if (typeString === "Document" && !this.props.auth.checkPermissions(["search:documents"])) typeString = "ALL"
          if ((typeString === "Client" || typeString === "Custodian" || typeString === "RecordKeeper" || typeString === "Index" || typeString === "Groups") && !this.props.auth.checkPermissions(["view:all_clients"])) typeString = "ALL"

          return (
            <form className="navbar-form navbar-left" onSubmit={this.handleSubmit}>
              <InputGroup>
                <UncontrolledDropdown className="dropdown-select ">
                  <DropdownToggle className="btn-toggle">
                    { typeString !== '' ? typeString : 'ALL' }
                    <FontAwesomeIcon
                      icon="chevron-down"
                      className="fontawesome-icon"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleTypeChange('')} className={this.state.type === null ? 'active' : ''}>ALL</DropdownItem>
                    {
                      SearchTypeDisplaysOrder.map(displayType => {
                        const checkPermission = (type:SearchTypeDisplays, permission:string[]) => {
                          return displayType === type && !this.props.auth.checkPermissions(permission)
                        }

                        if (checkPermission(SearchTypeDisplays.Manager, ["view:all_managers"]) ||
                          checkPermission(SearchTypeDisplays.Custodian, ["view:all_clients"]) ||
                          checkPermission(SearchTypeDisplays["Record Keeper"], ["view:all_clients"]) ||
                          checkPermission(SearchTypeDisplays.Client, ["view:all_clients"]) ||
                          checkPermission(SearchTypeDisplays.Index, ["view:all_clients"]) ||
                          checkPermission(SearchTypeDisplays.Group, ["view:all_clients"]) ||
                          checkPermission(SearchTypeDisplays.Documents, ["search:documents"])) {
                          return <React.Fragment key={`type-option-${displayType}`}></React.Fragment>
                        }

                        return (<DropdownItem onClick={() => this.handleTypeChange(displayType)} key={`type-option-${displayType}`} className={this.state.type === displayType ? 'active' : ''}>{displayType}</DropdownItem>)

                      })
                    }
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Input
                  type="text"
                  className="form-control global-searchbar"
                  id="global_searchbar"
                  onChange={this.handleSearchChange}
                  placeholder="Search Callan..."
                  value={this.state.query}
                />

                <InputGroupButtonDropdown addonType="append" className="o-88 absolute center-v right-1 pe-none">
                  <FontAwesomeIcon
                    icon={["fas", "search"]}
                    size="2x"
                    className="fontawesome-icon dark-icon-color text-gray-50"
                  />
                </InputGroupButtonDropdown>
              </InputGroup>
              { autocompleter }
            </form>
          )
        }}
      </Query>
    )
  }
}
